import React, { useEffect, useState,useRef } from 'react';
import { Flex,Button, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link, Accordion, AccordionItem, AccordionButton, Box, AccordionIcon, AccordionPanel, StackDivider, Heading, Tabs, TabList, Tab, TabPanels, TabPanel, SimpleGrid, FormControl, FormLabel, Center, Spinner, Select, Image, Avatar,RadioGroup ,Radio } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
// import DevicePagination from './DevicePagination';
import { AddIcon } from '@chakra-ui/icons';
import { PATROLLING_LOGS_CLEAR_SNACKBAR,PATROLLING_LOGS_SEARCH } from 'constants/types';
// import {  } from '../../actions/devicesAction'; // Assuming you have a DeviceAction file
// import DeviceTableRow from './DevicesTableRow';
import PatrollingLogsTableRow from './PatrollingLogsTableRow'
// import DevicesPagination from './DevicesPagination';
// import PatrollingLogsagination from './PatrollingLogsPagination'

import { patrollingLogsGetAll } from '../../actions/patrollingLogsAction';
import PatrollingLogsPagination from './PatrollingLogsPagination';
import PatrollingLogsAdvanceSearch from './PatrollingLogsAdvanceSearch';
import { Skeleton, SkeletonCircle, SkeletonText,Stack } from '@chakra-ui/react'
import TimelineRow from 'components/Tables/TimelineRow';
const PatrollingLogsTable = (props) => {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const {url= "",plant} = props;
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.patrollingLogs.status);
  const responseMessage = useSelector(state => state.patrollingLogs.text);
  const loading = useSelector(state => state.patrollingLogs.loading);
  const patrollingLogsList = useSelector(state => state.patrollingLogs.patrollingLogsList);
  const response = useSelector(state => state.patrollingLogs.response);
  const showSnackbar = useSelector(state => state.patrollingLogs.showSnackbar);
  const currentPage = useSelector(state => state.patrollingLogs.page);
  const limit = useSelector(state => state.patrollingLogs.limit);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [getPatrollings, setGetPetrollings] = useState([]);
  const [patrollingDetails, setPatrollingDetails] = useState([]);
  const [allGuards, setAllGuards] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [guard, setGuard] = useState("");
  const history = useHistory();
  const tableRef = useRef(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [timeLineLoading, setTimeLineLoading] = useState(false);
  useEffect(() => {
    dispatch({
      type: PATROLLING_LOGS_SEARCH,
      payload: {
        searching: "",
      },
    });
    dispatch(patrollingLogsGetAll("&plant="+plant));
  }, [dispatch]);
 
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: PATROLLING_LOGS_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
      getAllGuards();
  }, [])

  useEffect(() => {
    // Function to update the state with the current window height
    const updateScreenHeight = () => {
      setScreenHeight(window.innerHeight);
    };

    // Add event listener to update the screen height whenever the window is resized
    window.addEventListener('resize', updateScreenHeight);

    // Remove event listener when component unmounts to prevent memory leaks
    return () => window.removeEventListener('resize', updateScreenHeight);
  }, []);

  const getPetrolling = (startDate="",endDate="",guard="") => {
    setPageLoading(true)
    setPatrollingDetails([]);
    axios
      .get(`${server_url}/patrolling-Duration/new?guardId=${guard}&startDate=${startDate}&endDate=${endDate}`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((response) => {
        setGetPetrollings(response.data.data)
        setPageLoading(false)
      })
      .catch((e) => {
       console.log("error")

      });
      
  }
  
  const getPetrollingDurationDetails = (id) => {
    setTimeLineLoading(true);
    axios
      .get(`${server_url}/patrolling-Duration/details/${id}`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((response) => {
        setPatrollingDetails(response.data.data)
        setTimeLineLoading(false);
      })
      .catch((e) => {
       console.log("error")

      });
  }

  const getAllGuards = () => {
    axios
      .get(`${server_url}/guard`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((response) => {
        setAllGuards(response.data.data);
        if(response.data.data)
        {
          getPetrolling("","","");
        }
      })
      .catch((e) => {
       console.log("error")

      });
  }
  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
    dispatch({
      type: PATROLLING_LOGS_SEARCH,
      payload: {
        searching: "",
      },
    });
    dispatch(deviceGetAll(sorting));
  };

  useEffect(() => {
    const searching = "&search=" + search;
    dispatch({
      type: PATROLLING_LOGS_SEARCH,
      payload: {
        searching: "",
      },
    });
    dispatch(patrollingLogsGetAll(searching));
  }, [search]);
console.log(patrollingDetails.length);
console.log("patrollingDetails->>>>>>>>>>>");
const getCurrentDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  let month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // padStart ensures two digits
  let day = currentDate.getDate().toString().padStart(2, '0'); // padStart ensures two digits
  return `${year}-${month}-${day}`; // Format: YYYY-MM-DD
};

  // return (
  //   <>
  //   <PatrollingLogsAdvanceSearch></PatrollingLogsAdvanceSearch>
  //   <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
  //     <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
  //       <Flex>
  //         <CardHeader p="6px 0px 22px 0px">
  //           <Text fontSize="xl" color={textColor} fontWeight="bold">
  //           Patrolling Logs
  //           </Text>
  //         </CardHeader>
  //         <Spacer />
  //         {/* <Flex justify="flex-end" style={{ padding: '10px' ,marginTop : '-5px' ,marginRight :'12px' }}> 
  //           <Link href={`#/admin/patrolling-logs-form`}>
  //           <IconButton
  //             colorScheme="blue"
  //             aria-label="Search database"
  //             icon={<AddIcon />}
  //           />
  //         </Link>
  //         </Flex> */}
  //         <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
  //           <DownloadTableExcel
  //             filename="Patrolling Logs Table"
  //             sheet="users"
  //             currentTableRef={tableRef.current}
  //           >
  //             <Button colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>
  //               Export Excel
  //             </Button>
  //           </DownloadTableExcel>
  //         </Flex>
  //         <CardHeader p="6px 0px 22px 0px">
  //           <Text fontSize="xl" color={textColor} fontWeight="bold">
  //             <InputGroup borderRadius="8px" w="200px">
  //               <InputLeftElement
  //                 children={
  //                   <IconButton
  //                     bg="inherit"
  //                     borderRadius="inherit"
  //                     _hover="none"
  //                     _active={{
  //                       bg: "inherit",
  //                       transform: "none",
  //                       borderColor: "transparent",
  //                     }}
  //                     _focus={{
  //                       boxShadow: "none",
  //                     }}
  //                     icon={
  //                       <SearchIcon
  //                         color={searchIconColor}
  //                         w="15px"
  //                         h="15px"
  //                       />
  //                     }
  //                   ></IconButton>
  //                 }
  //               />
  //               <Input
  //                 variant="search"
  //                 fontSize="xs"
  //                 bg={inputBg}
  //                 onKeyUp={(e) => {
  //                   setSearch(e.target.value);
  //                   if (e.target.value.length < 3) {
  //                     setSearch("");
  //                   }
  //                 }}
  //                 placeholder="Type here..."
  //               />
  //             </InputGroup>
  //           </Text>
  //         </CardHeader>
  //       </Flex>
  //       <CardBody pb={4}>
  //       <div style={{ overflowX: "auto" }}>
        
  //         {loading ? <Stack>
  //                   <Skeleton height='20px' />
  //                   <Skeleton height='20px' />
  //                   <Skeleton height='20px' />
  //                   <Skeleton height='20px' />
  //                   <Skeleton height='20px' />
  //                   <Skeleton height='20px' />
  //                   <Skeleton height='20px' />
  //                 </Stack>:(<Table variant="simple" color={textColor} ref={tableRef}>
  //           <Thead>
  //             <Tr my=".8rem" pl="0px" color="gray.400">
  //             <Th
  //                   pl="15px"
  //                   borderColor={borderColor}
  //                   color="gray.400"
  //                   cursor="pointer"
  //                 >
  //                  SNO
  //                 </Th>
  //               <Th
  //                 pl="0px"
  //                 borderColor={borderColor}
  //                 color="gray.400"
  //                 onClick={() => handleSort("name")}
  //                 cursor="pointer"
  //               >
  //                guard Name
  //                 {sortColumn === "name" && (
  //                   <Icon
  //                     as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
  //                     boxSize={4}
  //                     ml={1}
  //                   />
  //                 )}
  //               </Th>
              
                
                            
  //               <Th
  //                 pl="0px"
  //                 borderColor={borderColor}
  //                 color="gray.400"
  //                 onClick={() => handleSort("name")}
  //                 cursor="pointer"
  //               >
  //                Start-Date-Time
  //                 {sortColumn === "name" && (
  //                   <Icon
  //                     as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
  //                     boxSize={4}
  //                     ml={1}
  //                   />
  //                 )}
  //               </Th>
                            
  //               <Th
  //                 pl="0px"
  //                 borderColor={borderColor}
  //                 color="gray.400"
  //                 onClick={() => handleSort("name")}
  //                 cursor="pointer"
  //               >
  //                End-Date-Time
  //                 {sortColumn === "name" && (
  //                   <Icon
  //                     as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
  //                     boxSize={4}
  //                     ml={1}
  //                   />
  //                 )}
  //               </Th>
                            
  //               <Th
  //                 pl="0px"
  //                 borderColor={borderColor}
  //                 color="gray.400"
  //                 onClick={() => handleSort("name")}
  //                 cursor="pointer"
  //               >
  //                route Name
  //                 {sortColumn === "name" && (
  //                   <Icon
  //                     as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
  //                     boxSize={4}
  //                     ml={1}
  //                   />
  //                 )}
  //               </Th>

  //               <Th
  //                 pl="0px"
  //                 borderColor={borderColor}
  //                 color="gray.400"
  //                 onClick={() => handleSort("name")}
  //                 cursor="pointer"
  //               >
  //                no-Of-Scan
  //                 {sortColumn === "name" && (
  //                   <Icon
  //                     as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
  //                     boxSize={4}
  //                     ml={1}
  //                   />
  //                 )}
  //               </Th>
  //               <Th
  //                 pl="0px"
  //                 borderColor={borderColor}
  //                 color="gray.400"
  //                 onClick={() => handleSort("name")}
  //                 cursor="pointer"
  //               >
  //                scan-Log
  //                 {sortColumn === "name" && (
  //                   <Icon
  //                     as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
  //                     boxSize={4}
  //                     ml={1}
  //                   />
  //                 )}
  //               </Th>
  //               <Th
  //                 pl="0px"
  //                 borderColor={borderColor}
  //                 color="gray.400"
  //                 onClick={() => handleSort("name")}
  //                 cursor="pointer"
  //               >
  //                missed-Scan-Log
  //                 {sortColumn === "name" && (
  //                   <Icon
  //                     as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
  //                     boxSize={4}
  //                     ml={1}
  //                   />
  //                 )}
  //               </Th>
  //               {/* <Th
  //                 pl="0px"
  //                 borderColor={borderColor}
  //                 color="gray.400"
  //                 onClick={() => handleSort("name")}
  //                 cursor="pointer"
  //               >
  //                patrolling Logs
  //                 {sortColumn === "name" && (
  //                   <Icon
  //                     as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
  //                     boxSize={4}
  //                     ml={1}
  //                   />
  //                 )}
  //               </Th> */}
                
  //             </Tr>
  //           </Thead>
  //           <Tbody>
  //             { patrollingLogsList ? (patrollingLogsList.map((row, index, arr) => {
  //                 return (
  //                   <PatrollingLogsTableRow
  //                    serialnumber={((currentPage - 1)*limit) + (index+1)}
  //                     guardName={row.guard.name}
  //                     startDateTime = {row.startDateTime}
  //                     endDateTime = {row.endDateTime}
  //                     routeName = {row.route.name}
  //                     noOfScan = {row.noOfScan}
  //                     scanLog = {row.scanLog.length !== 0 ? row.scanLog : [] }
  //                     missedScanLog = {row.missedScanLog.length !== 0 ? row.missedScanLog : [] }
  //                     // patrollingLogs={row.patrollingLogs.length !== 0 ? row.patrollingLogs : [] }
  //                     isLast={index === arr.length - 1 ? true : false}
  //                     id={row._id}
  //                     key={row._id}
  //                   />
  //                 );
  //             })):(<h1></h1>)
  //             }
  //           </Tbody>
  //         </Table>)}
  //         </div>
  //         {patrollingLogsList.length === 0 ? (
  //           <></>
  //         ) : (
  //           <PatrollingLogsPagination
  //             totalPages={response.totalPages}
  //             currentPage={response.currentPage}
  //             perPage={response.perPage}
  //             sortBy={sortColumn}
  //             sortOrder={sortOrder}
  //             handlePageClick={(data) => {
  //               const selectedPage = data.selected + 1;
  //               dispatch(patrollingLogsGetAll(`&page=${selectedPage}`));
  //             }}
  //           />
  //         )}
  //       </CardBody>
  //     </Card>
  //   </Flex>
  //   </>
  // );
  var ii = 0;
 return (<>
  <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Advance Search
          </Text>
          <SimpleGrid minChildWidth="120px" spacing="40px">
            <FormControl >
            <FormLabel>Start Date</FormLabel>
              <Input
                type="date"
                label="date "
                // value={startDate}
                defaultValue={getCurrentDate()}
                onChange={(e) => {
                    setStartDate(e.target.value);
                    setEndDate(e.target.value);
                }}
                
                 
              />
            </FormControl>
            <FormControl >
              <FormLabel>Guard</FormLabel>
              <Select
                placeholder="All Guard"
                onChange={(e) => {
                  
                  setGuard(e.target.value);
                }}
                value={guard}
              >
                {allGuards ? (allGuards.map((item, index) => (
                  <option key={item._id} value={item._id}>{item.name}</option>
                ))) : (<></>)}
              </Select>
              
            </FormControl>
            
          </SimpleGrid>
          
          <Center>
            {loading ? (
              <Spinner mt="24px" size="lg" />
            ) : (
                <><Button colorScheme="blue" mt="24px"  onClick={() => 
                {
                  getPetrolling(startDate,endDate,guard);
                }}>
                Submit
              </Button>
              <Button colorScheme="green" mt="24px" ml="10px"  >
                Reset
              </Button>
              </>
              
              
            )}
          </Center>
        </CardHeader>
        <CardBody></CardBody>
      </Card>
    </Flex>

    <Flex pt={{ base: "120px", md: "75px" }}>
    <Card flex='1' pb="0px">
      
      <CardHeader p="6px 0px 22px 0px">
        {/* <Text fontSize="xl" color={textColor} fontWeight="bold">
          Patrolling Route
        </Text> */}
        {pageLoading?<> <Stack>
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' /></Stack></>
                    :<Accordion allowToggle overflowX={{ sm: "scroll", xl: "hidden" }}  height={screenHeight/2}>
        <RadioGroup>
        {(getPatrollings.map((row, index, arr) => {
         
          ii++;
                  return (
                    
                    <AccordionItem>
                      
                      <h2>
                        <AccordionButton _expanded={{ bg: 'tomato', color: 'white' }}>
                          <Box as="span" flex='1' textAlign='left'>
                          <Heading size='sm' textTransform='uppercase'>
                            {row._id.name}
                          </Heading>
                            
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel mt={5}>
                      
                          <Stack divider={<StackDivider />} spacing='4' >
                            { (row.guards.patrollingData.map((row, index, arr) => {
                              return(
                                 <Box onClick={() => getPetrollingDurationDetails(row.patrollingDuration._id)}>
                                 
                                    <Radio value={(index+ii).toString()}>
                                  <Heading size='xs' textTransform='uppercase'>
                                    Patrolling {index+1}
                                  </Heading>
                                  <Text pt='2' fontSize='sm'>
                                    Patrolled by {row.guardName} ({row.patrollingDuration.startTime} - {row.patrollingDuration.endTime})
                                  </Text>
                                  </Radio>
                                  
                                </Box>   
                              );
                              }))}
                            </Stack>
                      </AccordionPanel>
                      
                    </AccordionItem>
                    
                  );
              }))}
          </RadioGroup>
        </Accordion>}
        
      </CardHeader>
      <CardBody>
      </CardBody>
    </Card>
    <Spacer />
    <Card flex='4' overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
      
      <CardHeader p="6px 0px 22px 0px">
        {/* <Text fontSize="xl" color={textColor} fontWeight="bold">
          Patrolling Route
        </Text> */}
        
        
      </CardHeader>
      <CardBody>
      <Tabs>
          <TabList>
            <Tab>Timeline View</Tab>
            {/* <Tab>Two</Tab>
            <Tab>Three</Tab> */}
          </TabList>

          {pageLoading || timeLineLoading?<> <Stack>
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' /></Stack></>
                    :<TabPanels>
            <TabPanel height={screenHeight/2}>
            {patrollingDetails.length != [] ?<>
              
                          <Card>
                            <CardHeader mb={5}>
                              <Heading size='md'>{patrollingDetails[0].route.name}</Heading>
                            </CardHeader>
                          <Spacer></Spacer>
                            {/* <CardBody> */}
                            <Stack divider={<StackDivider />} spacing='2'>
                          {patrollingDetails[0]['scanLog'].map((row, index, arr) => {
                              return(
                                <Flex spacing='4'>
                                <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
                                  <Avatar size='sm' name='Sacnned' src='https://ik.imagekit.io/hzrggwyw5/success.png?updatedAt=1711546144941' />

                                  <Box>
                                    <Text>{row.floorName}</Text>
                                  </Box>
                                </Flex>
                                <Text>Scanned</Text>
                                </Flex>   
                              );
                              })}
                          
                          {patrollingDetails[0]['missedScanLog'].map((row, index, arr) => {
                            
                              return(
                                <Flex spacing='4'>
                              <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
                                <Avatar size='sm' name='Missed' src='https://ik.imagekit.io/hzrggwyw5/missed.png?updatedAt=1711546120097' />

                                <Box>
                                  <Text>{row.floorName}</Text>
                                </Box>
                              </Flex>
                              <Text>Missed</Text>
                              </Flex>  
                              );
                              
                              
                              
                              })}
                              </Stack>
                            {/* </CardBody> */}
                          </Card>
            </>:<></>}

                          
            </TabPanel>
            {/* <TabPanel>
              <p>two!</p>
            </TabPanel>
            <TabPanel>
              <p>three!</p>
            </TabPanel> */}
          </TabPanels>}
        </Tabs>
      </CardBody>
    </Card>
  </Flex>
    
  </>);
};

export default PatrollingLogsTable;
