// Chakra imports
import {
    Box,
    Button,
    Center,
    Flex,
    Input,
    SimpleGrid,
    Spacer,
    Stack,
    Text,
    Wrap,
    WrapItem,
    useColorModeValue
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from 'react-redux';
  import { shiftAddOrUpdate } from '../../actions/shiftAction.js';
  import { useToast } from '@chakra-ui/react'
  import { useParams } from "react-router-dom/cjs/react-router-dom.min";
  import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { server_url } from '../../constants/types';
  
  import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
  } from '@chakra-ui/react'
  import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'
import { SHIFT_CLEAR_SNACKBAR } from "constants/types";
  
  function Shift() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [name, setName] = useState("");
   
    const [status, setStatus] = useState("");
    const [Description, setDescription] = useState("");
  
    const dispatch = useDispatch();
    const toast = useToast();
  
    const statuses = ['success', 'error', 'warning', 'info'];
    const responseStatus = useSelector(state => state.shift.status);
    const responseMessage = useSelector(state => state.shift.text);
    const loading = useSelector(state => state.shift.loading);
    const shiftDetail = useSelector(state => state.shift.shiftDetail);
    const showSnackbar = useSelector(state => state.shift.showSnackbar);
    const history = useHistory()
    const params = useParams();

    
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === 'OK' && loading === false) {
          console.log(shiftDetail);
          setName(shiftDetail.name);
          setStatus(shiftDetail.status);
          setDescription(shiftDetail.description);

          
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'success');
          }
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'error');
          }
        }
      }
  
      dispatch({
        type: SHIFT_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        }
      })
    }, [loading]);
  
    useEffect(() => {
      console.log("params");
      const shiftId = params.id;
      const shift = {
        _id : shiftId
      };
      dispatch(shiftAddOrUpdate(shift,'edit'));
    }, []);
  
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      })
    };
    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
    }, [])
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              City View 
            </Text>
          </CardHeader>
          <CardBody>
            {loading ? <Stack>
              <Skeleton height='20px' />
              <Skeleton height='20px' />
              <Skeleton height='20px' />
            </Stack>
              :
              <TableContainer>
                <Table variant='simple'>
                  <Tbody>
                    <Tr>
                      <Td>Name</Td>
                      <Td>{name}</Td>
                    </Tr>
                    <Tr>
                      <Td>Status</Td>
                      <Td>{status?"Active" :"Inactive"}</Td>
                    </Tr>
                    <Tr>
                      <Td>Description</Td>
                      <Td>{Description}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>}
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default Shift;