import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";

function CustomInput({ type, label, value, onChange,isDisabled, regex="", submitted,required=false ,isEmpty=false}) {
  const [error, setError] = useState("");

  useEffect(() => {
    if (submitted) {
      validateInput(value);
    }
  }, [value, submitted]);
const validateInput = (inputValue) => {
  setError("");
  if (inputValue === "" && !isEmpty) {
    setError(`${label} is required.`);
  } else if (regex && typeof regex === "object" && !regex.test(inputValue)) {
    // Ensure regex is provided and is an object (regular expression)
    if (type === "email") {
      setError("Invalid email format.");
    } else if (type === "number" && label === "Phone Number") {
      setError("Phone number must be 10 digits.");
    } else if (type === "number" && label === "Bus No") {
      setError("Bus number must be 4 digits.");
    } else {
      setError("Invalid input.");
    }
  }
};

  

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    onChange(inputValue);
    validateInput(inputValue);
    if (submitted) {
      validateInput(inputValue);
    }
  };

  const handleBlur = () => {
    if (submitted) {
      validateInput(value);
    }
  };

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>{label} {required ?  <span style={{ color: 'red' }}>*</span> :<></>} </FormLabel>
      
      <Input
        type={type}
        isDisabled={isDisabled}
        value={value}
        onChange={handleInputChange}
        onBlur={handleBlur}
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
}

export default CustomInput;
