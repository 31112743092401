import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';import React, { useEffect, useState } from 'react';
import {
  Flex,
  Input,
  SimpleGrid,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Button,
  Center,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useDispatch, useSelector } from 'react-redux';
import { patrollingRouteClearShowSnackbar, patrollingRouteAddOrUpdate } from '../../actions/PatrollingRouteAction';
import { useToast } from '@chakra-ui/react';
import { PATROLLING_ROUTE_CLEAR_SNACKBAR } from 'constants/types';
import { Spinner } from '@chakra-ui/react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import dataConstant from 'constants/dataConstant';

function PatrollingRouteUpdateForm() {
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('');
  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorDescription, setIsErrorDescription] = useState(false);
  const [isErrorStatus, setIsErrorStatus] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.patrollingRoute.status);
  const responseMessage = useSelector(state => state.patrollingRoute.text);
  const loading = useSelector(state => state.patrollingRoute.loading);
  const patrollingRouteDetail = useSelector((state) => state.patrollingRoute.patrollingRouteDetail);
  const showSnackbar = useSelector(state => state.patrollingRoute.showSnackbar);

  const params = useParams();
  const id = params.id;
console.log("responseMessage");
console.log(responseMessage);
  useEffect(() => {
    const patrollingRoute = {
      _id: id,
    };
    dispatch(patrollingRouteAddOrUpdate(patrollingRoute, 'view'));
  }, [id]);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsErrorName(name === '');
    // setIsErrorDescription(description === '');
    setIsErrorStatus(status === '');

    if (name !== '' && status !== '') {
      const patrollingRouteData = {
        _id: id,
        name,
        description,
        status,
      };

      dispatch(patrollingRouteAddOrUpdate(patrollingRouteData, 'edit'));
    }
  };

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        setName(patrollingRouteDetail.name);
        setDescription(patrollingRouteDetail.description);
        setStatus(patrollingRouteDetail.status);
  
        if (responseMessage !== "") {
          toastFunction(responseMessage, "success");
        }
      } else if (responseStatus === "NOK" && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, "error");
        }
      }
  
      dispatch(patrollingRouteClearShowSnackbar());
    }
  }, [showSnackbar, responseStatus, loading, responseMessage]);
  

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Location Update Form
          </Text>
          <br/>
          <SimpleGrid minChildWidth='120px' spacing='40px'>
            <FormControl isInvalid={isErrorName}>
              <FormLabel>Name</FormLabel>
              <Input
                type='text'
                value={name}
                onChange={(e) => {
                  setIsErrorName(e.target.value === '')
                  setName(e.target.value)
                }}
              />
              {!isErrorName ? (
                <></>
              ) : (
                <FormErrorMessage>Name is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorDescription}>
              <FormLabel>Description</FormLabel>
              <Input
                type='text'
                value={description}
                onChange={(e) => {
                  // setIsErrorDescription(e.target.value === '')
                  setDescription(e.target.value)
                }}
              />
              {!isErrorDescription ? (
                <></>
              ) : (
                <FormErrorMessage>Description is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorStatus}>
              <FormLabel>Status</FormLabel>
              <Select
                placeholder="Select status"
                value={status}
                onChange={(e) => {
                  setIsErrorStatus(e.target.value === '')
                  setStatus(e.target.value)
                }}
              >
                {dataConstant.status.map((element, index) => {
                  return (
                    <option key={element['id']} value={element['value']}>
                      {element['name']}
                    </option>
                  );
                })}
              </Select>
              {!isErrorStatus ? (
                <></>
              ) : (
                <FormErrorMessage>Status is required.</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>
          <Center>
            {loading ? (
              <Spinner mt='24px' size='lg' />
            ) : (
                <div>
              <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                Submit
              </Button>

                </div>
              
            )}
          </Center>
        </CardHeader>
        <CardBody></CardBody>
      </Card>
    </Flex>
  );
}

export default PatrollingRouteUpdateForm;
