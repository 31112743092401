import axios from 'axios';
import {
  PARKINGDEPARTMENT_LOADING,
  PARKINGDEPARTMENT_GET_ALL_SUCCESS,
  PARKINGDEPARTMENT_ERROR,
  PARKINGDEPARTMENT_CLEAR_SNACKBAR
} from '../constants/types';

import { server_url } from '../constants/types';

export const parkingdepartmentGetAll = (pagination = '') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PARKINGDEPARTMENT_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/parkingdepartment?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {

          dispatch({
            type: PARKINGDEPARTMENT_GET_ALL_SUCCESS,
            payload: {
              parkingdepartmentList: response.data.data,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all parkingdepartment data successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: PARKINGDEPARTMENT_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting parkingdepartment data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const parkingdepartmentAddOrUpdate = (parkingdepartment, state) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PARKINGDEPARTMENT_LOADING,
        payload: {
          loading: true
        }
      });
      if (state === 'add') {
        axios
          .post(`${server_url}/parkingdepartment`, parkingdepartment, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/parkingdepartment`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {
                console.log(responseF.data['message'])
                dispatch({
                  type: PARKINGDEPARTMENT_GET_ALL_SUCCESS,
                  payload: {
                    parkingdepartmentList: response.data.data,
                    response: response.data,
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: PARKINGDEPARTMENT_ERROR,
                  payload: {
                    text: 'Error occurred during getting parkingdepartment data.',
                    status: 'error',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: PARKINGDEPARTMENT_ERROR,
              payload: {
                text: 'Error occurred during adding parkingdepartment data.',
                status: 'error',
                loading: false
              }
            });
            resolve();
          });
      } else if (state === 'edit') {
        axios
          .put(`${server_url}/parkingdepartment/${parkingdepartment._id}`, parkingdepartment, {
            headers: {
              
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/parkingdepartment`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {
                console.log("parkingdepartmentDetail reset");
                console.log(responseF.data['data']);
                dispatch({
                  type: PARKINGDEPARTMENT_GET_ALL_SUCCESS,
                  payload: {
                    parkingdepartmentList: response.data.data,
                    response: response.data,
                    loading: false,
                    status: responseF.data['status'],
                    parkingdepartmentDetail: responseF.data['data'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: PARKINGDEPARTMENT_ERROR,
                  payload: {
                    text: 'Error occurred during getting parkingdepartment data.',
                    status: 'error',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: PARKINGDEPARTMENT_ERROR,
              payload: {
                text: 'Error occurred during updating parkingdepartment data.',
                status: 'error',
                loading: false
              }
            });
            resolve();
          });
      } else if (state === 'view') {
        axios
          .get(`${server_url}/parkingdepartment/${parkingdepartment._id}`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {
            console.log("PARKINGDEPARTMENT_GET_ALL_SUCCESS");
            console.log(responseF.data['data']);
            axios
              .get(`${server_url}/parkingdepartment`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {

               
                dispatch({
                  type: PARKINGDEPARTMENT_GET_ALL_SUCCESS,
                  payload: {
                    parkingdepartmentList: response.data.data,
                    response: response.data,
                    parkingdepartmentDetail: responseF.data['data'],
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: PARKINGDEPARTMENT_ERROR,
                  payload: {
                    text: 'Error occurred during getting parkingdepartment data.',
                    status: 'error',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: PARKINGDEPARTMENT_ERROR,
              payload: {
                text: 'Error occurred during updating parkingdepartment data.',
                status: 'error',
                loading: false
              }
            });
            resolve();
          });
      }
    });
  };
};

export const parkingdepartmentDelete = (parkingdepartment) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PARKINGDEPARTMENT_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .delete(`${server_url}/parkingdepartment/${parkingdepartment}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((responseF) => {
          axios
            .get(`${server_url}/parkingdepartment`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
            .then((response) => {
              dispatch({
                type: PARKINGDEPARTMENT_GET_ALL_SUCCESS,
                payload: {
                  parkingdepartmentList: response.data.data,
                  response: response.data,
                  parkingdepartmentDetail: responseF.data['data'],
                  loading: false,
                  status: responseF.data['status'],
                  text: responseF.data['message']
                }
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: PARKINGDEPARTMENT_ERROR,
                payload: {
                  text: 'Error occurred during getting parkingdepartment data.',
                  status: 'error',
                  loading: false
                }
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: PARKINGDEPARTMENT_ERROR,
            payload: {
              text: 'Error occurred during deleting parkingdepartment data.',
              status: 'error',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const parkingdepartmentClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: PARKINGDEPARTMENT_CLEAR_SNACKBAR
    });
  };
};
