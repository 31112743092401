import { 
    PLANTADMIN_LOADING, 
    PLANTADMIN_GET_ALL_SUCCESS, 
    PLANTADMIN_ERROR,
    PLANTADMIN_CLEAR_SNACKBAR
} from '../constants/types';

const initialState = {
    plantList: [],
    plantDetail: {},
    loginDetail : {},
    response:"",
    loading: false,
    status: "success",
    text: "",
    showSnackbar: false
};
const PlantReducer = (state = initialState, action) => {
    switch (action.type) {
        case PLANTADMIN_CLEAR_SNACKBAR: 
            return {
                ...state,
                showSnackbar: false
            }
        case PLANTADMIN_LOADING: 
            return {
                ...state,
                loading: true,
            }
        case PLANTADMIN_ERROR:
            return {
                ...state,
                plantList: [],
                plantDetail : {},
                loginDetail : {},
                loading: false,
                status: action.payload.status,
                text: action.payload.text,
                showSnackbar: true
            }
        case PLANTADMIN_GET_ALL_SUCCESS: console.log(action.payload.plantList);console.log("action.payload.plantList")
            return {
                ...state,
                plantList: action.payload.plantList,
                plantDetail: action.payload.plantDetail,
                loginDetail: action.payload.loginDetail,
                response:action.payload.response,
                loading: false,
                status: action.payload.status,
                text: action.payload.text,
                showSnackbar: true
            }
        default: 
            return state || {};
    }
}
console.log("state plant")
console.log(initialState.plantList)

export default PlantReducer;