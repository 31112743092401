import {
    REPORTINCEDENT_MODULE_LOADING,
    REPORTINCEDENT_MODULE_GET_ALL_SUCCESS,
    REPORTINCEDENT_MODULE_ERROR,
    REPORTINCEDENT_MODULE_CLEAR_SNACKBAR
  } from '../constants/types';
  
  const initialState = {
    response: {},
    reportIncedentModuleList: [],
    reportIncedentModuleDetail: {},
    loading: false,
    status: 'success',
    text: '',
    showSnackbar: false
  };
  
  const reportIncedentModuleReducer = (state = initialState, action) => {
    switch (action.type) {
      case REPORTINCEDENT_MODULE_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case REPORTINCEDENT_MODULE_LOADING:
        return {
          ...state,
          loading: true
        };
      case REPORTINCEDENT_MODULE_ERROR:
        return {
          ...state,
          reportIncedentModuleList: [],
          response: {},
          reportIncedentModuleDetail: {},
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case REPORTINCEDENT_MODULE_GET_ALL_SUCCESS:
        return {
          ...state,
          reportIncedentModuleList: action.payload.reportIncedentModuleList,
          response: action.payload.response,
          reportIncedentModuleDetail: action.payload.reportIncedentModuleDetail,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      default:
        return state || {};
    }
  };
  
  export default reportIncedentModuleReducer;