const {
    SHIFT_LOADING,
    SHIFT_GET_ALL_SUCCESS,
    SHIFT_ERROR,
    SHIFT_CLEAR_SNACKBAR,
    SHIFT_SEARCH
  } = require('../constants/types');
  
  const initialState = {
    response: {},
    shiftList: [],
    shiftDetail: {},
    loading: false,
    status: 'success',
    text: '',
    showSnackbar: false,
    limit: 0,  // Add missing properties
    page: 0,
    totalPages: 0,
    searching: ""  // Add missing properties
  };
  
  const shiftReducer = (state = initialState, action) => {
    switch (action.type) {
      case SHIFT_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case SHIFT_LOADING:
        return {
          ...state,
          loading: true
        };
      case SHIFT_ERROR:
        return {
          ...state,
          shiftList: [],
          response: {},
          shiftDetail: {},
          loading: false,
          limit: 0,  // Add missing properties
          page: 0,
          totalPages: 0,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case SHIFT_GET_ALL_SUCCESS:
        return {
          ...state,
          shiftList: action.payload.shiftList,
          response: action.payload.response,
          shiftDetail: action.payload.shiftDetail,
          loading: false,
          limit: action.payload.limit,
          page: action.payload.page,
          totalPages: action.payload.totalPages,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
        case SHIFT_SEARCH: // Add the new case
        return {
          ...state,
          searching: action.payload.searching
        };
      default:
        return state || {};
    }
  };
  
  // module.exports = shiftReducer;
  export default shiftReducer;
  