const {
    DEPARTMENT_LOADING,
    DEPARTMENT_GET_ALL_SUCCESS,
    DEPARTMENT_ERROR,
    DEPARTMENT_CLEAR_SNACKBAR
  } = require('../constants/types');
  
  const initialState = {
    response: {},
    departmentList: [],
    departmentDetail: {},
    loading: false,
    status: 'success',
    text: '',
    showSnackbar: false,
    limit: 0,  // Add missing properties
    page: 0,
    totalPages: 0,
  };


  const departmentReducer = (state = initialState, action) => {
    switch (action.type) {
      case DEPARTMENT_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case DEPARTMENT_LOADING:
        return {
          ...state,
          loading: true
        };
      case DEPARTMENT_ERROR:
        return {
          ...state,
          departmentList: [],
          response: {},
          departmentDetail: {},
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true,
          limit: 0,  // Add missing properties
          page: 0,
          totalPages: 0,
        };
      case DEPARTMENT_GET_ALL_SUCCESS:
        return {
          ...state,
          departmentList: action.payload.departmentList,
          response: action.payload.response,
          departmentDetail: action.payload.departmentDetail,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true,
          limit: action.payload.limit,
          page: action.payload.page,
          totalPages: action.payload.totalPages,
        };
      default:
        return state || {};
    }
  };
  
  export default departmentReducer;
  // module.exports = departmentReducer;
  