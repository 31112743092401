import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useDispatch, useSelector } from "react-redux";

import { useToast } from '@chakra-ui/react';
import { useParams } from "react-router-dom";
import { GUARD_CLEAR_SNACKBAR } from "constants/types";
import {
  Table,
  Tbody,
  Td,
  Tr,
  TableContainer,
} from '@chakra-ui/react';
import { Skeleton, SkeletonText } from '@chakra-ui/react';
import { guardAddOrUpdate } from "actions/GaurdAction";
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';

function GuardView() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name, setName] = useState("");
  const [photo, setPhoto] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [guardId, setGuardId] = useState("");
  const [checkpost, setCheckpost] = useState("");
  const [test, setTest] = useState("")
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [locality, setLocality] = useState("");
  const [state, setState] = useState("");

  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const responseStatus = useSelector(state => state.guard.status);
  const responseMessage = useSelector(state => state.guard.text);
  const loading = useSelector(state => state.guard.loading);
  const guardDetail = useSelector(state => state.guard.guardDetail);
  const showSnackbar = useSelector(state => state.guard.showSnackbar);

  console.log("123456")
  console.log(checkpost)

  const params = useParams();
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        setName(guardDetail.name);
        setPhoto(guardDetail.photo);
        setNumber(guardDetail.number);
        setEmail(guardDetail.email);
        setGuardId(guardDetail.guardId);
        setCheckpost(guardDetail.checkpost);
        guardDetail.Address.map((item) => {
          setLine1(item.line1);
          setLine2(item.line2);
          setPostalCode(item.postalCode);
          setLocality(item.locality);
          setState(item.state)
        });

        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }

    dispatch({
      type: GUARD_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    });
  }, [loading]);

  useEffect(() => {
    const guardId = params.id;
    const guard = {
      _id: guardId
    };
    dispatch(guardAddOrUpdate(guard, 'view'));
  }, []);
  
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  console.log("guardDetail")
  console.log(guardDetail)

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Guard View
          </Text>
          <br/>
        </CardHeader>
        <CardBody>
          {loading ? <SkeletonText noOfLines={3} spacing="4" /> :
            <TableContainer>
              <Table variant='simple'>
                <Tbody>
                  <Tr>
                    <Td>Name</Td>
                    <Td>{name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Photo</Td>
                    <Td>
                      <Flex direction="column">
                        <Image
                          boxSize='100px'
                          objectFit='cover'
                          src={photo}
                          alt='Guard Image'
                        />
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Phone Number</Td>
                    <Td>{number}</Td>
                  </Tr>
                  <Tr>
                    <Td>Email</Td>
                    <Td>{email}</Td>
                  </Tr>
                  <Tr>
                    <Td>Guard ID</Td>
                    <Td>{guardId}</Td>
                  </Tr>
                  <Tr>
                    <Td>Checkpost</Td>
                    <Td>
                      {Array.isArray(checkpost) ? (
                        checkpost.map((ele) => <li key={ele.name}>{ele.name}</li>)
                      ) : (
                        "No checkpost data available"
                      )}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Address</Td>
                    <Td>
                      <ul>
                        <li>Line 1: {line1}</li>
                        <li>Line 2: {line2}</li>
                        <li>PostalCode : {postalCode}</li>
                        <li>Locality : {locality}</li>
                        <li>State : {state}</li>
                      </ul>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          }
        </CardBody>
      </Card>
    </Flex>
  );
}

export default GuardView;
