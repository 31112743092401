const {
    DEVICE_LOADING,
    DEVICE_GET_ALL_SUCCESS,
    DEVICE_ERROR,
    DEVICE_CLEAR_SNACKBAR
  } = require('../constants/types');
  
  const initialState = {
    response: {},
    deviceList: [],
    deviceDetail: {},
    loading: false,
    status: 'success',
    text: '',
    showSnackbar: false,
    deviceConnectionList: [],
    connectionLogs: [],
  };
  
  const deviceReducer = (state = initialState, action) => {
    switch (action.type) {
      case DEVICE_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case DEVICE_LOADING:
        return {
          ...state,
          loading: true
        };
      case DEVICE_ERROR:
        return {
          ...state,
          deviceList: [],
          response: {},
          deviceDetail: {},
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true,
          deviceConnectionList: [],
          connectionLogs: [],
        };
      case DEVICE_GET_ALL_SUCCESS:
        return {
          ...state,
          deviceList: action.payload.deviceList,
          deviceConnectionList: action.payload.deviceConnectionList,
          connectionLogs: action.payload.connectionLogs,
          response: action.payload.response,
          deviceDetail: action.payload.deviceDetail,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
          
        };
      default:
        return state || {};
    }
  };
  
  export default deviceReducer;
  // module.exports = deviceReducer;
  