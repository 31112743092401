import React, { useEffect, useState } from 'react';
import {
  Flex,
  Input,
  SimpleGrid,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Button,
  Center,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useDispatch, useSelector } from 'react-redux';
import { busRouteClearShowSnackbar, busRouteAddOrUpdate } from '../../actions/busRouteAction';
import { useToast } from '@chakra-ui/react';
import { BUS_ROUTE_CLEAR_SNACKBAR } from 'constants/types';
import { Spinner } from '@chakra-ui/react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import dataConstant from 'constants/dataConstant';

function BusRouteUpdateForm() {
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('');
  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorDescription, setIsErrorDescription] = useState(false);
  const [isErrorStatus, setIsErrorStatus] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.busRoute.status);
  const responseMessage = useSelector(state => state.busRoute.text);
  const loading = useSelector(state => state.busRoute.loading);
  const busRouteDetail = useSelector((state) => state.busRoute.busRouteDetail);
  const showSnackbar = useSelector(state => state.busRoute.showSnackbar);

  const params = useParams();
  const id = params.id;

  useEffect(() => {
    const busRoute = {
      _id: id,
    };
    dispatch(busRouteAddOrUpdate(busRoute, 'view'));
  }, [id]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsErrorName(name === '');
    setIsErrorDescription(description === '');
    setIsErrorStatus(status === '');

    if (name !== '' && status !== '' && description !== '') {
      const busRouteData = {
        _id: id,
        name,
        description,
        status,
      };

      dispatch(busRouteAddOrUpdate(busRouteData, 'edit'));
    }
  };

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        setName(busRouteDetail.name);
        setDescription(busRouteDetail.description);
        setStatus(busRouteDetail.status);

        if (responseMessage !== "") {
          toastFunction(responseMessage, "success");
        }
      } else if (responseStatus === "NOK" && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, "error");
        }
      }

      dispatch(busRouteClearShowSnackbar());
    }
  }, [showSnackbar, responseStatus, loading, responseMessage]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Update Form
          </Text>
          <SimpleGrid minChildWidth='120px' spacing='40px'>
            <FormControl isInvalid={isErrorName}>
              <FormLabel>Name</FormLabel>
              <Input
                type='text'
                value={name}
                onChange={(e) => {
                  setIsErrorName(e.target.value === '')
                  setName(e.target.value)
                }}
              />
              {!isErrorName ? (
                <></>
              ) : (
                <FormErrorMessage>Name is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorDescription}>
              <FormLabel>Description</FormLabel>
              <Input
                type='text'
                value={description}
                onChange={(e) => {
                  setIsErrorDescription(e.target.value === '')
                  setDescription(e.target.value)
                }}
              />
              {!isErrorDescription ? (
                <></>
              ) : (
                <FormErrorMessage>Description is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorStatus}>
              <FormLabel>Status</FormLabel>
              <Select
                placeholder="Select status"
                value={status}
                onChange={(e) => {
                  setIsErrorStatus(e.target.value === '')
                  setStatus(e.target.value)
                }}
              >
                {dataConstant.status.map((element, index) => {
                  return (
                    <option key={element['id']} value={element['value']}>
                      {element['name']}
                    </option>
                  );
                })}
              </Select>
              {!isErrorStatus ? (
                <></>
              ) : (
                <FormErrorMessage>Status is required.</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>
          <Center>
            {loading ? (
              <Spinner mt='24px' size='lg' />
            ) : (
              <div>
                <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                  Submit
                </Button>
              </div>
            )}
          </Center>
        </CardHeader>
        <CardBody></CardBody>
      </Card>
    </Flex>
  );
}

export default BusRouteUpdateForm;
