import React, { useEffect, useState } from 'react';
import {
  Flex,
  Input,
  SimpleGrid,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Button,
  Center,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useDispatch, useSelector } from 'react-redux';
import { floorClearShowSnackbar, floorAddOrUpdate } from '../../actions/floorAction';
import { useToast } from '@chakra-ui/react';
import { FLOOR_CLEAR_SNACKBAR } from 'constants/types';
import { Spinner } from '@chakra-ui/react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import dataConstant from 'constants/dataConstant';
import {patrollingRouteGetAll} from '../../actions/PatrollingRouteAction'
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
function FloorUpdateForm() {
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(""); // Initialize status as a string
  const [route , setRoute] = useState('');
  const [time , setTime] = useState('');
  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorDescription, setIsErrorDescription] = useState(false);
  const [isErrorStatus, setIsErrorStatus] = useState(false);
  const [isErrorRoute , setIsErrorRoute] = useState(false);
  const [isErrorTime , setIsErrorTime] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.floor.status);
  const responseMessage = useSelector(state => state.floor.text);
  const loading = useSelector(state => state.floor.loading);
  const floorDetail = useSelector((state) => state.floor.floorDetail);
  const showSnackbar = useSelector(state => state.floor.showSnackbar);
  const patrollingRouteList = useSelector((state) => state.patrollingRoute.patrollingRouteList);

  const params = useParams();
  const id = params.id;
console.log("floorDetail1234567890")
console.log(floorDetail)
  useEffect(() => {
    const floor = {
      _id: id,
    };
    dispatch(floorAddOrUpdate(floor, 'view'));
  }, [id]);

  useEffect(()=>{
    dispatch(patrollingRouteGetAll());
  },[]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsErrorName(name === '');
    // setIsErrorDescription(description === '');
    setIsErrorStatus(status === '');
    setIsErrorRoute(route === '');
    setIsErrorTime(time === '');

    if (name !== '' && status !== '' && route !== '' && time !== '') {
      const floorData = {
        _id: id,
        name : name,
        description : description,
        status : status,
        patrollingRoute : route,
        time : time,
      };

      dispatch(floorAddOrUpdate(floorData, 'edit'));
    }
  };
  
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        setName(floorDetail.name);
        setDescription(floorDetail.description);
        setStatus(floorDetail.status);
        setRoute(floorDetail.patrollingRoute);
        setTime(floorDetail.time);
  
        if (responseMessage !== "") {
          toastFunction(responseMessage, "success");
        }
      } else if (responseStatus === "NOK" && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, "error");
        }
      }
  
      dispatch(floorClearShowSnackbar());
    }
  }, [showSnackbar, responseStatus, loading, responseMessage]);
  
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
           Sub Location Update Form
          </Text>
          <br/>
          <SimpleGrid minChildWidth='120px' spacing='40px'>
            <FormControl isInvalid={isErrorName}>
              <FormLabel>Name</FormLabel>
              <Input
                type='text'
                value={name}
                onChange={(e) => {
                  setIsErrorName(e.target.value === '')
                  setName(e.target.value)
                }}
              />
              {!isErrorName ? (
                <></>
              ) : (
                <FormErrorMessage>Name is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorDescription}>
              <FormLabel>Description</FormLabel>
              <Input
                type='text'
                value={description}
                onChange={(e) => {
                  // setIsErrorDescription(e.target.value === '')
                  setDescription(e.target.value)
                }}
              />
              {!isErrorDescription ? (
                <></>
              ) : (
                <FormErrorMessage>Description is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorRoute}>
            <FormLabel>Patrolling Route</FormLabel>
                <Select
                  placeholder="-- select Patrolling Route  --"
                  defaultValue={route}
                  onChange={(e) => {
                    setIsErrorRoute(e.target.value === '');
                    setRoute(e.target.value);
                  }}
                  
                >
                {patrollingRouteList ? (patrollingRouteList.map((item, index) => (
                    <option key={item._id} value={item._id} selected={route == item._id ? true : false}>{item.name}</option>
                  ))):(<></>)}
                </Select>
                {!isErrorRoute ? (
                <></>
              ) : (
                <FormErrorMessage>Patrolling Route  is required.</FormErrorMessage>
              )}
              </FormControl>

          </SimpleGrid>
          <SimpleGrid minChildWidth='120px' spacing='40px' marginTop="40px">
            
          <FormControl isInvalid={isErrorStatus}>
              <FormLabel>Status</FormLabel>
              <Select
                placeholder="Select status"
                value={status}
                onChange={(e) => {
                  setIsErrorStatus(e.target.value === '')
                  setStatus(e.target.value)
                }}
              >
                {dataConstant.status.map((element, index) => {
                  return (
                    <option key={element['id']} value={element['value']}>
                      {element['name']}
                    </option>
                  );
                })}
              </Select>
              {!isErrorStatus ? (
                <></>
              ) : (
                <FormErrorMessage>Status is required.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={isErrorTime}>
              <FormLabel>Time</FormLabel>
              <Input
                type='Date'
                value={time}
                onChange={(e) => {
                  setIsErrorTime(e.target.value === '');
                  setTime(e.target.value);
                }}
              />
              {!isErrorTime ? (
                <></>
              ) : (
                <FormErrorMessage>Time is required.</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>
          <Center>
            {loading ? (
              <Spinner mt='24px' size='lg' />
            ) : (
              <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                Submit
              </Button>
            )}
          </Center>
        </CardHeader>
        <CardBody></CardBody>
      </Card>
    </Flex>
  );
}

export default FloorUpdateForm;
