import React, { useEffect, useState } from 'react';
import {
  Flex,
  Input,
  SimpleGrid,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Button,
  Center,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import { DESIGNATION_CLEAR_SNACKBAR } from 'constants/types'; // Update with the correct import for designation constants
import { Spinner } from '@chakra-ui/react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'; // Update this import to your specific path
import dataConstant from 'constants/dataConstant';
import { designationAddOrUpdate } from 'actions/designationAction'; // Update with the correct import for designation actions
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
import Relect from 'react-select';
function DesignationUpdateForm() {
    const textColor = useColorModeValue('gray.700', 'white');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [status, setStatus] = useState('');
    const [departmentId, setDepartmentId] = useState('');
    const [department, setDepartment] = useState('64b656e1b52a6367cddff8a3');
    const [subDepartment, setSubDepartment] = useState('64be68ee2a5df279d0d03df2');
    const [subDepartmentList, setSubDepartmentList] = useState('');
    const [departmentList, setDepartmentList] = useState('');
    const [isErrorName, setIsErrorName] = useState(false);
    const [isErrorDescription, setIsErrorDescription] = useState(false);
    const [isErrorStatus, setIsErrorStatus] = useState(false);
    const [isErrorDepartment, setIsErrorDepartment] = useState(false);
    const [isErrorSubDepartment, setIsErrorSubDepartment] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [type, setType] = useState([]);

  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const responseStatus = useSelector((state) => state.designation.status);
  const responseMessage = useSelector((state) => state.designation.text);
  const loading = useSelector((state) => state.designation.loading);
  const showSnackbar = useSelector((state) => state.designation.showSnackbar);
  const designationDetail = useSelector((state) => state.designation.designationDetail);

  const selectedDesignation = useSelector((state) => state.designation.selectedDesignation); // Assuming you have a selectedDesignation state in your Redux store
  const params = useParams();
  const designationId = params.id;

  useEffect(() => {
    const designation = {
      _id: designationId,
    };
    dispatch(designationAddOrUpdate(designation, 'edit'));
  }, [designationId]);

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsErrorName(name === '');
    // setIsErrorDescription(description === '');
    setIsErrorStatus(status === '');
    setIsErrorDepartment(department === '');
    setIsErrorSubDepartment(subDepartment === '');

    if ( name !== '' &&
    status !== '' &&
    // description !== '' &&
    department !== '' &&
    subDepartment !== '') {
      console.log('Submit');
      // Get form data
      const designationData = {
        _id: designationId,
        name: name,
        status: status,
        description: description,
        department: department,
        subDepartment: subDepartment,
        type: type.map((module) => module.value),
        // Other designation data fields
      };
      console.log(designationData);

      // Call the designationUpdate action
      dispatch(designationAddOrUpdate(designationData, 'edit'));
    }
  };


  
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        
        setName(designationDetail.name)
        setDescription(designationDetail.description)
        setStatus(designationDetail.status)
        setDepartment(designationDetail.department)
        setSubDepartment(designationDetail.subDepartment)
        if (designationDetail.type && Array.isArray(designationDetail.type)) {
          setType(
            designationDetail.type.map((item) => ({
              value: item,
              // label: replaceUnderscoresAndCapitalize(item)
              label: item
            }))
          );
        } else {
          setType([]);
        }
      } 
    }

    dispatch({
      type: DESIGNATION_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }

    dispatch({
      type: DESIGNATION_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      },
    });
  }, [loading]);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  
  const departmentListforDropdown = ()=>{
    axios
    .get(`${server_url}/plant/department-list`, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      }
    }).then((responce)=>{
      setDepartmentList(responce.data.data)
    }).catch(()=> console.log("error"))
  }

  const subdepartmentListforDropdown = (depId)=>{
    axios
    .get(`${server_url}/plant/subdepartment-list/${depId}`, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      }
    }).then((responce)=>{
      setSubdepartmentList(responce.data.data)
    })
  }



  useEffect(()=>{
    departmentListforDropdown()
  
   
  },[])

  console.log("department")
  console.log(department)


  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
    <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
      <CardHeader p="6px 0px 22px 0px">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          Designation Update Form
        </Text>
        <br/>
        <SimpleGrid minChildWidth="120px" spacing="40px">
          <FormControl isInvalid={isErrorName || (submitted && name === '')}>
            <FormLabel>Name</FormLabel>
            <Input
              type="text"
              value={name}
              onChange={(e) => {
                setIsErrorName(e.target.value === '');
                setName(e.target.value);
              }}
            />
            {!isErrorName ? (
              <></>
            ) : (
              <FormErrorMessage>Name is required.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={isErrorDescription}>
              <FormLabel>Description</FormLabel>
              <Input
                type='text'
                value={description}
                onChange={(e) => {
                  // setIsErrorDescription(e.target.value === '')
                  setDescription(e.target.value)
                }}
              />
              {!isErrorDescription ? (
                <></>
              ) : (
                <FormErrorMessage>Description is required.</FormErrorMessage>
              )}
            </FormControl>

          <FormControl isInvalid={isErrorStatus || (submitted && status === '')}>
            <FormLabel>Status</FormLabel>
            <Select
              placeholder="Select status"
              value={status}
              onChange={(e) => {
                setIsErrorStatus(e.target.value === '');
                setStatus(e.target.value);
              }}
            >
              {dataConstant.status.map((element, index) => (
                <option key={element.id} value={element.value}>
                  {element.name}
                </option>
              ))}
            </Select>
            {!isErrorStatus ? (
              <></>
            ) : (
              <FormErrorMessage>Status is required.</FormErrorMessage>
            )}
          </FormControl>
        </SimpleGrid>
        <SimpleGrid minChildWidth="120px" spacing="40px" mt="24px">
          <FormControl
            isInvalid={isErrorDepartment || (submitted && department === '')}
          >
            <FormLabel>Department</FormLabel>
            <Select
              placeholder="Department"
              value={department}
              onChange={(e) => {
                setIsErrorDepartment(e.target.value === '');
                setDepartment(e.target.value);
                subdepartmentListforDropdown(e.target.value);
              }}
             
            >
              {departmentList ? (
                departmentList.map((item, index) => (
                  <option key={item._id} value={item._id}>
                    {item.name}
                  </option>
                ))
              ) : (
                <></>
              )}
            </Select>
            {isErrorDepartment && submitted && (
              <FormErrorMessage>Department is required.</FormErrorMessage>
            )}
          </FormControl>

          <FormControl
            isInvalid={isErrorSubDepartment || (submitted && subDepartment === '')}
          >
            <FormLabel>Subdepartment</FormLabel>
            <Select
              placeholder="Subdepartment"
              value={subDepartment}
              onChange={(e) => {
                setIsErrorSubDepartment(e.target.value === '');
                setSubDepartment(e.target.value);
              }}
              
            >
              {subDepartmentList ? (
                subDepartmentList.map((item, index) => (
                  <option key={item._id} value={item._id}>
                    {item.name}
                  </option>
                ))
              ) : (
                <></>
              )}
            </Select>
            {isErrorSubDepartment && submitted && (
              <FormErrorMessage>Subdepartment is required.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl>
              <FormLabel>Type</FormLabel>
              <Relect
                isMulti
                name="colors"
                placeholder="--select type--"
                options={dataConstant.designationType.map((item) => ({
                  value: item.value,
                  label: item.name
                }))}
                value={type}
                onChange={(value) => {
                  setType(value)
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
              {/* {isErrorSubDepartment && submitted && (
                <FormErrorMessage>Subdepartment is required.</FormErrorMessage>
              )} */}
            </FormControl>
        </SimpleGrid>
        <Center>
          <Button
            type="submit"
            variant="solid"
            mt="32px"
            colorScheme="blue"
            onClick={(e) => handleSubmit(e)}
          >
            {loading ? <Spinner size="sm" /> : 'Submit'}
          </Button>
        </Center>
      </CardHeader>
      <CardBody>{/* Other card body content */}</CardBody>
    </Card>
  </Flex>
  );
}

export default DesignationUpdateForm;
