// Import the action type constants for outPass
import {
  OUTPASS_CLEAR_SNACKBAR,
  OUTPASS_LOADING,
  OUTPASS_ERROR,
  OUTPASS_GET_ALL_SUCCESS,
  OUTPASS_SEARCH // Add the new action type
} from '../constants/types';

const initialState = {
  response: {},
  outPassList: [],
  outPassTodayList: [],
  outPassDetail: {},
  logs: [],
  outPass: [],
  loading: false,
  status: 'success',
  text: '',
  showSnackbar: false,
  limit: 0, // Add missing properties
  page: 0,
  totalPages: 0,
  searching: '' // Add missing properties
};

const outPassReducer = (state = initialState, action) => {
  switch (action.type) {
    case OUTPASS_CLEAR_SNACKBAR:
      return {
        ...state,
        showSnackbar: false,
      };
    case OUTPASS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case OUTPASS_ERROR:
      return {
        ...state,
        outPassList: [],
        outPassTodayList: [],
        response: {},
        outPassDetail: {},
        logs: [],
        outPass: [],
        limit: 0, // Add missing properties
        page: 0,
        totalPages: 0,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };
    case OUTPASS_GET_ALL_SUCCESS:
      return {
        ...state,
        outPassList: action.payload.outPassList,
        outPassTodayList: action.payload.outPassTodayList,
        response: action.payload.response,
        outPassDetail: action.payload.outPassDetail,
        logs: action.payload.logs,
        outPass: action.payload.outPass,
        limit: action.payload.limit,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };
    case OUTPASS_SEARCH: // Add the new case
      return {
        ...state,
        searching: action.payload.searching,
      };
    default:
      return state || {};
  }
};

export default outPassReducer;
