import axios from "axios";
import {
  FLOOR_LOADING,
  FLOOR_GET_ALL_SUCCESS,
  FLOOR_ERROR,
  FLOOR_CLEAR_SNACKBAR,
} from "../constants/types";

import { server_url } from "../constants/types";

export const floorGetAll = (pagination = "") => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: FLOOR_LOADING,
        payload: {
          loading: true,
        },
      });
      axios
        .get(`${server_url}/floor?${pagination}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        })
        .then((response) => {
          dispatch({
            type: FLOOR_GET_ALL_SUCCESS,
            payload: {
              floorList: response.data.data,
              response: response.data,
              loading: false,
              status: "success",
              text: "Get all banner position successfully",
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: FLOOR_ERROR,
            payload: {
              status: "error",
              text: "Error occurred during getting floor data.",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};

export const floorAddOrUpdate = (floor, state) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: FLOOR_LOADING,
        payload: {
          loading: true,
        },
      });
      if (state === "add") {
        axios
          .post(`${server_url}/floor`, floor, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/floor?plant=${responseF.data.data.plant}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("authToken"),
                },
              })
              .then((response) => {
                dispatch({
                  type: FLOOR_GET_ALL_SUCCESS,
                  payload: {
                    floorList: response.data.data,
                    response: response.data,
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: FLOOR_ERROR,
                  payload: {
                    text: "Error occurred during getting floor data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: FLOOR_ERROR,
              payload: {
                text: "Error occurred during adding floor data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      } else if (state === "edit") {
        axios
          .put(`${server_url}/floor/${floor._id}`, floor, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/floor`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("authToken"),
                },
              })
              .then((response) => {
                dispatch({
                  type: FLOOR_GET_ALL_SUCCESS,
                  payload: {
                    floorList: response.data.data,
                    response: response.data,
                    floorDetail: responseF.data["data"],
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: FLOOR_ERROR,
                  payload: {
                    text: "Error occurred during getting floor data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: FLOOR_ERROR,
              payload: {
                text: "Error occurred during updating floor data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      } else if (state === "view") {
        axios
          .get(`${server_url}/floor/${floor._id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/floor`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("authToken"),
                },
              })
              .then((response) => {
                dispatch({
                  type: FLOOR_GET_ALL_SUCCESS,
                  payload: {
                    floorList: response.data.data,
                    response: response.data,
                    floorDetail: responseF.data["data"],
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: FLOOR_ERROR,
                  payload: {
                    text: "Error occurred during getting floor data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: FLOOR_ERROR,
              payload: {
                text: "Error occurred during updating floor data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      }
    });
  };
};


export const floorDelete = (floorId,plant="") => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: FLOOR_LOADING,
        payload: {
          loading: true,
        },
      });
      axios
        .delete(`${server_url}/floor/${floorId}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        })
        .then((responseF) => {
          axios
            .get(`${server_url}/floor?plant=${plant}`, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("authToken"),
              },
            })
            .then((response) => {
              dispatch({
                type: FLOOR_GET_ALL_SUCCESS,
                payload: {
                  floorList: response.data.data,
                  response: response.data,
                  floorDetail: responseF.data["data"],
                  loading: false,
                  status: responseF.data["status"],
                  text: responseF.data["message"],
                  limit: response.data.limit,
                  page: response.data.currentPage,
                  totalPages: response.data.totalPages,
                },
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: FLOOR_ERROR,
                payload: {
                  text: "Error occurred during getting floor data.",
                  status: "error",
                  loading: false,
                },
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: FLOOR_ERROR,
            payload: {
              text: "Error occurred during deleting floor data.",
              status: "error",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};


  export const floorClearShowSnackbar = () => {
    return (dispatch, getState) => {
      dispatch({
        type: FLOOR_CLEAR_SNACKBAR,
      });
    };
  };
  