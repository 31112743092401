// Chakra imports
import {
    Box,
    Button,
    Center,
    Flex,
    Input,
    SimpleGrid,
    Spacer,
    Text,
    Wrap,
    WrapItem,
    useColorModeValue,
    Select,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from 'react-redux';
  import { biometricSettingsAddOrUpdate, biometricSettingsClearShowSnackbar,biometricSettingsGetAll } from '../../actions/biometricsettingsAction';
  import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
  } from '@chakra-ui/react'
  import { useToast } from '@chakra-ui/react'
  import { BIOMETRIC_SETTINGS_CLEAR_SNACKBAR } from "constants/types";
  import { Spinner } from '@chakra-ui/react'
  import dataConstant from "constants/dataConstant";
  import { useHistory,useParams } from 'react-router-dom';
  import axios from 'axios';
  import { server_url } from '../../constants/types';


  function BiometricSettingsForm() {
    const params = useParams();
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [name, setName] = useState("");
    const [position, setPosition] = useState("");
    const [description, setDescription] = useState("");
    const [baseurl, setBaseurl] = useState("");
    const [authtype, setAuthtype] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [token, setToken] = useState("");
    const [timeInterval, setTimeIntervel] = useState("");


    const [status, setStatus] = useState('false');
    const [type, setType] = useState("");
    const [city , setCity] = useState("");
  
    const [isErrorName, setIsErrorName] = useState(false);
    const [isErrorPosition, setIsErrorPosition] = useState(false);
    const [isErrorDescription, setIsErrorDescription] = useState(false);
    const [isErrorBaseurl, setIsErrorBaseurl] = useState(false);
    const [isErrorAuthtype, setIsErrorAuthtype] = useState(false);
    const [isErrorUsername, setIsErrorUsername] = useState(false);
    const [isErrorPassword, setIsErrorPassword] = useState(false);
    const [isErrorToken, setIsErrorToken] = useState(false);
    const [isErrorTimeInterval, setIsErrorTimeInterval] = useState(false);

    
    const [isErrorType, setIsErrorType] = useState(false);
    const [isErrorStatus, setIsErrorStatus] = useState(false);
    const [isErrorRole, setIsErrorRole] = useState(false);
    const [isErrorCity , setIsErrorCity] = useState(false);
  
    const dispatch = useDispatch();
    const toast = useToast();
    const history = useHistory();
    const responseStatus = useSelector(state => state.biometricSettings.status);
    const responseMessage = useSelector(state => state.biometricSettings.text);
    const loading = useSelector(state => state.biometricSettings.loading);
    const showSnackbar = useSelector(state => state.biometricSettings.showSnackbar);
    const cityList = useSelector(state => state.city.cityList);
    console.log(" citylist./././././././././/");
    console.log(cityList);

    useEffect(()=>{
    }, [dispatch])
  
    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
    }, [])


    // Function to handle form submission
    const handleSubmit = (event) => {
      event.preventDefault();
      setIsErrorName(name === '');
      setIsErrorBaseurl(baseurl ==='')
      setIsErrorAuthtype(authtype ==='')
      setIsErrorUsername(username ==='')
      // setIsErrorPassword(password ==='')

      if (name !== "" && baseurl !== "" && authtype !== "" && username !== ""
      //  && password !== "" 
       ) {
        console.log("Submit");
        // Get form data
        const biometricSettings = {
          name: name,
          baseUrl: baseurl,
          authType: authtype,
          username: username,
          password: password,
          token: token,
          timeInterval: timeInterval,
          status: status,
          // type : type,
          plantAdmin : params.id

          // Other banner position data fields
        };
  
        // Determine if it's an add or edit operation
        const state = 'add';
        // Call the bannerPositionAddOrUpdate action
        dispatch(biometricSettingsAddOrUpdate(biometricSettings, state,params.id))
        // history.push("/admin/banner-position")
      }
    };
  
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === 'OK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'success');
            history.goBack();
          }
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'error');
          }
        }
      }
      dispatch({
        type: BIOMETRIC_SETTINGS_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        }
      })
    }, [loading]);
  
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      })
    };

    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
    }, [])

    useEffect(() => {
      dispatch(biometricSettingsGetAll ("&limit=100"));
    }, [dispatch]);
  
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Biometric Settings Form
            </Text>
            <SimpleGrid minChildWidth='120px' spacing='40px'>
              
              <FormControl isInvalid={isErrorName}>
                <FormLabel>Brand Name</FormLabel>
                <Input
                  type='text'
                  onChange={(e) => {
                    setIsErrorName(e.target.value === '')
                    setName(e.target.value)
                  }}
                />
                {!isErrorName ? (
                  <></>
                ) : (
                  <FormErrorMessage>Name is required.</FormErrorMessage>
                )}
              </FormControl>
              
              <FormControl isInvalid={isErrorDescription}>
                <FormLabel>Description</FormLabel>
                <Input
                  type='text'
                  onChange={(e) => {
                    setIsErrorDescription(e.target.value === '')
                    setDescription(e.target.value)
                  }}
                />
                {!isErrorDescription ? (
                  <></>
                ) : (
                  <FormErrorMessage>Description is required.</FormErrorMessage>
                )}
              </FormControl>
              
              <FormControl isInvalid={isErrorBaseurl}>
                <FormLabel>Base URL</FormLabel>
                <Input
                  type='text'
                  onChange={(e) => {
                    setIsErrorBaseurl(e.target.value === '')
                    setBaseurl(e.target.value)
                  }}
                />
                {!isErrorBaseurl ? (
                  <></>
                ) : (
                  <FormErrorMessage>Base URL is required.</FormErrorMessage>
                )}
              </FormControl>
  
              
            </SimpleGrid>

            <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
              
              <FormControl isInvalid={isErrorAuthtype}>
                <FormLabel>Auth Type</FormLabel>
                <Select
                  placeholder="Select Auth Type"
                  onChange={(e) => {
                    setIsErrorAuthtype(e.target.value === '')
                    setAuthtype(e.target.value)
                  }}
                >
                  { dataConstant.biometricAuthType.map((element,index) => {
                      return (<option key={element['name']} value={element['value']} >{element['name']}</option>);   
                        
                    })}
                </Select>
                {!isErrorAuthtype ? (
                  <></>
                ) : (
                  <FormErrorMessage>Auth Type is required.</FormErrorMessage>
                )}
              </FormControl>
              
              <FormControl isInvalid={isErrorUsername}>
                <FormLabel>Username</FormLabel>
                <Input
                  type='text'
                  onChange={(e) => {
                    setIsErrorUsername(e.target.value === '')
                    setUsername(e.target.value)
                  }}
                />
                {!isErrorUsername ? (
                  <></>
                ) : (
                  <FormErrorMessage>Username is required.</FormErrorMessage>
                )}
              </FormControl>
              
              <FormControl isInvalid={isErrorPassword}>
                <FormLabel>Password</FormLabel>
                <Input
                  type='text'
                  onChange={(e) => {
                    setIsErrorPassword(e.target.value === '')
                    setPassword(e.target.value)
                  }}
                />
                {!isErrorPassword ? (
                  <></>
                ) : (
                  <FormErrorMessage>Password is required.</FormErrorMessage>
                )}
              </FormControl>
  
              
            </SimpleGrid>

            <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            <FormControl isInvalid={isErrorToken}>
                <FormLabel>Token</FormLabel>
                <Input
                  type='text'
                  onChange={(e) => {
                    setIsErrorToken(e.target.value === '')
                    setToken(e.target.value)
                  }}
                />
                {!isErrorToken ? (
                  <></>
                ) : (
                  <FormErrorMessage>Token is required.</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={isErrorTimeInterval}>
                <FormLabel>Time Interval (in min for getiing data from the client server)</FormLabel>
                <Select
                  placeholder="Select Time Interval"
                  onChange={(e) => {
                    setIsErrorTimeInterval(e.target.value === '')
                    setTimeIntervel(e.target.value)
                  }}
                >
                  { dataConstant.biometricTimeInterval.map((element,index) => {
                      return (<option key={element['name']} value={element['value']} >{element['name']}</option>);   
                        
                    })}
                </Select>
                {!isErrorTimeInterval ? (
                  <></>
                ) : (
                  <FormErrorMessage>Time Interval is required.</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={isErrorStatus}>
                <FormLabel>Status</FormLabel>
                <Select
                  placeholder="Select status"
                  onChange={(e) => {
                    setIsErrorStatus(e.target.value === '')
                    setStatus(e.target.value)
                  }}
                >
                  { dataConstant.status.map((element,index) => {
                      return (<option key={element['id']} value={element['value']} >{element['name']}</option>);   
                        
                    })}
                </Select>
                {!isErrorStatus ? (
                  <></>
                ) : (
                  <FormErrorMessage>Status is required.</FormErrorMessage>
                )}
              </FormControl>

              {/* <FormControl isInvalid={isErrorType}>
                <FormLabel>Type</FormLabel>
                <Select
                  placeholder="Select Type"
                  onChange={(e) => {
                    setIsErrorType(e.target.value === '')
                    setType(e.target.value)
                  }}
                >
                  { dataConstant.biometricType.map((element,index) => {
                      return (<option key={element['name']} value={element['value']} >{element['name']}</option>);   
                        
                    })}
                </Select>
                {!isErrorType ? (
                  <></>
                ) : (
                  <FormErrorMessage>Type is required.</FormErrorMessage>
                )}
              </FormControl> */}
            </SimpleGrid>

            <Center>
              {loading ? <Spinner mt='24px' size='lg' /> :
                <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                  Submit
                </Button>
              }
            </Center>
          </CardHeader>
          <CardBody>
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default BiometricSettingsForm;
  