import React from "react";
import {
  Avatar,
  Badge,
  Button,
  Flex,
  Link,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure,
  ButtonGroup,
  IconButton,
} from "@chakra-ui/react";
import { BsBoxArrowUpRight, BsFillTrashFill } from "react-icons/bs";
import { ViewIcon } from "@chakra-ui/icons";
import { AiFillEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { deviceDelete } from "actions/devicesAction"; // Make sure you have the correct import for deviceDelete

const DevicesConnectionTableRow = (props) => {
  const {
    name,
    buildNumber,
    deviceId,
    status,
    plant,
    isLast,
    id,
    serialnumber,
    key,
    plantId="",
    deviceStatus,
    guardName,
    checkpost,
    lastConnection
  } = props;

  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const deviceDetail = useSelector((state) => state.device.deviceDetail); // Make sure you have the correct state slice for device detail
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const dispatch = useDispatch();

  const handleDelete = () => {
    const device = {
      _id: id,
    };
    dispatch(deviceDelete(device._id,plantId)); // Use your deviceDelete action
  };
  const authDetail = useSelector((state) => state.auth.authDetail);
  return (
    <>
      <Tr>
      <Td
          >
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={titleColor}
                  fontWeight="bold"
                  minWidth="100%"
                >
                  {serialnumber}
                </Text>
              </Flex>
            </Flex>
          </Td>
        
        <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {deviceId}
              </Text>
            </Flex>
          </Flex>
        </Td>
        
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
            <Badge colorScheme={status ? "green" : "red"}>{ status ? "Active" : "InActive"}</Badge>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {guardName}
              </Text>
            </Flex>
          </Flex>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {checkpost}
              </Text>
            </Flex>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
            <Badge colorScheme={deviceStatus == 'Connected' ? "green" : "red"}>{ deviceStatus}</Badge>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {lastConnection}
              </Text>
            </Flex>
          </Flex>
        </Td>

       
        {/* <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <ButtonGroup>
            <Link href={`#/admin/device-view/${id}`}>
              <IconButton
                colorScheme="blue"
                icon={<ViewIcon />}
                aria-label="Up"
              />
            </Link>

            <Link href={`#/admin/device-update-form/${id}`}>
              <IconButton
                colorScheme="green"
                icon={<AiFillEdit />}
                aria-label="Edit"
              />
            </Link>

            <IconButton
              colorScheme="red"
              variant="outline"
              icon={<BsFillTrashFill />}
              aria-label="Delete"
              onClick={onOpen}
            />
          </ButtonGroup>
        </Td> */}
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          {authDetail.role.allPermissions === false ? (
            <ButtonGroup variant="solid" size="sm" spacing={3}>
              <Link href={`#/admin/device-connection-logs/${id}`}>
                    <IconButton
                    colorScheme="blue"
                    icon={<ViewIcon />}
                    aria-label="View"
                    />
                </Link>
            </ButtonGroup>
          ) : (
            <ButtonGroup variant="solid" size="sm" spacing={3}>
              <Link href={`#/admin/device-connection-logs/${id}`}>
                <IconButton
                  colorScheme="blue"
                  icon={<ViewIcon />}
                  aria-label="View"
                />
              </Link>
            </ButtonGroup>
          )}

            
          </Td>

        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Device
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={() => { handleDelete(); onClose(); }} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Tr>
    </>
  );
};

export default DevicesConnectionTableRow;
