import axios from 'axios';
import {
  EMPLOYEETYPE_LOADING,
  EMPLOYEETYPE_GET_ALL_SUCCESS,
  EMPLOYEETYPE_ERROR,
  EMPLOYEETYPE_CLEAR_SNACKBAR
} from '../constants/types';

import { server_url } from '../constants/types';

export const employeeTypeGetAll = () => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: EMPLOYEETYPE_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/employee-types`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          dispatch({
            type: EMPLOYEETYPE_GET_ALL_SUCCESS,
            payload: {
              employeeTypeList: response.data.data,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all employee types successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: EMPLOYEETYPE_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting employee type data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const employeeTypeAddOrUpdate = (employeeType, state) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: EMPLOYEETYPE_LOADING,
        payload: {
          loading: true
        }
      });
      if (state === 'add') {
        axios
          .post(`${server_url}/employee-type`, employeeType, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/employee-types`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {
                dispatch({
                  type: EMPLOYEETYPE_GET_ALL_SUCCESS,
                  payload: {
                    employeeTypeList: response.data.data,
                    response: response.data,
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: EMPLOYEETYPE_ERROR,
                  payload: {
                    text: 'Error occurred during getting employee type data.',
                    status: 'error',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: EMPLOYEETYPE_ERROR,
              payload: {
                text: 'Error occurred during adding employee type data.',
                status: 'error',
                loading: false
              }
            });
            resolve();
          });
      } else if (state === 'edit') {
        axios
          .put(`${server_url}/employee-type/${employeeType._id}`, employeeType, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/employee-types`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {
                dispatch({
                  type: EMPLOYEETYPE_GET_ALL_SUCCESS,
                  payload: {
                    employeeTypeList: response.data.data,
                    response: response.data,
                    loading: false,
                    status: responseF.data['status'],
                    employeeTypeDetail: responseF.data['data'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: EMPLOYEETYPE_ERROR,
                  payload: {
                    text: 'Error occurred during getting employee type data.',
                    status: 'error',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: EMPLOYEETYPE_ERROR,
              payload: {
                text: 'Error occurred during updating employee type data.',
                status: 'error',
                loading: false
              }
            });
            resolve();
          });
      }else if (state === 'view') {

        axios
        .put(`${server_url}/employee-type/${employeeType._id}`, employeeType, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
          })
          .then((responseF) => {
           
            axios
            .get(`${server_url}/employee-types`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
              })
              .then((response) => {
                console.log("employee-details")
                console.log(responseF.data['data'])
                dispatch({
                  type: EMPLOYEETYPE_GET_ALL_SUCCESS,
                  payload: {
                    employeeTypeList: response.data.data,
                    response: response.data,
                    employeeTypeDetail: responseF.data['data'],
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: EMPLOYEETYPE_ERROR,
                  payload: {
                    text: 'Error occurred during getting employee data.',
                    status: 'error',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: EMPLOYEETYPE_ERROR,
              payload: {
                text: 'Error occurred during updating employee data.',
                status: 'error',
                loading: false
              }
            });
            resolve();
          });
      }
    });
  };
};

export const employeeTypeDelete = (employeeType) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: EMPLOYEETYPE_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .delete(`${server_url}/employee-type/${employeeType}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((responseF) => {
          axios
            .get(`${server_url}/employee-types`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
            .then((response) => {
              dispatch({
                type: EMPLOYEETYPE_GET_ALL_SUCCESS,
                payload: {
                  employeeTypeList: response.data.data,
                  response: response.data,
                  loading: false,
                  status: responseF.data['status'],
                  text: responseF.data['message']
                }
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: EMPLOYEETYPE_ERROR,
                payload: {
                  text: 'Error occurred during getting employee type data.',
                  status: 'error',
                  loading: false
                }
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: EMPLOYEETYPE_ERROR,
            payload: {
              text: 'Error occurred during deleting employee type data.',
              status: 'error',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const employeeClearShowSnackbar = () => {
    return (dispatch, getState) => {
      dispatch({
        type: EMPLOYEETYPE_CLEAR_SNACKBAR
      });
    };
  };