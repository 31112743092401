import axios from 'axios';
import {
  ADMIN_LOADING,
  ADMIN_GET_ALL_SUCCESS,
  ADMIN_ERROR,
  ADMIN_CLEAR_SNACKBAR
} from '../constants/types';

import { server_url } from '../constants/types';
import { EditIcon } from '@chakra-ui/icons';


export const adminGetAll = (pagination) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: ADMIN_LOADING,
        payload: {
          loading: true
        }
      });
      axios.get(`${server_url}/admin?${pagination}`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      })
        .then(response => {
          dispatch({
            type: ADMIN_GET_ALL_SUCCESS,
            payload: {
              adminList: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              adminDetail: {},
              loginDetail: {},
              response: response.data,
              loading: false,
              status: "success",
              text: "Get All user data successfully."
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: ADMIN_ERROR,
            payload: {
              status: "error",
              text: "Error occurred during getting admin data.",
              loading: false
            }
          });
          resolve();
        })
    })
  }
}
// Corporate List
export const corporateGetAll = (pagination) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: ADMIN_LOADING,
        payload: {
          loading: true
        }
      });
      axios.get(`${server_url}/get/corporate-list?${pagination}`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      })
        .then(response => {
          dispatch({
            type: ADMIN_GET_ALL_SUCCESS,
            payload: {
              adminList: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              adminDetail: {},
              loginDetail: {},
              response: response.data,
              loading: false,
              status: "success",
              text: "Get All user data successfully."
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: ADMIN_ERROR,
            payload: {
              status: "error",
              text: "Error occurred during getting admin data.",
              loading: false
            }
          });
          resolve();
        })
    })
  }
}





export const adminDelete = (admin) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: ADMIN_LOADING,
        payload: {
          loading: true
        }
      });
      axios.delete(`${server_url}/admin/${admin._id}`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then(responseF => {
        axios.get(`${server_url}/admin`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then(response => {
          dispatch({
            type: ADMIN_GET_ALL_SUCCESS,
            payload: {
              adminList: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              adminDetail: {},
              loginDetail: {},
              loading: false,
              status: "success",
              text: "Get All user data successfully."
            }
          });
          resolve();
        }).catch((e) => {
          dispatch({
            type: ADMIN_ERROR,
            payload: {
              text: "Error occured during getting admin data.",
              status: "error",
              loading: false
            }
          });
          resolve();
        })
      }).catch((e) => {
        dispatch({
          type: ADMIN_ERROR,
          payload: {
            text: "Error occured during deleteing admin data.",
            status: "error",
            loading: false
          }
        });
        resolve();
      })
    })
  }
}


export const adminAddOrUpdate = (admin, state) => {
  console.log("admin")
  console.log(admin)
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: ADMIN_LOADING,
        payload: {
          loading: true
        }
      });
      if (state === "add") {
        axios.post(`${server_url}/admin`, admin, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }
        )
          .then(responseF => {
            axios.get(`${server_url}/admin`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
              .then(response => {
                dispatch({
                  type: ADMIN_GET_ALL_SUCCESS,
                  payload: {
                    adminList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']

                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: ADMIN_ERROR,
                  payload: {
                    text: "Error occurred during getting admin data.",
                    status: "error",
                    loading: false
                  }
                });
                resolve();
              })
          })
          .catch((e) => {
            dispatch({
              type: ADMIN_ERROR,
              payload: {
                text: "Error occurred during adding admin data.",
                status: "error",
                loading: false
              }
            });
            resolve();
          })
      } else if (state === "edit") {
        axios.put(`${server_url}/admin/${admin._id}`, admin, {
          headers: {

            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
          .then(responseF => {
            axios.get(`${server_url}/admin`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
              .then(response => {
                dispatch({
                  type: ADMIN_GET_ALL_SUCCESS,
                  payload: {
                    adminList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    adminDetail: responseF.data['data'],
                    response: response.data,
                    status: responseF.data.status,
                    text: responseF.data.message
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: ADMIN_ERROR,
                  payload: {
                    text: "Error occurred during getting admin data.",
                    status: "error",
                    loading: false
                  }
                });
                resolve();
              })
          })
          .catch((e) => {
            dispatch({
              type: ADMIN_ERROR,
              payload: {
                text: "Error occurred during updating admin data.",
                status: "error",
                loading: false
              }
            });
            resolve();
          })
      }
      else if (state === "view") {
        axios.get(`${server_url}/admin/view/${admin._id}`, admin, {
          headers: {

            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
          .then(responseF => {
            axios.get(`${server_url}/admin`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
              .then(response => {
                console.log("----adminDetail----");
                console.log(responseF.data['data']);
                dispatch({
                  type: ADMIN_GET_ALL_SUCCESS,
                  payload: {
                    adminList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    adminDetail: responseF.data['data'],
                    response: response.data,
                    status: responseF.data.status,
                    text: responseF.data.message
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: ADMIN_ERROR,
                  payload: {
                    text: "Error occurred during getting admin data.",
                    status: "error",
                    loading: false
                  }
                });
                resolve();
              })
          })
          .catch((e) => {
            dispatch({
              type: ADMIN_ERROR,
              payload: {
                text: "Error occurred during updating admin data.",
                status: "error",
                loading: false
              }
            });
            resolve();
          })

      }
    })
  }
}
// corporate addorupdate
export const corporateAddOrUpdate = (admin, state) => {
  console.log("admin")
  console.log(admin)
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: ADMIN_LOADING,
        payload: {
          loading: true
        }
      });
      if (state === "add") {
        axios.post(`${server_url}/admin`, admin, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }
        )
          .then(responseF => {
            axios.get(`${server_url}/get/corporate-list`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
              .then(response => {
                dispatch({
                  type: ADMIN_GET_ALL_SUCCESS,
                  payload: {
                    adminList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']

                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: ADMIN_ERROR,
                  payload: {
                    text: "Error occurred during getting admin data.",
                    status: "error",
                    loading: false
                  }
                });
                resolve();
              })
          })
          .catch((e) => {
            dispatch({
              type: ADMIN_ERROR,
              payload: {
                text: "Error occurred during adding admin data.",
                status: "error",
                loading: false
              }
            });
            resolve();
          })
      } else if (state === "edit") {
        axios.put(`${server_url}/admin/${admin._id}`, admin, {
          headers: {

            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
          .then(responseF => {
            axios.get(`${server_url}/get/corporate-list`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
              .then(response => {
                dispatch({
                  type: ADMIN_GET_ALL_SUCCESS,
                  payload: {
                    adminList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    adminDetail: responseF.data['data'],
                    response: response.data,
                    status: responseF.data.status,
                    text: responseF.data.message
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: ADMIN_ERROR,
                  payload: {
                    text: "Error occurred during getting admin data.",
                    status: "error",
                    loading: false
                  }
                });
                resolve();
              })
          })
          .catch((e) => {
            dispatch({
              type: ADMIN_ERROR,
              payload: {
                text: "Error occurred during updating admin data.",
                status: "error",
                loading: false
              }
            });
            resolve();
          })
      }
      else if (state === "view") {
        axios.get(`${server_url}/corporate/view/${admin._id}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
          .then(responseF => {
            axios.get(`${server_url}/get/corporate-list`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
              .then(response => {
                console.log("----adminDetail----");
                console.log(responseF.data['data']);
                dispatch({
                  type: ADMIN_GET_ALL_SUCCESS,
                  payload: {
                    adminList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    adminDetail: responseF.data['data'],
                    response: response.data,
                    status: responseF.data.status,
                    text: responseF.data.message
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: ADMIN_ERROR,
                  payload: {
                    text: "Error occurred during getting admin data.",
                    status: "error",
                    loading: false
                  }
                });
                resolve();
              })
          })
          .catch((e) => {
            dispatch({
              type: ADMIN_ERROR,
              payload: {
                text: "Error occurred during updating admin data.",
                status: "error",
                loading: false
              }
            });
            resolve();
          })

      }
    })
  }
}


export const ChangeAdminStatus = (admin , page='' , limit='') => {
  return dispatch => {
    dispatch({
      type: ADMIN_LOADING,
      payload: {
        loading: true
      }
    });
    axios
      .get(`${server_url}/admin/change-status/${admin._id}`,
        {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
      .then(responseF => {
        axios
          .get(`${server_url}/admin?limit=${limit}&page=${page}`)
          .then(response => {
            dispatch({
              type: ADMIN_GET_ALL_SUCCESS,
              payload: {
                adminList: response.data.data,
                limit: response.data.limit,
                page: response.data.currentPage,
                totalPages: response.data.totalPages,
                response: response.data,
                loading: false,
                status: responseF.data['status'],
                text: responseF.data['message']
              }
            });
          })
          .catch(error => {
            dispatch({
              type: ADMIN_ERROR,
              payload: {
                text: "Error occured during getting user data.",
                status: "error",
                loading: false
              },
            });
          });
      })
      .catch(error => {
        dispatch({
          type: ADMIN_ERROR,
          payload: {
            status: "error",
            text: "Error occured during getting user data.",
            loading: false
          }
        });
      });
  };
};



// login
// export const adminLogin = (admin) => {
//   return dispatch => {
//     dispatch({
//       type: ADMIN_LOADING,
//       payload: {
//         loading: true
//       }
//     });
//       axios
//         .post(`${server_url}/admin/login`, admin)
//         .then(response => {
//           dispatch({
//             type: ADMIN_GET_ALL_SUCCESS,
//             payload: {
//               adminList: [],
//               loginDetail: response.data['data'],
//               loading: false,
//               status: response.data['status'],
//               text: response.data['message']
//           }
//           }); 
//         })
//         .catch(error => {
//           dispatch({
//             type: ADMIN_ERROR,
//             payload: {
//               text: "Error occured during getting user data.",
//               status: "error",
//               loading: false
//           }
//           });
//         });

//   };
// };
// // Logout
// export const adminLogout = () => {
//   return dispatch => {
//     dispatch({
//       type: ADMIN_LOADING,
//       payload: {
//         loading: true
//       }
//     });
//     console.log(localStorage.getItem('authToken'));
//       axios
//         .get(`${server_url}/admin/logout`,{headers: { 
//           'Authorization': 'Bearer '+localStorage.getItem('authToken')
//         }})
//         .then(response => {
//           dispatch({
//             type: ADMIN_GET_ALL_SUCCESS,
//             payload: {
//               adminList: [],
//               adminDetail: {},
//               loading: false,
//               status: response.data['status'],
//               text: response.data['message']
//           }
//           }); 
//         })
//         .catch(error => {
//           dispatch({
//             type: ADMIN_ERROR,
//             payload: {
//               text: "Error occured during getting user data.",
//               status: "error",
//               loading: false
//           }
//           });
//         });

//   };
// };

/// Login details
export const loginPermissionDetail = () => {
  return dispatch => {
    dispatch({
      type: ADMIN_LOADING,
      payload: {
        loading: true
      }
    });
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      })
      .then(response => {
        dispatch({
          type: ADMIN_GET_ALL_SUCCESS,
          payload: {
            adminList: [],
            adminDetail: {},
            response: response.data,
            loginDetail: response.data['data'],
            limit: response.data.limit,
            page: response.data.currentPage,
            totalPages: response.data.totalPages,
            loading: false,
            status: response.data['status'],
            text: response.data['message']
          }
        });
      })
      .catch(error => {
        dispatch({
          type: ADMIN_ERROR,
          payload: {
            text: "Error occured during getting user data.",
            status: "error",
            loading: false
          }
        });
      });

  };
};

export const adminClearShowSnackbar = () => {
  return {
    type: ADMIN_CLEAR_SNACKBAR
  };
};
