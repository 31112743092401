// Import the action type constants for raisedGatePass
import {
  RAISED_GATE_PASS_CLEAR_SNACKBAR,
  RAISED_GATE_PASS_LOADING,
  RAISED_GATE_PASS_ERROR,
  RAISED_GATE_PASS_GET_ALL_SUCCESS,
  RAISED_GATE_PASS_SEARCH // Add the new action type
} from '../constants/types';

const initialState = {
  response: {},
  raisedGatePassList: [],
  raisedGatePassDetail: {},
  logs: [],
  raised: [],
  raisedGatePass: [],
  loading: false,
  status: 'success',
  text: '',
  showSnackbar: false,
  limit: 0, // Add missing properties
  page: 0,
  totalPages: 0,
  searching: "", // Add missing properties
  totalItems: 0,
};

const raisedGatePassReducer = (state = initialState, action) => {
  switch (action.type) {
    case RAISED_GATE_PASS_CLEAR_SNACKBAR:
      return {
        ...state,
        showSnackbar: false,
      };
    case RAISED_GATE_PASS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case RAISED_GATE_PASS_ERROR:
      return {
        ...state,
        raisedGatePassList: [],
        response: {},
        raisedGatePassDetail: {},
        logs: [],
        raised: [],
        raisedGatePass: [],
        limit: 0, // Add missing properties
        page: 0,
        totalPages: 0,
        totalItems: 0,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };
    case RAISED_GATE_PASS_GET_ALL_SUCCESS:
      return {
        ...state,
        raisedGatePassList: action.payload.raisedGatePassList,
        response: action.payload.response,
        raisedGatePassDetail: action.payload.raisedGatePassDetail,
        logs: action.payload.logs,
        raised: action.payload.raised,
        raisedGatePass: action.payload.raisedGatePass,
        limit: action.payload.limit,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        totalItems: action.payload.totalItems,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };
    case RAISED_GATE_PASS_SEARCH: // Add the new case
      return {
        ...state,
        searching: action.payload.searching,
      };
    default:
      return state || {};
  }
};

export default raisedGatePassReducer;
