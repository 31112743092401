// Chakra imports
import {
    Box,
    Button,
    Center,
    Flex,
    Image,
    Input,
    SimpleGrid,
    Spacer,
    Stack,
    Text,
    Wrap,
    WrapItem,
    useColorModeValue
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from 'react-redux';

  import { useToast } from '@chakra-ui/react'
  import { useParams } from "react-router-dom/cjs/react-router-dom.min";
  import { VISITOR_PREAPPROVAL_CLEAR_SNACKBAR } from "../../../constants/types";
  import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
  } from '@chakra-ui/react'
  import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'
import { visitorPreapprovalGetById } from "../../../actions/visitorPreapprovalAction";
  
  function visitorPreapprovalView() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name , setName] = useState("");
  const [image , setImage] = useState("");
  const [contactNo , setContactNo] = useState("");
  const [company , setCompany] = useState("");
  const [purpose , setPurpose] = useState("");
  const [visitorType , setVisitorType] = useState("");
  const [department , setDepartment] = useState("");
  const [subdepartment , setSubdepartment] = useState("");
  const [status , setStatus] = useState("");
  const [inTime , setInTime] = useState("")
  const [outTime , setOutTime] = useState("")
  const [logs , setLogs] = useState([])
  const [visitDate , setVisitDate] = useState("")
  const [duration , setDuration] = useState("")
  const [visitTime , setVisitTime] = useState("")
  const [anyTime , setAnyTime] = useState("")
    const dispatch = useDispatch();
    const toast = useToast();
  
    const statuses = ['success', 'error', 'warning', 'info'];
    const responseStatus = useSelector(state => state.visitorPreapproval.status);
    const responseMessage = useSelector(state => state.visitorPreapproval.text);
    const loading = useSelector(state => state.visitorPreapproval.loading);
    const visitorPreapprovalDetails = useSelector(state => state.visitorPreapproval.visitorPreapprovalDetail);
    const showSnackbar = useSelector(state => state.visitorPreapproval.showSnackbar);
  
    console.log("visitorPreapprovalDetails")
    console.log(visitorPreapprovalDetails)
    const params = useParams();
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === 'OK' && loading === false) {
          console.log(visitorPreapprovalDetails);
          setName(visitorPreapprovalDetails.name);
          setImage(visitorPreapprovalDetails.image);
          setContactNo(visitorPreapprovalDetails.contactNo);
          setCompany(visitorPreapprovalDetails.company);
          setPurpose(visitorPreapprovalDetails.purpose);
          setVisitorType(visitorPreapprovalDetails.visitorType);
          setDepartment(visitorPreapprovalDetails?.departmentId?.name);
          setSubdepartment(visitorPreapprovalDetails?.subDepartmentId?.name);
          setStatus(visitorPreapprovalDetails.status);
          setInTime(visitorPreapprovalDetails.inDateTime);
          setOutTime(visitorPreapprovalDetails.outDateTime);
          setLogs(visitorPreapprovalDetails?.logs);
          setVisitDate(visitorPreapprovalDetails?.visitDate);
          setDuration(visitorPreapprovalDetails?.duration);
          setVisitTime(visitorPreapprovalDetails?.visitTime);
          setAnyTime(visitorPreapprovalDetails?.anyTime);
          
         
            
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'success');
          }
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'error');
          }
        }
      }
  
      dispatch({
        type: VISITOR_PREAPPROVAL_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        }
      })
    }, [loading]);
  
    
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      })
    };


    useEffect(() => {
      console.log("params");
      console.log(params);
      const visitorId = params.id;
      const visitor = {
        _id: visitorId,
      };
      dispatch(visitorPreapprovalGetById(visitor._id));
    }, [dispatch]);
  
    console.log("visitorPreapprovalDetails")
    console.log(visitorPreapprovalDetails)
  
    return (
      <>
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="10px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Details
            </Text>
          </CardHeader>
          <CardBody>
            {loading ? <Stack>
              <Skeleton height='20px' />
              <Skeleton height='20px' />
              <Skeleton height='20px' />
            </Stack>
              :
              <TableContainer>
                <Table variant='simple'>
                  <Tbody>
                    <Tr>
                      <Td>Name</Td>
                      <Td>{name}</Td>
                    </Tr>

                    <Tr>
                      <Td>Image</Td>
                      <Td><Flex direction="column">
                        <Text fontSize="md" color={textColor} fontWeight="bold">
                        <Image
                            boxSize='100px'
                            objectFit='cover'
                            src={image}
                            alt='Dan Abramov'
                          />
                          
                        </Text>
                      </Flex>
                      </Td>
                    </Tr>
                   
                    
                    <Tr>
                      <Td>Mobile Number</Td>
                      <Td>{contactNo}</Td>
                    </Tr>
                    <Tr>
                      <Td>Company</Td>
                      <Td>{company}</Td>
                    </Tr>
                    <Tr>
                      <Td>Purpose</Td>
                      <Td>{purpose}</Td>
                    </Tr>
                    <Tr>
                      <Td>VisitorType</Td>
                      <Td>{visitorType?.name}</Td>
                    </Tr>
                    <Tr>
                      <Td>Department</Td>
                      <Td>{department}</Td>
                    </Tr>
                   
                    <Tr>
                      <Td>Subdepartment</Td>
                      <Td>{subdepartment}</Td>
                    </Tr>

                    <Tr>
                      <Td>Status</Td>
                      <Td>{status ? "Active" : "Inactive"}</Td>
                    </Tr>

                    <Tr>
                      <Td>Visit Date</Td>
                      <Td>{visitDate}</Td>
                    </Tr>
                  
                    <Tr>
                      <Td>Duration</Td>
                      <Td>{duration}</Td>
                    </Tr>
                    <Tr>
                      <Td>Visit Time</Td>
                      <Td>{visitTime}</Td>
                    </Tr>
                    
                  
                  </Tbody>
                </Table>
             </TableContainer>}
          </CardBody>
        </Card>
      </Flex>

      {/* logs */}
      <Flex direction="column" pt={{ base: "120px", md: "10px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="10px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
            Logs
            </Text>
          </CardHeader>
          <CardBody>
         {loading ? (
            <Stack>
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
            </Stack>
          ) : visitorPreapprovalDetails ? (
            <TableContainer >
                <Table variant='simple'>
                  <Thead>
                    <Tr>
                      <Th color="white">SL</Th>
                      <Th color="white">Description</Th>
                      <Th color="white">Date & Time</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                   
                    {
                      logs.map((row, index, arr) => {

                        return (
                         
                           <Tr>
                            <Td>{index +1}</Td>
                            <Td>{row.description}</Td>
                            <Td>{row.createdAt}</Td>
                          </Tr>
                        );
                      })}
                      
                  
                  
                    
                  
                  </Tbody>
                </Table>
                </TableContainer>
          ) : null}
        </CardBody>
        </Card>
      </Flex>
      </>
    );
  }
  
  export default visitorPreapprovalView;
