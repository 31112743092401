// Chakra imports
import {
    Box,
    Button,
    Center,
    Divider,
    Flex,
    Grid,
    GridItem,
    Heading,
    Image,
    Input,
    SimpleGrid,
    Spacer,
    Stack,
    Text,
    Wrap,
    WrapItem,
    useColorModeValue,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from 'react-redux';

  import { useToast } from '@chakra-ui/react'
  import { useParams } from "react-router-dom/cjs/react-router-dom.min";
  import { DELIVARYINOUT_CLEAR_SNACKBAR } from "constants/types";
  import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
  } from '@chakra-ui/react'
  import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'
import { delivaryGetById } from "actions/delivaryInOutAction";
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
  function DelivaryInOutView() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
  
    const [name , setName] = useState("");
    const [image , setImage] = useState("");
    const [contact , setContact] = useState("");
    const [deliveryFrom , setDeliveryForm] = useState("");
    const [department , setDepartment] = useState("");
    const [subDepartment , setSubDepartment] = useState("");
    const [subDepartmentDesc , setSubDepartmentDesc] = useState("");
    const [deliveryFromDesc , setDeliveryFormDesc] = useState("");
    const [deliveryFromImage , setDeliveryFormImage] = useState("");
    const [parcel , setParcel] = useState("");
    const [status , setStatus] = useState("");
    const [parcelPhoto , setParcelPhoto] = useState("");
    const [carryItems , setCarryItems] = useState("");
    const [inTime , setInTime] = useState("");
    const [outTime , setOutTime] = useState("");
    const [guard , setGuard]= useState("");
    const [carryItemsPhotos , setCarryItemsPhotos] = useState("");

    const history = useHistory();
    const dispatch = useDispatch();
    const toast = useToast();
  
    const statuses = ['success', 'error', 'warning', 'info'];
    const responseStatus = useSelector(state => state.delivaryInOut.status);
    const responseMessage = useSelector(state => state.delivaryInOut.text);
    const loading = useSelector(state => state.delivaryInOut.loading);
    const DelivaryInOutDetails = useSelector(state => state.delivaryInOut.delivaryInOutDetail);
    const showSnackbar = useSelector(state => state.delivaryInOut.showSnackbar);
  
    const params = useParams();
    
   

    
    // console.log(DelivaryInOutDetails.departmentId)
 
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === 'OK' && loading === false) {
          console.log("DelivaryInOutDetails");
          console.log(DelivaryInOutDetails);
          setName(DelivaryInOutDetails.name);
          setImage(DelivaryInOutDetails.image);
          setContact(DelivaryInOutDetails.mobileNo);
          setDeliveryForm(DelivaryInOutDetails.deliveryFrom.name);
          setDeliveryFormDesc(DelivaryInOutDetails.deliveryFrom.description);
          setDeliveryFormImage(DelivaryInOutDetails.deliveryFrom.image);
          // setDepartment(DelivaryInOutDetails.departmentId.name);
          setSubDepartment(DelivaryInOutDetails.subDepartmentId.name);
          setSubDepartmentDesc(DelivaryInOutDetails.subDepartmentId.description);
          setParcel(DelivaryInOutDetails.parcel);
          setStatus(DelivaryInOutDetails.status);
          setParcelPhoto(DelivaryInOutDetails.parcelPhoto);
          setCarryItems(DelivaryInOutDetails.carryItems);
          setCarryItemsPhotos(DelivaryInOutDetails.carryItemsPhotos)
          setInTime(DelivaryInOutDetails.inTime);
          setOutTime(DelivaryInOutDetails.outTime);
         
         
            
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'success');
          }
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'error');
          }
        }
      }
  
      dispatch({
        type: DELIVARYINOUT_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        }
      })
    }, [loading]);
  
    
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      })

      if (!DelivaryInOutDetails && loading === false) {
        // Display toast with an error message
        toastFunction("Data not available.", "error");
      }
    };

  
    useEffect(() => {

      console.log("useEffect is done")
      const delivaryId = params.id;
      console.log(params);
      const delivary = {
        _id: delivaryId,
      };
      dispatch(delivaryGetById(delivary._id))
    }, []);

    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
    }, [])
   
  
    console.log("DelivaryInOutDetails")
    console.log(DelivaryInOutDetails)
  
    return (
      <><Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="20px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
          Delivery View 
          </Text>
        </CardHeader>
        <CardBody>
       {loading ? (
          <Stack>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
          </Stack>
        ) : DelivaryInOutDetails ? (
          <TableContainer >
              <Table variant='simple'>
                <Tbody>
                  <Tr>
                    <Td>Name</Td>
                    <Td>{name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Image</Td>
                    <Td><Flex direction="column">
                      <Text fontSize="md" color={textColor} fontWeight="bold">
                      <Image
                          boxSize='100px'
                          objectFit='cover'
                          src={image}
                          alt='Dan Abramov'
                        />
                        
                      </Text>
                    </Flex>
                    </Td>
                  </Tr>

                  
                 
                 
                  <Tr>
                    <Td>Contact</Td>
                    <Td>{contact}</Td>
                  </Tr>

                  {/* <Tr>
                    <Td>Delivery Form</Td>
                    <Td><p><b>Name :</b>{deliveryFrom?.name}<br/>
                    <b>Description :</b>{deliveryFrom?.description}<br/>
                    <b>Image :</b><Image
                          boxSize='100px'
                          objectFit='cover'
                          src={deliveryFrom?.image}
                          alt='Dan Abramov'
                        /><br/></p></Td>
                    
                  </Tr> */}
                  {/* <Tr>
                    <Td>Department</Td>
                    <Td>{department}</Td>
                  </Tr> */}
                  <Tr>
                    <Td>Delivery From</Td>
                    <Td>{deliveryFrom}</Td>
                  </Tr>
                  <Tr>
                  <Td>Delivery From Photo</Td>
                  <Td>
                    <Image
                      boxSize='100px'
                      objectFit='cover'
                      src={deliveryFromImage}
                      alt='Dan Abramov'
                    /></Td>
                  </Tr>
                  <Tr>
                    <Td>Department</Td>
                    <Td>{DelivaryInOutDetails.departmentId?.name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Sub Department</Td>
                    <Td>{subDepartment}</Td>
                  </Tr>
                  <Tr>
                    <Td>Parcel</Td>
                    <Td>{parcel}</Td>
                  </Tr>
                  {/* <Tr>
                    <Td>Totalparcel</Td>
                    <Td>{totalparcel}</Td>
                  </Tr> */}
                  <Tr>
                    <Td>Status</Td>
                    <Td>{status}</Td>
                  </Tr>
                  
                  
                  <Tr>
                  <Td>Parcel Photo</Td>
                  <Td>
                    <Image
                      boxSize='100px'
                      objectFit='cover'
                      src={parcelPhoto}
                      alt='Dan Abramov'
                    /></Td>
                  </Tr>
                  <Tr>
                    <Td>Carry Items</Td>
                   {carryItems? (carryItems.map((item)=>{
                      return(<Td>{item}</Td>)
                    })):(<></>)}
                  </Tr>
                  <Tr>
                    <Td>Carry Items Photos</Td>
                    {carryItemsPhotos ? (carryItemsPhotos.map((item)=>{
                      
                      return(<Td><Image
                        boxSize='100px'
                        objectFit='cover'
                        src={item}
                        alt='Dan Abramov'
                      /></Td>)
                    })):(<></>)}
                  </Tr>
                
                  <Tr>
                    <Td>In Date-Time</Td>
                    <Td>{DelivaryInOutDetails?.inDateTime}</Td>
                  </Tr>
                 
                  <Tr>
                    <Td>Out Date-Time</Td>
                    <Td>{DelivaryInOutDetails?.outDateTime}</Td>
                  </Tr>
                  <Tr>
                    <Td>Guard</Td>
                    <Td>{guard}</Td>
                  </Tr>
                
                  
                
                </Tbody>
              </Table>
              </TableContainer>
        ) : null}
      </CardBody>
      </Card>
    </Flex>
    {/* Parcel */}
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="20px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
          Parcel
          </Text>
        </CardHeader>
        <CardBody>
       {loading ? (
          <Stack>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
          </Stack>
        ) : DelivaryInOutDetails ? (
          DelivaryInOutDetails?.checkParcel !="" ?(
            <Grid templateColumns='repeat(4, 1fr)' gap={6}>
           { DelivaryInOutDetails?.checkParcel?.parcelPhotoLogs.map((row, index, arr) => {

              return (
                <GridItem w='100%' >
                  <Card maxW='sm'>
                    <CardBody>
                      <Image
                        src={row.url}
                        alt='Parcel'
                        borderRadius='lg'
                      />
                      <Stack mt='6' spacing='1'>
                        <Text align="center" color={row.status? 'green':'red'}>
                          {row.status?'Handed over to '+row.name : 'Pending'}
                        </Text>
                        <Text>
                        
                        </Text>
                      </Stack>
                    </CardBody>
                  </Card>
                </GridItem>
              );
              })}
          </Grid>
          ):null
        ) : null}
      </CardBody>
      </Card>
    </Flex>
    {/* Delivery Logs */}
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="10px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
            Logs
            </Text>
          </CardHeader>
          <CardBody>
         {loading ? (
            <Stack>
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
            </Stack>
          ) : DelivaryInOutDetails ? (
            <TableContainer >
                <Table variant='simple'>
                  <Thead>
                    <Tr>
                      <Th color="white">SL</Th>
                      <Th color="white">Description</Th>
                      <Th color="white">Date & Time</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                   
                    {
                      DelivaryInOutDetails?.logs.map((row, index, arr) => {

                        return (
                         
                           <Tr>
                            <Td>{index +1}</Td>
                            <Td>{row.description}</Td>
                            <Td>{row.createdAt}</Td>
                          </Tr>
                        );
                      })}
                      
                  
                  
                    
                  
                  </Tbody>
                </Table>
                </TableContainer>
          ) : null}
        </CardBody>
        </Card>
      </Flex>
    </>
    );
  }
  
  export default DelivaryInOutView;
