const {
    EMPLOYEETYPE_LOADING,
    EMPLOYEETYPE_GET_ALL_SUCCESS,
    EMPLOYEETYPE_ERROR,
    EMPLOYEETYPE_CLEAR_SNACKBAR
  } = require('../constants/types');
  
  const initialState = {
    response: {},
    employeeTypeList: [],
    employeeTypeDetail: {},
    loading: false,
    status: 'success',
    text: '',
    showSnackbar: false
  };
  
  const employeeTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case EMPLOYEETYPE_CLEAR_SNACKBAR:
     case EMPLOYEETYPE_LOADING:
        return {
          ...state,
          loading: true
        };
      case EMPLOYEETYPE_ERROR:
        return {
          ...state,
          employeeTypeList: [],
          response: {},
          employeeTypeDetail: {},
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case EMPLOYEETYPE_GET_ALL_SUCCESS:
        return {
          ...state,
          employeeTypeList: action.payload.employeeTypeList,
          response: action.payload.response,
          employeeTypeDetail: action.payload.employeeTypeDetail,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      default:
        return state || {};
    }
  };
  
  export default employeeTypeReducer;
  