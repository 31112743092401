import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useDispatch, useSelector } from "react-redux";

import { useToast } from '@chakra-ui/react';
import { useParams } from "react-router-dom";
import { HIGH_ALERT_CLEAR_SNACKBAR } from "constants/types";
import {
  Table,
  Tbody,
  Td,
  Tr,
  TableContainer,
} from '@chakra-ui/react';
import { Skeleton, SkeletonText } from '@chakra-ui/react';
import { highAlertAddOrUpdate } from "actions/highAlertAction";

import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
function HighAlertView() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [checkpost, setCheckpost] = useState("");
  const [guard, setGuard] = useState("");
  const [pin, setPin] = useState("");
  const [status, setStatus] = useState("");

  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const responseStatus = useSelector(state => state.highAlert.status);
  const responseMessage = useSelector(state => state.highAlert.text);
  const loading = useSelector(state => state.highAlert.loading);
  const highAlertDetail = useSelector(state => state.highAlert.highAlertDetail);
  const showSnackbar = useSelector(state => state.highAlert.showSnackbar);

  console.log("highAlertDetail111111111111111111")
  console.log(highAlertDetail)

  const params = useParams();
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
          setCheckpost(highAlertDetail.checkpost.name);
          setGuard(highAlertDetail.guard.name);
        setPin(highAlertDetail.pin);
        setStatus(highAlertDetail.status);

        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }

    dispatch({
      type: HIGH_ALERT_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    });
    console.log(highAlertDetail)
    console.log("highAlertDetail")
  }, [loading]);

  useEffect(() => {
    const highAlertId = params.id;
    console.log("highAlertId");
    console.log(highAlertId);
    const highAlert = {
      _id: highAlertId
    };
    dispatch(highAlertAddOrUpdate(highAlert, 'view'));
  }, []);

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            High Alert view
          </Text>
        </CardHeader>
        <CardBody>
          {loading ? <SkeletonText noOfLines={3} spacing="4" /> :
            <TableContainer>
              <Table variant='simple'>
                <Tbody>
                  <Tr>
                    <Td>Checkpost</Td>
                    <Td>{checkpost}</Td>
                  </Tr>
                  <Tr>
                    <Td>Guard</Td>
                    <Td>{guard}</Td>
                  </Tr>
                  <Tr>
                    <Td>Pin</Td>
                    <Td>{pin}</Td>
                  </Tr>
                  <Tr>
                    <Td>Status</Td>
                    <Td>{status}</Td>
                  </Tr>
                 
                </Tbody>
              </Table>
            </TableContainer>
          }
        </CardBody>
      </Card>
    </Flex>
  );
}

export default HighAlertView;
