
// Chakra imports
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  Select,
  Image,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { bannerPositionAddOrUpdate, bannerPositionClearShowSnackbar } from "../../actions/bannerpositionAction";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'
import { BANNER_POSITION_CLEAR_SNACKBAR } from "constants/types";
import { Spinner } from '@chakra-ui/react'
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import dataConstant from "constants/dataConstant";
import { cityGetAll } from "actions/CityActions";
import axios from 'axios';
import { server_url } from '../../constants/types';
import { useHistory } from 'react-router-dom';

function BannerPositionUpdateForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [status, setStatus] = useState("");
  const [position, setPosition] = useState("");
  const [alertMessage, setAlertMessage] = useState('');
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [city , setCity] = useState("");
  


  const [isErrorEndDate, setIsErrorEndDate] = useState(false);
  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorImage, setIsErrorImage] = useState(false);
  const [isErrorStatus, setIsErrorStatus] = useState(false);
  const [isErrorPosition, setIsErrorPosition] = useState(false);
  const [isErrorSecoundarycontactNo, setIsErrorSecoundarycontactNo] = useState(false);
  const [isErrorContactNo, setIsErrorContactNo] = useState(false);
  const [isErrorStartDate, setIsErrorStartDate] = useState(false);
  const [isErrorBannerPosition, setIsErrorBannerPosition] = useState(false);
  const [isErrorCity , setIsErrorCity] = useState(false);
  const [bannerPosition, setBannerPosition] = useState("");


  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.bannerposition.status);
  const responseMessage = useSelector(state => state.bannerposition.text);
  const loading = useSelector(state => state.bannerposition.loading);
  const showSnackbar = useSelector(state => state.bannerposition.showSnackbar);
  const bannerPositionDetail = useSelector(state => state.bannerposition.bannerPositionDetail);
  const bannerPositionList = useSelector(state => state.bannerposition.bannerPositionList);
  const cityList = useSelector(state => state.city.cityList);

  useEffect(()=>{
    dispatch(cityGetAll())
  }, [dispatch])

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])

  console.log("./././././././/././");
  console.log(cityList);

  const selectedBanner = useSelector(state => state.bannerposition.selectedBanner); // Assuming you have a selectedBanner state in your Redux store
  const params = useParams();
  const bannerPositionId = params.id;
  console.log("bannerPositionId");
  console.log(bannerPositionDetail);
  useEffect(() => {

    const bannerPosition = {
      _id: bannerPositionId
    };
    console.log(bannerPosition)
    console.log('bannerPosition')
    dispatch(bannerPositionAddOrUpdate(bannerPosition, 'edit'));
  }, []);

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsErrorName(name === '');
    // setIsErrorImage(image === '');
    setIsErrorStatus(status === '');
    setIsErrorPosition(position === '');
    setIsErrorCity(city === "");

    // console.log("Submit");

    if (name !== "" &&  status !== "" && position !== "" && city !== "" && startDate !== "" ) {
      console.log("Submit");
      // Get form data
      const bannerPositionData = {
        _id: bannerPositionId, // Assuming your banner model has an id field
        name: name,
        // image: image,
        status: status,
        position: position,
        city : city,
        startDate:startDate
        // Other banner data fields
      };
      console.log(bannerPositionData);

      // Call the bannerUpdate action
      dispatch(bannerPositionAddOrUpdate(bannerPositionData, 'edit'));
      

    }
  };

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        console.log("bannerPositionDetail?????????>>>>>>>>>>>>>>>");
        console.log(bannerPositionDetail);
        setName(bannerPositionDetail.name);
        // setImage(bannerPositionDetail.image);
        setStatus(bannerPositionDetail.status);
        setPosition(bannerPositionDetail.position);
        setStartDate(bannerPositionDetail.bannerType);
        setCity(bannerPositionDetail.city._id)
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }

    dispatch({
      type: BANNER_POSITION_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Banner Position Update Form
          </Text>
          <br/>
          <SimpleGrid minChildWidth='120px' spacing='40px'>

            <FormControl isInvalid={isErrorName}>
              <FormLabel>Name</FormLabel>
              <Input
                type='text'
                value={name}
                onChange={(e) => {
                  setIsErrorName(e.target.value === '')
                  setName(e.target.value)
                }}
              />
              {!isErrorName ? (
                <></>
              ) : (
                <FormErrorMessage>Name is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorStatus}>
              <FormLabel>Status</FormLabel>
              <Select
                placeholder="Select status"
                value={status}
                onChange={(e) => {
                  setIsErrorStatus(e.target.value === '')
                  setStatus(e.target.value)
                }}
              >
                {dataConstant.status.map((element, index) => {
                  return (<option key={element['id']} value={element['value']}  selected={city === element._id}>{element['name']}</option>);

                })}
              </Select>
              {!isErrorStatus ? (
                <></>
              ) : (
                <FormErrorMessage>Status is required.</FormErrorMessage>
              )}
            </FormControl>

            {/* <FormControl isInvalid={isErrorImage}>
              <FormLabel>Image</FormLabel>
              <Input
                type='file'
                onChange={(e) => {
                  setIsErrorImage(e.target.value === '')
                  setImage(e.target.files[0])
                }}
              />
              {!isErrorImage ? (
                <></>
              ) : (
                <FormErrorMessage>Image is required.</FormErrorMessage>
              )}
            </FormControl> */}

          </SimpleGrid>
          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>

       

            <FormControl isInvalid={isErrorPosition}>
              <FormLabel>Position</FormLabel>
              <Input
                type='text'
                value={position}
                onChange={(e) => {
                  setIsErrorPosition(e.target.value === '')
                  setPosition(e.target.value)
                }}
              />
              {!isErrorPosition ? (
                <></>
              ) : (
                <FormErrorMessage>Position is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorContactNo}>
              <FormLabel>Banner Type</FormLabel>
              <Input
                type="text"
                value = {startDate}
                onChange={(e) => {
                  setIsErrorStartDate(e.target.value === "");
                  setStartDate(e.target.value);
                }}
              />
              {!isErrorContactNo ? (
                <></>
              ) : (
                <FormErrorMessage>Start date is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorCity}>
              <FormLabel>City</FormLabel>
              <Select
                placeholder="Select City"
                onChange={(e) => {
                  setIsErrorCity(e.target.value === '')
                  setCity(e.target.value)
                }}
                value={city}
              >
                { cityList.map((element,index) => {
                    return (<option key={element._id} value={element._id} >{element.name}</option>);   
                      
                  })}
              </Select>
              {!isErrorCity ? (
                <></>
              ) : (
                <FormErrorMessage>City is required.</FormErrorMessage>
              )}
            </FormControl>

          </SimpleGrid>

          <Center>
            {loading ? <Spinner mt='24px' size='lg' /> :
              <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                Submit
              </Button>
            }
          </Center>
        </CardHeader>
        <CardBody>
        </CardBody>
      </Card>
      <Spacer></Spacer>
      {/* <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px" mt="4">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Banner
          </Text>
          <Box boxSize='sm'>
            <Image src={image} alt='Dan Abramov' />
          </Box>
        </CardHeader>
        <CardBody>
        </CardBody>
      </Card> */}
    </Flex>
  );
}

export default BannerPositionUpdateForm;
