const {
    SCHEDULE_HIGH_ALERT_LOADING,
    SCHEDULE_HIGH_ALERT_GET_ALL_SUCCESS,
    SCHEDULE_HIGH_ALERT_ERROR,
    SCHEDULE_HIGH_ALERT_CLEAR_SNACKBAR
  } = require('../constants/types');
  
  const initialState = {
    response: {},
    schedulehighAlertList: [],
    schedulehighAlertDetail: {},
    loading: false,
    status: 'success',
    text: '',
    showSnackbar: false,
    limit: 0,  // Add missing properties
    page: 0,
    totalPages: 0,
  };
  
  const schedulehighAlertReducer = (state = initialState, action) => {
    switch (action.type) {
      case SCHEDULE_HIGH_ALERT_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case SCHEDULE_HIGH_ALERT_LOADING:
        return {
          ...state,
          loading: true
        };
      case SCHEDULE_HIGH_ALERT_ERROR:
        return {
          ...state,
          schedulehighAlertList: [],
          response: {},
          schedulehighAlertDetail: {},
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true,
          limit: 0,  // Add missing properties
          page: 0,
          totalPages: 0,
        };
      case SCHEDULE_HIGH_ALERT_GET_ALL_SUCCESS:
        return {
          ...state,
          schedulehighAlertList: action.payload.schedulehighAlertList,
          response: action.payload.response,
          schedulehighAlertDetail: action.payload.schedulehighAlertDetail,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true,
          limit: action.payload.limit,
          page: action.payload.page,
          totalPages: action.payload.totalPages,
        };
      default:
        return state || {};
    }
  };
  
  export default schedulehighAlertReducer;
  