import React from "react";
import {
  Badge,
  Button,
  Flex,
  Link,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure,
  ButtonGroup,
  IconButton,
} from "@chakra-ui/react";
import { BsFillTrashFill } from "react-icons/bs";
import { ViewIcon } from "@chakra-ui/icons";
import { AiFillEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { designationDelete } from "../../../actions/designationAction"; // Make sure you have the correct import for designationDelete

const GroupDesignationTableRow = (props) => {
  const {
    name,
    description,
    status,
    plant,
    department,
    serialnumber,
    subDepartment,
    isLast,
    id,
    key,
    plantId="",
    type,
    departmentId,
    subdepartmentId
  } = props;

  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const authDetail = useSelector((state) => state.auth.authDetail);

  const dispatch = useDispatch();

  const handleDelete = () => {
    const designation = {
      _id: id,
    };
    dispatch(designationDelete(designation._id,plantId)); // Use your designationDelete action
  };

  return (
    <>
      <Tr>
      <Td
          >
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={titleColor}
                  fontWeight="bold"
                  minWidth="100%"
                >
                  {serialnumber}
                </Text>
              </Flex>
            </Flex>
          </Td>
        <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {name}
              </Text>
            </Flex>
          </Flex>
        </Td>
        {/* <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {description}
              </Text>
            </Flex>
          </Flex>
        </Td> */}

        
        {/* <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {plant}
              </Text>
            </Flex>
          </Flex>
        </Td> */}
          {/* <Td
            minWidth={{ sm: "250px" }}
            pl="0px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : null}
          >
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={titleColor}
                  fontWeight="bold"
                  minWidth="100%"
                >
                  {plant}
                </Text>
              </Flex>
            </Flex>
          </Td> */}

        <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {department}
              </Text>
            </Flex>
          </Flex>
        </Td>
        {/* <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {plant}
              </Text>
            </Flex>
          </Flex>
        </Td> */}
        <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {subDepartment}
              </Text>
            </Flex>
          </Flex>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Badge colorScheme={status ? "green" : "red"}>{status ? "Active" : "InActive"}</Badge>
        </Td>

        {/* <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <ButtonGroup>
            <Link href={`#/admin/designation-view/${id}`}>
              <IconButton
                colorScheme="blue"
                icon={<ViewIcon />}
                aria-label="Up"
              />
            </Link>

            <Link href={`#/admin/designation-update-form/${id}`}>
              <IconButton
                colorScheme="green"
                icon={<AiFillEdit />}
                aria-label="Edit"
              />
            </Link>

            <IconButton
              colorScheme="red"
              variant="outline"
              icon={<BsFillTrashFill />}
              aria-label="Delete"
              onClick={onOpen}
            />
          </ButtonGroup>
        </Td> */}

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          {authDetail.role.allPermissions === false ? (
            <ButtonGroup variant="solid" size="sm" spacing={3}>
              {authDetail.role.permissions.map((permission) => {
                const { permissionName, moduleName } = permission;

                if (
                  permissionName === "VIEW" &&
                  moduleName === "DESIGNATION"
                ) {
                  const employeeButton = type.find(element => element === "EMPLOYEE");
                  const guardButton = type.find(element => element === "GUARD");
                  const driverButton = type.find(element => element === "DRIVER");
                  return (
                    
                    <>
                    {/* <Link href={`#/admin/designation-view/${id}`}>
                      <IconButton
                        key={permission.id}
                        colorScheme="blue"
                        icon={<ViewIcon />}
                        aria-label="View"
                      />
                    </Link> */}
                    {
                      
                      (employeeButton !== undefined) ? <Link href={`#/admin/employee-form?departmentId=${departmentId}&subdepartmentId=${subdepartmentId}&designationId=${id}`}>
                      <Button
                        variant="solid"
                        mt="32px"
                        colorScheme="blue"
                      >
                        + Employee
                        </Button>
                      </Link>:<></>
                    }
                    {
                      
                      (guardButton !== undefined) ? <Link href={`#/admin/gaurd-form?departmentId=${departmentId}&subdepartmentId=${subdepartmentId}&designationId=${id}`}>
                      <Button
                        variant="solid"
                        mt="32px"
                        colorScheme="blue"
                      >
                        + Guard
                        </Button>
                      </Link>:<></>
                    }
                    {
                      
                      (driverButton !== undefined) ? <Link href={`#/admin/driver-form?departmentId=${departmentId}&subdepartmentId=${subdepartmentId}&designationId=${id}`}>
                      <Button
                        variant="solid"
                        mt="32px"
                        colorScheme="blue"
                      >
                        + Driver
                        </Button>
                      </Link>:<></>
                    }
                    </>

                  );
                  
                }

                if (
                  permissionName === "EDIT" &&
                  moduleName === "DESIGNATION"
                ) {
                  return (
                    <Link href={`#/admin/designation-update-form/${id}`}>
                      <IconButton
                        key={permission.id}
                        colorScheme="green"
                        icon={<AiFillEdit />}
                        aria-label="Edit"
                      />
                    </Link>
                  );
                }

                if (
                  permissionName === "DELETE" &&
                  moduleName === "DESIGNATION"
                ) {
                  return (
                    <IconButton
                      key={permission.id}
                      colorScheme="red"
                      variant="outline"
                      icon={<BsFillTrashFill />}
                      aria-label="Delete"
                      onClick={onOpen}
                    />
                  );
                }

                return null;
              })}
            </ButtonGroup>
          ) : (
            <ButtonGroup>
              <Link href={`#/admin/designation-view/${id}`}>
                <IconButton
                  colorScheme="blue"
                  icon={<ViewIcon />}
                  aria-label="View"
                />
              </Link>
              <Link href={`#/admin/designation-update-form/${id}`}>
                <IconButton
                  colorScheme="green"
                  icon={<AiFillEdit />}
                  aria-label="Edit"
                />
              </Link>
              <IconButton
                colorScheme="red"
                variant="outline"
                icon={<BsFillTrashFill />}
                aria-label="Delete"
                onClick={() => handleDelete(id)}
              />
            </ButtonGroup>
          )}
        </Td>

        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Designation
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={() => { handleDelete(); onClose(); }} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Tr>
    </>
  );
};

export default GroupDesignationTableRow;
