import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  Spinner,
  Select
} from "@chakra-ui/react";
import dataConstant from "constants/dataConstant";
import { useDispatch, useSelector } from "react-redux";
import { sosAddOrUpdate } from "../../actions/sosAction";
import { checkpostGetAll } from "actions/checkpostActions";
import { deviceGetAll } from "actions/devicesAction";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader";
import { useToast } from '@chakra-ui/react'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react'
import Relect from 'react-select';
import axios from "axios";
import { server_url } from "constants/types";
import { SOS_CLEAR_SNACKBAR } from "constants/types";
import CustomInput from "views/CustomValidation";
import { useHistory } from 'react-router-dom';


function SosForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [photo, setPhoto] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [sosId, setSosId] = useState("");
  const [checkpost, setCheckpost] = useState([]);
  const [homeModule, setHomeModule] = useState([]);
  const [bloodGroup, setBloodGroup] = useState("");
  // const [checkpostList, setCheckpostList] = useState([]);
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [locality, setLocality] = useState("");
  const [state, setState] = useState("");
  const [sosDeviceNo, setSosDeviceNo] = useState([]);

  const [isErrorBloodGroup, setIsErrorBloodGroup] = useState(false);
  const [bloodGroupId, setBloodGroupId] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const history = useHistory();
  const textRegex = /^[A-Za-z\s,0-9]+$/;
  const sosIdRegex = /^[a-zA-Z0-9]+$/;
  const postalCodeRegex = /^[0-9]{6}$/;
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/;
  const numberRegex = /^\d{10}$/;

  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorPhoto, setIsErrorPhoto] = useState(false);
  const [isErrorNumber, setIsErrorNumber] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isErrorSosId, setIsErrorSosId] = useState(false);
  const [isErrorCheckpost, setIsErrorCheckpost] = useState(false);
  const [isErrorPostalcode, setIsErrorPostalcode] = useState(false);
  const [isErrorLine1, setIsErrorLine1] = useState(false);
  const [isErrorLine2, setIsErrorLine2] = useState(false);
  const [isErrorLocality, setIsErrorLocality] = useState(false);
  const [isErrorState, setIsErrorState] = useState(false);
  const [isErrorSosDeviceNo, setIsErrorSosDeviceNo] = useState(false);
  const [isErrorHomeModule, setIsErrorHomeModule] = useState(false);
  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.sos.status);
  const responseMessage = useSelector(state => state.sos.text);
  const loading = useSelector(state => state.sos.loading);
  const showSnackbar = useSelector(state => state.sos.showSnackbar);
  const checkpostList = useSelector(state => state.checkpost.checkpostList);
  const deviceList = useSelector(state => state.device.deviceList);

  console.log("deviceList....");
  console.log(deviceList);

  console.log("checkpostList");
  console.log(checkpostList);

  console.log(loading ? "yes" : "no")

  const dispatch = useDispatch();
  const toast = useToast();

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);

    setIsErrorName(name === '' || !textRegex.test(name));
    setIsErrorPhoto(photo === '');
    setIsErrorNumber(number === '');
    setIsErrorEmail(email === '');
    setIsErrorSosId(sosId === '' || !sosIdRegex.test(sosId));
    setIsErrorCheckpost(checkpost === '');
    setIsErrorPostalcode(postalCode === '' || !postalCodeRegex.test(postalCode));
    setIsErrorLine1(line1 === '');
    setIsErrorLine2(line2 === '');
    setIsErrorLocality(locality === '' || !textRegex.test(locality));
    setIsErrorState(state === '' || !textRegex.test(state));
    setIsErrorSosDeviceNo(sosDeviceNo === '');
    setIsErrorHomeModule(homeModule === '')
    setIsErrorBloodGroup(bloodGroup === '');

    if (
      name !== "" &&
      photo !== "" &&
      number !== "" &&
      email !== "" &&
      sosId !== "" &&
      checkpost.length !== 0 &&
      line1 !== "" &&
      line2 !== "" &&
      postalCode !== "" &&
      locality !== "" &&
      state !== ""
      &&
      bloodGroup !== "" &&
      sosDeviceNo.length !== 0
    ) {

      const newSos = {
        name,
        file: photo,
        number,
        email,
        sosId,
        bloodGroup: bloodGroup,
        'checkpost': checkpost.map((module) => module.value),
        'sosDeviceNo': sosDeviceNo.map((module) => module.value),
        homeModule: dataConstant.homeModule.map((module) => module.value),
        Address: [
          {
            line1,
            line2,
            postalCode,
            locality,
            state,
          }
        ],
      };
      console.log(newSos)
      dispatch(sosAddOrUpdate(newSos, 'add'));

    //   history.push("/admin/gaurd-table")
    }
  };

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
          history.push('#/admin/gaurd-table');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: SOS_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  
  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };


  useEffect(() => {
    dispatch(checkpostGetAll("&limit=100"));
    dispatch(deviceGetAll("&limit=100"));
  }, [])
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <form onSubmit={handleSubmit}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">

          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              sos Form
            </Text>
            <SimpleGrid minChildWidth='120px' spacing='40px'>
              <FormControl isInvalid={isErrorName || (submitted && name === "")}>
                <CustomInput
                  type='text'
                  label='Sos Name'
                  value={name}
                  onChange={(value) => {
                    setIsErrorName(value === '' || !textRegex.test(value));
                    setName(value);
                  }}
                  isInvalid={isErrorName || (submitted && name === "")}
                  regex={textRegex}
                  submitted={submitted}
                />
              </FormControl>
              <FormControl isInvalid={isErrorPhoto || (submitted && photo === "")}>
                <FormLabel>Photo</FormLabel>
                <Input
                  type='file'
                  onChange={(e) => {
                    setIsErrorPhoto(e.target.value === '')
                    setPhoto(e.target.files[0])
                  }}
                />
                {isErrorPhoto && submitted && (
                  <FormErrorMessage>Photo is required.</FormErrorMessage>
                )}
              </FormControl>
            </SimpleGrid>

            <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
              <FormControl isInvalid={isErrorNumber || (submitted && number === "")}>
                <CustomInput
                  type="number"
                  label="Phone Number"
                  value={number}
                  onChange={(value) => {
                    setIsErrorNumber(value === '' || !numberRegex.test(value));
                    setNumber(value);
                  }}
                  isInvalid={isErrorNumber || (submitted && number === "")}
                  regex={numberRegex}
                  submitted={submitted}
                />
              </FormControl>

              <FormControl isInvalid={isErrorBloodGroup || (submitted && bloodGroup === "")}>
                <FormLabel>Blood Group</FormLabel>
                <Select
                  placeholder="Blood Group"
                  onChange={(e) => {
                    setIsErrorBloodGroup(e.target.value === '');
                    setBloodGroup(e.target.value);

                  }}
                  value={bloodGroup}
                >
                  {dataConstant.bloodGroup.map((item, index) => (
                    <option key={item._id} value={item._id}>{item.name}</option>
                  ))}
                </Select>p
                {isErrorBloodGroup && submitted && (
                  <FormErrorMessage>Blood Group is required.</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={isErrorEmail || (submitted && email === "")}>
                <CustomInput
                  type="email"
                  label="Email"
                  value={email}
                  onChange={(value) => {
                    setIsErrorEmail(value === '' || !emailRegex.test(value));
                    setEmail(value);
                  }}
                  isInvalid={isErrorEmail || (submitted && email === "")}
                  regex={emailRegex}
                  submitted={submitted}
                />
              </FormControl>
            </SimpleGrid>

            <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
              <FormControl isInvalid={isErrorSosId || (submitted && sosId === "")}>
                <CustomInput
                  type='text'
                  label="Sos Id"
                  placeholder="Sos Id"
                  regex={sosIdRegex}
                  value={sosId}
                  onChange={(value) => {
                    setIsErrorSosId(value === '' || !sosIdRegex.test(value));
                    setSosId(value);
                  }}
                  isInvalid={isErrorSosId || (submitted && sosId === "")}
                  submitted={submitted}
                />
              </FormControl>
              {/* Home Module */}
              <FormControl >
                <FormLabel>Home Module</FormLabel>
                <Relect
                  isMulti
                  name="colors"
                  placeholder="--select Home Module--"
                  options={dataConstant.homeModule.map((item) => ({
                    value: item.value,
                    label: item.name
                  }))}

                  onChange={(value) => {
                    setHomeModule(value)
                    setIsErrorHomeModule(value === '')
                  }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
                {isErrorHomeModule && submitted && (
                  <FormErrorMessage>Home Module is required.</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={isErrorCheckpost || (submitted && checkpost === "")}>
                <FormLabel>Check post</FormLabel>
                <Relect
                  isMulti
                  name="colors"
                  placeholder="--select checkpost--"
                  options={checkpostList.map((item) => ({
                    value: item._id,
                    label: item.name
                  }))}

                  onChange={(value) => {
                    setCheckpost(value)
                    setIsErrorCheckpost(value === '')
                  }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />

                {isErrorCheckpost && submitted && (
                  <FormErrorMessage>Checkpost is required.</FormErrorMessage>
                )}
              </FormControl>
            </SimpleGrid>

            <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
              <FormControl isInvalid={isErrorLine1 || (submitted && line1 === "")}>
                <CustomInput
                  type='text'
                  label="Line 1"
                  placeholder="Line 1"
                  regex={textRegex}
                  value={line1}
                  onChange={(value) => {
                    setIsErrorLine1(value === '');
                    setLine1(value);
                  }}
                  isInvalid={isErrorLine1 || (submitted && line1 === "")}
                  submitted={submitted}
                />
              </FormControl>
              <FormControl isInvalid={isErrorLine2 || (submitted && line2 === "")}>
                <CustomInput
                  type='text'
                  label="Line 2"
                  placeholder="Line 2"
                  regex={textRegex}
                  value={line2}
                  onChange={(value) => {
                    setIsErrorLine2(value === '');
                    setLine2(value);
                  }}
                  isInvalid={isErrorLine2 || (submitted && line2 === "")}
                  submitted={submitted}
                />
              </FormControl>
            </SimpleGrid>

            <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
              <FormControl isInvalid={isErrorPostalcode || (submitted && postalCode === "")}>
                <CustomInput
                  type='number'
                  label="Postal Code"
                  placeholder="Postal Code"
                  regex={postalCodeRegex}
                  value={postalCode}
                  onChange={(value) => {
                    setIsErrorPostalcode(value === '' || !postalCodeRegex.test(value));
                    setPostalCode(value);
                  }}
                  isInvalid={isErrorPostalcode || (submitted && postalCode === "")}
                  submitted={submitted}
                />
              </FormControl>

              <FormControl isInvalid={isErrorLocality || (submitted && locality === "")}>
                <CustomInput
                  type='text'
                  label="Locality"
                  placeholder="Locality"
                  regex={textRegex}
                  value={locality}
                  onChange={(value) => {
                    setIsErrorLocality(value === '' || !textRegex.test(value));
                    setLocality(value);
                  }}
                  isInvalid={isErrorLocality || (submitted && locality === "")}
                  submitted={submitted}
                />
              </FormControl>


            </SimpleGrid>
            <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
              <FormControl isInvalid={isErrorState || (submitted && state === "")}>
                <CustomInput
                  type='text'
                  label="State"
                  placeholder="State"
                  regex={textRegex}
                  value={state}
                  onChange={(value) => {
                    setIsErrorState(value === '' || !textRegex.test(value));
                    setState(value);
                  }}
                  isInvalid={isErrorState || (submitted && state === "")}
                  submitted={submitted}
                />
              </FormControl>
              <FormControl isInvalid={isErrorSosDeviceNo || (submitted && state === "")}>
                <FormLabel>Devices</FormLabel>
                <Relect
                  isMulti
                  name="colors"
                  placeholder="--select device--"
                  options={deviceList.map((item) => ({
                    value: item.deviceId,
                    label: item.name
                  }))}

                  onChange={(value) => {
                    setIsErrorSosDeviceNo(value.length === 0);
                    setSosDeviceNo(value);
                  }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
                {/* <CustomInput
                  type='text'
                  label="Device Id"
                  placeholder="Device Id"
                  regex={textRegex}
                  value={sosDeviceNo}
                  onChange={(value) => {
                    setIsErrorSosDeviceNo(value === '');
                    setSosDeviceNo(value);
                  }}
                  isInvalid={isErrorSosDeviceNo || (submitted && sosDeviceNo === "")}
                  submitted={submitted}
                /> */}
              </FormControl>
            </SimpleGrid>
            <Center>
              {loading ? <Spinner mt='24px' size='lg' /> :
                <Button colorScheme='blue' mt='24px' type="submit">
                  Submit
                </Button>
              }
            </Center>
          </CardHeader>
          <CardBody>
            {/* ... */}
          </CardBody>
        </Card>
      </form>
    </Flex>
  );
}

export default SosForm;
