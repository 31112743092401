import axios from 'axios';
import {
    VISITORINOUT_LOADING,
    VISITORINOUT_GET_ALL_SUCCESS,
    VISITORINOUT_ERROR,
    VISITORINOUT_CLEAR_SNACKBAR
} from '../constants/types';

import { server_url } from '../constants/types';

export const VisitorInOutGetAll = (pagination = '') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: VISITORINOUT_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/visitor?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          dispatch({
            type: VISITORINOUT_GET_ALL_SUCCESS,
            payload: {
              visitorInOutList: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              totalItems: response.data.totalItems,
              visitorInOutListIN: [],
              VisitorListToday: [],
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all visitor successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: VISITORINOUT_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting visitor  data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const VisitorGetHeadCount = (pagination) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: VISITORINOUT_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/admin/visitor-headcount?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          console.log("materialListsṁ,hjwral;ifcalkfyogwei");
          console.log(response);
          dispatch({
            type: VISITORINOUT_GET_ALL_SUCCESS,
            payload: {
              visitorInOutListIN: response.data.data,
              visitorInOutList: [],
              VisitorListToday: [],
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all visitor successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: VISITORINOUT_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting visitor  data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};
export const visitorInOutToday = (pagination) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: VISITORINOUT_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/admin/todays-visitor?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          console.log("materialListsṁ,hjwral;ifcalkfyogwei");
          console.log(response);
          dispatch({
            type: VISITORINOUT_GET_ALL_SUCCESS,
            payload: {
              VisitorListToday: response.data.data,
              visitorInOutList : [],
              visitorInOutListIN : [],
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all visitor successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: VISITORINOUT_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting visitor  data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};



export const visitorGetById = (visitor) => {
  console.log("yes it is working")
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: VISITORINOUT_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/visitor-view/${visitor}`)
        .then((responseF) => {
          axios
            .get(`${server_url}/visitor`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
            .then((response) => {
              console.log("responseF.data['data']")
              console.log(responseF.data['data'])
              dispatch({
                type: VISITORINOUT_GET_ALL_SUCCESS,
                payload: {
                  visitorInOutList: response.data.data,
                  limit: response.data.limit,
                  page: response.data.currentPage,
                  totalPages: response.data.totalPages,
                  response: response.data,
                  visitorInOutDetail: responseF.data['data'],
                  loading: false,
                  status: responseF.data['status'],
                  text: responseF.data['message']
                }
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: VISITORINOUT_ERROR,
                payload: {
                  text: 'Error occurred during getting visitor data.',
                  status: 'error',
                  loading: false
                }
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: VISITORINOUT_ERROR,
            payload: {
              text: 'Error occurred during deleting visitor data.',
              status: 'error',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};
