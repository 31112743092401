// Import the action type constants for wrongEntry
import {
  WRONG_ENTRY_CLEAR_SNACKBAR,
  WRONG_ENTRY_LOADING,
  WRONG_ENTRY_ERROR,
  WRONG_ENTRY_GET_ALL_SUCCESS,
  WRONG_ENTRY_SEARCH // Add the new action type
} from '../constants/types';

const initialState = {
  response: {},
  wrongEntryList: [],
  wrongEntryDetail: {},
  logs: [],
  material: [],
  wrongEntry: [],
  loading: false,
  status: 'success',
  text: '',
  showSnackbar: false,
  limit: 0, // Add missing properties
  page: 0,
  totalPages: 0,
  searching: '' // Add missing properties
};

const WrongEntryReducer = (state = initialState, action) => {
  switch (action.type) {
    case WRONG_ENTRY_CLEAR_SNACKBAR:
      return {
        ...state,
        showSnackbar: false,
      };
    case WRONG_ENTRY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case WRONG_ENTRY_ERROR:
      return {
        ...state,
        wrongEntryList: [],
        response: {},
        wrongEntryDetail: {},
        logs: [],
        material: [],
        wrongEntry: [],
        limit: 0, // Add missing properties
        page: 0,
        totalPages: 0,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };
    case WRONG_ENTRY_GET_ALL_SUCCESS:
      return {
        ...state,
        wrongEntryList: action.payload.wrongEntryList,
        response: action.payload.response,
        wrongEntryDetail: action.payload.wrongEntryDetail,
        logs: action.payload.logs,
        material: action.payload.material,
        wrongEntry: action.payload.wrongEntry,
        limit: action.payload.limit,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };
    case WRONG_ENTRY_SEARCH: // Add the new case
      return {
        ...state,
        searching: action.payload.searching,
      };
    default:
      return state || {};
  }
};

export default WrongEntryReducer;
