import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useDispatch, useSelector } from "react-redux";

import { useToast } from '@chakra-ui/react';
import { useParams } from "react-router-dom";
import { SOS_CLEAR_SNACKBAR } from "constants/types";
import {
  Table,
  Tbody,
  Td,
  Tr,
  TableContainer,
} from '@chakra-ui/react';
import { Skeleton, SkeletonText } from '@chakra-ui/react';
import { sosAddOrUpdate } from "actions/sosAction";
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';

function SosView() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

const [createdBy, setCreatedBy] = useState("");
const [name, setName] = useState("");
const [contactNo, setContactNo] = useState("");
const [department, setDepartment] = useState("");
const [subDepartment, setSubDepartment] = useState("");
const [designation, setDesignation] = useState("");
const [blood, setBlood] = useState("");
const [photo, setPhoto] = useState("");

const [nameA, setNameA] = useState("");
const [acceptedBy, setAcceptedBy] = useState("");
const [contactNoA, setContactNoA] = useState("");
const [departmentA, setDepartmentA] = useState("");
const [subDepartmentA, setSubDepartmentA] = useState("");
const [designationA, setDesignationA] = useState("");
const [bloodA, setBloodA] = useState("");
const [photoA, setPhotoA] = useState("");
const [sosCategoryName, setSosCategoryName] = useState("");
const [status, setStatus] = useState("");



const history = useHistory();
  const dispatch = useDispatch();
  const toast = useToast();

  const responseStatus = useSelector(state => state.sos.status);
  const responseMessage = useSelector(state => state.sos.text);
  const loading = useSelector(state => state.sos.loading);
  const sosDetail = useSelector(state => state.sos.sosDetail);
  const showSnackbar = useSelector(state => state.sos.showSnackbar);


  console.log("sosDetail.././././././")
  console.log(sosDetail)

  const params = useParams();
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {

        setCreatedBy(sosDetail.createdBy);
        setName(sosDetail.createdDetails.name);
        setContactNo(sosDetail.createdDetails.contactNo);
        setDepartment(sosDetail.createdDetails.department);
        setSubDepartment(sosDetail.createdDetails.subdepartment);
        setDesignation(sosDetail.createdDetails.designation);
        setBlood(sosDetail.createdDetails.bloodGroup);
        setPhoto(sosDetail.createdDetails.photo);

        setNameA(sosDetail.acceptedDetails.name);
        setContactNoA(sosDetail.acceptedDetails.contactNo);
        setDepartmentA(sosDetail.acceptedDetails.department);
        setSubDepartmentA(sosDetail.acceptedDetails.subdepartment);
        setDesignationA(sosDetail.acceptedDetails.designation);
        setBloodA(sosDetail.acceptedDetails.bloodGroup);
        setPhotoA(sosDetail.acceptedDetails.photo);
        setAcceptedBy(sosDetail.acceptedDetails.acceptedBy);

        setSosCategoryName(sosDetail.sosCategory.name);
        setStatus(sosDetail.status);

        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }

    dispatch({
      type: SOS_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    });
  }, [loading]);

  useEffect(() => {
    const sosId = params.id;
    const sos = {
      _id: sosId
    };
    dispatch(sosAddOrUpdate(sos, 'view'));
  }, [params.id]);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  console.log("sosDetail")
  console.log(sosDetail)

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="20px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            SOS view
          </Text>
        </CardHeader>
        <CardBody>
          {loading ? <SkeletonText noOfLines={3} spacing="4" /> :
            <TableContainer>
              <Table variant='simple'>
                <Tbody>
                  <Tr>
                    <Td>Created by</Td>
                    <Td>{createdBy}</Td>
                  </Tr>
                  <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Created By Details
          </Text>
        </CardHeader>
        <CardBody>
          {loading ? <SkeletonText noOfLines={3} spacing="4" /> :
            <TableContainer>
              <Table variant='simple'>
                <Tbody>
                  <Tr>
                    <Td>Name</Td>
                    <Td>{name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Contact Number</Td>
                    <Td>{contactNo}</Td>
                  </Tr>
                  <Tr>
                    <Td>Department</Td>
                    <Td>{department}</Td>
                  </Tr>
                  <Tr>
                    <Td>Sub-Department</Td>
                    <Td>{subDepartment}</Td>
                  </Tr>
                  <Tr>
                    <Td>Designation</Td>
                    <Td>{designation}</Td>
                  </Tr>
                  <Tr>
                    <Td>Blood </Td>
                    <Td>{blood}</Td>
                  </Tr>
                  <Tr>
                    <Td>Image</Td>
                    <Td>
                      <Flex direction="column">
                        <Image
                          boxSize='100px'
                          objectFit='cover'
                          src={photo}
                          alt='Sos Image'
                        />
                      </Flex>
                    </Td>
                  </Tr>


                </Tbody>
              </Table>
            </TableContainer>
          }
        </CardBody>
        
                  </Card>
                  <Tr>
                    <Td>Accepted by</Td>
                    <Td>{acceptedBy}</Td>
                  </Tr>
                  <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Accepted By Details
          </Text>
        </CardHeader>
        <CardBody>
  {loading ? (
    <SkeletonText noOfLines={3} spacing="4" />
  ) : 
    <TableContainer>
      <Table variant='simple'>
        <Tbody>
          <Tr>
            <Td>Name</Td>
            <Td>{nameA}</Td>
          </Tr>
          <Tr>
            <Td>Contact Number</Td>
            <Td>{contactNoA}</Td>
          </Tr>
          <Tr>
            <Td>Department</Td>
            <Td>{departmentA}</Td>
          </Tr>
          <Tr>
            <Td>Sub-Department</Td>
            <Td>{subDepartmentA}</Td>
          </Tr>
          <Tr>
            <Td>Designation</Td>
            <Td>{designationA}</Td>
          </Tr>
          <Tr>
            <Td>Blood</Td>
            <Td>{bloodA}</Td>
          </Tr>
          <Tr>
            <Td>Image</Td>
            <Td>
              <Flex direction="column">
                <Image
                  boxSize='100px'
                  objectFit='cover'
                  src={photoA}
                  alt='Sos Image'
                />
              </Flex>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
 
  }
</CardBody>


                  </Card>
                  <Tr>
                    <Td>Sos Category Name</Td>
                    <Td>{sosCategoryName}</Td>
                  </Tr>
                  <Tr>
                    <Td>Status</Td>
                    <Td>{status}</Td>
                  </Tr>


                </Tbody>
              </Table>
            </TableContainer>
          }
        </CardBody>
      </Card>
    </Flex>
  );
}

export default SosView;
