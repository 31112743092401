import { 
    USER_LOADING, 
    USER_GET_ALL_SUCCESS, 
    USER_ERROR,
    USER_CLEAR_SNACKBAR
} from '../constants/types';

const initialState = {
    userList: [],
    response : {},
    userList: [],
    userDetail: {},
    loading: false,
    status: "success",
    text: "",
    showSnackbar: false
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_CLEAR_SNACKBAR: 
            return {
                ...state,
                showSnackbar: false
            }
        case USER_LOADING: 
            return {
                ...state,
                loading: true,
            }
        case USER_ERROR:
            return {
                ...state,
                userList: [],
                response : {},
                userDetail : {},
                loading: false,
                status: action.payload.status,
                text: action.payload.text,
                showSnackbar: true
            }
        case USER_GET_ALL_SUCCESS:
            return {
                ...state,
                userList: action.payload.userList,
                response: action.payload.response,
                userDetail: action.payload.userDetail,
                loading: false,
                status: action.payload.status,
                text: action.payload.text,
                showSnackbar: true
            }
        default: 
            return state || {};
    }
}

export default userReducer;