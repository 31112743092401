// Chakra imports
import {
  Flex,
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  WrapItem,
  TableContainer,
  Image,
  Stack,
  useColorModeValue,
  Wrap,
  HStack,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  materialClearShowSnackbar,
  materialAddOrUpdate,
} from "../../actions/materialAction";
import { useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { MATERIAL_CLEAR_SNACKBAR } from "constants/types";
import { Skeleton, SkeletonCircle, SkeletonText } from "@chakra-ui/react";
// import { materialClearShowSnackbar } from "actions/materialAction";
import axios from "axios";
import { server_url } from "constants/types";
import { useHistory } from "react-router-dom";
function MaterialView() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [mode, setMode] = useState("");
  const [material, setMaterialBy] = useState("");
  const [mobile, setMobileNumber] = useState("");
  const [vehicle, setVehicleNumber] = useState("");
  const [shipment, setMaterialShipmentType] = useState("");
  const [returnDate, setReturnDate] = useState("");
  const [guardName, setSubName] = useState("");
  const [guardStatus, setGuardStatus] = useState("");
  const [guardCreated, setCreatedAt] = useState("");
  const [guardUpdate, setUpdatedAt] = useState("");
  const [deptName, setSubDeptName] = useState("");
  // const [newCreated,setCreatedAt ] = useState("");
  // const [newUpdate,setUpdatedAt ] = useState("");
  const [matStatus, setMaterialStatus] = useState("");
  const [matType, setMaterialType] = useState("");
  const [matWeigh, setMaterialWeigh] = useState("");
  const [txn, setMaterialTxn] = useState("");
  const [newName, setGuardName] = useState("");
  const [newNumber, setGuardNumber] = useState("");
  const [newMail, setGuardEmail] = useState("");
  const [newDevice, setDevice] = useState("");
  const [pinCode, setPostal] = useState("");
  const [local, setLocality] = useState("");
  const [state, setState] = useState("");
  const [guardId, setGuardId] = useState("");
  const [pass, setPasCode] = useState("");
  const [photo, setPhoto] = useState("");
  // const [set,setCreatedAt ] = useState("");
  // const [,setUpdatedAt ] = useState("");

  const [vendor, setVendor] = useState("");
  const [type, setType] = useState("");
  const [weighment, setWeighment] = useState("");
  const [gatePassNum, setGatePassNum] = useState("");
  const [gatePassImage, setGatePassImage] = useState("");
  const [gatePass, setCreatePass] = useState("");
  const [reasonOfRejection, setRejectReason] = useState("");
  const [helperName, setHelperName] = useState("");
  const [helperMobileNo, setHelperMobileNo] = useState("");
  const [licenceValidity, setLicenceValidity] = useState("");
  

  const history = useHistory();
  const dispatch = useDispatch();
  const toast = useToast();

  const statuses = ["success", "error", "warning", "info"];
  const responseStatus = useSelector((state) => state.material.status);
  const responseMessage = useSelector((state) => state.material.text);
  const loading = useSelector((state) => state.material.loading);
  // const designationDetail = useSelector(state => state.material.designationDetail);
  const materialDetail = useSelector((state) => state.material.materialDetail);
  const showSnackbar = useSelector((state) => state.material.showSnackbar);
  const logs = useSelector((state) => state.material.logs);
  const materialData = useSelector((state) => state.material.material);

  console.log("naterialData././././././././.");
  console.log(materialDetail);
  // console.log(materialData);

  const params = useParams();
  //  console.log("shasjgsadgjdagjadsjg-------");
  // console.log(materialDetail.logs)
  // console.log("materialDetl--materialDetail")
  // console.log(material);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === "OK" && loading === false) {
        console.log("logs");
        console.log(logs);
        console.log("logs");
        setName(materialDetail.department.name);
        setMode(materialDetail.mode);
        setMaterialBy(materialDetail.materialBy);
        setMobileNumber(materialDetail.mobileNumber);
        setVehicleNumber(materialDetail.vehicleNumber);
        setMaterialShipmentType(materialDetail.materialShipmentType);
        setReturnDate(materialDetail.returnDate);
        // setSubName(materialDetail.subdepartment.department.name)
        // setStatus(materialDetail.department.status)
        setCreatedAt(materialDetail.department.createdAt);
        setUpdatedAt(materialDetail.department.updatedAt);
        setSubDeptName(materialDetail.subdepartment.name);
        setCreatedAt(materialDetail.subdepartment.createdAt);
        setUpdatedAt(materialDetail.subdepartment.updatedAt);

        setMaterialStatus(materialDetail.status);
        setMaterialType(materialDetail.type);
        setMaterialWeigh(materialDetail.weighment);
        setMaterialTxn(materialDetail.txnNumber);
        setGuardName(materialDetail.guard.name);
        setGuardNumber(materialDetail.guard.number);
        setGuardEmail(materialDetail.guard.email);
        setDevice(materialDetail.guard.guardDeviceNo);
        // setPostal(materialDetail.Address.postalCode)
        setLocality(materialDetail.guard.Address[0].locality);
        setState(materialDetail.guard.Address[0].state);

        setGuardId(materialDetail.guard.guardId);
        setPasCode(materialDetail.guard.passcode);
        setPhoto(materialDetail.guard.photo);
        setCreatedAt(materialDetail.guard.createdAt);
        setUpdatedAt(materialDetail.guard.updatedAt);

        setStatus(materialDetail.guard.status);
        setVendor(materialDetail.vendor);
        setType(materialDetail.type);
        setWeighment(materialDetail.weighment);
        setGatePassNum(materialDetail.gatepassNumber)
        setGatePassImage(materialDetail.gatepassPhoto)
        setCreatePass(materialDetail.createdGatePass)
        setRejectReason(materialDetail.reasonOfRejection)
        setHelperName(materialDetail.otherDetails.HelperName)
        setHelperMobileNo(materialDetail.otherDetails.HelperMobileNo)
        setLicenceValidity(materialDetail.otherDetails.licenceValidity)

        setStatus(materialDetail.status ? "Active" : "inActive");
        if (responseMessage !== "") {
          toastFunction(responseMessage, "success");
        }
      } else if (responseStatus === "NOK" && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, "error");
        }
      }
      dispatch({
        type: MATERIAL_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        },
      });
    }
  }, [loading]);

  useEffect(() => {
    const materialId = params.id;
    const material = {
      _id: materialId,
    };
    dispatch(materialAddOrUpdate(material, "view"));
  }, []);

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
      })
      .then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push("/auth/signin");
      });
  }, []);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px" mb={4}>
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Material View
          </Text>
        </CardHeader>
        <CardBody>
          {loading ? (
            <Stack>
              <Skeleton height="20px" />
            </Stack>
          ) : materialDetail ? (
            <TableContainer>
              <Table variant="simple">
                <Tbody mb={4}>
                  {/* <Tr>
                      <Td>Name</Td>
                      <Td>{name}</Td>
                    </Tr> */}
                  {/* <Tr>
                      <Td>Description</Td>
                      <Td>{description}</Td>
                    </Tr> */}
                  {/* <Tr>
                      <Td>Status</Td>
                      <Td>{status}</Td>
                    </Tr> */}
                  <Tr>
                    <Td>Mode</Td>
                    <Td>{mode}</Td>
                  </Tr>
                  <Tr>
                    <Td>Vendor</Td>
                    <Td>{vendor}</Td>
                  </Tr>

                  <Tr>
                    <Td>Mobile</Td>
                    <Td>{mobile}</Td>
                  </Tr>
                  <Tr>
                    <Td>Status</Td>
                    <Td>{status}</Td>
                  </Tr>
                  <Tr>
                    <Td>Type</Td>
                    <Td>{type}</Td>
                  </Tr>
                  <Tr>
                    <Td>Weighment</Td>
                    <Td>{weighment}</Td>
                  </Tr>
                  <Tr>
                    <Td>Material By </Td>
                    <Td>{material}</Td>
                  </Tr>
                  <Tr>
                    <Td>Vehicle Number</Td>
                    <Td>{vehicle}</Td>
                  </Tr>
                  <Tr>
                    <Td>Gate pass Number</Td>
                    <Td>{gatePassNum}</Td>
                  </Tr>
                  <Tr>
                    <Td>Gate pass Image</Td>
                    <Image src ={gatePassImage}  style={{ maxWidth: "120px" }}></Image>
                  </Tr>

                  {/* <Tr>
                    <Td>Create Gate Pass</Td>
                    <Td>{gatePass}</Td>
                  </Tr> */}
                  <Tr>
                    <Td>Material Status</Td>
                    <Td>{matStatus}</Td>
                  </Tr>
                  <Tr>
                    <Td>Shipment</Td>
                    <Td>{shipment}</Td>
                  </Tr>
                  {shipment == 'RETURNABLE'?<Tr>
                      <Td>Return Date</Td>
                      <Td>{returnDate}</Td>
                    </Tr>:<></>}
                  <Tr>
                    <Td>Reason For Rejection</Td>
                    <Td>{reasonOfRejection}</Td>
                  </Tr>
                  
                  {/* <Tr>
                      <Td>guardName</Td>
                      <Td>{guardName}</Td>
                    </Tr>
                    <Tr>
                      <Td>guardStatus</Td>
                      <Td>{guardStatus}</Td>
                    </Tr> */}
                  {/* <Tr>
                      <Td>Guard Created</Td>
                      <Td>{guardCreated}</Td>
                    </Tr>
                    <Tr>
                      <Td>Guard Update</Td>
                      <Td>{guardUpdate}</Td>
                    </Tr> */}
                  {/* <Tr>
                    <Td>Dept Name</Td>
                    <Td>{deptName}</Td>
                  </Tr> */}
                  
                  {/* <Tr>
                    <Td>Material Type</Td>
                    <Td>{matType}</Td>
                  </Tr>
                  <Tr>
                    <Td>Material Weigh</Td>
                    <Td>{matWeigh}</Td>
                  </Tr> */}
                  <Tr>
                    <Td>Txn</Td>
                    <Td>{txn}</Td>
                  </Tr>
                  <Tr>
                    <Td>Licence Validity</Td>
                    <Td>{licenceValidity}</Td>
                  </Tr>

                  <Tr>
                    <Td>Helper Name</Td>
                    <Td>{helperName}</Td>
                  </Tr>
                  <Tr>
                    <Td>Helper Photo Number</Td>
                    <Td>{helperMobileNo}</Td>
                  </Tr>
                  {/* <Tr>
                    <Td>New Name</Td>
                    <Td>{newName}</Td>
                  </Tr>
                  <Tr>
                    <Td>New Number</Td>
                    <Td>{newNumber}</Td>
                  </Tr>
                  <Tr>
                    <Td>New Mail</Td>
                    <Td>{newMail}</Td>
                  </Tr>
                  <Tr>
                    <Td>New Device</Td>
                    <Td>{newDevice}</Td>
                  </Tr> */}
                  {/* <Tr>
                      <Td>Postal</Td>
                      <Td>{pinCode}</Td>
                    </Tr> */}
                  {/* <Tr>
                    <Td>Locality</Td>
                    <Td>{local}</Td>
                  </Tr>
                  <Tr>
                    <Td>State</Td>
                    <Td>{state}</Td>
                  </Tr>
                  <Tr>
                    <Td>GuardId</Td>
                    <Td>{guardId}</Td>
                  </Tr> */}
                  {/* <Tr>
                    <Td>Entry-Code</Td>
                    <Td>{pass}</Td>
                  </Tr> */}
                  <Tr>
                    <Td>Image</Td>
                    <Td>
                      <img src={photo} style={{ maxWidth: "120px" }} />
                    </Td>
                  </Tr>

                  <Tr>
                    <Td>Logs</Td>
                    <Td>
                      {logs.map((log) => {
                        return (
                          <ul>
                            <li>
                              {log.description} at {log.formattedDateTime}
                            </li>
                            {/* <li>log.dateTime</li> */}
                          </ul>
                        );
                      })}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          ) : null}
        </CardBody>
      </Card>

      <Flex direction="row" gap="15px">
        {materialData.map((hello) => {
          return (
            <Wrap spacing={4} justify="center" align="center">
              <Box>
                <Card mb={4}>
                  <Text fontSize="xl" color={textColor} fontWeight="bold">
                    Material
                  </Text>
                  <ul style={{ listStyleType: "none" }}>
                    <li>
                      <img src={hello.invoice} alt="" srcset="" />
                    </li>
                    <br />
                    <li>Invoice Number = {hello.invoiceNumber}</li>
                    <li>MaterialDescription = {hello.materialDescription}</li>
                    <li>Quantity = {hello.quantity}</li>
                  </ul>
                </Card>
              </Box>
            </Wrap>
          );
        })}
      </Flex>
    </Flex>
  );
}
export default MaterialView;
