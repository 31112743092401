import React, { useEffect, useState, useRef } from 'react';
import { Flex, Button, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link, SimpleGrid, FormControl, Center, Spinner, FormLabel, Select } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
// import DevicePagination from './DevicePagination';
import { AddIcon } from '@chakra-ui/icons';
import { EMPLOYEE_CLEAR_SNACKBAR } from 'constants/types';
// import {  } from '../../actions/devicesAction'; // Assuming you have a DeviceAction file
// import DeviceTableRow from './DevicesTableRow';
import { employeeGetAll } from 'actions/EmployeeAction';
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory,useLocation } from 'react-router-dom';
import dataConstant from 'constants/dataConstant';
const {
  EMPLOYEE_SEARCH
} = require('../../constants/types');
const EmployeeDesignation = (props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { url = "", plant,selectedDepartment,selectedSubdepartment,selectedDesignation } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.iamSafe.status);
  const responseMessage = useSelector(state => state.iamSafe.text);
  const loading = useSelector(state => state.employee.loading);
  const iamSafeList = useSelector(state => state.iamSafe.iamSafeList);
  const response = useSelector(state => state.iamSafe.response);
  const showSnackbar = useSelector(state => state.iamSafe.showSnackbar);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const history = useHistory();
  const tableRef = useRef(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [department , setDepartment] = useState("");
  const [subdepartment , setSubdepartment] = useState("");
  const [designation , setDesignation] = useState("");
  const [status , setStatus] = useState("");
  const [departmentList , setDepartmentList] = useState([]);
  const [subDepartmentList , setSubdepartmentList] = useState([]);
  const [designationList , setDesignationList] = useState([]);
  const [submitted, setSubmitted] = useState(false);


  useEffect(() => {
    
    
    departmentListforDropdown();
    subdepartmentListforDropdown(searchParams.get('departmentId'));
    designationListforDropdown(searchParams.get('subdepartmentId'));
  }, [dispatch]);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: EMPLOYEE_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const departmentListforDropdown = () => {
    axios
      .get(`${server_url}/department`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        setDepartmentList(responce.data.data)
      }).catch(() => console.log("error"))
  }

  const subdepartmentListforDropdown = (depId) => {
    if (depId) {
      axios
        .get(`${server_url}/plant/subdepartment-list/${depId}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          setSubdepartmentList(responce.data.data)
        })
    }
    else {
      setSubdepartmentList([])
    }

  }
  const designationListforDropdown = (subdepId) => {
    if (subdepId) {
      axios
        .get(`${server_url}/plant/designation-list/${subdepId}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          setDesignationList(responce.data.data)
        })
    }
    else {
      setDesignationList([])
    }

  }
  return (
    <>
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Adding Employee For
          </Text>
          

            <SimpleGrid minChildWidth="120px" spacing="40px" mt='20px'>
            <FormControl >
              <FormLabel>Department</FormLabel>
              <Select
                placeholder="Department"
                onChange={(e) => {
                  setDepartment(e.target.value);
                  subdepartmentListforDropdown(e.target.value);
                }}
                value={searchParams.get('departmentId')}
                disabled={true}
              >
                {departmentList ? (departmentList.map((item, index) => (
                  <option key={item._id} value={item._id}>{item.name}</option>
                ))) : (<></>)}
              </Select>
              
            </FormControl>

            <FormControl >
              <FormLabel>Sub Department</FormLabel>
              <Select
                placeholder="Subdepartment"
                onChange={(e) => {
                  setSubdepartment(e.target.value);
                  designationListforDropdown(e.target.value);
                }}
                value={searchParams.get('subdepartmentId')}
                disabled={true}
              >
                {subDepartmentList ? (subDepartmentList.map((item, index) => (
                  <option key={item._id} value={item._id}>{item.name}</option>
                ))) : (<></>)}
              </Select>
              
            </FormControl>
            <FormControl >
              <FormLabel>Designation</FormLabel>
              <Select
                placeholder="designation"
                onChange={(e) => {
                    setDesignation(e.target.value);
                }}
                value={searchParams.get('designationId')}
                disabled={true}
              >
                {designationList ? (designationList.map((item, index) => (
                  <option key={item._id} value={item._id}>{item.name}</option>
                ))) : (<></>)}
              </Select>
              
            </FormControl>


          </SimpleGrid>
          
        </CardHeader>
        <CardBody></CardBody>
      </Card>
    </Flex>
    </>
  );
};

export default EmployeeDesignation;
