// Chakra imports
import {
    Box,
    Button,
    Center,
    Flex,
    Input,
    SimpleGrid,
    Spacer,
    Text,
    Wrap,
    WrapItem,
    useColorModeValue,
    Select,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from 'react-redux';
  import { bannerPositionAddOrUpdate, bannerPositionClearShowSnackbar } from '../../actions/bannerpositionAction';
  import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
  } from '@chakra-ui/react'
  import { useToast } from '@chakra-ui/react'
  import { BANNER_POSITION_CLEAR_SNACKBAR } from "constants/types";
  import { Spinner } from '@chakra-ui/react'
  import dataConstant from "constants/dataConstant";
  import { useHistory } from 'react-router-dom';
  import { cityGetAll } from "actions/CityActions";
  import { bannerPositionGetAll } from "../../actions/bannerpositionAction";
  import axios from 'axios';
  import { server_url } from '../../constants/types';


  function BannerPositionForm() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [name, setName] = useState("");
    const [position, setPosition] = useState("");
    const [description, setDesc] = useState("");


    const [status, setStatus] = useState("");
    const [bannerType, setType] = useState("");
    const [type, setPopType] = useState("");
    const [city , setCity] = useState("");
  
    const [isErrorName, setIsErrorName] = useState(false);
    const [isErrorPosition, setIsErrorPosition] = useState(false);
    const [isErrorDesc, setIsErrorDesc] = useState(false);

    
    const [isErrorType, setIsErrorType] = useState(false);
    const [isErrorStatus, setIsErrorStatus] = useState(false);
    const [isErrorRole, setIsErrorRole] = useState(false);
    const [isErrorCity , setIsErrorCity] = useState(false);
  
    const dispatch = useDispatch();
    const toast = useToast();
    const history = useHistory();
    const responseStatus = useSelector(state => state.bannerposition.status);
    const responseMessage = useSelector(state => state.bannerposition.text);
    const loading = useSelector(state => state.bannerposition.loading);
    const showSnackbar = useSelector(state => state.bannerposition.showSnackbar);
    const cityList = useSelector(state => state.city.cityList);
    console.log(" citylist./././././././././/");
    console.log(cityList);

    useEffect(()=>{
      dispatch(cityGetAll())
    }, [dispatch])
  
    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
    }, [])


    // Function to handle form submission
    const handleSubmit = (event) => {
      event.preventDefault();
      setIsErrorName(name === '');
      setIsErrorType(bannerType === '');
      setIsErrorStatus(status === '');
      setIsErrorPosition(position === '');
      setIsErrorCity(city === "");

      if (name !== "" && bannerType !== "" && status !== "" && position !== "" && city !== ""  ) {
        console.log("Submit");
        // Get form data
        const bannerPositionData = {
          name: name,
          position: position,
          bannerType: bannerType,
          status: status,
          city : city,

          // Other banner position data fields
        };
        console.log("bannerPositionData");
        console.log(bannerPositionData);
        console.log("bannerPositionData");
  
        // Determine if it's an add or edit operation
        const state = 'add';
        // Call the bannerPositionAddOrUpdate action
        dispatch(bannerPositionAddOrUpdate(bannerPositionData, state))
        // history.push("/admin/banner-position")
      }
    };
  
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === 'OK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'success');
            history.push('#/admin/banner-position');
            // history.push('#/admin/banner-position');
          }
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'error');
          }
        }
      }
      dispatch({
        type: BANNER_POSITION_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        }
      })
    }, [loading]);
  
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      })
    };

    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
    }, [])

    useEffect(() => {
      dispatch(bannerPositionGetAll("&limit=100"));
    }, [dispatch]);
  
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Banner Position Form
            </Text>
            <SimpleGrid minChildWidth='120px' spacing='40px'>
              
              <FormControl isInvalid={isErrorName}>
                <FormLabel>Name</FormLabel>
                <Input
                  type='text'
                  onChange={(e) => {
                    setIsErrorName(e.target.value === '')
                    setName(e.target.value)
                  }}
                />
                {!isErrorName ? (
                  <></>
                ) : (
                  <FormErrorMessage>Name is required.</FormErrorMessage>
                )}
              </FormControl>
  
              <FormControl isInvalid={isErrorPosition}>
                <FormLabel>Position</FormLabel>
                <Input
                  type='text'
                  onChange={(e) => {
                    setIsErrorPosition(e.target.value === '')
                    setPosition(e.target.value)
                  }}
                />
                {!isErrorPosition ? (
                  <></>
                ) : (
                  <FormErrorMessage>Position is required.</FormErrorMessage>
                )}
              </FormControl>
            </SimpleGrid>

            <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
              <FormControl isInvalid={isErrorStatus}>
                <FormLabel>Status</FormLabel>
                <Select
                  placeholder="Select status"
                  onChange={(e) => {
                    setIsErrorStatus(e.target.value === '')
                    setStatus(e.target.value)
                  }}
                >
                  { dataConstant.status.map((element,index) => {
                      return (<option key={element['id']} value={element['value']} >{element['name']}</option>);   
                        
                    })}
                </Select>
                {!isErrorStatus ? (
                  <></>
                ) : (
                  <FormErrorMessage>Status is required.</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={isErrorType}>
                <FormLabel>Type</FormLabel>
                <Select
                  placeholder="Select Banner Type"
                  onChange={(e) => {
                    setIsErrorType(e.target.value === '')
                    setType(e.target.value)
                  }}
                >
                  { dataConstant.bannerType.map((element,index) => {
                      return (<option key={element['name']} value={element['name']} >{element['name']}</option>);   
                        
                    })}
                </Select>
                {!isErrorType ? (
                  <></>
                ) : (
                  <FormErrorMessage>Type is required.</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={isErrorCity}>
                
              <FormLabel>City</FormLabel>
              <Select
                placeholder="Select City"
                onChange={(e) => {
                  setIsErrorCity(e.target.value === '')
                  setCity(e.target.value)
                }}
              >
                { cityList.map((element,index) => {
                  console.log("elemrnt");
                  console.log(element);
                    return (<option key={element['_id']} value={element['_id']} >{element['name']}</option>);   
                      
                  })}
              </Select>
              {!isErrorCity ? (
                <></>
              ) : (
                <FormErrorMessage>City is required.</FormErrorMessage>
              )}
            </FormControl>
            </SimpleGrid>

            <Center>
              {loading ? <Spinner mt='24px' size='lg' /> :
                <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                  Submit
                </Button>
              }
            </Center>
          </CardHeader>
          <CardBody>
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default BannerPositionForm;
  