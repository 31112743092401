// Chakra imports
import {
    Box,
    Button,
    Center,
    Flex,
    Input,
    SimpleGrid,
    Spacer,
    Text,
    Wrap,
    WrapItem,
    useColorModeValue,
    Select,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from 'react-redux';
  import { delivaryAddOrUpdate, delivaryClearShowSnackbar } from '../../actions/delivaryAction';
  import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
  } from '@chakra-ui/react'
  import { useToast } from '@chakra-ui/react'
  import { DELIVARY_CLEAR_SNACKBAR } from "constants/types";
  import { Spinner } from '@chakra-ui/react'
  import dataConstant from "constants/dataConstant";
  import { useHistory } from 'react-router-dom'; 
  import { useParams } from "react-router-dom/cjs/react-router-dom.min";
  function DelivaryForm() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [status, setStatus] = useState("");
    const [image, setImage] = useState("");
    const [deliveryType ,setDeliveryType] = useState("")
  
    const [alertMessage, setAlertMessage] = useState('');
    const [isErrorName, setIsErrorName] = useState(false);
    const [isErrorDescription, setIsErrorDescription] = useState(false);
    const [isErrorImage, setIsErrorImage] = useState(false);
    const [isErrorStatus, setIsErrorStatus] = useState(false);
    const [isErrorDeliveryType , setIsErrorDeliveryType] = useState(false);
    const params = useParams();
  
    const dispatch = useDispatch();
    const toast = useToast();
    const history = useHistory();
    const statuses = ['success', 'error', 'warning', 'info'];
    const responseStatus = useSelector(state => state.delivary.status);
    const responseMessage = useSelector(state => state.delivary.text);
    const loading = useSelector(state => state.delivary.loading);
    const showSnackbar = useSelector(state => state.delivary.showSnackbar);
  
    // Function to handle form submission
    const handleSubmit = (event) => {
      event.preventDefault();
      setIsErrorName(name === '');
      setIsErrorImage(image === '');
      setIsErrorStatus(status === '');
      // setIsErrorDescription(description === '');
      setIsErrorDeliveryType(deliveryType === '');

      if (name !== "" && image !== "" && status !== ""  && deliveryType !== "") {
        console.log("Submit");
       
        const delivaryData = {
          name: name,
          description: description,
          image: image,
          status: status,
          deliveryType : deliveryType,
          plantAdmin : params.id
          
        };
        console.log(delivaryData);
  
        // Determine if it's an add or edit operation
        const state = 'add';
        // Call the delivaryAddOrUpdate action
        dispatch(delivaryAddOrUpdate(delivaryData, state))
      }
    };
  
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === 'OK' && loading === false) {
          if (responseMessage !== "") {
            history.goBack();
            toastFunction(responseMessage, 'success');
            
          }
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'error');
          }
        }
      }
      dispatch({
        type: DELIVARY_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        }
      })
    }, [loading]);
  
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      })
    };
  
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Form
            </Text>
            <SimpleGrid minChildWidth='120px' spacing='40px'>
              <FormControl isInvalid={isErrorName}>
                <FormLabel>Name</FormLabel>
                <Input
                  type='text'
                  onChange={(e) => {
                    setIsErrorName(e.target.value === '')
                    setName(e.target.value)
                  }}
                />
                {!isErrorName ? (
                  <></>
                ) : (
                  <FormErrorMessage>Name is required.</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={isErrorDescription}>
                <FormLabel>Description</FormLabel>
                <Input
                  type='text'
                  onChange={(e) => {
                    // setIsErrorDescription(e.target.value === '')
                    setDescription(e.target.value)
                  }}
                />
                {!isErrorDescription ? (
                  <></>
                ) : (
                  <FormErrorMessage>Description is required.</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={isErrorDeliveryType}>
                <FormLabel>Delivery Type</FormLabel>
                <Select
                  placeholder="Select Delivery Type"
                  onChange={(e) => {
                    setIsErrorDeliveryType(e.target.value === '')
                    setDeliveryType(e.target.value)
                  }}
                >
                  {
                    dataConstant.deliveryType.map((ele , index)=>{          
                        return (<option key={ele['id']} value={ele['value']} >{ele['name']}</option>);    
                    })
                  }
                   </Select>
                {!isErrorDeliveryType ? (
                  <></>
                ) : (
                  <FormErrorMessage>Delivery Type is required.</FormErrorMessage>
                )}
              </FormControl>
             
            </SimpleGrid>
            <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
              <FormControl isInvalid={isErrorStatus}>
                <FormLabel>Status</FormLabel>
                <Select
                  placeholder="Select status"
                  onChange={(e) => {
                    setIsErrorStatus(e.target.value === '')
                    setStatus(e.target.value)
                  }}
                >
                  { dataConstant.status.map((element,index) => {
                      return (<option key={element['id']} value={element['value']} >{element['name']}</option>);   
                        
                    })}
                </Select>
                {!isErrorStatus ? (
                  <></>
                ) : (
                  <FormErrorMessage>Status is required.</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={isErrorImage}>
                <FormLabel>Image</FormLabel>
                <Input
                  type='file'
                  onChange={(e) => {
                    setIsErrorImage(e.target.value === '')
                    setImage(e.target.files[0])
                  }}
                />
                {!isErrorImage ? (
                  <></>
                ) : (
                  <FormErrorMessage>Image is required.</FormErrorMessage>
                )}
              </FormControl>
            
            </SimpleGrid>
            <Center>
              {loading ? <Spinner mt='24px' size='lg' /> :
                <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                  Submit
                </Button>
              }
            </Center>
          </CardHeader>
          <CardBody>
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default DelivaryForm;
  