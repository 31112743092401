// Chakra imports
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { factoryAddOrUpdate, factoryClearShowSnackbar } from "../../actions/FactoryActions";
import { useToast } from "@chakra-ui/react";
import { FACTORY_CLEAR_SNACKBAR } from "constants/types";
import { Spinner } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

function FactoryForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [factoryName, setFactoryName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhoneNumber, setContactPhoneNumber] = useState("");
  const [industryType, setIndustryType] = useState("");
  const [yearOfEstablishment, setYearOfEstablishment] = useState("");
  const [isErrorFactoryName, setIsErrorFactoryName] = useState(false);
  const [isErrorAddress, setIsErrorAddress] = useState(false);
  const [isErrorCity, setIsErrorCity] = useState(false);
  const [isErrorState, setIsErrorState] = useState(false);
  const [isErrorCountry, setIsErrorCountry] = useState(false);
  const [isErrorContactPerson, setIsErrorContactPerson] = useState(false);
  const [isErrorContactEmail, setIsErrorContactEmail] = useState(false);
  const [isErrorContactPhoneNumber, setIsErrorContactPhoneNumber] = useState(false);
  const [isErrorIndustryType, setIsErrorIndustryType] = useState(false);
  const [isErrorYearOfEstablishment, setIsErrorYearOfEstablishment] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();

  const responseStatus = useSelector((state) => state.factory.status);
  const responseMessage = useSelector((state) => state.factory.text);
  const loading = useSelector((state) => state.factory.loading);
  const showSnackbar = useSelector((state) => state.factory.showSnackbar);

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsErrorFactoryName(factoryName === "");
    setIsErrorAddress(address === "");
    setIsErrorCity(city === "");
    setIsErrorState(state === "");
    setIsErrorCountry(country === "");
    setIsErrorContactPerson(contactPerson === "");
    setIsErrorContactEmail(contactEmail === "");
    setIsErrorContactPhoneNumber(contactPhoneNumber === "");
    setIsErrorIndustryType(industryType === "");
    setIsErrorYearOfEstablishment(yearOfEstablishment === "");

    if (
      factoryName !== "" &&
      address !== "" &&
      city !== "" &&
      state !== "" &&
      country !== "" &&
      contactPerson !== "" &&
      contactEmail !== "" &&
      contactPhoneNumber !== "" &&
      industryType !== "" &&
      yearOfEstablishment !== ""
    ) {
      console.log("Submit");
      // Get form data
      const factoryData = {
        factoryName: factoryName,
        address: address,
        city: city,
        state: state,
        country: country,
        contactPerson: contactPerson,
        contactEmail: contactEmail,
        contactPhoneNumber: contactPhoneNumber,
        industryType: industryType,
        yearOfEstablishment: yearOfEstablishment,
        // Other factory data fields
      };
      console.log(factoryData);

      // Determine if it's an add or edit operation
      const stateadd = "add";
      // Call the factoryAddOrUpdate action
      dispatch(factoryAddOrUpdate(factoryData, stateadd));
    }
  };

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Form
            </Text>
            <SimpleGrid minChildWidth='120px' spacing='40px'>
            <FormControl isInvalid={isErrorFactoryName}>
              <FormLabel>Factory Name</FormLabel>
              <Input
                type="text"
                value={factoryName}
                onChange={(e) => setFactoryName(e.target.value)}
              />
              {isErrorFactoryName && (
                <FormErrorMessage>Factory Name is required.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={isErrorAddress}>
              <FormLabel>Address</FormLabel>
              <Input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              {isErrorAddress && (
                <FormErrorMessage>Address is required.</FormErrorMessage>
              )}
            </FormControl>
            </SimpleGrid>
            <SimpleGrid minChildWidth='120px' spacing='40px'>
            <FormControl isInvalid={isErrorCity}>
              <FormLabel>City</FormLabel>
              <Input
                type="text"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
              {isErrorCity && <FormErrorMessage>City is required.</FormErrorMessage>}
            </FormControl>
            <FormControl isInvalid={isErrorState}>
              <FormLabel>State</FormLabel>
              <Input
                type="text"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
              {isErrorState && <FormErrorMessage>State is required.</FormErrorMessage>}
            </FormControl>
            </SimpleGrid>
            <SimpleGrid minChildWidth='120px' spacing='40px'>
            <FormControl isInvalid={isErrorCountry}>
              <FormLabel>Country</FormLabel>
              <Input
                type="text"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />
              {isErrorCountry && (
                <FormErrorMessage>Country is required.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={isErrorContactPerson}>
              <FormLabel>Contact Person</FormLabel>
              <Input
                type="text"
                value={contactPerson}
                onChange={(e) => setContactPerson(e.target.value)}
              />
              {isErrorContactPerson && (
                <FormErrorMessage>Contact Person is required.</FormErrorMessage>
              )}
            </FormControl>
            </SimpleGrid>
            <SimpleGrid minChildWidth='120px' spacing='40px'>
            <FormControl isInvalid={isErrorContactEmail}>
              <FormLabel>Contact Email</FormLabel>
              <Input
                type="text"
                value={contactEmail}
                onChange={(e) => setContactEmail(e.target.value)}
              />
              {isErrorContactEmail && (
                <FormErrorMessage>Contact Email is required.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={isErrorContactPhoneNumber}>
              <FormLabel>Contact PhoneNumber</FormLabel>
              <Input
                type="text"
                value={contactPhoneNumber}
                onChange={(e) => setContactPhoneNumber(e.target.value)}
              />
              {isErrorContactPhoneNumber && (
                <FormErrorMessage>Contact PhoneNumber is required.</FormErrorMessage>
              )}
            </FormControl>
            </SimpleGrid>
            <SimpleGrid minChildWidth='120px' spacing='40px'>
            <FormControl isInvalid={isErrorIndustryType}>
              <FormLabel>Industry Type</FormLabel>
              <Input
                type="text"
                value={industryType}
                onChange={(e) => setIndustryType(e.target.value)}
              />
              {isErrorIndustryType && (
                <FormErrorMessage>Industry Type is required.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={isErrorYearOfEstablishment}>
              <FormLabel>Year of Establishment</FormLabel>
              <Input
                type="text"
                value={yearOfEstablishment}
                onChange={(e) => setYearOfEstablishment(e.target.value)}
              />
              {isErrorYearOfEstablishment && (
                <FormErrorMessage>Year of Establishment is required.</FormErrorMessage>
              )}
            </FormControl>
            
            </SimpleGrid>
            <Center>
              {loading ? <Spinner mt='24px' size='lg' /> :
                <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                  Submit
                </Button>
              }
            </Center>
          </CardHeader>
          <CardBody>
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default FactoryForm;
  