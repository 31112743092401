import axios from "axios";
import {
    BIOMETRIC_SETTINGS_LOADING,
    BIOMETRIC_SETTINGS_GET_ALL_SUCCESS,
    BIOMETRIC_SETTINGS_ERROR,
    BIOMETRIC_SETTINGS_CLEAR_SNACKBAR,
} from "../constants/types";

import { server_url } from "../constants/types";

export const biometricSettingsGetAll = (pagination) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: BIOMETRIC_SETTINGS_LOADING,
        payload: {
          loading: true,
        },
      });
      axios
        .get(`${server_url}/attendanceiot/settings/get?${pagination}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        })
        .then((response) => {
          dispatch({
            type: BIOMETRIC_SETTINGS_GET_ALL_SUCCESS,
            payload: {
              biometricSettingsList: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              loading: false,
              status: 'success',
              text: "Get all banner position successfully",
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: BIOMETRIC_SETTINGS_ERROR,
            payload: {
              status: "error",
              text: "Error occurred during getting banner position data.",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};

export const biometricSettingsAddOrUpdate = (biometricSettings, state,plant="") => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: BIOMETRIC_SETTINGS_LOADING,
        payload: {
          loading: true,
        },
      });
      if (state === "add") {
        axios
          .post(`${server_url}/attendanceiot/settings/create`, biometricSettings, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/attendanceiot/settings/get?plant=${plant}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("authToken"),
                },
              })
              .then((response) => {
                dispatch({
                  type: BIOMETRIC_SETTINGS_GET_ALL_SUCCESS,
                  payload: {
                    biometricSettingsList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: BIOMETRIC_SETTINGS_ERROR,
                  payload: {
                    text: "Error occurred during getting banner position data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: BIOMETRIC_SETTINGS_ERROR,
              payload: {
                text: "Error occurred during adding banner position data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      } else if (state === "edit") {
        axios
          .patch(`${server_url}/attendanceiot/settings/update/${biometricSettings._id}`, biometricSettings, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            },
          })
          .then((responseF) => {
            axios
            .get(`${server_url}/attendanceiot/settings/get?plant=${plant}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("authToken"),
                },
              })
              .then((response) => {
                dispatch({
                  type: BIOMETRIC_SETTINGS_GET_ALL_SUCCESS,
                  payload: {
                    biometricSettingsList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    biometricSettingsDetail: responseF.data["data"],
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],

                  },

                });

                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: BIOMETRIC_SETTINGS_ERROR,
                  payload: {
                    text: "Error occurred during getting banner position data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: BIOMETRIC_SETTINGS_ERROR,
              payload: {
                text: "Error occurred during updating banner position data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      } else if (state === "view") {
        axios
          .get(`${server_url}/attendanceiot/settings/view/${biometricSettings._id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/attendanceiot/settings/get?plant=${plant}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("authToken"),
                },
              })
              .then((response) => {
                dispatch({
                  type: BIOMETRIC_SETTINGS_GET_ALL_SUCCESS,
                  payload: {
                    biometricSettingsList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    biometricSettingsDetail: responseF.data["data"],
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: BIOMETRIC_SETTINGS_ERROR,
                  payload: {
                    text: "Error occurred during getting banner position data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: BIOMETRIC_SETTINGS_ERROR,
              payload: {
                text: "Error occurred during updating banner position data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      }
    });
  };
};

export const biometricSettingsDelete = (biometricSettings,plant="") => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: BIOMETRIC_SETTINGS_LOADING,
        payload: {
          loading: true,
        },
      });
      axios
        .delete(`${server_url}/attendanceiot/settings/delete/${biometricSettings}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        })
        .then((responseF) => {
          axios
            .get(`${server_url}/attendanceiot/settings/get?plant=${plant}`, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("authToken"),
              },
            })
            .then((response) => {
              dispatch({
                type: BIOMETRIC_SETTINGS_GET_ALL_SUCCESS,
                payload: {
                  biometricSettingsList: response.data.data,
                  limit: response.data.limit,
                  page: response.data.currentPage,
                  totalPages: response.data.totalPages,
                  response: response.data,
                  biometricSettingsDetail: responseF.data["data"],
                  loading: false,
                  status: responseF.data["status"],
                  text: responseF.data["message"],
                },
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: BIOMETRIC_SETTINGS_ERROR,
                payload: {
                  text: "Error occurred during getting banner position data.",
                  status: "error",
                  loading: false,
                },
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: BIOMETRIC_SETTINGS_ERROR,
            payload: {
              text: "Error occurred during deleting banner position data.",
              status: "error",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};

export const biometricSettingsClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: BIOMETRIC_SETTINGS_CLEAR_SNACKBAR,
    });
  };
};
