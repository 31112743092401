import axios from 'axios';
import {
    VISITOR_PREAPPROVAL_LOADING,
    VISITOR_PREAPPROVAL_GET_ALL_SUCCESS,
    VISITOR_PREAPPROVAL_ERROR,
    VISITOR_PREAPPROVAL_CLEAR_SNACKBAR
} from '../constants/types';

import { server_url } from '../constants/types';

export const VisitorPreapprovalGetAll = (pagination = '') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: VISITOR_PREAPPROVAL_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/visitor/pre-approval?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          dispatch({
            type: VISITOR_PREAPPROVAL_GET_ALL_SUCCESS,
            payload: {
              visitorPreapprovalList: response.data.data,
              visitorPreapprovalDetail: {},
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              totalItems: response.data.totalItems,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all visitor successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: VISITOR_PREAPPROVAL_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting visitor  data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};




export const visitorPreapprovalGetById = (visitor) => {
  console.log("yes it is working")
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: VISITOR_PREAPPROVAL_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/visitor/pre-approval/${visitor}`,
            {headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }}
        )
        .then((responseF) => {
          axios
            .get(`${server_url}/visitor/pre-approval`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
            .then((response) => {
              console.log("responseF.data['data']")
              console.log(responseF.data['data'])
              dispatch({
                type: VISITOR_PREAPPROVAL_GET_ALL_SUCCESS,
                payload: {
                  visitorPreapprovalList: response.data.data,
                  limit: response.data.limit,
                  page: response.data.currentPage,
                  totalPages: response.data.totalPages,
                  response: response.data,
                  visitorPreapprovalDetail: responseF.data['data'],
                  loading: false,
                  status: responseF.data['status'],
                  text: responseF.data['message']
                }
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: VISITOR_PREAPPROVAL_ERROR,
                payload: {
                  text: 'Error occurred during getting visitor data.',
                  status: 'error',
                  loading: false
                }
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: VISITOR_PREAPPROVAL_ERROR,
            payload: {
              text: 'Error occurred during deleting visitor data.',
              status: 'error',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};
