// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
  LightMode,
  Image,
  Select,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Spinner,
  Grid,
  GridItem
} from "@chakra-ui/react";
import { userAddOrUpdate } from "actions/userActions";
// Assets
import BgSignUp from "assets/img/BgSignUp.png";
import CameraIcon from "./camera.jpg";
import React, { useState ,useEffect,useRef} from "react";
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import { useDispatch,useSelector } from "react-redux";
import dataConstant from "constants/dataConstant";
import Webcam from "react-webcam";
import OtpInput from 'react-otp-input';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from 'axios';
import { server_url } from "constants/types";
import cigirates from './cigirates.png';
import electronic from './electronic.png';
import inflammable from './inflammable.png';
import firearms from './firearms.png';
import Explosives from './Explosives.png';
import drugs from './drugs.png';
import radioactive from './radioactive.png';
import toxic from './toxic.png';
import knifes from './knifes.png';
import compressedgases from './compressedgases.png';
import alcohol from './alcohol.png';
import kavachIcone from './Logo.png'
function Weblink() {
  const toast = useToast();
  const bgForm = useColorModeValue("white", "navy.800");
  const titleColor = useColorModeValue("gray.700", "blue.500");
  const textColor = useColorModeValue("gray.700", "white");
  const colorIcons = useColorModeValue("gray.700", "white");
  const bgIcons = useColorModeValue("trasnparent", "navy.700");
  const bgIconsHover = useColorModeValue("gray.50", "whiteAlpha.100");
  const [name,setName]= useState("");
  const [email,setEmail]= useState("");
  const [password,setPassword]= useState("");
  const [contactNo,setContactNo]= useState("");
  const dispatch = useDispatch();
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [cardWidth, setCardWidth] = useState('765px');
  const [cardMargin, setCardMargin] = useState({ base: "100px" });
  const [photo, setPhoto] = useState("");
  const [photoFile, setPhotoFile] = useState("");
  const [photoTimeStamp, setPhotoTimeStamp] = useState("");
  const [showCamera, setShowCamera] = useState(false);
  const webcamRef = useRef(null);
  const [photoId, setPhotoId] = useState("");
  const [photoIdFile, setPhotoIdFile] = useState("");
  const [photoIdTimeStamp, setPhotoIdTimeStamp] = useState("");
  const [showCameraId, setShowCameraId] = useState(false);
  const [photoCi, setPhotoCi] = useState("");
  const [photoCiFile, setPhotoCiFile] = useState("");
  const [photoCiTimeStamp, setPhotoCiTimeStamp] = useState("");
  const [showCameraCi, setShowCameraCi] = useState(false);
  const [idProof, setIdProof] = useState("");
  const [carryItems, setCarryItems] = useState("");
  const [vehicleType, setVehicleType] = useState("BY_WALK");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [licenceNumber, setLicenceNumber] = useState("");
  const [licenseExpiry, setLicenseExpiry] = useState("");
  const [driverMobileNumber, setDriverMobileNumber] = useState("");
  const [driverName, setDriverName] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [otp, setOtp] = useState('');
  const [message, setMessage] = useState('');
  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bloodGroup, setBloodGroup] = useState("");
  const [preapprovalDetail, setPreapprovalDetail] = useState("");
  const [userName, setUserName] = useState("");
  const [grid, setGrid] = useState("repeat(6, 1fr)");
  const params = useParams();
  const [isOlderDate, setIsOlderDate] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  useEffect(() => {
    // const handleResize = () => {
    //   setDimensions({
    //     width: window.innerWidth,
    //     height: window.innerHeight,
    //   });
    //   if(window.innerWidth >= 1340 && window.innerWidth <= 1550)
    //     {
    //         setCardWidth('120px');
    //         setBoxWidth({
    //             left: "50%",
    //             right: "50%",
    //           });
    //     }
    //     else if(window.innerWidth >= 2000)
    //         {
    //             setCardWidth('100px');
    //             setBoxWidth({
    //                 left: "70%",
    //                 right: "30%",
    //             });
    //         }
    //     else
    //     {
    //         setCardWidth('100px');
    //         setBoxWidth({
    //             left: "60%",
    //             right: "40%",
    //           });
    //     }
    // };
    
    // window.addEventListener('resize', handleResize);

    // return () => {
    //   window.removeEventListener('resize', handleResize);
    // };
    console.log(params.id);
    onOpen();
    handleResize()
    sendOtp();
  }, []);

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    if(window.innerWidth <= 786)
      {
          setCardWidth('100%');
          setCardMargin({ base: "10px" });
          setGrid("repeat(3, 1fr)");
          
      }
      else if(window.innerWidth >= 2000)
          {
              setCardWidth('765px');
              setCardMargin({ base: "100px" });
              setGrid("repeat(6, 1fr)");
          }
      else
      {
          setCardWidth('765px');
          setCardMargin({ base: "100px" });
          setGrid("repeat(6, 1fr)");
      }
  };

  const captureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot();

  // Create a canvas element
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Create an image element to draw the captured image
  const image = new window.Image();
  image.src = imageSrc;

  image.onload = () => {
    // Set canvas dimensions to match the image
    canvas.width = image.width;
    canvas.height = image.height;

    // Draw the image on the canvas
    ctx.drawImage(image, 0, 0);

    // Generate the timestamp
    const timestamp = new Date().toLocaleString();

    // Set the font and style for the timestamp
    ctx.font = '24px Arial';
    ctx.fillStyle = 'black';
    ctx.strokeStyle = 'white';
    ctx.lineWidth = 3;

    // Position the timestamp on the image
    const textWidth = ctx.measureText(timestamp).width;
    ctx.strokeText(timestamp, canvas.width - textWidth - 20, canvas.height - 20);
    ctx.fillText(timestamp, canvas.width - textWidth - 20, canvas.height - 20);

    // Get the new image with the timestamp as Base64
    const imageWithTimestamp = canvas.toDataURL('image/jpeg');

    // Convert Base64 to File if needed
    const byteString = atob(imageWithTimestamp.split(',')[1]);
    const mimeString = imageWithTimestamp.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([ab], { type: mimeString });
    const file = new File([blob], `captured_image_with_timestamp.jpeg`, { type: mimeString });
  // Now you have a File object
    setPhotoFile(file);
    console.log(file);
    setPhotoTimeStamp(timestamp);
    setPhoto(imageWithTimestamp);
    setShowCamera(false);
  }
  };
  const captureImageId = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    // Create a canvas element
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Create an image element to draw the captured image
  const image = new window.Image();
  image.src = imageSrc;

  image.onload = () => {
    // Set canvas dimensions to match the image
    canvas.width = image.width;
    canvas.height = image.height;

    // Draw the image on the canvas
    ctx.drawImage(image, 0, 0);

    // Generate the timestamp
    const timestamp = new Date().toLocaleString();

    // Set the font and style for the timestamp
    ctx.font = '24px Arial';
    ctx.fillStyle = 'black';
    ctx.strokeStyle = 'white';
    ctx.lineWidth = 3;

    // Position the timestamp on the image
    const textWidth = ctx.measureText(timestamp).width;
    ctx.strokeText(timestamp, canvas.width - textWidth - 20, canvas.height - 20);
    ctx.fillText(timestamp, canvas.width - textWidth - 20, canvas.height - 20);

    // Get the new image with the timestamp as Base64
    const imageWithTimestamp = canvas.toDataURL('image/jpeg');

    // Convert Base64 to File if needed
    const byteString = atob(imageWithTimestamp.split(',')[1]);
    const mimeString = imageWithTimestamp.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([ab], { type: mimeString });

  // Create a File object from the Blob
  const file = new File([blob], "photo.jpeg", { type: mimeString });

  // Now you have a File object
    setPhotoIdFile(file);
    setPhotoIdTimeStamp(timestamp);
    setPhotoId(imageWithTimestamp);
    setShowCameraId(false);
  }
  };
  const captureImageCi = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    // Create a canvas element
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Create an image element to draw the captured image
  const image = new window.Image();
  image.src = imageSrc;

  image.onload = () => {
    // Set canvas dimensions to match the image
    canvas.width = image.width;
    canvas.height = image.height;

    // Draw the image on the canvas
    ctx.drawImage(image, 0, 0);

    // Generate the timestamp
    const timestamp = new Date().toLocaleString();

    // Set the font and style for the timestamp
    ctx.font = '24px Arial';
    ctx.fillStyle = 'black';
    ctx.strokeStyle = 'white';
    ctx.lineWidth = 3;

    // Position the timestamp on the image
    const textWidth = ctx.measureText(timestamp).width;
    ctx.strokeText(timestamp, canvas.width - textWidth - 20, canvas.height - 20);
    ctx.fillText(timestamp, canvas.width - textWidth - 20, canvas.height - 20);

    // Get the new image with the timestamp as Base64
    const imageWithTimestamp = canvas.toDataURL('image/jpeg');

    // Convert Base64 to File if needed
    const byteString = atob(imageWithTimestamp.split(',')[1]);
    const mimeString = imageWithTimestamp.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([ab], { type: mimeString });

    // Create a File object from the Blob
    const file = new File([blob], "photo.jpeg", { type: mimeString });

    // Now you have a File object
    setPhotoCiFile(file);
    setPhotoCiTimeStamp(timestamp);
    setPhotoCi(imageWithTimestamp);
    setShowCameraCi(false);
  }
  };

  const handleChange = () => {
    setIsChecked((prevChecked) => !prevChecked); // Functional update to prevent stale state issues.
  };

  const sendOtp = () => {
    setLoading(true);
    const bodyData = {
      id : params.id
    }
    axios
      .post(`${server_url}/pre-approved/send-otp`, bodyData).then((response) => {
        if(response.data.status == 'OK')
          {

          }
          else
          {
            setIsLinkExpired(true);
            setMessage(response.data.message);
          }
        
      }).catch(() => console.log("error"))
      setLoading(false);
  }

  const verifyOtp = (otp) => {
    setLoading(true);
    const bodyData = {
      id : params.id,
      otp : otp
    }
    axios
      .post(`${server_url}/pre-approved/verify-otp`, bodyData).then((response) => {
        if(response.data.status == 'OK')
          {
            setPreapprovalDetail(response.data.data.findPreapproval);
            setUserName(response.data.data?.findPreapproval?.name);
            const data = response.data.data?.findExisting;
            if(data?.webLinkData)
              {
                const webLinkData = data?.webLinkData
                if(webLinkData.image != "")
                  {
                    setPhoto(webLinkData.image);
                    setPhotoTimeStamp(data.formatedUpdated)
                  }
                  setIdProof(webLinkData.idProof)
                if(webLinkData.idProofImage != "")
                  {
                    setPhotoId(webLinkData.idProofImage);
                    setPhotoIdTimeStamp(data.formatedUpdated)
                  }
                  setCarryItems(webLinkData.carryItems);
                if(webLinkData.carryImage != "")
                  {
                    setPhotoCi(webLinkData.carryImage);
                    setPhotoCiTimeStamp(data.formatedUpdated)
                  }

                  setVehicleType(webLinkData.vehicleType);
                  setVehicleNumber(webLinkData.vehicleNumber);
                  setLicenceNumber(webLinkData.licenseNumber);
                  if(webLinkData.licenseExpiry != null)
                    {
                      setLicenseExpiry(webLinkData.licenseExpiry);
                    }
                    setDriverMobileNumber(webLinkData.driverMobileNumber);
                    setDriverName(webLinkData.driverName);
                    setBloodGroup(webLinkData.bloodGroup);
                  

              }
            onClose();
          }
          else
          {
            toastFunction(response.data.message, "error");
          }
        
      }).catch(() => console.log("error"))
      setLoading(false);
  }

  // Submit Form
  const submitWebLinkData = () => {
    
    if(!isChecked)
      {
        toastFunction("Disclaimer is mandatory", "error");
        return;
      }
      setLoading(true);
      console.log(true);
    const bodyData = {
      id : params.id,
      photo : photoFile!=""?photoFile:photo,
      idProof : idProof,
      idProofImage : photoIdFile !="" ? photoIdFile:photoId,
      carryItems : carryItems,
      carryItemsImage : photoCiFile != "" ? photoCiFile:photoCi,
      vehicleType : vehicleType,
      vehicleNumber : vehicleNumber,
      licenseNumber : licenceNumber,
      licenseExpiry : licenseExpiry,
      driverMobileNumber : driverMobileNumber,
      driverName : driverName,
      bloodGroup : bloodGroup
    }
    console.log(bodyData);
    axios
      .post(`${server_url}/pre-approved/submit-weblink-data`, bodyData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        if(response.data.status == 'OK')
          {
            setIsSubmitted(true);
            toastFunction(response.data.message, "success");
          }
          else
          {
            toastFunction(response.data.message, "error");
          }
        
      }).catch(() => console.log("error"));
      setLoading(false);
      console.log(false);
  }

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };
  const today = new Date().toISOString().split('T')[0];
  return (
    <Flex
      direction='column'
      alignSelf='center'
      justifySelf='center'
      overflow='hidden'
      >
      <Box
        position='absolute'
        minH={{ base: "70vh", md: "50vh" }}
        maxH={{ base: "70vh", md: "50vh" }}
        w={{ md: "calc(100vw - 50px)" }}
        maxW={{ md: "calc(100vw - 50px)" }}
        left='0'
        right='0'
        bgRepeat='no-repeat'
        overflow='hidden'
        zIndex='-1'
        top='0'
        bgImage={BgSignUp}
        bgSize='cover'
        mx={{ md: "auto" }}
        mt={{ md: "14px" }}
        borderRadius={{ base: "0px", md: "20px" }}>
        <Box w='100vw' h='100vh' bg='blue.500' opacity='0.8'></Box>
      </Box>
      <Flex alignItems='center' justifyContent='center' mb='60px' mt='20px' width='100%'>
        <Flex
          direction='column'
          w={cardWidth}
          background='transparent'
          borderRadius='15px'
          p='40px'
          mx={cardMargin}
          bg={bgForm}
          boxShadow={useColorModeValue(
            "0px 5px 14px rgba(0, 0, 0, 0.05)",
            "unset"
          )}>
            <HStack spacing='15px' justify='center' mb='22px'>
            <Flex
              justify='center'
              align='center'
              w='75px'
              h='75px'
              borderRadius='8px'
              border={useColorModeValue("1px solid", "0px")}
              borderColor='gray.200'
              cursor='pointer'
              transition='all .25s ease'
              bg={bgIcons}
              _hover={{ bg: bgIconsHover }}>
                {/* <Icon as={FaGoogle} color={colorIcons} w='30px' h='30px' /> */}
                <Image src={kavachIcone} alt="Kavach Icon" />
            </Flex>
          </HStack>
          {isLinkExpired ? <Text
            fontSize='xl'
            color="red"
            fontWeight='bold'
            textAlign='center'
            mb='22px'>
            {message}
          </Text> :isSubmitted ?<Text
            fontSize='xl'
            color={textColor}
            fontWeight='bold'
            textAlign='center'
            mb='22px'>
            Thank you!
          </Text> :<Text
            fontSize='xl'
            color={textColor}
            fontWeight='bold'
            textAlign='center'
            mb='22px'>
            Welcome to {preapprovalDetail.plantAdmin?.plant[0]?.plantName}.
          </Text>}
          
           
          {isLinkExpired ? <></>:isSubmitted?<Text fontSize='md' mb="2" textAlign='center'>Your data has been successfully submitted.</Text>:<FormControl>
            {preapprovalDetail !="" ?<>
            <Text fontSize='md'>Dear {preapprovalDetail.name}</Text>
            <Text fontSize='md' mb="2">You are invited to {preapprovalDetail.plantAdmin?.plant[0]?.plantName} by {preapprovalDetail.employee?.employeeName} on {preapprovalDetail.visitDate} at {preapprovalDetail.visitTime}</Text>
            <Text fontSize='md' mb="8">(To ensure a smooth and secure check-in process, please complete the form below with accurate details.)</Text>
            </>:
              <></>}
            <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
              Name
            </FormLabel>
            <Input
              variant='auth'
              fontSize='sm'
              readOnly={true}
              ms='4px'
              type='text'
              placeholder='Name'
              mb='24px'
              size='lg'
              name='name'
              value={userName}
            />
          <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
              Photo <span style={{
                color : "red"
              }}>*</span> (Take a recent photo of yourself.)
            </FormLabel>
            {showCamera ? (
              <>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width="100%"
                  videoConstraints={{
                    facingMode: "user",
                  }}
                />
                <Button colorScheme='blue' mt="10px" onClick={captureImage}>
                  Capture
                </Button>
              </>
            ) : (
              <Image
                boxSize="100px"
                objectFit="cover"
                src={photo || CameraIcon}
                alt="User"
                
                onClick={() => setShowCamera(true)}
                cursor="pointer"
              />
            )}
            <Text fontSize='xs' mb="24px">{photoTimeStamp}</Text>
            <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
              Blood Group (Specify your blood group for to help you in emergency purposes.)
            </FormLabel>
              <Select
              mb='24px'
                placeholder="Select Blood group"
                onChange={(e) => {
                  // setIsErrorStatus(e.target.value === "");
                  setBloodGroup(e.target.value);
                }}
              >

              {dataConstant.bloodGroup.map((status) => (
                  <option key={status.id} value={status.value}>
                    {status.name}
                  </option>
                ))}
              </Select>
            <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
              ID Proof <span style={{
                color : "red"
              }}>*</span>
            </FormLabel>
            <Select
            value={idProof}
            mb='24px'
              placeholder="Select ID Proof"
              onChange={(e) => {
                // setIsErrorStatus(e.target.value === "");
                setIdProof(e.target.value);
              }}
            >

            {dataConstant.idProof.map((status) => (
                <option key={status.id} value={status.value}>
                  {status.name} 
                </option>
              ))}
            </Select>
            <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
              ID Photo <span style={{
                color : "red"
              }}>*</span> (This will be used for identity verification.)
            </FormLabel>
            {showCameraId ? (
              <>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width="100%"
                  videoConstraints={{
                    facingMode: { exact: "environment" },
                  }}
                />
                <Button mt="10px" colorScheme='blue' onClick={captureImageId}>
                  Capture
                </Button>
              </>
            ) : (
              <Image
                boxSize="100px"
                objectFit="cover"
                src={photoId || CameraIcon}
                alt="User"
                // mb="24px"
                onClick={() => setShowCameraId(true)}
                cursor="pointer"
              />
            )}
            <Text fontSize='xs' mb="24px">{photoIdTimeStamp}</Text>
            <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
              Carry Items (Declare any items you will be carrying during your visit, Ex: Laptop, mobiles, Tools etc..)
            </FormLabel>
            <Input
              variant='auth'
              fontSize='sm'
              ms='4px'
              type='text'
              placeholder='Description'
              mb='24px'
              size='lg'
              name='name'
              onChange={(e) => {setCarryItems(e.target.value)}}
            />
            <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
              Carry Item Photo 
            </FormLabel>
            {showCameraCi ? (
              <>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width="100%"
                  videoConstraints={{
                    facingMode: { exact: "environment" },
                  }}
                />
                <Button colorScheme='blue' mt="10px" onClick={captureImageCi}>
                  Capture
                </Button>
              </>
            ) : (
              <Image
                boxSize="100px"
                objectFit="cover"
                src={photoCi || CameraIcon}
                alt="User"
                // mb="24px"
                onClick={() => setShowCameraCi(true)}
                cursor="pointer"
              />
            )}
          <Text fontSize='xs' mb="24px">{photoCiTimeStamp}</Text>
          <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
            Prohibited Items
            </FormLabel>
            <Grid templateColumns={grid} gap={2} mb='24px'>
              <GridItem w='100%' > <Image
                boxSize='100px'
                objectFit='cover'
                src={cigirates}
                alt='Dan Abramov'
              /></GridItem>
              <GridItem w='100%' ><Image
                boxSize='100px'
                objectFit='cover'
                src={inflammable}
                alt='Dan Abramov'
              /></GridItem>
              <GridItem w='100%'  ><Image
                boxSize='100px'
                objectFit='cover'
                src={firearms}
                alt='Dan Abramov'
              /></GridItem>
              <GridItem w='100%'><Image
                boxSize='100px'
                objectFit='cover'
                src={Explosives}
                alt='Dan Abramov'
              /></GridItem>
              <GridItem w='100%'><Image
                boxSize='100px'
                objectFit='cover'
                src={drugs}
                alt='Dan Abramov'
              /></GridItem>
              <GridItem w='100%'><Image
                boxSize='100px'
                objectFit='cover'
                src={radioactive}
                alt='Dan Abramov'
              /></GridItem>
              <GridItem w='100%'><Image
                boxSize='100px'
                objectFit='cover'
                src={toxic}
                alt='Dan Abramov'
              /></GridItem>
              <GridItem w='100%'><Image
                boxSize='100px'
                objectFit='cover'
                src={knifes}
                alt='Dan Abramov'
              /></GridItem>
              <GridItem w='100%'><Image
                boxSize='100px'
                objectFit='cover'
                src={compressedgases}
                alt='Dan Abramov'
              /></GridItem>
              <GridItem w='100%'><Image
                boxSize='100px'
                objectFit='cover'
                src={alcohol}
                alt='Dan Abramov'
              /></GridItem>
            </Grid>
          <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
              Select Mode of Transport (Only valid licenses are permitted for driving onto the premises.)
            </FormLabel>
            <Select
            value={vehicleType}
            mb='24px'
              placeholder="Select Vehicle Type"
              onChange={(e) => {
                // setIsErrorStatus(e.target.value === "");
                setVehicleType(e.target.value);
              }}
            >

            {dataConstant.vechilType.map((status) => (
                <option key={status.id} value={status.name}>
                  {status.name}
                </option>
              ))}
            </Select>
            {vehicleType != "BY_WALK" && vehicleType != ""?<>
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
              Last 4 Digit Vehicle Number <span style={{
                color : "red"
              }}>*</span>
            </FormLabel>
            <Input
            value={vehicleNumber}
              variant='auth'
              fontSize='sm'
              ms='4px'
              type='number'
              placeholder='Last 4 Digit Vehicle Number'
              mb='24px'
              size='lg'
              name="contactNo"
              onChange={(e) => {setVehicleNumber(e.target.value)}}
            />
            <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
              Last 5 Digit DL Number <span style={{
                color : "red"
              }}>*</span>
            </FormLabel>
            <Input
            value={licenceNumber}
              variant='auth'
              fontSize='sm'
              ms='4px'
              type='number'
              placeholder='Last 5 Digit DL Number'
              mb='24px'
              size='lg'
              name="Last 5 Digit DL Number"
              onChange={(e) => {setLicenceNumber(e.target.value)}}
            />
            <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
              Licence Expiry <span style={{
                color : "red"
              }}>*</span>
            </FormLabel>
            <Input
            value={licenseExpiry}
              variant='auth'
              fontSize='sm'
              ms='4px'
              type='date'
              min={today}
              placeholder='Licence Expiry'
              mb='24px'
              size='lg'
              name="Licence Expiry"
              onChange={(e) => {setLicenseExpiry(e.target.value)}}
            />
            <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
              Driver Mobile Number (Optional)
            </FormLabel>
            <Input
            value={driverMobileNumber}
              variant='auth'
              fontSize='sm'
              ms='4px'
              type='number'
              placeholder='Driver Mobile Number'
              mb='24px'
              size='lg'
              name="Driver Mobile Number"
              onChange={(e) => {setDriverMobileNumber(e.target.value)}}
            />
            <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
              Driver Name (Optional)
            </FormLabel>
            <Input
            value={driverName}
              variant='auth'
              fontSize='sm'
              ms='4px'
              type='number'
              placeholder='Driver Name'
              mb='24px'
              size='lg'
              name="Driver Name"
              onChange={(e) => {setDriverName(e.target.value)}}
            />
            </>:<></>
            
            
            }

          <Flex color='white' mb='20px'>
            <Box>
            {/* <Checkbox
            isChecked={isChecked}
            onChange={handleChange}
            > */}
               <Text color="black" fontSize="16px"> Disclaimer</Text> 
               {/* </Checkbox> */}
            </Box>
          </Flex>
            <Flex color='white' mb='24px'>
            <Box>
            {/* <Checkbox
            isChecked={isChecked}
            onChange={handleChange}
            > */}
                <Text color="red" fontSize="14px"  mb='8px'> Correct Information Disclaimer:</Text> 
               <Text color="black" fontSize="14px"> By submitting your details, you confirm that the information provided is accurate and up-to-date. Providing false or misleading information may result in denied entry or further action as per company policies.</Text> 
               {/* </Checkbox> */}
            </Box>
          </Flex>
          <Flex color='white' mb='24px'>
            <Box>
            <Text color="red" fontSize="14px" mb='8px'> Health Declaration:</Text> 
            <Text color="black" fontSize="14px"> By submitting this form, you declare that you are in good health, have not experienced any symptoms related to infectious diseases (e.g., fever, cough, shortness of breath) within the past 14 days, and are not allergic to any conditions or substances that may be present during your visit. You agree to comply with all health and safety protocols as mandated by the company.</Text>
            </Box>
          </Flex>
          <Flex color='white' mb='24px'>
            <Box>
            <Text color="red" fontSize="14px" mb='8px' > Data Privacy Statement:</Text> 
            <Text color="black" fontSize="14px"> Our personal information will be used solely for the purpose of verifying your identity and ensuring a secure visit. We are committed to protecting your data and will not share it with any third parties without your consent, except as required by law. For more details, please refer to our <Link href="https://kavachtechnologies.com/privacy-policy-2/" color="blue" target="_blank"><u>Privacy Policy</u></Link> </Text>
            </Box>
          </Flex>
            {loading ? <Spinner mt='24px' size='lg' />:<Button
            onClick={() => 
              {
                submitWebLinkData();
              }
            }
              fontSize='14px'
              variant='dark'
              fontWeight='bold'
              w='100%'
              h='45'
              mb='24px'>
              Submit
            </Button>}
          </FormControl>}
          <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay bg='blackAlpha.800' />
          {isLinkExpired ? <ModalContent>
          <ModalHeader>EXPIRED</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text color="red" fontSize="14px">{message}</Text>
          </ModalBody>

          
        </ModalContent>
        
        :<ModalContent>
            <ModalHeader>Enter OTP</ModalHeader>
            {/* <ModalCloseButton /> */}
            <ModalBody pb={6}>
            <OtpInput
            inputStyle={{ width: '2.5rem', height: '2.5rem', margin: '0 0.5rem', textAlign: 'center' ,border: '1px solid gray', // Add border style here
              borderRadius: '4px', // Optional: round the corners
              fontSize: '1.5rem', }}
            focusStyle={{ borderColor: 'blue', outline: 'none' }}
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
              <Text mt="8px" fontSize="12px" color="green">An OTP has been sent to the mobile number XXXXXXX897.</Text>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={() => 
                {
                  verifyOtp(otp);
                }
              }>
                Verify
              </Button>
              
            </ModalFooter>
          </ModalContent>}
        </Modal>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Weblink;
