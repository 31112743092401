// Chakra imports
import {
    Flex,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Td,
    TableContainer,
    Image,
    useColorModeValue,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { patrollingRouteClearShowSnackbar, patrollingRouteAddOrUpdate } from '../../actions/PatrollingRouteAction';
  import { useToast } from '@chakra-ui/react'
  import { useParams } from "react-router-dom/cjs/react-router-dom.min";
  import { PATROLLING_ROUTE_CLEAR_SNACKBAR } from "constants/types";
  import {
    Skeleton,
    SkeletonCircle,
    SkeletonText,
  } from "@chakra-ui/react";
// import { patrollingRouteClearShowSnackbar } from "actions/PatrollingRouteAction";
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
  function PatrollingRouteView() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [status, setStatus] = useState("");

    const history = useHistory();
    const dispatch = useDispatch();
    const toast = useToast();
  
    const statuses = ['success', 'error', 'warning', 'info'];
    const responseStatus = useSelector(state => state.patrollingRoute.status);
    const responseMessage = useSelector(state => state.patrollingRoute.text);
    const loading = useSelector(state => state.patrollingRoute.loading);
    // const designationDetail = useSelector(state => state.patrollingRoute.designationDetail);
    const patrollingRouteDetail = useSelector((state) => state.patrollingRoute.patrollingRouteDetail); 
    const showSnackbar = useSelector(state => state.patrollingRoute.showSnackbar);
  
    const params = useParams();
    console.log("patrollingRouteDetail--patrollingRouteDetail") 
    console.log(patrollingRouteDetail)
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === 'OK' && loading === false) {
          setName(patrollingRouteDetail.name);
          setDescription(patrollingRouteDetail.description);
          setStatus(patrollingRouteDetail.status ?'Active' : 'inActive');
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'success');
          }
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'error');
          }
        }
        dispatch({
          type: PATROLLING_ROUTE_CLEAR_SNACKBAR,
          payload: {
            showSnackbar: false,
          }
        });
      }
    }, [loading]);
  
    useEffect(() => {
      const patrollingRouteId = params.id;
      const patrollingRoute = {
        _id: patrollingRouteId,
      };
      dispatch(patrollingRouteAddOrUpdate(patrollingRoute, 'view'));
    }, []);

    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
    }, [])
    
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      });
    };
  
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Location View 
            </Text>
          </CardHeader>
          <CardBody>
            {loading ? (
              <Skeleton height="20px" />
            ) : (
              <TableContainer>
                <Table variant="simple">
                  <Tbody>
                    <Tr>
                      <Td>Name</Td>
                      <Td>{name}</Td>
                    </Tr>
                    <Tr>
                      <Td>Description</Td>
                      <Td>{description}</Td>
                    </Tr>
                    <Tr>
                      <Td>Status</Td>
                      <Td>{status}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default PatrollingRouteView;
  