// Chakra imports
import {
    Box,
    Button,
    Center,
    Checkbox,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Input,
    Select,
    SimpleGrid,
    Spinner,
    Stack,
    Text,
    useColorModeValue,
    useToast,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import axios from 'axios';
  import { server_url } from "constants/types";
  import { useHistory } from 'react-router-dom';
  import { PURPOSE_CLEAR_SNACKBAR } from "constants/types";
  import {
    List,
    ListItem,
    ListIcon,
    OrderedList,
    UnorderedList,
  } from "@chakra-ui/react";
  import dataConstant from "constants/dataConstant";
//   import { permissionGetAll } from "actions/permissionActions";
  import { purposeGetAll } from "actions/purposeAction";
  import { purposeAddOrUpdate } from "actions/purposeAction";
import {purposeClearShowSnackbar} from "actions/purposeAction"
//   import { parkingClearShowSnackbar } from "actions/parkingAction";
  import { useParams } from "react-router-dom/cjs/react-router-dom.min";
  
  function PurposeUpdateForm() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [name , setName] = useState('');
    const [description , setDescription] = useState("");
    const [status , setStatus] = useState("");

    
    const [isErrorName, setIsErrorName] = useState(false);
    const [isErrorDescription, setIsErrorDescription] = useState(false);
    const [isErrorStatus, setIsErrorStatus] = useState(false);

  
    const [permissionStyle, setPermissionStyle] = useState({
      display: "none",
    });
    const dispatch = useDispatch();
    const toast = useToast();
    const history = useHistory();
    const statuses = ['success', 'error', 'warning', 'info'];
    const responseStatus = useSelector(state => state.purpose.status);
    const responseMessage = useSelector(state => state.purpose.text);
    const loading = useSelector(state => state.purpose.loading);
    const showSnackbar = useSelector(state => state.purpose.showSnackbar);
    const purposeDetail = useSelector(state => state.purpose.purposeDetail);
  // const plantAdmin = parkingDetail.plantAdmin 
  // console.log("plantAdmin123445")
  // console.log(plantAdmin)
  
  // useEffect(() => {
  //   plantDepartmentList()
  // }, []);
  
//   const plantDepartmentList = (plantAdmin) => {
//     axios
//       .get(`${server_url}/plant/department/${plantAdmin}`, {  
//         headers: {
//           'Authorization': 'Bearer ' + localStorage.getItem('authToken')
//         }
//       })
//       .then((response) => {
//         setDepartmentList(response.data.data);
//       })
//       .catch((error) => {
//         console.error('Error fetching department list:', error);
//       });
//   };
  
  
    const params = useParams();
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === "OK" && loading === false) {
  
            setName(purposeDetail.name);
            setDescription(purposeDetail.description);
            setStatus(purposeDetail.status);
   
          if (responseMessage !== "") {
            toastFunction(responseMessage, "success");
          }
        } else if (responseStatus === "NOK" && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, "error");
          }
        }
      }
      dispatch({
        type: PURPOSE_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        }
      });
    }, [loading]);
  
    // useEffect(() => {
    //   dispatch(moduleGetAll());
    //   dispatch(permissionGetAll());
  
    //   dispatch(adminGetAll());
  
  
    // }, []);
  
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      });
    };
  
    
  
    // const parkingdepartmentListforDropdown = () => {
    //   axios
    //     .get(`${server_url}/parkingdepartment?`, {
    //       headers: {
    //         'Authorization': 'Bearer ' + localStorage.getItem('authToken')
    //       }
    //     }).then((responce) => {
    //       setParkingDepartmentList(responce.data.data)
    //     }).catch(() => console.log("error"))
    // }
  
    useEffect(() => {
      console.log(params);
      const state = 'view';
      // Call the roleAddOrUpdate action
      const purpose = {
        _id: params.id,
      };
  
      dispatch(purposeAddOrUpdate(purpose, state))
  
  
    }, []);
  
    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
    }, [])
    const handleSubmit = (event) => {
      event.preventDefault();
      setIsErrorName(name === '');
      // setIsErrorDescription(description === '');
      setIsErrorStatus(status === '');

  

      if (name !== "" && status !== "") {
        const purposeData = {
          _id : params.id,
          name,
          description:description,
          status: status ,
          
        
        };
  
  
        const state = "edit";
        dispatch(purposeAddOrUpdate(purposeData, state));
      }
    };
  
  

  
  
  
  
  
  
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
          Purpose Update Form
          </Text>
          <br/>
          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>


<FormControl isInvalid={isErrorName}>
      <FormLabel> Name</FormLabel>
      <Input
        type="text"
        value={name}
        onChange={(e) => {
          setIsErrorName(e.target.value === "");
          setName(e.target.value);
        }}
      />
      {!isErrorName ? (
        <></>
      ) : (
        <FormErrorMessage>Name is required.</FormErrorMessage>
      )}
    </FormControl>



    <FormControl isInvalid={isErrorDescription}>
      <FormLabel>Description</FormLabel>
      <Input
        type="text"
        value={description}
        onChange={(e) => {
          // setIsErrorDescription(e.target.value === "" );
          setDescription(e.target.value);
        }}
      />
      {!isErrorDescription ? (
        <></>
      ) : (
        <FormErrorMessage>Description is required.</FormErrorMessage>
      )}
    </FormControl>



</SimpleGrid>


<SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>


<FormControl isInvalid={isErrorStatus}>
    <FormLabel>Status</FormLabel>
    <Select
      placeholder="----Select status----"
      value={status}
      onChange={(e) => {
        setIsErrorStatus(e.target.value === '')
        setStatus(e.target.value)
      }}
    >
      { dataConstant.status.map((element,index) => {
          return (<option key={element['id']} value={element['value']} >{element['name']}</option>);   
            
        })}
    </Select>
    {!isErrorStatus ? (
      <></>
    ) : (
      <FormErrorMessage>Status is required.</FormErrorMessage>
    )}
  </FormControl>


{/* <FormControl isInvalid={isErrorAdmin}>
<FormLabel>Admin</FormLabel>
<Input
type='text'
onChange={(e) => {
const inputValue = e.target.value;
setIsErrorUnLoadedBays(inputValue === '');
setUnloadedBays(inputValue === '');
}}
/>
{!isErrorAdmin ? (
<></>
) : (
<FormErrorMessage>Admin is required.</FormErrorMessage>
)}
</FormControl> */}


</SimpleGrid>
          <Center>
            {loading ? <Spinner mt='24px' size='lg' /> :
              <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                Submit
              </Button>
            }
          </Center>
        </CardHeader>
        <CardBody>
        </CardBody>
      </Card>
    </Flex>
    );
  }
  
  export default PurposeUpdateForm;
  