// Chakra imports
import {
    Box,
    Button,
    Center,
    Flex,
    Input,
    SimpleGrid,
    Spacer,
    Text,
    Wrap,
    WrapItem,
    useColorModeValue,
    Select,
    Tr,
    Td,
    Table,
    Thead,
    Th,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from 'react-redux';
  import { inspectionqrListAddOrUpdate, inspectionqrListClearShowSnackbar,inspectionqrListGetAll } from '../../actions/inspectionqrAction';
  import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
  } from '@chakra-ui/react'
  import { useToast } from '@chakra-ui/react'
  import { BIOMETRIC_SETTINGS_CLEAR_SNACKBAR } from "constants/types";
  import { Spinner } from '@chakra-ui/react'
  import dataConstant from "constants/dataConstant";
  import { useHistory,useParams } from 'react-router-dom';
  import axios from 'axios';
  import { server_url } from '../../constants/types';
  import { PhoneIcon, AddIcon, CloseIcon } from '@chakra-ui/icons'


  function InspectionQrForm() {
    const params = useParams();
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [name, setName] = useState("");
    const [position, setPosition] = useState("");
    const [description, setDescription] = useState("");
    const [baseurl, setBaseurl] = useState("");
    const [authtype, setAuthtype] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [token, setToken] = useState("");
    const [timeInterval, setTimeIntervel] = useState("");


    const [status, setStatus] = useState(true);
    const [type, setType] = useState("");
    const [inspectionItem, setInspectionItem] = useState("");
    const [inspectionList, setInspectionList] = useState([]);
    const [inspectionDuration, setInspectionDuration] = useState("Daily");

  
    const [isErrorName, setIsErrorName] = useState(false);

    const [isErrorDescription, setIsErrorDescription] = useState(false);
    
    const [isErrorStatus, setIsErrorStatus] = useState(false);
    const [isErrorType, setIsErrorType] = useState(false);
    const [isErrorInspectionItem, setIsErrorInspectionItem] = useState(false);
    const [isErrorInspectionDuration, setIsErrorInspectionDuration] = useState(false);
    const [checklists, setChecklists] = useState([{ description: "" }]);

  
    const dispatch = useDispatch();
    const toast = useToast();
    const history = useHistory();
    const responseStatus = useSelector(state => state.inspectionqr.status);
    const responseMessage = useSelector(state => state.inspectionqr.text);
    const loading = useSelector(state => state.inspectionqr.loading);
    const showSnackbar = useSelector(state => state.inspectionqr.showSnackbar);

    useEffect(()=>{
      inspectionListforDropdown();
    }, [])
  
    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
    }, [])

    const inspectionListforDropdown = () => {
      axios
        .get(`${server_url}/admin/inspection/items?limit=1000`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          setInspectionList(responce.data.data)
        }).catch(() => console.log("error"))
    }


    // Function to handle form submission
    const handleSubmit = (event) => {
      event.preventDefault();
      setIsErrorName(name === '');
      setIsErrorInspectionItem(inspectionItem === '');
      setInspectionDuration(inspectionDuration === '');
      // setIsErrorPassword(password ==='')

      if (name !== ""
       && inspectionDuration !== "" 
       && inspectionItem !== "" 
       ) {
        console.log("Submit");
        // Get form data
        const inspectionData = {
          inspectionItem : inspectionItem,
          name: name,
          description: description,
          status: status,
          type : type,
          inspectionSchedule : inspectionDuration,
          plantAdmin : params.id,
          checklists : checklists

          // Other banner position data fields
        };
  
        // Determine if it's an add or edit operation
        const state = 'add';
        console.log(inspectionData);

        dispatch(inspectionqrListAddOrUpdate(inspectionData, state,params.id))
      }
    };
  
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === 'OK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'success');
            history.goBack();
          }
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'error');
          }
        }
      }
      dispatch({
        type: BIOMETRIC_SETTINGS_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        }
      })
    }, [loading]);
  
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      })
    };

    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
    }, [])

    useEffect(() => {
      dispatch(inspectionqrListGetAll ("&limit=100"));
    }, [dispatch]);

    const addChecklist = () => {
      setChecklists([...checklists, { description: "" }]);
    };

    // Remove a checklist item
  const removeChecklist = (index) => {
    const updatedChecklists = [...checklists];
    updatedChecklists.splice(index, 1);
    setChecklists(updatedChecklists);
  };

  // Handle description change for checklist items
  const handleChecklistChange = (index, value) => {
    const updatedChecklists = [...checklists];
    updatedChecklists[index].description = value;
    setChecklists(updatedChecklists);
  };
  
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Inspection QR Form
            </Text>
            <SimpleGrid minChildWidth='120px' spacing='40px'>
            <FormControl isInvalid={isErrorInspectionItem}>
              <FormLabel>Inspection</FormLabel>
                <Select
                  placeholder="Select Inspection"
                  onChange={(e) => {
                    setIsErrorInspectionItem(e.target.value === '');
                    setInspectionItem(e.target.value);
                  }}
                  value={inspectionItem}
                >
                  {inspectionList ? (inspectionList.map((item, index) => (
                    <option key={item._id} value={item._id}>{item.name}</option>
                  ))) : (<></>)}
                </Select>

                {!isErrorInspectionItem ? (
                  <></>
                ) : (
                  <FormErrorMessage>Inspection is required.</FormErrorMessage>
                )}
              </FormControl>
              
              <FormControl isInvalid={isErrorName}>
                <FormLabel>Name</FormLabel>
                <Input
                  type='text'
                  onChange={(e) => {
                    setIsErrorName(e.target.value === '')
                    setName(e.target.value)
                  }}
                />
                {!isErrorName ? (
                  <></>
                ) : (
                  <FormErrorMessage>Name is required.</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={isErrorType}>
                <FormLabel>Type</FormLabel>
                <Input
                  type='text'
                  onChange={(e) => {
                    // setIsErrorType(e.target.value === '')
                    setType(e.target.value)
                  }}
                />
                {!isErrorType ? (
                  <></>
                ) : (
                  <FormErrorMessage>Type is required.</FormErrorMessage>
                )}
              </FormControl>
              
              <FormControl isInvalid={isErrorDescription}>
                <FormLabel>Description</FormLabel>
                <Input
                  type='text'
                  onChange={(e) => {
                    setIsErrorDescription(e.target.value === '')
                    setDescription(e.target.value)
                  }}
                />
                {!isErrorDescription ? (
                  <></>
                ) : (
                  <FormErrorMessage>Description is required.</FormErrorMessage>
                )}
              </FormControl>
  
              
            </SimpleGrid>

            <SimpleGrid minChildWidth='120px' spacing='40px' mt="8px">
            <FormControl isInvalid={isErrorInspectionDuration}>
                <FormLabel>Duration</FormLabel>
                <Select
                  placeholder="Select duration"
                  value={inspectionDuration}
                  onChange={(e) => {
                    setIsErrorInspectionDuration(e.target.value === '')
                    setInspectionDuration(e.target.value)
                  }}
                >
                  { dataConstant.inspectionDuration.map((element,index) => {
                      return (<option key={element['id']} value={element['value']} >{element['name']}</option>);   
                        
                    })}
                </Select>
                {!isErrorInspectionDuration ? (
                  <></>
                ) : (
                  <FormErrorMessage>Duration is required.</FormErrorMessage>
                )}
              </FormControl>
            <FormControl isInvalid={isErrorDescription}>
                <FormLabel>Description</FormLabel>
                <Input
                  type='text'
                  onChange={(e) => {
                    setIsErrorDescription(e.target.value === '')
                    setDescription(e.target.value)
                  }}
                />
                {!isErrorDescription ? (
                  <></>
                ) : (
                  <FormErrorMessage>Description is required.</FormErrorMessage>
                )}
              </FormControl>
              
              <FormControl isInvalid={isErrorStatus}>
                <FormLabel>Status</FormLabel>
                <Select
                  placeholder="Select status"
                  value={status}
                  onChange={(e) => {
                    setIsErrorStatus(e.target.value === '')
                    setStatus(e.target.value)
                  }}
                >
                  { dataConstant.status.map((element,index) => {
                      return (<option key={element['id']} value={element['value']} >{element['name']}</option>);   
                        
                    })}
                </Select>
                {!isErrorStatus ? (
                  <></>
                ) : (
                  <FormErrorMessage>Status is required.</FormErrorMessage>
                )}
              </FormControl>
  
              
            </SimpleGrid>
            <SimpleGrid minChildWidth="120px" spacing="40px" mt="8px">
            <Table>
              <Thead>
                <Tr>
                  <Td colSpan="1" width="90%">Checklist</Td>
                  <Td width="10%">
                    <Button mt="2px" colorScheme="teal" onClick={addChecklist}>
                    <AddIcon />
                    </Button>
                    
                  </Td>
                </Tr>
              </Thead>
              {checklists.map((checklist, index) => (
                <Tr key={index}>
                  <Td>
                    <FormControl isInvalid={isErrorDescription} pb="4px">
                      <FormLabel>Title</FormLabel>
                      <Input
                        type="text"
                        value={checklist.description}
                        onChange={(e) =>
                          handleChecklistChange(index, e.target.value)
                        }
                      />
                      {!isErrorDescription ? (
                        <></>
                      ) : (
                        <FormErrorMessage>
                          Title is required.
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Td>
                  <Td>
                    {index > 0 && (
                      <Button
                        colorScheme="red"
                        onClick={() => removeChecklist(index)}
                      >
                        <CloseIcon/>
                      </Button>
                    )}
                  </Td>
                </Tr>
              ))}
            </Table>
            
          </SimpleGrid>
            <Center>
              {loading ? <Spinner mt='24px' size='lg' /> :
                <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                  Submit
                </Button>
              }
            </Center>
          </CardHeader>
          <CardBody>
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default InspectionQrForm;
  