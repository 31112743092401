import React, { useEffect, useState,useRef } from 'react';
import { Flex, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link ,Button, Tabs, TabList, TabPanels, TabPanel, Tab,Badge} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';

import { AddIcon } from '@chakra-ui/icons';
import { Skeleton, SkeletonCircle, SkeletonText,Stack } from '@chakra-ui/react'
import { useParams ,useLocation,useHistory} from "react-router-dom/cjs/react-router-dom.min";
import axios from 'axios';
import { server_url } from "constants/types";
import { DelivaryInOutGetAll } from 'actions/delivaryInOutAction';
import PastAdvanceSearch from './Past/PastAdvanceSearch'
import PastTable from './Past/PastTable'
import CurrentTable from './Current/CurrentTable'
import CurrentAdvanceSearch from './Current/CurrentAdvanceSearch'
import LeaveatgateTable from './Leaveatgate/LeaveatgateTable'
import LeaveatgateAdvanceSearch from './Leaveatgate/LeaveatgateAdvanceSearch'
import HandedoverTable from './Handedover/HandedoverTable'
import HandedoverAdvanceSearch from './Handedover/HandedoverAdvanceSearch'
const DeliveryTabs = (props) => {
    const {
        module=1,
      } = props;
  const tableRef = useRef(null);
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [tab, setTab] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const totalItems = useSelector(state => state.delivaryInOut.totalItems);
  const [pastTotal, setPastTotal] = useState(0);
  const [currentTotal, setCurrentTotal] = useState(0);
  const [leaveAtGateTotal, setLeaveAtGateTotal] = useState(0);
  const [handedOverTotal, setHandedOverTotal] = useState(0);
  const [tabCountDetail, setTabCountDetail] = useState('');
  const searching = useSelector(state => state.visitorInOut.searching);
  useEffect(() => {
    
    const query = new URLSearchParams(location.search);
    setTabValue(parseInt(tabValue, 10));
    setTabValue(query.get('tab'));
    setTabValue(query.get('tab'));
    setTabValue(query.get('tab'));
    // tabValue = query.get('tab');
    console.log(tabValue + "taaaaaaa")
    const tabValueInt = parseInt(query.get('tab'), 10)
    if (!isNaN(tabValueInt)) {
        setTab(tabValueInt);
      }
      else
      {
        setTab(0);
      }
      getVisitorTabCount();
  }, []);

//   useEffect(() => {
    
//     const query = new URLSearchParams(location.search);
//     // setTabValue(parseInt(tabValue, 10));
//     // setTabValue(query.get('tab'));
//     const tabValueInt = parseInt(query.get('tab'), 10);
//     console.log("tabValueInt" +tabValueInt);
//     switch (tabValueInt) {
//         case 0:
//             setPastTotal(visitortotalItems.totalItems);
//             console.log(visitortotalItems.totalItems + "items")
//             break;
//         case 2:
//             setCurrentTotal(visitortotalItems.totalItems);
//             break;
//         case 3:
//             setLeaveAtGateTotal(visitortotalItems.totalItems);
//             break;
//         case 4:
//             setHandedOverTotal(visitortotalItems.totalItems);
//             break;
    
//         default:
//             break;
//     }
    
//   }, [searching]);

  const getVisitorTabCount = () => {
    axios
      .get(`${server_url}/delivery/tab-count`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((response) => {
        if(response.data.status == 'OK')
            {
                var data = response.data.data;
                setPastTotal(data['pastCount']);
                setCurrentTotal(data['currentCount']);
                setLeaveAtGateTotal(data['leaveatgateCount']);
                setHandedOverTotal(data['handedoverCount']);
            }
        
      }).catch(() => console.log("error"))
  }

const onChange = (tab) =>
    {
        if(tab==0)
            {
                history.push(`?tab=${tab}`);
                dispatch(DelivaryInOutGetAll("type=EXIT"));
            }
            else if(tab ==1)
                {
                    history.push(`?tab=${tab}`);
                    dispatch(DelivaryInOutGetAll("type=IN"));
                }
            else if(tab ==2)
                {
                    history.push(`?tab=${tab}`);
                    dispatch(DelivaryInOutGetAll("status=LEAVE AT GATE"));
                }
            else if(tab ==3)
                {
                    history.push(`?tab=${tab}`);
                    dispatch(DelivaryInOutGetAll("status=RECIEVED"));
                }
        setTab(tab);
    }
    useEffect(() => {
        const timer = setTimeout(() => {
          setLoading(false); // Hide loader after 2 seconds
        }, 200);
    
        return () => clearTimeout(timer); // Clean up timer on unmount
      }, []);

  return (
  <>
  {tab ==0 ?<PastAdvanceSearch></PastAdvanceSearch> : (tab ==1 ?<CurrentAdvanceSearch/> : tab ==2 ? <LeaveatgateAdvanceSearch></LeaveatgateAdvanceSearch>: tab == 3 ? <HandedoverAdvanceSearch></HandedoverAdvanceSearch> : <></>)}
  <Flex direction="column" pt={{ base: "120px", md: "20px" }}>
       
        {!loading ?<Tabs defaultIndex={tab}>
        <Card><TabList>
            <Tab onClick={()=>{
                onChange(0);
                setTab(0);
            }} >Past <Badge ml='1' fontSize='0.8em' colorScheme='red'>
            {tab==0 ?totalItems:pastTotal }
          </Badge>
          </Tab>
            <Tab onClick={()=>{
                onChange(1);
                setTab(1);
            }}>Current <Badge ml='1' fontSize='0.8em' colorScheme='red'>
            {tab==1 ?totalItems:currentTotal}
          </Badge></Tab>
            <Tab isSelected onClick={()=>{
                onChange(2);
                setTab(2);
            }}>Leave At Gate<Badge ml='1' fontSize='0.8em' colorScheme='red'>
            {tab==2 ?totalItems:leaveAtGateTotal}
          </Badge> </Tab>
            <Tab isSelected onClick={()=>{
                onChange(3);
                setTab(3);
            }}>Handed Over <Badge ml='1' fontSize='0.8em' colorScheme='red'>
            {tab==3 ?totalItems:handedOverTotal}
          </Badge></Tab>
        </TabList>
        </Card>
        <TabPanels >
            <TabPanel className="remove-padding-table">{tab ==0 ? <PastTable></PastTable>:<></>}</TabPanel>
            <TabPanel className="remove-padding-table">{tab ==1 ? <CurrentTable></CurrentTable>:<></>}</TabPanel>
            <TabPanel className="remove-padding-table">{tab ==2 ? <LeaveatgateTable></LeaveatgateTable>:<></>}</TabPanel>
            <TabPanel className="remove-padding-table">{tab ==3 ? <HandedoverTable></HandedoverTable>:<></>}</TabPanel>
            
        </TabPanels>
        </Tabs>:<></>}
    </Flex>
  </>
  );
};

export default DeliveryTabs;
