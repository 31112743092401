import axios from 'axios';
import {
  REPORTINCEDENT_LOADING,
  REPORTINCEDENT_GET_ALL_SUCCESS,
  REPORTINCEDENT_ERROR,
  REPORTINCEDENT_CLEAR_SNACKBAR
} from '../constants/types';

import {
  server_url
} from '../constants/types';

export const reportIncedentGetAll = (pagination = "") => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: REPORTINCEDENT_LOADING,
        payload: {
          loading: true
        }
      });
      axios.get(`${server_url}/report-incident?${pagination}`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      })
        .then(response => {
          console.log("reportIncedentList----");
          console.log(response);
          dispatch({
            type: REPORTINCEDENT_GET_ALL_SUCCESS,
            payload: {
              reportIncedentList: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              totalItems: response.data.totalItems,
              response: response.data,
              loading: false,
              status: "success",
              text: "Get all reportIncedent data successfully."
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: REPORTINCEDENT_ERROR,
            payload: {
              status: "error",
              text: "Error occurred during getting reportIncedent data.",
              loading: false
            }
          });
          resolve();
        })
    })
  }
}

export const reportIncedentAddOrUpdate = (reportIncedent, state , limit='' , page='') => {
  console.log("reportIncedent")
  console.log(reportIncedent)
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: REPORTINCEDENT_LOADING,
        payload: {
          loading: true
        }
      });
      if (state === "add") {
        axios.post(`${server_url}/report-incident`, reportIncedent, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
          .then(responseF => {
            axios.get(`${server_url}/report-incident?limit=${limit}&page=${page}`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
              .then(response => {
                dispatch({
                  type: REPORTINCEDENT_GET_ALL_SUCCESS,
                  payload: {
                    reportIncedentList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: REPORTINCEDENT_ERROR,
                  payload: {
                    text: "Error occurred during getting reportIncedent data.",
                    status: "error",
                    loading: false
                  }
                });
                resolve();
              })
          })
          .catch((e) => {
            dispatch({
              type: REPORTINCEDENT_ERROR,
              payload: {
                text: "Error occurred during adding reportIncedent data.",
                status: "error",
                loading: false
              }
            });
            resolve();
          })
      } else if (state === "edit") {
        axios.put(`${server_url}/report-incident/${reportIncedent._id}`, reportIncedent, {
          headers: {

            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
          .then(responseF => {
            axios.get(`${server_url}/report-incident?limit=${limit}&page=${page}`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
              .then(response => {
                dispatch({
                  type: REPORTINCEDENT_GET_ALL_SUCCESS,
                  payload: {
                    reportIncedentList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    reportIncedentDetail: responseF.data['data'],
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: REPORTINCEDENT_ERROR,
                  payload: {
                    text: "Error occurred during getting reportIncedent data.",
                    status: "error",
                    loading: false
                  }
                });
                resolve();
              })
          })
          .catch((e) => {
            dispatch({
              type: REPORTINCEDENT_ERROR,
              payload: {
                text: "Error occurred during updating reportIncedent data.",
                status: "error",
                loading: false
              }
            });
            resolve();
          })
      }
      else if (state === "view") {
        axios.get(`${server_url}/report-incident/${reportIncedent._id}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
          .then(responseF => {
            axios.get(`${server_url}/report-incident?limit=${limit}&page=${page}`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
              .then(response => {

                dispatch({
                  type: REPORTINCEDENT_GET_ALL_SUCCESS,
                  payload: {
                    reportIncedentList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    reportIncedentDetail: responseF.data['data'],
                    loading: false,
                    typeOfIncident: responseF.data["data"]['typeOfIncident'],
                    status: responseF.data.status,
                    text: responseF.data.message,
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: REPORTINCEDENT_ERROR,
                  payload: {
                    text: "Error occurred during getting reportIncedent data.",
                    status: "error",
                    loading: false
                  }
                });
                resolve();
              })
          })
          .catch((e) => {
            dispatch({
              type: REPORTINCEDENT_ERROR,
              payload: {
                text: "Error occurred during updating reportIncedent data.",
                status: "error",
                loading: false
              }
            });
            resolve();
          })
      }
    })
  }
}

export const reportIncedentDelete = (reportIncedent , limit='' , page='') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: REPORTINCEDENT_LOADING,
        payload: {
          loading: true
        }
      });
      axios.delete(`${server_url}/report-incident/${reportIncedent._id}`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then(responseF => {
        axios.get(`${server_url}/report-incident?limit=${limit}&page=${page}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then(response => {
          dispatch({
            type: REPORTINCEDENT_GET_ALL_SUCCESS,
            payload: {
              reportIncedentList: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              reportIncedentDetail: responseF.data['data'],
              loading: false,
              status: responseF.data['status'],
              text: responseF.data['message']
            }
          });
          resolve();
        }).catch((e) => {
          dispatch({
            type: REPORTINCEDENT_ERROR,
            payload: {
              text: "Error occured during getting reportIncedent data.",
              status: "error",
              loading: false
            }
          });
          resolve();
        })
      }).catch((e) => {
        dispatch({
          type: REPORTINCEDENT_ERROR,
          payload: {
            text: "Error occured during deleteing reportIncedent data.",
            status: "error",
            loading: false
          }
        });
        resolve();
      })
    })
  }
}

export const reportIncedentClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: REPORTINCEDENT_CLEAR_SNACKBAR
    });
  }
}
