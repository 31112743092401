import React from "react";
import {
  Avatar,
  Badge,
  Button,
  Flex,
  Link,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure,
  ButtonGroup,
  IconButton,
  Image,
  Switch
} from "@chakra-ui/react";
import { BiCalendar } from 'react-icons/bi';
import { BsBoxArrowUpRight, BsFillTrashFill } from "react-icons/bs";
import { ViewIcon } from "@chakra-ui/icons";
import { AiFillEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";

import { driverDelete,driverAddOrUpdate } from "../../actions/DriverAction"; // Make sure you have the correct import for driverDelete


const DriverTableRow = (props) => {
  const {
    name,
    photo,
    number,
    email,
    driverId,
    isLast,
    id,
    status,
    department,
    subdepartment,
    designation,
    passcode,
    company,
    startDate,
    endDate,
    activeDate,
    inactiveDate,
    serialnumber,
    isExit
  } = props;

  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const driverDetail = useSelector((state) => state.driver.driverDetail); // Make sure you have the correct state slice for driver detail
  const page = useSelector((state) => state.driver.page); // Make sure you have the correct state slice for driver detail
  const limit = useSelector((state) => state.driver.limit); // Make sure you have the correct state slice for driver detail
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const dispatch = useDispatch();

  const handleDelete = () => {
    const driver = {
      _id: id,
    };
    dispatch(driverDelete(driver._id));
  };

  function handleSwitchChange() {

    console.log();

    const driver = {
      _id: id,
      status: !status
    };
    const state = 'edit';
    console.log(driver._id)
    dispatch(driverAddOrUpdate(driver, state,limit,page))

  }

  const startingDate = startDate ? new Date(startDate).toISOString().split('T')[0] : '';
  const endingDate = endDate ? new Date(endDate).toISOString().split('T')[0] : '';


  return (
    <>
      <Tr background={isExit?'red.500':''} fontStyle={{color:isExit?'white':'black'}}>
      <Td
          >
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={titleColor}
                  fontWeight="bold"
                  minWidth="100%"
                >
                  {serialnumber}
                </Text>
              </Flex>
            </Flex>
          </Td> 
        <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {name}
              </Text>
            </Flex>
          </Flex>
        </Td>

        <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {passcode}
              </Text>
            </Flex>
          </Flex>
        </Td>

        <Td
          minWidth={{ sm: "250px" }}
          pl="15px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
            <Image
                boxSize='70px'
                objectFit='cover'
                src={photo}
                borderRadius='50%'
                alt='Dan Abramov'
                _hover={{ transform: 'scale(2)' }} 
                transition='transform 0.5s ease'
                cursor = 'pointer'
          />
              
            </Text>
          </Flex>
        </Td>

        <Td
          minWidth={{ sm: "250px" }}
          pl="15px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {number}
            </Text>
          </Flex>
        </Td>
        <Td
          minWidth={{ sm: "250px" }}
          // pl="15px"
          pl="30px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
>
  <Switch
    sx={{
      'span.chakra-switch__track:not([data-checked])': {
        backgroundColor: 'red'
      }
    }}
    colorScheme='green'
    id='isRequired'
    onChange={handleSwitchChange}
    isChecked={status}
    marginLeft="1.5rem" 
    marginBottom="1rem" // Adjust the margin to create space between Switch and inactiveDate
  />

{status ? (
    <Text color="green.500" fontWeight="bold">
      {activeDate}
    </Text>
  ) : (
    <Text color="red.500" fontWeight="bold">
      {inactiveDate}
    </Text>
  )}
</Td>

        <Td
          minWidth={{ sm: "250px" }}
          pl="30px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {department}
              </Text>
            </Flex>
          </Flex>
        </Td>

        <Td
          minWidth={{ sm: "250px" }}
          pl="30px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {subdepartment}
              </Text>
            </Flex>
          </Flex>
        </Td>

        <Td
          minWidth={{ sm: "250px" }}
          pl="30px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {designation}
              </Text>
            </Flex>
          </Flex>
        </Td>

        <Td
          minWidth={{ sm: "250px" }}
          pl="10px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {email}
            </Text>
          </Flex>
        </Td>
        <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {startingDate}
            </Text>
          </Flex>
        </Td>
        <Td
          minWidth={{ sm: "250px" }}
          pl="15px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {endingDate}
            </Text>
          </Flex>
        </Td>
        <Td
          minWidth={{ sm: "250px" }}
          pl="15px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {company}
            </Text>
          </Flex>
        </Td>

        <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
         <Link href={`#/admin/driver/attendance/history/list/${id}`}>
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Button colorScheme='facebook' leftIcon={<BiCalendar />}>
          Attendance History
          </Button>
          </Flex>
          </Link>
        </Td>
        {/* <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {driverId}
            </Text>
          </Flex>
        </Td> */}

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <ButtonGroup>
            <Link href={`#/admin/driver-view/${id}`}>
              <IconButton
                colorScheme="blue"
                icon={<ViewIcon />}
                aria-label="Up"
              />
            </Link>

            <Link href={`#/admin/driver-update-form/${id}`}>
              <IconButton
                colorScheme="green"
                icon={<AiFillEdit />}
                aria-label="Edit"
              />
            </Link>

            <IconButton
              colorScheme="red"
              variant="outline"
              icon={<BsFillTrashFill />}
              aria-label="Delete"
              onClick={onOpen}
            />
          </ButtonGroup>
        </Td>
      

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Employee
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={()=>{handleDelete() , onClose()}} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
        
        
      </Tr>
    </>
  );
};

export default DriverTableRow;
