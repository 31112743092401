import axios from "axios";
import {
  CHECKPOST_LOADING,
  CHECKPOST_GET_ALL_SUCCESS,
  CHECKPOST_ERROR,
  CHECKPOST_CLEAR_SNACKBAR,
} from "../constants/types";

import { server_url } from "../constants/types";

export const checkpostGetAll = (pagination = "") => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: CHECKPOST_LOADING,
        payload: {
          loading: true,
        },
      });
      axios
        .get(`${server_url}/checkpost?${pagination}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        })
        .then((response) => {
          dispatch({
            type: CHECKPOST_GET_ALL_SUCCESS,
            payload: {
              checkpostList: response.data.data,
              response: response.data,
              loading: false,
              status: 'success',
              text: "Get all banner position successfully",
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: CHECKPOST_ERROR,
            payload: {
              status: "error",
              text: "Error occurred during getting checkpost data.",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};

export const checkpostAddOrUpdate = (checkpost, state) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: CHECKPOST_LOADING,
        payload: {
          loading: true,
        },
      });
      if (state === "add") {
        axios
          .post(`${server_url}/checkpost`, checkpost, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/checkpost`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("authToken"),
                },
              })
              .then((response) => {
                dispatch({
                  type: CHECKPOST_GET_ALL_SUCCESS,
                  payload: {
                    checkpostList: response.data.data,
                    response: response.data,
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: CHECKPOST_ERROR,
                  payload: {
                    text: "Error occurred during getting checkpost data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: CHECKPOST_ERROR,
              payload: {
                text: "Error occurred during adding checkpost data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      } else if (state === "edit") {
        axios
          .put(`${server_url}/checkpost/${checkpost._id}`, checkpost, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/checkpost`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("authToken"),
                },
              })
              .then((response) => {
                dispatch({
                  type: CHECKPOST_GET_ALL_SUCCESS,
                  payload: {
                    checkpostList: response.data.data,
                    response: response.data,
                    checkpostDetail: responseF.data["data"],
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: CHECKPOST_ERROR,
                  payload: {
                    text: "Error occurred during getting checkpost data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: CHECKPOST_ERROR,
              payload: {
                text: "Error occurred during updating checkpost data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      }else if (state === "view") {
        axios
          .get(`${server_url}/checkpost-view/${checkpost._id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            },
          })
          .then((responseF) => {
            console.log("------444444----");
            console.log(responseF.data["data"]);
            axios
              .get(`${server_url}/checkpost`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("authToken"),
                },
              })
              .then((response) => {
                dispatch({
                  type: CHECKPOST_GET_ALL_SUCCESS,
                  payload: {
                    checkpostList: response.data.data,
                    response: response.data,
                    checkpostDetail: responseF.data["data"],
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: CHECKPOST_ERROR,
                  payload: {
                    text: "Error occurred during getting checkpost data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: CHECKPOST_ERROR,
              payload: {
                text: "Error occurred during updating checkpost data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      }
    });
  };
};

export const checkpostDelete = (checkpost,plant="") => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: CHECKPOST_LOADING,
        payload: {
          loading: true,
        },
      });
      axios
        .delete(`${server_url}/checkpost/${checkpost._id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        })
        .then((responseF) => {
          axios
            .get(`${server_url}/checkpost?plant=${plant}`, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("authToken"),
              },
            })
            .then((response) => {
              dispatch({
                type: CHECKPOST_GET_ALL_SUCCESS,
                payload: {
                  checkpostList: response.data.data,
                  response: response.data,
                  checkpostDetail: responseF.data["data"],
                  loading: false,
                  status: responseF.data["status"],
                  text: responseF.data["message"],
                  limit: response.data.limit,
                  page: response.data.currentPage,
                  totalPages: response.data.totalPages,
                },
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: CHECKPOST_ERROR,
                payload: {
                  text: "Error occurred during getting checkpost data.",
                  status: "error",
                  loading: false,
                },
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: CHECKPOST_ERROR,
            payload: {
              text: "Error occurred during deleting checkpost data.",
              status: "error",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};

export const checkpostClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: CHECKPOST_CLEAR_SNACKBAR,
    });
  };
};
