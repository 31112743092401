// Chakra imports
import {
    Box,
    Button,
    Center,
    Checkbox,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Input,
    Select,
    SimpleGrid,
    Spinner,
    Stack,
    Text,
    useColorModeValue,
    useToast,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import axios from 'axios';
  import { server_url } from "constants/types";
  import { useHistory } from 'react-router-dom';

  import {
    subDepartmentAddOrUpdate,
    subDepartmentClearShowSnackbar,
    subDepartmentGetAll,
  } from "../../../actions/subDepartmentAction";
  import { SUBDEPARTMENT_CLEAR_SNACKBAR } from "constants/types";
  import {
    List,
    ListItem,
    ListIcon,
    OrderedList,
    UnorderedList,
  } from "@chakra-ui/react";
  import dataConstant from "constants/dataConstant";
import { permissionGetAll } from "actions/permissionActions";
import { moduleGetAll } from "actions/moduleActions";
import { departmentGetAll } from "actions/departmentActions";
import { adminGetAll } from "actions/adminActions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
  function GroupSubDepartmentForm() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [status, setStatus] = useState("");
    const [departmentId, setDepartmentId] = useState("");
    const [image , setImage] = useState("");
    const [adminId, setAdminId] = useState("");
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [isErrorName, setIsErrorName] = useState(false);
    const [isErrorDescription, setIsErrorDescription] = useState(false);
    const [isErrorStatus, setIsErrorStatus] = useState(false);
    const [isErrorImage, setIsErrorImage] = useState(false);
    const [isErrorDepartmentId, setIsErrorDepartmentId] = useState(false);
    const [isErrorAdminId, setIsErrorAdminId] = useState(false);
    const [permissionStyle, setPermissionStyle] = useState({
      display: "none",
    });
    const history = useHistory();

  
    const dispatch = useDispatch();
    const params = useParams();
    const toast = useToast();
  
    const statuses = ["success", "error", "warning", "info"];
    const responseStatus = useSelector((state) => state.subDepartment.status);
    const responseMessage = useSelector((state) => state.subDepartment.text);
    const loading = useSelector((state) => state.subDepartment.loading);
    const showSnackbar = useSelector(
      (state) => state.subDepartment.showSnackbar
    );
    const moduleList = useSelector((state) => state.module.moduleList);
    const permissionList = useSelector(
      (state) => state.permission.permissionList
    );

    const departmentList = useSelector((state) => state.department.departmentList)
    const subDepartmentList = useSelector((state) => state.subDepartment.subDepartmentList)
    const adminList = useSelector((state) => state.admin.adminList)
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === "OK" && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, "success");
            history.goBack();
          }
        } else if (responseStatus === "NOK" && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, "error");
          }
        }
      }
      dispatch(subDepartmentClearShowSnackbar());
    }, [loading]);
  
    useEffect(() => {
      dispatch(moduleGetAll());
      dispatch(permissionGetAll());
      dispatch(departmentGetAll("limit=100&group="+params.id));
      dispatch(adminGetAll());
      dispatch(subDepartmentGetAll())

    }, []);
    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
    }, [])
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      });
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      setIsErrorName(name === "");
      // setIsErrorDescription(description === "");
      setIsErrorStatus(status === "");
      // setIsErrorImage(image === '');
      setIsErrorDepartmentId(departmentId === "");
  
      if (
        name !== "" &&
        // description !== "" &&
        status !== "" &&
        // image !== "" &&
        departmentId !== "" 
      ) {
        const subDepartment = {
          departmentId: departmentId,
          name: name,
          description: description,
          status: status,
          group: params.id,
          permissions: selectedPermissions,
        };
        if(image)
        {
          subDepartment.file = image;
        }
        if(description)
        {
          subDepartment.description = description;
        }
        console.log("subDepartment");
        console.log(subDepartment);
        const state = "add";
        dispatch(subDepartmentAddOrUpdate(subDepartment, state));
      }
    };

  
    const handlePermissionChange = (permissionId, moduleId) => {
      setSelectedPermissions((prevSelectedPermissions) => {
        const existingPermissionIndex = prevSelectedPermissions.findIndex(
          (permission) =>
            permission._id === permissionId &&
            permission.modules._id === moduleId
        );
  
        if (existingPermissionIndex !== -1) {
          const updatedPermissions = [...prevSelectedPermissions];
          updatedPermissions.splice(existingPermissionIndex, 1);
          return updatedPermissions;
        } else {
          const permission = permissionList.find(
            (permission) =>
              permission._id === permissionId &&
              permission.modules._id === moduleId
          );
  
          return [...prevSelectedPermissions, permission];
        }
      });
    };
  console.log("ADMIN LIST")
console.log(adminList)

    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              SubDepartment Form
            </Text>
            <SimpleGrid minChildWidth="120px" spacing="40px">
            <FormControl isInvalid={isErrorDepartmentId}>
                <FormLabel>Department</FormLabel>
                <Select
                  placeholder="Select department"
                  onChange={(e) => {
                    setIsErrorDepartmentId(e.target.value === "");
                    setDepartmentId(e.target.value);
                  }}
                >{departmentList.map((status) => (
                    <option key={status.id} value={status._id}>
                      {status.name}
                    </option>
                  ))}
                
                  {/* Render the department options here */}
                </Select>
                {!isErrorDepartmentId ? (
                  <></>
                ) : (
                  <FormErrorMessage>Department is required.</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={isErrorName}>
                <FormLabel>Name</FormLabel>
                <Input
                  type="text"
                  onChange={(e) => {
                    setIsErrorName(e.target.value === "");
                    setName(e.target.value);
                  }}
                />
                {!isErrorName ? (
                  <></>
                ) : (
                  <FormErrorMessage>Name is required.</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={isErrorImage}>
              <FormLabel>Image</FormLabel>
              <Input
                type='file'
                onChange={(e) => {
                  console.log(e.target.files);
                  console.log(e.target.value);
                  setIsErrorImage(e.target.value === '')
                  setImage(e.target.files[0])
                }}
              />
              {!isErrorImage ? (
                <></>
              ) : (
                <FormErrorMessage>Image is required.</FormErrorMessage>
              )}
            </FormControl>
              <FormControl isInvalid={isErrorDescription}>
                <FormLabel>Description</FormLabel>
                <Input
                  type="text"
                  onChange={(e) => {
                    // setIsErrorDescription(e.target.value === "");
                    setDescription(e.target.value);
                  }}
                />
                {!isErrorDescription ? (
                  <></>
                ) : (
                  <FormErrorMessage>Description is required.</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={isErrorStatus}>
                <FormLabel>Status</FormLabel>
                <Select
                  placeholder="Select status"
                  onChange={(e) => {
                    setIsErrorStatus(e.target.value === "");
                    setStatus(e.target.value);
                  }}
                >
                  {dataConstant.status.map((status) => (
                    <option key={status.id} value={status.value}>
                      {status.name}
                    </option>
                  ))}
                </Select>
                {!isErrorStatus ? (
                  <></>
                ) : (
                  <FormErrorMessage>Status is required.</FormErrorMessage>
                )}
              </FormControl>
             
              <FormControl mt="24px" style={permissionStyle}>
                <FormLabel>Permissions</FormLabel>
                <Stack spacing={4}>
                  <OrderedList>
                    {/* Render the permission checkboxes here */}
                  </OrderedList>
                </Stack>
              </FormControl>
            </SimpleGrid>
            <Center>
              {loading ? (
                <Spinner mt="24px" size="lg" />
              ) : (
                <Button colorScheme="blue" mt="24px" onClick={handleSubmit}>
                  Submit
                </Button>
              )}
            </Center>
          </CardHeader>
          <CardBody></CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default GroupSubDepartmentForm;
  