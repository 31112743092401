import {
  Avatar,
  Badge,
  Button,
  Flex,
  Link,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure,
  ButtonGroup,
  IconButton,
} from "@chakra-ui/react";
import { BsBoxArrowUpRight, BsFillTrashFill } from "react-icons/bs";
import { ViewIcon} from '@chakra-ui/icons';
import { AiFillEdit } from "react-icons/ai";
import { userDelete } from "actions/userActions";
import React, { useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react'


function TablesTableRow(props) {
  const { logo, name, email, contactNo, password, isLast,id } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const authDetail = useSelector((state) => state.auth.authDetail);

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef()

  const dispatch = useDispatch();

  const deleteUser = () =>
  {
    const user = {
      _id : id
    };
    dispatch(userDelete(user))
  }
console.log("authDetail"+authDetail);
console.log(authDetail);
  if(authDetail != {})
  {
    return (
      <>
       <Tr>
         <Td
           minWidth={{ sm: "250px" }}
           pl="0px"
           borderColor={borderColor}
           borderBottom={isLast ? "none" : null}
         >
           <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
             <Flex direction="column">
               <Text
                 fontSize="md"
                 color={titleColor}
                 fontWeight="bold"
                 minWidth="100%"
               >
                 {name}
               </Text>
               
             </Flex>
           </Flex>
         </Td>
   
         <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
           <Flex direction="column">
             <Text fontSize="md" color={textColor} fontWeight="bold">
               {email}
             </Text>
             
           </Flex>
         </Td>
         
         <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
           <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
             {contactNo}
           </Text>
         </Td>
   
         <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
           <Flex direction="column">
             <Text fontSize="md" color={textColor} fontWeight="bold">
               {password}
             </Text>
             
           </Flex>
         </Td>
         <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
           {authDetail.role.allPermissions == false? <ButtonGroup variant="solid" size="sm" spacing={3}>
            {console.log(authDetail.role.allPermissions)}
           {authDetail.role.permissions.map((row, index, arr) => {
              console.log(row['permissionName']);
                if(row['permissionName'] == 'VIEW' && row['moduleName'] == 'USER' )
                {
                  return (
                    <Link href={`#/admin/view/form/${id}`}>
                      <IconButton
                        colorScheme="blue"
                        icon={<ViewIcon />}
                        aria-label="Up"
                      />
                      </Link>
                  );
                }
                if(row['permissionName'] == 'EDIT' && row['moduleName'] == 'USER' )
                {
                  return (
                    <Link href={`#/admin/update/form/${id}`}>
                      <IconButton
                        colorScheme="green"
                        icon={<AiFillEdit />}
                        aria-label="Edit"
                      />
                    </Link>
                  );
                }
                if(row['permissionName'] == 'DELETE' && row['moduleName'] == 'USER' )
                {
                  return (
                    <IconButton
                      colorScheme="red"
                      variant="outline"
                      icon={<BsFillTrashFill />}
                      aria-label="Delete"
                      onClick={onOpen}
                    />
                  );
                }
              })}
           
           </ButtonGroup> : <ButtonGroup><Link href={`#/admin/view/form/${id}`}>
             <IconButton
               colorScheme="blue"
               icon={<ViewIcon />}
               aria-label="Up"
             />
             </Link>
                 
             <Link href={`#/admin/update/form/${id}`}>
              <IconButton
                colorScheme="green"
                icon={<AiFillEdit />}
                aria-label="Edit"
              />
            </Link>
   
             
             
             <IconButton
               colorScheme="red"
               variant="outline"
               icon={<BsFillTrashFill />}
               aria-label="Delete"
               onClick={onOpen}
             /></ButtonGroup>}
         </Td>
       </Tr>
       {/* <Button colorScheme='red' onClick={onOpen}>
           Delete Customer
         </Button> */}
   
         <AlertDialog
           isOpen={isOpen}
           leastDestructiveRef={cancelRef}
           onClose={onClose}
         >
           <AlertDialogOverlay>
             <AlertDialogContent>
               <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                 Delete User
               </AlertDialogHeader>
   
               <AlertDialogBody>
                 Are you sure? You can't undo this action afterwards.
               </AlertDialogBody>
   
               <AlertDialogFooter>
                 <Button ref={cancelRef} onClick={onClose}>
                   Cancel
                 </Button>
                 <Button colorScheme='red' onClick={deleteUser} ml={3}>
                   Delete
                 </Button>
               </AlertDialogFooter>
             </AlertDialogContent>
           </AlertDialogOverlay>
         </AlertDialog>
      </>
       
     );
  }
  else
  {
    return null;
  }

  
}

export default TablesTableRow;
