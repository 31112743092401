// Import the action type constants for patrollingLogs
import {
  PATROLLING_LOGS_CLEAR_SNACKBAR,
  PATROLLING_LOGS_LOADING,
  PATROLLING_LOGS_ERROR,
  PATROLLING_LOGS_GET_ALL_SUCCESS,
  PATROLLING_LOGS_SEARCH  // Add the new action type for search if needed
} from '../constants/types';

const initialState = {
  response: {},
  patrollingLogsList: [],
  patrollingLogsDetail: {},
  patrollingLogs: [],
  loading: false,
  status: 'success',
  text: '',
  showSnackbar: false,
  limit: 0,  // Add missing properties
  page: 0,
  totalPages: 0,
  searching: "", // Add missing properties,
  totalItems: 0,
};

const patrollingLogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PATROLLING_LOGS_CLEAR_SNACKBAR:
      return {
        ...state,
        showSnackbar: false,
      };
    case PATROLLING_LOGS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PATROLLING_LOGS_ERROR:
      return {
        ...state,
        patrollingLogsList: [],
        response: {},
        patrollingLogsDetail: {},
        patrollingLogs: [],
        limit: 0,  // Add missing properties
        page: 0,
        totalPages: 0,
        totalItems: 0,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };
    case PATROLLING_LOGS_GET_ALL_SUCCESS:
      return {
        ...state,
        patrollingLogsList: action.payload.patrollingLogsList,
        response: action.payload.response,
        patrollingLogsDetail: action.payload.patrollingLogsDetail,
        patrollingLogs: action.payload.patrollingLogs,
        limit: action.payload.limit,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        totalItems: action.payload.totalItems,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };
    case PATROLLING_LOGS_SEARCH: // Add the new case
      return {
        ...state,
        searching: action.payload.searching
      };
    default:
      return state;
  }
};

export default patrollingLogsReducer;
