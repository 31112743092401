import React, { useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Flex,
  Link,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure,
  ButtonGroup,
  IconButton,
  Image,
  Switch,
  
  
} from "@chakra-ui/react";
import { BiCalendar } from 'react-icons/bi';
import { BsBoxArrowUpRight, BsFillTrashFill } from "react-icons/bs";
import { ViewIcon,InfoIcon } from "@chakra-ui/icons";
import { AiFillEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { employeeDelete } from "actions/EmployeeAction";
import { ChangeAdminStatus } from "../../actions/adminActions";
import { employeeAddOrUpdate } from "actions/EmployeeAction";
import { employeeAttendanceHistoryGetAll } from "actions/EmployeeAction";


const EmployeeTableRow = (props) => {
  const {
    employeeName,
    passcode,
    photo,
    phone,
    officialEmail,
    department,
    subdepartment,
    designation,
    startDate,
    endDate,
    companyName,
    isLast,
    serialnumber,
    status,
    activeDate,
    inactiveDate,
    id,
    isExit,
    employeeId
  } = props;

  console.log("department");
  console.log(department);
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const employeeDetail = useSelector((state) => state.employee.employeeDetail);
  const limit = useSelector((state) => state.employee.limit);
  const page = useSelector((state) => state.employee.page);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [updateStatus, setUpdateStatus] = useState(status)

  const dispatch = useDispatch();

const handleLinkClick = () => {
  const employee = {
    _id : id,
  }
  dispatch(employeeAttendanceHistoryGetAll(employee))
}

  const handleDelete = () => {
    const employee = {
      _id: id,
    };
    dispatch(employeeDelete(employee._id));

  };
  function handleSwitchChange() {

    console.log();

    const employee = {
      _id: id,
      status: !status
    };
    const state = 'edit';
    console.log(employee._id)
    dispatch(employeeAddOrUpdate(employee, state,limit,page))
  }

  const startingDate = startDate ? new Date(startDate).toISOString().split('T')[0] : '';
  const endingDate = endDate ? new Date(endDate).toISOString().split('T')[0] : '';


  return (
    <>
      <Tr background={isExit?'red.500':''} fontStyle={{color:isExit?'white':'black'}}>
        {/* <Td
          minWidth={{ sm: "150px" }}

          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {employeeName}
              </Text>
            </Flex>
          </Flex>
        </Td> */}
        <Td
          >
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={titleColor}
                  fontWeight="bold"
                  minWidth="100%"
                >
                  {serialnumber}
                </Text>
              </Flex>
            </Flex>
          </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {employeeName}
              </Text>
            </Flex>
          </Flex>
        </Td>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {employeeId}
              </Text>
            </Flex>
          </Flex>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {passcode}
              </Text>
            </Flex>
          </Flex>
        </Td>

    
        {/* <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              <Image
                boxSize='70px'
                objectFit='cover'
                src={photo}
                borderRadius='50%'
                alt='Dan Abramov'
                _hover={{ transform: 'scale(2)' }}
                transition='transform 0.5s ease'
                cursor='pointer'
              />

            </Text>
            </Flex>
          </Flex>
        </Td> */}
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {phone}
              </Text>
            </Flex>
          </Flex>
        </Td>
        {/* <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {officialEmail}
              </Text>
            </Flex>
          </Flex>
        </Td> */}
        
       
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
>
  <Switch
    sx={{
      'span.chakra-switch__track:not([data-checked])': {
        backgroundColor: 'red'
      }
    }}
    colorScheme='green'
    id='isRequired'
    onChange={handleSwitchChange}
    isChecked={status}
    marginLeft="1.5rem" 
    marginBottom="1rem" // Adjust the margin to create space between Switch and inactiveDate
  />

  {status ? (
    <Text color="green.500" fontWeight="bold">
      {activeDate}
    </Text>
  ) : (
    <Text color="red.500" fontWeight="bold">
      {inactiveDate}
    </Text>
  )}
</Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {department}
              </Text>
            </Flex>
          </Flex>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {subdepartment}
              </Text>
            </Flex>
          </Flex>
        </Td>
        {/* <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {designation}
              </Text>
            </Flex>
          </Flex>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {startingDate}
              </Text>
            </Flex>
          </Flex>
        </Td>
        <Td
         pl="0px"
         borderColor={borderColor}
         borderBottom={isLast ? "none" : "1px solid"}
         whiteSpace="nowrap"
         overflow="hidden"
         textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {endingDate}
              </Text>
            </Flex>
          </Flex>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {companyName}
              </Text>
            </Flex>
          </Flex>
        </Td> */}
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
         <Link href={`#/admin/employee/attendance/history/list/${id}`}>
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          {/* <Button colorScheme='facebook' leftIcon={}>
          Logs
          </Button> */}
          <BiCalendar color="#3e83ce"  size="20px"/>
          </Flex>
          </Link>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <ButtonGroup>

          
           

            <Link href={`#/admin/employee-view/${id}`}>
              <IconButton
                colorScheme="blue"
                icon={<ViewIcon />}
                aria-label="Up"
              />
            </Link>

            <Link href={`#/admin/employee-update-form/${id}`}>
              <IconButton
                colorScheme="green"
                icon={<AiFillEdit />}
                aria-label="Edit"
              />
            </Link>

            <IconButton
              colorScheme="red"
              variant="outline"
              icon={<BsFillTrashFill />}
              aria-label="Delete"
              onClick={onOpen}
            />
          </ButtonGroup>
        </Td>


        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Employee
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={() => { handleDelete(), onClose() }} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>


      </Tr>
    </>
  );
};

export default EmployeeTableRow;
