import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useDispatch, useSelector } from "react-redux";

import { useToast } from '@chakra-ui/react';
import { useParams } from "react-router-dom";
import { GUARD_CLEAR_SNACKBAR } from "constants/types";
import {
  Table,
  Tbody,
  Td,
  Tr,
  TableContainer,
} from '@chakra-ui/react';
import { Skeleton, SkeletonText } from '@chakra-ui/react';
import { driverAddOrUpdate } from "actions/DriverAction"; // Make sure you have the correct action for driverAddOrUpdate
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
function DriverView() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name, setName] = useState("");
  const [photo, setPhoto] = useState("");
  const [number, setNumber] = useState("");
  const [alternateNumber, setAlternateNumber] = useState("");
  const [email, setEmail] = useState("");
 
  const [employeeAddress, setEmployeeAddress] = useState("");
 
  
  
  const [licenseDetails, setlicenseDetails] = useState("");
  
  const history = useHistory();
  const dispatch = useDispatch();
  const toast = useToast();

  const responseStatus = useSelector(state => state.driver.status); // Make sure you have the correct state slice for driver status
  const responseMessage = useSelector(state => state.driver.text); // Make sure you have the correct state slice for driver text
  const loading = useSelector(state => state.driver.loading); // Make sure you have the correct state slice for driver loading
  const driverDetail = useSelector(state => state.driver.driverDetail); // Make sure you have the correct state slice for driver detail
  const showSnackbar = useSelector(state => state.driver.showSnackbar); // Make sure you have the correct state slice for driver showSnackbar
  console.log("driverDetai")
  console.log(driverDetail)
  const params = useParams();
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        setName(driverDetail.Name);
        setPhoto(driverDetail.photo);
        setNumber(driverDetail.phone);
        setAlternateNumber(driverDetail.alternateNumber);

        setEmail(driverDetail.officialEmail);
        setEmployeeAddress(driverDetail.employeeAddress);
        setlicenseDetails(driverDetail.licenseDetails)

        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }

    dispatch({
      type: GUARD_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    });
  }, [loading]);

  useEffect(() => {
    const driverId = params.id;
    const driver = {
      _id: driverId
    };
    dispatch(driverAddOrUpdate(driver, 'view')); // Make sure you have the correct action for driverAddOrUpdate
  }, []);

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  
  console.log("driverDetail")
  console.log(driverDetail)

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Driver View
          </Text>
        </CardHeader>
        <CardBody>
          {loading ? <SkeletonText noOfLines={3} spacing="4" /> :
            <TableContainer>
              <Table variant='simple'>
                <Tbody>
                  <Tr>
                    <Td>Name</Td>
                    <Td>{name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Photo</Td>
                    <Td>
                      <Flex direction="column">
                        <Image
                          boxSize='100px'
                          objectFit='cover'
                          src={photo}
                          alt='Driver Image'
                        />
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Phone Number</Td>
                    <Td>{number}</Td>
                  </Tr>
                  <Tr>
                    <Td>Alternative Phone Number</Td>
                    <Td>{alternateNumber}</Td>
                  </Tr>
                  <Tr>
                    <Td>Email</Td>
                    <Td>{email}</Td>
                  </Tr>
                 
            
                  <Tr>
                  <Td>Employee Address</Td>
                    <Td>{employeeAddress}</Td>
                   
                  </Tr>

                  <Tr>
                  <Td>License Details</Td>
                    <Td><p><b>license Number :</b>{licenseDetails.licenseNumber}<br/>
                    <b>licenseExpiry :</b>{licenseDetails.licenseExpiry}<br/>
                    
                    <b>licensePhoto :</b> <Flex direction="column"><Image
                          boxSize='100px'
                          objectFit='cover'
                          src={licenseDetails.licensePhoto}
                          alt='Driver Image'
                        />
                        </Flex>
                        
                        </p></Td>
                   
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          }
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DriverView;
