import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  Select,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Spinner,
  Image,
  ButtonGroup,
  Link,
  IconButton
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader";
import { useDispatch, useSelector } from "react-redux";
import { driverAddOrUpdate } from "actions/DriverAction";
import CustomInput from "views/CustomValidation";
import axios from "axios";
import { server_url } from "constants/types";
import { useToast } from '@chakra-ui/react'
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { DRIVER_CLEAR_SNACKBAR } from "constants/types";
import { useHistory,useLocation } from 'react-router-dom';
import dataConstant from "constants/dataConstant";
import EmployeeDesignation from "views/Employee/EmployeeDesignation";
import { FaUpload } from "react-icons/fa";
function DriverForm() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [photo, setPhoto] = useState("");
  const [name, setName] = useState("");

  const [phone, setPhone] = useState("");
  const [alternateNumber, setAlternateNumber] = useState("");
  const [officialEmail, setOfficialEmail] = useState("");

  const [designationList, setDesignationList] = useState([])
  const [subdepartmentList, setSubdepartmentList] = useState([])
  const [departmentList, setDepartmentList] = useState('')

  const [licenseNumber, setLicenseNumber] = useState("");
  const [licenseExpiry, setLicenseExpiry] = useState("");
  const [licensePhoto, setLicensePhoto] = useState("");
  const [employeeaddress, setEmployeeaddress] = useState("");
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [employeeType, setEmployeeType] = useState('');
  const [companyName, setCompanyName] = useState("");
  const [isErrorStartDate, setIsErrorStartDate] = useState(false);
  const [isErrorEndDate, setIsErrorEndDate] = useState(false);
  const [isErrorEmployeeType, setIsErrorEmployeeType] = useState(false);
  const [isErrorCompanyName, setIsErrorCompanyName] = useState(false);
  const [isErrorDepartment, setIsErrorDepartment] = useState(false);
  const [isErrorDesignation, setIsErrorDesignation] = useState(false);
  const [isErrorSubdepartment, setIsErrorSubdepartment] = useState(false);

  const [departmentId, setDepartmentId] = useState(searchParams.get('departmentId'));
  const [designation, setDesignation] = useState(searchParams.get('designationId'));
  const [subdepartment, setSubdepartment] = useState(searchParams.get('subdepartmentId'));

  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorBloodGroup, setIsErrorBloodGroup] = useState(false);
  const [bloodGroup, setBloodGroup] = useState(false);
  const [isErrorPhone, setIsErrorPhone] = useState(false);
  const [isErrorPhoto, setIsErrorPhoto] = useState(false);
  const [isErrorAlternateNumber, setIsErrorAlternateNumber] = useState(false);
  const [isErrorOfficialEmail, setIsErrorOfficialEmail] = useState(false);
  const [isErrorEmployeeaddress, setIsErrorEmployeeaddress] = useState(false);


  const [isErrorLicenseNumber, setIsErrorLicenseNumber] = useState(false);
  const [isErrorLicenseExpiry, setIsErrorLicenseExpiry] = useState(false);
  const [isErrorLicensePhoto, setIsErrorLicensePhoto] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const history = useHistory();
  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.driver.status);
  const responseMessage = useSelector(state => state.driver.text);
  const loading = useSelector(state => state.driver.loading);

  const showSnackbar = useSelector(state => state.driver.showSnackbar);

  const dispatch = useDispatch();
  const toast = useToast();

  const params = useParams();
  const IdofDriver = params.id;


  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsErrorName(name === '');
    setIsErrorPhone(phone === '');


    if (
      name !== "" && phone !== ""
    ) {
      const newDriver = {

        file1: photo,
        Name: name,
        phone: phone,
        alternateNumber: alternateNumber,
        officialEmail: officialEmail,
        bloodGroup: bloodGroup,
        licenseDetails: {

          licenseNumber: licenseNumber,
          licenseExpiry: licenseExpiry,
        },

        file2: licensePhoto,
        employeeaddress: employeeaddress,
        department : departmentId,
        subdepartment :  subdepartment,
        designation : designation,
        startDate: startDate,
        endDate: endDate,
        companyName : companyName,
        employeeType: employeeType,

      };
      console.log("newDriver1")
      console.log(newDriver)
      dispatch(driverAddOrUpdate(newDriver, 'add'));
      // history.push("/admin/driver-table")
    }

  };

  const departmentListforDropdown = () => {
    axios
      .get(`${server_url}/department`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        setDepartmentList(responce.data.data)
      }).catch(() => console.log("error"))
  }

  const subdepartmentListforDropdown = (depId) => {
    if (depId) {
      axios
        .get(`${server_url}/plant/subdepartment-list/${depId}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          setSubdepartmentList(responce.data.data)
        })
    }
    else {
      setSubdepartmentList([])
    }

  }

  const designationListforDropdown = (subdepId) => {
    if (subdepId) {
      axios
        .get(`${server_url}/plant/designation-list/${subdepId}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          setDesignationList(responce.data.data)
        })
    }
    else {
      setDesignationList([])
    }

  }


  useEffect(() => {
    departmentListforDropdown()


  }, [])

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
          setName("");
          setIsErrorName(false);
          setPhone("");
          setIsErrorPhone(false);
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: DRIVER_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  const textRegex = /^[A-Za-z0-9,\s]+$/;

  const phoneRegex = /^\d{10}$/;
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/;

  const licencenoRegex = /^[A-Za-z0-9]{6,12}$/

  return (
    <>
    <EmployeeDesignation
    selectedDepartment={departmentId}
    selectedSubdepartment={subdepartment}
    selectedDesignation={designation}
    ></EmployeeDesignation>
    <Flex direction="column" pt={{ base: "120px", md: "25px" }}>
      <form onSubmit={handleSubmit}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            
            <Flex minWidth='max-content' alignItems='center' gap='2'>
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Driver Form
            </Text>
              <Spacer />
              <ButtonGroup gap='2'>
              <Link href={`#/admin/driver-upload-excelsheet?departmentId=${departmentId}&subdepartmentId=${subdepartment}&designationId=${designation}`}>
              <IconButton
                colorScheme="blue"
                aria-label="Upload file"
                icon={<FaUpload />}
              />
            </Link>
              </ButtonGroup>
            </Flex>
          </CardHeader>
          <CardBody>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing="8">
              <FormControl isInvalid={isErrorName || (submitted && name === "")}>
                <CustomInput
                required ={true}
                  type="text"
                  label="Name"
                  value={name}
                  onChange={(value) => {
                    setIsErrorName(value === "" || !textRegex.test(value));
                    setName(value);
                  }}
                  isInvalid={isErrorName || (submitted && name === "")}
                  regex={textRegex}
                  submitted={submitted}
                />
              </FormControl>


              <FormControl isInvalid={isErrorPhone || (submitted && phone === "")}>
                <CustomInput
                required={true}
                  type="tel"
                  label="Phone"
                  value={phone}
                  onChange={(value) => {
                    setIsErrorPhone(value === "" || !phoneRegex.test(value));
                    setPhone(value);
                  }}
                  isInvalid={isErrorPhone || (submitted && phone === "")}
                  regex={phoneRegex}
                  submitted={submitted}
                />
              </FormControl>
              <FormControl
                isInvalid={isErrorAlternateNumber}
              >
                <CustomInput
                  type="tel"
                  isEmpty={true}
                  label="Alternate Number"
                  value={alternateNumber}
                  onChange={(value) => {
                    // setIsErrorAlternateNumber(value === "" || !phoneRegex.test(value));
                    setAlternateNumber(value);
                  }}
                  isInvalid={isErrorAlternateNumber}
                  regex={phoneRegex}
                  // submitted={submitted}
                />
              </FormControl>
              <FormControl
                isInvalid={isErrorOfficialEmail}
              >
                <CustomInput
                  type="email"
                  label="Official Email"
                  value={officialEmail}
                  onChange={(value) => {
                    // setIsErrorOfficialEmail(value === "" || !emailRegex.test(value));
                    setOfficialEmail(value);
                  }}
                  isInvalid={isErrorOfficialEmail}
                  regex={emailRegex}
                  
                />
              </FormControl>

              <FormControl isInvalid={isErrorBloodGroup || (submitted && bloodGroup === "")}>
                <FormLabel>Blood Group</FormLabel>
                <Select
                  placeholder="Blood Group"
                  onChange={(e) => {
                    setIsErrorBloodGroup(e.target.value === '');
                    setBloodGroup(e.target.value);

                  }}
                  value={bloodGroup}
                >
                  {dataConstant.bloodGroup.map((item, index) => (
                    <option key={item._id} value={item._id}>{item.name}</option>
                  ))}
                </Select>
                {isErrorBloodGroup && submitted && (
                  <FormErrorMessage>Blood Group is required.</FormErrorMessage>
                )}
              </FormControl>

               
            </SimpleGrid>
            
            <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            

                <FormControl isInvalid={isErrorStartDate}>
                  <FormLabel>Start Date</FormLabel>
                  <Input
                    type='date'
                    onChange={(e) => {

                      // setIsErrorStartDate(e.target.value === '')
                      setStartDate(e.target.value)
                    }}
                  />
                  {!isErrorStartDate ? (
                    <></>
                  ) : (
                    <FormErrorMessage>Start Date is required.</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl isInvalid={isErrorEndDate}>
                  <FormLabel>End Date</FormLabel>
                  <Input
                    type='date'
                    onChange={(e) => {

                      // setIsErrorEndDate(e.target.value === '')
                      setEndDate(e.target.value)
                    }}
                  />
                  {!isErrorEndDate ? (
                    <></>
                  ) : (
                    <FormErrorMessage>End Date is required.</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl isInvalid={isErrorCompanyName}>
                  <FormLabel>Service Provider Company Name</FormLabel>
                  <Input
                    type='text'
                    // value={companyName}
                    // onChange={(e) => {
                    //   setIsErrorCompanyName(e.target.value === '' || !textRegex.test(value));
                    //   setCompanyName(e.target.value);
                    // }}
                      onChange={(e) => {

                        // setIsErrorCompanyName(e.target.value === '')
                        setCompanyName(e.target.value)
                    }}
                    regex={textRegex}

                  />
                  {!isErrorCompanyName ? (
                    <></>
                  ) : (
                    <FormErrorMessage>Company Name is required.</FormErrorMessage>
                  )}
                </FormControl>
            {/* </Flex> */}

          </SimpleGrid>
            <Center>
              {loading ? (
                <Spinner mt="24px" size="lg" />
              ) : (
                <Button colorScheme="blue" mt="24px" type="submit">
                  Submit
                </Button>
              )}
            </Center>
          </CardBody>
        </Card>
      </form>
    </Flex>
    </>
  );
}

export default DriverForm;
