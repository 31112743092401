const {
    EMERGENCY_CONTACT_LOADING,
    EMERGENCY_CONTACT_GET_ALL_SUCCESS,
    EMERGENCY_CONTACT_ERROR,
    EMERGENCY_CONTACT_CLEAR_SNACKBAR,
  } = require('../constants/types');
  
  const initialState = {
    response: {},
    emergencyContactList: [],
    emergencyContactDetail: {},
    loading: false,
    status: 'success',
    text: '',
    showSnackbar: false,
  };
  
  const emergencyContactReducer = (state = initialState, action) => {
    switch (action.type) {
      case EMERGENCY_CONTACT_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false,
        };
      case EMERGENCY_CONTACT_LOADING:
        return {
          ...state,
          loading: true,
        };
      case EMERGENCY_CONTACT_ERROR:
        return {
          ...state,
          emergencyContactList: [],
          response: {},
          emergencyContactDetail: {},
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true,
        };
      case EMERGENCY_CONTACT_GET_ALL_SUCCESS:
        return {
          ...state,
          emergencyContactList: action.payload.emergencyContactList,
          response: action.payload.response,
          emergencyContactDetail: action.payload.emergencyContactDetail,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true,
        };
      default:
        return state || {};
    }
  };
  
  export default emergencyContactReducer;