/*eslint-disable*/
import { HamburgerIcon } from "@chakra-ui/icons";
// chakra imports
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Spacer,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import IconBox from "components/Icons/IconBox";
import {
  renderThumbDark,
  renderThumbLight,
  renderTrack,
  renderTrackRTL,
  renderView,
  renderViewRTL,
} from "components/Scrollbar/Scrollbar";
import { HSeparator } from "components/Separator/Separator";
import { SidebarHelp } from "components/Sidebar/SidebarHelp";
import React, { useState, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ADMIN_CLEAR_SNACKBAR } from "constants/types";
import { loginPermissionDetail } from "../../actions/authActions";
import { AUTH_CLEAR_SNACKBAR } from "constants/types";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import dataConstant from "constants/dataConstant";
// FUNCTIONS

function Sidebar(props) {
  const dispatch = useDispatch();
  const authDetail = useSelector((state) => state.auth.authDetail);
  const loading = useSelector((state) => state.auth.loading);
  const status = useSelector((state) => state.auth.status);
  console.log("authDetail");
  console.log(authDetail);
  // to check for active links and opened collapses
  let location = useLocation();
  // this is for the rest of the collapses
  const [state, setState] = React.useState({});
  const mainPanel = React.useRef();
  let variantChange = "0.2s linear";
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };
  const { colorMode } = useColorMode;
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const { sidebarVariant } = props;
  const createLinks = (routes) => {
    // Chakra Color Mode
    let activeBg = useColorModeValue("white", "navy.700");
    let inactiveBg = useColorModeValue("white", "navy.700");
    let activeColor = useColorModeValue("gray.700", "white");
    let inactiveColor = useColorModeValue("gray.400", "gray.400");
    let sidebarActiveShadow = "0px 7px 11px rgba(0, 0, 0, 0.04)";

    // Check if the authenticated user's module matches the route's module
    const hasPermission = (route) => {
      if (!authDetail || !authDetail.role) {
        return false;
      }
      const { role } = authDetail;
      if (!role.allPermissions) {
        return role.permissions.some(
          (permission) =>
            permission.moduleName === route.module &&
            permission.permissionName == "VIEW"
        );
      }
      return true;
    };

    const plantAdminCondition = (route) => {
      console.log(authDetail?.role?.name);
      console.log("-?????? Checking Role name");
      console.log("-?????? Constant Name");
      console.log(dataConstant.GROUPROLE);
      if (authDetail?.role?.name != 'Plant Admin' && authDetail?.role?.name != dataConstant.GROUPROLE) {
        return true;
      }

      if (route.plantAdmin) {
        return true;
      }
      else
      {
        return false;
      }
      
    };

    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (key === 0 && prop.module === 'DASHBOARD') {
        // Handle route at index 0 separately
        return (
          <NavLink to={prop.layout + prop.path} key={key}>
            {activeRoute(prop.layout + prop.path) === "active" ? (
              <Button
                boxSize="initial"
                justifyContent="flex-start"
                alignItems="center"
                boxShadow={sidebarActiveShadow}
                bg={activeBg}
                transition={variantChange}
                mb={{
                  xl: "6px",
                }}
                mx={{
                  xl: "auto",
                }}
                ps={{
                  sm: "10px",
                  xl: "16px",
                }}
                py="12px"
                borderRadius="15px"
                _hover="none"
                w="100%"
                _active={{
                  bg: "inherit",
                  transform: "none",
                  borderColor: "transparent",
                }}
                _focus={{
                  boxShadow: "0px 7px 11px rgba(0, 0, 0, 0.04)",
                }}
              >
                <Flex>
                  {typeof prop.icon === "string" ? (
                    <Icon>{prop.icon}</Icon>
                  ) : (
                    <IconBox
                      bg="blue.500"
                      color="white"
                      h="30px"
                      w="30px"
                      me="12px"
                      transition={variantChange}
                    >
                      {prop.icon}
                    </IconBox>
                  )}
                  <Text color={activeColor} my="auto" fontSize="sm">
                    {document.documentElement.dir === "rtl"
                      ? prop.rtlName
                      : prop.name}
                  </Text>
                </Flex>
              </Button>
            ) : (
              <Button
                boxSize="initial"
                justifyContent="flex-start"
                alignItems="center"
                bg="transparent"
                mb={{
                  xl: "6px",
                }}
                mx={{
                  xl: "auto",
                }}
                py="12px"
                ps={{
                  sm: "10px",
                  xl: "16px",
                }}
                borderRadius="15px"
                _hover="none"
                w="100%"
                _active={{
                  bg: "inherit",
                  transform: "none",
                  borderColor: "transparent",
                }}
                _focus={{
                  boxShadow: "none",
                }}
              >
                <Flex>
                  {typeof prop.icon === "string" ? (
                    <Icon>{prop.icon}</Icon>
                  ) : (
                    <IconBox
                      bg={inactiveBg}
                      color="blue.500"
                      h="30px"
                      w="30px"
                      me="12px"
                      transition={variantChange}
                    >
                      {prop.icon}
                    </IconBox>
                  )}
                  <Text color={inactiveColor} my="auto" fontSize="sm">
                    {document.documentElement.dir === "rtl"
                      ? prop.rtlName
                      : prop.name}
                  </Text>
                </Flex>
              </Button>
            )}
          </NavLink>
        );
      }
      if (prop.category && hasPermission(prop) && plantAdminCondition(prop)) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <>
            <Text
              color={activeColor}
              fontWeight="bold"
              mb={{
                xl: "6px",
              }}
              mx="auto"
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              py="12px"
            >
              {document.documentElement.dir === "rtl"
                ? prop.rtlName
                : prop.name}
            </Text>
            {createLinks(prop.views)}
          </>
        );
      }
      if (prop.sidebar && hasPermission(prop) && plantAdminCondition(prop)) {
        // Check permission before rendering
        return (
          <NavLink to={prop.layout + prop.path} key={key}>
            {activeRoute(prop.layout + prop.path) === "active" ? (
              <Button
                boxSize="initial"
                justifyContent="flex-start"
                alignItems="center"
                boxShadow={sidebarActiveShadow}
                bg={activeBg}
                transition={variantChange}
                mb={{
                  xl: "6px",
                }}
                mx={{
                  xl: "auto",
                }}
                ps={{
                  sm: "10px",
                  xl: "16px",
                }}
                py="12px"
                borderRadius="15px"
                _hover="none"
                w="100%"
                _active={{
                  bg: "inherit",
                  transform: "none",
                  borderColor: "transparent",
                }}
                _focus={{
                  boxShadow: "0px 7px 11px rgba(0, 0, 0, 0.04)",
                }}
              >
                <Flex>
                  {typeof prop.icon === "string" ? (
                    <Icon>{prop.icon}</Icon>
                  ) : (
                    <IconBox
                      bg="blue.500"
                      color="white"
                      h="30px"
                      w="30px"
                      me="12px"
                      transition={variantChange}
                    >
                      {prop.icon}
                    </IconBox>
                  )}
                  <Text color={activeColor} my="auto" fontSize="sm">
                    {document.documentElement.dir === "rtl"
                      ? prop.rtlName
                      : prop.name}
                  </Text>
                </Flex>
              </Button>
            ) : (
              <Button
                boxSize="initial"
                justifyContent="flex-start"
                alignItems="center"
                bg="transparent"
                mb={{
                  xl: "6px",
                }}
                mx={{
                  xl: "auto",
                }}
                py="12px"
                ps={{
                  sm: "10px",
                  xl: "16px",
                }}
                borderRadius="15px"
                _hover="none"
                w="100%"
                _active={{
                  bg: "inherit",
                  transform: "none",
                  borderColor: "transparent",
                }}
                _focus={{
                  boxShadow: "none",
                }}
              >
                <Flex>
                  {typeof prop.icon === "string" ? (
                    <Icon>{prop.icon}</Icon>
                  ) : (
                    <IconBox
                      bg={inactiveBg}
                      color="blue.500"
                      h="30px"
                      w="30px"
                      me="12px"
                      transition={variantChange}
                    >
                      {prop.icon}
                    </IconBox>
                  )}
                  <Text color={inactiveColor} my="auto" fontSize="sm">
                    {document.documentElement.dir === "rtl"
                      ? prop.rtlName
                      : prop.name}
                  </Text>
                </Flex>
              </Button>
            )}
          </NavLink>
        );
      }
      return null;
    });
  };

  // const createLinks = (routes) => {
  //   // Chakra Color Mode
  //   let activeBg = useColorModeValue("white", "navy.700");
  //   let inactiveBg = useColorModeValue("white", "navy.700");
  //   let activeColor = useColorModeValue("gray.700", "white");
  //   let inactiveColor = useColorModeValue("gray.400", "gray.400");
  //   let sidebarActiveShadow = "0px 7px 11px rgba(0, 0, 0, 0.04)";
  //   return routes.map((prop, key) => {
  //     if (prop.redirect) {
  //       return null;
  //     }
  //     if (prop.category) {
  //       var st = {};
  //       st[prop["state"]] = !state[prop.state];
  //       return (
  //         <>
  //           <Text
  //             color={activeColor}
  //             fontWeight="bold"
  //             mb={{
  //               xl: "6px",
  //             }}
  //             mx="auto"
  //             ps={{
  //               sm: "10px",
  //               xl: "16px",
  //             }}
  //             py="12px"
  //           >
  //             {document.documentElement.dir === "rtl"
  //               ? prop.rtlName
  //               : prop.name}
  //           </Text>
  //           {createLinks(prop.views)}
  //         </>
  //       );
  //     }
  //     if(prop.sidebar)
  //     {

  //       if (Object.keys(authDetail).length != 0) {
  //         if(authDetail.role.allPermissions === false)
  //         {
  //           authDetail.role.permissions.map((permission) => {
  //             const { permissionName, moduleName } = permission;

  //             if(moduleName == prop.module)
  //             {
  //               return (
  //                 <NavLink to={prop.layout + prop.path} key={key}>
  //                   {activeRoute(prop.layout + prop.path) === "active" ? (
  //                     <Button
  //                       boxSize="initial"
  //                       justifyContent="flex-start"
  //                       alignItems="center"
  //                       boxShadow={sidebarActiveShadow}
  //                       bg={activeBg}
  //                       transition={variantChange}
  //                       mb={{
  //                         xl: "6px",
  //                       }}
  //                       mx={{
  //                         xl: "auto",
  //                       }}
  //                       ps={{
  //                         sm: "10px",
  //                         xl: "16px",
  //                       }}
  //                       py="12px"
  //                       borderRadius="15px"
  //                       _hover="none"
  //                       w="100%"
  //                       _active={{
  //                         bg: "inherit",
  //                         transform: "none",
  //                         borderColor: "transparent",
  //                       }}
  //                       _focus={{
  //                         boxShadow: "0px 7px 11px rgba(0, 0, 0, 0.04)",
  //                       }}
  //                     >
  //                       <Flex>
  //                         {typeof prop.icon === "string" ? (
  //                           <Icon>{prop.icon}</Icon>
  //                         ) : (
  //                           <IconBox
  //                             bg="blue.500"
  //                             color="white"
  //                             h="30px"
  //                             w="30px"
  //                             me="12px"
  //                             transition={variantChange}
  //                           >
  //                             {prop.icon}
  //                           </IconBox>
  //                         )}
  //                         <Text color={activeColor} my="auto" fontSize="sm">
  //                           {document.documentElement.dir === "rtl"
  //                             ? prop.rtlName
  //                             : prop.name}
  //                         </Text>
  //                       </Flex>
  //                     </Button>
  //                   ) : (
  //                     <Button
  //                       boxSize="initial"
  //                       justifyContent="flex-start"
  //                       alignItems="center"
  //                       bg="transparent"
  //                       mb={{
  //                         xl: "6px",
  //                       }}
  //                       mx={{
  //                         xl: "auto",
  //                       }}
  //                       py="12px"
  //                       ps={{
  //                         sm: "10px",
  //                         xl: "16px",
  //                       }}
  //                       borderRadius="15px"
  //                       _hover="none"
  //                       w="100%"
  //                       _active={{
  //                         bg: "inherit",
  //                         transform: "none",
  //                         borderColor: "transparent",
  //                       }}
  //                       _focus={{
  //                         boxShadow: "none",
  //                       }}
  //                     >
  //                       <Flex>
  //                         {typeof prop.icon === "string" ? (
  //                           <Icon>{prop.icon}</Icon>
  //                         ) : (
  //                           <IconBox
  //                             bg={inactiveBg}
  //                             color="blue.500"
  //                             h="30px"
  //                             w="30px"
  //                             me="12px"
  //                             transition={variantChange}
  //                           >
  //                             {prop.icon}
  //                           </IconBox>
  //                         )}
  //                         <Text color={inactiveColor} my="auto" fontSize="sm">
  //                           {document.documentElement.dir === "rtl"
  //                             ? prop.rtlName
  //                             : prop.name}
  //                         </Text>
  //                       </Flex>
  //                     </Button>
  //                   )}
  //                 </NavLink>
  //               );
  //             }
  //             else{
  //               return null;
  //             }
  //           })
  //       }
  //         else{
  //           return (
  //             <NavLink to={prop.layout + prop.path} key={key}>
  //               {activeRoute(prop.layout + prop.path) === "active" ? (
  //                 <Button
  //                   boxSize="initial"
  //                   justifyContent="flex-start"
  //                   alignItems="center"
  //                   boxShadow={sidebarActiveShadow}
  //                   bg={activeBg}
  //                   transition={variantChange}
  //                   mb={{
  //                     xl: "6px",
  //                   }}
  //                   mx={{
  //                     xl: "auto",
  //                   }}
  //                   ps={{
  //                     sm: "10px",
  //                     xl: "16px",
  //                   }}
  //                   py="12px"
  //                   borderRadius="15px"
  //                   _hover="none"
  //                   w="100%"
  //                   _active={{
  //                     bg: "inherit",
  //                     transform: "none",
  //                     borderColor: "transparent",
  //                   }}
  //                   _focus={{
  //                     boxShadow: "0px 7px 11px rgba(0, 0, 0, 0.04)",
  //                   }}
  //                 >
  //                   <Flex>
  //                     {typeof prop.icon === "string" ? (
  //                       <Icon>{prop.icon}</Icon>
  //                     ) : (
  //                       <IconBox
  //                         bg="blue.500"
  //                         color="white"
  //                         h="30px"
  //                         w="30px"
  //                         me="12px"
  //                         transition={variantChange}
  //                       >
  //                         {prop.icon}
  //                       </IconBox>
  //                     )}
  //                     <Text color={activeColor} my="auto" fontSize="sm">
  //                       {document.documentElement.dir === "rtl"
  //                         ? prop.rtlName
  //                         : prop.name}
  //                     </Text>
  //                   </Flex>
  //                 </Button>
  //               ) : (
  //                 <Button
  //                   boxSize="initial"
  //                   justifyContent="flex-start"
  //                   alignItems="center"
  //                   bg="transparent"
  //                   mb={{
  //                     xl: "6px",
  //                   }}
  //                   mx={{
  //                     xl: "auto",
  //                   }}
  //                   py="12px"
  //                   ps={{
  //                     sm: "10px",
  //                     xl: "16px",
  //                   }}
  //                   borderRadius="15px"
  //                   _hover="none"
  //                   w="100%"
  //                   _active={{
  //                     bg: "inherit",
  //                     transform: "none",
  //                     borderColor: "transparent",
  //                   }}
  //                   _focus={{
  //                     boxShadow: "none",
  //                   }}
  //                 >
  //                   <Flex>
  //                     {typeof prop.icon === "string" ? (
  //                       <Icon>{prop.icon}</Icon>
  //                     ) : (
  //                       <IconBox
  //                         bg={inactiveBg}
  //                         color="blue.500"
  //                         h="30px"
  //                         w="30px"
  //                         me="12px"
  //                         transition={variantChange}
  //                       >
  //                         {prop.icon}
  //                       </IconBox>
  //                     )}
  //                     <Text color={inactiveColor} my="auto" fontSize="sm">
  //                       {document.documentElement.dir === "rtl"
  //                         ? prop.rtlName
  //                         : prop.name}
  //                     </Text>
  //                   </Flex>
  //                 </Button>
  //               )}
  //             </NavLink>
  //           );
  //         }
  //       }
  //       else
  //       {
  //         return null;
  //       }
  //     }
  //   });
  // };
  const { logo, routes } = props;
  const history = useHistory();

  var links = <>{createLinks(routes)}</>;
  //  BRAND
  //  Chakra Color Mode
  let sidebarBg = useColorModeValue("white", "navy.800");
  let sidebarRadius = "20px";
  let sidebarMargins = "0px";
  var brand = (
    <Box pt={"15px"}>
      <Flex
        // justifyContent="center"
        alignItems="center"
        // mb="24px"
      >
        <svg
          width="80"
          height="80"
          viewBox="0 0 1080 1080"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* <rect width="1080" height="1080" fill="white"/> */}
          <path fill="none" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M200.647 339.435C184.099 521.391 295.14 752.068 459.83 840C511.501 812.403 557.899 770.74 596.73 720.95H550.904C524.176 751.496 493.657 778.528 459.83 799.274V285.985C492.396 311.197 527.546 330.847 564.812 345.19V307.041C527.139 290.968 491.92 268.748 459.83 240C386.986 305.256 298.145 336.823 200.647 339.435Z"
            fill="#ED1C24"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M544.498 401.989H501.132C495.253 401.989 490.408 406.821 490.408 412.72V456.08C490.408 461.973 495.253 466.804 501.132 466.804H544.498C550.398 466.804 555.243 461.973 555.243 456.08V412.72C555.243 406.821 550.391 401.989 544.498 401.989Z"
            fill="#9E1F63"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M672.948 607.916H618.837C611.49 607.916 605.453 613.947 605.453 621.301V675.426C605.453 682.787 611.49 688.818 618.837 688.818H672.948C680.323 688.818 686.333 682.787 686.333 675.426V621.301C686.333 613.947 680.316 607.916 672.948 607.916Z"
            fill="#662D91"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M732.862 346.775H716.679C698.952 346.775 691.391 350.966 688.249 358.63C685.106 366.28 686.388 377.418 686.388 391.334V405.877C686.388 435.83 696.037 435.176 723.289 435.176H745.144C748.121 435.176 750.837 436.402 752.815 438.374C754.759 440.324 755.986 443.033 755.986 446.01V489.742C755.986 492.719 754.759 495.428 752.815 497.385C750.837 499.35 748.121 500.563 745.144 500.563H701.42C698.422 500.563 695.734 499.35 693.783 497.385C691.805 495.428 690.599 492.719 690.599 489.742V469.968C690.599 441.262 682.576 434.852 657.433 434.852H613.778C609.801 434.852 606.21 433.218 603.598 430.606C600.986 427.994 599.359 424.403 599.359 420.44V362.214C599.359 358.258 600.986 354.646 603.598 352.041C606.21 349.429 609.801 347.809 613.778 347.809H642.898C670.391 347.809 688.097 353.599 688.097 318V302.003C688.097 299.556 689.089 297.33 690.702 295.717C692.315 294.118 694.541 293.119 696.96 293.119H732.876C735.316 293.119 737.549 294.118 739.141 295.717C740.754 297.33 741.767 299.563 741.767 302.003V337.905C741.767 340.352 740.754 342.557 739.141 344.177C737.549 345.79 735.309 346.775 732.876 346.775H732.862Z"
            fill="#7AC143"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M727.942 589.362H749.528C773.837 589.362 784.189 592.188 784.189 564.674V550.207C784.189 545.941 787.684 542.447 791.936 542.447H823.317C825.474 542.447 827.397 543.322 828.803 544.735C830.223 546.141 831.07 548.078 831.07 550.207V581.574C831.07 583.711 830.216 585.648 828.803 587.06C827.397 588.473 825.474 589.335 823.317 589.335H808.092C781.846 589.335 781.819 596.682 781.819 621.653V643.246C781.819 646.182 780.619 648.87 778.676 650.8C776.753 652.73 774.058 653.943 771.129 653.943H727.949C725.006 653.943 722.304 652.737 720.381 650.8C718.444 648.87 717.252 646.182 717.252 643.246V600.052C717.252 597.116 718.444 594.442 720.381 592.505C722.325 590.576 725.006 589.362 727.949 589.362H727.942Z"
            fill="#F15A29"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M712.979 528.462H680.303C675.836 528.462 672.197 532.115 672.197 536.561V569.244C672.197 573.696 675.836 577.342 680.303 577.342H712.979C717.424 577.342 721.063 573.696 721.063 569.244V536.561C721.063 532.122 717.431 528.462 712.979 528.462Z"
            fill="#FFF200"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M643.16 267.066H611.076C606.7 267.066 603.129 270.622 603.129 274.992V307.089C603.129 311.452 606.7 315.029 611.076 315.029H643.16C647.522 315.029 651.099 311.452 651.099 307.089V274.992C651.099 270.622 647.522 267.066 643.16 267.066Z"
            fill="#003E56"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M848.445 451.469H808.745C803.342 451.469 798.924 455.88 798.924 461.29V500.983C798.924 506.379 803.342 510.804 808.745 510.804H848.445C853.855 510.804 858.259 506.379 858.259 500.983V461.29C858.259 455.873 853.855 451.469 848.445 451.469Z"
            fill="#F6851F"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M813.269 381.74H784.542C780.655 381.74 777.429 384.924 777.429 388.832V417.566C777.429 421.481 780.655 424.672 784.542 424.672H813.269C817.204 424.672 820.382 421.481 820.382 417.566V388.832C820.382 384.924 817.204 381.74 813.269 381.74Z"
            fill="#8E9738"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M873.871 323.486H845.151C841.229 323.486 838.038 326.684 838.038 330.592V359.326C838.038 363.234 841.229 366.432 845.151 366.432H873.871C877.779 366.432 880.984 363.234 880.984 359.326V330.592C880.984 326.684 877.779 323.486 873.871 323.486Z"
            fill="#9B8579"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M525.125 688.928V661.655C525.125 657.933 528.143 654.901 531.865 654.901H559.131C562.86 654.901 565.885 657.94 565.885 661.655V688.928C565.885 692.643 562.86 695.689 559.131 695.689H531.865C531.521 695.689 531.162 695.655 530.831 695.607C527.585 695.11 525.125 692.312 525.125 688.928Z"
            fill="#079747"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M629.865 470.133H571.088C567.083 470.133 563.458 471.773 560.811 474.406C558.172 477.039 556.545 480.678 556.545 484.683V514.064C556.545 547.788 557.937 560.649 518.355 560.649H497.782C494.846 560.649 492.144 561.855 490.207 563.806C488.264 565.743 487.058 568.417 487.058 571.374V614.726C487.058 617.683 488.264 620.364 490.207 622.308C492.144 624.251 494.846 625.464 497.782 625.464H541.148C544.091 625.464 546.765 624.258 548.722 622.308C550.673 620.364 551.858 617.683 551.858 614.726V593.836C551.858 552.565 566.718 557.996 600.483 557.996H629.865C633.855 557.996 637.488 556.362 640.127 553.729C642.753 551.083 644.407 547.451 644.407 543.453V484.683C644.407 480.685 642.753 477.046 640.127 474.406C637.488 471.773 633.862 470.133 629.865 470.133Z"
            fill="#385BA9"
          />
        </svg>{" "}
        <Flex>
          <div style={{ marginBottom: "10px" }}>
            <Text fontSize="xl" fontWeight="bold" mt="10px" pl="20px" color="rgb(237, 28, 36)">
              KAVACH
            </Text>
            <Text fontSize="sm" fontWeight="bold" color="#4169E1">
              Science of Security
            </Text>
          </div>
        </Flex>
      </Flex>
      <HSeparator my="10px" />
    </Box>
  );
  const currentURL = window.location.href;
  useEffect(() => {
    if (status === "error") {
      localStorage.clear();
      history.push("/auth/signin");
    }
    dispatch({
      type: AUTH_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      },
    });
  }, [currentURL]);

  useEffect(() => {
    dispatch(loginPermissionDetail());
  }, [currentURL]);

  // SIDEBAR
  return (
    <Box ref={mainPanel}>
      <Box display={{ sm: "none", xl: "block" }} position="fixed">
        <Box
          bg={sidebarBg}
          transition={variantChange}
          w="260px"
          maxW="260px"
          ms={{
            sm: "16px",
          }}
          my={{
            sm: "16px",
          }}
          h="calc(100vh - 32px)"
          ps="20px"
          pe="20px"
          m={sidebarMargins}
          filter="drop-shadow(0px 5px 14px rgba(0, 0, 0, 0.05))"
          borderRadius={sidebarRadius}
        >
          <Scrollbars
            autoHide
            renderTrackVertical={
              document.documentElement.dir === "rtl"
                ? renderTrackRTL
                : renderTrack
            }
            renderThumbVertical={useColorModeValue(
              renderThumbLight,
              renderThumbDark
            )}
            renderView={
              document.documentElement.dir === "rtl"
                ? renderViewRTL
                : renderView
            }
          >
            <Box>{brand}</Box>
            <Stack direction="column" mb="40px">
              <Box>{links}</Box>
            </Stack>
            <SidebarHelp sidebarVariant={sidebarVariant} />
          </Scrollbars>
        </Box>
      </Box>
    </Box>
  );
}

// FOR RESPOSNIVE SIDEBAR

export function SidebarResponsive(props) {
  const dispatch = useDispatch();
  const authDetail = useSelector((state) => state.auth.authDetail);
  const loading = useSelector((state) => state.auth.loading);
  const status = useSelector((state) => state.auth.status);
  console.log("authDetail kkkkkk");
  console.log(authDetail);
  // to check for active links and opened collapses
  let location = useLocation();
  const { logo, routes, colorMode, hamburgerColor, ...rest } = props;
  let variantChange = "0.2s linear";

  // this is for the rest of the collapses
  const [state, setState] = React.useState({});
  const mainPanel = React.useRef();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };
  // Chakra Color Mode
  let activeBg = useColorModeValue("white", "navy.700");
  let inactiveBg = useColorModeValue("white", "navy.700");
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue("gray.400", "white");
  let sidebarActiveShadow = useColorModeValue(
    "0px 7px 11px rgba(0, 0, 0, 0.04)",
    "none"
  );
  let sidebarBackgroundColor = useColorModeValue("white", "navy.800");
  // const authDetail = useSelector((state) => state.auth.authDetail);

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    let activeBg = useColorModeValue("white", "navy.700");
    let inactiveBg = useColorModeValue("white", "navy.700");
    let activeColor = useColorModeValue("gray.700", "white");
    let inactiveColor = useColorModeValue("gray.400", "gray.400");
    let sidebarActiveShadow = "0px 7px 11px rgba(0, 0, 0, 0.04)";

    // Check if the authenticated user's module matches the route's module
    const hasPermission = (route) => {
      if (!authDetail || !authDetail.role) {
        return false;
      }
      const { role } = authDetail;
      if (!role.allPermissions) {
        return role.permissions.some(
          (permission) =>
            permission.moduleName === route.module &&
            permission.permissionName == "VIEW"
        );
      }
      return true;
    };
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (key === 0) {
        // Handle route at index 0 separately
        return (
          <NavLink to={prop.layout + prop.path} key={key}>
            {activeRoute(prop.layout + prop.path) === "active" ? (
              <Button
                boxSize="initial"
                justifyContent="flex-start"
                alignItems="center"
                boxShadow={sidebarActiveShadow}
                bg={activeBg}
                transition={variantChange}
                mb={{
                  xl: "6px",
                }}
                mx={{
                  xl: "auto",
                }}
                ps={{
                  sm: "10px",
                  xl: "16px",
                }}
                py="12px"
                borderRadius="15px"
                _hover="none"
                w="100%"
                _active={{
                  bg: "inherit",
                  transform: "none",
                  borderColor: "transparent",
                }}
                _focus={{
                  boxShadow: "0px 7px 11px rgba(0, 0, 0, 0.04)",
                }}
              >
                <Flex>
                  {typeof prop.icon === "string" ? (
                    <Icon>{prop.icon}</Icon>
                  ) : (
                    <IconBox
                      bg="blue.500"
                      color="white"
                      h="30px"
                      w="30px"
                      me="12px"
                      transition={variantChange}
                    >
                      {prop.icon}
                    </IconBox>
                  )}
                  <Text color={activeColor} my="auto" fontSize="sm">
                    {document.documentElement.dir === "rtl"
                      ? prop.rtlName
                      : prop.name}
                  </Text>
                </Flex>
              </Button>
            ) : (
              <Button
                boxSize="initial"
                justifyContent="flex-start"
                alignItems="center"
                bg="transparent"
                mb={{
                  xl: "6px",
                }}
                mx={{
                  xl: "auto",
                }}
                py="12px"
                ps={{
                  sm: "10px",
                  xl: "16px",
                }}
                borderRadius="15px"
                _hover="none"
                w="100%"
                _active={{
                  bg: "inherit",
                  transform: "none",
                  borderColor: "transparent",
                }}
                _focus={{
                  boxShadow: "none",
                }}
              >
                <Flex>
                  {typeof prop.icon === "string" ? (
                    <Icon>{prop.icon}</Icon>
                  ) : (
                    <IconBox
                      bg={inactiveBg}
                      color="blue.500"
                      h="30px"
                      w="30px"
                      me="12px"
                      transition={variantChange}
                    >
                      {prop.icon}
                    </IconBox>
                  )}
                  <Text color={inactiveColor} my="auto" fontSize="sm">
                    {document.documentElement.dir === "rtl"
                      ? prop.rtlName
                      : prop.name}
                  </Text>
                </Flex>
              </Button>
            )}
          </NavLink>
        );
      }
      if (prop.category && hasPermission(prop)) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <>
            <Text
              color={activeColor}
              fontWeight="bold"
              mb={{
                xl: "6px",
              }}
              mx="auto"
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              py="12px"
            >
              {document.documentElement.dir === "rtl"
                ? prop.rtlName
                : prop.name}
            </Text>
            {createLinks(prop.views)}
          </>
        );
      }
      if (prop.sidebar && hasPermission(prop)) {
        // Check permission before rendering
        return (
          <NavLink to={prop.layout + prop.path} key={key}>
            {activeRoute(prop.layout + prop.path) === "active" ? (
              <Button
                boxSize="initial"
                justifyContent="flex-start"
                alignItems="center"
                boxShadow={sidebarActiveShadow}
                bg={activeBg}
                transition={variantChange}
                mb={{
                  xl: "6px",
                }}
                mx={{
                  xl: "auto",
                }}
                ps={{
                  sm: "10px",
                  xl: "16px",
                }}
                py="12px"
                borderRadius="15px"
                _hover="none"
                w="100%"
                _active={{
                  bg: "inherit",
                  transform: "none",
                  borderColor: "transparent",
                }}
                _focus={{
                  boxShadow: "0px 7px 11px rgba(0, 0, 0, 0.04)",
                }}
              >
                <Flex>
                  {typeof prop.icon === "string" ? (
                    <Icon>{prop.icon}</Icon>
                  ) : (
                    <IconBox
                      bg="blue.500"
                      color="white"
                      h="30px"
                      w="30px"
                      me="12px"
                      transition={variantChange}
                    >
                      {prop.icon}
                    </IconBox>
                  )}
                  <Text color={activeColor} my="auto" fontSize="sm">
                    {document.documentElement.dir === "rtl"
                      ? prop.rtlName
                      : prop.name}
                  </Text>
                </Flex>
              </Button>
            ) : (
              <Button
                boxSize="initial"
                justifyContent="flex-start"
                alignItems="center"
                bg="transparent"
                mb={{
                  xl: "6px",
                }}
                mx={{
                  xl: "auto",
                }}
                py="12px"
                ps={{
                  sm: "10px",
                  xl: "16px",
                }}
                borderRadius="15px"
                _hover="none"
                w="100%"
                _active={{
                  bg: "inherit",
                  transform: "none",
                  borderColor: "transparent",
                }}
                _focus={{
                  boxShadow: "none",
                }}
              >
                <Flex>
                  {typeof prop.icon === "string" ? (
                    <Icon>{prop.icon}</Icon>
                  ) : (
                    <IconBox
                      bg={inactiveBg}
                      color="blue.500"
                      h="30px"
                      w="30px"
                      me="12px"
                      transition={variantChange}
                    >
                      {prop.icon}
                    </IconBox>
                  )}
                  <Text color={inactiveColor} my="auto" fontSize="sm">
                    {document.documentElement.dir === "rtl"
                      ? prop.rtlName
                      : prop.name}
                  </Text>
                </Flex>
              </Button>
            )}
          </NavLink>
        );
      }
    });
  };

  var links = <>{createLinks(routes)}</>;

  //  BRAND

  var brand = (
    <Box pt={"35px"} mb="8px">
      {logo}
      <HSeparator my="26px" />
    </Box>
  );

  // SIDEBAR
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  // Color variables
  return (
    <Flex
      display={{ sm: "flex", xl: "none" }}
      ref={mainPanel}
      alignItems="center"
    >
      <HamburgerIcon
        color={hamburgerColor}
        w="18px"
        h="18px"
        ref={btnRef}
        onClick={onOpen}
      />
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={document.documentElement.dir === "rtl" ? "right" : "left"}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent
          w="250px"
          maxW="250px"
          ms={{
            sm: "16px",
          }}
          my={{
            sm: "16px",
          }}
          borderRadius="16px"
          bg={sidebarBackgroundColor}
        >
          <DrawerCloseButton
            _focus={{ boxShadow: "none" }}
            _hover={{ boxShadow: "none" }}
          />
          <DrawerBody maxW="250px" px="1rem">
            <Box maxW="100%" h="100vh">
              <Box pt={"15px"}>
                <Flex
                  // justifyContent="center"
                  alignItems="center"
                  // mb="24px"
                >
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 1080 1080"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {/* <rect width="1080" height="1080" fill="white"/> */}
                    <path fill="none" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M200.647 339.435C184.099 521.391 295.14 752.068 459.83 840C511.501 812.403 557.899 770.74 596.73 720.95H550.904C524.176 751.496 493.657 778.528 459.83 799.274V285.985C492.396 311.197 527.546 330.847 564.812 345.19V307.041C527.139 290.968 491.92 268.748 459.83 240C386.986 305.256 298.145 336.823 200.647 339.435Z"
                      fill="#ED1C24"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M544.498 401.989H501.132C495.253 401.989 490.408 406.821 490.408 412.72V456.08C490.408 461.973 495.253 466.804 501.132 466.804H544.498C550.398 466.804 555.243 461.973 555.243 456.08V412.72C555.243 406.821 550.391 401.989 544.498 401.989Z"
                      fill="#9E1F63"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M672.948 607.916H618.837C611.49 607.916 605.453 613.947 605.453 621.301V675.426C605.453 682.787 611.49 688.818 618.837 688.818H672.948C680.323 688.818 686.333 682.787 686.333 675.426V621.301C686.333 613.947 680.316 607.916 672.948 607.916Z"
                      fill="#662D91"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M732.862 346.775H716.679C698.952 346.775 691.391 350.966 688.249 358.63C685.106 366.28 686.388 377.418 686.388 391.334V405.877C686.388 435.83 696.037 435.176 723.289 435.176H745.144C748.121 435.176 750.837 436.402 752.815 438.374C754.759 440.324 755.986 443.033 755.986 446.01V489.742C755.986 492.719 754.759 495.428 752.815 497.385C750.837 499.35 748.121 500.563 745.144 500.563H701.42C698.422 500.563 695.734 499.35 693.783 497.385C691.805 495.428 690.599 492.719 690.599 489.742V469.968C690.599 441.262 682.576 434.852 657.433 434.852H613.778C609.801 434.852 606.21 433.218 603.598 430.606C600.986 427.994 599.359 424.403 599.359 420.44V362.214C599.359 358.258 600.986 354.646 603.598 352.041C606.21 349.429 609.801 347.809 613.778 347.809H642.898C670.391 347.809 688.097 353.599 688.097 318V302.003C688.097 299.556 689.089 297.33 690.702 295.717C692.315 294.118 694.541 293.119 696.96 293.119H732.876C735.316 293.119 737.549 294.118 739.141 295.717C740.754 297.33 741.767 299.563 741.767 302.003V337.905C741.767 340.352 740.754 342.557 739.141 344.177C737.549 345.79 735.309 346.775 732.876 346.775H732.862Z"
                      fill="#7AC143"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M727.942 589.362H749.528C773.837 589.362 784.189 592.188 784.189 564.674V550.207C784.189 545.941 787.684 542.447 791.936 542.447H823.317C825.474 542.447 827.397 543.322 828.803 544.735C830.223 546.141 831.07 548.078 831.07 550.207V581.574C831.07 583.711 830.216 585.648 828.803 587.06C827.397 588.473 825.474 589.335 823.317 589.335H808.092C781.846 589.335 781.819 596.682 781.819 621.653V643.246C781.819 646.182 780.619 648.87 778.676 650.8C776.753 652.73 774.058 653.943 771.129 653.943H727.949C725.006 653.943 722.304 652.737 720.381 650.8C718.444 648.87 717.252 646.182 717.252 643.246V600.052C717.252 597.116 718.444 594.442 720.381 592.505C722.325 590.576 725.006 589.362 727.949 589.362H727.942Z"
                      fill="#F15A29"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M712.979 528.462H680.303C675.836 528.462 672.197 532.115 672.197 536.561V569.244C672.197 573.696 675.836 577.342 680.303 577.342H712.979C717.424 577.342 721.063 573.696 721.063 569.244V536.561C721.063 532.122 717.431 528.462 712.979 528.462Z"
                      fill="#FFF200"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M643.16 267.066H611.076C606.7 267.066 603.129 270.622 603.129 274.992V307.089C603.129 311.452 606.7 315.029 611.076 315.029H643.16C647.522 315.029 651.099 311.452 651.099 307.089V274.992C651.099 270.622 647.522 267.066 643.16 267.066Z"
                      fill="#003E56"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M848.445 451.469H808.745C803.342 451.469 798.924 455.88 798.924 461.29V500.983C798.924 506.379 803.342 510.804 808.745 510.804H848.445C853.855 510.804 858.259 506.379 858.259 500.983V461.29C858.259 455.873 853.855 451.469 848.445 451.469Z"
                      fill="#F6851F"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M813.269 381.74H784.542C780.655 381.74 777.429 384.924 777.429 388.832V417.566C777.429 421.481 780.655 424.672 784.542 424.672H813.269C817.204 424.672 820.382 421.481 820.382 417.566V388.832C820.382 384.924 817.204 381.74 813.269 381.74Z"
                      fill="#8E9738"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M873.871 323.486H845.151C841.229 323.486 838.038 326.684 838.038 330.592V359.326C838.038 363.234 841.229 366.432 845.151 366.432H873.871C877.779 366.432 880.984 363.234 880.984 359.326V330.592C880.984 326.684 877.779 323.486 873.871 323.486Z"
                      fill="#9B8579"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M525.125 688.928V661.655C525.125 657.933 528.143 654.901 531.865 654.901H559.131C562.86 654.901 565.885 657.94 565.885 661.655V688.928C565.885 692.643 562.86 695.689 559.131 695.689H531.865C531.521 695.689 531.162 695.655 530.831 695.607C527.585 695.11 525.125 692.312 525.125 688.928Z"
                      fill="#079747"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M629.865 470.133H571.088C567.083 470.133 563.458 471.773 560.811 474.406C558.172 477.039 556.545 480.678 556.545 484.683V514.064C556.545 547.788 557.937 560.649 518.355 560.649H497.782C494.846 560.649 492.144 561.855 490.207 563.806C488.264 565.743 487.058 568.417 487.058 571.374V614.726C487.058 617.683 488.264 620.364 490.207 622.308C492.144 624.251 494.846 625.464 497.782 625.464H541.148C544.091 625.464 546.765 624.258 548.722 622.308C550.673 620.364 551.858 617.683 551.858 614.726V593.836C551.858 552.565 566.718 557.996 600.483 557.996H629.865C633.855 557.996 637.488 556.362 640.127 553.729C642.753 551.083 644.407 547.451 644.407 543.453V484.683C644.407 480.685 642.753 477.046 640.127 474.406C637.488 471.773 633.862 470.133 629.865 470.133Z"
                      fill="#385BA9"
                    />
                  </svg>{" "}
                  <Flex>
                  <div style={{ marginBottom: "10px" }}>
                    <Text fontSize="xl" fontWeight="bold" mt="10px" color="rgb(237, 28, 36)">
                      KAVACH
                    </Text>
                    <Text fontSize="sm" fontWeight="bold" color="#4169E1">
                      Science of Security
                    </Text>
                  </div>
                </Flex>
                </Flex>
                <HSeparator my="10px" />
              </Box>

              <Stack direction="column" mb="40px">
                <Box>{links}</Box>
              </Stack>
              <SidebarHelp />
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}

export default Sidebar;
