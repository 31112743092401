// Chakra imports
import {
  Flex,
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  WrapItem,
  TableContainer,
  Image,
  useColorModeValue,
  Wrap,
  HStack,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { wrongEntryClearShowSnackbar, wrongEntryAddOrUpdate } from '../../actions/wrongEntryAction';
import { useToast } from '@chakra-ui/react'
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
//   import { WRONG_ENTRY_CLEAR_SNACKBAR_GATE_PASS_CLEAR_SNACKBAR } from "constants/types";
import {
  Skeleton,
  SkeletonCircle,
  SkeletonText,
} from "@chakra-ui/react";
import { WRONG_ENTRY_CLEAR_SNACKBAR } from "constants/types";
// import { wrongEntryClearShowSnackbar } from "actions/wrongEntryAction";
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
function WrongEntryView() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [department, setDepartment] = useState("");
  const [subdepartment, setSubdepartment] = useState("");
  const [checkpostName, setCheckpostName] = useState("");
  const [timeIn, setTimeIn] = useState("");
  const [guardName, setGuardName] = useState("");
  const [currentStatustype, setCurrentStatustype] = useState("");
  const [purpose, setPurpose] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [txnNumber, setTxnNumber] = useState("");
  const [totalTime, setTotalTime] = useState("");


  const history = useHistory();
  const dispatch = useDispatch();
  const toast = useToast();

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.wrongEntry.status);
  const responseMessage = useSelector(state => state.wrongEntry.text);
  const loading = useSelector(state => state.wrongEntry.loading);
  // const designationDetail = useSelector(state => state.wrongEntry.designationDetail);
  const wrongEntryDetail = useSelector((state) => state.wrongEntry.wrongEntryDetail);
  const showSnackbar = useSelector(state => state.wrongEntry.showSnackbar);
  const logs = useSelector(state => state.wrongEntry.logs);
  // const  = useSelector(state => state.wrongEntry.materialId.logs);
  const wrongEntryData = useSelector(state => state.wrongEntry.material);

  const params = useParams();
  

console.log("hugfsdjygygjwdfgiydwf")
console.log(wrongEntryDetail);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        
        setType(wrongEntryDetail.type);
        setName(wrongEntryDetail.name);
        setImage(wrongEntryDetail.image);
        setDepartment(wrongEntryDetail.department);
        setSubdepartment(wrongEntryDetail.subdepartment);
        setCheckpostName(wrongEntryDetail.checkpostName);
        setTimeIn(wrongEntryDetail.timeIn);
        setGuardName(wrongEntryDetail.guardName);
        setCurrentStatustype(wrongEntryDetail.currentStatustype);
        setPurpose(wrongEntryDetail.purpose);
        setCurrentStatus(wrongEntryDetail.currentStatus);
        setTxnNumber(wrongEntryDetail.txnNumber);
        setTotalTime(wrongEntryDetail.totalTime);

        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
      dispatch({
        type: WRONG_ENTRY_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        }
      });
    }
  }, [loading]);

  useEffect(() => {
    const wrongEntryId = params.id;
    const wrongEntry = {
      _id: wrongEntryId,
    };
    dispatch(wrongEntryAddOrUpdate(wrongEntry, 'view'));
  }, []);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };


  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px" mb={4} >
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Wrong Entry View 
          </Text>
        </CardHeader>
        <CardBody>
          {loading ? (
            <Skeleton height="20px" />
          ) : (
            <TableContainer>
            <Table variant="simple">
                <Tbody>
                  <Tr>
                    <Td>Type</Td>
                    <Td>{type}</Td>
                  </Tr>
                  <Tr>
                    <Td>Name</Td>
                    <Td>{name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Image</Td>
                    <Td>
                      <Flex direction="column">
                        <Image
                          boxSize="100px"
                          objectFit="cover"
                          src={image}
                          alt="HowItWork Image"
                        />
                      </Flex>
                    </Td>
                  </Tr>
                
                  <Tr>
                    <Td>Department</Td>
                    <Td>{department}</Td>
                  </Tr>
                  <Tr>
                    <Td>Subdepartment</Td>
                    <Td>{subdepartment}</Td>
                  </Tr>
                  <Tr>
                    <Td>Checkpost Name</Td>
                    <Td>{checkpostName}</Td>
                  </Tr>
                  <Tr>
                    <Td>Time In</Td>
                    <Td>{timeIn}</Td>
                  </Tr>
                  <Tr>
                    <Td>Guard Name</Td>
                    <Td>{guardName}</Td>
                  </Tr>
                  <Tr>
                    <Td>Current Status Type</Td>
                    <Td>{ }</Td>
                  </Tr>
                  <Tr>
                    <Td>Purpose</Td>
                    <Td>{purpose}</Td>
                  </Tr>
                  <Tr>
                    <Td>Current Status</Td>
                    <Td>{currentStatus}</Td>
                  </Tr>
                  <Tr>
                    <Td>Transaction Number</Td>
                    <Td>{txnNumber}</Td>
                  </Tr>
                  <Tr>
                    <Td>Total Time</Td>
                    <Td>{totalTime}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </CardBody>

      </Card>
      
    </Flex>
  );
}
export default WrongEntryView