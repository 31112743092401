import axios from 'axios';
import {
    EMPLOYEEATTENDENCE_LOADING,
    EMPLOYEEATTENDENCE_GET_ALL_SUCCESS,
    EMPLOYEEATTENDENCE_ERROR,
    EMPLOYEEATTENDENCE_CLEAR_SNACKBAR
} from '../constants/types';

import { server_url } from '../constants/types';

export const EmployeeAttendenceGetAll = (pagination = '') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: EMPLOYEEATTENDENCE_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/employee/attendance/new?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          console.log("employee attend data")
          console.log(response.data.data)
          dispatch({
            type: EMPLOYEEATTENDENCE_GET_ALL_SUCCESS,
            payload: {
              employeeattendenceList: response.data.data,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all employee successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: EMPLOYEEATTENDENCE_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting employee attendence data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const EmployeeAttendenceHeadCount = (pagination) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: EMPLOYEEATTENDENCE_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/admin/workforce-headcount?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          console.log("employee attend data")
          console.log(response.data.data)
          dispatch({
            type: EMPLOYEEATTENDENCE_GET_ALL_SUCCESS,
            payload: {
              employeeattendenceListIN: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all employee successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: EMPLOYEEATTENDENCE_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting employee attendence data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const EmployeeAttendenceOut = (pagination) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: EMPLOYEEATTENDENCE_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/admin/employee-attendence-out?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          console.log("employee attend data")
          console.log(response.data.data)
          dispatch({
            type: EMPLOYEEATTENDENCE_GET_ALL_SUCCESS,
            payload: {
              employeeattendenceListOut: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all employee successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: EMPLOYEEATTENDENCE_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting employee attendence data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const EmployeeAttendenceIn = (pagination) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: EMPLOYEEATTENDENCE_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/admin/employee-attendence-in?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          console.log("employeeattenddata")
          console.log(response.data.data)
          dispatch({
            type: EMPLOYEEATTENDENCE_GET_ALL_SUCCESS,
            payload: {
              workForceIn: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all employee successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: EMPLOYEEATTENDENCE_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting employee attendence data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};


export const EmployeeGetById = (Employee) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: EMPLOYEEATTENDENCE_LOADING,
        payload: {
          loading: true
        }
      });

      // Fetch employee attendance details by ID
      axios.get(`${server_url}/employee/attendance/${Employee}`)
        .then((responseF) => {
          // Fetch employee attendence record list  by Id 
          axios.get(`${server_url}/third/employee/attendance`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((ResponseE) => {
            // Fetch other employee attendance records
            axios.get(`${server_url}/employee/attendance/new`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
            .then((response) => {
              dispatch({
                type: EMPLOYEEATTENDENCE_GET_ALL_SUCCESS,
                payload: {
                  employeeattendenceList: response.data.data,
                  limit: response.data.limit,
                  page: response.data.currentPage,
                  totalPages: response.data.totalPages,
                  response: response.data,
                  employeeattendenceDetail: responseF.data['data'],
                  employeeattendenceGetByIdList: ResponseE.data['data'], // Store the third API data
                  loading: false,
                  status: responseF.data['status'],
                  text: responseF.data['message']
                }
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: EMPLOYEEATTENDENCE_ERROR,
                payload: {
                  text: 'Error occurred during getting employee data.',
                  status: 'error',
                  loading: false
                }
              });
              resolve();
            });
          })
          .catch((e) => {
            dispatch({
              type: EMPLOYEEATTENDENCE_ERROR,
              payload: {
                text: 'Error occurred during getting third API data.',
                status: 'error',
                loading: false
              }
            });
            resolve();
          });
        })
        .catch((e) => {
          dispatch({
            type: EMPLOYEEATTENDENCE_ERROR,
            payload: {
              text: 'Error occurred during fetching employee attendance details.',
              status: 'error',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

// export const EmployeeGetById = (Employee) => {
//   return (dispatch, getState) => {
//     return new Promise((resolve) => {
//       dispatch({
//         type: EMPLOYEEATTENDENCE_LOADING,
//         payload: {
//           loading: true
//         }
//       });
//       axios
//         .get(`${server_url}/employee/attendance/${Employee}`)
//         .then((responseF) => {
//           axios
//             .get(`${server_url}/employee/attendance/new`, {
//               headers: {
//                 'Authorization': 'Bearer ' + localStorage.getItem('authToken')
//               }
//             })
//             .then((response) => {
//               dispatch({
//                 type: EMPLOYEEATTENDENCE_GET_ALL_SUCCESS,
//                 payload: {
//                   employeeattendenceList: response.data.data,
//                   response: response.data,
//                   employeeattendenceDetail: responseF.data['data'],
//                   loading: false,
//                   status: responseF.data['status'],
//                   text: responseF.data['message']
//                 }
//               });
//               resolve();
//             })
//             .catch((e) => {
//               dispatch({
//                 type: EMPLOYEEATTENDENCE_ERROR,
//                 payload: {
//                   text: 'Error occurred during getting employee data.',
//                   status: 'error',
//                   loading: false
//                 }
//               });
//               resolve();
//             });
//         })
//         .catch((e) => {
//           dispatch({
//             type: EMPLOYEEATTENDENCE_ERROR,
//             payload: {
//               text: 'Error occurred during deleting employee data.',
//               status: 'error',
//               loading: false
//             }
//           });
//           resolve();
//         });
//     });
//   };
// };


// export const EmployeeAttendenceById = (pagination = '') => {
//   return (dispatch, getState) => {
//     return new Promise((resolve) => {
//       dispatch({
//         type: EMPLOYEEATTENDENCE_LOADING,
//         payload: {
//           loading: true
//         }
//           });
//           axios.get(`${server_url}/employee/attendance`, {headers: { 
//               'Authorization': 'Bearer '+localStorage.getItem('authToken')
//             }}).then(responseF => {
//               axios.get(`${server_url}/employee/attendance/new`, {headers: { 
//                   'Authorization': 'Bearer '+localStorage.getItem('authToken')
//                 }}).then(response => {
//                   dispatch({
//                       type: EMPLOYEEATTENDENCE_GET_ALL_SUCCESS,
//                       payload: {
//                         employeeattendenceList: response.data.data,
//                         employeeattendenceRecord:responseF.data.data,
//                         response: response.data,
//                         loading: false,
//                         status: 'success',
//                         text: 'Get all employee successfully.'
//                       }
//                     });
//                   resolve();
//               }).catch((e) => {
//                 dispatch({
//                   type: EMPLOYEEATTENDENCE_ERROR,
//                   payload: {
//                     status: 'error',
//                     text: 'Error occurred during getting employee attendence data.',
//                     loading: false
//                   }
//                 });
//                   resolve();
//               })
//           }).catch((e) => {
//             dispatch({
//               type: EMPLOYEEATTENDENCE_ERROR,
//               payload: {
//                 status: 'error',
//                 text: 'Error occurred during getting employee attendence data.',
//                 loading: false
//               }
//             });
//               resolve();
//           })
//       })
//   }
// }




