import React, { useEffect, useState } from 'react';
import {
  Flex,
  Input,
  SimpleGrid,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Button,
  Center,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useDispatch, useSelector } from 'react-redux';
import { schedulehighAlertClearShowSnackbar, schedulehighAlertAddOrUpdate } from '../../actions/schedulehighalertAction';
import { useToast } from '@chakra-ui/react';
import { SCHEDULE_HIGH_ALERT_CLEAR_SNACKBAR } from 'constants/types';
import { Spinner } from '@chakra-ui/react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import dataConstant from 'constants/dataConstant';
import {patrollingRouteGetAll} from '../../actions/PatrollingRouteAction'
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
import { checkpostGetAll } from 'actions/checkpostActions';
import RSelect from 'react-select';



function ScheduleHighAlertUpdateForm() {
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [startDate, setStartDate] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('');
  const [route, setRoute] = useState('');
  const [endDate, setEndDate] = useState('');
  const [duration, setDuration] = useState('');
  const [popupDuration, setPopupDuration] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [alternateContactNumber, setAlternateContactNumber] = useState('');
  const [isErrorStartDate, setIsErrorStartDate] = useState(false);
  const [isErrorDescription, setIsErrorDescription] = useState(false);
  const [isErrorStatus, setIsErrorStatus] = useState(false);
  const [isErrorRoute, setIsErrorRoute] = useState(false);
  const [isErrorEndDate, setIsErrorEndDate] = useState(false);
  const [isErrorCheckpost , setIsErrorCheckpost] = useState(false);
  const [checkpost , setCheckpost] = useState("");
  const [isErrorAlternateContactNumber , setIsErrorAlternateContactNumber] = useState(false);
  const [isErrorContactNumber , setIsErrorContactNumber] = useState(false);
  const [isErrorpopupDuration , setIsErrorpopupDuration] = useState(false);
  const [isErrorDuration , setIsErrorDuration] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.SchedulehighAlert.status);
  const responseMessage = useSelector(state => state.SchedulehighAlert.text);
  const loading = useSelector(state => state.SchedulehighAlert.loading);
  const schedulehighAlertDetail = useSelector((state) => state.SchedulehighAlert.schedulehighAlertDetail);
  const showSnackbar = useSelector(state => state.SchedulehighAlert.showSnackbar);
  const patrollingRouteList = useSelector((state) => state.patrollingRoute.patrollingRouteList);
  const checkpostList = useSelector((state) => state.checkpost.checkpostList);

  console.log("uoagdfiygadufguioad;;;;;;;;;;;;");
  console.log(checkpost);

  const params = useParams();
  const id = params.id;
console.log("schedulehighAlertDetail1234567890")
console.log(schedulehighAlertDetail)

  useEffect(() => {
    const schedulehighAlert = {
      _id: id,
    };
    dispatch(schedulehighAlertAddOrUpdate(schedulehighAlert, 'view'));
  }, [id]);

  useEffect(()=>{
    dispatch(patrollingRouteGetAll());
  },[]);

  useEffect(()=>{
    // dispatch(checkpostGetAll());
  },[]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsErrorStartDate(startDate === '');
    setIsErrorStatus(status === '');
    setIsErrorEndDate(endDate === '');
    setIsErrorCheckpost(checkpost.length == 0);
    // setIsErrorAlternateContactNumber(alternateContactNumber === '')
    // setIsErrorContactNumber(contactNumber === '')
    setIsErrorpopupDuration(popupDuration === '')
    setIsErrorDuration(duration === '')

    if (startDate !== '' && status !== '' && endDate !== ''  && checkpost.length != 0 && duration !== "" && popupDuration !== ''){
      const schedulehighAlertData = {
        _id: id,
        startDate: startDate,
        status: status,
        endDate: endDate,
        checkpost :  checkpost.map((module) => module.value),
        duration : duration,
        popupDuration : popupDuration,
        // contactNumber : contactNumber,
        // alternateContactNumber : alternateContactNumber,
      };

      dispatch(schedulehighAlertAddOrUpdate(schedulehighAlertData, 'edit'));
    }
  };
  
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        const updatedScheduleHighAlertDetail = schedulehighAlertDetail || {};
        setStartDate(updatedScheduleHighAlertDetail.startDate || '');
        setEndDate(updatedScheduleHighAlertDetail.endDate || '');
        setDuration(updatedScheduleHighAlertDetail.duration || '');
        setPopupDuration(updatedScheduleHighAlertDetail.popupDuration || '');
        setContactNumber(updatedScheduleHighAlertDetail.contactNumber || '');
        setAlternateContactNumber(updatedScheduleHighAlertDetail.alternateContactNumber || '');
        // setCheckpost(updatedScheduleHighAlertDetail.checkpost || '');
        setStatus(updatedScheduleHighAlertDetail.status || '');
        setCheckpost(updatedScheduleHighAlertDetail.checkpost.map((item) => ({
          value: item._id,
          label: item.name
        })));
        dispatch(checkpostGetAll("&plant=" + updatedScheduleHighAlertDetail.plantAdmin));
        if (responseMessage !== "") {
          toastFunction(responseMessage, "success");
          history.goBack();
        }
      } else if (responseStatus === "NOK" && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, "error");
        }
      }
  
      dispatch(schedulehighAlertClearShowSnackbar());
    }
  }, [loading]);
  
  
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
           Schedule-High-Alert Update Form
          </Text>
          <br/>
          <SimpleGrid minChildWidth='120px' spacing='40px'>
          

            <FormControl isInvalid={isErrorStartDate}>
              <FormLabel>Start Date</FormLabel>
              <Input
                type='time'
                value={startDate}
                onChange={(e) => {
                  setIsErrorStartDate(e.target.value === '');
                  setStartDate(e.target.value);
                }}
              />
              {!isErrorStartDate ? (
                <></>
              ) : (
                <FormErrorMessage>StartDate is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorEndDate}>
              <FormLabel>EndDate</FormLabel>
              <Input
                type='time'
                value={endDate}
                onChange={(e) => {
                  setIsErrorEndDate(e.target.value === '');
                  setEndDate(e.target.value);
                }}
              />
              {!isErrorEndDate ? (
                <></>
              ) : (
                <FormErrorMessage>EndDate is required.</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' marginTop='30px'>
          <FormControl isInvalid={isErrorDuration}>
            <FormLabel>Duration (in min)</FormLabel>
            <Input
              type='text'
              value={duration}
              onChange={(e) => {
                setIsErrorDuration(e.target.value === '');
                setDuration(e.target.value);
              }}
            />
            {!isErrorDuration ? (
              <></>
            ) : (
              <FormErrorMessage>Duration is required.</FormErrorMessage>
            )}
          </FormControl>

          <FormControl isInvalid={isErrorpopupDuration}>
            <FormLabel>Popup Duration (in sec)</FormLabel>
            <Input
              type="text"
              value={popupDuration}
              onChange={(e) => {
                setIsErrorpopupDuration(e.target.value === '');
                setPopupDuration(e.target.value);
              }}
            />
            {!isErrorpopupDuration ? (
              <></>
            ) : (
              <FormErrorMessage>Popup Duration is required.</FormErrorMessage>
            )}
          </FormControl>
        </SimpleGrid>

          {/* <SimpleGrid minChildWidth='120px' spacing='40px' marginTop='30px'>
          

          <FormControl isInvalid={isErrorContactNumber}>
            <FormLabel>Contact Number</FormLabel>
            <Input
              type='text'
              value={contactNumber}
              onChange={(e) => {
                setIsErrorContactNumber(e.target.value === '');
                setContactNumber(e.target.value);
              }}
            />
            {!isErrorContactNumber ? (
              <></>
            ) : (
              <FormErrorMessage>Contact Number is required.</FormErrorMessage>
            )}
          </FormControl>

          <FormControl isInvalid={isErrorAlternateContactNumber}>
            <FormLabel>Alternate Contact Number</FormLabel>
            <Input
              type="text"
              value={alternateContactNumber}
              onChange={(e) => {
                setIsErrorAlternateContactNumber(e.target.value === '');
                setAlternateContactNumber(e.target.value);
              }}
            />
            {!isErrorAlternateContactNumber ? (
              <></>
            ) : (
              <FormErrorMessage>Alternate Contact Number is required.</FormErrorMessage>
            )}
          </FormControl>
        </SimpleGrid> */}

          <SimpleGrid minChildWidth='120px' spacing='40px' marginTop='30px'>
            <FormControl isInvalid={isErrorStatus}>
              <FormLabel>Status</FormLabel>
              <Select
                placeholder="Select status"
                value={status}
                onChange={(e) => {
                  setIsErrorStatus(e.target.value === '');
                  setStatus(e.target.value);
                }}
              >
                {dataConstant.status.map((element, index) => {
                  return (<option key={element['id']} value={element['value']} >{element['name']}</option>);
                })}
              </Select>
              {!isErrorStatus ? (
                <></>
              ) : (
                <FormErrorMessage>Status is required.</FormErrorMessage>
              )}
            </FormControl>
            

            <FormControl isInvalid={isErrorCheckpost }>
                <FormLabel>Check post</FormLabel>
                <RSelect
               isMulti
               name="colors"
               value={checkpost}
               placeholder="--select checkpost--"
               options={checkpostList.map((item) => ({
                  value: item._id,
                  label: item.name
                  }))}
              
               onChange={(value) => {
                console.log(value);
                console.log(value)
                setCheckpost(value)
                 setIsErrorCheckpost(value.length==0)}}
                className="basic-multi-select"
                classNamePrefix="select"
              />
              
                {isErrorCheckpost && (
                  <FormErrorMessage>Checkpost is required.</FormErrorMessage>
                )}
              </FormControl>
          </SimpleGrid>
          <Center>
            {loading ? (
              <Spinner mt='24px' size='lg' />
            ) : (
              <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                Submit
              </Button>
            )}
          </Center>
        </CardHeader>
        <CardBody></CardBody>
      </Card>
    </Flex>
  );
}

export default ScheduleHighAlertUpdateForm;
