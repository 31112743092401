import React, { useRef } from "react";
import {
  Image,
  Flex,
  IconButton,
  Link,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure,
  ButtonGroup,
  Center,
  Divider,
  Spacer,
  Box
} from "@chakra-ui/react";
import { ViewIcon,DownloadIcon } from "@chakra-ui/icons";
import { AiFillEdit } from "react-icons/ai";
import { useDispatch } from "react-redux";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { inspectionqrListDelete } from "actions/inspectionqrAction";
import { BsFillTrashFill } from "react-icons/bs";
import { Button } from "@chakra-ui/react/dist/chakra-ui-react.cjs";
import QRCode from 'react-qr-code';
import { toPng } from 'html-to-image';

const InspectionQrTableRow = (props) => {
  const {
    name,
    description,
    status,
    isLast,
    id,
    serialnumber,
    plant,
    qrCode,
    inspectionSchedule,
    inspectionName
  } = props;

  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const dispatch = useDispatch();

  const handleDelete = () => {
    const bannerPosition = {
      _id: id,
    };
    dispatch(inspectionqrListDelete(bannerPosition._id,plant));
  };
  const qrCodeRef = useRef();

  console.log("nakhkahksdkhl");
  console.log(qrCodeRef);

  const handleDownloadQRCode = async () => {
    try {
      const svgString = qrCodeRef.current.querySelector('svg').outerHTML;
      const dataUrl = await toPng(qrCodeRef.current);

      const a = document.createElement('a');
      a.href = dataUrl;
      a.download = `${name}.png`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading QR code:', error);
    }
  };
  return (
    <>
      <Tr>
        {/* Name */}
        <Td
          >
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={titleColor}
                  fontWeight="bold"
                  minWidth="100%"
                >
                  {serialnumber}
                </Text>
              </Flex>
            </Flex>
          </Td>
        {/* <Td
         pl="0px"
         borderColor={borderColor}
         borderBottom={isLast ? "none" : "1px solid"}
         whiteSpace="nowrap"
         overflow="hidden"
         textOverflow="ellipsis"
        >
          <Text
            fontSize="md"
            color={titleColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {inspectionName}
          </Text>
        </Td> */}
        <Td
         pl="0px"
         borderColor={borderColor}
         borderBottom={isLast ? "none" : "1px solid"}
         whiteSpace="nowrap"
         overflow="hidden"
         textOverflow="ellipsis"
        >
          {/* Render Name */}
          <Text
            fontSize="md"
            color={titleColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {name}
          </Text>
        </Td>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {/* Render Name */}
          <Text
            fontSize="md"
            color={titleColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {inspectionSchedule}
          </Text>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {/* Render Name */}
          <Text fontSize="md" color={textColor} fontWeight="bold" ref={qrCodeRef} style={{ textAlign: 'center', color: 'black' }}>
              <Center><QRCode size={100} value={qrCode.toString()} /></Center>
              {/* <span style={{ display: 'inline-block', fontWeight: 'italic', color: 'white', padding: '15px',paddingTop: '5px', paddingBottom: '5px', textAlign: 'center', backgroundColor: 'blue', margin: "10px", marginLeft: '90px', marginRight: '90px', borderRadius: '10px'}}>{name}</span> */}
              <div style={{
                display: 'inline-block',
                margin: '5px',
                borderRadius: '8px',
                fontFamily: 'Arial, sans-serif',
                fontSize: '12px',
                marginTop: '20px',
                backgroundColor: '#ccccff',
                color: 'black',
                border: '2px solid #3498db',
                padding: '15px', paddingTop: '5px', paddingBottom: '5px', textAlign: 'center',
              }}>
                <Flex>
                  <Box p="4px">{name}</Box>
                  <Spacer />
                  <Center color='black' height="25px"><Divider borderColor='black' borderWidth='1px' orientation='vertical' /></Center>
                  <Box p="4px">{inspectionSchedule}</Box>
                </Flex>
                <Center><Box p="4px">{plant.plant[0]?.plantName}</Box></Center>
                </div>
              <br/>
              <span style={{ display: 'inline-block', fontWeight: 'italic', color: 'white', padding: '15px', paddingTop: '5px', paddingBottom: '5px', textAlign: 'center', backgroundColor: 'blue', margin: "10px", marginLeft: '90px', marginRight: '90px', borderRadius: '10px', }}>{inspectionName} <DownloadIcon onClick={handleDownloadQRCode}></DownloadIcon></span>
            </Text>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {/* Render Name */}
          <Text
            fontSize="md"
            color={titleColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {status?'Active':'Inactive'}
          </Text>
        </Td>

        {/* Action buttons */}
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <ButtonGroup>
            {/* View Button */}
            <Link href={`#/admin/inspection-qr/view/${id}`}>
              <IconButton
                colorScheme="blue"
                icon={<ViewIcon />}
                aria-label="View"
              />
            </Link>

            {/* Edit Button */}
            <Link href={`#/admin/inspection-qr/update/${id}`}>
              <IconButton
                colorScheme="green"
                icon={<AiFillEdit />}
                aria-label="Edit"
              />
            </Link>

            {/* Delete Button */}
            <IconButton
              colorScheme="red"
              variant="outline"
              icon={<BsFillTrashFill />}
              aria-label="Delete"
              onClick={onOpen}
            />
          </ButtonGroup>
        </Td>

        {/* Delete Confirmation Dialog */}
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Banner Position
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    handleDelete();
                    onClose();
                  }}
                  ml={3}
                >
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Tr>
    </>
  );
};

export default InspectionQrTableRow;
