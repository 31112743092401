import React from "react";
import {
  Avatar,
  Badge,
  Button,
  Flex,
  Link,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure,
  ButtonGroup,
  IconButton,
 
} from "@chakra-ui/react";
import { BsBoxArrowUpRight, BsFillTrashFill } from "react-icons/bs";
import { ViewIcon } from "@chakra-ui/icons";
import { AiFillEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";

const MaterialTypeInTableRow = (props) => {
  const {
                    mode,
                    materialBy,
                    purpose,
                    status,
                    materialDescription,
                    invoiceNumber,
                    quantity,
                    type,
                    weighment,
                    isLast,
                    id,
                    serialnumber,
                    key,
  } = props;

  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const materialDetail = useSelector((state) => state.material.materialDetail); // Make sure you have the correct state slice for device detail
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const dispatch = useDispatch();
//   const authDetail = useSelector((state) => state.auth.authDetail);
  return (
    <>
      <Tr>
      <Td
          >
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={titleColor}
                  fontWeight="bold"
                  minWidth="100%"
                >
                  {serialnumber}
                </Text>
              </Flex>
            </Flex>
          </Td>
      <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={titleColor} fontWeight="bold">
              {mode}
            </Text>
          </Flex>
        </Td>
        <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {materialBy}
            </Text>
          </Flex>
        </Td>
        <Td
          minWidth={{ sm: "250px" }}
          pl="15px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {purpose}
            </Text>
          </Flex>
        </Td>
        <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
            <Badge colorScheme={status === "ALLOW" || status === "NRGP" || status === "RGP" ? "green" : "red"}>{status}</Badge>
          </Td>
        <Td
          minWidth={{ sm: "250px" }}
          pl="15px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {materialDescription}
            </Text>
          </Flex>
        </Td>
        <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {invoiceNumber}
            </Text>
          </Flex>
        </Td>
        <Td
          minWidth={{ sm: "250px" }}
          pl="30px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {quantity}
            </Text>
          </Flex>
        </Td>
        <Td
          minWidth={{ sm: "250px" }}
          pl="10px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
            <Badge colorScheme={type === "IN" ? "green" : "red"}>{type}</Badge>
          </Td>
        <Td
          minWidth={{ sm: "250px" }}
          pl="15px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {weighment}
            </Text>
          </Flex>
        </Td>

        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Device
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={() => { handleDelete(); onClose(); }} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Tr>
    </>
  );
};

export default MaterialTypeInTableRow;
