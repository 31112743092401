// Chakra imports
import {
    Box,
    Button,
    Center,
    Flex,
    Input,
    SimpleGrid,
    Spacer,
    Text,
    Select,
    Wrap,
    WrapItem,
    useColorModeValue,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import {
    adminAddOrUpdate,
    adminClearShowSnackbar,
    adminDelete,
    adminGetAll,
  } from "../../actions/adminActions";
  import {
    FormControl,
    FormErrorMessage,
    FormLabel,
  } from "@chakra-ui/react";
  import { useToast } from "@chakra-ui/react";
  import { ADMIN_CLEAR_SNACKBAR } from "constants/types";
  import { Spinner } from "@chakra-ui/react";
  import dataConstant from "constants/dataConstant";
  import { roleGetAll } from "actions/roleActions";
  import { cityGetAll } from "actions/CityActions";
  import AdminTable from "../../views/Dashboard/AdminTable"
  // src/views/Dashboard/AdminTable.js
  import { useHistory } from 'react-router-dom';
  import Relect from 'react-select';
  import axios from 'axios';
  import { server_url } from "constants/types";
  function CorporateForm() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [contactNo, setContactNo] = useState("");
    const [role, setRole] = useState("");
    const [city, setCity] = useState("");
    const [secoundarycontactNo, setSecoundarycontactNo] = useState("");
    const [Line1, setLine1] = useState("");
    const [Line2, setLine2] = useState("");
    const [Postalcode, setPostalcode] = useState("");
    const [Locality, setLocality] = useState("");
    const [State, setState] = useState("");
    const [Age, setAge] = useState("");
    const [Gender, setGender] = useState("");
    const [Maritalstatus, setMaritalstatus] = useState("");
    const [status, setStatus] = useState(true);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [Validation, setValidation] = useState("");
    const [isErrorName, setIsErrorName] = useState(false);
    const [isErrorEmail, setIsErrorEmail] = useState(false);
    const [isErrorContactNo, setIsErrorContactNo] = useState(false);
    const [isErrorPassword, setIsErrorPassword] = useState(false);
    const [isErrorRole, setIsErrorRole] = useState(false);
    const [isErrorCity, setIsErrorCity] = useState(false);
    const [isErrorSecoundarycontactNo, setIsErrorSecoundarycontactNo] = useState(false);
    const [isErrorStatus, setIsErrorStatus] = useState(false);
    const [isErrorLine1, setIsErrorLine1] = useState(false);
    const [isErrorLine2, setIsErrorLine2] = useState(false);
    const [isErrorPostalcode, setIsErrorPostalcode] = useState(false);
    const [isErrorLocality, setIsErrorLocality] = useState(false);
    const [isErrorState, setIsErrorState] = useState(false);
    const [isErrorAge, setIsErrorAge] = useState(false);
    const [isErrorGender, setIsErrorGender] = useState(false);
    const [isErrorMaritalstatus, setIsErrorMaritalstatus] = useState(false);
    const [isErrorValidation, setIsErrorValidation] = useState(false)
    const [isErrorStartDate, setIsErrorStartDate] = useState(false);
    const [isErrorEndDate, setIsErrorEndDate] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [plantList, setPlantList] = useState([]);
    const [plant, setPlant] = useState([]);
    const [isErrorPlant, setIsErrorPlant] = useState("");
  
    const [roleVale, setRoleVale] = useState("")
    const roleList = useSelector(state => state.role.roleList);
    const cityList = useSelector(state => state.city.cityList);
  
    const dispatch = useDispatch();
    const toast = useToast();
    const numberRegex = /^\d{10}$/;
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/;
  
    const responseStatus = useSelector((state) => state.admin.status);
    const responseMessage = useSelector((state) => state.admin.text);
    const loading = useSelector((state) => state.admin.loading);
    const showSnackbar = useSelector((state) => state.admin.showSnackbar);
    const history = useHistory();
  
    console.log(status)
  
  
    useEffect(() => {
      dispatch(roleGetAll());
      dispatch(cityGetAll());
  
      roleList.map((item) => { if (item.name === "Corporate") { setRoleVale(item._id) } })
        getPlantAdminLists();
    }, [dispatch]);
  
  
    // Function to handle form submission
    const handleSubmit = (event) => {
      event.preventDefault();
      setSubmitted(true);
      setIsErrorName(name === "");
      setIsErrorEmail(email === "");
      setIsErrorContactNo(contactNo === "");
    //   setIsErrorPlant(plant.length == 0);
    //   setIsErrorCity(city === "");
    //   setIsErrorSecoundarycontactNo(secoundarycontactNo === "");
      setIsErrorStartDate(startDate === "")
      setIsErrorEndDate(endDate === "")
      if (
        name != "" &&
        email != "" &&
        contactNo != "" &&
        // city != "" &&
        // secoundarycontactNo != "" &&
        // status != "" &&
        startDate != "" &&
        endDate != ""
  
      ) {
        console.log("Submit");
        // Get form data
        const admin = {
          name: name,
          email: email,
  
          contactNo: contactNo,
        //   secoundarycontactNo: secoundarycontactNo,
          role: dataConstant.CORPORATEROLE,
          mappedPlant: plant,
  
          status: status,
          startDate: startDate,
          endDate: endDate,
          // Other admin data fields
        };
        console.log(admin);
  
        // Determine if it's an add or edit operation
        const state = "add";
        // Call the adminAddOrUpdate action
        dispatch(adminAddOrUpdate(admin, state))
        // .then(() => {
        //   setFormSubmitted(true); 
        // })
        // .catch((error) => {
  
        //   toastFunction('Form submission failed.', 'error');
        // });
      }
    };
  
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === "OK" && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, "success");
            history.goBack();
          }
        } else if (responseStatus === "NOK" && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, "error");
          }
        }
      }
      dispatch({
        type: ADMIN_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        },
      });
    }, [loading]);
  
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      });
    };

    const getPlantAdminLists = () => {
        axios
          .get(`${server_url}/admin/plantAdmin?limit=1000`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          }).then((response) => {
            setPlantList(response.data.data)
          }).catch(() => console.log("error"))
      }
  
  
    // console.log(roleList.filter((element,index) => {
    //     element['name']=="City Admin" ?( element['_id']):(<></>)}))
  
    return (
  
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
  
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Corporate Form
            </Text>
            <SimpleGrid minChildWidth="120px" spacing="40px">
              <FormControl isInvalid={isErrorName}>
                <FormLabel>Name</FormLabel>
                <Input
                  type="text"
                  onChange={(e) => {
                    setIsErrorName(e.target.value === "");
                    setName(e.target.value);
                  }}
                />
                {!isErrorName ? (
                  <></>
                ) : (
                  <FormErrorMessage>Name is required.</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={isErrorEmail}>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  onChange={(e) => {
                    setIsErrorEmail(e.target.value === "" || !emailRegex.test(e.target.value)); +
                      setEmail(e.target.value);
                  }}
                  regex={emailRegex}
                  submitted={submitted}
                />
                {!isErrorEmail ? (
                  <></>
                ) : (
                  <FormErrorMessage>Email is required.</FormErrorMessage>
                )}
              </FormControl>
            </SimpleGrid>
            <SimpleGrid minChildWidth="120px" spacing="40px" mt="24px">
              <FormControl isInvalid={isErrorContactNo}>
                <FormLabel>Primary Phone Number</FormLabel>
                <Input
                  type="number"
                  onChange={(e) => {
                    setIsErrorContactNo(e.target.value === "" || !numberRegex.test(e.target.value));
                    setContactNo(e.target.value);
                  }}
                  regex={numberRegex}
                  submitted={submitted}
                />
                {!isErrorContactNo ? (
                  <></>
                ) : (
                  <FormErrorMessage>Contact No. is required.</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={isErrorSecoundarycontactNo}>
                <FormLabel>Secondary Phone Number</FormLabel>
                <Input
                  type="number"
                  onChange={(e) => {
                    setIsErrorSecoundarycontactNo(e.target.value === "" || !numberRegex.test(e.target.value));
                    setSecoundarycontactNo(e.target.value);
                  }}
                  regex={numberRegex}
                  submitted={submitted}
                />
                {!isErrorSecoundarycontactNo ? (
                  <></>
                ) : (
                  <FormErrorMessage>Secoundary Contact No. is required.</FormErrorMessage>
                )}
              </FormControl>
              
  
            </SimpleGrid>
            <SimpleGrid minChildWidth="120px" spacing="40px" mt="24px">
  
              
  
  
            <FormControl>
              <FormLabel>Plant</FormLabel>
              <Relect
                isMulti
                name="colors"
                placeholder="--select plant--"
                options={plantList.map((item) => ({
                  value: item._id,
                  label: item.plant[0]?.plantName
                }))}
                value={plant}
                onChange={(value) => {
                  setPlant(value)
                  setIsErrorPlant(value === '')
                  console.log(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />

              {isErrorPlant && submitted && (
                <FormErrorMessage>Module is required.</FormErrorMessage>
              )}
            </FormControl>
  
  
  
  
              <FormControl isInvalid={isErrorStatus}>
                <FormLabel>Status</FormLabel>
                <Select
                  placeholder="Select Status"
                  onChange={(e) => {
                    setIsErrorStatus(e.target.value === '')
                    setStatus(e.target.value)
                  }}
                >
                  {dataConstant.status.map((element, index) => {
                    return (<option key={element['id']} value={element['value']} >{element['name']}</option>);
  
                  })}
                </Select>
                {!isErrorStatus ? (
                  <></>
                ) : (
                  <FormErrorMessage>Status is required.</FormErrorMessage>
                )}
              </FormControl>
  
  
  
            </SimpleGrid>
            <SimpleGrid minChildWidth="120px" spacing="40px" mt="24px">
              <FormControl isInvalid={isErrorContactNo}>
                <FormLabel>Start Date</FormLabel>
                <Input
                  type="date"
                  onChange={(e) => {
                    setIsErrorStartDate(e.target.value === "");
                    setStartDate(e.target.value);
                  }}
                />
                {!isErrorContactNo ? (
                  <></>
                ) : (
                  <FormErrorMessage>Start date is required.</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={isErrorSecoundarycontactNo}>
                <FormLabel>End Date</FormLabel>
                <Input
                  type="date"
                  onChange={(e) => {
                    setIsErrorEndDate(e.target.value === "");
                    setEndDate(e.target.value);
                  }}
                />
                {!isErrorSecoundarycontactNo ? (
                  <></>
                ) : (
                  <FormErrorMessage>End date is required.</FormErrorMessage>
                )}
              </FormControl>
              {/* <FormControl isInvalid={isErrorValidation}>
                  <FormLabel>Validation</FormLabel>
                  <Select
                  placeholder="Select Validation"
                  onChange={(e) => {
                    setIsErrorValidation(e.target.value === '')
                    setValidation(e.target.value)
                  }}
                >
                  { dataConstant.validation.map((element,index) => {
                      return (<option key={element['id']} value={element['name']} >{element['name']}</option>);   
                        
                    })}
                </Select>
                  {!isErrorValidation ? (
                    <></>
                  ) : (
                    <FormErrorMessage>Validation is required.</FormErrorMessage>
                  )}
                </FormControl> */}
  
            </SimpleGrid>
  
  
            <Center>
              {loading ? (
                <Spinner mt="24px" size="lg" />
              ) : (
                <Button colorScheme="blue" mt="24px" onClick={handleSubmit}>
                  Submit
                </Button>
              )}
            </Center>
          </CardHeader>
          <CardBody></CardBody>
        </Card>
  
      </Flex>
    );
  }
  
  export default CorporateForm;
  