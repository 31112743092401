import {
    FACTORY_LOADING,
    FACTORY_GET_ALL_SUCCESS,
    FACTORY_ERROR,
    FACTORY_CLEAR_SNACKBAR
  } from '../constants/types';
  
  const initialState = {
    response: {},
    factoryList: [],
    factoryDetail: {},
    loading: false,
    status: 'success',
    text: '',
    showSnackbar: false
  };
  
  const FactoryReducer = (state = initialState, action) => {
    switch (action.type) {
      case FACTORY_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case FACTORY_LOADING:
        return {
          ...state,
          loading: true
        };
      case FACTORY_ERROR:
        return {
          ...state,
          factoryList: [],
          response: {},
          factoryDetail: {},
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case FACTORY_GET_ALL_SUCCESS:
        return {
          ...state,
          factoryList: action.payload.factoryList,
          response: action.payload.response,
          factoryDetail: action.payload.factoryDetail,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      default:
        return state || {};
    }
  };
  
  export default FactoryReducer;