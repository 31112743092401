import { 
    AUTH_LOADING, 
    AUTH_GET_ALL_SUCCESS, 
    AUTH_ERROR,
    AUTH_CLEAR_SNACKBAR
} from '../constants/types';

const initialState = {
    authDetail : {},
    loading: false,
    status: "success",
    text: "",
    showSnackbar: false
};

const AUTHReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_CLEAR_SNACKBAR: 
            return {
                ...state,
                showSnackbar: false
            }
        case AUTH_LOADING: 
            return {
                ...state,
                loading: true,
            }
        case AUTH_ERROR:
            return {
                ...state,
                authDetail : {},
                loading: false,
                status: action.payload.status,
                text: action.payload.text,
                showSnackbar: true
            }
        case AUTH_GET_ALL_SUCCESS:
            return {
                ...state,
                authDetail: action.payload.authDetail,
                loading: false,
                status: action.payload.status,
                text: action.payload.text,
                showSnackbar: true
            }
        default: 
            return state || {};
    }
}

export default AUTHReducer;
