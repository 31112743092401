import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Spinner,
  Image,
  Select
} from "@chakra-ui/react";
import Relect from 'react-select';
import dataConstant from "constants/dataConstant";
import { SOS_CATEGORY_CLEAR_SNACKBAR } from "constants/types";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { server_url } from '../../constants/types';

import { useToast } from '@chakra-ui/react'

import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { sosCategoryAddOrUpdate } from "actions/sosCategoryAction";
import CustomInput from "views/CustomValidation";

function SosCategoryUpdateForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [image, setPhoto] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [sosCategoryId, setSosId] = useState("");
  const [description, setDescription] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");


  const [checkpost, setCheckpost] = useState("");
  const [checkpostList , setCheckpostList]=useState([]); 
  // const [line1, setLine1] = useState("");
  // const [line2, setLine2] = useState("");
  // const [postalCode, setPostalCode] = useState("");
  // const [locality, setLocality] = useState("");
  // const [state, setState] = useState("");
  const [selectedCheckposts, setSelectedCheckposts] = useState([]);
  const [homeModule , setHomeModule] = useState([]);
  const [isErrorName, setIsErrorName] = useState(false);

  const [isErrorDescription, setIsErrorDescription] = useState(false);
  const [isErrorCreatedAt, setIsErrorCreatedAt] = useState(false);
  const [isErrorUpdatedAt, setIsErrorUpdatedAt] = useState(false);





  const [isErrorPhoto, setIsErrorPhoto] = useState(false);
  // const [isErrorNumber, setIsErrorNumber] = useState(false);
  // const [isErrorEmail, setIsErrorEmail] = useState(false);
  // const [isErrorSosId, setIsErrorSosId] = useState(false);
  // const [isErrorCheckpost, setIsErrorCheckpost] = useState(false);
  // const [isErrorPostalcode, setIsErrorPostalcode] = useState(false);
  // const [isErrorLine1, setIsErrorLine1] = useState(false);
  // const [isErrorLine2 , setIsErrorLine2  ] = useState(false);
  // const [isErrorLocality  , setIsErrorLocality] = useState(false);
  // const [isErrorHomeModule , setIsErrorHomeModule] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.sosCategory.status);
  const responseMessage = useSelector(state => state.sosCategory.text);
  const loading = useSelector(state => state.sosCategory.loading);
  const showSnackbar = useSelector(state => state.sosCategory.showSnackbar);
  const sosCategoryDetail = useSelector(state => state.sosCategory.sosCategoryDetail);

  const [isErrorState, setIsErrorState] = useState(false);
  
  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory()
  const params = useParams();
  const IdofsosCategory = params.id;

  
  useEffect(() => {
    
      const sosCategory = {
        _id : IdofsosCategory
      }
      dispatch(sosCategoryAddOrUpdate(sosCategory,'view'));
  }, []);
  
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        
        setPhoto(sosCategoryDetail.image);
        setName(sosCategoryDetail.name);
        setDescription(sosCategoryDetail.description)
        setCreatedAt(sosCategoryDetail.createdAt)
        setUpdatedAt(sosCategoryDetail.updatedAt)

          if(sosCategoryDetail.homeModule){
            setHomeModule(sosCategoryDetail.homeModule.map((item) => ({
              value: item.value,
              label: item.name
              })));
          }else{
            setHomeModule(sosCategoryDetail.homeModule);
          }
      
      
      } 
      
    }

    dispatch({
      type: SOS_CATEGORY_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsErrorName(name === '');
    setIsErrorPhoto(image === '');
    setIsErrorDescription(description === "");
    setIsErrorCreatedAt(createdAt === "")
    setIsErrorUpdatedAt(updatedAt === "")


    if (name !== "" && image !== "" && number !== "" && email !== ""  && sosCategoryId !== "" && homeModule !== "" && selectedCheckposts !== ""  && line1 !== ""  && line2 !== "" && postalCode !== "" && locality !== "" && state !== "" ) {

      const newSos = { 
        _id: IdofsosCategory,
        name,
        file:image,
        number,
        email,
        sosCategoryId,
        'homeModule' : dataConstant.homeModule.map((module) => module.value),
        'checkpost':selectedCheckposts.map((module) => module.value),
        Address: [
          {
            line1,
            line2,
            postalCode,
            locality,
            state,
          }
        
        ],
      };
      console.log(newSos)
      dispatch(sosCategoryAddOrUpdate(newSos , 'edit'));
    }  
   
  };


  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: SOS_CATEGORY_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);


  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };



  const checkpostListforDropdown = ()=>{
    axios
    .get(`${server_url}/plant/checkpost-list`, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      }
    }).then((responce)=>{
      
      setCheckpostList(responce.data.data)
    }).catch(()=> console.log("error"))
  }

  useEffect(()=>{
    checkpostListforDropdown()
  
   
  },[])

  const textRegex = /^[A-Za-z\s]+$/;
  const numberRegex = /^\d{10}$/;
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/;
  const sosCategoryIdRegex = /^[a-zA-Z0-9]+$/;
  const postalCodeRegex = /^[0-9]{6}$/;

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            SOS Category Update Form
          </Text>
          <br/>
          <SimpleGrid minChildWidth='120px' spacing='40px'>
              <FormControl isInvalid={isErrorName || (submitted && name === "")}>
                <CustomInput
                  type='text'
                  label='Name'
                  value={name}
                  onChange={(value) => {
                    setIsErrorName(value === '' || !textRegex.test(value));
                    setName(value);
                  }}
                  isInvalid={isErrorName || (submitted && name === "")}
                  regex={textRegex}
                  submitted={submitted}
                />
              </FormControl>
              <FormControl isInvalid={isErrorPhoto || (submitted && image === "")}>
                <FormLabel>Photo</FormLabel>
                <Input
                  type='file'
                  onChange={(e) => {
                    setIsErrorPhoto(e.target.value === '')
                    setPhoto(e.target.files[0])
                  }}
                />
                {isErrorPhoto && submitted && (
                  <FormErrorMessage>Photo is required.</FormErrorMessage>
                )}
              </FormControl>
            </SimpleGrid>


            <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            <FormControl isInvalid={isErrorDescription || (submitted && name === "")}>
                <CustomInput
                  type='text'
                  label='Description'
                  value={description}
                  onChange={(value) => {
                    setIsErrorName(value === '' || !textRegex.test(value));
                    setDescription(value);
                  }}
                  isInvalid={isErrorDescription || (submitted && name === "")}
                  regex={textRegex}
                  submitted={submitted}
                />
              </FormControl>
              <FormControl isInvalid={isErrorCreatedAt || (submitted && name === "")}>
                <CustomInput
                  type='text'
                  label='Created At'
                  value={createdAt}
                  onChange={(value) => {
                    setIsErrorName(value === '' || !textRegex.test(value));
                    setCreatedAt(value);
                  }}
                  isInvalid={isErrorCreatedAt || (submitted && name === "")}
                  regex={textRegex}
                  submitted={submitted}
                />
              </FormControl>
              <FormControl isInvalid={isErrorUpdatedAt || (submitted && name === "")}>
                <CustomInput
                  type='text'
                  label='Updated At'
                  value={updatedAt}
                  onChange={(value) => {
                    setIsErrorName(value === '' || !textRegex.test(value));
                    setUpdatedAt(value);
                  }}
                  isInvalid={isErrorUpdatedAt || (submitted && name === "")}
                  regex={textRegex}
                  submitted={submitted}
                />
              </FormControl>
              
            </SimpleGrid>

            {/* <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
              <FormControl isInvalid={isErrorLine1 || (submitted && line1 === "")}>
                <CustomInput
                  type='text'
                  label="Line 1"
                  placeholder="Line 1"
                  regex={textRegex}
                  value={line1}
                  onChange={(value) => {
                    setIsErrorLine1(value === '' || !textRegex.test(value));
                    setLine1(value);
                  }}
                  isInvalid={isErrorLine1 || (submitted && line1 === "")}
                  submitted={submitted}
                />
              </FormControl>
              <FormControl isInvalid={isErrorLine2 || (submitted && line2 === "")}>
                <CustomInput
                  type='text'
                  label="Line 2"
                  placeholder="Line 2"
                  regex={textRegex}
                  value={line2}
                  onChange={(value) => {
                    setIsErrorLine2(value === '' || !textRegex.test(value));
                    setLine2(value);
                  }}
                  isInvalid={isErrorLine2 || (submitted && line2 === "")}
                  submitted={submitted}
                />
              </FormControl>
            </SimpleGrid> */}

          {/* ... Additional form fields ... */}
          <Center>
            {loading ? (
              <Spinner mt="24px" size="lg" />
            ) : (
              <Button colorScheme="blue" mt="24px" onClick={handleSubmit}>
                Submit
              </Button>
            )}
          </Center>
        </CardHeader>
        <CardBody>
          {/* ... Additional form content ... */}
        </CardBody>
      </Card>
      <Spacer></Spacer>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px" mt="4">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Image
          </Text>
          <Box boxSize='sm'>
            <Image src={image} alt='Dan Abramov' />
          </Box>
        </CardHeader>
        <CardBody>
          {/* ... Additional content ... */}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default SosCategoryUpdateForm;
