import React from "react";
import {
  Tr,
  Td,
  Badge,
  Flex,
  Text,
  IconButton,
  useColorModeValue,
  ButtonGroup,
  Spacer,
  Link,
  useDisclosure,
  Button
} from "@chakra-ui/react";
import { BsBoxArrowUpRight, BsFillTrashFill } from "react-icons/bs";
import { ViewIcon } from "@chakra-ui/icons";
import { AiFillEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { departmentDelete } from "../../actions/departmentActions";

const DepartmentTableRow = (props) => {
  const {
    name,
    description,
    status,
    adminId,
    isLast,
    serialnumber,
    id,
    plant=""
  } = props;

  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const authDetail = useSelector((state) => state.auth.authDetail);
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const handleDelete = () => {
    dispatch(departmentDelete(id,plant));
  };

  return (
    <>
      <Tr>
      <Td
          >
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={titleColor}
                  fontWeight="bold"
                  minWidth="100%"
                >
                  {serialnumber}
                </Text>
              </Flex>
            </Flex>
          </Td>
        <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {name}
              </Text>
            </Flex>
          </Flex>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {description}
            </Text>
          </Flex>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
            <Badge colorScheme={status == "Active" ? "green" : "red"}>{status}</Badge>
          </Td>

        {/* <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {adminId}
            </Text>
          </Flex>
        </Td> */}

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          {authDetail.role.allPermissions === false ? (
            <ButtonGroup variant="solid" size="sm" spacing={3}>
              {authDetail.role.permissions.map((permission) => {
                const { permissionName, moduleName } = permission;

                if (
                  permissionName === "VIEW" &&
                  moduleName === "DEPARTMENT"
                ) {
                  return (
                    <Link href={`#/admin/department-view/${id}`}>
                      <IconButton
                        key={permission.id}
                        colorScheme="blue"
                        icon={<ViewIcon />}
                        aria-label="View"
                      />
                    </Link>
                  );
                }

                if (
                  permissionName === "EDIT" &&
                  moduleName === "DEPARTMENT"
                ) {
                  return (
                    <Link href={`#/admin/department-update/${id}`}>
                      <IconButton
                        key={permission.id}
                        colorScheme="green"
                        icon={<AiFillEdit />}
                        aria-label="Edit"
                      />
                    </Link>
                  );
                }

                if (
                  permissionName === "DELETE" &&
                  moduleName === "DEPARTMENT"
                ) {
                  return (
                    <IconButton
                      key={permission.id}
                      colorScheme="red"
                      variant="outline"
                      icon={<BsFillTrashFill />}
                      aria-label="Delete"
                      onClick={onOpen}
                    />
                  );
                }

                return null;
              })}
            </ButtonGroup>
          ) : (
            <ButtonGroup>
              <Link href={`#/admin/department-view/${id}`}>
                <IconButton
                  colorScheme="blue"
                  icon={<ViewIcon />}
                  aria-label="View"
                />
              </Link>
              <Link href={`#/admin/department-update/${id}`}>
                <IconButton
                  colorScheme="green"
                  icon={<AiFillEdit />}
                  aria-label="Edit"
                />
              </Link>
              <IconButton
                colorScheme="red"
                variant="outline"
                icon={<BsFillTrashFill />}
                aria-label="Delete"
                onClick={onOpen}
              />
            </ButtonGroup>
          )}
        </Td>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Designation
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={() => { handleDelete(); onClose(); }} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Tr>
    </>
  );
};

export default DepartmentTableRow;
