import axios from 'axios';
import {
    DELIVARYINOUT_LOADING,
    DELIVARYINOUT_GET_ALL_SUCCESS,
    DELIVARYINOUT_ERROR,
    DELIVARYINOUT_CLEAR_SNACKBAR
} from '../constants/types';

import { server_url } from '../constants/types';

export const DelivaryInOutGetAll = (pagination = '') => {
  console.log("yes it is delivary")
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: DELIVARYINOUT_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/delivery?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          
          console.log(response.data.data)
          dispatch({
            type: DELIVARYINOUT_GET_ALL_SUCCESS,
            payload: {
              delivaryInOutList: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              totalItems: response.data.totalItems,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all delivary successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: DELIVARYINOUT_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting delivary  data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};
export const DelivaryTodayGetAll = (pagination) => {
  console.log("yes it is delivary")
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: DELIVARYINOUT_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/admin/todays-delivery?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          
          console.log(response.data.data)
          dispatch({
            type: DELIVARYINOUT_GET_ALL_SUCCESS,
            payload: {
              delivaryTodayList: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all delivary successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: DELIVARYINOUT_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting delivary  data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const DelivaryHeadCount = (pagination) => {
  console.log("yes it is delivary")
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: DELIVARYINOUT_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/admin/delivery-headcount?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          console.log("hyadygadgyiadsyadyuadu77777777777777777777")
          console.log(response.data.data)

          dispatch({
            type: DELIVARYINOUT_GET_ALL_SUCCESS,
            payload: {
              delivaryIN: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all delivary successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: DELIVARYINOUT_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting delivary  data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};


export const delivaryGetById = (delivary) => {
  
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: DELIVARYINOUT_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/delivery-view/${delivary}`)
        .then((responseF) => {
          axios
            .get(`${server_url}/delivery`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
            .then((response) => {
              console.log("yes it is getbyid")
              dispatch({
                type: DELIVARYINOUT_GET_ALL_SUCCESS,
                payload: {
                  delivaryInOutList: response.data.data,
                  response: response.data,
                  delivaryInOutDetail: responseF.data['data'],
                  loading: false,
                  status: responseF.data['status'],
                  text: responseF.data['message']
                }
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: DELIVARYINOUT_ERROR,
                payload: {
                  text: 'Error occurred during getting delivary data.',
                  status: 'error',
                  loading: false
                }
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: DELIVARYINOUT_ERROR,
            payload: {
              text: 'Error occurred during deleting delivary data.',
              status: 'error',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};
