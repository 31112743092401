import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  Spinner,
  Select,
  ButtonGroup,
  Link,
  IconButton
} from "@chakra-ui/react";
import dataConstant from "constants/dataConstant";
import { useDispatch, useSelector } from "react-redux";
import { guardAddOrUpdate } from "../../actions/GaurdAction";
import { checkpostGetAll } from "actions/checkpostActions";
import { deviceGetAll } from "actions/devicesAction";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader";
import { useToast } from '@chakra-ui/react'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react'
import Relect from 'react-select';
import axios from "axios";
import { server_url } from "constants/types";
import { GUARD_CLEAR_SNACKBAR } from "constants/types";
import CustomInput from "views/CustomValidation";
import { useHistory,useLocation } from 'react-router-dom';
import EmployeeDesignation from "views/Employee/EmployeeDesignation";
import { FaUpload } from "react-icons/fa";


function GaurdForm() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [photo, setPhoto] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [guardId, setGuardId] = useState("");
  const [checkpost, setCheckpost] = useState([]);
  const [homeModule, setHomeModule] = useState([]);
  const [bloodGroup, setBloodGroup] = useState("");
  // const [checkpostList, setCheckpostList] = useState([]);
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [locality, setLocality] = useState("");
  const [state, setState] = useState("");
  const [guardDeviceNo, setGuardDeviceNo] = useState([]);

  const [isErrorBloodGroup, setIsErrorBloodGroup] = useState(false);
  const [bloodGroupId, setBloodGroupId] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const history = useHistory();
  const textRegex = /^[A-Za-z\s,0-9]+$/;
  const guardIdRegex = /^[a-zA-Z0-9]+$/;
  const postalCodeRegex = /^[0-9]{0,10}$/;
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/;
  const numberRegex = /^\d{10}$/;
  const [departmentList, setDepartmentList] = useState('')
  const [department, setDepartment] = useState(searchParams.get('departmentId'));
  const [designation, setDesignation] = useState(searchParams.get('designationId'));
  const [subdepartment, setSubdepartment] = useState(searchParams.get('subdepartmentId'));
  const [subdepartmentList, setSubdepartmentList] = useState([])
  const [designationList, setDesignationList] = useState([])
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [employeeType, setEmployeeType] = useState('');
  const [companyName, setCompanyName] = useState("");

  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorPhoto, setIsErrorPhoto] = useState(false);
  const [isErrorNumber, setIsErrorNumber] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isErrorGuardId, setIsErrorGuardId] = useState(false);
  const [isErrorCheckpost, setIsErrorCheckpost] = useState(false);
  const [isErrorPostalcode, setIsErrorPostalcode] = useState(false);
  const [isErrorLine1, setIsErrorLine1] = useState(false);
  const [isErrorLine2, setIsErrorLine2] = useState(false);
  const [isErrorLocality, setIsErrorLocality] = useState(false);
  const [isErrorState, setIsErrorState] = useState(false);
  const [isErrorGuardDeviceNo, setIsErrorGuardDeviceNo] = useState(false);
  const [isErrorHomeModule, setIsErrorHomeModule] = useState(false);
  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.guard.status);
  const responseMessage = useSelector(state => state.guard.text);
  const loading = useSelector(state => state.guard.loading);
  const showSnackbar = useSelector(state => state.guard.showSnackbar);
  const checkpostList = useSelector(state => state.checkpost.checkpostList);
  const deviceList = useSelector(state => state.device.deviceList);
  const [isErrorDepartment, setIsErrorDepartment] = useState(false);
  const [isErrorDesignation, setIsErrorDesignation] = useState(false);
  const [isErrorSubdepartment, setIsErrorSubdepartment] = useState(false);
  const [isErrorStartDate, setIsErrorStartDate] = useState(false);
  const [isErrorEndDate, setIsErrorEndDate] = useState(false);
  const [isErrorEmployeeType, setIsErrorEmployeeType] = useState(false);
  const [isErrorCompanyName, setIsErrorCompanyName] = useState(false);
  console.log("deviceList....");
  console.log(deviceList);

  console.log("checkpostList");
  console.log(checkpostList);

  console.log(loading ? "yes" : "no")

  const dispatch = useDispatch();
  const toast = useToast();

  const handleSubmit = (event) => {
    console.log(event);
    event.preventDefault();
    setSubmitted(true);

    setIsErrorName(name === '' || !textRegex.test(name));
    setIsErrorNumber(number === '');
    

    if (
      name !== "" &&
      number !== ""
      

    ) {

      const newGuard = {
        name,
        file: photo,
        number,
        email,
        guardId,
        bloodGroup: bloodGroup,
        'checkpost': checkpost.map((module) => module.value),
        'guardDeviceNo': guardDeviceNo.map((module) => module.value),
        // homeModule: homeModule.map((module) => module.value),
        department,
        designation,
        subdepartment,
        startDate: startDate,
        endDate: endDate,
        companyName: companyName,
        employeeType: employeeType,
        Address: [
          {
            line1,
            line2,
            postalCode,
            locality,
            state,
          }
        ],
      };
      console.log(newGuard)
      dispatch(guardAddOrUpdate(newGuard, 'add'));

      // history.push("/admin/gaurd-table")
    }
  };


  const departmentListforDropdown = () => {
    axios
      .get(`${server_url}/department`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        setDepartmentList(responce.data.data)
      }).catch(() => console.log("error"))
  }

  const subdepartmentListforDropdown = (depId) => {
    if (depId) {
      axios
        .get(`${server_url}/plant/subdepartment-list/${depId}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          setSubdepartmentList(responce.data.data)
        })
    }
    else {
      setSubdepartmentList([])
    }

  }

  const designationListforDropdown = (subdepId) => {
    if (subdepId) {
      axios
        .get(`${server_url}/plant/designation-list/${subdepId}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          setDesignationList(responce.data.data)
        })
    }
    else {
      setDesignationList([])
    }

  }

  useEffect(() => {
    departmentListforDropdown()

  }, [])

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
         setName("");
         setNumber("");
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: GUARD_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

console.log("isErrorGuardDeviceNo")
console.log(isErrorGuardDeviceNo)
useEffect(() => {
    dispatch(checkpostGetAll("&limit=1000"));
    dispatch(deviceGetAll("&limit=1000"));
  }, [])

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  return (
    <>
    <EmployeeDesignation
    selectedDepartment={department}
    selectedSubdepartment={subdepartment}
    selectedDesignation={designation}
    ></EmployeeDesignation>
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <form onSubmit={handleSubmit}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">

          <CardHeader p="6px 0px 22px 0px">
            
            <Flex minWidth='max-content' alignItems='center' gap='2'>
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Gaurd Form
            </Text>
              <Spacer />
              <ButtonGroup gap='2'>
              <Link href={`#/admin/guard-upload-excelsheet?departmentId=${department}&subdepartmentId=${subdepartment}&designationId=${designation}`}>
              <IconButton
                colorScheme="blue"
                aria-label="Upload Data"
                icon={<FaUpload />}
              />
            </Link>
              </ButtonGroup>
            </Flex>
            
          </CardHeader>
          <CardBody>
          <SimpleGrid minChildWidth='120px' spacing='40px'>
              <FormControl isInvalid={isErrorName || (submitted && name === "")}>
                <CustomInput
                  type='text'
                  label='Guard Name'
                  value={name}
                  required = {true}
                  onChange={(value) => {
                    setIsErrorName(value === '' || !textRegex.test(value));
                    setName(value);
                  }}
                  isInvalid={isErrorName || (submitted && name === "")}
                  regex={textRegex}
                  submitted={submitted}
                />
              </FormControl>
              <FormControl isInvalid={isErrorPhoto }>
                <FormLabel>Photo </FormLabel>
                <Input
                  type='file'
                  onChange={(e) => {
                    setPhoto(e.target.files[0])
                  }}
                />
                {isErrorPhoto && submitted && (
                  <FormErrorMessage>Photo is required.</FormErrorMessage>
                )}
              </FormControl>
            </SimpleGrid>

            <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
              <FormControl isInvalid={isErrorNumber || (submitted && number === "")}>
                <CustomInput
                  type="number"
                  label="Phone Number"
                  value={number}
                  required={true}
                  onChange={(value) => {
                    setIsErrorNumber(value === '' || !numberRegex.test(value));
                    setNumber(value);
                  }}
                  isInvalid={isErrorNumber || (submitted && number === "")}
                  regex={numberRegex}
                  submitted={submitted}
                />
              </FormControl>

              <FormControl isInvalid={isErrorBloodGroup}>
                <FormLabel>Blood Group</FormLabel>
                <Select
                  placeholder="Blood Group"
                  onChange={(e) => {
                    setIsErrorBloodGroup(e.target.value === '');
                    setBloodGroup(e.target.value);

                  }}
                  value={bloodGroup}
                >
                  {dataConstant.bloodGroup.map((item, index) => (
                    <option key={item._id} value={item._id}>{item.name}</option>
                  ))}
                </Select>
                {isErrorBloodGroup && submitted && (
                  <FormErrorMessage>Blood Group is required.</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={isErrorEmail}>
                <CustomInput
                  type="email"
                  label="Email"
                  value={email}
                  onChange={(value) => {
                    // setIsErrorEmail(value === '' || !emailRegex.test(value));
                    setEmail(value);
                  }}
                  isInvalid={isErrorEmail}
                  regex={emailRegex}
                  // submitted={submitted}
                />
              </FormControl>
            </SimpleGrid>


            <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
              {/* <Flex  minChildWidth='120px' spacing='40px' mt='24px'> */}
              
                <>

                  <FormControl isInvalid={isErrorStartDate}>
                    <FormLabel>Start Date </FormLabel>
                    <Input
                      type='date'
                      onChange={(e) => {

                        // setIsErrorStartDate(e.target.value === '')
                        setStartDate(e.target.value)
                      }}
                    />
                    {!isErrorStartDate ? (
                      <></>
                    ) : (
                      <FormErrorMessage>Start Date is required.</FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl isInvalid={isErrorEndDate}>
                    <FormLabel>End Date </FormLabel>
                    <Input
                      type='date'
                      onChange={(e) => {

                        // setIsErrorEndDate(e.target.value === '')
                        setEndDate(e.target.value)
                      }}
                    />
                    {!isErrorEndDate ? (
                      <></>
                    ) : (
                      <FormErrorMessage>End Date is required.</FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl isInvalid={isErrorCompanyName}>
                    <FormLabel>Company Name</FormLabel>
                    <Input
                      type='text'
                      // value={companyName}
                      // onChange={(e) => {
                      //   setIsErrorCompanyName(e.target.value === '' || !textRegex.test(value));
                      //   setCompanyName(e.target.value);
                      // }}
                      onChange={(e) => {

                        // setIsErrorCompanyName(e.target.value === '')
                        setCompanyName(e.target.value)
                      }}
                      regex={textRegex}

                    />
                    {!isErrorCompanyName ? (
                      <></>
                    ) : (
                      <FormErrorMessage>Company Name is required.</FormErrorMessage>
                    )}
                  </FormControl>
                </>
              {/* </Flex> */}

            </SimpleGrid>
            <Center>
              {loading ? <Spinner mt='24px' size='lg' /> :
                <Button colorScheme='blue' mt='24px' type="submit">
                  Submit
                </Button>
              }
            </Center>
          </CardBody>
        </Card>
      </form>
    </Flex>
    </>
    
  );
}

export default GaurdForm;
