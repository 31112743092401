import {
    DELIVARY_LOADING,
    DELIVARY_GET_ALL_SUCCESS,
    DELIVARY_ERROR,
    DELIVARY_CLEAR_SNACKBAR
  } from '../constants/types';
  
  const initialState = {
    response: {},
    delivaryList: [],
    delivaryDetail: {},
    loading: false,
    status: 'success',
    text: '',
    limit: 0,  // Add missing properties
    page: 0,
    totalPages: 0,
    showSnackbar: false,
    totalItems: 0,
  };
  
  const delivaryReducer = (state = initialState, action) => {
    switch (action.type) {
      case DELIVARY_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case DELIVARY_LOADING:
        return {
          ...state,
          loading: true
        };
      case DELIVARY_ERROR:
        return {
          ...state,
          delivaryList: [],
          response: {},
          delivaryDetail: {},
          loading: false,
          limit: 0,  // Add missing properties
          page: 0,
          totalPages: 0,
          totalItems: 0,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case DELIVARY_GET_ALL_SUCCESS:
        return {
          ...state,
          delivaryList: action.payload.delivaryList,
          response: action.payload.response,
          delivaryDetail: action.payload.delivaryDetail,
          limit: action.payload.limit,
          page: action.payload.page,
          totalPages: action.payload.totalPages,
          totalItems: action.payload.totalItems,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      default:
        return state || {};
    }
  };
  
  export default delivaryReducer;