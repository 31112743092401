import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  Spinner,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { driverAddOrUpdate } from "../../actions/DriverAction";
// import { checkpostGetAll } from "actions/checkpostActions";
// import { deviceGetAll } from "actions/devicesAction";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader";
import { useToast } from '@chakra-ui/react'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react'
import Select from 'react-select';
import axios from "axios";
import { server_url } from "constants/types";
import { DRIVER_CLEAR_SNACKBAR } from "constants/types";
import CustomInput from "views/CustomValidation";
import { useHistory,useLocation } from 'react-router-dom';
import EmployeeDesignation from "views/Employee/EmployeeDesignation";

function DriverUploadFile() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [file, setFile] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [driverId, setGuardId] = useState("");
  const [checkpost, setCheckpost] = useState([]);
  // const [checkpostList, setCheckpostList] = useState([]);
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [locality, setLocality] = useState("");
  const [state, setState] = useState("");
  const [driverDeviceNo, setGuardDeviceNo] = useState([]);
  const [departmentId, setDepartmentId] = useState(searchParams.get('departmentId'));
  const [designation, setDesignation] = useState(searchParams.get('designationId'));
  const [subdepartment, setSubdepartment] = useState(searchParams.get('subdepartmentId'));
  const [departmentList, setDepartmentList] = useState('')
  const [subdepartmentList, setSubdepartmentList] = useState([])
  const [designationList, setDesignationList] = useState([])

  const [submitted, setSubmitted] = useState(false);
  const history = useHistory();
  const textRegex = /^[A-Za-z\s]+$/;
  const driverIdRegex = /^[a-zA-Z0-9]+$/;
  const postalCodeRegex = /^[0-9]{6}$/;
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/;
  const numberRegex = /^\d{10}$/;

  const [isErrorFile, setIsErrorFile] = useState(false);


  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.driver.status);
  const responseMessage = useSelector(state => state.driver.text);
  const loading = useSelector(state => state.driver.loading);
  const showSnackbar = useSelector(state => state.driver.showSnackbar);
//   const checkpostList = useSelector(state => state.checkpost.checkpostList);
//   const deviceList = useSelector(state => state.device.deviceList);

  console.log(loading ? "yes" : "no")

  const dispatch = useDispatch();
  const toast = useToast();

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);


    if (
      file !== ""
    ) {

      const newGuard = {
        excelFile: file,
        departmentId:departmentId,
        subdepartmentId : subdepartment,
        designationId:designation
      };
      console.log(newGuard)
      dispatch(driverAddOrUpdate(newGuard, 'upload'));

      history.push("/admin/driver-table")
    }
  };

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
          history.push('#/admin/driver-table');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: DRIVER_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };


//   useEffect(() => {
//     dispatch(checkpostGetAll());
//     dispatch(deviceGetAll());
//   }, [])
  return (
    <>
    <EmployeeDesignation
    selectedDepartment={departmentId}
    selectedSubdepartment={subdepartment}
    selectedDesignation={designation}
    ></EmployeeDesignation>
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <form onSubmit={handleSubmit}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">

          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Driver Bulk upload
            </Text>
            <br/>
            <SimpleGrid minChildWidth='120px' spacing='40px'>

              <FormControl isInvalid={isErrorFile || (submitted && photo === "")}>
               
                <Input
                  type='file'
                  onChange={(e) => {
                    setIsErrorFile(e.target.value === '')
                    setFile(e.target.files[0])
                  }}
                />
                {isErrorFile && submitted && (
                  <FormErrorMessage>File is required.</FormErrorMessage>
                )}
              </FormControl>
            </SimpleGrid>


            <Center>
              {loading ? <Spinner mt='24px' size='lg' /> :
                <Button colorScheme='blue' mt='24px' type="submit">
                  Submit
                </Button>
              }
            </Center>
          </CardHeader>
          <CardBody>
            {/* ... */}
          </CardBody>
        </Card>
      </form>
    </Flex>
    </>
  );
}

export default DriverUploadFile
