import axios from 'axios';
import {
  PERMISSION_LOADING,
  PERMISSION_GET_ALL_SUCCESS,
  PERMISSION_ERROR,
  PERMISSION_CLEAR_SNACKBAR
} from '../constants/types';

import {
  server_url
} from '../constants/types';

export const permissionGetAll = (pagination = "") => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PERMISSION_LOADING,
        payload: {
          loading: true
        }
      });
      axios.get(`${server_url}/permission?${pagination}`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      })
        .then(response => {
          dispatch({
            type: PERMISSION_GET_ALL_SUCCESS,
            payload: {
              permissionList: response.data.data,
              response: response.data,
              loading: false,
              status: "success",
              text: "Get all permission data successfully."
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: PERMISSION_ERROR,
            payload: {
              status: "error",
              text: "Error occurred during getting permission data.",
              loading: false
            }
          });
          resolve();
        })
    })
  }
}

export const permissionAddOrUpdate = (permission, state) => {
  console.log("permission")
  console.log(permission)
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PERMISSION_LOADING,
        payload: {
          loading: true
        }
      });
      if (state === "add") {
        axios.post(`${server_url}/permission`, permission, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
          .then(responseF => {
            axios.get(`${server_url}/permission`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
              .then(response => {
                dispatch({
                  type: PERMISSION_GET_ALL_SUCCESS,
                  payload: {
                    permissionList: response.data.data,
                    response: response.data,
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: PERMISSION_ERROR,
                  payload: {
                    text: "Error occurred during getting permission data.",
                    status: "error",
                    loading: false
                  }
                });
                resolve();
              })
          })
          .catch((e) => {
            dispatch({
              type: PERMISSION_ERROR,
              payload: {
                text: "Error occurred during adding permission data.",
                status: "error",
                loading: false
              }
            });
            resolve();
          })
      } else if (state === "edit") {
        axios.put(`${server_url}/permission/${permission._id}`, permission, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
          .then(responseF => {
            axios.get(`${server_url}/permission`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
              .then(response => {
                dispatch({
                  type: PERMISSION_GET_ALL_SUCCESS,
                  payload: {
                    permissionList: response.data.data,
                    response: response.data,
                    permissionDetail: responseF.data['data'],
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: PERMISSION_ERROR,
                  payload: {
                    text: "Error occurred during getting permission data.",
                    status: "error",
                    loading: false
                  }
                });
                resolve();
              })
          })
          .catch((e) => {
            dispatch({
              type: PERMISSION_ERROR,
              payload: {
                text: "Error occurred during updating permission data.",
                status: "error",
                loading: false
              }
            });
            resolve();
          })
      }
      else if (state === "view") {
        axios.get(`${server_url}/permission/${permission._id}`, permission, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
          .then(responseF => {
            axios.get(`${server_url}/permission`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
              .then(response => {
                dispatch({
                  type: PERMISSION_GET_ALL_SUCCESS,
                  payload: {
                    permissionList: response.data.data,
                    response: response.data,
                    permissionDetail: responseF.data['data'],
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: PERMISSION_ERROR,
                  payload: {
                    text: "Error occurred during getting permission data.",
                    status: "error",
                    loading: false
                  }
                });
                resolve();
              })
          })
          .catch((e) => {
            dispatch({
              type: PERMISSION_ERROR,
              payload: {
                text: "Error occurred during updating permission data.",
                status: "error",
                loading: false
              }
            });
            resolve();
          })
      }
    })
  }
}

export const permissionDelete = (permission) => {
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            dispatch({
                type: PERMISSION_LOADING,
                payload: {
                    loading: true
                }
            });
            axios.delete(`${server_url}/permission/${permission._id}`, {headers: { 
                'Authorization': 'Bearer '+localStorage.getItem('authToken')
              }}).then(responseF => {
                axios.get(`${server_url}/permission`, {headers: { 
                    'Authorization': 'Bearer '+localStorage.getItem('authToken')
                  }}).then(response => {
                    dispatch({
                        type: PERMISSION_GET_ALL_SUCCESS,
                        payload: {
                          permissionList: response.data.data,
                          response: response.data,
                          permissionDetail: responseF.data['data'],
                          loading: false,
                          status: responseF.data['status'],
                          text: responseF.data['message']
                        }
                      });
                    resolve();
                }).catch((e) => {
                    dispatch({
                        type: PERMISSION_ERROR,
                        payload: {
                            text: "Error occured during getting permission data.",
                            status: "error",
                            loading: false
                        }
                    });
                    resolve();
                })
            }).catch((e) => {
                dispatch({
                    type: PERMISSION_ERROR,
                    payload: {
                        text: "Error occured during deleting permission data.",
                        status: "error",
                        loading: false
                    }
                });
                resolve();
            })
        })
    }
}

export const permissionClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: PERMISSION_CLEAR_SNACKBAR
    });
  }
}
