// Chakra Icons
import { BellIcon} from "@chakra-ui/icons";
// Chakra Imports
import {
  Box, Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList, Stack, Text, useColorMode,
  useColorModeValue,
  useToast,
  Link,
  Badge ,
  Center

} from "@chakra-ui/react";
// Assets
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
// Custom Icons
import { ArgonLogoDark, ArgonLogoLight, ChakraLogoDark, ChakraLogoLight, ProfileIcon, SettingsIcon,ArrowBackIcon } from "components/Icons/Icons";
import {ChevronDownIcon} from "@chakra-ui/icons";
// Custom Components
import { ItemContent } from "components/Menu/ItemContent";
import { SearchBar } from "components/Navbars/SearchBar/SearchBar";
import { SidebarResponsive } from "components/Sidebar/Sidebar";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import routes from "routes.js";
import { useHistory } from 'react-router-dom';
import { adminLogout } from "../../actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ADMIN_CLEAR_SNACKBAR ,server_url} from "constants/types";
import dataConstant from "constants/dataConstant";
import axios from 'axios';

export default function HeaderLinks(props) {
  const {
    variant,
    children,
    fixed,
    scrolled,
    secondary,
    onOpen,
    ...rest
  } = props;

  const { colorMode } = useColorMode();
  const history = useHistory();
  const dispatch = useDispatch();
  const toast = useToast();
  const responseStatus = useSelector((state) => state.auth.status);
  const responseMessage = useSelector((state) => state.auth.text);
  const loading = useSelector((state) => state.auth.loading);
  const showSnackbar = useSelector((state) => state.auth.showSnackbar);
  const authDetail = useSelector(state => state.auth.authDetail);
  const [authName,setAuthName]=useState('');
  const [adminName, setAdminName] = useState('');
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationList, setNotificationList] = useState([]);
  const [impersonateMode, setImpersonateMode] = useState(false);
  // const [adminName,setAdminName]=useState('');

  console.log("authDetailllllllll-------");
  console.log(authDetail);

  useEffect(() => {
    
    setAuthName(authDetail?.name);
    setAdminName(authDetail?.role?.name);
    if(localStorage.getItem('impersonateMode'))
      {
        setImpersonateMode(true)
      }

  }, [authDetail]);

   useEffect(() => {
    const events = new EventSource(`${server_url}/admin/live-notification-list?authToken=${localStorage.getItem('authToken')}`);

        events.onmessage = (event) => {
          console.log(event.data);
          const parsedData = JSON.parse(event.data);
          console.log(parsedData.length);
          setNotificationCount(parsedData.length);
          setNotificationList(parsedData);
          console.log("->>>>>>>>>>>>>>>>>>>");
        };
      }, [])

  // Chakra Color Mode
  let navbarIcon =
    fixed && scrolled
      ? useColorModeValue("gray.700", "gray.200")
      : useColorModeValue("white", "gray.200");
  let menuBg = useColorModeValue("white", "navy.800");
  if (secondary) {
    navbarIcon = "white";
  }

  const logoutHandel = () =>
  {
      dispatch(adminLogout());
      localStorage.clear();
      if(adminName == dataConstant.GROUPROLE)
        {
          history.push('/auth/group-signin');
        }
        else
        {
          history.push('/auth/signin');
        }
      
  };
  const stopImpersonateMode = () =>
  {
    const bodyData = {
    };
    axios
      .post(`${server_url}/corporate/impersonate-stop`,bodyData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((response) => {
        if(response.data.status == 'OK')
            {
                var message = response.data.message;
                toastFunction(message,'success');
                localStorage.removeItem('impersonateMode');
                history.push('/#/admin/dashboard');
            }
            else
            {
              var message = response.data.message;
                toastFunction(message,'error');
            }
        
      }).catch(() => console.log("error"))
        
  };

  // useEffect(() => {
  //   if (showSnackbar) {
  //     if (responseStatus === "OK" && loading === false) {
  //       if (responseMessage !== "") {
  //         toastFunction(responseMessage, "success");
  //         localStorage.clear();
  //         history.push('/auth/signin');
  //       }
  //     } else if (responseStatus === "NOK" && loading === false) {
  //       if (responseMessage !== "") {
  //         toastFunction(responseMessage, "error");
  //         localStorage.clear();
  //         history.push('/auth/signin');
  //       }
  //     }
  //   }
  //   dispatch({
  //     type: ADMIN_CLEAR_SNACKBAR,
  //     payload: {
  //       showSnackbar: false,
  //     },
  //   });
  // }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems='center'
      flexDirection='row'>
      {/* <SearchBar me='18px' /> */}
      {impersonateMode?<Button colorScheme='red' size='xs' onClick={()=> {
        stopImpersonateMode();
      }}>Exit Plant Mode</Button> : <></>}
      {adminName != 'Plant Admin'?<></>:<Menu>
          <MenuButton  colorScheme='#3182ce' size='sm' mr={2} as={Button} rightIcon={<ChevronDownIcon />}>
           <Text> Add New</Text>
          </MenuButton>
          <MenuList>
          <Link href="#/admin/DesignationTable"><MenuItem>Employee</MenuItem></Link>
          <Link href="#/admin/DesignationTable"><MenuItem>Guard</MenuItem></Link>
          <Link href="#/admin/DesignationTable"><MenuItem>Driver</MenuItem></Link>
          <Link href="#/admin/bus-route-form"><MenuItem>Routes</MenuItem></Link>
          <Link href="#/admin/emergencycontact-form"> <MenuItem>Emergency Contact</MenuItem></Link>
          </MenuList>
        </Menu>}
        <Button
        // onClick={
        //   logoutHandel
        // }
          ms='0px'
          px='0px'
          me={{ sm: "2px", md: "16px" }}
          color={navbarIcon}
          variant='no-effects'
          rightIcon={
            document.documentElement.dir ? (
              ""
            ) : (
              <ProfileIcon color={navbarIcon} w='40px' h='40px' me='0px' />
            )
          }
          leftIcon={
            document.documentElement.dir ? (
              <ProfileIcon color={navbarIcon} w='22px' h='22px' me='0px' />
            ) : (
              ""
            )
          }>
          <Text  display={{ sm: "none", md: "flex" }}>{authName}</Text>
        </Button>
       

            {/* Menu button */}
      <SidebarResponsive
        hamburgerColor={"white"}
        // logo={
        //   <Stack direction='row' spacing='12px' align='center' justify='center'>
        //     {colorMode === "dark" ? (
        //       <ArgonLogoLight w='74px' h='27px' />
        //     ) : (
        //       <ArgonLogoDark w='74px' h='27px' />
        //     )}
        //     <Box
        //       w='1px'
        //       h='20px'
        //       bg={colorMode === "dark" ? "white" : "gray.700"}
        //     />
        //     {colorMode === "dark" ? (
        //       <ChakraLogoLight w='82px' h='21px' />
        //     ) : (
        //       <ChakraLogoDark w='82px' h='21px' />
        //     )}
        //   </Stack>
        // }
        colorMode={colorMode}
        secondary={props.secondary}
        routes={routes}
        {...rest}
      />

      {/* Setting icons */}
      <SettingsIcon
        cursor='pointer'
        ms={{ base: "16px", xl: "0px" }}
        me='16px'
        onClick={props.onOpen}
        color={navbarIcon}
        w='18px'
        h='18px'
      />

      {/* Bell icon */}
        {adminName != 'Plant Admin'?<></>:<Menu>
          <MenuButton>
            <BellIcon color={navbarIcon} w='18px' h='18px' />
            {/* <CountBadge  /> */}
            <div
            style={{
              position: 'absolute',
              top: '25px',
              right: '45px',
              backgroundColor: 'red',
              borderRadius: '50%',
              width: '20px',
              height: '12px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '10px',
              color: 'white',
            }}
          >
            {notificationCount}
          </div>
          </MenuButton>
          <MenuList p='16px 8px' bg={menuBg}>
            <Flex flexDirection='column'>
             
              {notificationList.length >0 ? (notificationList.map((item, index) => (
                  
                    item.moduleName == 'REPORT'?
                    <Link href={`#/admin/reportIncedent-view/${item.moduleId}`}>
                    <MenuItem borderRadius='8px' mb='10px'>
                        <ItemContent
                        time={`${item.updatedAt}`}
                        info={`${item.createdByName}`}
                        boldInfo={`New ${item.moduleName} by ${item.createdBy}`}
                        aName={`${item.createdByName}`}
                        aSrc="https://ik.imagekit.io/hzrggwyw5/1693392662_sQUMm5fEd"
                        
                      />
                    </MenuItem>
                    </Link>:item.moduleName == 'SOS'?<Link href={`#/admin/sos-view/${item.moduleId}`}>
                    <MenuItem borderRadius='8px' mb='10px'>
                        <ItemContent
                        time={`${item.updatedAt}`}
                        info={`${item.createdByName}`}
                        boldInfo={`New ${item.moduleName} by ${item.createdBy}`}
                        aName={`${item.createdByName}`}
                        aSrc="https://ik.imagekit.io/hzrggwyw5/1703234374_R_Yc7bYdE"
                        
                      />
                    </MenuItem>
                    </Link>:<Link href={`#/admin/iamSafe-view-form/${item.moduleId}`}>
                    <MenuItem borderRadius='8px' mb='10px'>
                        <ItemContent
                        time={`${item.updatedAt}`}
                        info={`${item.createdByName}`}
                        boldInfo={`New ${item.moduleName} by ${item.createdBy}`}
                        aName={`${item.createdByName}`}
                        aSrc="https://ik.imagekit.io/hzrggwyw5/1703248008_lqwVmXkJf"
                      />
                    </MenuItem>
                    </Link>
                ))) : (<><Center className="css-1h6jkt6" >
                No notification
              </Center></>)}
                
            </Flex>
          </MenuList>
        </Menu>}
    </Flex>
  );
}