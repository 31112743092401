import { 
    ADMIN_LOADING, 
    ADMIN_GET_ALL_SUCCESS, 
    ADMIN_ERROR,
    ADMIN_CLEAR_SNACKBAR
} from '../constants/types';

const initialState = {
    adminList: [],
    response: {},
    adminDetail: {},
    loginDetail : {},
    limit:0,
    page: 0,
    totalPages: 0,
    loading: false,
    status: "success",
    text: "",
    showSnackbar: false
};
const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADMIN_CLEAR_SNACKBAR: 
            return {
                ...state,
                showSnackbar: false
            }
        case ADMIN_LOADING: 
            return {
                ...state,
                loading: true,
            }
        case ADMIN_ERROR:
            return {
                ...state,
                adminList: [],
                limit:0,
                page: 0,
                totalPages: 0,
                response: {},
                adminDetail : {},
                loginDetail : {},
                loading: false,
                status: action.payload.status,
                text: action.payload.text,
                showSnackbar: true
            }
        case ADMIN_GET_ALL_SUCCESS:
            return {
                ...state,
                adminList: action.payload.adminList,
                adminDetail: action.payload.adminDetail,
                loginDetail: action.payload.loginDetail,
                limit: action.payload.limit,
                page: action.payload.page,
                totalPages: action.payload.totalPages,
                response : action.payload.response,
                loading: false,
                status: action.payload.status,
                text: action.payload.text,
                showSnackbar: true
            }
        default: 
            return state || {};
    }
}
console.log("state")
console.log(initialState.adminList)

export default adminReducer;
