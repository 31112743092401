// Chakra Imports
import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Flex,
    Link,
    useColorModeValue,
    Text,
    Button,
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    ModalContent,
  } from "@chakra-ui/react";
  import React, { useEffect, useState,useRef } from "react";
  import AdminNavbarLinks from "./AdminNavbarLinks";
  import { useSelector } from "react-redux";
import axios from "axios";
  
  export default function ModalNavbar(props) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isNotification,setIsNotification] = useState(false);
    const [notificationData,setNotificationData] = useState({});
    const buttonRef = useRef(null);
    // useEffect(() => {
    //     const events = new EventSource('http://localhost:3500/api/admin/notification');

    //     events.onmessage = (event) => {
    //     //   console.log(event.data)
    //       const parsedData = JSON.parse(event.data);
    //     //  console.log(parsedData.message);
    //     if(parsedData.message=='Notification from server')
    //     {
    //         console.log("yes.....");
    //         setIsNotification(true);
    //         setNotificationData(parsedData.data);
    //         buttonRef.current.click();
    //     }
        
    //     };
    //   }, [])
    
    return (
        <>
        <Button ref={buttonRef} onClick={onOpen} style={{ display: 'none' }}>Open Modal</Button>
  
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{notificationData.moduleName}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text fontWeight='bold' mb='1rem'>
                Created by {notificationData.createdBy} name {notificationData.createdByName}
              </Text>
              {/* <Lorem count={2} /> */}
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Close
              </Button>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Acknowledge
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  
  }