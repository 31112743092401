import axios from 'axios';
import {
  BUS_LOADING,
  BUS_GET_ALL_SUCCESS,
  BUS_ERROR,
  BUS_CLEAR_SNACKBAR
} from '../constants/types';

import {
  server_url
} from '../constants/types';
import { useEffect } from 'react';

export const busGetAll = (pagination) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: BUS_LOADING,
        payload: {
          loading: true
        }
      });
      axios.get(`${server_url}/bus?${pagination}`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      })
        .then(response => {
            console.log("response.data of buss");
            console.log(response.data.data);
          dispatch({
            type: BUS_GET_ALL_SUCCESS,
            payload: {
              busList: response.data.data,
              response: response.data,
              loading: false,
              status: "success",
              text: "Get all bus data successfully."
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: BUS_ERROR,
            payload: {
              status: "error",
              text: "Error occurred during getting bus data.",
              loading: false
            }
          });
          resolve();
        })
    })
  }
}

export const busAddOrUpdate = (bus, state) => {
    console.log("bus");
    console.log(bus);
    return (dispatch, getState) => {
      return new Promise((resolve) => {
        dispatch({
          type: BUS_LOADING,
          payload: {
            loading: true
          }
        });
        if (state === "add") {
          axios.post(`${server_url}/bus`, bus, {
            headers: {
         
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
            .then(responseF => {
              axios.get(`${server_url}/bus`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
                .then(response => {
                  console.log("response.data.data");
                  console.log(response.data.data);
                  dispatch({
                    type: BUS_GET_ALL_SUCCESS,
                    payload: {
                      busList: response.data.data,
                      response: response.data,
                      loading: false,
                      status: responseF.data['status'],
                      text: responseF.data['message']
                    }
                  });
                  resolve();
                })
                .catch((e) => {
                  dispatch({
                    type: BUS_ERROR,
                    payload: {
                      text: "Error occurred during getting bus data.",
                      status: "error",
                      loading: false
                    }
                  });
                  resolve();
                })
            })
            .catch((e) => {
              dispatch({
                type: BUS_ERROR,
                payload: {
                  text: "Error occurred during adding bus data.",
                  status: "error",
                  loading: false
                }
              });
              resolve();
            })
        } else if (state === "edit") {
          axios.put(`${server_url}/bus/${bus._id}`, bus, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
            .then(responseF => {
              axios.get(`${server_url}/bus`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
                .then(response => {
                  dispatch({
                    type: BUS_GET_ALL_SUCCESS,
                    payload: {
                      busList: response.data.data,
                      response: response.data,
                      busDetail: responseF.data['data'],
                      loading: false,
                      status: responseF.data['status'],
                      text: responseF.data['message']
                    }
                  });
                  resolve();
                })
                .catch((e) => {
                  dispatch({
                    type: BUS_ERROR,
                    payload: {
                      text: "Error occurred during getting bus data.",
                      status: "error",
                      loading: false
                    }
                  });
                  resolve();
                })
            })
            .catch((e) => {
              dispatch({
                type: BUS_ERROR,
                payload: {
                  text: "Error occurred during updating bus data.",
                  status: "error",
                  loading: false
                }
              });
              resolve();
            })
        } else if (state === "view") {
          axios.get(`${server_url}/bus/${bus._id}`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
            .then(responseF => {
              axios.get(`${server_url}/bus`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
                .then(response => {
                  dispatch({
                    type: BUS_GET_ALL_SUCCESS,
                    payload: {
                      busList: response.data.data,
                      response: response.data,
                      busDetail: responseF.data['data'],
                      loading: false,
                      status: responseF.data.status,
                      text: responseF.data.message,
                    }
                  });
                  resolve();
                })
                .catch((e) => {
                  dispatch({
                    type: BUS_ERROR,
                    payload: {
                      text: "Error occurred during getting bus data.",
                      status: "error",
                      loading: false
                    }
                  });
                  resolve();
                })
            })
            .catch((e) => {
              dispatch({
                type: BUS_ERROR,
                payload: {
                  text: "Error occurred during updating bus data.",
                  status: "error",
                  loading: false
                }
              });
              resolve();
            })
        }
      })
    }
  }

export const busDelete = (bus) => {
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            dispatch({
                type: BUS_LOADING,
                payload: {
                    loading: true
                }
            });
            axios.delete(`${server_url}/bus/${bus._id}`, {
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('authToken')
                }
            }).then(responseF => {
                axios.get(`${server_url}/bus`, {
                    headers: {
                        'Authorization': 'Bearer '+localStorage.getItem('authToken')
                    }
                }).then(response => {
                    dispatch({
                        type: BUS_GET_ALL_SUCCESS,
                        payload: {
                          busList: response.data.data,
                          response: response.data,
                          busDetail: responseF.data['data'],
                          loading: false,
                          status: responseF.data['status'],
                          text: responseF.data['message']
                        }
                      });
                    resolve();
                }).catch((e) => {
                    dispatch({
                        type: BUS_ERROR,
                        payload: {
                            text: "Error occurred during getting bus data.",
                            status: "error",
                            loading: false
                        }
                    });
                    resolve();
                })
            }).catch((e) => {
                dispatch({
                    type: BUS_ERROR,
                    payload: {
                        text: "Error occurred during deleting bus data.",
                        status: "error",
                        loading: false
                    }
                });
                resolve();
            })
        })
    }
}

export const busClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: BUS_CLEAR_SNACKBAR
    });
  }
}
