import axios from "axios";
import {
  MATERIAL_LOADING,
  MATERIAL_GET_ALL_SUCCESS,
  MATERIAL_ERROR,
  MATERIAL_CLEAR_SNACKBAR,
} from "../constants/types";

import { server_url } from "../constants/types";

export const materialGetAll = (pagination = "") => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: MATERIAL_LOADING,
        payload: {
          loading: true,
        },
      });
      axios
        .get(`${server_url}/material?${pagination}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        })
        .then((response) => {
          dispatch({
            type: MATERIAL_GET_ALL_SUCCESS,
            payload: {
              materialList: response.data.data,
              response: response.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              totalItems: response.data.totalItems,
              logs: [],
              material: [],
              loading: false,
              status: 'success',
              text: "Get all banner position successfully",
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: MATERIAL_ERROR,
            payload: {
              status: "error",
              text: "Error occurred during getting material data.",
              loading: false,
            },
          });
          resolve();
        });
    }
    
    );
  };
};

export const materialGetHeadCount = (pagination) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: MATERIAL_LOADING,
        payload: {
          loading: true,
        },
      });
      axios
        .get(`${server_url}/admin/material-headcount?${pagination}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        })
        .then((response) => {
          console.log("materialList");
          console.log(response.data.data);
          dispatch({
            type: MATERIAL_GET_ALL_SUCCESS,
            payload: {
              materialListIN: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              logs: [],
              material: [],
              loading: false,
              status: 'success',
              text: "Get all material In successfully",
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: MATERIAL_ERROR,
            payload: {
              status: "error",
              text: "Error occurred during getting material data.",
              loading: false,
            },
          });
          resolve();
        });
    }
    
    );
  };
};


export const materialAddOrUpdate = (material, state , page='' , limit='') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: MATERIAL_LOADING,
        payload: {
          loading: true,
        },
      });
      if (state === "add") {
        axios
          .post(`${server_url}/material`, material, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/material?limit=${limit}&page=${page}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("authToken"),
                },
              })
              .then((response) => {
                dispatch({
                  type: MATERIAL_GET_ALL_SUCCESS,
                  payload: {
                    materialList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: MATERIAL_ERROR,
                  payload: {
                    text: "Error occurred during getting material data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: MATERIAL_ERROR,
              payload: {
                text: "Error occurred during adding material data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      } else if (state === "edit") {
        axios
          .put(`${server_url}/material/${material._id}`, material, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/material?limit=${limit}&page=${page}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("authToken"),
                },
              })
              .then((response) => {
                console.log("materialDetail)))))))))))");
                console.log(responseF.data["data"]);
                dispatch({
                  type: MATERIAL_GET_ALL_SUCCESS,
                  payload: {
                    materialList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    materialDetail: responseF.data["data"],
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: MATERIAL_ERROR,
                  payload: {
                    text: "Error occurred during getting patrolling route data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: MATERIAL_ERROR,
              payload: {
                text: "Error occurred during updating material data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      } else if (state === "view") {
        axios
          .get(`${server_url}/material/${material._id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken")
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/material?limit=${limit}&page=${page}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("authToken")
                },
              })
              .then((response) => {
                dispatch({
                  type: MATERIAL_GET_ALL_SUCCESS,
                  payload: {
                    materialList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    materialDetail: responseF.data["data"],
                    logs: responseF.data["data"]["logs"],
                    material: responseF.data["data"]["material"],
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: MATERIAL_ERROR,
                  payload: {
                    text: "Error occurred during getting material data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: MATERIAL_ERROR,
              payload: {
                text: "Error occurred during updating material data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      }
    });
  };
};

export const materialDelete = (materialId , limit='' , page='') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: MATERIAL_LOADING,
        payload: {
          loading: true,
        },
      });
      axios
        .delete(`${server_url}/material/${materialId}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        })
        .then((responseF) => {
          axios
            .get(`${server_url}/material?limit=${limit}&page=${page}`, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("authToken"),
              },
            })
            .then((response) => {
              dispatch({
                type: MATERIAL_GET_ALL_SUCCESS,
                payload: {
                  materialList: response.data.data,
                  limit: response.data.limit,
                  page: response.data.currentPage,
                  totalPages: response.data.totalPages,
                  response: response.data,
                  materialDetail: responseF.data["data"],
                  loading: false,
                  status: responseF.data["status"],
                  text: responseF.data["message"],
                },
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: MATERIAL_ERROR,
                payload: {
                  text: "Error occurred during getting patrolling route data.",
                  status: "error",
                  loading: false,
                },
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: MATERIAL_ERROR,
            payload: {
              text: "Error occurred during deleting material data.",
              status: "error",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};

export const materialClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: MATERIAL_CLEAR_SNACKBAR,
    });
  };
};

