import React from "react";
import {
  chakra,
  Badge,
  Td,
  Tr,
  Text,
  useColorModeValue,
  Flex,
  ButtonGroup,
  IconButton,
  Link,
  Image,
} from "@chakra-ui/react";
import { ViewIcon,CheckIcon,CloseIcon } from "@chakra-ui/icons";
import { id } from "date-fns/locale";

const AllTableRow = (props) => {
  const {
    serialnumber,
    date,
    time,
    schedule,
    name,
    status,
    inspectedBy,
    totalChecklist,
    inspected,
    missed,
    remark,
    image,
    isLast,
    id,
    approvedBy
  } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <>
      <Tr>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {date}
              </Text>
            </Flex>
          </Flex>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {time}
              </Text>
            </Flex>
          </Flex>
        </Td>


        

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {schedule}
            </Text>
          </Flex>
        </Td>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {name}
            </Text>
          </Flex>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {status}
              </Text>
            </Flex>
          </Flex>
        </Td>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {inspectedBy}
            </Text>
          </Flex>
        </Td>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {totalChecklist}
            </Text>
          </Flex>
        </Td>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            {
                inspected != "" && inspected != null ? (inspected.map((row, index, arr) => {

                    if(row.status)
                        {
                            return (
                                <Text fontSize="md" color={textColor} fontWeight="bold">
                                    <CheckIcon color="green"></CheckIcon> {row?.title}
                                    </Text>
                              );
                        }
                        else
                        {
                            return (
                                <></>
                              );
                        }
                  })) : <Text fontSize="md" color={textColor} fontWeight="bold">
                  {inspected}
                </Text>
            }
            
          </Flex>
        </Td>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
          {
                missed != "" && missed != null ? (missed.map((row, index, arr) => {

                    if(!row.status)
                        {
                            return (
                                <Text fontSize="md" color={textColor} fontWeight="bold">
                                    <CloseIcon color="red"></CloseIcon>{row?.title}
                                    </Text>
                              );
                        }
                        else
                        {
                            return (
                                <></>
                              );
                        }
                  })) : <Text fontSize="md" color={textColor} fontWeight="bold">
                  {missed}
                </Text>
            }
          </Flex>
        </Td>



        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {remark}
            </Text>
          </Flex>
        </Td>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {(image == "" || image == null)  ?<></>:<Image
                boxSize="70px"
                objectFit="cover"
                src={image}
                borderRadius="50%"
                alt="Image"
                _hover={{ transform: "scale(2)" }}
                transition="transform 0.5s ease"
                cursor="pointer"
              />}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <ButtonGroup>
            <Link href={`#/admin/visitor-view/${id}`}>
              <IconButton
              colorScheme="blue"
                icon={<ViewIcon />}
                aria-label="Up"
              />
            </Link>

            
          </ButtonGroup>
        </Td>
      </Tr>
    </>
  );
};

export default AllTableRow;
