// Chakra imports
import {
    Box,
    Button,
    Center,
    Flex,
    Input,
    SimpleGrid,
    Spacer,
    Text,
    Wrap,
    WrapItem,
    useColorModeValue,
    Select,
    Image,
    Stack,
    Skeleton
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from 'react-redux';
  import { employeemoduleClearShowSnackbar, employeemoduleAddOrUpdate } from '../../actions/employeeModuleAction';
  import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
  } from '@chakra-ui/react'
  import { useToast } from '@chakra-ui/react'
  import { EMPLOYEEMODULE_CLEAR_SNACKBAR } from "constants/types";
  import { Spinner } from '@chakra-ui/react'
  import { useParams } from "react-router-dom/cjs/react-router-dom.min";
  import dataConstant from "constants/dataConstant";
  import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { server_url } from '../../constants/types';
import {
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  Th,
  TableContainer,
} from '@chakra-ui/react'
  
  function EmployeemoduleUpdateForm() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [name, setName] = useState("");
    const [image, setImage] = useState("");
    const [status, setStatus] = useState("");
    const [description, setDescription] = useState("");
    const [alertMessage, setAlertMessage] = useState('');
    const [isErrorName, setIsErrorName] = useState(false);
    const [isErrorImage, setIsErrorImage] = useState(false);
    const [isErrorStatus, setIsErrorStatus] = useState(false);
    const [isErrorDescription, setIsErrorDescription] = useState(false);
    const [subModule, setSubModule] = useState([]);
    const dispatch = useDispatch();
    const toast = useToast();
    const history = useHistory()
    const statuses = ['success', 'error', 'warning', 'info'];
    const responseStatus = useSelector(state => state.employeemodule.status);
    const responseMessage = useSelector(state => state.employeemodule.text);
    const loading = useSelector(state => state.employeemodule.loading);
    const showSnackbar = useSelector(state => state.employeemodule.showSnackbar);
    const employeemoduleDetail = useSelector(state => state.employeemodule.employeemoduleDetail);
  
    const selectedEmployeemodule = useSelector(state => state.employeemodule.selectedEmployeemodule); // Assuming you have a selectedEmployeemodule state in your Redux store
    const params = useParams();
    const employeemoduleId = params.id;
    console.log("employeemoduleId");
    console.log(employeemoduleDetail);
    useEffect(() => {
      
      const employeemodule = {
        _id : employeemoduleId
      };
      dispatch(employeemoduleAddOrUpdate(employeemodule,'edit'));
    }, []);
  
    // Function to handle form submission
    const handleSubmit = (event) => {
      event.preventDefault();
      setIsErrorName(name === '');
      setIsErrorImage(image === '');
      setIsErrorStatus(status === '');
      setIsErrorDescription(description === '');
  
      if (name !== "" && image !== "" && status !== "" && description !== "") {
        console.log("Submit");
        // Get form data
        const employeemoduleData = {
          _id: employeemoduleId, // Assuming your employeemodule model has an id field
          name: name,
          image: image,
          status: status,
          description: description,
          // Other employeemodule data fields
        };
        console.log("employeemoduleData");
        console.log(employeemoduleData);
  
        // Call the employeemoduleUpdate action
        dispatch(employeemoduleAddOrUpdate(employeemoduleData,'edit'));
      }
    };
    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
    }, [])

    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === 'OK' && loading === false) {
          console.log(employeemoduleDetail);
          setName(employeemoduleDetail.name);
          setImage(employeemoduleDetail.image);
          setStatus(employeemoduleDetail.status);
          setDescription(employeemoduleDetail.description);
          setSubModule(employeemoduleDetail.subModule);
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'success');
          }
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'error');
          }
        }
      }
  
      dispatch({
        type: EMPLOYEEMODULE_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        }
      })
    }, [loading]);
  
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      })
    };
  
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Employee Module Update Form
            </Text>
            <br/>
            <SimpleGrid minChildWidth='120px' spacing='40px'>
              <FormControl isInvalid={isErrorName}>
                <FormLabel>Name</FormLabel>
                <Input
                  type='text'
                  value={name}
                  onChange={(e) => {
                    setIsErrorName(e.target.value === '')
                    setName(e.target.value)
                  }}
                />
                {!isErrorName ? (
                  <></>
                ) : (
                  <FormErrorMessage>Name is required.</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={isErrorImage}>
                <FormLabel>Image</FormLabel>
                <Input
                  type='file'
                  onChange={(e) => {
                    setIsErrorImage(e.target.value === '')
                    setImage(e.target.files[0])
                  }}
                />
                {!isErrorImage ? (
                  <></>
                ) : (
                  <FormErrorMessage>Image is required.</FormErrorMessage>
                )}
              </FormControl>
            </SimpleGrid>
            <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
              <FormControl isInvalid={isErrorStatus}>
                <FormLabel>Status</FormLabel>
                <Select
                  placeholder="Select status"
                  value={status}
                  onChange={(e) => {
                    setIsErrorStatus(e.target.value === '')
                    setStatus(e.target.value)
                  }}
                >
                  { dataConstant.status.map((element,index) => {
                      return (<option key={element['id']} value={element['value']} >{element['name']}</option>);   
                        
                    })}
                </Select>
                {!isErrorStatus ? (
                  <></>
                ) : (
                  <FormErrorMessage>Status is required.</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={isErrorDescription}>
                <FormLabel>Description</FormLabel>
                <Input
                  type='text'
                  value={description}
                  onChange={(e) => {
                    setIsErrorDescription(e.target.value === '')
                    setDescription(e.target.value)
                  }}
                />
                {!isErrorDescription ? (
                  <></>
                ) : (
                  <FormErrorMessage>Description is required.</FormErrorMessage>
                )}
              </FormControl>
            </SimpleGrid>
            <Center>
              {loading ? <Spinner mt='24px' size='lg' /> :
                <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                  Submit
                </Button>
              }
            </Center>
          </CardHeader>
          <CardBody>
          </CardBody>
        </Card>
        <Spacer></Spacer>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px" mt="4">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Employee Module
            </Text>
            <Box boxSize='sm'>
              <Image src={image} alt='Employee' />
            </Box>
          </CardHeader>
          <CardBody>
          </CardBody>
        </Card>
        <Spacer></Spacer>
        {/* Sub Modules */}
      
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="10px" mt="4">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
            Sub Module
            </Text>
          </CardHeader>
          <CardBody>
         {loading ? (
            <Stack>
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
            </Stack>
          ) :  (
            <TableContainer >
                <Table variant='simple'>
                  <Thead>
                    <Tr>
                      <Th color="white">SL</Th>
                      <Th color="white">Name</Th>
                      <Th color="white">Description</Th>
                      <Th color="white">Image</Th>
                      <Th color="white">status</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                   
                    {
                      subModule.map((row, index, arr) => {

                        return (
                         
                           <Tr>
                            <Td>{index +1}</Td>
                            <Td>{row.name}</Td>
                            <Td>{row.description}</Td>
                            <Td><Image
                                  boxSize='70px'
                                  objectFit='cover'
                                  src={row.image}
                                  borderRadius='50%'
                                  alt={name}
                                  _hover={{ transform: 'scale(2)' }} 
                                  transition='transform 0.5s ease'
                                  cursor = 'pointer'
                            /></Td>
                            <Td>{row.status?'true':'false'}</Td>
                          </Tr>
                        );
                      })}
                      
                  
                  
                    
                  
                  </Tbody>
                </Table>
                </TableContainer>
          )}
        </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default EmployeemoduleUpdateForm;
  