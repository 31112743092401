import {
    EMPLOYEEMODULE_LOADING,
    EMPLOYEEMODULE_GET_ALL_SUCCESS,
    EMPLOYEEMODULE_ERROR,
    EMPLOYEEMODULE_CLEAR_SNACKBAR
  } from '../constants/types';
  
  const initialState = {
    response: {},
    employeemoduleList: [],
    employeemoduleDetail: {},
    loading: false,
    status: 'success',
    text: '',
    showSnackbar: false
  };
  
  const employeemoduleReducer = (state = initialState, action) => {
    switch (action.type) {
      case EMPLOYEEMODULE_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case EMPLOYEEMODULE_LOADING:
        return {
          ...state,
          loading: true
        };
      case EMPLOYEEMODULE_ERROR:
        return {
          ...state,
          employeemoduleList: [],
          response: {},
          employeemoduleDetail: {},
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case EMPLOYEEMODULE_GET_ALL_SUCCESS:
        return {
          ...state,
          employeemoduleList: action.payload.employeemoduleList,
          response: action.payload.response,
          employeemoduleDetail: action.payload.employeemoduleDetail,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      default:
        return state || {};
    }
  };
  
  export default employeemoduleReducer;
  