import React from "react";
import {
  Avatar,
  Badge,
  Button,
  Flex,
  Link,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure,
  ButtonGroup,
  IconButton,
  Image,
} from "@chakra-ui/react";
import { BsFillTrashFill } from "react-icons/bs";
import { ViewIcon } from "@chakra-ui/icons";
import { AiFillEdit } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { bannerDelete } from "../../actions/bannerActions";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";

function BannersTableRow(props) {
  const { name, image, status, position,bannerPosition, serialnumber , id,isLast } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const dispatch = useDispatch();

  const deleteBanner = () => {
    const banner = {
      _id: id,
    };
    dispatch(bannerDelete(banner));
  };

  return (
    <>
      <Tr>
      <Td
          >
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={titleColor}
                  fontWeight="bold"
                  minWidth="100%"
                >
                  {serialnumber}
                </Text>
              </Flex>
            </Flex>
          </Td>
        <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {name}
              </Text>
            </Flex>
          </Flex>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
            <Image
                boxSize='70px'
                objectFit='cover'
                src={image}
                borderRadius='50%'
                alt='Dan Abramov'
                _hover={{ transform: 'scale(2)' }} 
                transition='transform 0.5s ease'
                cursor = 'pointer'
          />
              
            </Text>
          </Flex>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
            <Badge colorScheme={status=="Active" ? "green" : "red"}>{status}</Badge>
          </Td>

        <Td
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {position}
            </Text>
          </Flex>
        </Td>
       
        <Td
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {bannerPosition ?? 'NA'}
            </Text>
          </Flex>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <ButtonGroup>
            <Link href={`#/admin/banner-view/${id}`}>
              <IconButton
                colorScheme="blue"
                icon={<ViewIcon />}
                aria-label="Up"
              />
            </Link>

            <Link href={`#/admin/banner-update/form/${id}`}>
              <IconButton
                colorScheme="green"
                icon={<AiFillEdit />}
                aria-label="Edit"
              />
            </Link>

            <IconButton
              colorScheme="red"
              variant="outline"
              icon={<BsFillTrashFill />}
              aria-label="Delete"
              onClick={onOpen}
            />
          </ButtonGroup>
        </Td>
      </Tr>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Banner
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={()=>{deleteBanner() , onClose()}} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default BannersTableRow;