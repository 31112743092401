import {
    CALL_SETTING_LOADING,
    CALL_SETTING_GET_ALL_SUCCESS,
    CALL_SETTING_ERROR,
    CALL_SETTING_CLEAR_SNACKBAR
  } from '../constants/types';
  
  const initialState = {
    response: {},
    settingList: [],
    settingDetail: {},
    highDetail : {},
    loading: true,
    status: "success",
    text: "",
    limit: 0,  // Add missing properties
    page: 0,
    totalPages: 0,
    showSnackbar: false
  };
  
  const callSettingReducer = (state = initialState, action) => {
    switch (action.type) {
      case CALL_SETTING_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case CALL_SETTING_LOADING:
        return {
          ...state,
          loading: true,
        };
      case CALL_SETTING_ERROR:
        return {
          ...state,
          settingList: [],
          response: {},
          settingDetail: {},
          highDetail : {},
          loading: false,
          limit: 0,  // Add missing properties
          page: 0,
          totalPages: 0,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case CALL_SETTING_GET_ALL_SUCCESS:
        return {
          ...state,
          settingList: action.payload.settingList,
          response: action.payload.response,
          settingDetail: action.payload.settingDetail,
          highDetail: action.payload.highDetail,
          loading: false,
          limit: action.payload.limit,
          page: action.payload.page,
          totalPages: action.payload.totalPages,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      default:
        return state || {};
    }
  };
  
  export default callSettingReducer;
  