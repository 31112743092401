import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  Select,
  Wrap,
  WrapItem,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { busAddOrUpdate, busClearShowSnackbar } from "../../actions/busAction";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { BUS_CLEAR_SNACKBAR } from "constants/types";
import { Spinner } from "@chakra-ui/react";
import dataConstant from "constants/dataConstant";
import axios from "axios";
import { server_url } from "constants/types";
import CustomInput from "views/CustomValidation";
import { useHistory } from 'react-router-dom';
import { driverGetAll } from '../../actions/DriverAction';
// import { server_url } from '../../constants/types';
// import axios from 'axios';


function BusForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [busNo, setBusNo] = useState("");
  const [routeNo, setRouteNo] = useState("");
  const [list, setDriverList] = useState("");
  const [transport, setTransport] = useState("");

  const [routnoList, setRoutnoList] = useState([]);
  const [sittingCapacity, setSittingCapacity] = useState("");
  const [insuranceValidityDate, setInsuranceValidityDate] = useState("");
  const [isErrorBusNo, setIsErrorBusNo] = useState(false);
  const [isErrorRouteNo, setIsErrorRouteNo] = useState(false);
  const [isErrorSittingCapacity, setIsErrorSittingCapacity] = useState(false);
  const [isErrorInsuranceValidityDate, setIsErrorInsuranceValidityDate] = useState(false);
  const [isErrorTransport, setIsErrorTransport] = useState(false);
  const [isErrorDriver, setIsErrorDriver] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const history = useHistory();
  const busnoRegex = /^\d{4}$/;
  const numberRegex = /^[0-9]{0,3}$/;
  const busnumberRegex = /^\d{4}$/;

  const dispatch = useDispatch();
  const toast = useToast();
  console.log("routnoList#######");
  console.log(routnoList);
  const responseStatus = useSelector((state) => state.bus.status);
  const responseMessage = useSelector((state) => state.bus.text);
  const loading = useSelector((state) => state.bus.loading);
  const showSnackbar = useSelector((state) => state.bus.showSnackbar);
  const driverList = useSelector(state => state.driver.driverList);
  

  useEffect(() => {
    dispatch(driverGetAll())
  }, [dispatch])

  console.log(routeNo);
  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsErrorBusNo(busNo === "");
    // setIsErrorRouteNo(routeNo === "");
    // setIsErrorSittingCapacity(sittingCapacity === "");
    // setIsErrorInsuranceValidityDate(insuranceValidityDate === "");
    setIsErrorTransport(transport === '');
    // setIsErrorDriver(list === '');

    if (
      busNo !== "" &&
      // routeNo !== "" &&
      // sittingCapacity !== "" &&
      // insuranceValidityDate !== "" &&
      // list !== "" &&
      transport !== ""
    ) {
      console.log("Submit");
      const busData = {
        busNo: busNo,
        // route: routeNo,
        sittingCapacity: sittingCapacity,
        insuranceValidityDate: insuranceValidityDate,
        // driver: list,
        type: transport,
        // Other checkpost data fields
      };
      if(routeNo)
      {
        busData.routeNo = routeNo;
      }
      if(list)
      {
        busData.driver = list;
      }
      // Determine if it's an add or edit operation
      const state = "add";
      dispatch(busAddOrUpdate(busData, state));
    }
    // DriverListforDropdown()
    // history.push('/admin/bus-table')
  };

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === "OK") {
        if (responseMessage !== "") {
          toastFunction(responseMessage, "success");
          history.goBack();
        }
      } else if (responseStatus === "NOK") {
        if (responseMessage !== "") {
          toastFunction(responseMessage, "error");
        }
      }
    }
    dispatch(busClearShowSnackbar());
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  const RoutenoListforDropdown = () => {
    axios
      .get(`${server_url}/routes`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        setRoutnoList(responce.data.data)
      }).catch(() => console.log("error"))
  }
  // const DriverListforDropdown = () => {
  //   axios
  //     .get(`${server_url}/AvailableDriver`, {
  //       headers: {
  //         'Authorization': 'Bearer ' + localStorage.getItem('authToken')
  //       }
  //     }).then((responce) => {
  //       console.log(responce.data.data)
  //       setDriverList(responce.data.data)
  //     }).catch(() => console.log("error"))
  // }

  useEffect(() => {
    RoutenoListforDropdown()
    // DriverListforDropdown()
  }, [])
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Form
          </Text>
          <SimpleGrid minChildWidth="120px" spacing="40px">
            <FormControl isInvalid={isErrorBusNo || (submitted && busNo === "")}>

              <CustomInput
                required ={true}
                type="text"
                label="Bus No"
                value={busNo}
                onChange={(value) => {
                  setIsErrorBusNo(value === "");
                  setBusNo(value.toUpperCase());
                }}
                submitted={submitted}
                
              />
            </FormControl>

            <FormControl isInvalid={isErrorInsuranceValidityDate}>
              <FormLabel>Insurance Validity Date</FormLabel>
              <Input
                type='Date'
                value={insuranceValidityDate}
                onChange={(e) => {
                  // setIsErrorInsuranceValidityDate(e.target.value === '')
                  setInsuranceValidityDate(e.target.value)
                }}
              />
              {!isErrorInsuranceValidityDate ? (
                <></>
              ) : (
                <FormErrorMessage>
                  Insurance Validity Date is required.
                </FormErrorMessage>
              )}
            </FormControl>


            {/* <FormControl isInvalid={isErrorRouteNo}>
              <FormLabel>Route No</FormLabel>
              <Select
                placeholder="-- select Route No --"

                onChange={(e) => {
                  setIsErrorRouteNo(e.target.value === '');
                  setRouteNo(e.target.value);
                }}

              >
                {routnoList ? (routnoList.map((item, index) => (
                  <option key={item._id} value={item._id}>{item.name}</option>
                ))) : (<></>)}
              </Select>
              {isErrorRouteNo && submitted && (
                <FormErrorMessage>Route No is required.</FormErrorMessage>
              )}
            </FormControl> */}
          </SimpleGrid>
          <SimpleGrid minChildWidth="120px" spacing="40px" mt="24px">
            {/* <FormControl isInvalid={isErrorSittingCapacity || (submitted && sittingCapacity === "")}>

              <CustomInput
                type="number"
                label="Sitting Capacity"
                value={sittingCapacity}
                onChange={(value) => {
                  // setIsErrorSittingCapacity(value === "" || !numberRegex.test(value));
                  setSittingCapacity(value);
                }}
                regex={numberRegex}
                // submitted={submitted}
              />
            </FormControl> */}
            
          </SimpleGrid>
          <SimpleGrid minChildWidth="120px" spacing="40px">
            {/* <FormControl isInvalid={isErrorDriver}>
              <FormLabel>Driver</FormLabel>
              <Select
                placeholder="-- select Driver --"

                onChange={(e) => {
                  setIsErrorDriver(e.target.value === '');
                  setDriverList(e.target.value);
                }}

              >
                {driverList ? (driverList.map((item, index) => (
                  <option key={item._id} value={item._id}>{item.Name}</option>
                ))) : (<></>)}
              </Select>
              {isErrorDriver && submitted && (
                <FormErrorMessage>Driver is required.</FormErrorMessage>
              )}
            </FormControl> */}

            <FormControl isInvalid={isErrorTransport}>
              <FormLabel>Transport Type<span style={{ color: 'red' }}>*</span></FormLabel>
              <Select
                placeholder="-- select Transport --"

                onChange={(e) => {
                  setIsErrorTransport(e.target.value === '');
                  setTransport(e.target.value);
                }}

              >
                {dataConstant.transportType.map((element, index) => {
                  return (<option key={element['id']} value={element['name']} >{element['name']}</option>);

                })}

              </Select>
              {isErrorTransport && submitted && (
                <FormErrorMessage>Transport type is required.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl></FormControl>
          </SimpleGrid>
          <Center>
            {loading ? (
              <Spinner mt="24px" size="lg" />
            ) : (
              <Button colorScheme="blue" mt="24px" onClick={handleSubmit}>
                Submit
              </Button>
            )}
          </Center>
        </CardHeader>
        <CardBody></CardBody>
      </Card>
    </Flex>
  );
}

export default BusForm;
