// Import the action type constants for patrollingRoute
import {
  PATROLLING_ROUTE_CLEAR_SNACKBAR,
  PATROLLING_ROUTE_LOADING,
  PATROLLING_ROUTE_ERROR,
  PATROLLING_ROUTE_GET_ALL_SUCCESS,
  PATROLLING_ROUTE_SEARCH  // Add the new action type for search if needed
} from '../constants/types';

const initialState = {
  response: {},
  patrollingRouteList: [],
  patrollingRouteDetail: {},
  loading: false,
  status: 'success',
  text: '',
  showSnackbar: false,
  limit: 0,  // Add missing properties
  page: 0,
  totalPages: 0,
  searching: ""  // Add missing properties
};

const patrollingRouteReducer = (state = initialState, action) => {
  switch (action.type) {
    case PATROLLING_ROUTE_CLEAR_SNACKBAR:
      return {
        ...state,
        showSnackbar: false,
      };
    case PATROLLING_ROUTE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PATROLLING_ROUTE_ERROR:
      return {
        ...state,
        patrollingRouteList: [],
        response: {},
        patrollingRouteDetail: {},
        limit: 0,  // Add missing properties
        page: 0,
        totalPages: 0,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };
    case PATROLLING_ROUTE_GET_ALL_SUCCESS:
      return {
        ...state,
        patrollingRouteList: action.payload.patrollingRouteList,
        response: action.payload.response,
        patrollingRouteDetail: action.payload.patrollingRouteDetail,
        limit: action.payload.limit,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };
    case PATROLLING_ROUTE_SEARCH: // Add the new case
      return {
        ...state,
        searching: action.payload.searching
      };
    default:
      return state;
  }
};

export default patrollingRouteReducer;
