import React, { useEffect, useState,useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useDispatch, useSelector } from 'react-redux';
import { groupAddOrUpdate, groupClearShowSnackbar } from '../../actions/groupActions';
import { useToast } from '@chakra-ui/react';
import { GROUP_CLEAR_SNACKBAR } from "constants/types";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';
import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react';
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
import GroupCheckpostTable from "views/Checkpost/GroupCheckpost/GroupCheckpostTable";
import GroupDevicesTable from "views/Devices/GroupDevice/GroupDevicesTable";
import GaurdTable from "views/Gaurd/GaurdTable";
import GroupGaurdTable from "views/Gaurd/GroupGaurd/GaurdTable";
import GroupDepartmentTable from "views/Department/GroupDepartment/GroupDepartmentTable";
import GroupSubDepartmentTable from "views/subDepartment/GroupSubDepartment/GroupSubDepartmentTable";
import GroupDesignationsTable from "views/Designation/GroupDesignation/GroupDesignationTable";
import GroupPatrollingRouteTable from "views/PatrollingRoute/GroupPatrollingRoute/GroupPatrollingRouteTable";
import GroupFloorTable from "views/Floor/GroupFloor/GroupFloorTable";
function GroupViewForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [adminId, setAdminId] = useState("");
  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const responseStatus = useSelector(state => state.group.status);
  const responseMessage = useSelector(state => state.group.text);
  const loading = useSelector(state => state.group.loading);
  const groupDetail = useSelector(state => state.group.groupDetail);
  const showSnackbar = useSelector(state => state.group.showSnackbar);
  const myCheckPost = useRef(null);
  const myDeviceTable = useRef(null);
  const myGuardTable = useRef(null);
  const myDepartment = useRef(null);
  const mySubdepartment = useRef(null);
  const myDesignation = useRef(null);
  const myPatrollingRoute = useRef(null);
  const myFloorTable = useRef(null);
  const params = useParams();
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        console.log(groupDetail);
        setName(groupDetail.name);
        setDescription(groupDetail.description);
        setContactNo(groupDetail.contactNo);
        setEmail(groupDetail.email);
        setStatus(groupDetail.status);
        setAdminId(groupDetail.adminId);
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }

      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }

      }
    }

    dispatch(groupClearShowSnackbar());
  }, [loading]);

  useEffect(() => {
    console.log(params);
    const group= {
      _id : params.id
    };
    dispatch(groupAddOrUpdate(group,'view'));
  }, []);

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  
  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };
  const buttonStyle = {
    size: "md",
    height: "48px",
    width: "200px",
    border: "2px",
    borderColor: "green.500",
  };
  const scrollToCheckpost = () => {
    myCheckPost.current.scrollIntoView({ behavior: 'smooth' });
  };
  const scrollToDepartment = () => {
    myDepartment.current.scrollIntoView({ behavior: 'smooth' });
  };
  const scrollToSubdepartment = () => {
    mySubdepartment.current.scrollIntoView({ behavior: 'smooth' });
  };
  const scrollToDesignation = () => {
    myDesignation.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToPurpose = () => {
    myPurpose.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToDelivarypartner = () => {
    myDelivarypartner.current.scrollIntoView({ behavior: 'smooth' });
  };
  
  const scrollToBiometricsettings = () => {
    myBiometricSettings.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToFloor = () => {
    myFloorTable.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToPatrollingRoute = () => {
    myPatrollingRoute.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToDeviceTable = () => {
    myDeviceTable.current.scrollIntoView({behavior : 'smooth'})
  }
  const scrollToScheduleHighAlert = () => {
    myScheduleHighAlertTable.current.scrollIntoView({behavior : 'smooth'})
  }
  const scrollToParking = () => {
    myParking.current.scrollIntoView({behavior : 'smooth'})
  }
  return (
    <>
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="18px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
           Group View 
          </Text>
        </CardHeader>
        <CardBody>
          {loading ? <Stack>
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
          </Stack>
            :
            <TableContainer>
              <Table variant='simple'>
                <Tbody>
                  <Tr>
                    <Td>Name</Td>
                    <Td>{name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Contact No</Td>
                    <Td>{contactNo}</Td>
                  </Tr>
                  <Tr>
                    <Td>Email</Td>
                    <Td>{email}</Td>
                  </Tr>
                  <Tr>
                    <Td>Status</Td>
                    <Td>{status?'Active' : 'Inactive'}</Td>
                  </Tr>
                  <Tr>
                    <Td>Admin</Td>
                    <Td>{adminId.name}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>}
        </CardBody>
      </Card>
    </Flex>

    <Flex direction="column" pt={{ base: "10px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="15px">
          {/* <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Plant Action
            </Text>
          </CardHeader> */}
          <CardBody>
            
              <TableContainer>
                <Table variant='simple'>
                  <Tbody>

                          <Tr>
                            <Td><Button style={buttonStyle} onClick={scrollToCheckpost}>CheckPost</Button></Td>
                            <Td><Button style={buttonStyle} onClick={scrollToDepartment}>Departments</Button></Td>
                            <Td><Button style={buttonStyle} onClick={scrollToSubdepartment}>Sub Departments</Button></Td>
                            <Td><Button style={buttonStyle} onClick={scrollToDesignation}>Designation</Button></Td>
                          </Tr>

                          <Tr>
                            {/* <Td><Button style={buttonStyle} onClick={scrollToParking}>Parking Management</Button></Td> */}
                            {/* <Td><Button style={buttonStyle} onClick={scrollToParkingDepartment}>Parking Departments</Button></Td> */}
                            <Td><Button style={buttonStyle} onClick={scrollToPatrollingRoute}>Patrolling Route</Button></Td>
                            <Td><Button style={buttonStyle} onClick={scrollToFloor}>Floor</Button></Td>
                            <Td><Button style={buttonStyle} onClick={scrollToDeviceTable}>Devices</Button></Td>
                          </Tr>
                          <Tr>
                            {/* <Td><Button style={buttonStyle} onClick={scrollToScheduleHighAlert}>ScheduleHighAlert</Button></Td> */}
                          </Tr>

                  </Tbody>

                </Table>
              </TableContainer>
          </CardBody>
        </Card>
      </Flex>
    <SimpleGrid minChildWidth="120px" spacing="40px" ref={myCheckPost}>
    <GroupCheckpostTable url={`#/admin/group-checkpost-form/${params.id}`} group={params.id}></GroupCheckpostTable>
  </SimpleGrid>
  <SimpleGrid minChildWidth="120px" spacing="40px" ref={myDepartment}>
    <GroupDepartmentTable url={`#/admin/group-department-form/${params.id}`} group={params.id}></GroupDepartmentTable>
  </SimpleGrid>
  <SimpleGrid minChildWidth="120px" spacing="40px" ref={mySubdepartment}>
  <GroupSubDepartmentTable url={`#/admin/group-subdepartment-form/${params.id}`} group={params.id}></GroupSubDepartmentTable>
  </SimpleGrid>
  <SimpleGrid minChildWidth="120px" spacing="40px" ref={myDesignation}>
        <GroupDesignationsTable url={`#/admin/group-designation-form/${params.id}`} group={params.id}></GroupDesignationsTable>
      </SimpleGrid>
  <SimpleGrid minChildWidth="120px" spacing="40px" ref={myPatrollingRoute}>
    <GroupPatrollingRouteTable url={`#/admin/group-patrolling-route-form/${params.id}`} group={params.id} filter={false}></GroupPatrollingRouteTable>
  </SimpleGrid>

  <SimpleGrid minChildWidth="120px" spacing="40px" ref={myFloorTable}>
        <GroupFloorTable url={`#/admin/group-floor-form/${params.id}`} group={params.id}></GroupFloorTable>
      </SimpleGrid>

  <SimpleGrid minChildWidth="120px" spacing="40px" ref={myDeviceTable}>
        <GroupDevicesTable url={`#/admin/group-device-form/${params.id}`} group={params.id} ></GroupDevicesTable>
      </SimpleGrid>
    
    </>
    
    
    
  );
}

export default GroupViewForm;
