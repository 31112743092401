import React, { useEffect, useState } from 'react';
import {
  Flex,
  Input,
  SimpleGrid,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Button,
  Center,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import { FLOOR_CLEAR_SNACKBAR } from 'constants/types';
import { Spinner } from '@chakra-ui/react';
import dataConstant from 'constants/dataConstant';
import { useParams, useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import { floorAddOrUpdate } from 'actions/FloorAction'; // Import your Floor action
import { floorAddOrUpdate } from 'actions/floorAction'
import { patrollingRouteGetAll } from '../../actions/PatrollingRouteAction'
import axios from 'axios';
import { server_url } from "constants/types";


function FloorForm() {
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('');
  const [route, setRoute] = useState('');
  const [time, setTime] = useState('');
  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorDescription, setIsErrorDescription] = useState(false);
  const [isErrorStatus, setIsErrorStatus] = useState(false);
  const [isErrorRoute, setIsErrorRoute] = useState(false);
  const [isErrorTime, setIsErrorTime] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();
  const params = useParams();
  const history = useHistory();

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector((state) => state.floor.status);
  const responseMessage = useSelector((state) => state.floor.text);
  const loading = useSelector((state) => state.floor.loading);
  const showSnackbar = useSelector((state) => state.floor.showSnackbar);
  const patrollingRouteList = useSelector((state) => state.patrollingRoute.patrollingRouteList);
  console.log("patrollingRouteList")
  console.log(patrollingRouteList);
  console.log(route)
  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsErrorName(name === '');
    // setIsErrorDescription(description === '');
    setIsErrorStatus(status === '');
    setIsErrorRoute(route === '');
    // setIsErrorTime(time === '');

    if (name !== '' && status !== ''  && route !== '') {
      // Get form data
      const floorData = {
        name: name,
        description: description,
        status: status,
        patrollingRoute: route,
        // time: time,
        plant : params.id,
        // plant: req._id,
        // Other floor data fields
      };
      console.log("floorData+++++++++");

      console.log(floorData);

      // Determine if it's an add or edit operation
      const state = 'add';

      // Call the floorAddOrUpdate action
      dispatch(floorAddOrUpdate(floorData, state));
      
    }
  };
  useEffect(() => {
    dispatch(patrollingRouteGetAll("&limit=1000&plant=" + params.id));
  }, [])

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== '') {
          toastFunction(responseMessage, 'success');
          history.goBack();
          // Redirect to the floor table or the desired page after successful submission
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== '') {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: FLOOR_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      },
    });
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Form
          </Text>
          <SimpleGrid minChildWidth='120px' spacing='40px'>
            <FormControl isInvalid={isErrorName}>
              <FormLabel>Name</FormLabel>
              <Input
                type='text'
                onChange={(e) => {
                  setIsErrorName(e.target.value === '');
                  setName(e.target.value);
                }}
              />
              {!isErrorName ? (
                <></>
              ) : (
                <FormErrorMessage>Name is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorDescription}>
              <FormLabel>Description</FormLabel>
              <Input
                type='text'
                onChange={(e) => {
                  // setIsErrorDescription(e.target.value === '');
                  setDescription(e.target.value);
                }}
              />
              {!isErrorDescription ? (
                <></>
              ) : (
                <FormErrorMessage>Description is required.</FormErrorMessage>
              )}
            </FormControl>

            
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' marginTop='30px'>

          <FormControl isInvalid={isErrorRoute}>
              <FormLabel>Patrolling Route</FormLabel>
              <Select
                placeholder="-- select Patrolling Route  --"

                onChange={(e) => {
                  setIsErrorRoute(e.target.value === '');
                  setRoute(e.target.value);
                }}

              >
                {patrollingRouteList ? (patrollingRouteList.map((item, index) => (
                  <option key={item._id} value={item._id}>{item.name}</option>
                ))) : (<></>)}
              </Select>
              {!isErrorRoute ? (
                <></>
              ) : (
                <FormErrorMessage>Patrolling Route  is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorStatus}>
              <FormLabel>Status</FormLabel>
              <Select
                placeholder="Select status"
                onChange={(e) => {
                  setIsErrorStatus(e.target.value === '');
                  setStatus(e.target.value);
                }}
              >
                {dataConstant.status.map((element, index) => {
                  return (<option key={element['id']} value={element['value']} >{element['name']}</option>);
                })}
              </Select>
              {!isErrorStatus ? (
                <></>
              ) : (
                <FormErrorMessage>Status is required.</FormErrorMessage>
              )}
            </FormControl>

          </SimpleGrid>
          <Center>
            {loading ? <Spinner mt='24px' size='lg' /> :
              <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                Submit
              </Button>
            }
          </Center>
        </CardHeader>
        <CardBody>
          {/* Additional form fields and content can be added here */}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default FloorForm;
