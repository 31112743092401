// Chakra imports
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  Select,
  ButtonGroup,
  Link,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Stack,
  StackDivider,
  Heading,

} from "@chakra-ui/react";
import Relect from 'react-select';
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { bannerAddOrUpdate, bannerClearShowSnackbar } from '../../actions/bannerActions';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'

import { Spinner } from '@chakra-ui/react'
import dataConstant from "constants/dataConstant";
import { EMPLOYEE_CLEAR_SNACKBAR } from "constants/types";
import { employeeAddOrUpdate } from "actions/EmployeeAction";
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory,useLocation } from 'react-router-dom';

import CustomInput from "views/CustomValidation";
import EmployeeDesignation from "./EmployeeDesignation";
import { FaUpload } from "react-icons/fa";
function EmployeeForm(props) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [employeeName, setEmployeeName] = useState("");
  const [photo, setPhoto] = useState("");
  const [phone, setPhone] = useState("");
  const [alternateNumber, setAlternateNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [officialEmail, setOfficialEmail] = useState('');
  const [phoneCall , setPhoneCall] = useState(false)
  const [phoneCallType , setPhoneCallType] = useState("NOTASSIGNED");

  const [departmentList, setDepartmentList] = useState('')
  const [departmentId, setDepartmentId] = useState(searchParams.get('departmentId'));
  const [designation, setDesignation] = useState(searchParams.get('designationId'));
  const [subdepartment, setSubdepartment] = useState(searchParams.get('subdepartmentId'));
  const [employeeType, setEmployeeType] = useState('');
  const [employeeAddress, setEmployeeAddress] = useState('');
  const [subdepartmentList, setSubdepartmentList] = useState([])
  const [designationList, setDesignationList] = useState([])
  const [vehicleType, setVehicleType] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [licenseNumber, setLicenseNumber] = useState('');
  const [licenseExpiry, setLicenseExpiry] = useState('');
  const [licensePhoto, setLicensePhoto] = useState('')
  const [disable, setDisable] = useState(false);
  const [appAccess, setAppAccess] = useState(false);
  const [notification, setNotification] = useState(false);
  const [bloodGroup, setBloodGroup] = useState(false);
  const [requestType, setRequestType] = useState('');
  const [module, setmodule] = useState();
  const [moduleList, setModuleList] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isDepartmentHead, setIsDepartmentHead] = useState(false);
  const [isErrorEmployeeName, setIsErrorEmployeeName] = useState(false);
  const [isErrorPhoto, setIsErrorPhoto] = useState(false);
  const [isErrorPhone, setIsErrorPhone] = useState(false);
  const [isErrorAlternateNumber, setIsErrorAlternateNumber] = useState(false);
  const [isErrorCompanyName, setIsErrorCompanyName] = useState(false);
  const [isErrorOfficialEmail, setIsErrorOfficialEmail] = useState(false);
  const [isErrorPhoneCall, setIsErrorPhoneCall] = useState(false);
  const [isErrorPhoneCallType, setIsErrorPhoneCallType] = useState(false);

  const [isErrorDepartment, setIsErrorDepartment] = useState(false);
  const [isErrorDesignation, setIsErrorDesignation] = useState(false);
  const [isErrorSubdepartment, setIsErrorSubdepartment] = useState(false);
  const [isErrorEmployeeType, setIsErrorEmployeeType] = useState(false);
  const [isErrorEmployeeAddress, setIsErrorEmployeeAddress] = useState(false);
  const [isErrorVehiclType, setIsErrorVehilcType] = useState(false);
  const [isErrorVehicleNumber, setIsErrorVehicleNumber] = useState(false);
  const [isErrorLicenseNumber, setIsErrorLicenseNumber] = useState(false);
  const [isErrorLicenseExpiry, setIsErrorLicenseExpiry] = useState(false);
  const [isErrorLicensePhoto, setIsErrorLicensePhoto] = useState(false);
  const [isErrorAppAccess, setIsErrorAppAccess] = useState(false);
  const [isErrorNotification, setIsErrorNotification] = useState(false);
  const [isErrorBloodGroup, setIsErrorBloodGroup] = useState(false);
  const [isErrorRequestType, setIsErrorRequestType] = useState(false);
  const [isErrorStartDate, setIsErrorStartDate] = useState(false);
  const [isErrorEndDate, setIsErrorEndDate] = useState(false);
  const [isErrorModule, setIsErrorModule] = useState(false);

  const [submitted, setSubmitted] = useState(false);
console.log("companyName")
console.log(companyName)
  const textRegex = /^[A-Za-z,\s]+$/;

  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/;
  const numberRegex = /^\d{10}$/;
  const altNumberRegex = /^(\d{10})?$/;
  const vechilenoRegex = /^[A-Za-z0-9!@#$%^&*()_\-\[\]{};':"\\|,.<>\/? ]*$/;
  const licencenoRegex = /^[A-Za-z0-9]{6,12}$/

  const dispatch = useDispatch();
  const toast = useToast();

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.employee.status);
  const responseMessage = useSelector(state => state.employee.text);
  const loading = useSelector(state => state.employee.loading);
  const showSnackbar = useSelector(state => state.employee.showSnackbar);
  const history = useHistory();
  // const employeeList = useSelector(state => state.employee.employeeList)

  console.log("department Id " + searchParams.get('departmentId'))

  // Function to handle form submission
  const handleSubmit = (event) => {
    if(validNumber == true)
      {
        onOpen();
        return;
      }
    event.preventDefault();
    setSubmitted(true);
    setIsErrorEmployeeName(employeeName === '');
    // setIsErrorPhoto(photo === '');
    setIsErrorPhone(phone === '');
    // setIsErrorAlternateNumber(alternateNumber === '');
    // setIsErrorCompanyName(companyName === '');
    setIsErrorOfficialEmail(officialEmail === '');

    setIsErrorDepartment(departmentId === '');
    setIsErrorDesignation(designation === '');
    setIsErrorSubdepartment(subdepartment === '');
    setIsErrorEmployeeType(employeeType === '');
    // setIsErrorEmployeeAddress(employeeAddress === '');
    setIsErrorVehilcType(vehicleType === '');
    setIsErrorLicenseExpiry(licenseExpiry === '');
    setIsErrorLicensePhoto(licensePhoto === '');
    setIsErrorLicensePhoto(licensePhoto === '');
    setIsErrorBloodGroup(bloodGroup === '');
    // setIsErrorRequestType(requestType === '');
    // setIsErrorStartDate(startDate === '');
    // setIsErrorEndDate(endDate === '');
    setIsErrorPhoneCall(phoneCall === '');


    if (employeeName != "" && phone !== ""  && officialEmail !== "") {
      // || startDate !== "" || endDate !== ""
      console.log("Submit");
      // Get form data
      var newModule = [];
      const employee = {

        employeeName: employeeName,
        file1: photo,
        phone: phone,
        alternateNumber: alternateNumber,
        officialEmail: officialEmail,
        department: departmentId,
        designation: designation,
        subdepartment: subdepartment,
        
        employeeAddress: employeeAddress,
        companyName : companyName,
        appAccess: appAccess,
        phoneType: phoneCallType,
        phoneCall : phoneCall,
        enableNotification: notification,
        startDate: startDate,
        endDate: endDate,
        isDepartmentHead:isDepartmentHead,
        
        bloodGroup: bloodGroup,
        requestType: requestType,
        vehicleDetails: {
          vehicleType: "BY_WALK",
          vehicleNumber: vehicleNumber,
          licenseNumber: licenseNumber,
          licenseExpiry: licenseExpiry,

        },

      };
      if(startDate && endDate)
      {
        employee.employeeType= 3;
      }
      else{
        employee.employeeType= 1;
      }
      if(module)
      {
        employee.employeeModules = module.map((module) => module.value)
      }
      else
      {
        employee.employeeModules = [];
      }
      console.log("employeeData");
      console.log(employee);

      // Determine if it's an add or edit operation
      const state = 'add';
      // Call the employeeAddOrUpdate action
      dispatch(employeeAddOrUpdate(employee, state))
      // history.push("/admin/employee-table")

    }
  };

  const departmentListforDropdown = () => {
    axios
      .get(`${server_url}/department`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        setDepartmentList(responce.data.data)
      }).catch(() => console.log("error"))
  }

  const subdepartmentListforDropdown = (depId) => {
    if (depId) {
      axios
        .get(`${server_url}/plant/subdepartment-list/${depId}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          setSubdepartmentList(responce.data.data)
        })
    }
    else {
      setSubdepartmentList([])
    }

  }

  const designationListforDropdown = (subdepId) => {
    if (subdepId) {
      axios
        .get(`${server_url}/plant/designation-list/${subdepId}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          setDesignationList(responce.data.data)
        })
    }
    else {
      setDesignationList([])
    }

  }

  const moduleListDropdown = () => {
    axios
      .get(`${server_url}/employeeModules`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        setModuleList(responce.data.data)
      })


  }


  useEffect(() => {
    departmentListforDropdown()
    moduleListDropdown();

  }, [])

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])


  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
          setEmployeeName('');
          setPhone("");
          setOfficialEmail("");
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: EMPLOYEE_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })


  };
  const [isOpen, setIsOpen] = useState(false);
  const [validNumber, setValidNumber] = useState(false);
  const [details, setDetails] = useState('');

  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);

  useEffect(() => {
    // if(phone.length == 10)
    //   {
        axios
        .post(`${server_url}/admin/check/phone-number`,{phone:phone}, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          },
        }).then((responce) => {
          if(responce.data.status == 'NOK')
            {
              if(responce.data.data)
                {
                  setDetails(responce.data.data);
                  setValidNumber(true)
                  onOpen();
                }
              console.log(responce.data.data);
            }
            else
            {
              setValidNumber(false)
            }
        }).catch(() => console.log("error"))
      // }
    }, [phone.length == 10]);



  return (
    
    <>
     <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color='red'>{details.message}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Stack divider={<StackDivider />} spacing='2'>
          <Box>
            <Text  fontSize='sm'>
              Plant Name : {details.plantName}
            </Text>
          </Box>
          <Box>
            <Text  fontSize='sm'>
            Department : {details.department}
            </Text>
          </Box>
          <Box>
            <Text  fontSize='sm'>
            Designation : {details.designation}
            </Text>
          </Box>
          <Box>
            <Text  fontSize='sm'>
            Passcode : {details.passcode}
            </Text>
          </Box>
          
        </Stack>
            
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    <EmployeeDesignation
    selectedDepartment={departmentId}
    selectedSubdepartment={subdepartment}
    selectedDesignation={designation}
    ></EmployeeDesignation>
    <Flex direction="column" pt={{ base: "70px", md: "25px" }}>
      
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">

        <CardHeader p="6px 0px 22px 0px">
          
          <Flex>
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Employee Form 
          </Text>
            <Spacer />
            <ButtonGroup gap='2'>
            <Link href={`#/admin/employee-upload-excelsheet?departmentId=${departmentId}&subdepartmentId=${subdepartment}&designationId=${designation}`}>
              <IconButton
                colorScheme="blue"
                aria-label="Upload File"
                icon={<FaUpload />}
              />
            </Link>
            </ButtonGroup>
          </Flex>
          
          
        </CardHeader>
        <CardBody>
        <SimpleGrid minChildWidth='120px' spacing='40px'>
            {/* Employee Name */}
            <FormControl isInvalid={isErrorEmployeeName || (submitted && employeeName === "")}>

              <CustomInput
              required ={true}
                type='text'
                label='Employee Name'
                value={employeeName}
                onChange={(value) => {
                  setIsErrorEmployeeName(value === '' || !textRegex.test(value));
                  setEmployeeName(value);
                }}
                regex={textRegex}
                submitted={submitted}
              />
            </FormControl>

            {/* Photo */}
            <FormControl isInvalid={isErrorPhoto}>
              <FormLabel>Photo</FormLabel>
              <Input
                type='file'

                onChange={(e) => {

                  setIsErrorPhoto(e.target.value === '')
                  setPhoto(e.target.files[0])
                }}
              />
              {!isErrorPhoto ? (
                <></>
              ) : (
                <FormErrorMessage>Photo is required.</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            {/* Phone Number */}
            <FormControl isInvalid={isErrorPhone || (submitted && phone === "")}>

              <CustomInput
              required ={true}
                type='number'
                label='Phone Number'
                value={phone}
                onChange={(value) => {
                  setIsErrorPhone(value === '' || !numberRegex.test(value));
                  setPhone(value);
                }}
                regex={numberRegex}
                submitted={submitted}
              />
            </FormControl>

            {/* Alternate Number */}
            <FormControl >
            {/* <FormLabel>Alternate Number</FormLabel> */}
              <CustomInput
                type='number'
                label='Alternate Number'
                value={alternateNumber}
                onChange={(value) => {
                  // setIsErrorAlternateNumber(value === '' || !numberRegex.test(value));
                  setAlternateNumber(value);
                }}
                regex={altNumberRegex}
                isEmpty = {true}
                // submitted={submitted}
              />
            </FormControl>
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            {/* Official Email */}
            <FormControl isInvalid={isErrorOfficialEmail || (submitted && officialEmail === "")}>

              <CustomInput
              required ={true}
                type='text'
                label='Official Email'
                value={officialEmail}
                onChange={(value) => {
                  setIsErrorOfficialEmail(value === '' || !emailRegex.test(value));
                  setOfficialEmail(value);
                }}
                regex={emailRegex}
                submitted={submitted}
              />
            </FormControl>

            {/* Employee Id
              <FormControl isInvalid={isErrorEmployeeId || (submitted && employeeId === "")}>
              
                <CustomInput
                  type='text'
                  label='Employee Id'
                  value={employeeId}
                  onChange={(value) => {
                    setIsErrorEmployeeId(value === '' || !textRegex.test(value));
                    setEmployeeId(value);
                  }}
                  regex={textRegex}
                  submitted={submitted}
                />
              </FormControl> */}
            <FormControl isInvalid={isErrorBloodGroup || (submitted && bloodGroup === "")}>
              <FormLabel>Blood Group</FormLabel>
              <Select
                placeholder="--- Select Blood Group ---"
                onChange={(e) => {
                  setIsErrorBloodGroup(e.target.value === '');
                  setBloodGroup(e.target.value);
                }}
                value={bloodGroup}
              >
                {dataConstant.bloodGroup.map((element, index) => (
                  <option key={element.id} value={element.value}>{element.name}</option>
                ))}
              </Select>
              {isErrorBloodGroup && submitted && (
                <FormErrorMessage>Blood group is required.</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            {/* <Flex  minChildWidth='120px' spacing='40px' mt='24px'> */}
            
            

                <FormControl isInvalid={isErrorStartDate}>
                  <FormLabel>Start Date</FormLabel>
                  <Input
                    type='date'
                    onChange={(e) => {

                      // setIsErrorStartDate(e.target.value === '')
                      setStartDate(e.target.value)
                    }}
                  />
                  {!isErrorStartDate ? (
                    <></>
                  ) : (
                    <FormErrorMessage>Start Date is required.</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl isInvalid={isErrorEndDate}>
                  <FormLabel>End Date</FormLabel>
                  <Input
                    type='date'
                    onChange={(e) => {

                      // setIsErrorEndDate(e.target.value === '')
                      setEndDate(e.target.value)
                    }}
                  />
                  {!isErrorLicensePhoto ? (
                    <></>
                  ) : (
                    <FormErrorMessage>End Date is required.</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl isInvalid={isErrorCompanyName}>
                  <FormLabel>Service Provider Company Name</FormLabel>
                  <Input
                    type='text'
                    // value={companyName}
                    // onChange={(e) => {
                    //   setIsErrorCompanyName(e.target.value === '' || !textRegex.test(value));
                    //   setCompanyName(e.target.value);
                    // }}
                      onChange={(e) => {

                        // setIsErrorCompanyName(e.target.value === '')
                        setCompanyName(e.target.value)
                    }}
                    regex={textRegex}

                  />
                  {!isErrorCompanyName ? (
                    <></>
                  ) : (
                    <FormErrorMessage>Service Provider Company Name is required.</FormErrorMessage>
                  )}
                </FormControl>
               
             
            {/* </Flex> */}

          </SimpleGrid>

          <Center>
            {loading ? <Spinner mt='24px' size='lg' /> :
              <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                Submit
              </Button>
            }
          </Center>
        </CardBody>
      </Card>
    </Flex></>
  );
}

export default EmployeeForm;

