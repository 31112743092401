import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Spinner,
  Image,
  Select,
  Skeleton
} from "@chakra-ui/react";
import Relect from "react-select";
import { PATROLLING_REQUEST_CLEAR_SNACKBAR } from "constants/types";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
import { useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { patrollingRequestAddOrUpdate ,patrollingRequestClearShowSnackbar} from "actions/patrollingRequestAction"; // Import the correct action
import CustomInput from "views/CustomValidation";
import { patrollingRouteGetAll } from "actions/PatrollingRouteAction";
import {floorGetAll} from 'actions/floorAction';
import { guardGetAll } from 'actions/GaurdAction';

function PatrollingRequestUpdateForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [patrollingRouteName, setPatrollingRouteName] = useState([]);
  const [floor, SetFloor] = useState('');
  const [guard, setGuard] = useState('');
  const [dateTime, setDateTime] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [requestData, setRequestData] = useState('');

  const [isErrorPatrollingRouteName, setIsErrorPatrollingRouteName] = useState(false);
  const [isErrorFloor, setIsErrorFloor] = useState(false);
  const [isErrorGuard, setIsErrorGuard] = useState(false);
  const [isErrorDateTime, setIsErrorDateTime] = useState(false);
  const history = useHistory();

  const statuses = ["success", "error", "warning", "info"];
  const responseStatus = useSelector((state) => state.patrollingRequest.status);
  const responseMessage = useSelector((state) => state.patrollingRequest.text);
  const loading = useSelector((state) => state.patrollingRequest.loading);
  const showSnackbar = useSelector((state) => state.patrollingRequest.showSnackbar);
  const patrollingRequestDetail = useSelector((state) => state.patrollingRequest.patrollingRequestDetail);
  const patrollingRouteList = useSelector(state => state.patrollingRoute.patrollingRouteList);
  const floorList = useSelector(state=> state.floor.floorList);
  const guardList = useSelector(state => state.guard.guardList);

  const dispatch = useDispatch();
  const toast = useToast();
  const params = useParams();
  const IdofPatrollingRequest = params.id;
console.log("guard")
console.log(guard)

console.log("guard")

  useEffect(() => {
    const patrollingRequest = {
      _id: IdofPatrollingRequest,
    };
    dispatch(patrollingRequestAddOrUpdate(patrollingRequest, "view"));
  }, []);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === "OK" && loading === false) {
        setRequestData(patrollingRequestDetail);
        console.log("tt-->");
        console.log(patrollingRequestDetail);
        console.log("tt-->");
        if(requestData !='')
        {
          setPatrollingRouteName(patrollingRequestDetail.patrollingRoute.map((item) => ({
            value: item._id,
            label: item.name
            })));
            SetFloor(patrollingRequestDetail.floor.map((item) => ({
              value: item._id,
              label: item.name
              })));
          setGuard(patrollingRequestDetail.guard._id);
          setDateTime(patrollingRequestDetail.dateTime)
        }
        
        
      
      }
    }

    dispatch(patrollingRequestClearShowSnackbar())
  },[loading]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsErrorPatrollingRouteName(patrollingRouteName === '');
    setIsErrorFloor(floor === '');
    setIsErrorGuard(guard === '');
    setIsErrorDateTime(dateTime === '');

    if(patrollingRouteName.length !== '' && floor.length !== '' && guard !== '' ) {
      const patrollingRequestData = {
        _id: IdofPatrollingRequest,
        patrollingRoute : patrollingRouteName.map((module) => module.value),
        floor: 
        floor.map((module) => module.value),
        guard: guard,
        dateTime : dateTime,
      };
      dispatch(patrollingRequestAddOrUpdate(patrollingRequestData, "edit"));
    }
  };

  // useEffect(() => {
  //   if (showSnackbar) {
  //     if (responseStatus === "OK" && loading === false) {
  //       if (responseMessage !== "") {
  //         toastFunction(responseMessage, "success");
  //       }
  //     } else if (responseStatus === "NOK" && loading === false) {
  //       if (responseMessage !== "") {
  //         toastFunction(responseMessage, "error");
  //       }
  //     }
  //   }

  //   dispatch({
  //     type: PATROLLING_REQUEST_CLEAR_SNACKBAR,
  //     payload: {
  //       showSnackbar: false,
  //     },
  //   });
  // }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };
  useEffect(() => {
    dispatch(patrollingRouteGetAll('limit=1000'));
    dispatch(floorGetAll('limit=1000'));
    dispatch(guardGetAll('limit=1000'))
  }, [])

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])

//   const textRegex = /^[A-Za-z\s]+$/;
//   const numberRegex = /^\d{10}$/;
//   const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/;
//   const guardIdRegex = /^[a-zA-Z0-9]+$/;
//   const postalCodeRegex = /^[0-9]{6}$/;

  return (
    (loading && requestData == '')? <Skeleton height="20px" />:<Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Patrolling Request Update Form
          </Text>
          <br/>
          <SimpleGrid minChildWidth='120px' spacing='40px'>
          <FormControl isInvalid={isErrorPatrollingRouteName  || (submitted &&  patrollingRouteName === "")}>
                <FormLabel>Patrolling-Route</FormLabel>
                <Relect
               isMulti
               value={patrollingRouteName}
               name="colors"
               placeholder="--Select Patrolling-Route--"
               options={patrollingRouteList.map((item) => ({
                  value: item._id,
                  label: item.name
                  }))}
              
               onChange={(value) => {
                setPatrollingRouteName(value)
                 setIsErrorPatrollingRouteName(value === '')}}
                className="basic-multi-select"
                classNamePrefix="select"
              />
              
              {!isErrorPatrollingRouteName && submitted && (
                  <FormErrorMessage> Patrolling-Route is required.</FormErrorMessage>
                )}
                
              </FormControl>

              <FormControl isInvalid={isErrorFloor  || (submitted &&  floor === "")}>
                <FormLabel>Floor</FormLabel>
                <Relect
               isMulti
               value={floor}
               name="colors"
               placeholder="--Select Patrolling-Route--"
               options={floorList.map((item) => ({
                  value: item._id,
                  label: item.name
                  }))}
              
               onChange={(value) => {
                SetFloor(value)
                 setIsErrorFloor(value === '')}}
                className="basic-multi-select"
                classNamePrefix="select"
              />
              
              {!isErrorFloor && submitted && (
                  <FormErrorMessage>floor is required.</FormErrorMessage>
                )}
                
              </FormControl>

          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' marginTop='30px'>
            <FormControl isInvalid={isErrorGuard}>
              <FormLabel>Guard</FormLabel>
              <Select
                placeholder="Select Guard"
                value={guard}
                onChange={(e) => {
                  setIsErrorGuard(e.target.value === '');
                  setGuard(e.target.value);
                }}
              >
                {guardList.map((item, index) => {
                 return   <option key={item._id} value={item._id} selected={guard == item._id ? true : false}>{item.name}</option>
                })}
              </Select>
              {!isErrorGuard ? (
                <></>
              ) : (
                <FormErrorMessage>Guard is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorDateTime || (submitted && dateTime === "")}>
                  <FormLabel>Date & Time</FormLabel>
                  <Input
                    type='datetime-local'
                    value={dateTime}
                    onChange={(e) => {

                      setIsErrorDateTime(e.target.value === '')
                      setDateTime(e.target.value)
                    }}
                  />
                  {!isErrorDateTime ? (
                    <></>
                  ) : (
                    <FormErrorMessage>Date & Time is required.</FormErrorMessage>
                  )}
                </FormControl>
          </SimpleGrid>

          <Center>
            {loading ? (
              <Spinner mt="24px" size="lg" />
            ) : (
              <Button colorScheme="blue" mt="24px" onClick={handleSubmit}>
                Submit
              </Button>
            )}
          </Center>
        </CardHeader>
        <CardBody>
          {/* Additional form content */}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default PatrollingRequestUpdateForm;
