import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
 
  SimpleGrid,
  Spacer,
  Spinner,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useDispatch, useSelector } from 'react-redux';
import { getCheckpost, checkpostClearShowSnackbar, checkpostAddOrUpdate } from '../../../actions/checkpostActions';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react'
import Select from 'react-select';
import Relect from 'react-select';
import { useToast } from '@chakra-ui/react'
import { CHECKPOST_CLEAR_SNACKBAR } from "constants/types";
import dataConstant from "constants/dataConstant";
import { guardmoduleGetAll } from "../../../actions/guardModuleAction";
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
function GroupCheckpostUpdateForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState();
  const [guardModules, setGuardModules] = useState();
  const [adminId, setAdminId] = useState("");
  const [homeModule, setHomeModule] = useState([]);
  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorStatus, setIsErrorStatus] = useState(false);
  const [isErrorGuardModules, setIsErrorGuardModules] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const responseStatus = useSelector(state => state.checkpost.status);
  const responseMessage = useSelector(state => state.checkpost.text);
  const loading = useSelector(state => state.checkpost.loading);
  const checkpostDetail = useSelector(state => state.checkpost.checkpostDetail);
  const showSnackbar = useSelector(state => state.checkpost.showSnackbar);
  const guardmoduleList = useSelector(state => state.guardmodule.guardmoduleList);

  console.log(status)
  console.log(guardModules)
  
  useEffect(() => {
  
    dispatch(guardmoduleGetAll("limit=100"));
  }, []);
  const params = useParams();

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isErrorName) {
      // Get form data
      const checkpost = {
        _id: params.id,
        name: name,
        description: description,
        status:status.value,
        gaurdModule:guardModules.map((module) => module.value),
        'homeModule': homeModule.map((module) => module.value),
        // Other checkpost data fields
      };
      console.log("checkpost");
      console.log(checkpost);

      // Determine if it's an add or edit operation
      const state = 'edit';
      // Call the addOrUpdateCheckpost action
      dispatch(checkpostAddOrUpdate(checkpost, state));
    } else {
      setIsErrorName(name === '');
    }
  };
  function replaceUnderscoresAndCapitalize(inputString) {
    // Replace underscores with spaces
    const stringWithSpaces = inputString.replace(/_/g, ' ');

    // Capitalize the first letter of each word
    const words = stringWithSpaces.split(' ');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

    // Join the words back into a single string
    const resultString = capitalizedWords.join(' ');

    return resultString;
  }
  // useEffect(() => {
  //   if (showSnackbar) {
  //     if (responseStatus === 'OK' && loading === false) {
  //       console.log(checkpostDetail);
  //       setName(checkpostDetail.name);
  //       setDescription(checkpostDetail.description);
  //       setStatus({
  //         value:checkpostDetail.status , label:checkpostDetail.status?("Active"):("Inactive")
  //       });
  //       setGuardModules(checkpostDetail.gaurdModule.map((item) => ({
  //         value: item._id,
  //         label: item.name
  //         })))  
        
  //       if (responseMessage !== "") {
  //         toastFunction(responseMessage, 'success');
  //         history.push('/admin/checkpost');
  //       }
        
  //     } else if (responseStatus === 'NOK' && loading === false) {
  //       if (responseMessage !== "") {
  //         toastFunction(responseMessage, 'error');
  //       }
  //     }
  //     if (checkpostDetail.homeModule && Array.isArray(checkpostDetail.homeModule)) {
  //       console.log(checkpostDetail.homeModule);
  //       console.log('checkpostDetail.homeModule');
  //       setHomeModule(checkpostDetail.homeModule.map((item) => ({
  //         value: item,
  //         label: replaceUnderscoresAndCapitalize(item)
  //       })));
  //     } else {
  //       setHomeModule(checkpostDetail.homeModule);
  //     }
      
  //   }

  //   dispatch(checkpostClearShowSnackbar());
  // }, [loading]);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        console.log(checkpostDetail);
        setName(checkpostDetail.name);
        setDescription(checkpostDetail.description);
        setStatus({
          value: checkpostDetail.status,
          label: checkpostDetail.status ? "Active" : "Inactive"
        });
        setGuardModules(
          checkpostDetail.gaurdModule.map((item) => ({
            value: item._id,
            label: item.name
          }))
        );
  
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
          history.goBack();
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
  
      if (checkpostDetail.homeModule && Array.isArray(checkpostDetail.homeModule)) {
        console.log(checkpostDetail.homeModule);
        console.log('checkpostDetail.homeModule');
        setHomeModule(
          checkpostDetail.homeModule.map((item) => ({
            value: item,
            label: replaceUnderscoresAndCapitalize(item)
          }))
        );
      } else {
        setHomeModule([]);
      }
  
      dispatch(checkpostClearShowSnackbar());
    }
  }, [loading]);
  

  useEffect(() => {
    const checkpost = {
        _id : params.id
    };
    console.log(params);
    dispatch(checkpostAddOrUpdate(checkpost,'view'));
  }, []);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Checkpost Update Form
          </Text>
          <br/>
          <SimpleGrid minChildWidth='120px' spacing='40px'>
            <FormControl isInvalid={isErrorName}>
              <FormLabel>Name</FormLabel>
              <Input
                type='text'
                value={name}
                onChange={(e) => {
                  setIsErrorName(e.target.value === '');
                  setName(e.target.value);
                }}
              />
              {!isErrorName ? (
                <></>
              ) : (
                <FormErrorMessage>Name is required.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Input
                type='text'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormControl>
          </SimpleGrid>
          
          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
          <FormControl isInvalid={isErrorStatus}>
              <FormLabel>Status</FormLabel>
              <Select
                placeholder="Select Status"
                value={status}
                options={dataConstant.status.map((item) => ({
                  value: item['value'],
                  label: item['name']
                  }))}
                  onChange={(status) => {
                    setIsErrorStatus(status === "");
                  setStatus(status);}}
              
              />
              
             
              {!isErrorStatus ? (
                <></>
              ) : (
                <FormErrorMessage>Status is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorGuardModules}>
              <FormLabel>Guard Modules</FormLabel>
              <Select
               isMulti
               name="colors"
               value={guardModules}
               placeholder="--select GuardModules--"
               options={guardmoduleList.map((item) => ({
                  value: item['_id'],
                  label: item['name']
                  }))}
              
               onChange={(value) => {
                 setGuardModules(value)
                 setIsErrorGuardModules(value === '')}}
                className="basic-multi-select"
                classNamePrefix="select"
              />
              {!isErrorGuardModules ? (
                <></>
              ) : (
                <FormErrorMessage>Guard Modules is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl >
              <FormLabel>Home Module</FormLabel>
              <Relect
                isMulti
                name="colors"
                placeholder="--select Home Module--"
                options={dataConstant.homeModule.map((item) => ({
                  value: item.value,
                  label: item.name
                }))}
                value={homeModule}
                onChange={(value) => {

                  setHomeModule(value)
                  console.log('homeModule');
                  console.log(value);
                  console.log("123123123123123123123123123123123");
                  console.log(setHomeModule(value));
                  // setIsErrorHomeModule(value === '')
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
              {/* {isErrorHomeModule && submitted && (
                <FormErrorMessage>Home Module is required.</FormErrorMessage>
              )} */}
            </FormControl>
          </SimpleGrid>
          <Center>
            {loading ? (
              <Spinner size='lg' mt='24px' />
            ) : (
              <Button
                colorScheme='blue'
                mt='24px'
                onClick={handleSubmit}
              >
                Submit
              </Button>
            )}
          </Center>
        </CardHeader>
        <CardBody>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default GroupCheckpostUpdateForm;
