import React, { useEffect, useState } from 'react';
import { Flex, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';


import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import HowItWorkPagination from './HowItWorkCategoryPagination';
import { AddIcon } from '@chakra-ui/icons';
import { HOWITWORK_CLEAR_SNACKBAR } from 'constants/types';
import { howItWorkGetAll } from '../../actions/howItWorkCategoryAction';
import HowItWorkCategoryTableRow from './HowItWorkCategoryTableRow';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { server_url } from '../../constants/types';

const HowItWorkCategoryTable = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.HowItWorkCategory.status);
  const responseMessage = useSelector(state => state.HowItWorkCategory.text);
  const loading = useSelector(state => state.HowItWorkCategory.loading);
  const howItWorkList = useSelector(state => state.HowItWorkCategory.howItWorkList);
  const response = useSelector(state => state.HowItWorkCategory.response);
  const showSnackbar = useSelector(state => state.HowItWorkCategory.showSnackbar);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const history = useHistory()
  useEffect(() => {
    dispatch(howItWorkGetAll());
  }, [dispatch]);

  console.log("ihuhsuhs-----------------");
  console.log(howItWorkList);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: HOWITWORK_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
    dispatch(howItWorkGetAll(sorting));
  };

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])

  useEffect(() => {
    const searching = "&search=" + search;
    dispatch(howItWorkGetAll(searching));
  }, [search]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              How-It-Work Categorys
            </Text>
          </CardHeader>
          <Spacer />
          {/* <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            <Link href={`#/admin/howItWorkCategoryform`}>
              <IconButton
                colorScheme="blue"
                aria-label="Search database"
                icon={<AddIcon />}
              />
            </Link>
          </Flex> */}
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              <InputGroup borderRadius="8px" w="200px">
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon
                          color={searchIconColor}
                          w="15px"
                          h="15px"
                        />
                      }
                    ></IconButton>
                  }
                />
                <Input
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.length < 3) {
                      setSearch("");
                    }
                  }}
                  placeholder="Type here..."
                />
              </InputGroup>
            </Text>
          </CardHeader>
        </Flex>
        <CardBody pb={4}>
        <div style={{ overflowX: "auto" }}>
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
              <Th
                    pl="15px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                   SNO
                  </Th>
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                >
                  Name
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("photo")}
                  cursor="pointer"
                >
                  Description
                  {sortColumn === "photo" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>

                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("photo")}
                  cursor="pointer"
                >
                  Switch
                  {sortColumn === "photo" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>

                <Th borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("status")}
                  cursor="pointer">
                  Type
                  {sortColumn === "status" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>

                <Th borderColor={borderColor}></Th>
              </Tr>
            </Thead>
            <Tbody>

              {howItWorkList ? (howItWorkList.map((row, index, arr) => {
                return (
                  <HowItWorkCategoryTableRow
                   serialnumber={response.currentPage - 1 + index + 1}
                    name={row.name}
                    description={row.description}
                    type={row.type}
                    status={row.status}
                    isLast={index === arr.length - 1 ? true : false}
                    id={row._id}

                  />
                );
              })) : (<h1></h1>)
              }

            </Tbody>
          </Table>
          </div>
          {howItWorkList.length === 0 ? (
            <></>
          ) : (
            <HowItWorkPagination
              totalPages={response.totalPages}
              currentPage={response.currentPage}
              perPage={response.perPage}
              sortBy={sortColumn}
              sortOrder={sortOrder}
              handlePageClick={(data) => {
                const selectedPage = data.selected + 1;
                dispatch(howItWorkGetAll(`&page=${selectedPage}`));
              }}
            />
          )}
        </CardBody>
      </Card>
    </Flex>
  );
};

export default HowItWorkCategoryTable;
