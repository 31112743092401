import React, { useEffect, useState , useRef} from 'react';
import { Flex,Button, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { useToast } from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
// import DevicePagination from './DevicePagination';
import { AddIcon } from '@chakra-ui/icons';
import { PATROLLING_ROUTE_CLEAR_SNACKBAR ,PATROLLING_ROUTE_SEARCH} from 'constants/types';
// import {  } from '../../actions/devicesAction'; // Assuming you have a DeviceAction file
// import DeviceTableRow from './DevicesTableRow';
import GroupPatrollingRouteTableRow from './GroupPatrollingRouteTableRow'
// import DevicesPagination from './DevicesPagination';
// import GroupPatrollingRoutePagination from './GroupPatrollingRoutePagination'

import { patrollingRouteGetAll } from '../../../actions/PatrollingRouteAction';
import GroupPatrollingRoutePagination from './GroupPatrollingRoutePagination';
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
import GroupPatrollingRouteAdvanceSearch from './GroupPatrollingRouteAdvanceSearch';
import { Skeleton, SkeletonCircle, SkeletonText,Stack } from '@chakra-ui/react'
const GroupPatrollingRouteTable = (props) => {
  const { url = "", group = "" ,filter = true} = props;
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.patrollingRoute.status);
  const responseMessage = useSelector(state => state.patrollingRoute.text);
  const loading = useSelector(state => state.patrollingRoute.loading);
  const patrollingRouteList = useSelector(state => state.patrollingRoute.patrollingRouteList);
  const response = useSelector(state => state.patrollingRoute.response);
  const showSnackbar = useSelector(state => state.patrollingRoute.showSnackbar);
  const currentPage = useSelector(state => state.patrollingRoute.page);
  const limit = useSelector(state => state.patrollingRoute.limit);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const history = useHistory();
  const tableRef = useRef(null);
  useEffect(() => {
    dispatch({
      type: PATROLLING_ROUTE_SEARCH,
      payload: {
        searching: "",
      },
    });
    dispatch(patrollingRouteGetAll("&group=" + group));
  }, [dispatch]);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: PATROLLING_ROUTE_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    dispatch({
      type: PATROLLING_ROUTE_SEARCH,
      payload: {
        searching: "",
      },
    });
    const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
    dispatch(deviceGetAll(sorting));
  };

  useEffect(() => {

    const searching = "&search=" + search + "&group=" + group;
    dispatch({
      type: PATROLLING_ROUTE_SEARCH,
      payload: {
        searching: "",
      },
    });
    dispatch(patrollingRouteGetAll(searching));
  }, [search]);

  return (
    <>
    {filter?<GroupPatrollingRouteAdvanceSearch></GroupPatrollingRouteAdvanceSearch>:<></>}
    <Flex direction="column" pt={{ base: "120px", md: "50px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Location
            </Text>
          </CardHeader>
          <Spacer />
          <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            {url == "" ? <></> : <Link href={url}>
              <IconButton
                colorScheme="blue"
                aria-label="Search database"
                icon={<AddIcon />}
              />
            </Link>}
          </Flex>
          <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            <DownloadTableExcel
              filename="Patrolling Route Table"
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <Button colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>
                Export Excel
              </Button>
            </DownloadTableExcel>
          </Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              <InputGroup borderRadius="8px" w="200px">
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon
                          color={searchIconColor}
                          w="15px"
                          h="15px"
                        />
                      }
                    ></IconButton>
                  }
                />
                <Input
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.length < 3) {
                      setSearch("");
                    }
                  }}
                  placeholder="Type here..."
                />
              </InputGroup>
            </Text>
          </CardHeader>
        </Flex>
        <CardBody pb={4}>
          <div style={{ overflowX: "auto" }} className='vertical-scroll'>
          
            {loading ? <Stack>
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                  </Stack>:<Table variant="simple" color={textColor} ref={tableRef}>
              <Thead className='stick-header'>
                <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                    pl="15px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                   SNO
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                    Name
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                    Description
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  {/* <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                    group Admin
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th> */}

                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                    status
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {patrollingRouteList ? (patrollingRouteList.map((row, index, arr) => {

                  return (
                    <GroupPatrollingRouteTableRow
                      serialnumber={((currentPage - 1)*limit) + (index+1)}
                      name={row.name}
                      description={row.description}
                      status={row.status}
                      // group={row.group && row.group.group[0] && row.group.group[0].groupName}
                      isLast={index === arr.length - 1 ? true : false}
                      id={row._id}
                      key={row._id}
                      groupId={group}
                    />

                  );
                  console.log("patrollingRouteList......................................");
                  console.log(patrollingRouteList);
                })) : (<h1></h1>)
                }
              </Tbody>
            </Table>}
          </div>
          {patrollingRouteList.length === 0 ? (
            <></>
          ) : (
            <GroupPatrollingRoutePagination
            group = {group}
              totalPages={response.totalPages}
              currentPage={response.currentPage}
              perPage={response.perPage}
              sortBy={sortColumn}
              sortOrder={sortOrder}
              handlePageClick={(data) => {
                const selectedPage = data.selected + 1;
                dispatch(patrollingRouteGetAll(`&page=${selectedPage}`));
              }}
            />
          )}
        </CardBody>
      </Card>
    </Flex>
    </>
  );
};

export default GroupPatrollingRouteTable;
