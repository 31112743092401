
import axios from 'axios';
import {
  PLANTADMIN_LOADING,
  PLANTADMIN_GET_ALL_SUCCESS,
  PLANTADMIN_ERROR,
  PLANTADMIN_CLEAR_SNACKBAR
} from '../constants/types';

import { server_url } from '../constants/types';

export const PlantAdminGetAll = (pagination = "") => {

  return (dispatch, getState) => {
    return new Promise((resolve) => {
      console.log("it is working")
      dispatch({
        type: PLANTADMIN_LOADING,
        payload: {
          loading: true
        }
      });
      axios.get(`${server_url}/admin/plantAdmin?${pagination}`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      })
        .then(response => {
          console.log("response.data-plant")
          console.log(response.data)
          dispatch({
            type: PLANTADMIN_GET_ALL_SUCCESS,
            payload: {
              plantList: response.data['data'],
              response: response.data,
              plantDetail: {},
              loginDetail: {},
              loading: false,
              status: "success",
              text: "Get All user data successfully."
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: PLANTADMIN_ERROR,
            payload: {
              status: "error",
              text: "Error occurred during getting plantadmin data.",
              loading: false
            }
          });
          resolve();
        })
    })
  }
}






export const plantadminAddOrUpdate = (admin, state) => {
  console.log("admin")
  console.log(admin)
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PLANTADMIN_LOADING,
        payload: {
          loading: true
        }
      });
      if (state === "add") {

        axios.post(`${server_url}/admin/plantAdmin`, admin, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
          .then(responseF => {
            axios.get(`${server_url}/admin/plantAdmin`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
              .then(response => {
                dispatch({
                  type: PLANTADMIN_GET_ALL_SUCCESS,
                  payload: {
                    plantList: response.data['data'],
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']

                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: PLANTADMIN_ERROR,
                  payload: {
                    text: "Error occurred during getting plantadmin data.",
                    status: "error",
                    loading: false
                  }
                });
                resolve();
              })
          })
          .catch((e) => {
            dispatch({
              type: PLANTADMIN_ERROR,
              payload: {
                text: "Error occurred during adding plantadmin data.",
                status: "error",
                loading: false
              }
            });
            resolve();
          })
      } else if (state === "edit") {
        axios.put(`${server_url}/plant/updatePlantAdmin/${admin._id}`, admin, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
          .then(responseF => {
            axios.get(`${server_url}/admin/plantAdmin`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
              .then(response => {
                dispatch({
                  type: PLANTADMIN_GET_ALL_SUCCESS,
                  payload: {
                    plantList: response.data['data'],
                    plantDetail: responseF.data['data'],
                    status: responseF.data.status,
                    text: responseF.data.message
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: PLANTADMIN_ERROR,
                  payload: {
                    text: "Error occurred during getting plantadmin data.",
                    status: "error",
                    loading: false
                  }
                });
                resolve();
              })
          })
          .catch((e) => {
            dispatch({
              type: PLANTADMIN_ERROR,
              payload: {
                text: "Error occurred during updating plantadmin data.",
                status: "error",
                loading: false
              }
            });
            resolve();
          })
      }
      else if (state === "view") {
        axios.put(`${server_url}/plant/updatePlantAdmin/${admin._id}`, admin, {
          headers: {

            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
          .then(responseF => {
            axios.get(`${server_url}/admin/plantAdmin`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
              .then(response => {
                console.log("12334454")
                console.log(responseF.data['data']);
                dispatch({
                  type: PLANTADMIN_GET_ALL_SUCCESS,
                  payload: {
                    plantList: response.data['data'],
                    plantDetail: responseF.data['data'],
                    status: responseF.data.status,
                    text: responseF.data.message
                  }

                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: PLANTADMIN_ERROR,
                  payload: {
                    text: "Error occurred during getting admin data.",
                    status: "error",
                    loading: false
                  }
                });
                resolve();
              })
          })
          .catch((e) => {
            dispatch({
              type: PLANTADMIN_ERROR,
              payload: {
                text: "Error occurred during updating plantadmin data.",
                status: "error",
                loading: false
              }
            });
            resolve();
          })

      }
    })
  }
}