import React from "react";
import {
  chakra,
  Badge,
  Td,
  Tr,
  Text,
  useColorModeValue,
  Flex,
  ButtonGroup,
  IconButton,
  Link,
  Image,
  Tooltip
} from "@chakra-ui/react";
import { InfoOutlineIcon, ViewIcon } from "@chakra-ui/icons";

const DelivaryInOutTableRow = (props) => {
  const {
    name ,
    date ,
    // image,
    contact,
    DeliveryFrom,
    Department,
    SubDepartment,
    Parcel,
    // Totalparcel,
    serialnumber,
    Status ,
    inTime,
    outTime,
    isLast,
    id,
    pending,
    handedOver,
    lastStatus,
    logText,
    totalTime
   } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <>
      <Tr>
      <Td
          >
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={titleColor}
                  fontWeight="bold"
                  minWidth="100%"
                >
                  {serialnumber}
                </Text>
              </Flex>
            </Flex>
          </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {date}
              </Text>
            </Flex>
          </Flex>
        </Td>

       
        {/* <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
            <Image
                boxSize='100px'
                objectFit='cover'
                src={image}
                alt='Dan Abramov'
              />
              
            </Text>
          </Flex>
        </Td> */}

        

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {DeliveryFrom}
            </Text>
          </Flex>
        </Td>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {Department}
            </Text>
          </Flex>
        </Td>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {SubDepartment}
            </Text>
          </Flex>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {Parcel}
            </Text>
          </Flex>
        </Td>

        {/* <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {Totalparcel}
            </Text>
          </Flex>
        </Td> */}
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
            <Badge colorScheme={Status == "NEW REQUEST" || Status == "APPROVED" || Status == "REACHED" || Status == "RECIEVED" || Status == "ALLOW"  ? "green" : "red"}>{Status}</Badge>
          </Td>
          <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {handedOver}
            </Text>
          </Flex>
        </Td>
          <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {pending}
            </Text>
          </Flex>
        </Td>

          <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {inTime}
            </Text>
          </Flex>
        </Td>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {outTime}
            </Text>
          </Flex>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {totalTime}
            </Text>
          </Flex>
        </Td>
        <Td
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
          overflowX="auto" // Add overflowX property for horizontal scrollbar
        >
          <Flex direction="column">
            {lastStatus.map((logItem, index) => (
              <Text key={index} fontSize="md" color={textColor} whiteSpace="nowrap">
                {logItem.description} { }
                <Tooltip hasArrow label={logText} aria-label='A tooltip' >
              <InfoOutlineIcon  />
            </Tooltip>
              </Text>
            ))}
            
          </Flex>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <ButtonGroup>
            <Link href={`#/admin/delivary-view/${id}`}>
              <IconButton
                colorScheme="blue"
                icon={<ViewIcon />}
                aria-label="Up"
              />
            </Link>
            </ButtonGroup>
            </Td>
      </Tr>
    </>
  );
};

export default DelivaryInOutTableRow;
