const {
    VISITOR_PREAPPROVAL_LOADING,
    VISITOR_PREAPPROVAL_GET_ALL_SUCCESS,
    VISITOR_PREAPPROVAL_ERROR,
    VISITOR_PREAPPROVAL_CLEAR_SNACKBAR,
    VISITOR_PREAPPROVAL_SEARCH
  } = require('../constants/types');
  
  const initialState = {
    response: {},
    visitorPreapprovalList: [],
    visitorPreapprovalDetail: {},
    loading: false,
    status: 'success',
    text: '',
    showSnackbar: false,
    limit: 0,  // Add missing properties
    page: 0,
    totalPages: 0,
    searching: '',  // Add missing properties
    totalItems : 0
  };
  
  const VisitorPreapprovalReducer = (state = initialState, action) => {
    switch (action.type) {
      case VISITOR_PREAPPROVAL_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case VISITOR_PREAPPROVAL_LOADING:
        return {
          ...state,
          loading: true
        };
      case VISITOR_PREAPPROVAL_ERROR:
        return {
          ...state,
          visitorPreapprovalList: [],
          response: {},
          visitorPreapprovalDetail: {},
          limit: 0,  // Add missing properties
          page: 0,
          totalPages: 0,
          totalItems: 0,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case VISITOR_PREAPPROVAL_GET_ALL_SUCCESS:
        return {
          ...state,
          visitorPreapprovalList: action.payload.visitorPreapprovalList,
          response: action.payload.response,
          visitorPreapprovalDetail: action.payload.visitorPreapprovalDetail,
          limit: action.payload.limit,
          page: action.payload.page,
          totalPages: action.payload.totalPages,
          totalItems: action.payload.totalItems,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
        case VISITOR_PREAPPROVAL_SEARCH: // Add the new case
      return {
        ...state,
        searching: action.payload.searching
      };
      default:
        return state || {};
    }
  };
  
  export default VisitorPreapprovalReducer;
  // module.exports = VisitorInOutReducer;