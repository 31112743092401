// Chakra imports
import {
    Box,
    Button,
    Center,
    Flex,
    Image,
    Input,
    SimpleGrid,
    Spacer,
    Stack,
    Text,
    Wrap,
    WrapItem,
    useColorModeValue
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from 'react-redux';

  import { useToast } from '@chakra-ui/react'
  import { useParams } from "react-router-dom/cjs/react-router-dom.min";
  import { GUARDATTENDENCE_CLEAR_SNACKBAR } from "constants/types";
  import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
  } from '@chakra-ui/react'
  import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'

import { GuardGetById } from "actions/GuardAttendenceAction";

  
  function GuardAttendenceView() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [guardName, setGuardName] = useState("");
    const [guardPhoto, setGuardPhoto] = useState("");
    const [phone, setPhone] = useState("");
    const [email , setEmail] = useState('');
    const [inDateTime, setInDateTime] = useState("");
    const [outDateTime, setOutDateTime] = useState("");
    const [image, setImage] = useState("");
  
    const dispatch = useDispatch();
    const toast = useToast();
  
    const statuses = ['success', 'error', 'warning', 'info'];
    const responseStatus = useSelector(state => state.guardattendence.status);
    const responseMessage = useSelector(state => state.guardattendence.text);
    const loading = useSelector(state => state.guardattendence.loading);
    const guardattendenceDetail = useSelector(state => state.guardattendence.guardattendenceDetail);
    const showSnackbar = useSelector(state => state.guardattendence.showSnackbar);
  
    const params = useParams();
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === 'OK' && loading === false) {
          console.log(guardattendenceDetail);
          setGuardName(guardattendenceDetail.guard.name);
          setGuardPhoto(guardattendenceDetail.guard.photo);
          setPhone(guardattendenceDetail.guard.number);
          setEmail(guardattendenceDetail.guard.email);
          setInDateTime(guardattendenceDetail.type === "IN" ?(guardattendenceDetail.attendanceAt):(<></>));
          setOutDateTime(guardattendenceDetail.type === "OUT" ?(guardattendenceDetail.attendanceAt):(<></>))
          setImage(guardattendenceDetail.image);
         
         
            
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'success');
          }
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'error');
          }
        }
      }
  
      dispatch({
        type: GUARDATTENDENCE_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        }
      })
    }, [loading]);
  
    useEffect(() => {
      console.log("params");
      const GuardId = params.id;
      const Guard = {
        _id : GuardId
      };
      dispatch(GuardGetById(Guard._id));
    }, []);
  
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      })
    };

    
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              View Form
            </Text>
          </CardHeader>
          <CardBody>
            {loading ? <Stack>
              <Skeleton height='20px' />
              <Skeleton height='20px' />
              <Skeleton height='20px' />
            </Stack>
              :
              <TableContainer>
                <Table variant='simple'>
                  <Tbody>
                    <Tr>
                      <Td>Guard Name</Td>
                      <Td>{guardName}</Td>
                    </Tr>
                    <Td>Guard Photo</Td>
                    <Td><Flex direction="column">
                        <Text fontSize="md" color={textColor} fontWeight="bold">
                        <Image
                            boxSize='100px'
                            objectFit='cover'
                            src={guardPhoto}
                            alt='Dan Abramov'
                          />
                          
                        </Text>
                      </Flex>
                      </Td>
                    <Tr>
                      <Td>Phone</Td>
                      <Td>{phone}</Td>
                    </Tr>
                    <Tr>
                      <Td>Email</Td>
                      <Td>{email}</Td>
                    </Tr>
                    <Tr>
                      <Td>In DateTime</Td>
                      <Td>{inDateTime}</Td>
                    </Tr>
                    <Tr>
                      <Td>Out DateTime</Td>
                      <Td>{outDateTime}</Td>
                    </Tr>
                    <Tr>
                      <Td>Image</Td>
                      <Td>{image}</Td>
                    </Tr>
                 
                  </Tbody>
                </Table>
              </TableContainer>}
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default GuardAttendenceView;
