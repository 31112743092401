import {
    Box,
    Button,
    Center,
    Flex,
    Input,
    SimpleGrid,
    Spacer,
    Text,
    Wrap,
    WrapItem,
    useColorModeValue,
    Select,
  } from "@chakra-ui/react";
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from 'react-redux';
  import { employeeTypeAddOrUpdate, employeeTypeClearShowSnackbar } from '../../actions/EmployeeTypeAction'; // Import your employee type actions
  import {
    FormControl,
    FormLabel,
    FormErrorMessage,
  } from '@chakra-ui/react';
  import { useToast } from '@chakra-ui/react';
  import { EMPLOYEETYPE_CLEAR_SNACKBAR } from "constants/types"; // Import your employee type constants
  import { Spinner } from '@chakra-ui/react';
  import dataConstant from "constants/dataConstant"; // Import your employee type data constants
  
  function EmployeeTypeForm() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [status, setStatus] = useState("");

    const [alertMessage, setAlertMessage] = useState('');
    const [isErrorName, setIsErrorName] = useState(false);
    const [isErrorDescription, setIsErrorDescription] = useState(false);
    // const [isErrorImage, setIsErrorImage] = useState(false);
    const [isErrorStatus, setIsErrorStatus] = useState(false);
  
  
    const dispatch = useDispatch();
    const toast = useToast();
  
    const statuses = ['success', 'error', 'warning', 'info'];
    const responseStatus = useSelector(state => state.employeeType.status); // Update with your employee type state
    const responseMessage = useSelector(state => state.employeeType.text); // Update with your employee type state
    const loading = useSelector(state => state.employeeType.loading); // Update with your employee type state
    const showSnackbar = useSelector(state => state.employeeType.showSnackbar); // Update with your employee type state
  

    console.log(loading + "LOder");
    const handleSubmit = (event) => {
      event.preventDefault();
      setIsErrorName(name === '');
      setIsErrorStatus(status === '');
      setIsErrorDescription(description === '');
  
      if (name !== "" && status !== "" && description !== "") {
        const employeeTypeData = {
          name: name,
          description: description,
          status: status,
        };
  
        const state = 'add';
        dispatch(employeeTypeAddOrUpdate(employeeTypeData, state));
      }
    };
  
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === 'OK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'success');
          }
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'error');
          }
        }
      }
      dispatch({
        type: EMPLOYEETYPE_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        }
      });
    }, [loading]);
  
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      });
    };
  
    return (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
          <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
            
            <CardHeader p="6px 0px 22px 0px">
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                Form
              </Text>
              <SimpleGrid minChildWidth='120px' spacing='40px'>
                <FormControl isInvalid={isErrorName}>
                  <FormLabel>Employee Type Name</FormLabel>
                  <Input
                    type='text'
                    onChange={(e) => {
                      setIsErrorName(e.target.value === '')
                      setName(e.target.value)
                    }}
                  />
                  {!isErrorName ? (
                    <></>
                  ) : (
                    <FormErrorMessage>Employee Type Name is required.</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={isErrorDescription}>
                  <FormLabel>Description</FormLabel>
                  <Input
                    type='text'
                    onChange={(e) => {
                      setIsErrorDescription(e.target.value === '')
                      setDescription(e.target.value)
                    }}
                  />
                  {!isErrorDescription ? (
                    <></>
                  ) : (
                    <FormErrorMessage>Description is required.</FormErrorMessage>
                  )}
                </FormControl>
               
              </SimpleGrid>
              <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
                <FormControl isInvalid={isErrorStatus}>
                  <FormLabel>Status</FormLabel>
                  <Select
                    placeholder="Select status"
                    onChange={(e) => {
                      setIsErrorStatus(e.target.value === '')
                      setStatus(e.target.value)
                    }}
                  >
                    { dataConstant.status.map((element,index) => {
                        return (<option key={element['id']} value={element['value']} >{element['name']}</option>);   
                          
                      })}
                  </Select>
                  {!isErrorStatus ? (
                    <></>
                  ) : (
                    <FormErrorMessage>Status is required.</FormErrorMessage>
                  )}
                </FormControl>
               
              </SimpleGrid>
              <Center>
                {/* {loading ? <Spinner mt='24px' size='lg' /> :
                  <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                    Submit
                  </Button>
                } */}
                 <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                    Submit
                  </Button>
              </Center>
            </CardHeader>
            <CardBody>
            </CardBody>
          </Card>
        </Flex>
      );
  }
  
  export default EmployeeTypeForm;
  