// Chakra imports
import {
    Box,
    Button,
    Center,
    Checkbox,
    Flex,
    Input,
    Select,
    SimpleGrid,
    Spacer,
    Stack,
    Text,
    Wrap,
    WrapItem,
    useColorModeValue
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { tablesProjectData, tablesTableData } from "variables/general";
  import { useDispatch, useSelector } from 'react-redux';
  import { roleAddOrUpdate } from '../../actions/roleActions';
  import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
  } from '@chakra-ui/react'
  import { useToast } from '@chakra-ui/react'
  import { USER_CLEAR_SNACKBAR } from "constants/types";
  import { Spinner } from '@chakra-ui/react'
import { moduleGetAll } from "actions/moduleActions";
import { permissionGetAll } from "actions/permissionActions";
import { ROLE_CLEAR_SNACKBAR } from "constants/types";
import {
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from '@chakra-ui/react'
import dataConstant from "constants/dataConstant";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { server_url } from '../../constants/types';
  
  function RoleUpdateForm() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [name,setName]= useState("");
    const [permissions,setPermissions]= useState([]);
    const [allPermission,setAllPermission]= useState("");
    const [contactNo,setContactNo]= useState("");
    const [alertMessage, setAlertMessage] = useState('iii');
    const [isErrorName, setIsErrorName] = useState(false);
    const [isErrorAllPermission, setIsErrorAllPermission] = useState(false);
    const [isErrorContactNo, setIsErrorContactNo] = useState(false);
    const [isErrorPassword, setIsErrorPassword] = useState(false);
    const [permissionStyle, setPermissionStyle] = useState({
      display : "none"
    });
    
    const dispatch = useDispatch();
    const toast = useToast();
  
    const statuses = ['success', 'error', 'warning', 'info'];
    const responseStatus = useSelector(state => state.role.status);
    const responseMessage = useSelector(state => state.role.text);
    const roleloading = useSelector(state => state.role.loading);
    const loading = useSelector(state => state.module.loading);
    const showSnackbar = useSelector(state => state.role.showSnackbar);
    const moduleList = useSelector(state => state.module.moduleList);
    const roleDetail = useSelector(state => state.role.roleDetail);
    const permissionList = useSelector(state => state.permission.permissionList);

    const params = useParams();
    const history = useHistory()
    console.log("moduleList")
    console.log(moduleList)
    console.log(permissionList)
  
     // Function to handle form submission
     const handleSubmit = (event) => {
      event.preventDefault();
        setIsErrorName(name=='');
        setIsErrorAllPermission(allPermission=='');
      if(name != "" && allPermission != "" )
      {
        console.log("Submit");
        var newPermissions = [];
        selectedPermissions.forEach(element => {
          newPermissions.push({
            'permissionId': element._id,
            'permissionName' : element.name,
            'moduleId' : element.modules._id,
            'moduleName' : element.modules.name
          });
        });
        // Get form data
        const role = {
          _id : params.id,
          name: name,
          allPermissions: allPermission,
          permissions: newPermissions,
          // Other user data fields
        };
        console.log(role);
  
        const state = 'edit';
        // Call the userAddOrUpdate action
        dispatch(roleAddOrUpdate(role, state))
      }
      else
      {
        
      }
      
      
      
    };
    useEffect(() => {
      if(showSnackbar)
      {
        if (responseStatus == 'OK' && loading == false) {
            console.log(roleDetail.permissions);
            console.log("roleDetail");
            setName(roleDetail.name);
            setAllPermission(roleDetail.allPermissions?'true':'false');
            if(!roleDetail.allPermissions)
            {
                setPermissionStyle({display : 'block'});
            }
            setPermissions(roleDetail.permissions);
          if(responseMessage !="")
          {
            toastFunction(responseMessage, 'success');
          }
        } else if (responseStatus == 'NOK' && loading == false) {
          if(responseMessage !="")
          {
            toastFunction(responseMessage, 'error');
          }
        }
      }
      dispatch({
        type: ROLE_CLEAR_SNACKBAR,
        payload: {
            showSnackbar: false,
        }
    })
    }, [loading,roleloading]);
  
    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
    }, [])

    useEffect(() => {
        const role = {
            '_id' : params.id
          };
        dispatch(roleAddOrUpdate(role, 'view'))
        dispatch(moduleGetAll());
        dispatch(permissionGetAll());
    }, []);
  
    const toastFunction = (title,status) =>
    {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      })
    };

    const [selectedPermissions, setSelectedPermissions] = useState([]);

    const handlePermissionChange = (permissionId, moduleId,checked=false) => {
      
        setSelectedPermissions((prevSelectedPermissions) => {
          const existingPermissionIndex = prevSelectedPermissions.findIndex(
            (permission) => 
              permission._id === permissionId &&
              permission.modules._id === moduleId
              
            
              
          );
            console.log(existingPermissionIndex);
          if (existingPermissionIndex !== -1) {
            // Permission already exists, remove it
            console.log("Permission already exists, remove it");
            const updatedPermissions = [...prevSelectedPermissions];
            updatedPermissions.splice(existingPermissionIndex, 1);
            return updatedPermissions;
          } else {
            // Permission does not exist, add it
            const permission = permissionList.find(
              (permission) =>
                permission._id === permissionId && permission.modules._id === moduleId
            );
      
            return [...prevSelectedPermissions, permission];
          }
        });
      
    };

    useEffect(() => {
      var initPerArry = [];
      permissions.forEach(element => {
        const permission = permissionList.find(
          (permission) =>
            permission._id === element.permissionId && permission.modules._id === element.moduleId
        );
        if(permission != null)
        {
          initPerArry.push(permission);
        }
        
       });
      setSelectedPermissions(initPerArry);
    }, [permissions]);

    
    
    
    
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Role Form
            </Text>
            <SimpleGrid minChildWidth='120px' spacing='40px'>
            <FormControl isInvalid={isErrorName}>
                <FormLabel>Role Name</FormLabel>
                <Input type='text'
                value={name}
                onChange={(e) => {
                  setIsErrorName( e.target.value === '')
                  setName(e.target.value)}}
                 />
                 {!isErrorName ? (
                  <></>
                ) : (
                  <FormErrorMessage>Name is required.</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={isErrorAllPermission}>
                <FormLabel>All Permission</FormLabel>
                <Select
                placeholder="Select all permission"
                onChange={(e) => {
                  setIsErrorAllPermission (e.target.value === '')
                  setAllPermission(e.target.value)
                  if(e.target.value == 'false')
                  {
                    setPermissionStyle({display : "block"});
                  }
                  else{
                    setPermissionStyle({display : "none"});
                  }
                }}
              >
                { dataConstant.boolean.map((element,index) => {
                    return (<option key={element['id']} value={element['name']} selected={allPermission==element['name']?true:false}>{element['name']}</option>);   
                      
                  })}
              </Select>
                {!isErrorAllPermission ? (
                  <></>
                ) : (
                  <FormErrorMessage>All Permission is required.</FormErrorMessage>
                )}
              </FormControl>
            
          </SimpleGrid>
          <FormControl mt='24px' style={permissionStyle}>
          <FormLabel>Permissions</FormLabel>
          <Stack spacing={4}>
          <OrderedList>
            {moduleList.map((module) => (
              <Stack key={module?._id} direction="row" alignItems="center" mb={4}>
                <ListItem>{module?.name}</ListItem>
                {permissionList
                  .filter((permission) => permission.modules?._id === module?._id)
                  .map((permission) => (
                    <Checkbox
                      key={permission._id}
                      isChecked={selectedPermissions.some(
                        (per) =>
                          per._id === permission._id &&
                          per.modules._id === permission.modules._id
                      )}
                      onChange={() =>
                        handlePermissionChange(
                          permission._id,
                          permission.modules._id
                        )
                      }
                    >
                      {permission.name}
                    </Checkbox>
                  ))}
              </Stack>
            ))}
          </OrderedList>
  {/* <pre>{JSON.stringify(selectedPermissions, null, 2)}</pre> */}
</Stack>

          </FormControl>
          <Center>
          {loading ? <Spinner mt='24px' size='lg'/>
          :
          <Button colorScheme='blue' mt='24px' 
          onClick={handleSubmit}
          >Submit</Button>}
          </Center>
          </CardHeader>
          <CardBody>
            
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default RoleUpdateForm;
  