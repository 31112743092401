import React from "react";
import {
  Tr,
  Td,
  Badge,
  Flex,
  Text,
  IconButton,
  useColorModeValue,
  ButtonGroup,
  Spacer,
  Link,
} from "@chakra-ui/react";
import { BsBoxArrowUpRight, BsFillTrashFill } from "react-icons/bs";
import { ViewIcon } from "@chakra-ui/icons";
import { AiFillEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { parkingDelete } from "../../actions/parkingAction";

const DepartmentTableRow = (props) => {
  const {
    serialnumber,
    departmentName,
     type,
     twoWheeler,
     fourWheeler,
     bus,
     loadedBays,
     unloadedBays,
     parkingStatus,
     isLast,
     id,
     plant=""
  } = props;

  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const authDetail = useSelector((state) => state.auth.authDetail);
  const dispatch = useDispatch();

  const handleDelete = (parkingId) => {
    dispatch(parkingDelete(parkingId,plant));
  };

  return (
    <>
      <Tr>
      <Td
          >
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={titleColor}
                  fontWeight="bold"
                  minWidth="100%"
                >
                  {serialnumber}
                </Text>
              </Flex>
            </Flex>
          </Td>
        <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {departmentName}
              </Text>
            </Flex>
          </Flex>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {type}
            </Text>
          </Flex>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {twoWheeler}
            </Text>
          </Flex>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {fourWheeler}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {bus}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {loadedBays}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {unloadedBays}
            </Text>
          </Flex>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
            <Badge colorScheme={parkingStatus == "Active" ? "green" : "red"}>{parkingStatus}</Badge>
          </Td>



        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          {authDetail.role.allPermissions === false ? (
            <ButtonGroup variant="solid" size="sm" spacing={3}>
              {authDetail.role.permissions.map((permission) => {
                const { permissionName, moduleName } = permission;

                if (
                  permissionName === "VIEW" &&
                  moduleName === "PARKING"
                ) {
                  return (
                    <Link href={`#/admin/parking-view/${id}`}>
                      <IconButton
                        key={permission.id}
                        colorScheme="blue"
                        icon={<ViewIcon />}
                        aria-label="View"
                      />
                    </Link>
                  );
                }

                if (
                  permissionName === "EDIT" &&
                  moduleName === "PARKING"
                ) {
                  return (
                    <Link href={`#/admin/parking-update/${id}`}>
                      <IconButton
                        key={permission.id}
                        colorScheme="green"
                        icon={<AiFillEdit />}
                        aria-label="Edit"
                      />
                    </Link>
                  );
                }

                if (
                  permissionName === "DELETE" &&
                  moduleName === "PARKING"
                ) {
                  return (
                    <IconButton
                      key={permission.id}
                      colorScheme="red"
                      variant="outline"
                      icon={<BsFillTrashFill />}
                      aria-label="Delete"
                      onClick={() => handleDelete(id)}
                    />
                  );
                }

                return null;
              })}
            </ButtonGroup>
          ) : (
            <ButtonGroup>
              <Link href={`#/admin/parking-view/${id}`}>
                <IconButton
                  colorScheme="blue"
                  icon={<ViewIcon />}
                  aria-label="View"
                />
              </Link>
              <Link href={`#/admin/parking-update/${id}`}>
                <IconButton
                  colorScheme="green"
                  icon={<AiFillEdit />}
                  aria-label="Edit"
                />
              </Link>
              <IconButton
                colorScheme="red"
                variant="outline"
                icon={<BsFillTrashFill />}
                aria-label="Delete"
                onClick={() => handleDelete(id)}
              />
            </ButtonGroup>
          )}
        </Td>
      </Tr>
    </>
  );
};

export default DepartmentTableRow;
