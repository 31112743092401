// Chakra imports
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Spinner,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import { tablesProjectData, tablesTableData } from "variables/general";
import { useDispatch, useSelector } from 'react-redux';
import { userGetAll, userDelete, userAddOrUpdate, userClearShowSnackbar } from '../../actions/userActions';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { USER_CLEAR_SNACKBAR } from "constants/types";

function UpdateForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name,setName]= useState("");
  const [email,setEmail]= useState("");
  const [password,setPassword]= useState("");
  const [contactNo,setContactNo]= useState("");
  const [alertMessage, setAlertMessage] = useState('iii');
  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isErrorContactNo, setIsErrorContactNo] = useState(false);
  const [isErrorPassword, setIsErrorPassword] = useState(false);
  
  const dispatch = useDispatch();
  const toast = useToast();

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.user.status);
  const responseMessage = useSelector(state => state.user.text);
  const loading = useSelector(state => state.user.loading);
  const userDetail = useSelector(state => state.user.userDetail);
  const showSnackbar = useSelector(state => state.user.showSnackbar);

  const params = useParams();
   // Function to handle form submission
   const handleSubmit = (event) => {
    event.preventDefault();
    if(!isErrorName && !isErrorEmail && !isErrorContactNo && !isErrorPassword)
    {
      // Get form data
      const user = {
        _id : params.id,
        name: name,
        email: email,
        password: password,
        contactNo: contactNo,
        // Other user data fields
      };
      console.log(user);

      // Determine if it's an add or edit operation
      const state = 'edit';
      // Call the userAddOrUpdate action
      dispatch(userAddOrUpdate(user, state))
    }
    else
    {
      setIsErrorName(name=='');
      setIsErrorEmail(email=='');
      setIsErrorContactNo(contactNo=='');
      setIsErrorPassword(password =='');
    }
    
    
    
  };
  useEffect(() => {
    if(showSnackbar)
    {
      if (responseStatus == 'OK' && loading == false) {
        console.log(userDetail);
          setName(userDetail.name);
          setEmail(userDetail.email);
          setContactNo(userDetail.contactNo);
          setPassword(userDetail.password);
        if(responseMessage !="")
        {
          toastFunction(responseMessage, 'success');
        }
        
      } else if (responseStatus == 'NOK' && loading == false) {
        if(responseMessage !="")
        {
          toastFunction(responseMessage, 'error');
        }
        
      }
    }

    dispatch({
      type: USER_CLEAR_SNACKBAR,
      payload: {
          showSnackbar: false,
      }
  })
  }, [loading]);

  useEffect(() => {
    console.log(params);
    const state = 'edit';
      // Call the userAddOrUpdate action
      const user = {
        '_id' : params.id
      };
      dispatch(userAddOrUpdate(user, state))
  }, []);

  const toastFunction = (title,status) =>
  {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Update Form
          </Text>
          <SimpleGrid minChildWidth='120px' spacing='40px'>
          <FormControl isInvalid={isErrorName}>
              <FormLabel>Name</FormLabel>
              <Input type='text'
              value={name}
              onChange={(e) => {
                setIsErrorName( e.target.value === '')
                setName(e.target.value)}}
               />
               {!isErrorName ? (
                <></>
              ) : (
                <FormErrorMessage>Name is required.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={isErrorEmail}>
              <FormLabel>Email</FormLabel>
              <Input type='email'
              value={email}
              onChange={(e) => {
                setIsErrorEmail( e.target.value === '')
                setEmail(e.target.value)
              }}
              />
              {!isErrorEmail ? (
                <></>
              ) : (
                <FormErrorMessage>Email is required.</FormErrorMessage>
              )}
            </FormControl>
          
        </SimpleGrid>
        <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            <FormControl isInvalid={isErrorContactNo}>
              <FormLabel>Phone Number</FormLabel>
              <Input type='text'
              value={contactNo}
              onChange={(e) => {
                setIsErrorContactNo( e.target.value === '')
                setContactNo(e.target.value)}}
              />
              {!isErrorContactNo ? (
                <></>
              ) : (
                <FormErrorMessage>Contact No. is required.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={isErrorPassword}>
              <FormLabel>Password</FormLabel>
              <Input type='password' 
              value={password}
              onChange={(e) => {
                setIsErrorPassword( e.target.value === '')
                setPassword(e.target.value)}}
              />
              {!isErrorPassword ? (
                <></>
              ) : (
                <FormErrorMessage>Password is required.</FormErrorMessage>
              )}
            </FormControl>
          
        </SimpleGrid>
        <Center>
       {loading ? <Spinner size='lg'mt='24px' />
       : <Button colorScheme='blue' mt='24px' 
        onClick={handleSubmit}
        >Submit</Button>}
        </Center>
        </CardHeader>
        <CardBody>
          
        </CardBody>
      </Card>
      {/* <Card
        my="22px"
        overflowX={{ sm: "scroll", xl: "hidden" }}
        pb="0px"
      >
      
      </Card> */}
    </Flex>
  );
}

export default UpdateForm;
