// Chakra imports
import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Input,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { bannerPositionAddOrUpdate, bannerPositionClearShowSnackbar } from "actions/inspectionAction";
import { useToast } from '@chakra-ui/react'
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { BANNER_POSITION_CLEAR_SNACKBAR } from "constants/types";
import axios from 'axios';
import { server_url } from '../../constants/types';
import { useHistory } from 'react-router-dom';

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'
import dataConstant from "constants/dataConstant";
import QRCode from 'react-qr-code';
import { toPng } from 'html-to-image';

function InspectionQrView() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [inspectionName, setInspectionName] = useState("");
  const [duration, setDuration] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [plantAdmin, setPlantAdmin] = useState("");
  const [qrCheckList, setQrCheckList] = useState([]);


  const [status, setStatus] = useState('false');
  const [type, setType] = useState("");

  const dispatch = useDispatch();
  const toast = useToast();

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.inspectionqr.status);
  const responseMessage = useSelector(state => state.inspectionqr.text);
  const loading = useSelector(state => state.inspectionqr.loading);
  const showSnackbar = useSelector(state => state.inspectionqr.showSnackbar);
  const history = useHistory();

  const params = useParams();

  useEffect(() => {
    console.log("params");
    const id = params.id;
    axios
      .get(`${server_url}/admin/inspection/items-qr/${id}`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        // if(responce.data.data)
        // {
        const data = responce.data.data;
          setId(data.inspectionItemQr._id);
          setName(data.inspectionItemQr.name);
          setDescription(data.inspectionItemQr.description);
          setType(data.inspectionItemQr.type);
          setStatus(data.inspectionItemQr.status);
          setInspectionName(data.inspectionItemQr?.inspectionItem?.name);
          setDuration(data.inspectionItemQr.inspectionSchedule);
          setQrCode(data.inspectionItemQr.qrCode);
          setQrCheckList(data.inspectionCheckList);
          
          
          setPlantAdmin(responce.data.data.plant?._id);
          console.log("I am here");
        // }
      })
      .catch((e) => {

      });
  }, []);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  return (
    <>
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="18px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Inspection QR Detail
          </Text>
        </CardHeader>
        <CardBody>
          {loading ? <Stack>
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
          </Stack>
            :
            <TableContainer>
              <Table variant='simple'>
                <Tbody>
                <Tr>
                    <Td>Inspection Name</Td>
                    <Td>{inspectionName}</Td>
                  </Tr>
                  <Tr>
                    <Td>Name</Td>
                    <Td>{name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Type</Td>
                    <Td>{type}</Td>
                  </Tr>
                  <Tr>
                    <Td>Description</Td>
                    <Td>{description}</Td>
                  </Tr>
                  <Tr>
                    <Td>Duration</Td>
                    <Td>{duration}</Td>
                  </Tr>
                  
                  <Tr>
                    <Td>Status</Td>
                    <Td>{status?'Active':'Inactive'}</Td>
                  </Tr>

                  <Tr>
                    <Td>Qr code</Td>
                    <Td><QRCode size={100} value={qrCode.toString()} /></Td>
                  </Tr>
                  
                </Tbody>
              </Table>
            </TableContainer>}
        </CardBody>
      </Card>
    </Flex>
    <Flex direction="column" pt={{ base: "120px", md: "10px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="10px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
            Check List
            </Text>
          </CardHeader>
          <CardBody>
         {loading ? (
            <Stack>
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
            </Stack>
          ) : qrCheckList ? (
            <TableContainer >
                <Table variant='simple'>
                  <Thead>
                    <Tr>
                      <Th color="white">SL</Th>
                      <Th color="white">Title</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                   
                    {
                      qrCheckList?.map((row, index, arr) => {

                        return (
                         
                           <Tr>
                            <Td>{index +1}</Td>
                            <Td>{row.title.en}</Td>
                          </Tr>
                        );
                      })}
                      
                  
                  
                    
                  
                  </Tbody>
                </Table>
                </TableContainer>
          ) : null}
        </CardBody>
        </Card>
      </Flex>
    </>
  );
}

export default InspectionQrView;