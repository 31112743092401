import React, { useEffect, useState } from 'react';
import { Flex, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import EmployeeAttendencePagination from './EmployeeAttendencePagination';
import { AddIcon } from '@chakra-ui/icons';
import { EMPLOYEEATTENDENCE_CLEAR_SNACKBAR } from 'constants/types';

import { EmployeeAttendenceOut } from '../../actions/EmployeeAttendenceAction';
import EmployeeAttendenceOutTableRow from "./EmployeeAttendenceOutTableRow";
import EmployeeAttendenceOutTablePagination from './EmployeeAttendenceOutTablePagination';

const EmployeeAttendenceOutTable = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.employeeattendence.status);
  const responseMessage = useSelector(state => state.employeeattendence.text);
  const loading = useSelector(state => state.employee.loading);
  const employeeattendenceListOut = useSelector(state => state.employeeattendence.employeeattendenceListOut);
  const response = useSelector(state => state.employeeattendence.response);
  const showSnackbar = useSelector(state => state.employeeattendence.showSnackbar);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    dispatch(EmployeeAttendenceOut());
  }, [dispatch]);
  console.log("employeeattendenceList");
  console.log(employeeattendenceListOut);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: EMPLOYEEATTENDENCE_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
    dispatch(EmployeeAttendenceOut(sorting));
  };

  useEffect(() => {
    const searching = "&search=" + search;
    dispatch(EmployeeAttendenceOut(searching));
  }, [search]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Employee Attendence Table
            </Text>
          </CardHeader>
          <Spacer />

          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              <InputGroup borderRadius="8px" w="200px">
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon
                          color={searchIconColor}
                          w="15px"
                          h="15px"
                        />
                      }
                    ></IconButton>
                  }
                />
                <Input
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.length < 3) {
                      setSearch("");
                    }
                  }}
                  placeholder="Type here..."
                />
              </InputGroup>
            </Text>
          </CardHeader>
        </Flex>
        <CardBody pb={4}>
        <div style={{ overflowX: "auto" }}>
          <Table variant="simple" color={textColor}>
          <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
              <Th
                    pl="15px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                   SNO
                  </Th>
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("employeename")}
                  cursor="pointer"
                >
                  Out Guard
                  {sortColumn === "employeename" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("employeePhone")}
                  cursor="pointer"
                >
                  out Checkpost
                  {sortColumn === "employeePhone" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("employeePhone")}
                  cursor="pointer"
                >
                 employee name
                  {sortColumn === "employeePhone" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("intime")}
                  cursor="pointer"
                >
                  designation Name
                  {sortColumn === "intime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("intime")}
                  cursor="pointer"
                >
                  department Name
                  {sortColumn === "intime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("intime")}
                  cursor="pointer"
                >
                  subdepartment Name
                  {sortColumn === "intime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("intime")}
                  cursor="pointer"
                >
                  out Time
                  {sortColumn === "intime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("intime")}
                  cursor="pointer"
                >
                  logs
                  {sortColumn === "intime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                {/* <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                  Attendance
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th> */}
              </Tr>
            </Thead>
            <Tbody>
              {employeeattendenceListOut.length != 0 ? (employeeattendenceListOut.map((row, index, arr) => {
                return (
                  <EmployeeAttendenceOutTableRow
                    serialnumber={response.currentPage - 1 + index + 1}
                    outGuard={row.outGuard}
                    outCheckpost={row.logs[1]?.checkpost}
                    employeename={row.employeeName}
                    designationName={row.designationName}
                    departmentName={row.departmentName}
                    subdepartmentName={row.subdepartmentName}
                    outTime={row.logs[1]?.time}
                    logs={row.logs}
                    isLast={index === arr.length - 1 ? true : false}
                    id={row._id}
                  />
                );
              })) : (<h1></h1>)}
            </Tbody>
          </Table>
          </div>
          {employeeattendenceListOut.length === 0 ? (
            <></>
          ) : (
            <EmployeeAttendenceOutTablePagination
              totalPages={response.totalPages}
              currentPage={response.currentPage}
              perPage={response.perPage}
              sortBy={sortColumn}
              sortOrder={sortOrder}
              handlePageClick={(data) => {
                const selectedPage = data.selected + 1;
                dispatch(EmployeeAttendenceOut(`&page=${selectedPage}`));
              }}
            />
          )}
        </CardBody>
      </Card>
    </Flex>
  );
};

export default EmployeeAttendenceOutTable;
