import axios from 'axios';
import {
  CITY_LOADING,
  CITY_GET_ALL_SUCCESS,
  CITY_ERROR,
  CITY_CLEAR_SNACKBAR,
 
} from '../constants/types';

import {
  server_url
} from '../constants/types';

export const cityGetAll = (pagination) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: CITY_LOADING,
        payload: {
          loading: true
        }
      });
      axios.get(`${server_url}/city?${pagination}`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      })
        .then(response => {
          dispatch({
            type: CITY_GET_ALL_SUCCESS,
            payload: {
              cityList: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              loading: false,
              status: "success",
              text: "Get all city data successfully."
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: CITY_ERROR,
            payload: {
              status: "error",
              text: "Error occurred during getting city data.",
              loading: false
            }
          });
          resolve();
        })
    })
  }
}

export const cityAddOrUpdate = (city, state) => {
  console.log("city")
  console.log(city)
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: CITY_LOADING,
        payload: {
          loading: true
        }
      });
      if (state === "add") {
        axios.post(`${server_url}/city`, city , {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
          .then(responseF => {
            axios.get(`${server_url}/city`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
              .then(response => {
                dispatch({
                  type: CITY_GET_ALL_SUCCESS,
                  payload: {
                    cityList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: CITY_ERROR,
                  payload: {
                    text: "Error occurred during getting city data.",
                    status: "error",
                    loading: false
                  }
                });
                resolve();
              })
          })
          .catch((e) => {
            dispatch({
              type: CITY_ERROR,
              payload: {
                text: "Error occurred during adding city data.",
                status: "error",
                loading: false
              }
            });
            resolve();
          })
      } else if (state === "edit") {
        axios.put(`${server_url}/city/${city._id}`, city , {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
          .then(responseF => {
            axios.get(`${server_url}/city`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
              .then(response => {
                dispatch({
                  type: CITY_GET_ALL_SUCCESS,
                  payload: {
                    cityList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    cityDetail: responseF.data['data'],
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: CITY_ERROR,
                  payload: {
                    text: "Error occurred during getting city data.",
                    status: "error",
                    loading: false
                  }
                });
                resolve();
              })
          })
          .catch((e) => {
            dispatch({
              type: CITY_ERROR,
              payload: {
                text: "Error occurred during updating city data.",
                status: "error",
                loading: false
              }
            });
            resolve();
          })
      }
    })
  }
}

export const cityDelete = (city) => {
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            dispatch({
                type: CITY_LOADING,
                payload: {
                    loading: true
                }
            });
            axios.delete(`${server_url}/city/${city._id}`, {headers: { 
                'Authorization': 'Bearer '+localStorage.getItem('authToken')
              }}).then(responseF => {
                axios.get(`${server_url}/city`, {headers: { 
                    'Authorization': 'Bearer '+localStorage.getItem('authToken')
                  }}).then(response => {
                    dispatch({
                        type: CITY_GET_ALL_SUCCESS,
                        payload: {
                          cityList: response.data.data,
                          limit: response.data.limit,
                          page: response.data.currentPage,
                          totalPages: response.data.totalPages,
                          response: response.data,
                          cityDetail: responseF.data['data'],
                          loading: false,
                          status: responseF.data['status'],
                          text: responseF.data['message']
                        }
                      });
                    resolve();
                }).catch((e) => {
                    dispatch({
                        type: CITY_ERROR,
                        payload: {
                            text: "Error occured during getting city data.",
                            status: "error",
                            loading: false
                        }
                    });
                    resolve();
                })
            }).catch((e) => {
                dispatch({
                    type: CITY_ERROR,
                    payload: {
                        text: "Error occured during deleteing city data.",
                        status: "error",
                        loading: false
                    }
                });
                resolve();
            })
        })
    }
}

export const cityClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: CITY_CLEAR_SNACKBAR
    });
  }
}