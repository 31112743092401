// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Progress,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
  useColorModeValue,
  Link,
  Spacer,
  InputGroup,
  InputLeftElement,
  IconButton,
  TableContainer,
  TableCaption,
  Tfoot,
  Stack,
  StackDivider,
} from "@chakra-ui/react";
// Custom components
import { FaRunning } from "react-icons/fa";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { GrUserWorker } from "react-icons/gr";
import { IoFastFoodSharp } from "react-icons/io5";
import { TbTruckDelivery } from "react-icons/tb";
import { FaArrowsDownToPeople } from "react-icons/fa6";
import { TbSos } from "react-icons/tb";
import { GiLifeBar } from "react-icons/gi";
import { FaBuildingUser } from "react-icons/fa6";
import Card from "components/Card/Card.js";
import { FaIdCard } from "react-icons/fa6";
import { MdDevices } from "react-icons/md";
import { FaHouseChimneyUser } from "react-icons/fa6";
import { SiGooglemybusiness } from "react-icons/si";
import { FaPeopleRoof } from "react-icons/fa6";
import { VscUngroupByRefType } from "react-icons/vsc";
import { MdDriveFileRenameOutline } from "react-icons/md";
import { GiUndergroundCave } from "react-icons/gi";
import { GiMineTruck } from "react-icons/gi";
import BarChart from "components/Charts/BarChart";
import LineChart from "components/Charts/LineChart";
import IconBox from "components/Icons/IconBox";
// Custom icons
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  WalletIcon,
} from "components/Icons/Icons.js";
import { MdCheckCircle,MdCancelScheduleSend   } from "react-icons/md";
import { LiaIndustrySolid } from "react-icons/lia";
import { FcDepartment } from "react-icons/fc";
import { BsBarChartSteps } from "react-icons/bs";

import React from "react";
// Variables
import {
  barChartData,
  barChartOptions,
  lineChartData,
  lineChartOptions,
} from "variables/charts";
import { pageVisits, socialTraffic } from "variables/general";
import { useHistory } from 'react-router-dom';
// import { useEffect } from "react";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { server_url } from "constants/types";
import { useSelector } from "react-redux";
import { IoMdPeople } from 'react-icons/io';
import { GiEntryDoor, GiExitDoor } from 'react-icons/gi';
import { CiDeliveryTruck } from 'react-icons/ci';
import { IoMdCafe } from 'react-icons/io';
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { Value } from "sass";
import { useToast } from '@chakra-ui/react'
export default function Dashboard({ handleTitleClick }) {
  const toast = useToast();
  // Chakra Color Mode
  const iconBlue = useColorModeValue("blue.500", "blue.500");
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");
  const tableRowColor = useColorModeValue("#F7FAFC", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const textTableColor = useColorModeValue("gray.500", "white");
  const [devicesCount, setDevicesCount] = useState(0);
  const [countPlantAdmin, setCountPlantAdmin] = useState(0);
  const [countCityAdmin, setCountCityAdmin] = useState(0);
  const [role, setRole] = useState("")
  const [totalCanteen, setTotalCanteen] = useState("")
  const [totalDelivery, setTotalDelivery] = useState("")
  const [totalEmployee, setTotalEmployee] = useState("")
  const [totalEmployeeOut, setTotalEmployeeOut] = useState("")
  const [totalEmployeeIn, setTotalEmployeeIn] = useState("")
  const [totalVisitor, setTotalVisitor] = useState("")
  const [sortColumn, setSortColumn] = useState("");
  const [visitorHead, setVistiorHead] = useState(" ")
  const [materialHead, setMaterialHead] = useState(" ")
  const [workForceHead, setWorkForceHead] = useState(" ")
  const [canteenHead, setCanteenHead] = useState(" ")
  const [deliveryHead, setDeliveryHead] = useState(" ")
  const [totalHead, setTotalHead] = useState(" ")
  const [todaySos, setTodaySos] = useState(" ")
  const [todayIamSafe, setTodayIamSafe] = useState(" ")
  const [todayOutPass, setTodayOutPass] = useState(" ")

  const [devices,setDevices ] = useState(" ")
  const [countPlant,setCountPlant ] = useState(" ")
  const [checkpostCount, setCheckPost] = useState(" ")
  const [departmentCount, setDepartment] = useState(" ")
  const [subdepartmentCount,setSubDepartment ] = useState(" ")
  const [designationCount, setDesignation] = useState(" ")
  const [floorCount, setFloor] = useState(" ")
  // new
  const [visitorinexitStat, setVisitorinexitStat] = useState([]);
  const [visitorstatusStat, setVisitorstatusStat] = useState([]);
  const [deliveryStat, setDeliveryStat] = useState([]);
  const [materialStat, setMaterialStat] = useState([]);
  const [canteenStat, setCanteenStat] = useState([]);
  const [transportStat, setTransportStat] = useState([]);
  const [intercartingStat, setIntercartingStat] = useState([]);
  const [reportIncidentStat, setReportIncidentStat] = useState([]);
  const [gatepassStat, setGatepassStat] = useState([]);
  const [outpassStat, setOutpassStat] = useState([]);
  const [parcelStat, setParcelStat] = useState([]);
  const [weighmentStat, setWeighmentStat] = useState([]);
  const [totalMaterial, setTotalMaterial] = useState(0);
  const [totalTransport, setTotalTransport] = useState(0);
  const [totalIntercarting, setTotalIntercarting] = useState(0);
  const [totalRepportincidente, setTotalRepportincidente] = useState(0);
  const [totalParcel, setTotalParcel] = useState(0);
  const [totalGatepass, setTotalGatepass] = useState(0);
  const [totalWeighment, setTotalWeighment] = useState(0);
  const [totalRaisedGatepass, setTotalRaisedGatepass] = useState(0);
  const [raisedgatepassstat, setRaisedgatepassstat] = useState([]);
  const [sosStat, setSosStat] = useState([]);
  const [iamsafeStat, setIamsafeStat] = useState([]);
  const [highalertStat, setHighalertStat] = useState([]);
  const [totalHighAlert, setTotalHighAlert] = useState(0);
  const { colorMode } = useColorMode();
  const history = useHistory();
  const authDetail = useSelector(state => state.auth.authDetail);
  console.log(devicesCount);
  const [clusterDashboardData, setClusterDashboardData] = useState([]);
  const [corporateDashboardData, setCorporateDashboardData] = useState([]);

  //  const someEvent = (value) => {
  //   // For example, you can call the handleTitleClick function when needed
  //   handleTitleClick(Value);
  // };
  useEffect(() => {
    var authKey = localStorage.getItem("authToken");
    setRole(authDetail.role?.name);
    if (authKey == null) {
      history.push('/auth/signin');
    }
  }, [history]);
  
  useEffect(() => {
    if (authDetail.role?.name === 'Group') {
      getClusterDashboardData();
    }
    else if (authDetail.role?.name === 'Corporate')
      {
        getCorporateDashboardData();
      }
    
    axios
      .get(`${server_url}/admin/devicesCount`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      })
      .then((response) => {
        console.log("I aam here .... also")

        if (authDetail.role?.name === 'Admin') {
          console.log("Admin response-------");
          console.log(response);
          const { devicesCount, countPlantAdmin, countCityAdmin } = response.data.responseData;
          setDevicesCount(devicesCount);
          setCountPlantAdmin(countPlantAdmin);
          setCountCityAdmin(countCityAdmin);
        } else if (authDetail.role?.name === 'City Admin') {
          // Handle City Admin response here
          const { devicesCount, countPlantAdmin,checkpostCount,departmentCount,subdepartmentCount,designationCount,floorCount} = response.data.responseData;
          setDevices(devicesCount);
          setCountPlant(countPlantAdmin);
          setCheckPost(checkpostCount);
          setDepartment(departmentCount);
          setSubDepartment(subdepartmentCount);
          setDesignation(designationCount);
          setFloor(floorCount)
        } else if (authDetail.role?.name === 'Plant Admin') {
          console.log("Plant Admin response-------");
          console.log(response);
          const { totalCanteen, totalDelivery, totalEmployee, totalEmployeeIn, totalEmployeeOut, totalVisitor, visitorHeadCount, materialHeadCount, workforceHeadCount, canteenHeadCount, deliveryHeadCount, totalHeadcount,todayIamSafe, todaySos , todayOutPass,visitorinexitStat,visitorstatusStat,deliveryStat,materialStat,canteenStat,transportStat,intercartingStat,reportIncidentStat,
            gatepassStat,outpassStat,parcelStat,weighmentStat,totalMaterial,totalTransport,totalIntercarting,totalRepportincidente,totalGatepass,
            totalParcel,totalWeighment,totalRaisedGatepass,raisedgatepassstat,sosStat,iamsafeStat,totalHighAlert,highalertStat } = response.data.responseData;
          setTotalVisitor(totalVisitor);
          setTotalCanteen(totalCanteen);
          setTotalDelivery(totalDelivery);
          setTotalEmployee(totalEmployee);
          setTotalEmployeeIn(totalEmployeeIn);
          setTotalEmployeeOut(totalEmployeeOut);

          setVistiorHead(visitorHeadCount);
          setMaterialHead(materialHeadCount);
          setWorkForceHead(workforceHeadCount);
          setCanteenHead(canteenHeadCount);
          setDeliveryHead(deliveryHeadCount);
          setTotalHead(totalHeadcount);
          setTodaySos(todaySos)
          setTodayIamSafe(todayIamSafe)
          setTodayOutPass(todayOutPass)
          setVisitorinexitStat(visitorinexitStat)
          setVisitorstatusStat(visitorstatusStat)
          setDeliveryStat(deliveryStat)
          setMaterialStat(materialStat)
          setCanteenStat(canteenStat)
          setTransportStat(transportStat)
          setIntercartingStat(intercartingStat)
          setReportIncidentStat(reportIncidentStat)
          setGatepassStat(gatepassStat)
          setOutpassStat(outpassStat)
          setParcelStat(parcelStat)
          setWeighmentStat(weighmentStat)
          setTotalMaterial(totalMaterial)
          setTotalTransport(totalTransport)
          setTotalIntercarting(totalIntercarting)
          setTotalRepportincidente(totalRepportincidente)
          setTotalGatepass(totalGatepass)
          setTotalParcel(totalParcel)
          setTotalWeighment(totalWeighment)
          setTotalWeighment(totalWeighment)
          setTotalRaisedGatepass(totalRaisedGatepass)
          setRaisedgatepassstat(raisedgatepassstat)
          setSosStat(sosStat)
          setIamsafeStat(iamsafeStat)
          setTotalHighAlert(totalHighAlert)
          setHighalertStat(highalertStat)
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  },[authDetail.role?.name]);

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])



//   useEffect(() => {
//     if(role === 'Plant Admin'){
//     // Define a function to make the API call
//     const fetchData = async () => {
//       try {
//        await axios.get(`${server_url}/Unseen-IamSafe`, {
//           headers: {
//             'Authorization': 'Bearer ' + localStorage.getItem('authToken')
//           }
//         })
//         .then((response) => {

//   if (response.data.status === 'OK') {
//     let arr = response.data.data;
//     console.log("arr");
//     console.log(arr);
  
  
//     arr.map((currentElement, index) => {
//       const object = {
//         "title": currentElement.title,
//         "name": currentElement.name,
//         "department": currentElement.department ? currentElement.department : '',
//         "subdepartment": currentElement.subdepartment ? currentElement.subdepartment : '',
//         "designation": currentElement.designation ? currentElement.designation : '',
//       };
  
//       setTimeout(() => {
//         showToast(object, 'success');
//       }, index * 10000);
//     });
//   }
//   else {
//     showToast(response.data.message, 'error');
//   }
// })
//       } catch (error) {
//         console.error('Error:', error);
//         showToast('An error occurred', 'error');
//       }
//     };
//     fetchData();
//     // Set up an interval to make the API call every 5 seconds (adjust as needed)
//     // const intervalId = setInterval(fetchData, 10000);

//     // Clear the interval when the component is unmounted
//     // return () => clearInterval(intervalId);

//     // The empty dependency array ensures that this effect runs only once, similar to componentDidMount
//   }
// },);



// const showToast = (object, status) => {
//   const message = `Title: ${object.title}\n\Name: ${object.name}\nDepartment: ${object.department}\nSubdepartment: ${object.subdepartment}\nDesignation: ${object.designation}`;

//   toast({
//     title: message,
//     status: status,
//     duration: 3000, // Display duration in milliseconds
//     position: "top-right",
//     isClosable: true,
//   });
// };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
  };
  const getClusterDashboardData = () => {
    axios
      .get(`${server_url}/group/admin/dashboard`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((response) => {
        if(response.data.status == 'OK')
            {
                var data = response.data.data;
                setClusterDashboardData(data)
            }
        
      }).catch(() => console.log("error"))
  }
  const getCorporateDashboardData = () => {
    axios
      .get(`${server_url}/get/corporate/dashboard`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((response) => {
        if(response.data.status == 'OK')
            {
                var data = response.data.data;
                setCorporateDashboardData(data);
            }
        
      }).catch(() => console.log("error"))
  }
// Impersonate Mode
const activateImpersonateMode = (plantAdminId) => {
  const bodyData = {
    plantAdminId
  };
  axios
    .post(`${server_url}/corporate/impersonate-start`,bodyData, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      }
    }).then((response) => {
      if(response.data.status == 'OK')
          {
              var message = response.data.message;
              toastFunction(message,'success');
              localStorage.setItem('impersonateMode', 'true');
              history.push('/#/admin/dashboard');
          }
          else
          {
            var message = response.data.message;
              toastFunction(message,'error');
          }
      
    }).catch(() => console.log("error"))
}

const toastFunction = (title, status) => {
  toast({
    title: title,
    status: status,
    duration: 3000,
    isClosable: true,
  });
  
};

  if (authDetail.role?.name === 'Admin') {
    return (
      <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
        <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px' mb='20px'>
          <Card minH='125px'>
            <Flex direction='column'>
              <Flex
                flexDirection='row'
                align='center'
                justify='center'
                w='100%'
                mb='25px'>
                <Stat me='auto'>
                  <StatLabel
                    fontSize='xs'
                    color='gray.400'
                    fontWeight='bold'
                    textTransform='uppercase'>
                    Plant Admins
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      {countPlantAdmin}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox
                  borderRadius='50%'
                  as='box'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}>
                  <FaHouseChimneyUser style={{ fontSize: '25px', color: 'white' }} />
                </IconBox>
              </Flex>
              {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +3.48%{" "}
              </Text>
              Since last month
            </Text> */}
            </Flex>
          </Card>
          <Card minH='125px'>
            <Flex direction='column'>
              <Flex
                flexDirection='row'
                align='center'
                justify='center'
                w='100%'
                mb='25px'>
                <Stat me='auto'>
                  <StatLabel
                    fontSize='xs'
                    color='gray.400'
                    fontWeight='bold'
                    textTransform='uppercase'>
                    Devices
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      {devicesCount}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox
                  borderRadius='50%'
                  as='box'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}>
                  <MdDevices style={{ fontSize: '25px', color: 'white' }} />
                </IconBox>
              </Flex>
              {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +5.2%{" "}
              </Text>
              Since last month
            </Text> */}
            </Flex>
          </Card>
          <Card minH='125px'>
            <Flex direction='column'>
              <Flex
                flexDirection='row'
                align='center'
                justify='center'
                w='100%'
                mb='25px'>
                <Stat me='auto'>
                  <StatLabel
                    fontSize='xs'
                    color='gray.400'
                    fontWeight='bold'
                    textTransform='uppercase'>
                    City Admins
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      {countCityAdmin}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox
                  borderRadius='50%'
                  as='box'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}>
                  <FaBuildingUser style={{ fontSize: '25px', color: 'white' }} />
                </IconBox>
              </Flex>
              {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='red.500' fontWeight='bold'>
                -2.82%{" "}
              </Text>
              Since last month
            </Text> */}
            </Flex>
          </Card>
          <Card minH='125px'>
            <Flex direction='column'>
              <Flex
                flexDirection='row'
                align='center'
                justify='center'
                w='100%'
                mb='25px'>
                <Stat me='auto'>
                  <StatLabel
                    fontSize='xs'
                    color='gray.400'
                    fontWeight='bold'
                    textTransform='uppercase'>
                    B2B services
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      0
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox
                  borderRadius='50%'
                  as='box'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}>
                 <SiGooglemybusiness style={{ fontSize: '25px', color: 'white' }} />
                </IconBox>
              </Flex>
              {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +8.12%{" "}
              </Text>
              Since last month
            </Text> */}
            </Flex>
          </Card>
        </SimpleGrid>
        {/* <Grid
        templateColumns={{ sm: "1fr", lg: "2fr 1fr" }}
        templateRows={{ lg: "repeat(2, auto)" }}
        gap='20px'>
        <Card
          bg={
            colorMode === "dark"
              ? "navy.800"
              : "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
          }
          p='0px'
          maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column' mb='40px' p='28px 0px 0px 22px'>
            <Text color='#fff' fontSize='lg' fontWeight='bold' mb='6px'>
              Sales Overview
            </Text>
            <Text color='#fff' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                (+5) more{" "}
              </Text>
              in 2022
            </Text>
          </Flex>
          <Box minH='300px'>
            <LineChart
              chartData={lineChartData}
              chartOptions={lineChartOptions}
            />
          </Box>
        </Card>
        <Card p='0px' maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column' mb='40px' p='28px 0px 0px 22px'>
            <Text color='gray.400' fontSize='sm' fontWeight='bold' mb='6px'>
              PERFORMANCE
            </Text>
            <Text color={textColor} fontSize='lg' fontWeight='bold'>
              Total orders
            </Text>
          </Flex>
          <Box minH='300px'>
            <BarChart chartData={barChartData} chartOptions={barChartOptions} />
          </Box>
        </Card>
        <Card p='0px' maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column'>
            <Flex align='center' justify='space-between' p='22px'>
              <Text fontSize='lg' color={textColor} fontWeight='bold'>
                Page visits
              </Text>
              <Button variant='primary' maxH='30px'>
                SEE ALL
              </Button>
            </Flex>
            <Box overflow={{ sm: "scroll", lg: "hidden" }}>
              <Table>
                <Thead>
                  <Tr bg={tableRowColor}>
                    <Th color='gray.400' borderColor={borderColor}>
                      Page name
                    </Th>
                    <Th color='gray.400' borderColor={borderColor}>
                      Visitors
                    </Th>
                    <Th color='gray.400' borderColor={borderColor}>
                      Unique users
                    </Th>
                    <Th color='gray.400' borderColor={borderColor}>
                      Bounce rate
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {pageVisits.map((el, index, arr) => {
                    return (
                      <Tr key={index}>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          fontWeight='bold'
                          borderColor={borderColor}
                          border={index === arr.length - 1 ? "none" : null}>
                          {el.pageName}
                        </Td>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          border={index === arr.length - 1 ? "none" : null}
                          borderColor={borderColor}>
                          {el.visitors}
                        </Td>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          border={index === arr.length - 1 ? "none" : null}
                          borderColor={borderColor}>
                          {el.uniqueUsers}
                        </Td>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          border={index === arr.length - 1 ? "none" : null}
                          borderColor={borderColor}>
                          {el.bounceRate}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
          </Flex>
        </Card>
        <Card p='0px' maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column'>
            <Flex align='center' justify='space-between' p='22px'>
              <Text fontSize='lg' color={textColor} fontWeight='bold'>
                Social traffic
              </Text>
              <Button variant='primary' maxH='30px'>
                SEE ALL
              </Button>
            </Flex>
          </Flex>
          <Box overflow={{ sm: "scroll", lg: "hidden" }}>
            <Table>
              <Thead>
                <Tr bg={tableRowColor}>
                  <Th color='gray.400' borderColor={borderColor}>
                    Referral
                  </Th>
                  <Th color='gray.400' borderColor={borderColor}>
                    Visitors
                  </Th>
                  <Th color='gray.400' borderColor={borderColor}></Th>
                </Tr>
              </Thead>
              <Tbody>
                {socialTraffic.map((el, index, arr) => {
                  return (
                    <Tr key={index}>
                      <Td
                        color={textTableColor}
                        fontSize='sm'
                        fontWeight='bold'
                        borderColor={borderColor}
                        border={index === arr.length - 1 ? "none" : null}>
                        {el.referral}
                      </Td>
                      <Td
                        color={textTableColor}
                        fontSize='sm'
                        borderColor={borderColor}
                        border={index === arr.length - 1 ? "none" : null}>
                        {el.visitors}
                      </Td>
                      <Td
                        color={textTableColor}
                        fontSize='sm'
                        borderColor={borderColor}
                        border={index === arr.length - 1 ? "none" : null}>
                        <Flex align='center'>
                          <Text
                            color={textTableColor}
                            fontWeight='bold'
                            fontSize='sm'
                            me='12px'>{`${el.percentage}%`}</Text>
                          <Progress
                            size='xs'
                            colorScheme={el.color}
                            value={el.percentage}
                            minW='120px'
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
        </Card>
      </Grid> */}
      </Flex>
    );
  }
  else if (authDetail.role?.name === 'City Admin') {

    return (
      <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
        <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px' mb='20px'>
          <Card minH='125px'>
            <Flex direction='column'>
              <Flex
                flexDirection='row'
                align='center'
                justify='center'
                w='100%'
                mb='25px'>
                <Stat me='auto'>
                  <StatLabel
                    fontSize='xs'
                    color='gray.400'
                    fontWeight='bold'
                    textTransform='uppercase'>
                    Number Of Devices
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      {devices}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox
                  borderRadius='50%'
                  as='box'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}>
                  <MdDevices style={{ fontSize: '25px', color: 'white' }} />
                </IconBox>
              </Flex>
              
            </Flex>
          </Card>
          <Card minH='125px'>
            <Flex direction='column'>
              <Flex
                flexDirection='row'
                align='center'
                justify='center'
                w='100%'
                mb='25px'>
                <Stat me='auto'>
                  <StatLabel
                    fontSize='xs'
                    color='gray.400'
                    fontWeight='bold'
                    textTransform='uppercase'>
                    Number Of Plants
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      {countPlant}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox
                  borderRadius='50%'
                  as='box'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}>
                  <LiaIndustrySolid style={{ fontSize: '30px', color: 'white' }} />
                </IconBox>
              </Flex>
             
            </Flex>
          </Card>
          <Card minH='125px'>
            <Flex direction='column'>
              <Flex
                flexDirection='row'
                align='center'
                justify='center'
                w='100%'
                mb='25px'>
                <Stat me='auto'>
                  <StatLabel
                    fontSize='xs'
                    color='gray.400'
                    fontWeight='bold'
                    textTransform='uppercase'>
                    Number Of Checkpoints
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      {checkpostCount}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox
                  borderRadius='50%'
                  as='box'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}>
                  <MdCheckCircle style={{ fontSize: '25px', color: 'white' }} />
                </IconBox>
              </Flex>
            </Flex>
          </Card>
          <Card minH='125px'>
            <Flex direction='column'>
              <Flex
                flexDirection='row'
                align='center'
                justify='center'
                w='100%'
                mb='25px'>
                <Stat me='auto'>
                  <StatLabel
                    fontSize='xs'
                    color='gray.400'
                    fontWeight='bold'
                    textTransform='uppercase'>
                    Number Of Department
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      {departmentCount}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox
                  borderRadius='50%'
                  as='box'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}>
                  <FaPeopleRoof style={{ fontSize: '25px', color: 'white' }} />
                </IconBox>
              </Flex>
            </Flex>
          </Card>
          <Card minH='125px'>
            <Flex direction='column'>
              <Flex
                flexDirection='row'
                align='center'
                justify='center'
                w='100%'
                mb='25px'>
                <Stat me='auto'>
                  <StatLabel
                    fontSize='xs'
                    color='gray.400'
                    fontWeight='bold'
                    textTransform='uppercase'>
                    Number Of Sub-Department
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      {subdepartmentCount}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox
                  borderRadius='50%'
                  as='box'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}>
                  <VscUngroupByRefType style={{ fontSize: '25px', color: 'white' }} />
                </IconBox>
              </Flex>
            </Flex>
          </Card>

          <Card minH='125px'>
            <Flex direction='column'>
              <Flex
                flexDirection='row'
                align='center'
                justify='center'
                w='100%'
                mb='25px'>
                <Stat me='auto'>
                  <StatLabel
                    fontSize='xs'
                    color='gray.400'
                    fontWeight='bold'
                    textTransform='uppercase'>
                    Number Of Designation
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      {designationCount}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox
                  borderRadius='50%'
                  as='box'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}>
                  <MdDriveFileRenameOutline style={{ fontSize: '25px', color: 'white' }} />
                </IconBox>
              </Flex>
            </Flex>
          </Card>
          <Card minH='125px'>
            <Flex direction='column'>
              <Flex
                flexDirection='row'
                align='center'
                justify='center'
                w='100%'
                mb='25px'>
                <Stat me='auto'>
                  <StatLabel
                    fontSize='xs'
                    color='gray.400'
                    fontWeight='bold'
                    textTransform='uppercase'>
                    Number Of Floors
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      {floorCount}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox
                  borderRadius='50%'
                  as='box'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}>
                  {/* <BsBarChartSteps h={"24px"} w={"24px"} color={iconBoxInside} /> */}
                  <GiUndergroundCave style={{ fontSize: '25px', color: 'white' }} />
                </IconBox>
              </Flex>
            </Flex>
          </Card>
          {/* <Card minH='125px'>
            <Flex direction='column'>
              <Flex
                flexDirection='row'
                align='center'
                justify='center'
                w='100%'
                mb='25px'>
                <Stat me='auto'>
                  <StatLabel
                    fontSize='xs'
                    color='gray.400'
                    fontWeight='bold'
                    textTransform='uppercase'>
                    B2B services
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      0
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox
                  borderRadius='50%'
                  as='box'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}>
                  <CartIcon h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
             
            </Flex>
          </Card> */}
        </SimpleGrid>
        
      </Flex>
    );


  }
  else if (authDetail.role?.name === 'Plant Admin') {
    return (
      <>
        <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
          <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px' mb='20px'>
            {/* Head Count */}
            <Card minH='125px' style={{ cursor: 'pointer' }}>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='1px'>
                  <Stat me='auto'>
                  <Flex style={{'margin-bottom':'5px'}}>
                  <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      Live Head Count (IN)
                      
                    </StatLabel>
                    <Spacer></Spacer>
                    <iframe style={{display:'inline',paddingTop:"0px"}} height={20} width={25} src="https://lottie.host/embed/e33406ab-6e0a-49d2-94f5-9ee7f408cd4d/8Ijst1zrVe.json"></iframe>
                    <Spacer />
                    <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      {totalHead}
                    </StatLabel>
                    {/* <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"30px"}
                    w={"30px"}
                    bg="red.500">
                    <IoMdPeople style={{ fontSize: '20px', color: 'white' }} />
                  </IconBox> */}
                  </Flex>
                    <Stack>
                    <Link href="#/admin/visitor-tab?tab=2">
                    <Flex className="mt-2">
                      <StatLabel
                        fontSize='xs'
                        color='gray.400'
                        fontWeight='bold'
                        textTransform='uppercase'>
                      Visitor
                      </StatLabel>
                      <Spacer />
                      <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                        {visitorHead}
                      </StatNumber>
                    </Flex>
                    </Link>
                    <Link href="#/admin/material/head-count">
                    <Flex>
                      <StatLabel
                        fontSize='xs'
                        color='gray.400'
                        fontWeight='bold'
                        textTransform='uppercase'>
                      Material Drivers
                      </StatLabel>
                      <Spacer />
                      <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                        {materialHead}
                      </StatNumber>
                    </Flex>
                    </Link>
                    <Link href='#/admin/workforce/head-count'>
                    <Flex>
                      <StatLabel
                        fontSize='xs'
                        color='gray.400'
                        fontWeight='bold'
                        textTransform='uppercase'>
                      Work Force
                      </StatLabel>
                      <Spacer />
                      <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                        {workForceHead}
                      </StatNumber>
                    </Flex>
                    </Link>
                    <Link href="#/admin/canteen/head-count">
                    <Flex>
                      <StatLabel
                        fontSize='xs'
                        color='gray.400'
                        fontWeight='bold'
                        textTransform='uppercase'>
                      Canteen Vehicle Drivers
                      </StatLabel>
                      <Spacer />
                      <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                        {canteenHead}
                      </StatNumber>
                    </Flex>
                    </Link>
                    <Link href="#/admin/delivery/head-count">
                    <Flex>
                      <StatLabel
                        fontSize='xs'
                        color='gray.400'
                        fontWeight='bold'
                        textTransform='uppercase'>
                      Delivery/Courier Boys
                      </StatLabel>
                      <Spacer />
                      <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                        {deliveryHead}
                      </StatNumber>
                    </Flex>
                    </Link>
                    </Stack>
                    

                  </Stat>
                  
                </Flex>
                {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +3.48%{" "}
              </Text>
              Since last month
            </Text> */}
              </Flex>
            </Card>

{/* Visitor In Exit Stats */}
            <Link href="#/admin/Visitor-InOut-table">
            <Card minH='125px' style={{ cursor: 'pointer' }}>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='1px'>
                  <Stat me='auto'>
                  <Flex style={{'margin-bottom':'5px'}}>
                  <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      Visitor
                    </StatLabel>
                    <Spacer />
                    <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      {totalVisitor}
                    </StatLabel>
                    {/* <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"30px"}
                    w={"30px"}
                    bg="red.500">
                    <IoMdPeople style={{ fontSize: '20px', color: 'white' }} />
                  </IconBox> */}
                  </Flex>
                    <Stack>
                    
                    {visitorinexitStat.map((data, index, arr) => (
                      <>
                      <Link href={data._id == 'EXIT'?"#/admin/visitor-tab?tab=0" : "#/admin/visitor-tab?tab=2"}>
                      <Flex className="mt-2">
                        <StatLabel
                          fontSize='xs'
                          color='gray.400'
                          fontWeight='bold'
                          textTransform='uppercase'>
                        {data._id == 'EXIT'?'PAST':data._id == 'IN'?'CURRENT':data._id}
                        </StatLabel>
                        <Spacer />
                        <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                          {data.count}
                        </StatNumber>
                      </Flex>
                      </Link>
                      </>
                      ))}
                    {visitorstatusStat.map((data, index, arr) => (
                      <>
                      <Link href={data._id == 'DENY'?"#/admin/visitor-tab?tab=4" : "#/admin/visitor-tab?tab=3"}>
                      <Flex className="mt-2">
                        <StatLabel
                          fontSize='xs'
                          color='gray.400'
                          fontWeight='bold'
                          textTransform='uppercase'>
                        {data._id}
                        </StatLabel>
                        <Spacer />
                        <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                          {data.count}
                        </StatNumber>
                      </Flex>
                      </Link>
                      </>
                      ))}
                      </Stack>
                  </Stat>
                  
                </Flex>
                {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +3.48%{" "}
              </Text>
              Since last month
            </Text> */}
              </Flex>
            </Card>
            </Link>
            {/* Delivery Stat */}
            <Link href="#/admin/Delivary-InOut-table">
            <Card minH='125px' style={{ cursor: 'pointer' }}>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='1px'>
                  <Stat me='auto'>
                  <Flex style={{'margin-bottom':'5px'}}>
                  <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      Delivery
                    </StatLabel>
                    <Spacer />
                    <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      {totalDelivery}
                    </StatLabel>
                    {/* <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"30px"}
                    w={"30px"}
                    bg="red.500">
                    <IoMdPeople style={{ fontSize: '20px', color: 'white' }} />
                  </IconBox> */}
                  </Flex>
                  <Stack>
                    {deliveryStat.map((data, index, arr) => (
                      <>
                      <Link href={data._id == 'IN'?"#/admin/delivery-tab?tab=1" : data._id == 'EXIT'? "#/admin/delivery-tab?tab=0":data._id == 'LEAVE AT GATE'? "#/admin/delivery-tab?tab=2":"#/admin/delivery-tab?tab=3"}>
                      <Flex className="mt-2">
                        <StatLabel
                          fontSize='xs'
                          color='gray.400'
                          fontWeight='bold'
                          textTransform='uppercase'>
                        {data._id == 'RECIEVED' ? 'HANDED OVER' : data._id == 'EXIT'?'PAST':data._id == 'IN'?'CURRENT':data._id}
                        </StatLabel>
                        <Spacer />
                        <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                          {data.count}
                        </StatNumber>
                      </Flex>
                      </Link>
                      </>
                      ))}
                     </Stack>
                  </Stat>
                  
                </Flex>
                {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +3.48%{" "}
              </Text>
              Since last month
            </Text> */}
              </Flex>
            </Card>
            </Link>
            {/* Material Stat */}
            
            <Card minH='125px' style={{ cursor: 'pointer' }}>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='1px'>
                  <Stat me='auto'>
                  <Flex style={{'margin-bottom':'5px'}}>
                  <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      Material
                    </StatLabel>
                    <Spacer />
                    <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      {totalMaterial}
                    </StatLabel>
                    {/* <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"30px"}
                    w={"30px"}
                    bg="red.500">
                    <IoMdPeople style={{ fontSize: '20px', color: 'white' }} />
                  </IconBox> */}
                  </Flex>
                  <Stack>
                  {/* <Stack divider={<StackDivider />} spacing='0.5'> */}
                    {materialStat.map((data, index, arr) => (
                      <>
                      <Link href={data._id =='IN' ? '#/admin/material-tabs?tab=1' : data._id =='EXIT' ? '#/admin/material-tabs?tab=0' : data._id =='INCOMING_MATERIAL' ? '#/admin/material-tabs?tab=2':'#/admin/material-tabs?tab=3'}>

                      <Flex className="mt-2">
                        <StatLabel
                          fontSize='xs'
                          color='gray.400'
                          fontWeight='bold'
                          textTransform='uppercase'>
                        {data._id =='IN' ? 'Current' : data._id =='EXIT' ? 'PAST' : data._id.replace(/_/g, ' ')}
                        </StatLabel>
                        <Spacer />
                        <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                          {data.count}
                        </StatNumber>
                      </Flex>
                      </Link>
                      </>
                      ))}
                      </Stack>
                  </Stat>
                  
                </Flex>
                {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +3.48%{" "}
              </Text>
              Since last month
            </Text> */}
              </Flex>
            </Card>
            {/* Canteen Stat */}
            <Link href="#/admin/Canteen-InOut-table">
            <Card minH='125px' style={{ cursor: 'pointer' }}>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='1px'>
                  <Stat me='auto'>
                  <Flex style={{'margin-bottom':'5px'}}>
                  <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      Canteen
                    </StatLabel>
                    <Spacer />
                    <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      {totalCanteen}
                    </StatLabel>
                    {/* <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"30px"}
                    w={"30px"}
                    bg="red.500">
                    <IoMdPeople style={{ fontSize: '20px', color: 'white' }} />
                  </IconBox> */}
                  </Flex>
                  <Stack>
                  {/* <Stack divider={<StackDivider />} spacing='0.5'> */}
                    {canteenStat.map((data, index, arr) => (
                      <>
                      <Flex className="mt-2">
                        <StatLabel
                          fontSize='xs'
                          color='gray.400'
                          fontWeight='bold'
                          textTransform='uppercase'>
                        {data._id}
                        </StatLabel>
                        <Spacer />
                        <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                          {data.count}
                        </StatNumber>
                      </Flex>

                      </>
                      ))}
                      </Stack>
                  </Stat>
                  
                </Flex>
                {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +3.48%{" "}
              </Text>
              Since last month
            </Text> */}
              </Flex>
            </Card>
            </Link>
            {/* Transport Stat */}
            <Link href="#/admin/Transport-InOut-table">
            <Card minH='125px' style={{ cursor: 'pointer' }}>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='1px'>
                  <Stat me='auto'>
                  <Flex style={{'margin-bottom':'5px'}}>
                  <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      Transport
                    </StatLabel>
                    <Spacer />
                    <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      {totalTransport}
                    </StatLabel>
                    {/* <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"30px"}
                    w={"30px"}
                    bg="red.500">
                    <IoMdPeople style={{ fontSize: '20px', color: 'white' }} />
                  </IconBox> */}
                  </Flex>
                  <Stack>
                  {/* <Stack divider={<StackDivider />} spacing='0.5'> */}
                    {transportStat.map((data, index, arr) => (
                      <>
                      <Flex className="mt-2">
                        <StatLabel
                          fontSize='xs'
                          color='gray.400'
                          fontWeight='bold'
                          textTransform='uppercase'>
                        {data._id}
                        </StatLabel>
                        <Spacer />
                        <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                          {data.count}
                        </StatNumber>
                      </Flex>

                      </>
                      ))}
                      </Stack>
                  </Stat>
                  
                </Flex>
                {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +3.48%{" "}
              </Text>
              Since last month
            </Text> */}
              </Flex>
            </Card>
            </Link>
            {/* Intercarting Stat */}
            <Link href="#/admin/Intercarting-InOut-table">
            <Card minH='125px' style={{ cursor: 'pointer' }}>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='1px'>
                  <Stat me='auto'>
                  <Flex style={{'margin-bottom':'5px'}}>
                  <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      Intercarting
                    </StatLabel>
                    <Spacer />
                    <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      {totalIntercarting}
                    </StatLabel>
                    {/* <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"30px"}
                    w={"30px"}
                    bg="red.500">
                    <IoMdPeople style={{ fontSize: '20px', color: 'white' }} />
                  </IconBox> */}
                  </Flex>
                  <Stack>
                  {/* <Stack divider={<StackDivider />} spacing='0.5'> */}
                    {intercartingStat.map((data, index, arr) => (
                      <>
                      <Flex className="mt-2">
                        <StatLabel
                          fontSize='xs'
                          color='gray.400'
                          fontWeight='bold'
                          textTransform='uppercase'>
                        {data._id}
                        </StatLabel>
                        <Spacer />
                        <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                          {data.count}
                        </StatNumber>
                      </Flex>

                      </>
                      ))}
                      </Stack>
                  </Stat>
                  
                </Flex>
                {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +3.48%{" "}
              </Text>
              Since last month
            </Text> */}
              </Flex>
            </Card>
            </Link>
            {/* Report Incidence Stat */}
            <Link href="#/admin/reportIncedent-Table">
            <Card minH='125px' style={{ cursor: 'pointer' }}>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='1px'>
                  <Stat me='auto'>
                  <Flex style={{'margin-bottom':'5px'}}>
                  <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      Report Incident
                    </StatLabel>
                    <Spacer />
                    <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      {totalRepportincidente}
                    </StatLabel>
                    {/* <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"30px"}
                    w={"30px"}
                    bg="red.500">
                    <IoMdPeople style={{ fontSize: '20px', color: 'white' }} />
                  </IconBox> */}
                  </Flex>
                  <Stack>
                  {/* <Stack divider={<StackDivider />} spacing='0.5'> */}
                    {reportIncidentStat.map((data, index, arr) => (
                      <>
                      <Flex className="mt-2">
                        <StatLabel
                          fontSize='xs'
                          color='gray.400'
                          fontWeight='bold'
                          textTransform='uppercase'>
                        {data._id}
                        </StatLabel>
                        <Spacer />
                        <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                          {data.count}
                        </StatNumber>
                      </Flex>

                      </>
                      ))}
                      </Stack>
                  </Stat>
                  
                </Flex>
                {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +3.48%{" "}
              </Text>
              Since last month
            </Text> */}
              </Flex>
            </Card>
            </Link>
            {/* Gatepass Stat */}
            <Link href="#/admin/materialGatePass-table">
            <Card minH='125px' style={{ cursor: 'pointer' }}>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='1px'>
                  <Stat me='auto'>
                  <Flex style={{'margin-bottom':'5px'}}>
                  <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      Material Gate pass
                    </StatLabel>
                    <Spacer />
                    <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      {totalGatepass}
                    </StatLabel>
                    {/* <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"30px"}
                    w={"30px"}
                    bg="red.500">
                    <IoMdPeople style={{ fontSize: '20px', color: 'white' }} />
                  </IconBox> */}
                  </Flex>
                  <Stack>
                  {/* <Stack divider={<StackDivider />} spacing='0.5'> */}
                    {gatepassStat.map((data, index, arr) => (
                      <>
                      <Flex className="mt-2">
                        <StatLabel
                          fontSize='xs'
                          color='gray.400'
                          fontWeight='bold'
                          textTransform='uppercase'>
                        {data._id}
                        </StatLabel>
                        <Spacer />
                        <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                          {data.count}
                        </StatNumber>
                      </Flex>

                      </>
                      ))}
                      </Stack>
                  </Stat>
                  
                </Flex>
                {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +3.48%{" "}
              </Text>
              Since last month
            </Text> */}
              </Flex>
            </Card>
            </Link>
            {/* Raised gatepass Stat */}
            <Link href="#/admin/raisedGatePass-table">
            <Card minH='125px' style={{ cursor: 'pointer' }}>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='1px'>
                  <Stat me='auto'>
                  <Flex style={{'margin-bottom':'5px'}}>
                  <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      Raised Gate pass
                    </StatLabel>
                    <Spacer />
                    <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      {totalRaisedGatepass}
                    </StatLabel>
                    {/* <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"30px"}
                    w={"30px"}
                    bg="red.500">
                    <IoMdPeople style={{ fontSize: '20px', color: 'white' }} />
                  </IconBox> */}
                  </Flex>
                  <Stack>
                  {/* <Stack divider={<StackDivider />} spacing='0.5'> */}
                    {raisedgatepassstat.map((data, index, arr) => (
                      <>
                      <Flex className="mt-2">
                        <StatLabel
                          fontSize='xs'
                          color='gray.400'
                          fontWeight='bold'
                          textTransform='uppercase'>
                        {data._id}
                        </StatLabel>
                        <Spacer />
                        <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                          {data.count}
                        </StatNumber>
                      </Flex>

                      </>
                      ))}
                      </Stack>
                  </Stat>
                  
                </Flex>
                {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +3.48%{" "}
              </Text>
              Since last month
            </Text> */}
              </Flex>
            </Card>
            </Link>
            {/* Out pass Stat */}
            <Link href="#/admin/outPass-table">
            <Card minH='125px' style={{ cursor: 'pointer' }}>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='1px'>
                  <Stat me='auto'>
                  <Flex style={{'margin-bottom':'5px'}}>
                  <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      Out Pass
                    </StatLabel>
                    <Spacer />
                    <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      {todayOutPass}
                    </StatLabel>
                    {/* <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"30px"}
                    w={"30px"}
                    bg="red.500">
                    <IoMdPeople style={{ fontSize: '20px', color: 'white' }} />
                  </IconBox> */}
                  </Flex>
                  <Stack>
                  {/* <Stack divider={<StackDivider />} spacing='0.5'> */}
                    {outpassStat.map((data, index, arr) => (
                      <>
                      <Flex className="mt-2">
                        <StatLabel
                          fontSize='xs'
                          color='gray.400'
                          fontWeight='bold'
                          textTransform='uppercase'>
                        {data._id??'IN'}
                        </StatLabel>
                        <Spacer />
                        <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                          {data.count}
                        </StatNumber>
                      </Flex>

                      </>
                      ))}
                      </Stack>
                  </Stat>
                  
                </Flex>
                {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +3.48%{" "}
              </Text>
              Since last month
            </Text> */}
              </Flex>
            </Card>
            </Link>
            {/* Parcel Stat */}
            <Card minH='125px' style={{ cursor: 'pointer' }}>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='1px'>
                  <Stat me='auto'>
                  <Flex style={{'margin-bottom':'5px'}}>
                  <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      Parcel Management
                    </StatLabel>
                    <Spacer />
                    <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      {totalParcel}
                    </StatLabel>
                    {/* <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"30px"}
                    w={"30px"}
                    bg="red.500">
                    <IoMdPeople style={{ fontSize: '20px', color: 'white' }} />
                  </IconBox> */}
                  </Flex>
                  <Stack>
                  {/* <Stack divider={<StackDivider />} spacing='0.5'> */}
                    {parcelStat.map((data, index, arr) => (
                      <>
                      <Flex className="mt-2">
                        <StatLabel
                          fontSize='xs'
                          color='gray.400'
                          fontWeight='bold'
                          textTransform='uppercase'>
                        {data._id??'IN'}
                        </StatLabel>
                        <Spacer />
                        <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                          {data.count}
                        </StatNumber>
                      </Flex>

                      </>
                      ))}
                      </Stack>
                  </Stat>
                  
                </Flex>
                {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +3.48%{" "}
              </Text>
              Since last month
            </Text> */}
              </Flex>
            </Card>
            {/* Weighment */}
            <Link href="#/admin/weighmentBridge-table">
            <Card minH='125px' style={{ cursor: 'pointer' }}>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='1px'>
                  <Stat me='auto'>
                  <Flex style={{'margin-bottom':'5px'}}>
                  <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      Weighment
                    </StatLabel>
                    <Spacer />
                    <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      {totalWeighment}
                    </StatLabel>
                    {/* <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"30px"}
                    w={"30px"}
                    bg="red.500">
                    <IoMdPeople style={{ fontSize: '20px', color: 'white' }} />
                  </IconBox> */}
                  </Flex>
                  <Stack>
                  {/* <Stack divider={<StackDivider />} spacing='0.5'> */}
                    {weighmentStat.map((data, index, arr) => (
                      <>
                      <Flex className="mt-2">
                        <StatLabel
                          fontSize='xs'
                          color='gray.400'
                          fontWeight='bold'
                          textTransform='uppercase'>
                        {data._id??'IN'}
                        </StatLabel>
                        <Spacer />
                        <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                          {data.count}
                        </StatNumber>
                      </Flex>

                      </>
                      ))}
                      </Stack>
                  </Stat>
                  
                </Flex>
                {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +3.48%{" "}
              </Text>
              Since last month
            </Text> */}
              </Flex>
            </Card>
            </Link>
            
            {/* SoS stat */}
            <Link href="#/admin/sos-table">
            <Card minH='125px' style={{ cursor: 'pointer' }}>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='1px'>
                  <Stat me='auto'>
                  <Flex style={{'margin-bottom':'5px'}}>
                  <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      SOS
                    </StatLabel>
                    <Spacer />
                    <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      {todaySos}
                    </StatLabel>
                    {/* <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"30px"}
                    w={"30px"}
                    bg="red.500">
                    <IoMdPeople style={{ fontSize: '20px', color: 'white' }} />
                  </IconBox> */}
                  </Flex>
                  <Stack>
                  {/* <Stack divider={<StackDivider />} spacing='0.5'> */}
                    {sosStat.map((data, index, arr) => (
                      <>
                      <Flex className="mt-2">
                        <StatLabel
                          fontSize='xs'
                          color='gray.400'
                          fontWeight='bold'
                          textTransform='uppercase'>
                        {data._id??'IN'}
                        </StatLabel>
                        <Spacer />
                        <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                          {data.count}
                        </StatNumber>
                      </Flex>

                      </>
                      ))}
                      </Stack>
                  </Stat>
                  
                </Flex>
                {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +3.48%{" "}
              </Text>
              Since last month
            </Text> */}
              </Flex>
            </Card>
            </Link>
            
            {/* Iam safe stat */}
            <Link href="#/admin/iamSafe-table">
            <Card minH='125px' style={{ cursor: 'pointer' }}>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='1px'>
                  <Stat me='auto'>
                  <Flex style={{'margin-bottom':'5px'}}>
                  <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      I am safe
                    </StatLabel>
                    <Spacer />
                    <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      {todayIamSafe}
                    </StatLabel>
                    {/* <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"30px"}
                    w={"30px"}
                    bg="red.500">
                    <IoMdPeople style={{ fontSize: '20px', color: 'white' }} />
                  </IconBox> */}
                  </Flex>
                  <Stack>
                  {/* <Stack divider={<StackDivider />} spacing='0.5'> */}
                    {iamsafeStat.map((data, index, arr) => (
                      <>
                      <Flex className="mt-2">
                        <StatLabel
                          fontSize='xs'
                          color='gray.400'
                          fontWeight='bold'
                          textTransform='uppercase'>
                        {data._id??'IN'}
                        </StatLabel>
                        <Spacer />
                        <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                          {data.count}
                        </StatNumber>
                      </Flex>

                      </>
                      ))}
                      </Stack>
                  </Stat>
                  
                </Flex>
                {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +3.48%{" "}
              </Text>
              Since last month
            </Text> */}
              </Flex>
            </Card>
            </Link>
            {/* High Alert */}
            <Link href="#/admin/highalert-table">
            <Card minH='125px' style={{ cursor: 'pointer' }}>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='1px'>
                  <Stat me='auto'>
                  <Flex style={{'margin-bottom':'5px'}}>
                  <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      High Alert
                    </StatLabel>
                    <Spacer />
                    <StatLabel
                      fontSize='xs'
                      color='red.600'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      {totalHighAlert}
                    </StatLabel>
                    {/* <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"30px"}
                    w={"30px"}
                    bg="red.500">
                    <IoMdPeople style={{ fontSize: '20px', color: 'white' }} />
                  </IconBox> */}
                  </Flex>
                  <Stack>
                  {/* <Stack divider={<StackDivider />} spacing='0.5'> */}
                    {highalertStat.map((data, index, arr) => (
                      <>
                      <Flex className="mt-2">
                        <StatLabel
                          fontSize='xs'
                          color='gray.400'
                          fontWeight='bold'
                          textTransform='uppercase'>
                        {data._id??'IN'}
                        </StatLabel>
                        <Spacer />
                        <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                          {data.count}
                        </StatNumber>
                      </Flex>

                      </>
                      ))}
                      </Stack>
                  </Stat>
                  
                </Flex>
                {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +3.48%{" "}
              </Text>
              Since last month
            </Text> */}
              </Flex>
            </Card>
            </Link>
            
          
          {/* <Link href="#/admin/employee-table">
            <Card minH='125px' style={{ cursor: 'pointer' }}>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='25px'>
                  <Stat me='auto'>
                    <StatLabel
                      fontSize='xs'
                      color='gray.400'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      Total employee
                    </StatLabel>

                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      {totalEmployee}
                    </StatNumber>

                  </Stat>
                  <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"50px"}
                    w={"50px"}
                    bg="red.500">
                    <IoMdPeople style={{ fontSize: '25px', color: 'white' }} />
                  </IconBox>
                </Flex>
                
              </Flex>
            </Card>
          </Link> */}

          {/* <Link href="#/admin/employee-attendence-in">
            <Card minH='125px'  style={{ cursor: 'pointer' }}>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='25px'>
                  <Stat me='auto'>
                    <StatLabel
                      fontSize='xs'
                      color='gray.400'
                      fontWeight='bold'
                      textTransform='uppercase'>
                   Total Employee In
                    </StatLabel>
                    <Flex>
                      <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                        {totalEmployeeIn}
                      </StatNumber>
                    </Flex>
                  </Stat>
                  <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"45px"}
                    w={"45px"}
                    bg="red.500">
                   <GiExitDoor style={{ fontSize: '25px', color: 'white' }} />
                  </IconBox>
                </Flex>
              </Flex>
            </Card>
          </Link>

          <Link href="#/admin/employee-attendence-out">
            <Card minH='125px' style={{ cursor: 'pointer' }}>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='25px'>
                  <Stat me='auto'>
                    <StatLabel
                      fontSize='xs'
                      color='gray.400'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      Total Employee Out
                    </StatLabel>
                    <Flex>
                      <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                        {totalEmployeeOut}
                      </StatNumber>
                    </Flex>
                  </Stat>
                  <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"45px"}
                    w={"45px"}
                    bg="red.500">
                    <GiEntryDoor style={{ fontSize: '25px', color: 'white' }} />
                  </IconBox>
                </Flex>
              </Flex>
            </Card>
          </Link> */}

          
          </SimpleGrid>
          {/* <Grid
        templateColumns={{ sm: "1fr", lg: "2fr 1fr" }}
        templateRows={{ lg: "repeat(2, auto)" }}
        gap='20px'>
        <Card
          bg={
            colorMode === "dark"
              ? "navy.800"
              : "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
          }
          p='0px'
          maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column' mb='40px' p='28px 0px 0px 22px'>
            <Text color='#fff' fontSize='lg' fontWeight='bold' mb='6px'>
              Sales Overview
            </Text>
            <Text color='#fff' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                (+5) more{" "}
              </Text>
              in 2022
            </Text>
          </Flex>
          <Box minH='300px'>
            <LineChart
              chartData={lineChartData}
              chartOptions={lineChartOptions}
            />
          </Box>
        </Card>
        <Card p='0px' maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column' mb='40px' p='28px 0px 0px 22px'>
            <Text color='gray.400' fontSize='sm' fontWeight='bold' mb='6px'>
              PERFORMANCE
            </Text>
            <Text color={textColor} fontSize='lg' fontWeight='bold'>
              Total orders
            </Text>
          </Flex>
          <Box minH='300px'>
            <BarChart chartData={barChartData} chartOptions={barChartOptions} />
          </Box>
        </Card>
        <Card p='0px' maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column'>
            <Flex align='center' justify='space-between' p='22px'>
              <Text fontSize='lg' color={textColor} fontWeight='bold'>
                Page visits
              </Text>
              <Button variant='primary' maxH='30px'>
                SEE ALL
              </Button>
            </Flex>
            <Box overflow={{ sm: "scroll", lg: "hidden" }}>
              <Table>
                <Thead>
                  <Tr bg={tableRowColor}>
                    <Th color='gray.400' borderColor={borderColor}>
                      Page name
                    </Th>
                    <Th color='gray.400' borderColor={borderColor}>
                      Visitors
                    </Th>
                    <Th color='gray.400' borderColor={borderColor}>
                      Unique users
                    </Th>
                    <Th color='gray.400' borderColor={borderColor}>
                      Bounce rate
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {pageVisits.map((el, index, arr) => {
                    return (
                      <Tr key={index}>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          fontWeight='bold'
                          borderColor={borderColor}
                          border={index === arr.length - 1 ? "none" : null}>
                          {el.pageName}
                        </Td>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          border={index === arr.length - 1 ? "none" : null}
                          borderColor={borderColor}>
                          {el.visitors}
                        </Td>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          border={index === arr.length - 1 ? "none" : null}
                          borderColor={borderColor}>
                          {el.uniqueUsers}
                        </Td>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          border={index === arr.length - 1 ? "none" : null}
                          borderColor={borderColor}>
                          {el.bounceRate}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
          </Flex>
        </Card>
        <Card p='0px' maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column'>
            <Flex align='center' justify='space-between' p='22px'>
              <Text fontSize='lg' color={textColor} fontWeight='bold'>
                Social traffic
              </Text>
              <Button variant='primary' maxH='30px'>
                SEE ALL
              </Button>
            </Flex>
          </Flex>
          <Box overflow={{ sm: "scroll", lg: "hidden" }}>
            <Table>
              <Thead>
                <Tr bg={tableRowColor}>
                  <Th color='gray.400' borderColor={borderColor}>
                    Referral
                  </Th>
                  <Th color='gray.400' borderColor={borderColor}>
                    Visitors
                  </Th>
                  <Th color='gray.400' borderColor={borderColor}></Th>
                </Tr>
              </Thead>
              <Tbody>
                {socialTraffic.map((el, index, arr) => {
                  return (
                    <Tr key={index}>
                      <Td
                        color={textTableColor}
                        fontSize='sm'
                        fontWeight='bold'
                        borderColor={borderColor}
                        border={index === arr.length - 1 ? "none" : null}>
                        {el.referral}
                      </Td>
                      <Td
                        color={textTableColor}
                        fontSize='sm'
                        borderColor={borderColor}
                        border={index === arr.length - 1 ? "none" : null}>
                        {el.visitors}
                      </Td>
                      <Td
                        color={textTableColor}
                        fontSize='sm'
                        borderColor={borderColor}
                        border={index === arr.length - 1 ? "none" : null}>
                        <Flex align='center'>
                          <Text
                            color={textTableColor}
                            fontWeight='bold'
                            fontSize='sm'
                            me='12px'>{`${el.percentage}%`}</Text>
                          <Progress
                            size='xs'
                            colorScheme={el.color}
                            value={el.percentage}
                            minW='120px'
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
        </Card>
      </Grid> */}
        </Flex>

        {/* <Card>
    <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                  style={{ fontSize: "16px" }}
                >
                  Visitor
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  pl="20px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                  style={{ fontSize: "16px", color: "Black" }}
                >
                  {visitorHead}
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>

              </Tr>
            </Thead>
            </Table>

    <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                  style={{ fontSize: "16px" }}
                >
                  Material
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  pl="33px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                  style={{ fontSize: "16px", color: "Black" }}
                >
                  {materialHead}
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>

              </Tr>
            </Thead>
            </Table>

    <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                  style={{ fontSize: "16px" }}
                >
                  Work Force
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  pl="55px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                  style={{ fontSize: "16px", color: "Black" }}
                >
                  {workForceHead}
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>

              </Tr>
            </Thead>
            </Table>

    <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                  style={{ fontSize: "16px" }}
                >
                  Canteen
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  pl="28px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                  style={{ fontSize: "16px" , color: "Black" }}
                >
                  {canteenHead}
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>

              </Tr>
            </Thead>
            </Table>

    <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                  style={{ fontSize: "16px", padding: "10px" }}
                >
                  Delivery
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  pl="26px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                  style={{ fontSize: "16px", color: "Black" }}
                >
                  {deliveryHead}
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>

              </Tr>
            </Thead>
            </Table>
            <br/>

    <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Link href = "#">
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                  style={{ fontSize: "16px" }}
                >
                  Total
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                </Link>
                
                <Th
                  pl="10px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                  style={{ fontSize: "16px", color: "Black" }}
                >
                  {totalHead}
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>

              </Tr>
            </Thead>
            </Table>

            </Card> */}
{/* head Count */}
        {/* <Flex direction="column" >
          <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
            <Flex>
              <CardHeader p="6px 0px 22px 0px">
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Head Count
                </Text>
              </CardHeader>
              <Spacer />
              <CardHeader p="6px 0px 22px 0px">
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                </Text>
              </CardHeader>
            </Flex>
            <CardBody pb={4}>
              <TableContainer>
                <Table size='sm'>
                  <Thead>
                    <Tr my=".8rem" pl="0px" color="gray.400">
                      <Th pl="30px"
                        borderColor={borderColor}
                        color="gray.400">Module</Th>
                      <Th isNumeric pl="0px"
                        borderColor={borderColor}
                        color="gray.400">Count</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                    <Flex align="center">
                    <Box cursor="pointer"><Td> <FaRunning style={{ color: '#808080' }} /></Td></Box>
                    <Box> <Link href="#/admin/visitor/head-count" fontWeight="bold"  color="gray.400">Visitor</Link></Box> </Flex>                 
                  <Td pr="30px" isNumeric  fontWeight="bold"  color="gray.400">{visitorHead}</Td>
                    </Tr>


                    <Tr>
                    <Flex align="center">
                    <Box cursor="pointer"><Td><MdOutlineProductionQuantityLimits style={{ color: '#808080' }} /></Td></Box>
                    <Box><Link href="#/admin/material/head-count" fontWeight="bold"  color="gray.400"> Material </Link></Box> </Flex> 
                      <Td pr="30px" isNumeric  fontWeight="bold"  color="gray.400">{materialHead}</Td>
                    </Tr>

                    <Tr>
                    <Flex align="center">
                    <Box cursor="pointer"><Td><GrUserWorker style={{ color: '#808080' }} /></Td></Box>
                    <Box><Link href='#/admin/workforce/head-count'  fontWeight="bold"  color="gray.400">Work Force</Link></Box> </Flex> 
                      <Td pr="30px" isNumeric  fontWeight="bold"  color="gray.400">{workForceHead}</Td>
                    </Tr>


                    <Tr>
                    <Flex align="center">
                    <Box cursor="pointer"><Td><IoFastFoodSharp style={{ color: '#808080' }} /></Td></Box>
                    <Box><Link href="#/admin/canteen/head-count"  fontWeight="bold"  color="gray.400">Canteen</Link></Box> </Flex> 
                      <Td pr="30px" isNumeric  fontWeight="bold"  color="gray.400">{canteenHead}</Td>
                    </Tr>


                    <Tr>
                    <Flex align="center">
                    <Box cursor="pointer"><Td><TbTruckDelivery style={{ color: '#808080' }}/></Td></Box>
                    <Box><Link href="#/admin/delivery/head-count"  fontWeight="bold"  color="gray.400">Delivery</Link></Box> </Flex> 
                      <Td pr="30px" isNumeric  fontWeight="bold"  color="gray.400">{deliveryHead}</Td>
                    </Tr>
                  </Tbody>
                  <Tfoot>
                    <Tr>
                      <Th pl="30px">Total</Th>
                      <Th pr="30px" isNumeric  fontWeight="bold">{totalHead}</Th>
                    </Tr>
                  </Tfoot>
                </Table>
              </TableContainer>
            </CardBody>
          </Card>
        </Flex> */}
      </>



    );
  }
  else if (authDetail.role?.name === 'Group') {
    return (
      <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
        <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px' mb='20px'>
        {(clusterDashboardData.map((row, index, arr) => {
                  return (
                    <Card minH='125px'>
            <Flex direction='column'>
              <Flex
                flexDirection='row'
                align='center'
                justify='center'
                w='100%'
                mb='25px'>
                <Stat me='auto'>
                  <StatLabel
                    fontSize='xs'
                    color='gray.400'
                    fontWeight='bold'
                    textTransform='uppercase'>
                    {row?.plant[0]?.plantName}
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      Pre Approved : {row.preapprovalCount}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox
                  borderRadius='50%'
                  as='box'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}>
                  <FaHouseChimneyUser style={{ fontSize: '25px', color: 'white' }} />
                </IconBox>
              </Flex>
              {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +3.48%{" "}
              </Text>
              Since last month
            </Text> */}
            </Flex>
          </Card>
                  );
                }))
                }
         
        </SimpleGrid>
        
      </Flex>
    );
  }
  else if (authDetail.role?.name === 'Corporate') {
    return (
      <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
        <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px' mb='20px'>
        {(corporateDashboardData.map((row, index, arr) => {
                  return (
                    <Link onClick={()=>{
                      activateImpersonateMode(row.plant?._id);
                    }}>
                      <Card minH='125px' style={{ cursor: 'pointer' }}>
                      <Flex direction='column'>
                        <Flex
                          flexDirection='row'
                          align='center'
                          justify='center'
                          w='100%'
                          mb='1px'>
                          <Stat me='auto'>
                          <Flex style={{'margin-bottom':'14px'}} justify='center'>
                          <StatLabel
                              align="center"
                              fontSize='md'
                              color='red.600'
                              fontWeight='bold'
                              textTransform='uppercase'>
                             {row.plant?.plant[0]?.plantName}
                              
                            </StatLabel>
                           
                          </Flex>
                          <Flex style={{'margin-bottom':'5px'}}>
                          <StatLabel
                              fontSize='xs'
                              color='red.600'
                              fontWeight='bold'
                              textTransform='uppercase'>
                              Live Head Count (IN)
                              
                            </StatLabel>
                            <Spacer></Spacer>
                            <iframe style={{display:'inline',paddingTop:"0px"}} height={20} width={25} src="https://lottie.host/embed/e33406ab-6e0a-49d2-94f5-9ee7f408cd4d/8Ijst1zrVe.json"></iframe>
                            <Spacer />
                            <StatLabel
                              fontSize='xs'
                              color='red.600'
                              fontWeight='bold'
                              textTransform='uppercase'>
                              {row?.totalHeadcount}
                            </StatLabel>
                            {/* <IconBox
                            borderRadius='50%'
                            as='box'
                            h={"30px"}
                            w={"30px"}
                            bg="red.500">
                            <IoMdPeople style={{ fontSize: '20px', color: 'white' }} />
                          </IconBox> */}
                          </Flex>
                            <Stack>
                            <Flex className="mt-2">
                              <StatLabel
                                fontSize='xs'
                                color='gray.400'
                                fontWeight='bold'
                                textTransform='uppercase'>
                              Visitor
                              </StatLabel>
                              <Spacer />
                              <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                                {row.visitorHeadCount}
                              </StatNumber>
                            </Flex>
                            <Flex>
                              <StatLabel
                                fontSize='xs'
                                color='gray.400'
                                fontWeight='bold'
                                textTransform='uppercase'>
                              Material Drivers
                              </StatLabel>
                              <Spacer />
                              <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                                {row.materialHeadCount}
                              </StatNumber>
                            </Flex>
                            <Flex>
                              <StatLabel
                                fontSize='xs'
                                color='gray.400'
                                fontWeight='bold'
                                textTransform='uppercase'>
                              Work Force
                              </StatLabel>
                              <Spacer />
                              <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                                {row.workforceHeadCount}
                              </StatNumber>
                            </Flex>
                            <Flex>
                              <StatLabel
                                fontSize='xs'
                                color='gray.400'
                                fontWeight='bold'
                                textTransform='uppercase'>
                              Canteen Vehicle Drivers
                              </StatLabel>
                              <Spacer />
                              <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                                {row.canteenHeadCount}
                              </StatNumber>
                            </Flex>
                            <Flex>
                              <StatLabel
                                fontSize='xs'
                                color='gray.400'
                                fontWeight='bold'
                                textTransform='uppercase'>
                              Delivery/Courier Boys
                              </StatLabel>
                              <Spacer />
                              <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                                {row.deliveryHeadCount}
                              </StatNumber>
                            </Flex>
                            </Stack>
                            

                          </Stat>
                          
                        </Flex>
                        {/* <Text color='gray.400' fontSize='sm'>
                      <Text as='span' color='green.400' fontWeight='bold'>
                        +3.48%{" "}
                      </Text>
                      Since last month
                    </Text> */}
                      </Flex>
                    </Card>
                    </Link>
                  );
                }))
                }
         
        </SimpleGrid>
        
      </Flex>
    );
  }
  else {
    return (
      <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
        <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px' mb='20px'>

        </SimpleGrid>
        {/* <Grid
        templateColumns={{ sm: "1fr", lg: "2fr 1fr" }}
        templateRows={{ lg: "repeat(2, auto)" }}
        gap='20px'>
        <Card
          bg={
            colorMode === "dark"
              ? "navy.800"
              : "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
          }
          p='0px'
          maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column' mb='40px' p='28px 0px 0px 22px'>
            <Text color='#fff' fontSize='lg' fontWeight='bold' mb='6px'>
              Sales Overview
            </Text>
            <Text color='#fff' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                (+5) more{" "}
              </Text>
              in 2022
            </Text>
          </Flex>
          <Box minH='300px'>
            <LineChart
              chartData={lineChartData}
              chartOptions={lineChartOptions}
            />
          </Box>
        </Card>
        <Card p='0px' maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column' mb='40px' p='28px 0px 0px 22px'>
            <Text color='gray.400' fontSize='sm' fontWeight='bold' mb='6px'>
              PERFORMANCE
            </Text>
            <Text color={textColor} fontSize='lg' fontWeight='bold'>
              Total orders
            </Text>
          </Flex>
          <Box minH='300px'>
            <BarChart chartData={barChartData} chartOptions={barChartOptions} />
          </Box>
        </Card>
        <Card p='0px' maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column'>
            <Flex align='center' justify='space-between' p='22px'>
              <Text fontSize='lg' color={textColor} fontWeight='bold'>
                Page visits
              </Text>
              <Button variant='primary' maxH='30px'>
                SEE ALL
              </Button>
            </Flex>
            <Box overflow={{ sm: "scroll", lg: "hidden" }}>
              <Table>
                <Thead>
                  <Tr bg={tableRowColor}>
                    <Th color='gray.400' borderColor={borderColor}>
                      Page name
                    </Th>
                    <Th color='gray.400' borderColor={borderColor}>
                      Visitors
                    </Th>
                    <Th color='gray.400' borderColor={borderColor}>
                      Unique users
                    </Th>
                    <Th color='gray.400' borderColor={borderColor}>
                      Bounce rate
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {pageVisits.map((el, index, arr) => {
                    return (
                      <Tr key={index}>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          fontWeight='bold'
                          borderColor={borderColor}
                          border={index === arr.length - 1 ? "none" : null}>
                          {el.pageName}
                        </Td>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          border={index === arr.length - 1 ? "none" : null}
                          borderColor={borderColor}>
                          {el.visitors}
                        </Td>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          border={index === arr.length - 1 ? "none" : null}
                          borderColor={borderColor}>
                          {el.uniqueUsers}
                        </Td>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          border={index === arr.length - 1 ? "none" : null}
                          borderColor={borderColor}>
                          {el.bounceRate}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
          </Flex>
        </Card>
        <Card p='0px' maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column'>
            <Flex align='center' justify='space-between' p='22px'>
              <Text fontSize='lg' color={textColor} fontWeight='bold'>
                Social traffic
              </Text>
              <Button variant='primary' maxH='30px'>
                SEE ALL
              </Button>
            </Flex>
          </Flex>
          <Box overflow={{ sm: "scroll", lg: "hidden" }}>
            <Table>
              <Thead>
                <Tr bg={tableRowColor}>
                  <Th color='gray.400' borderColor={borderColor}>
                    Referral
                  </Th>
                  <Th color='gray.400' borderColor={borderColor}>
                    Visitors
                  </Th>
                  <Th color='gray.400' borderColor={borderColor}></Th>
                </Tr>
              </Thead>
              <Tbody>
                {socialTraffic.map((el, index, arr) => {
                  return (
                    <Tr key={index}>
                      <Td
                        color={textTableColor}
                        fontSize='sm'
                        fontWeight='bold'
                        borderColor={borderColor}
                        border={index === arr.length - 1 ? "none" : null}>
                        {el.referral}
                      </Td>
                      <Td
                        color={textTableColor}
                        fontSize='sm'
                        borderColor={borderColor}
                        border={index === arr.length - 1 ? "none" : null}>
                        {el.visitors}
                      </Td>
                      <Td
                        color={textTableColor}
                        fontSize='sm'
                        borderColor={borderColor}
                        border={index === arr.length - 1 ? "none" : null}>
                        <Flex align='center'>
                          <Text
                            color={textTableColor}
                            fontWeight='bold'
                            fontSize='sm'
                            me='12px'>{`${el.percentage}%`}</Text>
                          <Progress
                            size='xs'
                            colorScheme={el.color}
                            value={el.percentage}
                            minW='120px'
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
        </Card>
      </Grid> */}
      </Flex>
    );
  }

}
