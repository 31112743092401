// Chakra imports
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  Select,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { busClearShowSnackbar, busAddOrUpdate, } from '../../actions/busAction'; // Assuming you have the bus action functions
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useToast } from '@chakra-ui/react';
import { BUS_CLEAR_SNACKBAR } from "constants/types";
import { Spinner } from '@chakra-ui/react';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import dataConstant from "constants/dataConstant";
import axios from "axios";
import { server_url } from "constants/types";
import CustomInput from "views/CustomValidation";
import { driverGetAll } from "actions/DriverAction";

function BusUpdateForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [busNo, setBusNo] = useState("");
  const [routeNo, setRouteNo] = useState("");
  const [driver, setDriver] = useState("");
  const [routnoList, setRoutnoList] = useState([]);

  // const [driverList, setDriverList] = useState("");
  const [transport, setTransport] = useState("");
  const [sittingCapacity, setSittingCapacity] = useState("");
  const [insuranceValidityDate, setInsuranceValidityDate] = useState("");
  const [isErrorBusNo, setIsErrorBusNo] = useState(false);
  const [isErrorRouteNo, setIsErrorRouteNo] = useState(false);
  const [isErrorSittingCapacity, setIsErrorSittingCapacity] = useState(false);
  const [isErrorInsuranceValidityDate, setIsErrorInsuranceValidityDate] = useState(false);
  const [isErrorDriver, setIsErrorDriver] = useState(false);
  const [isErrorTransport, setIsErrorTransport] = useState(false);


  const [submitted, setSubmitted] = useState(false);

  const busnoRegex = /^\d{4}$/;
  const numberRegex = /^[0-9]{0,3}$/;

  const dispatch = useDispatch();
  const toast = useToast();

  const responseStatus = useSelector(state => state.bus.status);
  const responseMessage = useSelector(state => state.bus.text);
  const loading = useSelector(state => state.bus.loading);
  const showSnackbar = useSelector(state => state.bus.showSnackbar);
  const busDetail = useSelector(state => state.bus.busDetail);
  const driverList = useSelector(state => state.driver.driverList);

  console.log("driverList?>?>?>?>?");
  console.log(driverList);

  // useEffect(() => {
  //   dispatch(driverGetAll())
  // }, [dispatch])

  const selectedBus = useSelector(state => state.bus.selectedBus); // Assuming you have a selectedBus state in your Redux store

  const params = useParams();
  const busId = params.id;
  console.log("busId");
  console.log(busId);
  const history = useHistory();

  useEffect(() => {
    const bus = {
      _id: busId,
    };
    dispatch(busAddOrUpdate(bus, "view"));
  }, []);
  console.log("busDetail");
  console.log(busDetail);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsErrorBusNo(busNo === "");
    // setIsErrorRouteNo(routeNo === "");
    // setIsErrorSittingCapacity(sittingCapacity === "");
    // setIsErrorInsuranceValidityDate(insuranceValidityDate === "");
    setIsErrorTransport(transport === '');

    if (
      busNo !== "" &&
      // sittingCapacity !== "" &&
      // routeNo !== "" &&
      // insuranceValidityDate !== "" &&
      // driver !== "" &&
       transport !== ""
    ) {
      console.log("Submit");
      const busData = {
        _id: busId, // Assuming your bus model has an id field
        busNo: busNo,
        
       
        sittingCapacity: sittingCapacity,
        insuranceValidityDate: insuranceValidityDate,
        type: transport,
        // Other bus data fields
      };
      if(routeNo)
      {
        busData.route = routeNo;
      }
      if(driver)
      {
        busData. driver = driver;
      }
      console.log(busData);

      // Call the busAddOrUpdate action
      dispatch(busAddOrUpdate(busData, "edit"));
    }
    // DriverListforDropdown()
    // history.push("/admin/bus-table")
  };

  const RoutenoListforDropdown = () => {
    axios
      .get(`${server_url}/routes`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log(responce.data.data)
        setRoutnoList(responce.data.data)
      }).catch(() => console.log("error"))
  }

  // const DriverListforDropdown = () => {
  //   axios
  //     .get(`${server_url}/AvailableDriver`, {
  //       headers: {
  //         'Authorization': 'Bearer ' + localStorage.getItem('authToken')
  //       }
  //     }).then((responce) => {
  //       console.log(responce.data.data)
  //       setDriverList(responce.data.data)
  //     }).catch(() => console.log("error"))
  // }

  useEffect(() => {
    RoutenoListforDropdown()
    // DriverListforDropdown()
  }, [])

  useEffect(() => {
    if (true) {
      if (responseStatus === "OK" && loading === false) {
        console.log("busDetail??????>>>:>:>::>");
        console.log(busDetail);
        setBusNo(busDetail.busNo);
        // setRouteNo(busDetail.route);
        setSittingCapacity(busDetail.sittingCapacity);
        setInsuranceValidityDate(busDetail.insuranceValidityDate);
        // setDriver(busDetail.driver.Name)
        // setDriver(busDetail.driver.Name)
        setTransport(busDetail.type)
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === "NOK" && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }

    dispatch({
      type: BUS_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);





  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
           Bus Update Form
          </Text>
          <br/>
          <SimpleGrid minChildWidth="120px" spacing="40px">
            <FormControl isInvalid={isErrorBusNo}>

              <CustomInput
                required={true}
                type="text"
                label="Bus No"
                value={busNo}
                onChange={(value) => {
                  setIsErrorBusNo(value === "");
                  setBusNo(value);
                }}
                submitted={submitted}
                // regex={busnoRegex}
              />
            </FormControl>
            <FormControl isInvalid={isErrorInsuranceValidityDate}>
              <FormLabel>Insurance Validity Date</FormLabel>
              <Input
                type='Date'
                value={insuranceValidityDate}
                onChange={(e) => {
                  setIsErrorInsuranceValidityDate(e.target.value === '')
                  setInsuranceValidityDate(e.target.value)
                }}
              />
              {!isErrorInsuranceValidityDate ? (
                <></>
              ) : (
                <FormErrorMessage>
                  Insurance Validity Date is required.
                </FormErrorMessage>
              )}
            </FormControl>

            {/* <FormControl isInvalid={isErrorRouteNo || (submitted && routeNo === "")}>
              <FormLabel>Route No</FormLabel>
              <Select
                placeholder="-- select Route No --"

                onChange={(e) => {
                  setIsErrorRouteNo(e.target.value === '');
                  setRouteNo(e.target.value);
                }}
                value={routeNo}

              >
                {routnoList ? (routnoList.map((item, index) => (
                  <option key={item._id} value={item._id}>{item.name}</option>
                ))) : (<></>)}
              </Select>
              {isErrorRouteNo && submitted && (
                <FormErrorMessage>Route No is required.</FormErrorMessage>
              )}
            </FormControl> */}
          </SimpleGrid>
          <SimpleGrid minChildWidth="120px" spacing="40px" mt="24px">
            {/* <FormControl isInvalid={isErrorSittingCapacity || (submitted && sittingCapacity === "")}>

              <CustomInput
                type="number"
                label="Sitting Capacity"
                value={sittingCapacity}
                onChange={(value) => {
                  setIsErrorSittingCapacity(value === "" || !numberRegex.test(value));
                  setSittingCapacity(value);
                }}
                regex={numberRegex}
                submitted={submitted}
              />
            </FormControl> */}
            
          </SimpleGrid>

          <SimpleGrid minChildWidth="120px" spacing="40px">
          {/* <FormControl isInvalid={isErrorDriver || (submitted && drive === "")}>
            <FormLabel>Driver</FormLabel>
            <Select
              placeholder="-- select Driver --"
             
              onChange={(e) => {
                setIsErrorDriver(e.target.value === '');
                setDriver(e.target.value);
              }}
              value={driver}
            >
              {driverList ? (driverList.map((item, index) => (
                <option key={item._id} value={item._id} 
                selected={driver == item.Name ? true : false}
                >{item.Name}</option>
                
              ))) : (<></>)}
            </Select>
            {isErrorDriver && submitted && (
              <FormErrorMessage>Driver is required.</FormErrorMessage>
            )}
          </FormControl> */}

          <FormControl isInvalid={isErrorTransport}>
            <FormLabel>Transport Type<span style={{ color: 'red' }}>*</span></FormLabel>
            <Select
              placeholder="-- select Transport --"
              value={transport}
              onChange={(e) => {
                setIsErrorTransport(e.target.value === '');
                setTransport(e.target.value);
              }}

            >
              {dataConstant.transportType.map((element, index) => {
                return (<option key={element['id']} value={element['name']} >{element['name']}</option>);

              })}

            </Select>
            {isErrorTransport && submitted && (
              <FormErrorMessage>Transport type is required.</FormErrorMessage>
            )}
          </FormControl>
          </SimpleGrid>
          <Center>
            {loading ? <Spinner mt='24px' size='lg' /> :
              <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                Submit
              </Button>
            }
          </Center>
        </CardHeader>
        <CardBody>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default BusUpdateForm;
