// Chakra imports
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  Select,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { bannerAddOrUpdate } from '../../actions/bannerActions';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'
import { BANNER_CLEAR_SNACKBAR } from "constants/types";
import { Spinner } from '@chakra-ui/react'
import dataConstant from "constants/dataConstant";
import { bannerPositionGetAll } from '../../actions/bannerpositionAction';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { server_url } from '../../constants/types';



function BannerForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [status, setStatus] = useState("");
  const [bannerPosition, setBannerPosition] = useState("");
  const [position, setPosition] = useState("");
  const [number, setNumber] = useState("");
  const [startDate , setStartDate] = useState("");
  const [endDate , setEndDate] = useState("");
  const [alertMessage, setAlertMessage] = useState('');
  const [description, setDesc] = useState("");
  const [launchUrl, setLaunchUrl] = useState("");

  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorImage, setIsErrorImage] = useState(false);
  const [isErrorStatus, setIsErrorStatus] = useState(false);
  const [isErrorPosition, setIsErrorPosition] = useState(false);
  const [isErrorNumber, setIsErrorNumber] = useState(false);
  const [isErrorBannerPosition, setIsErrorBannerPosition] = useState(false);
  const [isErrorStartDate, setIsErrorStartDate] = useState(false);
  const [isErrorEndDate, setIsErrorEndDate] = useState(false);
  const [isErrorDesc, setIsErrorDesc] = useState(false);
  const [isErrorLaunchUrl, setIsErrorLaunchUrl] = useState(false);
  const [submitted, setSubmitted] = useState(false);


  const dispatch = useDispatch();
  const toast = useToast();
  const numberRegex = /^\d{10}$/;

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.banner.status);
  const responseMessage = useSelector(state => state.banner.text);
  const loading = useSelector(state => state.banner.loading);
  const showSnackbar = useSelector(state => state.banner.showSnackbar);
  const bannerPositionList = useSelector(state => state.bannerposition.bannerPositionList);

  console.log("bannerPositionList?././././.?????/?")
  console.log(bannerPositionList);

  // useEffect(()=>{
  //   dispatch(bannerGetAll())
  // }, [dispatch])


  const history = useHistory();
  // Function to handle form submission]
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsErrorName(name === '');
    setIsErrorImage(image === '');
    setIsErrorStatus(status === '');
    setIsErrorPosition(position === '');
    setIsErrorNumber(number === '');
    setIsErrorBannerPosition(bannerPosition === '');
    setIsErrorStartDate(startDate === '');
    setIsErrorEndDate(endDate === '');
    setIsErrorDesc(description === "")

    if (name !== "" && image !== "" && status !== "" && position !== "" && number !== "" && bannerPosition !== '' 
     && startDate !== ''  && endDate !== '' && description !== "" ) {
      console.log("Submit");
      // Get form data
      const bannerData = {
        name: name,
        image: image,
        status: status,
        position: position,
        number: number,
        bannerPosition:bannerPosition,
        startDate : startDate,
        endDate : endDate,
        description : description,
        launchUrl : launchUrl
      };

      // Determine if it's an add or edit operation
      const state = 'add';
      // Call the bannerAddOrUpdate action
      dispatch(bannerAddOrUpdate(bannerData, state))
    }
  };

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
          history.goBack();
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: BANNER_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  useEffect(() => {
    dispatch(bannerPositionGetAll("&limit=100"));
  }, [dispatch]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Form
          </Text>
          <SimpleGrid minChildWidth='120px' spacing='40px'>
            <FormControl Control isInvalid={isErrorName}>
              <FormLabel>Name</FormLabel>
              <Input
                type='text'
                onChange={(e) => {
                  setIsErrorName(e.target.value === '')
                  setName(e.target.value)
                }}
              />
              {!isErrorName ? (
                <></>
              ) : (
                <FormErrorMessage>Name is required.</FormErrorMessage>
              )}
            </FormControl>
            
            <FormControl isInvalid={isErrorImage}>
              <FormLabel>Image</FormLabel>
              <Input
                type='file'
                onChange={(e) => {
                  console.log(e.target.files);
                  console.log(e.target.value);
                  setIsErrorImage(e.target.value === '')
                  setImage(e.target.files[0])
                }}
              />
              {!isErrorImage ? (
                <></>
              ) : (
                <FormErrorMessage>Image size 1200 * 628 pixels is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorDesc}>
                <FormLabel>Description</FormLabel>
                <Input
                  type='text'
                  onChange={(e) => {
                    setIsErrorDesc(e.target.value === '')
                    setDesc(e.target.value)
                  }}
                />
                {!isErrorDesc ? (
                  <></>
                ) : (
                  <FormErrorMessage>Description is required.</FormErrorMessage>
                )}
              </FormControl>


          </SimpleGrid>
          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            <FormControl isInvalid={isErrorStatus}>
              <FormLabel>Status</FormLabel>
              <Select
                placeholder="Select status"
                onChange={(e) => {
                  setIsErrorStatus(e.target.value === '')
                  setStatus(e.target.value)
                }}
              >
                { dataConstant.status.map((element,index) => {
                    return (<option key={element['id']} value={element['name']} >{element['name']}</option>);   
                      
                  })}
              </Select>
              {!isErrorStatus ? (
                <></>
              ) : (
                <FormErrorMessage>Status is required.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={isErrorPosition}>
              <FormLabel>Position</FormLabel>
              <Input
                type='text'
                onChange={(e) => {
                  setIsErrorPosition(e.target.value === '')
                  setPosition(e.target.value)
                }}
              />
              {!isErrorPosition ? (
                <></>
              ) : (
                <FormErrorMessage>Position is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorNumber}>
              <FormLabel>Phone Number</FormLabel>
              <Input
                type="number"
                onChange={(e) => {
                  setIsErrorNumber(e.target.value === "" || !numberRegex.test(e.target.value));
                  setNumber(e.target.value);
                }}
                regex={numberRegex}
                submitted={submitted}
              />
              {!isErrorNumber ? (
                <></>
              ) : (
                <FormErrorMessage>Number is required.</FormErrorMessage>
              )}
            </FormControl>
            
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
          <FormControl isInvalid={isErrorStartDate}>
              <FormLabel>Start Date</FormLabel>
              <Input
                type='Date'
                onChange={(e) => {
                  setIsErrorStartDate(e.target.value === '')
                  setStartDate(e.target.value)
                }}
              />
              {!isErrorStartDate ? (
                <></>
              ) : (
                <FormErrorMessage>StartDate is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorEndDate}>
              <FormLabel>End Date</FormLabel>
              <Input
                type='Date'
                onChange={(e) => {
                  setIsErrorEndDate(e.target.value === '')
                  setEndDate(e.target.value)
                }}
              />
              {!isErrorEndDate ? (
                <></>
              ) : (
                <FormErrorMessage>EndDate is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorBannerPosition}>
              <FormLabel>Banner Position</FormLabel>
              <Select
                placeholder="Select Banner Position"
                onChange={(e) => {
                  setIsErrorBannerPosition(e.target.value === '')
                  setBannerPosition(e.target.value)
                }}
              >
                {bannerPositionList.map((element,index) => {
                    return (<option key={element['_id']} value={element['_id']} >{element['position']}</option>);   
                      
                  })}
              </Select>
              {!isErrorBannerPosition ? (
                <></>
              ) : (
                <FormErrorMessage>Banner Position is required.</FormErrorMessage>
              )}
            </FormControl>
            </SimpleGrid>
            <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            <FormControl isInvalid={isErrorLaunchUrl}>
              <FormLabel>Launch URL</FormLabel>
              <Input
                type='text'
                onChange={(e) => {
                  setLaunchUrl(e.target.value)
                }}
              />
              {!isErrorLaunchUrl ? (
                <></>
              ) : (
                <FormErrorMessage>Launch url is required.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl></FormControl>
            <FormControl></FormControl>
            </SimpleGrid>

          <Center>
            {loading ? <Spinner mt='24px' size='lg' /> :
              <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                Submit
              </Button>
            }
          </Center>
        </CardHeader>
        <CardBody>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default BannerForm;
