import axios from 'axios';
import {
  INSPECTION_SUMMARY_LOADING,
  INSPECTION_SUMMARY_GET_ALL_SUCCESS,
  INSPECTION_SUMMARY_ERROR,
  INSPECTION_SUMMARY_CLEAR_SNACKBAR
} from '../constants/types';

import { server_url } from '../constants/types';

export const inspectionSummaryListGetAll = (pagination = '') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: INSPECTION_SUMMARY_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/admin/inspection/get/task?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          console.log("employee-data-data-data");
          console.log(response.data.data)
          dispatch({
            type: INSPECTION_SUMMARY_GET_ALL_SUCCESS,
            payload: {
              inspectionList: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              totalItems: response.data.totalItems,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all role employee successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: INSPECTION_SUMMARY_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting employee data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};


export const inspectionListClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: INSPECTION_SUMMARY_CLEAR_SNACKBAR
    });
  };
};
