// Chakra imports
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  Select,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { biometricSettingsAddOrUpdate, biometricSettingsClearShowSnackbar,biometricSettingsGetAll } from '../../actions/biometricsettingsAction';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'
import { BIOMETRIC_SETTINGS_CLEAR_SNACKBAR } from "constants/types";
import { Spinner } from '@chakra-ui/react'
import dataConstant from "constants/dataConstant";
import { useHistory,useParams } from 'react-router-dom';
import axios from 'axios';
import { server_url } from '../../constants/types';


function BiometricSettingsUpdate() {
  const params = useParams();
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [description, setDescription] = useState("");
  const [baseurl, setBaseurl] = useState("");
  const [authtype, setAuthtype] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [timeInterval, setTimeIntervel] = useState("");


  const [status, setStatus] = useState('false');
  const [type, setType] = useState("");
  const [city , setCity] = useState("");

  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorPosition, setIsErrorPosition] = useState(false);
  const [isErrorDescription, setIsErrorDescription] = useState(false);
  const [isErrorBaseurl, setIsErrorBaseurl] = useState(false);
  const [isErrorAuthtype, setIsErrorAuthtype] = useState(false);
  const [isErrorUsername, setIsErrorUsername] = useState(false);
  const [isErrorPassword, setIsErrorPassword] = useState(false);
  const [isErrorToken, setIsErrorToken] = useState(false);
  const [isErrorTimeInterval, setIsErrorTimeInterval] = useState(false);

  
  const [isErrorType, setIsErrorType] = useState(false);
  const [isErrorStatus, setIsErrorStatus] = useState(false);
  const [isErrorRole, setIsErrorRole] = useState(false);
  const [isErrorCity , setIsErrorCity] = useState(false);
  const [biometricSettingsDetail , setBiometricSettingsDetail] = useState('');
  const [plantAdmin , setPlantAdmin] = useState('');
  const [id , setId] = useState('');

  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const responseStatus = useSelector(state => state.biometricSettings.status);
  const responseMessage = useSelector(state => state.biometricSettings.text);
  const loading = useSelector(state => state.biometricSettings.loading);
  const showSnackbar = useSelector(state => state.biometricSettings.showSnackbar);
  // const biometricSettingsDetail = useSelector(state => state.biometricSettings.biometricSettingsDetail);
  useEffect(()=>{
    viewBiometricSettings(params.id);
  }, [dispatch])

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])

  const viewBiometricSettings = (id) => {
    axios
      .get(`${server_url}/attendanceiot/settings/view/${id}`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        if(responce.data.data)
        {
          console.log(responce.data.data);
          console.log("responce.data.data");
          setId(responce.data.data._id);
          setName(responce.data.data.name);
          setDescription(responce.data.data.description);
          setBaseurl(responce.data.data.baseUrl);
          setAuthtype(responce.data.data.authType);
          setUsername(responce.data.data.username);
          setPassword(responce.data.data.password);
          setStatus(responce.data.data.status);
          setType(responce.data.data.type);
          setToken(responce.data.data.token);
          setTimeIntervel(responce.data.data.timeInterval);
          setPlantAdmin(responce.data.data.plantAdmin);
        }
      })
      .catch((e) => {

      });
  }


  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsErrorName(name === '');
    setIsErrorBaseurl(baseurl ==='')
    setIsErrorAuthtype(authtype ==='')
    setIsErrorUsername(username ==='')
    setIsErrorPassword(password ==='')

    if (name !== "" && baseurl !== "" && authtype !== "" && username !== "" && password !== ""  ) {
      console.log("Submit");
      // Get form data
      const biometricSettings = {
        _id : id,
        name: name,
        baseUrl: baseurl,
        authType: authtype,
        username: username,
        password: password,
        status: status,
        token : token,
        timeInterval:timeInterval,
        type : type,

        // Other banner position data fields
      };

      // Determine if it's an add or edit operation
      const state = 'edit';
      // Call the bannerPositionAddOrUpdate action
      dispatch(biometricSettingsAddOrUpdate(biometricSettings, state,plantAdmin._id))
      // history.push("/admin/banner-position")
    }
  };

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
          history.goBack();
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: BIOMETRIC_SETTINGS_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Biometric Settings Update Form
          </Text>
          <SimpleGrid minChildWidth='120px' spacing='40px'>
            
            <FormControl isInvalid={isErrorName}>
              <FormLabel>Brand Name</FormLabel>
              <Input
                type='text'
                value={name}
                onChange={(e) => {
                  setIsErrorName(e.target.value === '')
                  setName(e.target.value)
                }}
              />
              {!isErrorName ? (
                <></>
              ) : (
                <FormErrorMessage>Name is required.</FormErrorMessage>
              )}
            </FormControl>
            
            <FormControl isInvalid={isErrorDescription}>
              <FormLabel>Description</FormLabel>
              <Input
                type='text'
                value={description}
                onChange={(e) => {
                  setIsErrorDescription(e.target.value === '')
                  setDescription(e.target.value)
                }}
              />
              {!isErrorDescription ? (
                <></>
              ) : (
                <FormErrorMessage>Description is required.</FormErrorMessage>
              )}
            </FormControl>
            
            <FormControl isInvalid={isErrorBaseurl}>
              <FormLabel>Base URL</FormLabel>
              <Input
                type='text'
                value={baseurl}
                onChange={(e) => {
                  setIsErrorBaseurl(e.target.value === '')
                  setBaseurl(e.target.value)
                }}
              />
              {!isErrorBaseurl ? (
                <></>
              ) : (
                <FormErrorMessage>Base URL is required.</FormErrorMessage>
              )}
            </FormControl>

            
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            
            <FormControl isInvalid={isErrorAuthtype}>
              <FormLabel>Auth Type</FormLabel>
              <Select
                  placeholder="Select Auth Type"
                  value={authtype}
                  onChange={(e) => {
                    setIsErrorAuthtype(e.target.value === '')
                    setAuthtype(e.target.value)
                  }}
                >
                  { dataConstant.biometricAuthType.map((element,index) => {
                      return (<option key={element['name']} value={element['value']} >{element['name']}</option>);   
                        
                    })}
                </Select>
              {!isErrorAuthtype ? (
                <></>
              ) : (
                <FormErrorMessage>Auth Type is required.</FormErrorMessage>
              )}
            </FormControl>
            
            <FormControl isInvalid={isErrorUsername}>
              <FormLabel>Username</FormLabel>
              <Input
                type='text'
                value={username}
                onChange={(e) => {
                  setIsErrorUsername(e.target.value === '')
                  setUsername(e.target.value)
                }}
              />
              {!isErrorUsername ? (
                <></>
              ) : (
                <FormErrorMessage>Username is required.</FormErrorMessage>
              )}
            </FormControl>
            
            <FormControl isInvalid={isErrorPassword}>
              <FormLabel>Password</FormLabel>
              <Input
                type='text'
                value={password}
                onChange={(e) => {
                  setIsErrorPassword(e.target.value === '')
                  setPassword(e.target.value)
                }}
              />
              {!isErrorPassword ? (
                <></>
              ) : (
                <FormErrorMessage>Password is required.</FormErrorMessage>
              )}
            </FormControl>

            
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
          <FormControl isInvalid={isErrorToken}>
                <FormLabel>Token</FormLabel>
                <Input
                  type='text'
                  onChange={(e) => {
                    setIsErrorToken(e.target.value === '')
                    setToken(e.target.value)
                  }}
                  value={token}
                />
                {!isErrorToken ? (
                  <></>
                ) : (
                  <FormErrorMessage>Token is required.</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={isErrorTimeInterval}>
                <FormLabel>Time Interval (for getiing data from the client server)</FormLabel>
                <Select
                  placeholder="Select Time Interval"
                  value={timeInterval}
                  onChange={(e) => {
                    setIsErrorTimeInterval(e.target.value === '')
                    setTimeIntervel(e.target.value)
                  }}
                >
                  { dataConstant.biometricTimeInterval.map((element,index) => {
                      return (<option key={element['name']} value={element['value']} >{element['name']}</option>);   
                        
                    })}
                </Select>
                {!isErrorTimeInterval ? (
                  <></>
                ) : (
                  <FormErrorMessage>Time Interval is required.</FormErrorMessage>
                )}
              </FormControl>
            <FormControl isInvalid={isErrorStatus}>
              <FormLabel>Status</FormLabel>
              <Select
                placeholder="Select status"
                value={status}
                onChange={(e) => {
                  setIsErrorStatus(e.target.value === '')
                  setStatus(e.target.value)
                }}
              >
                { dataConstant.status.map((element,index) => {
                    return (<option key={element['id']} value={element['value']}>{element['name']}</option>);   
                      
                  })}
              </Select>
              {!isErrorStatus ? (
                <></>
              ) : (
                <FormErrorMessage>Status is required.</FormErrorMessage>
              )}
            </FormControl>

            {/* <FormControl isInvalid={isErrorType}>
              <FormLabel>Type</FormLabel>
              <Select
                placeholder="Select Type"
                value={type}
                onChange={(e) => {
                  setIsErrorType(e.target.value === '')
                  setType(e.target.value)
                }}
              >
                { dataConstant.biometricType.map((element,index) => {
                    return (<option key={element['name']} value={element['value']} selected={type == element['value']?true:false}>{element['name']}</option>);   
                      
                  })}
              </Select>
              {!isErrorType ? (
                <></>
              ) : (
                <FormErrorMessage>Type is required.</FormErrorMessage>
              )}
            </FormControl> */}
          </SimpleGrid>

          <Center>
            {loading ? <Spinner mt='24px' size='lg' /> :
              <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                Submit
              </Button>
            }
          </Center>
        </CardHeader>
        <CardBody>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default BiometricSettingsUpdate;
