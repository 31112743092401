const {
    GUARDATTENDENCE_LOADING,
    GUARDATTENDENCE_GET_ALL_SUCCESS,
    GUARDATTENDENCE_ERROR,
    GUARDATTENDENCE_CLEAR_SNACKBAR
  } = require('../constants/types');
  
  const initialState = {
    response: {},
    guardattendenceList: [],
    guardattendenceDetail: [],
    loading: false,
    status: 'success',
    text: '',
    showSnackbar: false
  };
  
  const GuardAttendenceReducer = (state = initialState, action) => {
    switch (action.type) {
      case GUARDATTENDENCE_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case GUARDATTENDENCE_LOADING:
        return {
          ...state,
          loading: true
        };
      case GUARDATTENDENCE_ERROR:
        return {
          ...state,
          guardattendenceList: [],
          response: {},
          guardattendenceDetail: {},
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case GUARDATTENDENCE_GET_ALL_SUCCESS:
        return {
          ...state,
          guardattendenceList: action.payload.guardattendenceList,
          response: action.payload.response,
          guardattendenceDetail: action.payload.guardattendenceDetail,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      default:
        return state || {};
    }
  };
  
  export default GuardAttendenceReducer;
  // module.exports = GuardAttendenceReducer;
  