import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Spinner,
  Image,
  Select
} from "@chakra-ui/react";
import Relect from 'react-select';
import dataConstant from "constants/dataConstant";
import { SOS_CLEAR_SNACKBAR } from "constants/types";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useDispatch, useSelector } from "react-redux";

import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';

import { useToast } from '@chakra-ui/react'

import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { sosAddOrUpdate } from "actions/sosAction";
import CustomInput from "views/CustomValidation";

function SosUpdateForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [photo, setPhoto] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [type, setType ] = useState("");
  const [blood, setBlood ] = useState("");
  const [format, setFormat ] = useState("");
  const [number, setNumber ] = useState("");
  
  
  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorImage, setIsErrorImage] = useState(false);
  const [isErrorDescription, setIsErrorDescription] = useState(false);
  const [isErrorEmail , setIsErrorEmail ] = useState(false);
  const [isErrorAddress, setIsErrorAddress] = useState(false);
  const [isErrorCheckpost, setIsErrorCheckpost] = useState(false);
  const [isErrorType, setIsErrorType] = useState(false);
  const [isErrorBlood, setIsErrorBlood] = useState(false);
  const [isErrorFormatDate, setIsErrorFormatDate] = useState(false);
  const [isErrorNumber, setIsErrorNumber] = useState(false);
 
  const history = useHistory();
  const [submitted, setSubmitted] = useState(false);

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.sos.status);
  const responseMessage = useSelector(state => state.sos.text);
  const loading = useSelector(state => state.sos.loading);
  const showSnackbar = useSelector(state => state.sos.showSnackbar);
  const sosDetail = useSelector(state => state.sos.sosDetail);

  console.log(" sosDetail ");
  console.log(sosDetail);

  const [isErrorState, setIsErrorState] = useState(false);
  
  const dispatch = useDispatch();
  const toast = useToast();

  const params = useParams();
  const Idofsos = params.id;

  
  useEffect(() => {
    
      const sos = {
        _id : Idofsos
      }
      dispatch(sosAddOrUpdate(sos,'view'));
  }, []);
  

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        
        setPhoto(sosDetail.employee.photo);
        setName(sosDetail.employee.employeeName);
        setNumber(sosDetail.employee.phone);
        setEmail(sosDetail.employee.officialEmail);  
        setDescription(sosDetail.sosCategory.description)
        setAddress(sosDetail.employee.employeeAddress)
        setType(sosDetail.employee.employeeType)
        setBlood(sosDetail.employee.bloodGroup)

        // setCheckpost(sosDetail.checkpost);
        // setSelectedCheckposts(sosDetail.checkpost.map((item) => ({
        //   value: item._id,
        //   label: item.name
        //   })));
          // if(sosDetail.homeModule){
          //   setHomeModule(sosDetail.homeModule.map((item) => ({
          //     value: item.value,
          //     label: item.name
          //     })));
          // }else{
          //   setHomeModule(sosDetail.homeModule);
          // }
      //  sosDetail.employee.employeeModules
      //  .map((item)=>{
      //     setLine1(item.line1) ; 
      //     setLine2(item.line2) ; 
      //     setPostalCode(item.postalCode) ; 
      //     setLocality(item.locality) ; 
      //     setState(item.state)}) ;
      
      } 
      
    }

    dispatch({
      type: SOS_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsErrorName(name === '');
    setIsErrorImage(photo === '');
    setIsErrorDescription(description === '');
    setIsErrorEmail(officialEmail === '');
    setIsErrorAddress(employeeAddress === '');
    setIsErrorCheckpost(selectedCheckposts.length == 0);
    setIsErrorType(employeeType === '');
    setIsErrorBlood(bloodGroup === '');
    setIsErrorFormatDate(formattedDateTime === '');

    if (name !== "" && photo !== "" && description !=="" && email !== "" && address !== "" && type !== "" && blood !== "" && format !== ""&& number !== "" ) {

      const newSos = { 
        _id: Idofsos,
        name,
        file:photo,
        number,
        email,
        description,
        address,
        type,
        blood,
        format,
        number

      };
      console.log(newSos)
      dispatch(sosAddOrUpdate(newSos , 'edit'));
    }  
   
  };


  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: SOS_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  // const checkpostListforDropdown = ()=>{
  //   axios
  //   .get(`${server_url}/plant/checkpost-list`, {
  //     headers: {
  //       'Authorization': 'Bearer ' + localStorage.getItem('authToken')
  //     }
  //   }).then((responce)=>{
      
  //     setCheckpostList(responce.data.data)
  //   }).catch(()=> console.log("error"))
  // }

  // useEffect(()=>{
  //   checkpostListforDropdown()
  
   
  // },[])

  const textRegex = /^[A-Za-z\s]+$/;
  const numberRegex = /^\d{10}$/;
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/;
  const sosIdRegex = /^[a-zA-Z0-9]+$/;
  const postalCodeRegex = /^[0-9]{6}$/;

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Sos Update Form
          </Text>
          <SimpleGrid minChildWidth='120px' spacing='40px'>

              <FormControl isInvalid={isErrorName || (submitted && name === "")}>
                <CustomInput
                  type='text'
                  label='Sos Name'
                  value={name}
                  onChange={(value) => {
                    setIsErrorName(value === '' || !textRegex.test(value));
                    setName(value);
                  }}
                  isInvalid={isErrorName || (submitted && name === "")}
                  regex={textRegex}
                  submitted={submitted}
                />
              </FormControl>

              <FormControl isInvalid={isErrorImage || (submitted && photo === "")}>
                <FormLabel>Photo</FormLabel>
                <Input
                  type='file'
                  onChange={(e) => {
                    setIsErrorPhoto(e.target.value === '')
                    setPhoto(e.target.files[0])
                  }}
                />
                {isErrorImage && submitted && (
                  <FormErrorMessage>Photo is required.</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={isErrorDescription || (submitted && description === "")}>
                <CustomInput
                  type='text'
                  label='Description'
                  value={description}
                  onChange={(value) => {
                    setIsErrorDescription(value === '' || !textRegex.test(value));
                    setDescription(value);
                  }}
                  isInvalid={isErrorDescription || (submitted && description === "")}
                  regex={textRegex}
                  submitted={submitted}
                />
              </FormControl>
            </SimpleGrid>

            <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
              <FormControl isInvalid={isErrorEmail || (submitted && email === "")}>
                <CustomInput
                  type="email"
                  label="Email"
                  value={email}
                  onChange={(value) => {
                    setIsErrorEmail(value === '' || !numberRegex.test(value));
                    setEmail(value);
                  }}
                  isInvalid={isErrorEmail || (submitted && email === "")}
                  regex={numberRegex}
                  submitted={submitted}
                />
              </FormControl>

              <FormControl isInvalid={isErrorAddress || (isErrorAddress && address === "")}>
                <CustomInput
                  type="text"
                  label="Address"
                  value={address}
                  onChange={(value) => {
                    setIsErrorAddress(value === '' || !numberRegex.test(value));
                    setAddress(value);
                  }}
                  isInvalid={isErrorAddress || (isErrorAddress && address === "")}
                  regex={numberRegex}
                  submitted={submitted}
                />
              </FormControl>

              <FormControl isInvalid={isErrorType || (submitted && email === "")}>
                <CustomInput
                  type="text"
                  label="Error Type"
                  value={type}
                  onChange={(value) => {
                    setIsErrorType(value === '' || !emailRegex.test(value));
                    setType(value);
                  }}
                  isInvalid={isErrorType || (submitted && email === "")}
                  regex={emailRegex}
                  submitted={submitted}
                />
              </FormControl>

            </SimpleGrid>

            <SimpleGrid>
            <FormControl isInvalid={isErrorBlood || (submitted && email === "")}>
                <CustomInput
                  type="text"
                  label="Blood"
                  value={blood}
                  onChange={(value) => {
                    setIsErrorBlood(value === '' || !emailRegex.test(value));
                    setBlood(value);
                  }}
                  isInvalid={isErrorBlood || (submitted && email === "")}
                  regex={emailRegex}
                  submitted={submitted}
                />
              </FormControl>

            <FormControl isInvalid={isErrorNumber || (submitted && email === "")}>
                <CustomInput
                  type="number"
                  label="Phone Number"
                  value={number}
                  onChange={(value) => {
                    setIsErrorNumber(value === '' || !emailRegex.test(value));
                    setNumber(value);
                  }}
                  isInvalid={isErrorNumber || (submitted && email === "")}
                  regex={emailRegex}
                  submitted={submitted}
                />
              </FormControl>

            </SimpleGrid>
           
          {/* ... Additional form fields ... */}
          <Center>
            {loading ? (
              <Spinner mt="24px" size="lg" />
            ) : (
              <Button colorScheme="blue" mt="24px" onClick={handleSubmit}>
                Submit
              </Button>
            )}
          </Center>
        </CardHeader>
        <CardBody>
          {/* ... Additional form content ... */}
        </CardBody>
      </Card>
      <Spacer></Spacer>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px" mt="4">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Sos
          </Text>
          <Box boxSize='sm'>
            <Image src={photo} alt='Dan Abramov' />
          </Box>
        </CardHeader>
        <CardBody>
          {/* ... Additional content ... */}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default SosUpdateForm;
