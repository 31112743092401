// Chakra imports
import {
    Box,
    Button,
    Center,
    Flex,
    Input,
    Select,
    SimpleGrid,
    Spacer,
    Spinner,
    Text,
    Wrap,
    WrapItem,
    useColorModeValue
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from 'react-redux';
  import { adminGetAll, adminDelete, corporateAddOrUpdate, adminClearShowSnackbar } from '../../actions/adminActions';
  import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
  } from '@chakra-ui/react'
  import { useToast } from '@chakra-ui/react'
  import { useParams } from "react-router-dom/cjs/react-router-dom.min";
  import { ADMIN_CLEAR_SNACKBAR } from "constants/types";
  import { roleGetAll } from "actions/roleActions";
  import { cityGetAll } from "actions/CityActions";
  import dataConstant from "constants/dataConstant";
  import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
  import Relect from 'react-select';
  import axios from 'axios';
  import { server_url } from "constants/types";
  
  function CorporateUpdateForm() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [contactNo, setContactNo] = useState("");
    const [role, setRole] = useState("");
    const [city, setCity] = useState([]);
    const [secoundarycontactNo, setSecoundarycontactNo] = useState("");
    const [status, setStatus] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [dob, setDob] = useState("");
    const [Line1, setLine1] = useState("");
    const [Line2, setLine2] = useState("");
    const [Postalcode, setPostalcode] = useState("");
    const [Locality, setLocality] = useState("");
    const [State, setState] = useState("");
    const [Age, setAge] = useState("");
    const [Gender, setGender] = useState("");
    const [Maritalstatus, setMaritalstatus] = useState("");
    const [Validation, setValidation] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [isErrorName, setIsErrorName] = useState(false);
    const [isErrorEmail, setIsErrorEmail] = useState(false);
    const [isErrorContactNo, setIsErrorContactNo] = useState(false);
    const [isErrorSecoundarycontactNo, setIsErrorSecoundarycontactNo] = useState(false);
    const [isErrorStartDate, setIsErrorStartDate] = useState(false);
    const [isErrorEndDate, setIsErrorEndDate] = useState(false);
    const [isErrorPassword, setIsErrorPassword] = useState(false);
    const [isErrorRole, setIsErrorRole] = useState(false);
    const [isErrorCity, setIsErrorCity] = useState(false);
    const [isErrorDob, setIsErrorDob] = useState(false);
    const [isErrorStatus, setIsErrorStatus] = useState(false);
    const [isErrorLine1, setIsErrorLine1] = useState(false);
    const [isErrorLine2, setIsErrorLine2] = useState(false);
    const [isErrorPostalcode, setIsErrorPostalcode] = useState(false);
    const [isErrorLocality, setIsErrorLocality] = useState(false);
    const [isErrorState, setIsErrorState] = useState(false);
    const [isErrorAge, setIsErrorAge] = useState(false);
    const [isErrorGender, setIsErrorGender] = useState(false);
    const [isErrorMaritalstatus, setIsErrorMaritalstatus] = useState(false);
    const [isErrorValidation, setIsErrorValidation] = useState(false);
    const [plantList, setPlantList] = useState([]);
    const [plant, setPlant] = useState([]);
    const [isErrorPlant, setIsErrorPlant] = useState("");

    const roleList = useSelector(state => state.role.roleList);
    const cityList = useSelector(state => state.city.cityList);
    const dispatch = useDispatch();
    const toast = useToast();
    const history = useHistory()
    const statuses = ['success', 'error', 'warning', 'info'];
    const responseStatus = useSelector(state => state.admin.status);
    const responseMessage = useSelector(state => state.admin.text);
    const loading = useSelector(state => state.admin.loading);
    const adminDetail = useSelector(state => state.admin.adminDetail);
    const showSnackbar = useSelector(state => state.admin.showSnackbar);
  
    const params = useParams();
  
    console.log("city12121212211");
    console.log(city);
  
    useEffect(() => {
      dispatch(roleGetAll());
      dispatch(cityGetAll());
    }, []);
  
    // Function to handle form submission
    const handleSubmit = (event) => {
      event.preventDefault();
      setSubmitted(true);
      setIsErrorName(name === "");
      setIsErrorEmail(email === "");
      setIsErrorContactNo(contactNo === "");
      setIsErrorStartDate(startDate === "")
      setIsErrorEndDate(endDate === "")
      if (name != "" &&
        name != "" &&
        email != "" &&
        contactNo != "" &&
        status != "" &&
        startDate != "" &&
        endDate != ""
  
      ) {
        // Get form data
        const admin = {
          _id: params.id,
          name: name,
          email: email,
  
          contactNo: contactNo,
          secoundarycontactNo: secoundarycontactNo,
          mappedPlant : plant,
          status: status,
          startDate: startDate,
          endDate: endDate,
          // Other admin data fields
        };
        console.log(admin);
  
        // Determine if it's an add or edit operation
        const state = 'edit';
        // Call the corporateAddOrUpdate action
        dispatch(corporateAddOrUpdate(admin, state));
      } else {
  
      }
    };
  
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === 'OK' && loading === false) {
          console.log("admin-details")
          console.log(adminDetail);
          setName(adminDetail.name);
          setEmail(adminDetail.email);
          setContactNo(adminDetail.contactNo);
          setSecoundarycontactNo(adminDetail.secoundarycontactNo);
          setStatus(adminDetail.status);
          setStartDate(adminDetail.startDate);
          setEndDate(adminDetail.endDate);
          setPlant(adminDetail?.mappedPlant.map((item) => ({
            value: item.value,
            label: item.label
          })));
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'success');
            history.goBack();
          }
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'error');
          }
        }
      }
  
      dispatch({
        type: ADMIN_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        }
      });
    }, [loading, showSnackbar]);
  
  
    useEffect(() => {
      console.log(params);
      const state = 'view';
      // Call the corporateAddOrUpdate action
      const adminId = params.id;
      const admin = {
        _id: adminId
      };
      dispatch(corporateAddOrUpdate(admin, state));
      getPlantAdminLists();
    }, []);
  
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      });
      
    };

    const getPlantAdminLists = () => {
        axios
          .get(`${server_url}/admin/plantAdmin?limit=1000`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          }).then((response) => {
            setPlantList(response.data.data)
          }).catch(() => console.log("error"))
      }
  
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Corporate Update Form
            </Text>
            <br />
            <SimpleGrid minChildWidth='120px' spacing='40px'>
              <FormControl isInvalid={isErrorName}>
                <FormLabel>Name</FormLabel>
                <Input
                  type='text'
                  value={name}
                  onChange={(e) => {
                    setIsErrorName(e.target.value === '');
                    setName(e.target.value);
                  }}
                />
                {!isErrorName ? (
                  <></>
                ) : (
                  <FormErrorMessage>Name is required.</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={isErrorEmail}>
                <FormLabel>Email</FormLabel>
                <Input
                  type='email'
                  value={email}
                  onChange={(e) => {
                    setIsErrorEmail(e.target.value === '');
                    setEmail(e.target.value);
                  }}
                />
                {!isErrorEmail ? (
                  <></>
                ) : (
                  <FormErrorMessage>Email is required.</FormErrorMessage>
                )}
              </FormControl>
            </SimpleGrid>
            <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
              <FormControl isInvalid={isErrorContactNo}>
                <FormLabel>Contact No.</FormLabel>
                <Input
                  type='text'
                  value={contactNo}
                  onChange={(e) => {
                    setIsErrorContactNo(e.target.value === '');
                    setContactNo(e.target.value);
                  }}
                />
                {!isErrorContactNo ? (
                  <></>
                ) : (
                  <FormErrorMessage>Contact No. is required.</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={isErrorSecoundarycontactNo}>
                <FormLabel>Secondary Phone Number</FormLabel>
                <Input
                  type="number"
                  value={secoundarycontactNo}
                  onChange={(e) => {
                    setIsErrorSecoundarycontactNo(e.target.value === "");
                    setSecoundarycontactNo(e.target.value);
                  }}
                />
                {!isErrorSecoundarycontactNo ? (
                  <></>
                ) : (
                  <FormErrorMessage>Secondary Contact No. is required.</FormErrorMessage>
                )}
              </FormControl>
            </SimpleGrid>
            <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
              
  
              <FormControl>
              <FormLabel>Plant</FormLabel>
              <Relect
                isMulti
                name="colors"
                placeholder="--select plant--"
                options={plantList.map((item) => ({
                  value: item._id,
                  label: item.plant[0]?.plantName
                }))}
                value={plant}
                onChange={(value) => {
                  setPlant(value)
                  setIsErrorPlant(value === '')
                  console.log(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />

              {isErrorPlant && submitted && (
                <FormErrorMessage>Module is required.</FormErrorMessage>
              )}
            </FormControl>
  
  
  
              <FormControl isInvalid={isErrorStatus}>
                <FormLabel>Status</FormLabel>
                <Select
                  placeholder="Select Status"
                  value={status}
                  onChange={(e) => {
                    setIsErrorStatus(e.target.value === '')
                    setStatus(e.target.value)
                  }}
                >
                  {dataConstant.status.map((element, index) => {
                    return (<option key={element['id']} value={element['value']} >{element['name']}</option>);
  
                  })}
                </Select>
                {!isErrorStatus ? (
                  <></>
                ) : (
                  <FormErrorMessage>Status is required.</FormErrorMessage>
                )}
              </FormControl>
            </SimpleGrid>
  
  
  
            <SimpleGrid minChildWidth="120px" spacing="40px" mt="24px">
  
              <FormControl isInvalid={isErrorContactNo}>
                <FormLabel>Start Date</FormLabel>
                <Input
                  type="date"
                  value={startDate}
                  onChange={(e) => {
                    setIsErrorStartDate(e.target.value === "");
                    setStartDate(e.target.value);
                  }}
                />
                {!isErrorContactNo ? (
                  <></>
                ) : (
                  <FormErrorMessage>Start date is required.</FormErrorMessage>
                )}
              </FormControl>
  
              <FormControl isInvalid={isErrorSecoundarycontactNo}>
                <FormLabel>End Date</FormLabel>
                <Input
                  type="date"
                  value={endDate}
                  onChange={(e) => {
                    setIsErrorEndDate(e.target.value === "");
                    setEndDate(e.target.value);
                  }}
                />
                {!isErrorSecoundarycontactNo ? (
                  <></>
                ) : (
                  <FormErrorMessage>End date is required.</FormErrorMessage>
                )}
              </FormControl>
            </SimpleGrid>
  
  
  
  
            <Center>
              {loading ? (
                <Spinner size='lg' mt='24px' />
              ) : (
                <Button
                  colorScheme='blue'
                  mt='24px'
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              )}
            </Center>
          </CardHeader>
          <CardBody>
          </CardBody>
        </Card>
        {/* <Card
          my="22px"
          overflowX={{ sm: "scroll", xl: "hidden" }}
          pb="0px"
        >
        
        </Card> */}
      </Flex>
    );
  }
  
  export default CorporateUpdateForm;
  