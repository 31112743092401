const {
    GUARD_LOADING,
    GUARD_GET_ALL_SUCCESS,
    GUARD_ERROR,
    GUARD_CLEAR_SNACKBAR,
    GUARD_SEARCH
  } = require('../constants/types');
  
  const initialState = {
    response: {},
    guardList: [],
    guardAttendenceHistoryList : [],
    guardDetail: {},
    loading: false,
    status: 'success',
    text: '',
    showSnackbar: false,
    limit: 0,  // Add missing properties
    page: 0,
    totalPages: 0,
    searching: ""  // Add missing properties
  };
  
  const guardReducer = (state = initialState, action) => {
    switch (action.type) {
      case GUARD_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case GUARD_LOADING:
        return {
          ...state,
          loading: true
        };
      case GUARD_ERROR:
        return {
          ...state,
          guardList: [],
          guardAttendenceHistoryList : [],
          response: {},
          guardDetail: {},
          loading: false,
          limit: 0,  // Add missing properties
          page: 0,
          totalPages: 0,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case GUARD_GET_ALL_SUCCESS:
        return {
          ...state,
          guardList: action.payload.guardList,
          guardAttendenceHistoryList : action.payload.guardAttendenceHistoryList,
          response: action.payload.response,
          guardDetail: action.payload.guardDetail,
          loading: false,
          limit: action.payload.limit,
          page: action.payload.page,
          totalPages: action.payload.totalPages,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
        case GUARD_SEARCH: // Add the new case
        return {
          ...state,
          searching: action.payload.searching
        };
      default:
        return state || {};
    }
  };
  
  // module.exports = guardReducer;
  export default guardReducer;
  