// Chakra imports
import {
    Flex,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Td,
    Th,
    TableContainer,
    Image,
    useColorModeValue,
    Stack
  } from "@chakra-ui/react";
  import { CheckIcon, AddIcon, WarningIcon,CloseIcon } from '@chakra-ui/icons'
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { patrollingLogsClearShowSnackbar, patrollingLogsAddOrUpdate } from '../../../actions/patrollingLogsAction';
  import { useToast } from '@chakra-ui/react'
  import { useParams } from "react-router-dom/cjs/react-router-dom.min";
  import { PATROLLING_LOGS_CLEAR_SNACKBAR } from "constants/types";
  import {
    Skeleton,
    SkeletonCircle,
    SkeletonText,
  } from "@chakra-ui/react";
  import { locale } from "moment/moment";
  // import { patrollingLogsClearShowSnackbar } from "actions/PatrollingLogsAction";
  import axios from 'axios';
  import { server_url } from "constants/types";
  import { useHistory,useLocation } from 'react-router-dom';

  function PatrollingByFloorView() {
  
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [guardName, setGuardName] = useState('');
    const [startDateTime, setStartDateTime] = useState('');
    const [endDateTime, setEndDateTime] = useState('');
    const [routeName, setRouteName] = useState('');
    const [noOfScan, setNoOfScan] = useState(''); // Assuming it's a number
    const [scanLog,setScanLog ] = useState(''); // Assuming it's an array
    const [missedScanLog, setMissedScanLog] = useState('');
    const [locationName, setLocationName] = useState('');
    const [scanDateTime, setScanDateTime] = useState('');
    const [patrollingCheckList, setPatrollingCheckList] = useState([]);
    const [image, setImage] = useState("");
  
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const toast = useToast();
  
    const statuses = ['success', 'error', 'warning', 'info'];
    const responseStatus = useSelector(state => state.patrollingLogs.status);
    const responseMessage = useSelector(state => state.patrollingLogs.text);
    const loading = useSelector(state => state.patrollingLogs.loading);
    // const designationDetail = useSelector(state => state.patrollingLogs.designationDetail);
    const patrollingLogsDetail = useSelector((state) => state.patrollingLogs.patrollingLogsDetail);
    const showSnackbar = useSelector(state => state.patrollingLogs.showSnackbar);
    const patrollingLogs = useSelector(state => state.patrollingLogs.patrollingLogs);
    const jj = useSelector((state) =>state.patrollingLogs )
  
    
    const params = useParams();
  console.log("patrollingLogsDetail123")
  console.log(patrollingLogsDetail)
  const query = new URLSearchParams(location.search);
    const floorName = query.get('floorName');
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === 'OK' && loading === false) {
       
         setGuardName(patrollingLogsDetail.guard ? patrollingLogsDetail.guard.name : "")
         setStartDateTime(patrollingLogsDetail.startDateTime)
         setEndDateTime(patrollingLogsDetail.endDateTime)
         setRouteName(patrollingLogsDetail.route ? patrollingLogsDetail.route.name : "")
         setNoOfScan(patrollingLogsDetail.noOfScan)
         setScanLog(patrollingLogsDetail.scanLog)
         setMissedScanLog(patrollingLogsDetail.missedScanLog)
         setLocationName(patrollingLogsDetail.matchData[0]?.floorName)
         setScanDateTime(patrollingLogsDetail.matchData[0]?.dateTime)
         setPatrollingCheckList(patrollingLogsDetail.matchData[0]?.checkList)
         setImage(patrollingLogsDetail.matchData[0]?.image)
          
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'success');
          }
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'error');
          }
        }
        dispatch({
          type: PATROLLING_LOGS_CLEAR_SNACKBAR,
          payload: {
            showSnackbar: false,
          }
        });
      }
    }, [loading]);
  
    useEffect(() => {
      const patrollingLogsId = params.id;
      const patrollingLogs = {
        _id: patrollingLogsId,
      };
      dispatch(patrollingLogsAddOrUpdate(patrollingLogs, 'view','','',floorName));
    }, []);
    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
    }, [])
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      });
    };
  
  
    return (
      <><Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="20px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Checked Logs View
          </Text>
        </CardHeader>
        <CardBody>
          {loading ? (
            <Skeleton height="20px" />
          ) : (
            <TableContainer>
              <Table variant="simple">
                <Tbody>
                  <Tr>
                    <Td>Guard Name</Td>
                    <Td>{guardName}</Td>
                  </Tr>
                  <Tr>
                    <Td>Location </Td>
                    <Td>{routeName}</Td>
                  </Tr>
                  <Tr>
                    <Td>Sub Location </Td>
                    <Td>{locationName}</Td>
                  </Tr>
                  <Tr>
                    <Td>Start-Date-Time</Td>
                    <Td>{scanDateTime}</Td>
                  </Tr>
                  
                  
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </CardBody>
      </Card>
    </Flex>
    {/* logs */}
    <Flex direction="column" pt={{ base: "120px", md: "10px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="10px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
            CheckList
            </Text>
          </CardHeader>
          <CardBody>
         {loading ? (
            <Stack>
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
            </Stack>
          ) :  (
            <TableContainer >
                <Table variant='simple'>
                  <Thead>
                    <Tr>
                      <Th color="white">Status</Th>
                      <Th color="white">Title</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                   
                    {
                      patrollingCheckList.map((row, index, arr) => {

                        return (
                         
                           <Tr>
                            <Td>{row.checkListStatus ? <CheckIcon color='green' /> : <CloseIcon color="red" />}</Td>
                            <Td>{row.name}</Td>
            
                          </Tr>
                        );
                      })}
                      
                  
                  
                    
                  
                  </Tbody>
                </Table>
                </TableContainer>
          )}
        </CardBody>
        </Card>
      </Flex>
      <Flex direction="column" pt={{ base: "120px", md: "10px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="10px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
            Photo
            </Text>
          </CardHeader>
          <CardBody>
         {loading ? (
            <Stack>
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
            </Stack>
          ) :  (
            <Image
                        // boxSize='70px'
                        objectFit='cover'
                        src={image}
                        alt='Dan Abramov'
                        cursor = 'pointer'
                  />
          )}
        </CardBody>
        </Card>
      </Flex>
    </>
    )};
  
    export default PatrollingByFloorView;
    