// Chakra imports
import {
    Box,
    Button,
    Flex,
    Grid,
    Progress,
    SimpleGrid,
    Stat,
    StatLabel,
    StatNumber,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorMode,
    useColorModeValue,
    Link,
    Spacer,
    InputGroup,
    InputLeftElement,
    IconButton,
    TableContainer,
    TableCaption,
    Tfoot,
    Stack,
    StackDivider,
    GridItem,
    Select,
    Divider,
    UnorderedList,
    ListItem,
    HStack,
    Wrap,
    WrapItem,
    Avatar
  } from "@chakra-ui/react";
  // Custom components
  import { FaRunning } from "react-icons/fa";
  import { MdOutlineProductionQuantityLimits } from "react-icons/md";
  import { GrUserWorker } from "react-icons/gr";
  import { IoFastFoodSharp } from "react-icons/io5";
  import { TbTruckDelivery } from "react-icons/tb";
  import { FaArrowsDownToPeople } from "react-icons/fa6";
  import { TbSos } from "react-icons/tb";
  import { GiLifeBar } from "react-icons/gi";
  import { FaBuildingUser } from "react-icons/fa6";
  import Card from "components/Card/Card.js";
  import { FaIdCard } from "react-icons/fa6";
  import { MdDevices } from "react-icons/md";
  import { FaHouseChimneyUser } from "react-icons/fa6";
  import { SiGooglemybusiness } from "react-icons/si";
  import { FaPeopleRoof } from "react-icons/fa6";
  import { VscUngroupByRefType } from "react-icons/vsc";
  import { MdDriveFileRenameOutline } from "react-icons/md";
  import { GiUndergroundCave } from "react-icons/gi";
  import { GiMineTruck } from "react-icons/gi";
  import BarChart from "components/Charts/BarChart";
  import LineChart from "components/Charts/LineChart";
  import IconBox from "components/Icons/IconBox";
  // Custom icons
  import {
    CartIcon,
    DocumentIcon,
    GlobeIcon,
    WalletIcon,
  } from "components/Icons/Icons.js";
  import { MdCheckCircle,MdCancelScheduleSend   } from "react-icons/md";
  import { LiaIndustrySolid } from "react-icons/lia";
  import { FcDepartment } from "react-icons/fc";
  import { BsBarChartSteps } from "react-icons/bs";
  
  import React, { useRef } from "react";
  // Variables
  import {
    barChartData,
    barChartOptions,
    lineChartData,
    lineChartOptions,
  } from "variables/charts";
  import { pageVisits, socialTraffic } from "variables/general";
  import { useHistory } from 'react-router-dom';
  // import { useEffect } from "react";
  import { useEffect, useState } from 'react';
  import axios from 'axios';
  import { server_url } from "constants/types";
  import { useSelector } from "react-redux";
  import { IoMdPeople } from 'react-icons/io';
  import { GiEntryDoor, GiExitDoor } from 'react-icons/gi';
  import { CiDeliveryTruck } from 'react-icons/ci';
  import { IoMdCafe } from 'react-icons/io';
  import CardHeader from "components/Card/CardHeader";
  import CardBody from "components/Card/CardBody";
  import { Value } from "sass";
  import { useToast } from '@chakra-ui/react'
  import { pieChartData, pieChartOptions } from "variables/charts";
  import PieChart from "../../components/Charts/PieChart";
  import jsPDF from 'jspdf';
  import html2canvas from 'html2canvas';
  import pastIcon from "assets/img/Past.png";
  import ApprovedIcon from "assets/img/Pre Approved.png";
  import CurrentIcon from "assets/img/Current.png";
  import WrongEntryIcon from "assets/img/Wrong entry.png";
  import DeniedIcon from "assets/img/deny.png";
  export default function Dashboard(props) {
    const { ...rest } = props;

    // const textColor = useColorModeValue("secondaryGray.900", "white");
    const cardColor = useColorModeValue("white", "navy.700");
    const cardShadow = useColorModeValue(
        "0px 18px 40px rgba(112, 144, 176, 0.12)",
        "unset"
    );
    const toast = useToast();
    // Chakra Color Mode
    const iconBlue = useColorModeValue("blue.500", "blue.500");
    const iconBoxInside = useColorModeValue("white", "white");
    const textColor = useColorModeValue("gray.700", "white");
    const tableRowColor = useColorModeValue("#F7FAFC", "navy.900");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const textTableColor = useColorModeValue("gray.500", "white");
    const [devicesCount, setDevicesCount] = useState(0);
    const [countPlantAdmin, setCountPlantAdmin] = useState(0);
    const [countCityAdmin, setCountCityAdmin] = useState(0);
    const [role, setRole] = useState("")
    
    const [totalPatroll, setTotalPatroll] = useState(0);
    const [totalCheckpoints, setTotalCheckpoints] = useState(0);
    const [missed, setMissed] = useState(0);
    const [checked, setChecked] = useState(0);
    const [totalReportIncident, setTotalReportIncident] = useState(0);
    const [mostMissedCheckpoints, setMostMissedCheckpoints] = useState([]);
    const [mostMissedChecklist, setMostMissedChecklist] = useState([]);
    const [averageVisit, setAverageVisit] = useState(0);
    const [mostVisitedEmployeeFilter, setMostVisitedEmployeeFilter] = useState([]);
    const [averageVistXaxis, setAverageVisitXaxis] = useState({});
    const [averageVistYaxis, setAverageVisitYaxis] = useState([]);
    const [averageVisitLoader, setAverageVisitLoader] = useState(true);
    const [recurringVisit, setRecurringVisit] = useState(0);
    const [oneTimeVisit, setOneTimeVisit] = useState(0);
    const [recOneVisit, setRecOneVisit] = useState([0,0]);
    const [returningLoader, setReturningLoader] = useState(true);
    const [mostCheckedCheckpoints, setMostCheckedCheckpoints] = useState([]);
    const [unscannedLastThreeDays, setUnscannedLastThreeDays] = useState([]);
    const [peakHourXaxis, setPeakHourXaxis] = useState([]);
    const [peakHourYaxis, setPeakHourYaxis] = useState([]);
    const [peakHourLoader, setPeakHourLoader] = useState(true);
    const [peakHourDateRange, setPeakHourDateRange] = useState([]);
    const [averageTurnarroundTime, setAverageTurnarroundTime] = useState([]);
    const [longStayPercentage, setLongStayPercentage] = useState('0');
    const [peakDate, setPeakDate] = useState('');
    const [peakInterval, setPeakInterval] = useState('');
    const [peakVisitCount, setPeakVisitCount] = useState('');
    const [peakHourPeriod, setPeakHourPeriod] = useState('');
    const [submittingLoader, setSubmittingLoader] = useState(false);
    const [guardWithMostPatrol, setGuardWithMostPatrol] = useState([]);
    const [topGuardHavingMostReportIncident, setTopGuardHavingMostReportIncident] = useState([]);
    const { colorMode } = useColorMode();
    const history = useHistory();
    const authDetail = useSelector(state => state.auth.authDetail);
    const [cardWidth, setCardWidth] = useState('100px');
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    const [boxWidth, setBoxWidth] = useState({
        left: "60%",
        right: "40%",
      });

      useEffect(() => {
        // const handleResize = () => {
        //   setDimensions({
        //     width: window.innerWidth,
        //     height: window.innerHeight,
        //   });
        //   if(window.innerWidth >= 1340 && window.innerWidth <= 1550)
        //     {
        //         setCardWidth('120px');
        //         setBoxWidth({
        //             left: "50%",
        //             right: "50%",
        //           });
        //     }
        //     else if(window.innerWidth >= 2000)
        //         {
        //             setCardWidth('100px');
        //             setBoxWidth({
        //                 left: "70%",
        //                 right: "30%",
        //             });
        //         }
        //     else
        //     {
        //         setCardWidth('100px');
        //         setBoxWidth({
        //             left: "60%",
        //             right: "40%",
        //           });
        //     }
        // };
        
        // window.addEventListener('resize', handleResize);
    
        // return () => {
        //   window.removeEventListener('resize', handleResize);
        // };
        handleResize()
      }, [dimensions]);
      const handleResize = () => {
        setDimensions({
          width: window.innerWidth,
          height: window.innerHeight,
        });
        if(window.innerWidth >= 1340 && window.innerWidth <= 1550)
          {
              setCardWidth('120px');
              setBoxWidth({
                  left: "50%",
                  right: "50%",
                });
          }
          else if(window.innerWidth >= 2000)
              {
                  setCardWidth('100px');
                  setBoxWidth({
                      left: "70%",
                      right: "30%",
                  });
              }
          else
          {
              setCardWidth('100px');
              setBoxWidth({
                  left: "60%",
                  right: "40%",
                });
          }
      };
    console.log(devicesCount);
  
    useEffect(() => {
      var authKey = localStorage.getItem("authToken");
      setRole(authDetail.role?.name);
      if (authKey == null) {
        history.push('/auth/signin');
      }
    }, [history]);
    
    const newPieChartOptions = {
        labels: ["Checked", "Missed"],
        colors: ["#4318FF", "#6AD2FF"],
        chart: {
          width: "50px",
        },
        states: {
          hover: {
            filter: {
              type: "none",
            },
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        hover: { mode: null },
        plotOptions: {
          donut: {
            expandOnClick: false,
            donut: {
              labels: {
                show: true,
              },
            },
          },
        },
        fill: {
          colors: ["#4318FF", "#6AD2FF", "#EFF4FB"],
        },
        tooltip: {
          enabled: true,
          theme: "dark",
        },
      };
  
    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
        getVisitorTabCount();
        getMostMissedCheckpoints('Monthly');
        getMostMissedChecklist('Monthly');
        getAveragePatroll('Monthly');
    //     setAverageVisitXaxis({
    //         chart: {
    //           toolbar: {
    //             show: false,
    //           },
    //         },
    //         tooltip: {
    //           theme: "dark",
    //         },
    //         xaxis: {
    //           categories: [],
    //           labels: {
    //             style: {
    //               colors: "#A0AEC0",
    //               fontSize: "12px",
    //             },
    //           },
    //           show: true,
    //           axisBorder: {
    //             show: false,
    //           },
              
    //         },
    //         yaxis: {
    //           show: true,
    //           color: "#A0AEC0",
    //           labels: {
    //             show: true,
    //             style: {
    //               colors: "#A0AEC0",
    //               fontSize: "14px",
    //             },
    //           },
    //           tickAmount: 5, // Set the desired number of ticks on the y-axis
    // min: 0, // Optional: Set the minimum value of the y-axis
    // max: 100,
    //         },
    //         fill: {
    //           colors: "#ED8936",
    //         },
    //         dataLabels: {
    //           enabled: false,
    //         },
    //         grid: {
    //           strokeDashArray: 5,
    //         },
    //         plotOptions: {
    //           bar: {
    //             borderRadius: 15,
    //             columnWidth: "15px",
    //           },
    //         },
    //         responsive: [
    //           {
    //             breakpoint: 768,
    //             options: {
    //               plotOptions: {
    //                 bar: {
    //                   borderRadius: 0,
    //                 },
    //               },
    //             },
    //           },
    //         ],
    //       });
    //       setAverageVisitYaxis([
    //         {
    //           name: "",
    //           data: [],
    //         },
    //       ]);
          getCheckedMissedCheckpoints('Monthly');
          getMostCheckedCheckpoints('Monthly');
          getUnscannedCheckPointsLast3Days('Monthly');
    //       setPeakHourXaxis({
    //         chart: {
    //           toolbar: {
    //             show: false,
    //           },
    //         },
    //         tooltip: {
    //           theme: "dark",
    //         },
    //         xaxis: {
    //           categories: [],
    //           labels: {
    //             style: {
    //               colors: "#A0AEC0",
    //               fontSize: "12px",
    //             },
    //           },
    //           show: true,
    //           axisBorder: {
    //             show: false,
    //           },
              
    //         },
    //         yaxis: {
    //           show: true,
    //           color: "#A0AEC0",
    //           labels: {
    //             show: true,
    //             style: {
    //               colors: "#A0AEC0",
    //               fontSize: "14px",
    //             },
    //           },
    //         },
    //         fill: {
    //           colors: "#ED8936",
    //         },
    //         dataLabels: {
    //           enabled: false,
    //         },
    //         grid: {
    //           strokeDashArray: 5,
    //         },
    //         plotOptions: {
    //           bar: {
    //             borderRadius: 15,
    //             columnWidth: "15px",
    //           },
    //         },
    //         responsive: [
    //           {
    //             breakpoint: 768,
    //             options: {
    //               plotOptions: {
    //                 bar: {
    //                   borderRadius: 0,
    //                 },
    //               },
    //             },
    //           },
    //         ],
    //       });
    //       setPeakHourYaxis([
    //         {
    //           name: "",
    //           data: [],
    //         },
    //       ]);
          getPeakHour("");
          getAverageTurnArroundTime("Monthly");
    //       getLongstayPercentage("Monthly");
          getPeakHourPeriodDetails("Monthly");
    //       setPeakHourPeriod("Monthly");
    getGuardWithMostPatrol("Monthly");
    getTopGuardHavingMostIncidentReport("Monthly");
    }, [])
  
    const getVisitorTabCount = () => {
        axios
          .get(`${server_url}/patrolling/tab-count`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          }).then((response) => {
            if(response.data.status == 'OK')
                {
                    var data = response.data.data;
                    setTotalPatroll(data['totalPatrolling']);
                    setTotalCheckpoints(data['totalCheckpoints']);
                    setChecked(data['checkedCount']);
                    setMissed(data['missedCount']);
                    setTotalReportIncident(data['reportIncident']);
                }
            
          }).catch(() => console.log("error"))
      }

      const getMostMissedCheckpoints = (data) => {
        let period;
        switch (data) {
            case 'Daily':
                period = 'today';
                break;
            case 'Weekly':
                period = 'lastWeek'
                break;
            case 'Monthly':
                period = 'lastMonth'
                break;
            case 'Yearly':
                period = 'lastYear'
                break;
        
            default:
                break;
        }
        axios
          .get(`${server_url}/patrolling/most-missed-checkpoints?period=${period}`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          }).then((response) => {
            if(response.data.status == 'OK')
                {
                    var data = response.data.data;
                    setMostMissedCheckpoints(data);
                }
            
          }).catch(() => console.log("error"))
      }
      const getMostMissedChecklist = (data) => {
        let period;
        switch (data) {
            case 'Daily':
                period = 'today';
                break;
            case 'Weekly':
                period = 'lastWeek'
                break;
            case 'Monthly':
                period = 'lastMonth'
                break;
            case 'Yearly':
                period = 'lastYear'
                break;
        
            default:
                break;
        }
        axios
          .get(`${server_url}/patrolling/most-missed-checklist?period=${period}`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          }).then((response) => {
            if(response.data.status == 'OK')
                {
                    var data = response.data.data;
                    setMostMissedChecklist(data);
                }
            
          }).catch(() => console.log("error"))
      }
      const getGuardWithMostPatrol = (data) => {
        let period;
        switch (data) {
            case 'Daily':
                period = 'today';
                break;
            case 'Weekly':
                period = 'lastWeek'
                break;
            case 'Monthly':
                period = 'lastMonth'
                break;
            case 'Yearly':
                period = 'lastYear'
                break;
        
            default:
                break;
        }
        axios
          .get(`${server_url}/patrolling/most-guard-scan-checkpoints?period=${period}`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          }).then((response) => {
            if(response.data.status == 'OK')
                {
                    var data = response.data.data;
                    setGuardWithMostPatrol(data);
                }
            
          }).catch(() => console.log("error"))
      }
      const getTopGuardHavingMostIncidentReport = (data) => {
        let period;
        switch (data) {
            case 'Daily':
                period = 'today';
                break;
            case 'Weekly':
                period = 'lastWeek'
                break;
            case 'Monthly':
                period = 'lastMonth'
                break;
            case 'Yearly':
                period = 'lastYear'
                break;
        
            default:
                break;
        }
        axios
          .get(`${server_url}/patrolling/top-guard-submit-most-report-incident?period=${period}`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          }).then((response) => {
            if(response.data.status == 'OK')
                {
                    var data = response.data.data;
                    setTopGuardHavingMostReportIncident(data);
                }
            
          }).catch(() => console.log("error"))
      }

      const getAveragePatroll = (data) => {
        setAverageVisitLoader(true)
        let period;
        switch (data) {
            case 'Daily':
                period = 'today';
                break;
            case 'Weekly':
                period = 'lastWeek'
                break;
            case 'Monthly':
                period = 'lastMonth'
                break;
            case 'Yearly':
                period = 'lastYear'
                break;
        
            default:
                break;
        }
        axios
          .get(`${server_url}/patrolling/average-patrolling?period=${period}`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          }).then((response) => {
            if(response.data.status == 'OK')
                {
                    var response = response.data;
                    setAverageVisit(response.average);
                    setAverageVisitXaxis({
                        chart: {
                          toolbar: {
                            show: false,
                          },
                        },
                        tooltip: {
                          theme: "dark",
                        },
                        xaxis: {
                          categories: response.xAxis,
                          labels: {
                            style: {
                              colors: "#A0AEC0",
                              fontSize: "12px",
                            },
                          },
                          show: true,
                          axisBorder: {
                            show: false,
                          },
                          
                        },
                        yaxis: {
                          show: true,
                          color: "#A0AEC0",
                          labels: {
                            show: true,
                            style: {
                              colors: "#A0AEC0",
                              fontSize: "14px",
                            },
                            formatter: (value) => {
                              return Math.floor(value); // Ensure the y-axis labels are whole numbers
                            }
                          },
                          tickAmount: 5, // Set the desired number of ticks on the y-axis
                          min: 0, // Optional: Set the minimum value of the y-axis'
                          max : response.maxRange,
                          forceNiceScale: true,
                        },
                        fill: {
                          colors: "#3e83ce",
                        },
                        dataLabels: {
                          enabled: true,
                        },
                        grid: {
                          strokeDashArray: 5,
                        },
                        plotOptions: {
                          bar: {
                            borderRadius: 15,
                            columnWidth: "15px",
                          },
                        },
                        responsive: [
                          {
                            breakpoint: 768,
                            options: {
                              plotOptions: {
                                bar: {
                                  borderRadius: 0,
                                },
                              },
                            },
                          },
                        ],
                      });
                      setAverageVisitYaxis([
                        {
                          name: "Patroll",
                          data: response.yAxis,
                        },
                      ]);
                }
                setAverageVisitLoader(false)
            console.log(response.yAxis);
            console.log("response.yAxis");
          }).catch(() => console.log("error"))
      }

      const getCheckedMissedCheckpoints = (data) => {
        setReturningLoader(true);
        let period;
        switch (data) {
            case 'Daily':
                period = 'today';
                break;
            case 'Weekly':
                period = 'lastWeek'
                break;
            case 'Monthly':
                period = 'lastMonth'
                break;
            case 'Yearly':
                period = 'lastYear'
                break;
        
            default:
                break;
        }
        axios
          .get(`${server_url}/patrolling/checked-missed?period=${period}`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          }).then((response) => {
            if(response.data.status == 'OK')
                {
                    var data = response.data.data;
                    setRecurringVisit(data.checkedCount);
                    setOneTimeVisit(data.totalMissed);
                    var array = [
                      data.checkedCount,
                      data.totalMissed
                    ];
                      setRecOneVisit(array);
                      console.log(array);
                      console.log("setRecOneVisit");
                      console.log(returningLoader);
                }
                setReturningLoader(false)
            
          }).catch(() => console.log("error"))
      }

      const getMostCheckedCheckpoints = (data) => {
        let period;
        switch (data) {
            case 'Daily':
                period = 'today';
                break;
            case 'Weekly':
                period = 'lastWeek'
                break;
            case 'Monthly':
                period = 'lastMonth'
                break;
            case 'Yearly':
                period = 'lastYear'
                break;
        
            default:
                break;
        }
        axios
          .get(`${server_url}/patrolling/most-checked-checkpoints?period=${period}`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          }).then((response) => {
            if(response.data.status == 'OK')
                {
                    var data = response.data.data;
                    setMostCheckedCheckpoints(data);
                    
                }
            
          }).catch(() => console.log("error"))
      }

      const getUnscannedCheckPointsLast3Days = (data) => {
        let period;
        switch (data) {
            case 'Daily':
                period = 'today';
                break;
            case 'Weekly':
                period = 'lastWeek'
                break;
            case 'Monthly':
                period = 'lastMonth'
                break;
            case 'Yearly':
                period = 'lastYear'
                break;
        
            default:
                break;
        }
        axios
          .get(`${server_url}/patrolling/missed-checkpoints-from-last-3days?period=${period}`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          }).then((response) => {
            if(response.data.status == 'OK')
                {
                    var data = response.data.data;
                    setUnscannedLastThreeDays(data);
                    
                }
            
          }).catch(() => console.log("error"))
      }

      const getPeakHour = (data) => {
        setPeakHourLoader(true)
        let period = data;
        
        axios
          .get(`${server_url}/patrolling/peak-hour?period=${period}`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          }).then((response) => {
            if(response.data.status == 'OK')
                {
                    var response = response.data;
                    setPeakHourXaxis({
                        chart: {
                          toolbar: {
                            show: false,
                          },
                        },
                        tooltip: {
                          theme: "dark",
                        },
                        xaxis: {
                          categories: response.xAxis,
                          labels: {
                            style: {
                              colors: "#A0AEC0",
                              fontSize: "12px",
                            },
                          },
                          show: true,
                          axisBorder: {
                            show: false,
                          },
                          
                        },
                        yaxis: {
                          show: true,
                          color: "#A0AEC0",
                          labels: {
                            show: true,
                            style: {
                              colors: "#A0AEC0",
                              fontSize: "14px",
                            },
                          },
                          tickAmount: 5, // Set the desired number of ticks on the y-axis
                          min: 0,
                          max : response.maxRange,
                        },
                        fill: {
                          colors: "#3e83ce",
                        },
                        dataLabels: {
                          enabled: true,
                        },
                        grid: {
                          strokeDashArray: 5,
                        },
                        plotOptions: {
                          bar: {
                            borderRadius: 15,
                            columnWidth: "15px",
                          },
                        },
                        responsive: [
                          {
                            breakpoint: 768,
                            options: {
                              plotOptions: {
                                bar: {
                                  borderRadius: 0,
                                },
                              },
                            },
                          },
                        ],
                      });
                      setPeakHourYaxis([
                        {
                          name: "Patrol",
                          data: response.yAxis,
                        },
                      ]);
                      setPeakHourDateRange(response.dateRange);
                }
                setPeakHourLoader(false)
            
          }).catch(() => console.log("error"))
      }
      
      const getAverageTurnArroundTime = (data) => {
        let period;
        switch (data) {
            case 'Daily':
                period = 'today';
                break;
            case 'Weekly':
                period = 'lastWeek'
                break;
            case 'Monthly':
                period = 'lastMonth'
                break;
            case 'Yearly':
                period = 'lastYear'
                break;
        
            default:
                break;
        }
        axios
          .get(`${server_url}/patrolling/average-patrol-time?period=${period}`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          }).then((response) => {
            if(response.data.status == 'OK')
                {
                    var data = response.data.data;
                    setAverageTurnarroundTime(`${data.averageTurnaroundTime.hours} hours ${data.averageTurnaroundTime.minutes} minutes`);
                }
            console.log(response.yAxis);
            console.log("response.yAxis");
          }).catch(() => console.log("error"))
      }
    //   const getLongstayPercentage = (data) => {
    //     let period;
    //     switch (data) {
    //         case 'Daily':
    //             period = 'today';
    //             break;
    //         case 'Weekly':
    //             period = 'lastWeek'
    //             break;
    //         case 'Monthly':
    //             period = 'lastMonth'
    //             break;
    //         case 'Yearly':
    //             period = 'lastYear'
    //             break;
        
    //         default:
    //             break;
    //     }
    //     axios
    //       .get(`${server_url}/visitor/longstay-percentage?period=${period}`, {
    //         headers: {
    //           'Authorization': 'Bearer ' + localStorage.getItem('authToken')
    //         }
    //       }).then((response) => {
    //         if(response.data.status == 'OK')
    //             {
    //                 var data = response.data.data;
    //                 setLongStayPercentage(`${data}%`);
    //             }
    //       }).catch(() => console.log("error"))
    //   }

      const getPeakHourPeriodDetails = (data) => {
        let period;
        switch (data) {
            case 'Daily':
                period = 'today';
                break;
            case 'Weekly':
                period = 'lastWeek'
                break;
            case 'Monthly':
                period = 'lastMonth'
                break;
            case 'Yearly':
                period = 'lastYear'
                break;
        
            default:
                break;
        }
        axios
          .get(`${server_url}/patrolling/peak-hour-V2?period=${period}`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          }).then((response) => {
            if(response.data.status == 'OK')
                {
                    var response = response.data;
                    setPeakDate(`${response.peak.date}`);
                    setPeakInterval(`${response.peak.interval}`);
                    setPeakVisitCount(`${response.peak.visitCount}`);
                }
          }).catch(() => console.log("error"))
      }

      const printRef = useRef();
      const now = new Date();
      const formattedDateTime = now.toLocaleString();
      const handleDownloadPdf = async () => {
        setSubmittingLoader(true);
        const element = printRef.current;
        // Wait for all images to load
      const images = element.querySelectorAll('img');
      const promises = Array.from(images).map(img => {
        if (!img.complete) {
          return new Promise((resolve) => {
            img.onload = resolve;
            img.onerror = resolve;
          });
        }
      });

      await Promise.all(promises);
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');

        const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'pt',
          format: 'a4',
        });

        const imgProps = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.text(`${authDetail.plant[0]?.plantName} - Patrolling statistics - ${formattedDateTime}`, 20, pdfHeight + 20);
        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('patrol statistics.pdf');
        setSubmittingLoader(false);
      };
  
      return (
        <>
        <Flex pt={{ base: "120px", md: "75px" }}>
          <Box >
            
          </Box>
          <Spacer />
          <Box >
          <Button
          isLoading={submittingLoader}
          loadingText='Downloading'
          onClick={handleDownloadPdf} colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>
                  Download Patrolling Statistics PDF
                </Button>
          </Box>
          <Spacer />
          <Box >
            
          </Box>
        </Flex>
          <Flex flexDirection='column' pt={{ base: "120px", md: "5px" }} ref={printRef}>
            <SimpleGrid columns={{ sm: 1, md: 2, xl: 5 }} spacing='30px' mb='20px'>
              
            
              <Card style={{
                    maxHeight : cardWidth
                  }} >
                <Flex direction='column'>
                  <Flex
                  
                    flexDirection='row'
                    align='center'
                    justify='center'
                    w='100%'
                    mb='25px'>
                    <Stat me='auto'>
                      <StatLabel
                        fontSize='xs'
                        color='gray.400'
                        fontWeight='bold'
                        textTransform='uppercase'>
                        Total Patrol <br></br> Conducted
                      </StatLabel>
                      <Text color={textColor} fontSize='2xl' fontWeight='bold'>
                      {totalPatroll}
                    
                    </Text>
                      {/* <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                        {pastTotal}
                      </StatNumber> */}
  
                    </Stat>
                    <Avatar name='Dan Abrahmov' src={pastIcon} bg="white"/>
                  </Flex>
                  
                </Flex>
              </Card>
              <Card style={{
                    maxHeight : cardWidth
                  }} >
                <Flex direction='column'>
                  <Flex
                    flexDirection='row'
                    align='center'
                    justify='center'
                    w='100%'
                    mb='25px'>
                    <Stat me='auto'>
                      <StatLabel
                        fontSize='xs'
                        color='gray.400'
                        fontWeight='bold'
                        textTransform='uppercase'>
                        QR Code Locations In premises
                      </StatLabel>
  
                      
                      <Text color={textColor} fontSize='2xl' fontWeight='bold'>
                      {totalCheckpoints}
                    
                    </Text>
  
                    </Stat>
                    <Avatar name='' src={CurrentIcon} bg="white"/>
                  </Flex>
                  
                </Flex>
              </Card>
            <Card style={{
                    maxHeight : cardWidth
                  }} >
                <Flex direction='column'>
                  <Flex
                    flexDirection='row'
                    align='center'
                    justify='center'
                    w='100%'
                    mb='25px'>
                    <Stat me='auto'>
                      <StatLabel
                        fontSize='xs'
                        color='gray.400'
                        fontWeight='bold'
                        textTransform='uppercase'>
                        Total <br></br> Checked Locations
                      </StatLabel>
  
                      {/* <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                        {preapprovaltotalItems}
                      </StatNumber> */}
                      <Text color={textColor} fontSize='2xl' fontWeight='bold'>
                      {checked}
                    
                    </Text>
  
                    </Stat>
                    <Avatar name='' src={ApprovedIcon} bg="white"/>
                  </Flex>
                  
                </Flex>
              </Card>
              
              <Card style={{
                    maxHeight : cardWidth
                  }} >
                <Flex direction='column'>
                  <Flex
                    flexDirection='row'
                    align='center'
                    justify='center'
                    w='100%'
                    mb='25px'>
                    <Stat me='auto'>
                      <StatLabel
                        fontSize='xs'
                        color='gray.400'
                        fontWeight='bold'
                        textTransform='uppercase'>
                        Total <br></br> Missed Locations 
                      </StatLabel>
  
                      
                      <Text color={textColor} fontSize='2xl' fontWeight='bold'>
                      {missed}
                    
                    </Text>
                      
  
                    </Stat>
                    <Avatar name='' src={WrongEntryIcon} bg="white"/>
                  </Flex>
                  
                </Flex>
              </Card>
              {/* <Card style={{
                    maxHeight : "100px"
                  }} >
                <Flex direction='column'>
                  <Flex
                    flexDirection='row'
                    align='center'
                    justify='center'
                    w='100%'
                    mb='25px'>
                    <Stat me='auto'>
                      <StatLabel
                        fontSize='xs'
                        color='gray.400'
                        fontWeight='bold'
                        textTransform='uppercase'>
                        Denied
                      </StatLabel>
  
                      
                      <Text color={textColor} fontSize='2xl' fontWeight='bold'>
                      {denyTotal}
                    
                    </Text>
  
                    </Stat>
                    <Avatar name='' src={DeniedIcon} bg="white"/>
                  </Flex>
                  
                </Flex>
              </Card> */}
            <Card style={{
                    maxHeight : cardWidth
                  }} >
                <Flex direction='column'>
                  <Flex
                    flexDirection='row'
                    align='center'
                    justify='center'
                    w='100%'
                    mb='25px'>
                    <Stat me='auto'>
                      <StatLabel
                        fontSize='xs'
                        color='gray.400'
                        fontWeight='bold'
                        textTransform='uppercase'>
                        Incident Reported During Patrol
                      </StatLabel>
  
                      
                      <Text color={textColor} fontSize='2xl' fontWeight='bold'>
                      {totalReportIncident}
                    
                    </Text>
  
                    </Stat>
                    <Avatar name='' src={CurrentIcon} bg="white"/>
                  </Flex>
                  
                </Flex>
              </Card>
  
            
            </SimpleGrid>
            <Flex >
              <Box w='25%'  px="8px">
              <Card p='0px' maxW={{ sm: "320px", md: "100%" }} mt="10px">
                    {/* <Flex direction='column'>
                    <Flex align='center' justify='space-between' p='22px'>
                        
                        
                    </Flex>
                    </Flex> */}
                    <Flex>
                    <Box p='4'  width={boxWidth.left}>
                    <Text fontSize='sm' color='gray.400' fontWeight='bold'>
                        Most Missed Locations
                        </Text>
                    </Box>  
                    <Spacer />
                    <Box p='4'  width={boxWidth.right}>
                    <Select
                        fontSize='sm'
                        variant='subtle'
                        defaultValue='Monthly'
                        width='unset'
                        fontWeight='700'
                        color="red"
                        onChange={
                            (e) => {
                                
                                
                                getMostMissedCheckpoints(e.target.value);
                            }
                        }
                        >
                        <option value='Weekly'>Daily</option>
                        <option value='Monthly'>Weekly</option>
                        <option value='Yearly'>Monthly</option>
                        </Select>
                    </Box>
                    </Flex>
                    <Box overflow={{ sm: "scroll", lg: "hidden" }}>

                        {mostMissedCheckpoints.map((el, index, arr) => {
                            return (
                                        <Flex>
                                        <Box px='2' py="2">
                                        <Wrap>
                                        <WrapItem>
                                          <Text fontSize='sm'>{el.floorName}
                                            </Text>
                                        </WrapItem>
                                        </Wrap>
                                        
                                        </Box>
                                        <Spacer />
                                        <Box px='2' py="2">
                                        <Text fontSize='sm'>{`${el.missedCount}`}</Text>
                                        
                                        </Box>
                                        </Flex>
                                        
                                        
                                         
                            );
                        })}
                    </Box>
                </Card>

                <Card p='0px' maxW={{ sm: "320px", md: "100%" }} mt="10px">
                    {/* <Flex direction='column'>
                    <Flex align='center' justify='space-between' p='22px'>
                        
                        
                    </Flex>
                    </Flex> */}
                    <Flex>
                    <Box p='4'  width={boxWidth.left}>
                    <Text fontSize='sm' color='gray.400' fontWeight='bold'>
                        Most Missed Checklist Criteria at Location
                        </Text>
                    </Box>  
                    <Spacer />
                    <Box p='4'  width={boxWidth.right}>
                    <Select
                        fontSize='sm'
                        variant='subtle'
                        defaultValue='Monthly'
                        width='unset'
                        fontWeight='700'
                        color="red"
                        onChange={
                            (e) => {
                                getMostMissedChecklist(e.target.value);
                            }
                        }
                        >
                        <option value='Weekly'>Daily</option>
                        <option value='Monthly'>Weekly</option>
                        <option value='Yearly'>Monthly</option>
                        </Select>
                    </Box>
                    </Flex>
                    <Box overflow={{ sm: "scroll", lg: "hidden" }}>

                    {mostMissedChecklist.map((el, index, arr) => {
                            return (
                                    // <ListItem>
                                        <Flex>
                                        <Box px='2' py="2">
                                        <Text fontSize='sm'>{el._id}</Text>
                                        </Box>
                                        <Spacer />
                                        <Box px='2' py="2">
                                        <Text fontSize='sm'>{`${el.missedCount}`}</Text>
                                        
                                        </Box>
                                        </Flex>
                                        
                                        
                                         
                            );
                        })}
                    </Box>
                </Card>

                <Card p='0px' maxW={{ sm: "320px", md: "100%" }} mt="10px">
                    {/* <Flex direction='column'>
                    <Flex align='center' justify='space-between' p='22px'>
                    <Flex direction='column' >
                    
                    
                    </Flex>
                        
                    </Flex>
                    </Flex> */}
                    <Flex>
                    <Box p='4'  width={boxWidth.left}>
                    <Text color='gray.400' fontSize='sm' fontWeight='bold' mb='6px'>
                    Avg Patrol Duration
                    
                    </Text>
                    <Text color={textColor} fontSize='sm' fontWeight='bold'>
                    {averageTurnarroundTime}
                    </Text>
                    </Box>  
                    <Spacer />
                    <Box p='4'  width={boxWidth.right}>
                    <Select
                        fontSize='sm'
                        variant='subtle'
                        defaultValue='Monthly'
                        width='unset'
                        fontWeight='700'
                        color="red"
                        onChange={
                            (e) => {
                              getAverageTurnArroundTime(e.target.value);
                            }
                        }
                        >
                        <option value='Weekly'>Daily</option>
                        <option value='Monthly'>Weekly</option>
                        <option value='Yearly'>Monthly</option>
                        </Select>
                    </Box>
                    </Flex>
                    
                </Card>
                <Card p='0px' maxW={{ sm: "320px", md: "100%" }} mt="10px">
                    {/* <Flex direction='column'>
                    <Flex align='center' justify='space-between' p='22px'>
                        
                        
                    </Flex>
                    </Flex> */}
                    <Flex>
                    <Box p='4'  width={boxWidth.left}>
                    <Text fontSize='sm' color='gray.400' fontWeight='bold'>
                        Most Patrol Done by Guard
                        </Text>
                    </Box>  
                    <Spacer />
                    <Box p='4'  width={boxWidth.right}>
                    <Select
                        fontSize='sm'
                        variant='subtle'
                        defaultValue='Monthly'
                        width='unset'
                        fontWeight='700'
                        color="red"
                        onChange={
                            (e) => {
                                getGuardWithMostPatrol(e.target.value);
                            }
                        }
                        >
                        <option value='Weekly'>Daily</option>
                        <option value='Monthly'>Weekly</option>
                        <option value='Yearly'>Monthly</option>
                        </Select>
                    </Box>
                    </Flex>
                    <Box overflow={{ sm: "scroll", lg: "hidden" }}>

                    {guardWithMostPatrol.map((el, index, arr) => {
                            return (
                                    // <ListItem>
                                        <Flex>
                                        <Box px='2' py="2">
                                        <Text fontSize='sm'>{el.guardDetails?.name}</Text>
                                        </Box>
                                        <Spacer />
                                        <Box px='2' py="2">
                                        <Text fontSize='sm'>{`${el.scanCount}`}</Text>
                                        
                                        </Box>
                                        </Flex>
                                        
                                        
                                         
                            );
                        })}
                    </Box>
                </Card>
                <Card p='0px' maxW={{ sm: "320px", md: "100%" }} mt="10px">
                    {/* <Flex direction='column'>
                    <Flex align='center' justify='space-between' p='22px'>
                        
                        
                    </Flex>
                    </Flex> */}
                    <Flex>
                    <Box p='4'  width={boxWidth.left}>
                    <Text fontSize='sm' color='gray.400' fontWeight='bold'>
                        Most Incident Reported by Guard During Patrol
                        </Text>
                    </Box>  
                    <Spacer />
                    <Box p='4'  width={boxWidth.right}>
                    <Select
                        fontSize='sm'
                        variant='subtle'
                        defaultValue='Monthly'
                        width='unset'
                        fontWeight='700'
                        color="red"
                        onChange={
                            (e) => {
                                getTopGuardHavingMostIncidentReport(e.target.value);
                            }
                        }
                        >
                        <option value='Weekly'>Daily</option>
                        <option value='Monthly'>Weekly</option>
                        <option value='Yearly'>Monthly</option>
                        </Select>
                    </Box>
                    </Flex>
                    <Box overflow={{ sm: "scroll", lg: "hidden" }}>

                    {topGuardHavingMostReportIncident.map((el, index, arr) => {
                            return (
                                    // <ListItem>
                                        <Flex>
                                        <Box px='2' py="2">
                                        <Text fontSize='sm'>{el.guardDetails?.name}</Text>
                                        </Box>
                                        <Spacer />
                                        <Box px='2' py="2">
                                        <Text fontSize='sm'>{`${el.incidentCount}`}</Text>
                                        
                                        </Box>
                                        </Flex>
                                        
                                        
                                         
                            );
                        })}
                    </Box>
                </Card>
                {/* <Card p='0px' maxW={{ sm: "320px", md: "100%" }} mt="10px">
                    <Flex direction='column'>
                    <Flex align='center' justify='space-between' p='22px'>
                    <Flex direction='column' >
                    <Text color='gray.400' fontSize='sm' fontWeight='bold' mb='6px'>
                    {`Long Stay more then ${(authDetail?.longstayTime1)} min %`}
                    
                    </Text>
                    <Text color={textColor} fontSize='sm' fontWeight='bold'>
                    {longStayPercentage}
                    </Text>
                    
                    </Flex>
                        <Select
                        fontSize='sm'
                        variant='subtle'
                        defaultValue='Monthly'
                        width='unset'
                        fontWeight='700'
                        color="red"
                        onChange={
                            (e) => {
                              getLongstayPercentage(e.target.value);
                            }
                        }
                        >
                        <option value='Weekly'>Daily</option>
                        <option value='Monthly'>Weekly</option>
                        <option value='Yearly'>Monthly</option>
                        </Select>
                    </Flex>
                    </Flex>
                    
                </Card> */}
              </Box>
              <Box w='50%' px="8px">
              <Card p='0px' maxW={{ sm: "320px", md: "100%" }}>
                    <Flex direction='column' justify mb='40px' p='28px 0px 0px 22px'>
                    <Flex align='center' justify='space-between' >
                    <Text color='gray.400' fontSize='sm' fontWeight='bold'>
                        Daily Patrol
                    </Text>
                    <Select
                        fontSize='sm'
                        variant='subtle'
                        defaultValue='Monthly'
                        width='unset'
                        fontWeight='700'
                        color="red"
                        onChange={
                            (e) => {
                                
                                
                                getAveragePatroll(e.target.value);
                            }
                        }
                        >
                        <option value='Weekly'>Daily</option>
                        <option value='Monthly'>Weekly</option>
                        <option value='Yearly'>Monthly</option>
                        </Select>
                        </Flex>
                    
                    
                    </Flex>
                    <Flex direction='column' mb='0px' p='0px 0px 0px 22px'>
                    <Text color={textColor} fontSize='lg' fontWeight='bold'>
                    {averageVisit}
                    </Text>
                    <Text color='gray.400' fontSize='sm' fontWeight='bold' mb='6px'>
                    Average Patrol
                    </Text>
                    
                    </Flex>
                    
                    <Box minH='300px'>
                    {averageVisitLoader?<></>:<BarChart chartData={averageVistYaxis} chartOptions={averageVistXaxis} />}
                    </Box>
                </Card>

                <Card p='0px' maxW={{ sm: "320px", md: "100%" }} mt="10px">
                    
                    <Flex
                        px={{ base: "0px", "2xl": "10px" }}
                        justifyContent='space-between'
                        alignItems='center'
                        w='100%'
                        mb='8px'>
                        <Text color='gray.400' fontSize='sm' fontWeight='600' mt='4px'>
                        Peak Hour
                        </Text>
                        
                        <Select
                        fontSize='sm'
                        variant='subtle'
                        defaultValue='Monthly'
                        width='unset'
                        fontWeight='700'
                        color="red"
                        onChange={
                            (e) => {
                                
                                setPeakHourPeriod(e.target.value)
                                getPeakHourPeriodDetails(e.target.value);
                            }
                        }
                        >
                        <option value='Weekly'>Daily</option>
                        <option value='Monthly'>Weekly</option>
                        <option value='Yearly'>Monthly</option>
                        </Select>
                    </Flex>

                    <Flex
                        px={{ base: "0px", "2xl": "10px" }}
                        justifyContent='space-between'
                        alignItems='center'
                        w='100%'
                        mb='8px'>
                        <Flex direction='column' mb='0px' p='0px 0px 0px 22px'>
                          <Text color={textColor} fontSize='sm' fontWeight='bold'>
                          {peakVisitCount}
                          
                          </Text>
                          <Text color='gray.400' fontSize='sm' fontWeight='bold' mb='6px'>
                          Peak Patrolling of this {peakHourPeriod == 'Weekly' ? 'daily' : peakHourPeriod == 'Monthly' ? 'week':'month'}
                          </Text>
                        </Flex>
                        <Flex direction='column' >
                          <Text color={textColor} fontSize='sm' fontWeight='bold'>
                          {peakDate}
                          
                          </Text>
                          <Text color='gray.400' fontSize='sm' fontWeight='bold' mb='6px'>
                          Peak Day
                          </Text>
                        </Flex>
                        <Flex direction='column' mb='0px' p='0px 22px 0px 0px'>
                          <Text color={textColor} fontSize='sm' fontWeight='bold'>
                          {peakInterval}
                          
                          </Text>
                          <Text color='gray.400' fontSize='sm' fontWeight='bold' mb='6px'>
                          Peak Hour
                          </Text>
                        </Flex>
                        
                    </Flex>
                    <Flex
                        px={{ base: "0px", "2xl": "10px" }}
                        justifyContent='space-between'
                        alignItems='center'
                        w='100%'
                        mb='8px'>
                        <Text color={textColor} fontSize='sm' fontWeight='600' mt='4px'>
                        
                        </Text>
                        
                        <Select
                        fontSize='sm'
                        variant='subtle'
                        defaultValue='Monthly'
                        width='unset'
                        fontWeight='700'
                        color="red"
                        onChange={
                            (e) => {
                                
                                
                                getPeakHour(e.target.value);
                            }
                        }
                        >

                          {peakHourDateRange.map((el, index, arr) => {
                            return (   
                              <option value={el.value}>{el.title}</option>               
                            );
                        })}
                        
                        
                        </Select>
                    </Flex>
                    <Box minH='300px'>
                    {peakHourLoader? <></>:<BarChart chartData={peakHourYaxis} chartOptions={peakHourXaxis} />}
                    </Box>
                </Card>
              </Box>
              <Box w='25%'  px="8px">
              <Card p='2px' align='center' direction='column' w='100%' {...rest}>
                    <Flex>
                    <Box p='4'  width={boxWidth.left}>
                    <Text color='gray.400' fontSize='sm' fontWeight='600' mt='4px'>
                        Checked vs Missed (%)
                        </Text>
                    </Box>  
                    <Spacer />
                    <Box p='4'  width={boxWidth.right}>
                    <Select
                        fontSize='sm'
                        variant='subtle'
                        defaultValue='Monthly'
                        width='unset'
                        fontWeight='700'
                        color="red"
                        onChange={
                            (e) => {
                                
                                
                                getCheckedMissedCheckpoints(e.target.value);
                            }
                        }
                        >
                        <option value='Weekly'>Daily</option>
                        <option value='Monthly'>Weekly</option>
                        <option value='Yearly'>Monthly</option>
                        </Select>
                    </Box>
                    </Flex>

                    {returningLoader ? <></>:<PieChart
                        h='100%'
                        w='100%'
                        chartData={recOneVisit}
                        chartOptions={newPieChartOptions}
                    />}
                    
                        <Stack direction='row' h='100px' p={4}>
                        <Flex direction='column' py='5px'>
                        <Flex align='center'>
                            <Box h='8px' w='8px' bg='#4318FF' borderRadius='80%' me='4px' />
                            <Text
                            fontSize='xs'
                            color='secondaryGray.600'
                            fontWeight='700'
                            mb='5px'>
                            Checked
                            </Text>
                        </Flex>
                        <Text fontSize='lg' color={textColor} fontWeight='700'>
                            {`${recurringVisit} %`}
                        </Text>
                        </Flex>
                            <Divider orientation='vertical' />
                            <Flex direction='column' py='5px' me='10px'>
                        <Flex align='center'>
                            <Box h='8px' w='8px' bg='#6AD2FF' borderRadius='80%' me='4px' />
                            <Text
                            fontSize='xs'
                            color='secondaryGray.600'
                            fontWeight='700'
                            mb='5px'>
                            Missed
                            </Text>
                        </Flex>
                        <Text fontSize='lg' color={textColor} fontWeight='700'>
                            {`${oneTimeVisit} %`}
                        </Text>
                        </Flex>
                        </Stack>
                        
                        
                        
                    </Card> 

                    <Card p='0px' maxW={{ sm: "320px", md: "100%" }} mt="10px">
                    {/* <Flex direction='column'>
                    <Flex align='center' justify='space-between' p='22px'>
                        
                        
                    </Flex>
                    </Flex> */}
                    <Flex>
                    <Box p='4'  width={boxWidth.left}>
                    <Text fontSize='sm' color='gray.400' fontWeight='bold'>
                        Most Checked Locations
                        </Text>
                    </Box>  
                    <Spacer />
                    <Box p='4'  width={boxWidth.right}>
                    <Select
                        fontSize='sm'
                        variant='subtle'
                        defaultValue='Monthly'
                        width='unset'
                        fontWeight='700'
                        color="red"
                        onChange={
                            (e) => {
                                getMostCheckedCheckpoints(e.target.value);
                            }
                        }
                        >
                        <option value='Weekly'>Daily</option>
                        <option value='Monthly'>Weekly</option>
                        <option value='Yearly'>Monthly</option>
                        </Select>
                    </Box>
                    </Flex>
                    <Box overflow={{ sm: "scroll", lg: "hidden" }}>
                    <UnorderedList styleType="' '">

                        
                        {mostCheckedCheckpoints.map((el, index, arr) => {
                            return (
                                    // <ListItem>
                                        <Flex>
                                        <Box px='2' py="2">
                                        <Text fontSize='sm'>{el._id?.floorName}</Text>
                                        </Box>
                                        <Spacer />
                                        <Box px='2' py="2">
                                        <Box w='60%' h='10'  >
                                                <Flex align='center'>
                                                <Text
                                                color={textTableColor}
                                                fontWeight='bold'
                                                fontSize='sm'
                                                me='12px'>{`${el.percentage}%`}</Text>
                                                <Progress
                                                size='xs'
                                                colorScheme="orange"
                                                value={el.percentage}
                                                minW='120px'
                                                />
                                            </Flex>
                                         </Box>
                                        
                                        </Box>
                                        </Flex>
                                        
                                        
                                         
                            );
                        })}
                        </UnorderedList>
                    </Box>
                </Card>
                <Card p='0px' maxW={{ sm: "320px", md: "100%" }} mt="10px">
                    <Flex direction='column'>
                    <Flex align='center' justify='space-between' p='22px'>
                        <Text fontSize='sm' color='gray.400' fontWeight='bold'>
                        Locations not Checked from Last 3 days
                        </Text>
                        {/* <Select
                        fontSize='sm'
                        variant='subtle'
                        defaultValue='Monthly'
                        width='unset'
                        fontWeight='700'
                        color="red"
                        onChange={
                            (e) => {
                                getUnscannedCheckPointsLast3Days(e.target.value);
                            }
                        }
                        >
                        <option value='Weekly'>Daily</option>
                        <option value='Monthly'>Weekly</option>
                        <option value='Yearly'>Monthly</option>
                        </Select> */}
                    </Flex>
                    </Flex>
                    <Box overflow={{ sm: "scroll", lg: "hidden" }}>

                    {unscannedLastThreeDays.map((el, index, arr) => {
                            return (
                                    // <ListItem>
                                        <Flex>
                                        <Box px='4' py="2">
                                        <Text fontSize='sm'>{el.name}</Text>
                                        </Box>
                                         <Spacer />
                                        {/*<Box px='2' py="2">
                                        <Box w='60%' h='10'  >
                                                <Flex align='center'>
                                                <Text
                                                color={textTableColor}
                                                fontWeight='bold'
                                                fontSize='sm'
                                                me='12px'>{`${el.percentage}%`}</Text>
                                                <Progress
                                                size='xs'
                                                colorScheme="orange"
                                                value={el.percentage}
                                                minW='120px'
                                                />
                                            </Flex>
                                         </Box>
                                        
                                        </Box> */}
                                        </Flex>
                                        
                                        
                                         
                            );
                        })}
                    </Box>
                </Card>
              </Box>
            </Flex>
            
            <Grid
          templateColumns={{ sm: "1fr", lg: "2fr 1fr" }}
          templateRows={{ lg: "repeat(2, auto)" }}
          gap='20px'>
          
         
          
        </Grid>
          </Flex>

         
        </>
  
  
  
      );
   
  
  }
  