import { 
    PARKING_LOADING, 
    PARKING_GET_ALL_SUCCESS, 
    PARKING_ERROR,
    PARKING_CLEAR_SNACKBAR
} from '../constants/types';

const initialState = {
    response : {},
    parkingList: [],
    parkingDetail: {},
    loading: false,
    status: "success",
    text: "",
    showSnackbar: false,
    limit: 0,  // Add missing properties
    page: 0,
    totalPages: 0,
};

const parkingReducer = (state = initialState, action) => {
    switch (action.type) {
        case PARKING_CLEAR_SNACKBAR: 
            return {
                ...state,
                showSnackbar: false
            }
        case PARKING_LOADING: 
            return {
                ...state,
                loading: true,
            }
        case PARKING_ERROR:
            return {
                ...state,
                parkingList: [],
                response : {},
                parkingDetail : {},
                loading: false,
                status: action.payload.status,
                text: action.payload.text,
                showSnackbar: true,
                limit: 0,  // Add missing properties
                page: 0,
                totalPages: 0,
            }
        case PARKING_GET_ALL_SUCCESS:
            return {
                ...state,
                parkingList: action.payload.parkingList,
                response: action.payload.response,
                parkingDetail: action.payload.parkingDetail,
                loading: false,
                status: action.payload.status,
                text: action.payload.text,
                showSnackbar: true,
                limit: action.payload.limit,
                page: action.payload.page,
                totalPages: action.payload.totalPages,
            }
        default: 
            return state || {};
    }
}

export default parkingReducer;
