import React, { useEffect, useRef, useState } from 'react';
import { Flex, InputGroup, Spacer, Table,Button, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { AddIcon } from '@chakra-ui/icons';
import { VISITORINOUT_CLEAR_SNACKBAR } from 'constants/types';

// import {VisitorHeadCountTableRow} from './VisitorHeadCountTableRow'
import VisitorTodayHeadCountRow from './VisitorTodayHeadCountRow';
import VisitorInOutPagination from './VisitorInOutPagination';
import { VisitorGetHeadCount } from 'actions/VisitorInOutAction';
import VisitorHeadCountTablePagination from './VisitorHeadCountTablePagination';

const VisitorHeadCountTable = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.visitorInOut.status);
  const responseMessage = useSelector(state => state.visitorInOut.text);
  const loading = useSelector(state => state.visitorInOut.loading);
  const VisitorInOutList = useSelector(state => state.visitorInOut.visitorInOutList);
  const visitorInOutListIN = useSelector(state => state.visitorInOut.visitorInOutListIN);
  const currentPage = useSelector(state => state.visitorInOut.page); // Make sure you have the correct state slice for driver showSnackbar
  const totalPages = useSelector(state => state.visitorInOut.totalPages); // Make sure you have the correct state slice for driver showSnackbar
  const limit = useSelector(state => state.visitorInOut.limit); // Make sure you have the correct state slice for driver showSnackbar
  console.log(";iakldu cfgdakuhalkud");
  console.log(responseStatus);
  const response = useSelector(state => state.visitorInOut.response);
  const showSnackbar = useSelector(state => state.visitorInOut.showSnackbar);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const tableRef = useRef(null);

  useEffect(() => {
    dispatch(VisitorGetHeadCount());
  }, [dispatch]);
  console.log("VisitorInOutList------")
  console.log(visitorInOutListIN)
  useEffect(() => {
    const searching = "&search=" + search;
    dispatch(VisitorGetHeadCount(searching));
  }, [search]);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: VISITORINOUT_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
    dispatch(VisitorGetHeadCount(sorting));
  };


  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
            Visitor Head Count Table
            </Text>
          </CardHeader>
          <Spacer />
{/* excel sheel */}
          <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            <DownloadTableExcel
              filename=" Visitor Head Count Table"
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <Button colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>
                Export Excel
              </Button>
            </DownloadTableExcel>
          </Flex>

          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              <InputGroup borderRadius="8px" w="200px">
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon
                          color={searchIconColor}
                          w="15px"
                          h="15px"
                        />
                      }
                    ></IconButton>
                  }
                />
                <Input
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.length < 3) {
                      setSearch("");
                    }
                  }}
                  placeholder="Type here..."
                />
              </InputGroup>
            </Text>
          </CardHeader>
        </Flex>
        <CardBody pb={4}>
        <div style={{ overflowX: "auto" }}> 
          <Table variant="simple" color={textColor} ref={tableRef}>
          <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
              <Th  color="gray.400">SNo</Th>
              <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("vechilenumber")}
                  cursor="pointer"
                >
                  Txt Number
                  {sortColumn === "vechilenumber" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("vechilenumber")}
                  cursor="pointer"
                >
                  Guard Name Entry Raised by
                  {sortColumn === "vechilenumber" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>

                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("vechilenumber")}
                  cursor="pointer"
                >
                  Checkpost In Name
                  {sortColumn === "vechilenumber" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("vechilenumber")}
                  cursor="pointer"
                >
                  Image
                  {sortColumn === "vechilenumber" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("drivername")}
                  cursor="pointer"
                >
                  Name
                  {sortColumn === "drivername" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("drivername")}
                  cursor="pointer"
                >
                  Company
                  {sortColumn === "drivername" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("intime")}
                  cursor="pointer"
                >
                  Contact No
                  {sortColumn === "intime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("intime")}
                  cursor="pointer"
                >
                  Visitor Type
                  {sortColumn === "intime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                 Purpose
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                Department
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                Sub Department
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
             visitor Status
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                Carry item
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                vehicle Type
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>

                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                vehicle Number
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
             status
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>

             
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
               in Time
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                
                {/* <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
              logs
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th> */}

              </Tr>
            </Thead>
            <Tbody>
              {visitorInOutListIN.length != 0 ? (visitorInOutListIN.map((row, index, arr) => {
                return (
                  <VisitorTodayHeadCountRow
                    serialnumber={((currentPage - 1)*limit) + (index+1)}
                    txnNumber = {row.txnNumber}
                    guardNameEntryRaisedBy = {row.guard.name}
                    checkpostInName = {row.checkpostNameIn}
                    image = {row.image}
                    name = {row.name}
                    company = {row.company}
                    contactno = {row.contactNo}
                    visitorType ={row.visitorType.name}
                    purpose = {row.purpose}
                    department = {row.departmentId?.name}
                    subdepartment = {row.subDepartmentId?.name}
                    carryItem = {row.otherDetails.carryItems}
                    vehicleType = {row.otherDetails.vehicleType}
                    vehicleNumber = {row.otherDetails.vehicleNumber}
                    inTime={row.inDateTime}
                    visitorStatus = {row.visitorStatus}
                    status = {row.status}
                    // totalTime = {row.totalTime}
                    // ExitByGuardName = {row.guardOut ? row.guardOut.name : <></>}
                    // checkpostOutName = {row.checkpostNameOut}
                    // outTime={row.outDateTime}
                    logs={row.logs}
                    isLast={index === arr.length - 1 ? true : false}
                    id={row._id}

                  />
                );
              })) : (<h1></h1>)}
            </Tbody>
          </Table>
          </div>
           {visitorInOutListIN.length === 0 ? (
            <></>
          ) : (
            <VisitorHeadCountTablePagination
              totalPages={response.totalPages}
              currentPage={response.currentPage}
              perPage={response.perPage}
              sortBy={sortColumn}
              sortOrder={sortOrder}
              handlePageClick={(data) => {
                const selectedPage = data.selected + 1;
                dispatch(VisitorGetHeadCount(`&page=${selectedPage}`));
              }}
            />
          )} 
        </CardBody>
      </Card>
    </Flex>
  );
};

export default VisitorHeadCountTable;
