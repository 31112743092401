import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
  useToast,
  Box,
  Center,
  Button,
  Flex,
} from "@chakra-ui/react";
import dataConstant from "constants/dataConstant";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { PURPOSE_CLEAR_SNACKBAR } from "constants/types";
// import { Spinner } from '@chakra-ui/react';
// import { parkingAddOrUpdate } from "actions/parkingAction";
import { purposeAddOrUpdate } from "actions/purposeAction";
import axios from "axios";
import { server_url } from "constants/types";
// import { parkingdepartmentGetAll } from "actions/parkingDepartmentAction";
import { purposeGetAll } from "actions/purposeAction";
import { useHistory } from 'react-router-dom'; 
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
function PurposeForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const params = useParams();
  const [name , setName] = useState('');
  const [description , setDescription] = useState("");
  const [status , setStatus] = useState("");
  const [admin , setAdmin] = useState(0);

  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorDescription, setIsErrorDescription] = useState(false);
  const [isErrorStatus, setIsErrorStatus] = useState(false);
  const [isErrorAdmin, setIsErrorAdmin] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.purpose.status);
  const responseMessage = useSelector(state => state.purpose.text);
  const loading = useSelector(state => state.purpose.loading);
  const showSnackbar = useSelector(state => state.purpose.showSnackbar);
//   const parkingDepartmentList = useSelector(state => state.parkingdepartment.parkingdepartmentList);


  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // setIsErrorName(name === '');
    
    setIsErrorName(name === '');
    // setIsErrorDescription(description === '');
    setIsErrorStatus(status === '');
    setIsErrorAdmin(admin === '')

    if (name !== "" && status !== "" && admin !== ""  ) {
      console.log("Submitttttt");
      // Get form data
      const purposeData = {
        name: name,
        description:description,
        status: status ,
        plantAdmin: params.id,
      
        // Other parking data fields
      };

      // Determine if it's an add or edit operation
      const state = 'add';
      // Call the parkingAddOrUpdate action
      dispatch(purposeAddOrUpdate(purposeData , state))
    }
  };

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
          history.goBack();
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: PURPOSE_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  useEffect(() => {
    dispatch(purposeGetAll())
    // plantDepartmentList()
  }, []);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])

//   const adminId = params.id;
//   const plantDepartmentList = () => {
//     axios
//       .get(`${server_url}/plant/department/${adminId}`, {  // Make sure to include the missing '/'
//         headers: {
//           'Authorization': 'Bearer ' + localStorage.getItem('authToken')
//         }
//       })
//       .then((response) => {
//         setDepartmentList(response.data.data);
//       })
//       .catch((error) => {
//         console.error('Error fetching department list:', error);
//       });
//   };
  

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
    <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
      
      <CardHeader p="6px 0px 22px 0px">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
         Purpose Form
        </Text>
        <br/>


        <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>


        <FormControl isInvalid={isErrorName}>
              <FormLabel> Name</FormLabel>
              <Input
                type="text"
                onChange={(e) => {
                  setIsErrorName(e.target.value === "");
                  setName(e.target.value);
                }}
              />
              {!isErrorName ? (
                <></>
              ) : (
                <FormErrorMessage>Name is required.</FormErrorMessage>
              )}
            </FormControl>



            <FormControl isInvalid={isErrorDescription}>
              <FormLabel>Description</FormLabel>
              <Input
                type="text"
                onChange={(e) => {
                  // setIsErrorDescription(e.target.value === "" );
                  setDescription(e.target.value);
                }}
              />
              {!isErrorDescription ? (
                <></>
              ) : (
                <FormErrorMessage>Description is required.</FormErrorMessage>
              )}
            </FormControl>



        </SimpleGrid>


        <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>


        <FormControl isInvalid={isErrorStatus}>
            <FormLabel>Status</FormLabel>
            <Select
              placeholder="----Select status----"
              onChange={(e) => {
                setIsErrorStatus(e.target.value === '')
                setStatus(e.target.value)
              }}
            >
              { dataConstant.status.map((element,index) => {
                  return (<option key={element['id']} value={element['value']} >{element['name']}</option>);   
                    
                })}
            </Select>
            {!isErrorStatus ? (
              <></>
            ) : (
              <FormErrorMessage>Status is required.</FormErrorMessage>
            )}
          </FormControl>


{/* <FormControl isInvalid={isErrorAdmin}>
  <FormLabel>Admin</FormLabel>
  <Input
    type='text'
    onChange={(e) => {
      const inputValue = e.target.value;
      setIsErrorUnLoadedBays(inputValue === '');
      setUnloadedBays(inputValue === '');
    }}
  />
  {!isErrorAdmin ? (
    <></>
  ) : (
    <FormErrorMessage>Admin is required.</FormErrorMessage>
  )}
</FormControl> */}


        </SimpleGrid>
        <Center>
          {loading ? <Spinner mt='24px' size='lg' /> :
            <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
              Submit
            </Button>
          }
        </Center>
      </CardHeader>
      <CardBody>
      </CardBody>
    </Card>
  </Flex>
  );
}

export default PurposeForm;
