import axios from 'axios';
import {
  GUARD_LOADING,
  GUARD_GET_ALL_SUCCESS,
  GUARD_ERROR,
  GUARD_CLEAR_SNACKBAR
} from '../constants/types';

import { server_url } from '../constants/types';

export const guardGetAll = (pagination = '') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: GUARD_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/guard?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          dispatch({
            type: GUARD_GET_ALL_SUCCESS,
            payload: {
              guardList: response.data.data,
              response: response.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              loading: false,
              status: 'success',
              text: 'Get all guards successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: GUARD_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting guard data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};


export const guardAttendanceHistoryGetAll = (guard, search) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: GUARD_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/guard/attendance/history/list/${guard._id}?${search}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          console.log("pradeep-data");
          console.log(response.data.data)
          dispatch({
            type: GUARD_GET_ALL_SUCCESS,
            payload: {
              guardAttendenceHistoryList: response.data.data,
              employeeList: [],
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Guard Attendence History List successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: GUARD_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting guard data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const guardAddOrUpdate = (guard, state ,limit='' ,page='') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: GUARD_LOADING,
        payload: {
          loading: true
        }
      });
      if (state === 'add') {
        axios
          .post(`${server_url}/guard`, guard, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/guard?limit=${limit}&page=${page}`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {
                console.log("response.data.data of guard add")
                console.log(response.data.data)
                dispatch({
                  type: GUARD_GET_ALL_SUCCESS,
                  payload: {
                    guardList: response.data.data,
                    response: response.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: GUARD_ERROR,
                  payload: {
                    text: 'Error occurred during getting guard data.',
                    status: 'error',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: GUARD_ERROR,
              payload: {
                text: 'Error occurred during adding guard data.',
                status: 'error',
                loading: false
              }
            });
            resolve();
          });
      } else if (state === 'edit') {
        axios
          .put(`${server_url}/guard/${guard._id}`, guard, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/guard?limit=${limit}&page=${page}`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {
                dispatch({
                  type: GUARD_GET_ALL_SUCCESS,
                  payload: {
                    guardList: response.data.data,
                    response: response.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    loading: false,
                    status: responseF.data['status'],
                    guardDetail: responseF.data['data'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: GUARD_ERROR,
                  payload: {
                    text: 'Error occurred during getting guard data.',
                    status: 'error',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: GUARD_ERROR,
              payload: {
                text: 'Error occurred during updating guard data.',
                status: 'error',
                loading: false
              }
            });
            resolve();
          });
      } else if (state === 'view') {
        axios
          .get(`${server_url}/guard-view/${guard._id}`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/guard?limit=${limit}&page=${page}`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {
                dispatch({
                  type: GUARD_GET_ALL_SUCCESS,
                  payload: {
                    guardList: response.data.data,
                    response: response.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    guardDetail: responseF.data['data'],
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: GUARD_ERROR,
                  payload: {
                    text: 'Error occurred during getting guard data.',
                    status: 'error',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: GUARD_ERROR,
              payload: {
                text: 'Error occurred during updating guard data.',
                status: 'error',
                loading: false
              }
            });
            resolve();
          });
      }
      else if (state === "upload") {
        axios
          .post(`${server_url}/guard/upload-excelsheet`, guard, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + localStorage.getItem('authToken'),  
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/guard?limit=${limit}&page=${page}`, {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('authToken'),
                },
              })
              .then((response) => {
                dispatch({
                  type: GUARD_GET_ALL_SUCCESS,
                  payload: {
                    guardList: response.data.data,
                    response: response.data,
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: GUARD_ERROR,
                  payload: {
                    text: "Error occurred during getting Guard data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: GUARD_ERROR,
              payload: {
                text: "Error occurred during adding Guard data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      }
    });
  };
};

export const guardDelete = (guard ,limit ='' , page='') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: GUARD_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .delete(`${server_url}/guard/${guard}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((responseF) => {
          axios
            .get(`${server_url}/guard?limit=${limit}&page=${page}`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
            .then((response) => {
              dispatch({
                type: GUARD_GET_ALL_SUCCESS,
                payload: {
                  guardList: response.data.data,
                  limit: response.data.limit,
                  page: response.data.currentPage,
                  totalPages: response.data.totalPages,
                  response: response.data,
                  guardDetail: responseF.data['data'],
                  loading: false,
                  status: responseF.data['status'],
                  text: responseF.data['message']
                }
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: GUARD_ERROR,
                payload: {
                  text: 'Error occurred during getting guard data.',
                  status: 'error',
                  loading: false
                }
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: GUARD_ERROR,
            payload: {
              text: 'Error occurred during deleting guard data.',
              status: 'error',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const guardClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: GUARD_CLEAR_SNACKBAR
    });
  };
};
