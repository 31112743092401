// Chakra imports
import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Input,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,

} from "@chakra-ui/react";
// Custom components
import { EmailIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import axios from 'axios';
import { server_url } from "constants/types";
import { Divider } from '@chakra-ui/react';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { employeeAddOrUpdate, employeeClearShowSnackbar } from '../../actions/EmployeeAction';
import { useToast } from '@chakra-ui/react'
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { EMPLOYEE_CLEAR_SNACKBAR } from "constants/types";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'

function EmployeeView() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [employeeName, setEmployeeName] = useState("");
  const [photo, setPhoto] = useState("");
  const [phone, setPhone] = useState("");
  const [alternateNumber, setAlternateNumber] = useState("");
  const [officialEmail, setOfficialEmail] = useState('');
  const [employeeId, setEmployeeId] = useState("");
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [subdepartment, setSubdepartment] = useState("");
  const [employeeType, setEmployeeType] = useState('');
  const [employeeAddress, setEmployeeAddress] = useState('');
  const [vehilcType, setVehilcType] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [licenseNumber, setLicenseNumber] = useState('');
  const [licenseExpiry, setLicenseExpiry] = useState('');
  const [licensePhoto, setLicensePhoto] = useState('')
  const [passcode, setPasscode] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");


  const dispatch = useDispatch();
  const toast = useToast();

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.employee.status);
  const responseMessage = useSelector(state => state.employee.text);
  const loading = useSelector(state => state.employee.loading);
  const employeeDetail = useSelector(state => state.employee.employeeDetail);
  const showSnackbar = useSelector(state => state.employee.showSnackbar);

  const params = useParams();

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === "OK" && loading === false) {
        if (employeeDetail) {
          setEmployeeName(employeeDetail.employeeName);
          setPhoto(employeeDetail.photo);
          setPhone(employeeDetail.phone);
          setAlternateNumber(employeeDetail.alternateNumber);
          setOfficialEmail(employeeDetail.officialEmail);
          setEmployeeId(employeeDetail.employeeId);
          setDepartment(employeeDetail.department?.name || "");
          setDesignation(employeeDetail.designation?.name || "");
          setSubdepartment(employeeDetail.subdepartment?.name || "");
          setEmployeeType(employeeDetail.employeeType);
          setStart(employeeDetail.startDate);
          setEnd(employeeDetail.endDate);

          setEmployeeAddress(employeeDetail.employeeAddress);
          setPasscode(employeeDetail.passcode);
          if (employeeDetail.vehicleDetails) {
            setVehilcType(employeeDetail.vehicleDetails.vehicleType);
            if (
              employeeDetail.vehicleDetails.vehicleType !== "" &&
              employeeDetail.vehicleDetails.vehicleType !== "BY_WALK"
            ) {
              setVehicleNumber(employeeDetail.vehicleDetails.vehicleNumber);
              setLicenseNumber(employeeDetail.vehicleDetails.licenseNumber);
              setLicenseExpiry(employeeDetail.vehicleDetails.licenseExpiry);
              setLicensePhoto(employeeDetail.vehicleDetails.licensePhoto);
            }
          }
        }

        if (responseMessage !== "") {
          toastFunction(responseMessage, "success");
        }
      } else if (responseStatus === "NOK" && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, "error");
        }
      }
    }

    dispatch({
      type: EMPLOYEE_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      },
    });
  }, [loading]);

  useEffect(() => {
    console.log("params");
    const empId = params.id;
    const employee = {
      _id: empId
    };
    dispatch(employeeAddOrUpdate(employee, 'view'));
  }, []);
  const empId = params.id;
  console.log("empId");
  console.log(empId);
  console.log("empId");


  const passCodeGenerater = () => {
    axios
      .get(`${server_url}/employee/generate-passcode/${empId}`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      })
      .then((response) => {
        if (response.data.status === 'OK') {
          const passcode = response.data.data;
          setPasscode(passcode);
          showToast(response.data.message, 'success');
        } else {
          setPasscode([]);
          showToast(response.data.message, 'error');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        showToast('An error occurred', 'error');
      });

  }

  const showToast = (message, status) => {
    toast({
      title: message,
      status: status,
      duration: 3000, // Display duration in milliseconds
      isClosable: true,
    });
  };


  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  console.log("employee-details")
  console.log(employeeDetail)

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
           Employee View 
          </Text>

          <Flex justifyContent="flex-end" marginRight={40}>
            {/* <Button
              colorScheme="blue"
              aria-label="Up"
              _hover={{
                background: "lightblue", // Change to light blue on hover
                color: "black",
              }}
              onClick={passCodeGenerater}
            >
              Generate Passcode
            </Button> */}
                <Button
                  rightIcon={<ArrowForwardIcon />}
                  onClick={passCodeGenerater}
                  colorScheme='blue'
                  variant='outline'
                  _hover={{ 
                    bg: 'blue.600', // Change background color on hover
                    color: 'white'   // Change text color on hover
                  }}
                >
                  Generate Passcode
                </Button>
          </Flex>

        </CardHeader>
        <CardBody>
          {loading ? <Stack>
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
          </Stack>
            :
            <TableContainer>
              <Table variant='simple'>
                <Tbody>
                  <Tr>
                    <Td>Name</Td>
                    <Td>{employeeName}</Td>
                  </Tr>
                  <Tr>
                    <Td>Image</Td>
                    <Td><Flex direction="column">
                      <Text fontSize="md" color={textColor} fontWeight="bold">
                        <Image
                          boxSize='100px'
                          objectFit='cover'
                          src={photo}
                          alt='Dan Abramov'
                        />

                      </Text>
                    </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Phone</Td>
                    <Td>{phone}</Td>
                  </Tr>
                  <Tr>
                    <Td>Alternate Number</Td>
                    <Td>{alternateNumber}</Td>
                  </Tr>
                  <Tr>
                    <Td>Official Email</Td>
                    <Td>{officialEmail}</Td>
                  </Tr>

                  <Tr>
                    <Td>Department</Td>
                    <Td>{department}</Td>
                  </Tr>
                  <Tr>
                    <Td>Designation</Td>
                    <Td>{designation}</Td>
                  </Tr>
                  <Tr>
                    <Td>Subdepartment</Td>
                    <Td>{subdepartment}</Td>
                  </Tr>
                  {
                    !start && !end ? (
                      <>
                      </>
                    ) : <>
                      <Tr>
                        <Td>Start Date</Td>
                        <Td>{start}</Td>
                      </Tr>
                      <Tr>
                        <Td>End Date</Td>
                        <Td>{end}</Td>
                      </Tr>
                    </>
                  }


                  <Tr>
                    <Td>Employee Type</Td>
                    <Td>{employeeType}</Td>
                  </Tr>
                  <Tr>
                    <Td>Employee Address</Td>
                    <Td>{employeeAddress}</Td>
                  </Tr>
                  <Tr>
                    <Td>Vehicle Details</Td>
                    {vehilcType === 'BY_WALK' ? (
                      <Td>
                        <p>
                          <b>vehicleType</b> : {vehilcType}
                        </p>
                      </Td>
                    ) : (
                      <Td>
                        <Flex>
                          <p>
                            <b>Vehicle Type</b> : {vehilcType}
                            <br />
                            <b>Vehicle Number</b> : {vehicleNumber}
                            <br />
                            <b>License Number</b>  : {licenseNumber}
                            <br />
                            <b>License Expiry</b> : {licenseExpiry}<br />
                          </p>
                          <Divider orientation="vertical" mx={4} h="auto" />
                          <Box>
                            <Flex pt={{ base: "120px", md: "75px" }}>
                              <b>License Photo</b> :
                              <Image
                                boxSize='100px'
                                objectFit='cover'
                                src={licensePhoto}
                                alt='Dan Abramov'
                                mt="-30px"
                                ml="20px"
                              />
                            </Flex>
                          </Box>
                        </Flex>
                      </Td>
                    )}
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>}
        </CardBody>
      </Card>

    </Flex>
  );
}

export default EmployeeView;
