// Chakra imports
import {
    Box,
    Button,
    Center,
    Flex,
    Input,
    SimpleGrid,
    Spacer,
    Stack,
    Text,
    Wrap,
    WrapItem,
    useColorModeValue
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from 'react-redux';
  import { corporateAddOrUpdate } from '../../actions/adminActions';
  import { useToast } from '@chakra-ui/react'
  import { useParams } from "react-router-dom/cjs/react-router-dom.min";
  import { ADMIN_CLEAR_SNACKBAR } from "constants/types";
  import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
  } from '@chakra-ui/react'
  import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react';
  
  
  
  function CorporateView() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    // const [password, setPassword] = useState("");
    const [contactNo, setContactNo] = useState("");
    // const [role, setRole] = useState("");
    const [city , setCity] = useState("");
    const [secoundarycontactNo, setSecoundarycontactNo] = useState("");
    const [status, setStatus] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    // const [dob, setDob] = useState("");
    // const [address, setAddress] = useState("");
    // const [basicDetails, setbasicDetails] = useState("");
    const [isErrorName, setIsErrorName] = useState(false);
    const [isErrorEmail, setIsErrorEmail] = useState(false);
    const [isErrorContactNo, setIsErrorContactNo] = useState(false);
    const [isErrorPassword, setIsErrorPassword] = useState(false);
    const [mappedPlant, setMappedPlant] = useState([]);
  
    const dispatch = useDispatch();
    const toast = useToast();
  
    const statuses = ['success', 'error', 'warning', 'info'];
    const responseStatus = useSelector(state => state.admin.status);
    const responseMessage = useSelector(state => state.admin.text);
    const loading = useSelector(state => state.admin.loading);
    const adminDetail = useSelector(state => state.admin.adminDetail);
    const showSnackbar = useSelector(state => state.admin.showSnackbar);
    console.log("-----adminDetail-----")
    console.log(adminDetail)
    const params = useParams();
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === 'OK' && loading === false) {
          console.log(adminDetail);
          setName(adminDetail.name);
          setEmail(adminDetail.email);
          setContactNo(adminDetail.contactNo);
          // setPassword(adminDetail.password);
          // setSecoundarycontactNo(adminDetail.secoundarycontactNo);
          setStatus(adminDetail.status);
          // setStartDate(adminDetail.startDate);
          // setEndDate(adminDetail.endDate);
          setCity(adminDetail?.city?.name);
          setMappedPlant(adminDetail.mappedPlant);
          // setAddress(adminDetail.address)
          // setbasicDetails(adminDetail.basicDetails)
          // setRole(adminDetail.role);
          // setDob(adminDetail.dob);
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'success');
          }
  
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'error');
          }
  
        }
      }
  
      dispatch({
        type: ADMIN_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        }
      })
    }, [loading]);
  
    useEffect(() => {
      console.log("params");
      const state = 'view';
      const adminId = params.id;
      const admin = {
        _id : adminId
      };
      console.log(admin._id)
      dispatch(corporateAddOrUpdate(admin, state));
    }, []);
  
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      })
    };
  
   
  
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="16px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Corporate View 
            </Text>
          </CardHeader>
          <CardBody>
            {loading ? <Stack>
              <Skeleton height='20px' />
              <Skeleton height='20px' />
              <Skeleton height='20px' />
            </Stack>
              :
              <TableContainer>
                <Table variant='simple'>
                  <Tbody>
                    <Tr>
                      <Td>Name</Td>
                      <Td>{name}</Td>
                    </Tr>
                    <Tr>
                      <Td>Email</Td>
                      <Td>{email}</Td>
                    </Tr>
                    <Tr>
                      <Td>Contact Number</Td>
                      <Td>{contactNo}</Td>
                    </Tr>
                    {/* <Tr>
                      <Td>Secoundary contact No</Td>
                      <Td>{secoundarycontactNo}</Td>
                    </Tr> */}
                    <Tr>
                      <Td>Status</Td>
                      <Td>{status.toString()}</Td>
                    </Tr>
                    
                    {/* <Tr>
                      <Td>Start Date</Td>
                      <Td>{startDate}</Td>
                    </Tr>
                    <Tr>
                      <Td>End Date</Td>
                      <Td>{endDate}</Td>
                    </Tr>
                     */}
                     <Tr>
                      <Td>Mapped Plant</Td>
                      <Td>{mappedPlant ? (mappedPlant.map((row, index, arr) => <Text>{index+1} {row.label}</Text>

                )) : (<h1></h1>)}</Td>
                    </Tr>
                  
                  </Tbody>
                </Table>
              </TableContainer>}
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default CorporateView;
  