// Chakra imports
import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Input,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { factoryAddOrUpdate, factoryClearShowSnackbar } from '../../actions/FactoryActions';
import { useToast } from '@chakra-ui/react'
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { FACTORY_CLEAR_SNACKBAR } from "constants/types";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'

function FactoryView() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();
  const toast = useToast();

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.factory.status);
  const responseMessage = useSelector(state => state.factory.text);
  const loading = useSelector(state => state.factory.loading);
  const factoryDetail = useSelector(state => state.factory.factoryDetail);
  const showSnackbar = useSelector(state => state.factory.showSnackbar);

  

  const params = useParams();
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        console.log(factoryDetail);
        setName(factoryDetail.name);
        setAddress(factoryDetail.address);
        setNumber(factoryDetail.number);
        setEmail(factoryDetail.email);
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }

    dispatch({
      type: FACTORY_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  useEffect(() => {
    console.log("params");
    const factoryId = params.id;
    const factory = {
      _id : factoryId
    };
    dispatch(factoryAddOrUpdate(factory,'view'));
  }, []);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Factory Form
          </Text>
        </CardHeader>
        <CardBody>
          {loading ? <Stack>
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
          </Stack>
            :
            <TableContainer>
              <Table variant='simple'>
                <Tbody>
                  <Tr>
                    <Td> Factory Name</Td>
                    <Td>{name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Factory Address</Td>
                    <Td><Flex direction="column">
                      <Text fontSize="md" color={textColor} fontWeight="bold">
                      <Image
                          boxSize='100px'
                          objectFit='cover'
                          src={address}
                          alt='Dan Abramov'
                        />
                        
                      </Text>
                    </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Number</Td>
                    <Td>{number}</Td>
                  </Tr>
                  <Tr>
                    <Td>Email</Td>
                    <Td>{email}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default FactoryView;