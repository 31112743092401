import React, { useEffect, useState,useRef } from 'react';
import { Flex, InputGroup,Button, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
// import DevicePagination from './DevicePagination';
import { AddIcon } from '@chakra-ui/icons';
import { RAISED_GATE_PASS_CLEAR_SNACKBAR , RAISED_GATE_PASS_SEARCH } from 'constants/types';
// import {  } from '../../actions/devicesAction'; // Assuming you have a DeviceAction file
// import DeviceTableRow from './DevicesTableRow';

// import DevicesPagination from './DevicesPagination';
// import materialGatePassPagination from './materialGatePassPagination'
import RaisedGatePassTableRow from './RaisedGatePassTableRow';
import { raisedGatePassGetAll } from '../../actions/raisedGatePassAction';
// import MaterialGatePassPagination from './MaterialGatePassPagination';

import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
import RaisedGatePassPagination from './RaisedGatePassPagination';
import RaisedGatePassAdvanceSearch from './RaisedGatePassAdvanceSearch';
import { Skeleton, SkeletonCircle, SkeletonText,Stack } from '@chakra-ui/react'
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
const RaisedGatePassTable = (props) => {
  const { url = "", plant } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.raisedGatePass.status);
  const responseMessage = useSelector(state => state.raisedGatePass.text);
  const loading = useSelector(state => state.raisedGatePass.loading);
  const raisedGatePassList = useSelector(state => state.raisedGatePass.raisedGatePassList || []);
  const response = useSelector(state => state.raisedGatePass.response);
  const showSnackbar = useSelector(state => state.raisedGatePass.showSnackbar);
  const currentPage = useSelector(state => state.raisedGatePass.page);
  const totalItems = useSelector(state => state.raisedGatePass.totalItems);
  const limit = useSelector(state => state.raisedGatePass.limit);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [submittingLoader, setSubmittingLoader] = useState(false);
  const history = useHistory();
  const tableRef = useRef(null);
  console.log("raisedGatePassList");
  console.log(raisedGatePassList)
  useEffect(() => {
    // dispatch(raisedGatePassGetAll);
    dispatch(raisedGatePassGetAll(("&status=RGP&plant=" + plant)))
    dispatch({
      type : RAISED_GATE_PASS_SEARCH,
      payload : {
        searching : ""
      }
    })
  }, [dispatch]);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: RAISED_GATE_PASS_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&status=RGP&sortOrder=" + sortOrder + "&column=" + column;
    dispatch({
      type : RAISED_GATE_PASS_SEARCH,
      payload : {
        searching : ""
      }
    })
    dispatch(raisedGatePassGetAll(sorting));
  };

  useEffect(() => {
    const searching = "&status=RGP&search=" + search;
    dispatch({
      type : RAISED_GATE_PASS_SEARCH,
      payload : {
        searching : ""
      }
    })
    dispatch(raisedGatePassGetAll(searching));
  }, [search]);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])

  // Function to export table data to Excel
  const exportToExcel = async () => {
    setSubmittingLoader(true);
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Sheet 1');

    // Define the headers
    const headers = [
      "SNO", "Date", "RGP Number", "Transaction Number", 
      "Expected Date of Return", "Vendor", "Material Photo", 
      "Description", "Return Quantity", "Pending Quantity", 
      "Gate Pass Status", "Department", "Sub Department", "Logs"
    ];

    // Set up the columns with specific widths
    sheet.columns = [
      { header: 'SNO', key: 'sno', width: 10 },
      { header: 'Date', key: 'date', width: 15 },
      { header: 'RGP Number', key: 'rgpNumber', width: 20 },
      { header: 'Transaction Number', key: 'txnNumber', width: 25 },
      { header: 'Expected Date of Return', key: 'expectedReturnDate', width: 25 },
      { header: 'Vendor', key: 'vendor', width: 20 },
      { header: 'Material Photo', key: 'materialPhoto', width: 20 },
      { header: 'Description', key: 'description', width: 30 },
      { header: 'Return Quantity', key: 'returnQuantity', width: 20 },
      { header: 'Pending Quantity', key: 'pendingQuantity', width: 20 },
      { header: 'Gate Pass Status', key: 'gatePassStatus', width: 20 },
      { header: 'Department', key: 'department', width: 20 },
      { header: 'Sub Department', key: 'subDepartment', width: 20 },
      { header: 'Logs', key: 'logs', width: 30 }
    ];

    // Add the headers to the sheet
    headers.forEach((header, index) => {
      sheet.getCell(1, index + 1).value = header;
    });

    // Add data and images
    for (const [rowIndex, row] of raisedGatePassList.entries()) {
      const rowData = [
        totalItems - (((currentPage - 1) * limit) + rowIndex),
        row.date,
        row.rgpNumber,
        row.txnNumber,
        row.returnDate,
        row.raisedMaterial?.vendor,
        row.raisedMaterial.photos[0], // Image URL
        row.raisedMaterial?.materialDescription +"-"+ row.raisedMaterial?.quantity+" "+ row.raisedMaterial?.oum,
        row.returnQuantity,
        row.pendingQuantity,
        row.gatepassStatus,
        row.department ? row.department.name : '',
        row.subdepartment ? row.subdepartment.name : '',
        row.logs[0]['description']
      ];

      for (const [colIndex, cell] of rowData.entries()) {
        if (colIndex === 6 && cell.startsWith('http')) { // Image column
          const base64Image = row.imageBase64Single;
          const imageId = workbook.addImage({
            base64: base64Image,
            extension: 'jpeg' // Change if your image is PNG
          });
          sheet.addImage(imageId, {
            tl: { col: colIndex, row: rowIndex + 1 }, // Top-left corner
            ext: { width: 60, height: 60 } // Resize image
          });
        } else if (colIndex === 13){
          var logData = "";
          for(const listLog of row.logs )
            {
              logData += listLog['description'] + "\n";
            }

            sheet.getCell(rowIndex + 2, colIndex + 1).value = logData;
          
        }else {
          sheet.getCell(rowIndex + 2, colIndex + 1).value = cell;
        }
      }
    }

    // Set row height (if needed)
    for (let rowIndex = 1; rowIndex <= sheet.rowCount; rowIndex++) {
      sheet.getRow(rowIndex).height = 60; // Set height in points
    }

    // Generate Excel file and save
    const excelBuffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([excelBuffer]), `Raised(RGP).xlsx`);
    setSubmittingLoader(false);
};

  return (
    <>
    <RaisedGatePassAdvanceSearch></RaisedGatePassAdvanceSearch>
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Raised (RGP)
            </Text>
          </CardHeader>
          <Spacer />
          {/* <Flex justify="flex-end" style={{ padding: '10px' ,marginTop : '-5px' ,marginRight :'12px' }}> 
            <Link href={`#/admin/patrolling-route-form`}>
            <IconButton
              colorScheme="blue"
              aria-label="Search database"
              icon={<AddIcon />}
            />
          </Link>
          </Flex> */}
          <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            {/* <DownloadTableExcel
              filename="Material Gate Pass Table"
              sheet="users"
              currentTableRef={tableRef.current}
            > */}
              <Button
              isLoading={submittingLoader}
              loadingText='Downloading'
               onClick={exportToExcel}
              colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>
                Export Excel
              </Button>
            {/* </DownloadTableExcel> */}
          </Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              <InputGroup borderRadius="8px" w="200px">
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon
                          color={searchIconColor}
                          w="15px"
                          h="15px"
                        />
                      }
                    ></IconButton>
                  }
                />
                <Input
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.length < 3) {
                      setSearch("");
                    }
                  }}
                  placeholder="Type here..."
                />
              </InputGroup>
            </Text>
          </CardHeader>
        </Flex>
        <CardBody pb={4}>
          <div style={{ overflowX: "auto" }}>
          
            {loading? <Stack>
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                  </Stack>:<Table className='vertical-scroll' variant="simple" color={textColor} ref={tableRef}>
              <Thead className='stick-header'>
                <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                    pl="15px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                   SNO
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                    Date
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    RGP Number
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    Transaction Number
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>

                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    Expected Date of Return 
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    Vendor
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    Material Photo
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    Description
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    Return Quantity
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    Pending Quantity
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  {/* <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    currentStatus
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th> */}
                  
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    gate pass Status
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>

                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    Department
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    sub department
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    Logs
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th></Th>

                </Tr>
              </Thead>
              <Tbody>
                {raisedGatePassList ? (
                  raisedGatePassList.map((row, index, arr) => {
                    
                    return (
                      <RaisedGatePassTableRow
                       serialnumber={totalItems - (((currentPage - 1) * limit) + index)}
                        passType={row.passType}
                        date={row.date}
                        mode={row.mode}
                        status={row.status}
                        returnDate={row.returnDate}
                        raisedMaterial={row.raisedMaterial}
                        currentStatus={row.currentStatus}
                        gatepassNumber={row.gatepassNumber}
                        gatepassStatus={row.gatepassStatus}
                        guardStatus={row.guardStatus}
                        department={row.department ? row.department.name : <></>}
                        subdepartment={row.subdepartment ? row.subdepartment.name : <></>}
                        totalTime={row.totalTime}
                        txn={row.txnNumber}
                        logs={row.logs}
                        isLast={index === arr.length - 1 ? true : false}
                        id={row._id}
                        key={row._id}
                        returnQuantity={row.returnQuantity}
                        pendingQuantity={row.pendingQuantity}
                      />
                    );
                    
                  })
                ) : (
                  <h1></h1>
                )}

              </Tbody>
            </Table>}
          </div>
          {raisedGatePassList.length === 0 ? (
            <></>
          ) : (
            <RaisedGatePassPagination
              totalPages={response.totalPages}
              currentPage={response.currentPage}
              perPage={response.perPage}
              sortBy={sortColumn}
              sortOrder={sortOrder}
              handlePageClick={(data) => {
                const selectedPage = data.selected + 1;
                dispatch(raisedGatePassGetAll(`&status=RGP&page=${selectedPage}`));
              }}
            />
          )}
        </CardBody>
      </Card>
    </Flex>
    </>
  );
};

export default RaisedGatePassTable;
