import {
    BANNER_POSITION_LOADING,
    BANNER_POSITION_GET_ALL_SUCCESS,
    BANNER_POSITION_ERROR,
    BANNER_POSITION_CLEAR_SNACKBAR
  } from '../constants/types';
  
  const initialState = {
    response: {},
    bannerPositionList: [],
    limit : 0,
    page : 0,
    totalPages : 0,
    bannerPositionDetail: {},
    loading: false,
    status: "success",
    text: "",
    showSnackbar: false
  };
  
  const bannerPositionReducer = (state = initialState, action) => {
    switch (action.type) {
      case BANNER_POSITION_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case BANNER_POSITION_LOADING:
        return {
          ...state,
          loading: true,
        };
      case BANNER_POSITION_ERROR:
        return {
          ...state,
          bannerPositionList: [],
          limit : 0,
          page : 0,
          totalPages : 0,
          response: {},
          bannerPositionDetail: {},
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case BANNER_POSITION_GET_ALL_SUCCESS:
        return {
          ...state,
          bannerPositionList: action.payload.bannerPositionList,
          response: action.payload.response,
          bannerPositionDetail: action.payload.bannerPositionDetail,
          limit: action.payload.limit,
          page: action.payload.page,
          totalPages: action.payload.totalPages,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      default:
        return state || {};
    }
  };
  
  export default bannerPositionReducer;
  