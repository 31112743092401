import { useRef } from 'react';
import React, { useEffect, useState } from "react";
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { SCHEDULE_HIGH_ALERT_CLEAR_SNACKBAR } from "constants/types";
import { Table, Tbody, Td, Tr, TableContainer } from "@chakra-ui/react";
import { Skeleton, SkeletonText } from "@chakra-ui/react";
import { schedulehighAlertAddOrUpdate } from "actions/schedulehighalertAction";
import axios from "axios";
import { server_url } from "constants/types";
import { useHistory } from "react-router-dom";
import QRCode from 'react-qr-code';
import { toPng } from 'html-to-image';
import {
  Button,
} from "@chakra-ui/react";
function scheduleHighAlertView() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name, setName] = useState("");
  const [scan, setScan] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [status, setStatus] = useState(""); // Initialize status as a string
  const [patrollingRouteName, setPatrollingRouteName] = useState("");
  const [patrollingDescription, setPatrollingDescription] = useState("");
  const [time, setTime] = useState("");
  const [plantName, setPlantName] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const toast = useToast();

  const responseStatus = useSelector((state) => state.SchedulehighAlert.status);
  const responseMessage = useSelector((state) => state.SchedulehighAlert.text);
  const loading = useSelector((state) => state.SchedulehighAlert.loading);
  const showSnackbar = useSelector((state) => state.SchedulehighAlert.showSnackbar);
  const scheduleHighAlertReducerDetail = useSelector((state) => state.SchedulehighAlert.schedulehighAlertDetail);
  const cancelRef = React.useRef();
  const qrCodeRef = useRef();
  const params = useParams();

  console.log("scheduleHighAlertReducerDetail");
  console.log(scheduleHighAlertReducerDetail);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === "OK" && loading === false) {
        setName(scheduleHighAlertReducerDetail.plantAdminName);
        // setScan(scheduleHighAlertReducerDetail.qrCode);
        // setDescription(scheduleHighAlertReducerDetail.description);
        setStatus(scheduleHighAlertReducerDetail.status ? "Active" : "Inactive");
        //setPatrollingRouteName(scheduleHighAlertReducerDetail.patrollingRoute.name);
        //setPatrollingDescription(scheduleHighAlertReducerDetail.patrollingRoute.description);
        //setTime(scheduleHighAlertReducerDetail.time);
        // setPlantName(scheduleHighAlertReducerDetail.plant.name);
        setStartDate(scheduleHighAlertReducerDetail.startDate);
        setEndDate(scheduleHighAlertReducerDetail.endDate);

        if (responseMessage !== "") {
          toastFunction(responseMessage, "success");
        }
      } else if (responseStatus === "NOK" && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, "error");
        }
      }
    }

    dispatch({
      type: SCHEDULE_HIGH_ALERT_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      },
    });
  }, [loading]);

  useEffect(() => {
    const ScheduleHighAlertReducerId = params.id;
    const ScheduleHighAlertReducer = {
      _id: ScheduleHighAlertReducerId,
    };
    dispatch(schedulehighAlertAddOrUpdate(ScheduleHighAlertReducer, "view"));
  }, [params.id]); // Include params.id as a dependency

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
      })
      .then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push("/auth/signin");
      });
  }, []);


  const handleDownloadQRCode = async () => {
    try {
      const svgString = qrCodeRef.current.querySelector('svg').outerHTML;
      const dataUrl = await toPng(qrCodeRef.current);

      const a = document.createElement('a');
      a.href = dataUrl;
      a.download = `${name}.png`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading QR code:', error);
    }
  };
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
          Schedule-High-Alert View
          </Text>
        </CardHeader>
        <CardBody>
          {loading ? (
            <SkeletonText noOfLines={3} spacing="4" />
          ) : (
            <TableContainer>
              <Table variant="simple">
                <Tbody>
                  <Tr>
                    <Td>Plant Admin Name</Td>
                    <Td>{name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Start Date</Td>
                    <Td>{startDate}</Td>
                  </Tr>
                  <Tr>
                    <Td>End Date</Td>
                    <Td>{endDate}</Td>
                  </Tr>
                  <Tr>
                    <Td>Status</Td>
                    <Td>{status}</Td>
                  </Tr>

                </Tbody>
              </Table>
            </TableContainer>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default scheduleHighAlertView;
