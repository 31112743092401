import axios from 'axios';
import { 
    USER_LOADING, 
    USER_GET_ALL_SUCCESS, 
    USER_ERROR,
    USER_CLEAR_SNACKBAR
} from '../constants/types';

import { 
    server_url 
} from '../constants/types';



export const userGetAll = (pagination="") => {
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            dispatch({
                type: USER_LOADING,
                payload: {
                    loading: true
                }
            });
            axios.get(`${server_url}/user?${pagination}`, {headers: { 
                'Authorization': 'Bearer '+localStorage.getItem('authToken')
              }}).then(response => {
                console.log('response');
                console.log(response);
                dispatch({
                    type: USER_GET_ALL_SUCCESS,
                    payload: {
                        userList: response.data.data,
                        response: response.data,
                        loading: false,
                        status: "success",
                        text: "Get All user data successfully."
                    }
                });
                resolve();
            }).catch((e) => {
                dispatch({
                    type: USER_ERROR,
                    payload: {
                        status: "error",
                        text: "Error occured during getting user data.",
                        loading: false
                    }
                });
                resolve();
            })
        })
    }
}

export const userDelete = (user) => {
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            dispatch({
                type: USER_LOADING,
                payload: {
                    loading: true
                }
            });
            axios.delete(`${server_url}/user/${user._id}`, {headers: { 
                'Authorization': 'Bearer '+localStorage.getItem('authToken')
              }}).then(responseF => {
                axios.get(`${server_url}/user`, {headers: { 
                    'Authorization': 'Bearer '+localStorage.getItem('authToken')
                  }}).then(response => {
                    dispatch({
                        type: USER_GET_ALL_SUCCESS,
                        payload: {
                            userList: response.data.data,
                            response: response.data,
                            loading: false,
                            status: responseF.data['status'],
                            text: responseF.data['message']
                        }
                    });
                    resolve();
                }).catch((e) => {
                    dispatch({
                        type: USER_ERROR,
                        payload: {
                            text: "Error occured during getting user data.",
                            status: "error",
                            loading: false
                        }
                    });
                    resolve();
                })
            }).catch((e) => {
                dispatch({
                    type: USER_ERROR,
                    payload: {
                        text: "Error occured during deleteing user data.",
                        status: "error",
                        loading: false
                    }
                });
                resolve();
            })
        })
    }
}

export const userAddOrUpdate = (user, state) => {
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            dispatch({
                type: USER_LOADING,
                payload: {
                    loading: true
                }
            });
            if (state === "add") {                
                axios.post(`${server_url}/user`, user,{headers: { 
                    'Authorization': 'Bearer '+localStorage.getItem('authToken')
                  }}).then(responseF => {
                    axios.get(`${server_url}/user`,{headers: { 
                        'Authorization': 'Bearer '+localStorage.getItem('authToken')
                      }})
                    .then(response => {
                        dispatch({
                            type: USER_GET_ALL_SUCCESS,
                            payload: {
                                userList: response.data.data,
                                response: response.data,
                                loading: false,
                                status: responseF.data['status'],
                                text: responseF.data['message']
                            }
                        });
                        resolve();
                    }).catch((e) => {
                        dispatch({
                            type: USER_ERROR,
                            payload: {
                                text: "Error occured during getting user data.",
                                status: "error",
                                loading: false
                            }
                        });
                        resolve();
                    })
                }).catch((e) => {
                    dispatch({
                        type: USER_ERROR,
                        payload: {
                            text: "Error occured during adding user data.",
                            status: "error",
                            loading: false
                        }
                    });
                    resolve();
                })
            } else if (state === "edit") {                
                axios.put(`${server_url}/user/${user._id}`, user,{headers: { 
                    'Authorization': 'Bearer '+localStorage.getItem('authToken')
                  }}).then(responseF => {
                    axios.get(`${server_url}/user`,{headers: { 
                        'Authorization': 'Bearer '+localStorage.getItem('authToken')
                      }})
                    .then(response => {
                        dispatch({
                            type: USER_GET_ALL_SUCCESS,
                            payload: {
                                userList : response.data.data,
                                response: response.data,
                                userDetail: responseF.data['data'],
                                loading: false,
                                status: responseF.data['status'],
                                text: responseF.data['message']
                            }
                        });
                        resolve();
                    }).catch((e) => {
                        dispatch({
                            type: USER_ERROR,
                            payload: {
                                text: "Error occured during getting user data.",
                                status: "error",
                                loading: false
                            }
                        });
                        resolve();
                    })
                }).catch((e) => {
                    dispatch({
                        type: USER_ERROR,
                        payload: {
                            text: "Error occured during updating user data.",
                            status: "error",
                            loading: false
                        }
                    });
                    resolve();
                })
            }
        })
    }
}

export const userClearShowSnackbar = () => {
    return (dispatch, getState) => {
        dispatch({
            type: USER_CLEAR_SNACKBAR
        });
    }
}