import React from "react";
import {
  Avatar,
  Badge,
  Button,
  Flex,
  Link,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure,
  ButtonGroup,
  IconButton,
 
} from "@chakra-ui/react";
import { BsBoxArrowUpRight, BsFillTrashFill } from "react-icons/bs";
import { ViewIcon } from "@chakra-ui/icons";
import { AiFillEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
// import { deviceDelete } from "actions/devicesAction"; // Make sure you have the correct import for deviceDelete
import { outPassDelete } from "actions/outPassAction";

const OutPassTableRow = (props) => {
  const {
    guardCheckpost,
    outByGuardName,
    employeeName,
    department,
    subdepartment,
serialnumber,
    raisedByEmpName,
    raisedTime,

    authorisedEmployeeName,
    authorisedTime,
   
    reason,
    desc,
    duration,
    isLast,
    id,
    key,
  } = props;

  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const outPassDetail = useSelector((state) => state.outPass.outPassDetail); // Make sure you have the correct state slice for device detail
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const dispatch = useDispatch();

  const handleDelete = () => {
    const outPass = {
      _id: id,
    };
    dispatch(outPassDelete(outPass._id)); // Use your deviceDelete action
  };
//   const authDetail = useSelector((state) => state.auth.authDetail);
  return (
    <>
      <Tr>
      <Td
          >
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={titleColor}
                  fontWeight="bold"
                  minWidth="100%"
                >
                  {serialnumber}
                </Text>
              </Flex>
            </Flex>
          </Td>
      <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {guardCheckpost}
              </Text>
            </Flex>
          </Flex>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {outByGuardName}
              </Text>
            </Flex>
          </Flex>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {employeeName}
              </Text>
            </Flex>
          </Flex>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {department}
              </Text>
            </Flex>
          </Flex>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {subdepartment}
              </Text>
            </Flex>
          </Flex>
        </Td>
        
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {raisedByEmpName}
              </Text>
            </Flex>
          </Flex>
        </Td>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {raisedTime}
              </Text>
            </Flex>
          </Flex>
        </Td>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {authorisedEmployeeName}
              </Text>
            </Flex>
          </Flex>
        </Td>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {authorisedTime}
              </Text>
            </Flex>
          </Flex>
        </Td>


        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {reason}
              </Text>
            </Flex>
          </Flex>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {desc}
              </Text>
            </Flex>
          </Flex>
        </Td>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {duration}
              </Text>
            </Flex>
          </Flex>
        </Td>

        <Td pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis">
          <ButtonGroup>
            <Link href={`#/admin/outPass-view-form/${id}`}>
              <IconButton
                colorScheme="blue"
                icon={<ViewIcon />}
                aria-label="Up"
              />
            </Link>

            {/* <Link href={`#/admin/outPass-update-form/${id}`}>
              <IconButton
                colorScheme="green"
                icon={<AiFillEdit />}
                aria-label="Edit"
              />
            </Link> */}

            
{/* 
            <IconButton
              colorScheme="red"
              variant="outline"
              icon={<BsFillTrashFill />}
              aria-label="Delete"
              onClick={onOpen}
            /> */}
          </ButtonGroup>
        </Td>

        
        

        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Device
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={() => { handleDelete(); onClose(); }} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Tr>
    </>
  );
};

export default OutPassTableRow;
