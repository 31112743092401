import axios from 'axios';
import {
  EMERGENCY_CONTACT_LOADING,
  EMERGENCY_CONTACT_GET_ALL_SUCCESS,
  EMERGENCY_CONTACT_ERROR,
  EMERGENCY_CONTACT_CLEAR_SNACKBAR,
} from '../constants/types';

import { server_url } from '../constants/types';

export const emergencyContactGetAll = (pagination = '') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: EMERGENCY_CONTACT_LOADING,
        payload: {
          loading: true,
        },
      });
      axios
        .get(`${server_url}/emergencyContact?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
          },
        })
        .then((response) => {
            console.log("emergency contacts successfully");
            console.log(response.data.data);
          dispatch({
            type: EMERGENCY_CONTACT_GET_ALL_SUCCESS,
            payload: {
              emergencyContactList: response.data.data,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all emergency contacts successfully.',
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: EMERGENCY_CONTACT_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting emergency contact data.',
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};

export const emergencyContactAddOrUpdate = (emergencyContact, state) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: EMERGENCY_CONTACT_LOADING,
        payload: {
          loading: true,
        },
      });
      if (state === 'add') {
        axios
          .post(`${server_url}/emergencyContact`, emergencyContact, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/emergencyContact`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
                },
              })
              .then((response) => {
                dispatch({
                  type: EMERGENCY_CONTACT_GET_ALL_SUCCESS,
                  payload: {
                    emergencyContactList: response.data.data,
                    response: response.data,
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message'],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: EMERGENCY_CONTACT_ERROR,
                  payload: {
                    text: 'Error occurred during getting emergency contact data.',
                    status: 'error',
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: EMERGENCY_CONTACT_ERROR,
              payload: {
                text: 'Error occurred during adding emergency contact data.',
                status: 'error',
                loading: false,
              },
            });
            resolve();
          });
      } else if (state === 'edit') {
        axios
          .put(`${server_url}/emergencyContact/${emergencyContact._id}`, emergencyContact, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/emergencyContact`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
                },
              })
              .then((response) => {
                dispatch({
                  type: EMERGENCY_CONTACT_GET_ALL_SUCCESS,
                  payload: {
                    emergencyContactList: response.data.data,
                    response: response.data,
                    loading: false,
                    status: responseF.data['status'],
                    emergencyContactDetail: responseF.data['data'],
                    text: responseF.data['message'],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: EMERGENCY_CONTACT_ERROR,
                  payload: {
                    text: 'Error occurred during getting emergency contact data.',
                    status: 'error',
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: EMERGENCY_CONTACT_ERROR,
              payload: {
                text: 'Error occurred during updating emergency contact data.',
                status: 'error',
                loading: false,
              },
            });
            resolve();
          });
      } else if (state === 'view') {
        axios
          .get(`${server_url}/emergencyContact/${emergencyContact._id}`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/emergencyContact`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
                },
              })
              .then((response) => {
                dispatch({
                  type: EMERGENCY_CONTACT_GET_ALL_SUCCESS,
                  payload: {
                    emergencyContactList: response.data.data,
                    response: response.data,
                    emergencyContactDetail: responseF.data['data'],
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message'],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: EMERGENCY_CONTACT_ERROR,
                  payload: {
                    text: 'Error occurred during getting emergency contact data.',
                    status: 'error',
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: EMERGENCY_CONTACT_ERROR,
              payload: {
                text: 'Error occurred during updating emergency contact data.',
                status: 'error',
                loading: false,
              },
            });
            resolve();
          });
      }
    });
  };
};

export const emergencyContactDelete = (emergencyContact) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: EMERGENCY_CONTACT_LOADING,
        payload: {
          loading: true,
        },
      });
      axios
        .delete(`${server_url}/emergencyContact/${emergencyContact}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
          },
        })
        .then((responseF) => {
          axios
            .get(`${server_url}/emergencyContact`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
              },
            })
            .then((response) => {
              dispatch({
                type: EMERGENCY_CONTACT_GET_ALL_SUCCESS,
                payload: {
                  emergencyContactList: response.data.data,
                  response: response.data,
                  emergencyContactDetail: responseF.data['data'],
                  loading: false,
                  status: responseF.data['status'],
                  text: responseF.data['message'],
                },
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: EMERGENCY_CONTACT_ERROR,
                payload: {
                  text: 'Error occurred during getting emergency contact data.',
                  status: 'error',
                  loading: false,
                },
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: EMERGENCY_CONTACT_ERROR,
            payload: {
              text: 'Error occurred during deleting emergency contact data.',
              status: 'error',
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};

export const emergencyContactClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: EMERGENCY_CONTACT_CLEAR_SNACKBAR,
    });
  };
};
