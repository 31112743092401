import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Text,

  Wrap,
  WrapItem,
  useColorModeValue,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useDispatch, useSelector } from "react-redux";
import Relect from 'react-select';
import {
  checkpostAddOrUpdate,
  checkpostClearShowSnackbar,
} from "../../actions/checkpostActions";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import Select from 'react-select';
import { useToast } from "@chakra-ui/react";
import { CHECKPOST_CLEAR_SNACKBAR } from "constants/types";
import { Spinner } from "@chakra-ui/react";
import dataConstant from "constants/dataConstant";
import { guardmoduleGetAll } from "../../actions/guardModuleAction";
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';

function CheckpostForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState();
  const [adminId, setAdminId] = useState("");
  const [guardModules, setGuardModules] = useState([]);
  const [homeModule, setHomeModule] = useState([]);
  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorDescription, setIsErrorDescription] = useState(false);
  const [isErrorStatus, setIsErrorStatus] = useState(false);
  const [isErrorGuardModules, setIsErrorGuardModules] = useState(false);
  const [isErrorAdminId, setIsErrorAdminId] = useState(false);
  const [isErrorHomeModule, setIsErrorHomeModule] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();

  const responseStatus = useSelector((state) => state.checkpost.status);
  const responseMessage = useSelector((state) => state.checkpost.text);
  const loading = useSelector((state) => state.checkpost.loading);
  const showSnackbar = useSelector((state) => state.checkpost.showSnackbar);

  const cityList = useSelector(state => state.city.cityList);
  const guardmoduleList = useSelector(state => state.guardmodule.guardmoduleList);
  const history = useHistory();
  console.log(status)
  console.log(guardModules)
  const params = useParams();
  useEffect(() => {
    console.log("yes it is checkpost")
    dispatch(guardmoduleGetAll("limit=1000"));
  }, []);



  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsErrorName(name === "");
    // setIsErrorDescription(description === "");
    setIsErrorStatus(status === "");
    setIsErrorAdminId(adminId === "");
    setIsErrorGuardModules(guardModules.length == 0)
    setIsErrorHomeModule(homeModule === '')

    if (
      name !== "" &&
      status !== "" &&
      // description !== "" &&
      guardModules.length !== 0
    ) {
      console.log("Submit");
      // Get form data
      const checkpost = {
        name: name,
        description: description,
        status: status.value,
        plant: params.id,
        gaurdModule: guardModules.map((module) => module.value),
        homeModule: homeModule.map((module) => module.value),
        // Other checkpost data fields
      };
      console.log("cjeckkkkkkkkkkk");
      console.log(checkpost);

      // Determine if it's an add or edit operation
      const state = "add";
      // Call the checkpostAddOrUpdate action
      dispatch(checkpostAddOrUpdate(checkpost, state));
    }
  };

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === "OK" && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, "success");
          history.goBack();
        }
      } else if (responseStatus === "NOK" && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, "error");
        }
      }
    }
    dispatch(checkpostClearShowSnackbar());
  }, [loading, showSnackbar]);

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Create Checkpost
          </Text>
          <SimpleGrid minChildWidth="120px" spacing="40px">
            <FormControl isInvalid={isErrorName}>
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                onChange={(e) => {
                  setIsErrorName(e.target.value === "");
                  setName(e.target.value);
                }}
              />
              {!isErrorName ? (
                <></>
              ) : (
                <FormErrorMessage>Name is required.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={isErrorDescription}>
              <FormLabel>Description</FormLabel>
              <Input
                type="text"
                onChange={(e) => {
                  setIsErrorDescription(e.target.value === "");
                  setDescription(e.target.value);
                }}
              />
              {!isErrorDescription ? (
                <></>
              ) : (
                <FormErrorMessage>Description is required.</FormErrorMessage>
              )}

            </FormControl>
          </SimpleGrid>
          <SimpleGrid minChildWidth="120px" spacing="40px" mt="24px">
            <FormControl isInvalid={isErrorStatus}>
              <FormLabel>Status</FormLabel>
              <Select
                placeholder="Select Status"
                options={dataConstant.status.map((item) => ({
                  value: item['value'],
                  label: item['name']
                }))}
                onChange={(status) => {
                  setIsErrorStatus(status === "");
                  setStatus(status);
                }}

              />


              {!isErrorStatus ? (
                <></>
              ) : (
                <FormErrorMessage>Status is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorGuardModules}>
              <FormLabel>Guard Modules</FormLabel>
              <Select
                isMulti
                name="colors"
                placeholder="--select GuardModules--"
                options={guardmoduleList.map((item) => ({
                  value: item['_id'],
                  label: item['name']
                }))}

                onChange={(value) => {

                  setGuardModules(value)
                  setIsErrorGuardModules(value.length == 0)
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
              {!isErrorGuardModules ? (
                <></>
              ) : (
                <FormErrorMessage>Guard Modules is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl >
              <FormLabel>Home Module</FormLabel>
              <Relect
                isMulti
                name="colors"
                placeholder="--select Home Module--"
                options={dataConstant.homeModule.map((item) => ({
                  value: item.value,
                  label: item.name
                }))}

                onChange={(value) => {
                  setHomeModule(value)
                  setIsErrorHomeModule(value === '')
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
              {isErrorHomeModule && submitted && (
                <FormErrorMessage>Home Module is required.</FormErrorMessage>
              )}
            </FormControl>

          </SimpleGrid>
          <Center>
            {loading ? (
              <Spinner mt="24px" size="lg" />
            ) : (
              <Button colorScheme="blue" mt="24px" onClick={handleSubmit}>
                Submit
              </Button>
            )}
          </Center>
        </CardHeader>
        <CardBody></CardBody>
      </Card>
    </Flex>
  );
}

export default CheckpostForm;
