import axios from 'axios';
import {
  AUTH_LOADING,
  AUTH_GET_ALL_SUCCESS,
  AUTH_ERROR,
  AUTH_CLEAR_SNACKBAR
} from '../constants/types';

import { server_url } from '../constants/types';

export const adminLogin = (AUTH) => {
  return dispatch => {
    dispatch({
      type: AUTH_LOADING,
      payload: {
        loading: true
      }
    });
      axios
        .post(`${server_url}/admin/login`, AUTH)
        .then(response => {
          dispatch({
            type: AUTH_GET_ALL_SUCCESS,
            payload: {
              authDetail: response.data['data'],
              loading: false,
              status: response.data['status'],
              text: response.data['message']
          }
          }); 
        })
        .catch(error => {
          dispatch({
            type: AUTH_ERROR,
            payload: {
              text: "Error occured during getting user data.",
              status: "error",
              loading: false
          }
          });
        });
    
  };
};
/// reset password
export const adminResetPassword = (AUTH) => {
  return dispatch => {
    dispatch({
      type: AUTH_LOADING,
      payload: {
        loading: true
      }
    });
      axios
        .post(`${server_url}/admin/reset/password`, AUTH)
        .then(response => {
          dispatch({
            type: AUTH_GET_ALL_SUCCESS,
            payload: {
              authDetail: response.data['data'],
              loading: false,
              status: response.data['status'],
              text: response.data['message']
          }
          }); 
        })
        .catch(error => {
          dispatch({
            type: AUTH_ERROR,
            payload: {
              text: "Error occured during getting user data.",
              status: "error",
              loading: false
          }
          });
        });
    
  };
};
// Logout
export const adminLogout = () => {
  return dispatch => {
    dispatch({
      type: AUTH_LOADING,
      payload: {
        loading: false
      }
    });
    console.log(localStorage.getItem('authToken'));
      axios
        .get(`${server_url}/admin/logout`,{headers: { 
          'Authorization': 'Bearer '+localStorage.getItem('authToken')
        }})
        .then(response => {
          dispatch({
            type: AUTH_GET_ALL_SUCCESS,
            payload: {
              authDetail: {},
              loading: false,
              status: "",
              text: response.data['message']
          }
          }); 
        })
        .catch(error => {
          dispatch({
            type: AUTH_ERROR,
            payload: {
              text: "Error occured during getting user data.",
              status: "error",
              loading: false
          }
          });
        });
    
  };
};

/// Login details
export const loginPermissionDetail = () => {
  return dispatch => {
    dispatch({
      type: AUTH_LOADING,
      payload: {
        loading: true
      }
    });
      axios
        .get(`${server_url}/admin/detail`,{headers: { 
          'Authorization': 'Bearer '+localStorage.getItem('authToken')
        }})
        .then(response => {
          console.log(" auth detailllllll")
          console.log(response.data['data'])
          dispatch({
            type: AUTH_GET_ALL_SUCCESS,
            payload: {
              authDetail: response.data['data'],
              loading: false,
              status: response.data['status'],
              text: response.data['message']
          }
          }); 
        })
        .catch(error => {
          dispatch({
            type: AUTH_ERROR,
            payload: {
              text: "Error occured during getting auth data.",
              status: "error",
              loading: false
          }
          });
        });
    
  };
};

///change-Password/////////////

export const changePassword = (changepass) => {
  return dispatch => {
    dispatch({
      type: AUTH_LOADING,
      payload: {
        loading: true
      }
    });

    // Make a request to reset the password
    axios.post(`${server_url}/admin/reset-password`, changepass, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      }
    })
      .then(response => {
        dispatch({ 
          type: AUTH_GET_ALL_SUCCESS,
          payload: {    
            authDetail: response.data['data'],     
            loading: false,
            status: response.data.status,
            text: response.data.message
          }
        });
      })
      .catch(error => {
        dispatch({
          type: AUTH_ERROR,
          payload: {
            text: "Error occurred during password change.",
            status: "error",
            loading: false
          }
        });
      });
    };
  };
    


export const authClearShowSnackbar = () => {
  return {
    type: AUTH_CLEAR_SNACKBAR
  };
};

// Group Authentation
export const groupLogin = (AUTH) => {
  return dispatch => {
    dispatch({
      type: AUTH_LOADING,
      payload: {
        loading: true
      }
    });
      axios
        .post(`${server_url}/group/login`, AUTH)
        .then(response => {
          dispatch({
            type: AUTH_GET_ALL_SUCCESS,
            payload: {
              authDetail: response.data['data'],
              loading: false,
              status: response.data['status'],
              text: response.data['message']
          }
          }); 
        })
        .catch(error => {
          dispatch({
            type: AUTH_ERROR,
            payload: {
              text: "Error occured during getting user data.",
              status: "error",
              loading: false
          }
          });
        });
    
  };
};
