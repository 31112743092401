// Chakra imports
import {
  Flex,
  InputGroup,
  Spacer,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  InputLeftElement,
  IconButton,
  Input,
  Icon,
  Link,
  Button
} from "@chakra-ui/react";
import { AddIcon, SearchIcon } from "@chakra-ui/icons";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
//   import Pagination from "components/Tables/Pagination";
import React, { useEffect, useState,useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import {
  busGetAll,
  busDelete,
  busAddOrUpdate,
  busClearShowSnackbar,
} from "../../actions/busAction";
import { BUS_CLEAR_SNACKBAR } from "constants/types";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import BusTableRow from "./BusTableRow";
import Pagination from "./Pagination";
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
import { DownloadTableExcel } from 'react-export-table-to-excel';
function BusTable() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector((state) => state.bus.status);
  const responseMessage = useSelector((state) => state.bus.text);
  const loading = useSelector((state) => state.bus.loading);
  const busList = useSelector((state) => state.bus.busList);
  const response = useSelector((state) => state.bus.response);
  const showSnackbar = useSelector((state) => state.bus.showSnackbar);
  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const tableRef = useRef(null);
  useEffect(() => {
      dispatch(busGetAll());
  }, [dispatch]);
  console.log("responseStatus");
  console.log(responseStatus);

  console.log("busList");
console.log(busList);
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === "success" && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, "success");
        }
      } else if (responseStatus === "error" && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, "error");
        }
      }
    }
    dispatch({
      type: BUS_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      },
    });
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
    dispatch(busGetAll(sorting));
  };

  useEffect(() => {
    const searching = "&search=" + search;
    console.log(searching);
    dispatch(busGetAll(searching));
  }, [search]);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Vehicles
            </Text>
          </CardHeader>
          <Spacer />
          <Flex justify="flex-end" style={{ padding: '10px' ,marginTop : '-5px' ,marginRight :'12px' }}> 
                  {/* Adjust the link to the appropriate form route */}
                  <Link href={`#/admin/bus-form`}>
                    <IconButton
                      colorScheme="blue"
                      aria-label="Search database"
                      icon={<AddIcon />}
                    />
                  </Link>
          </Flex>
          <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            <DownloadTableExcel
              filename="Bus Table"
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <Button colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>
                Export Excel
              </Button>
            </DownloadTableExcel>
          </Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              <InputGroup borderRadius="8px" w="200px">
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon
                          color={searchIconColor}
                          w="15px"
                          h="15px"
                        />
                      }
                    ></IconButton>
                  }
                />
                <Input
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.length < 3) {
                      setSearch("");
                    }
                  }}
                  placeholder="Type here..."
                />
              </InputGroup>
            </Text>
          </CardHeader>
        </Flex>
        <CardBody pb={4}>
        <div style={{ overflowX: "auto" }}>
        
          <Table variant="simple" color={textColor} ref={tableRef}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
              <Th
                    pl="15px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                   SNO
                  </Th>
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("busNo")}
                  cursor="pointer"
                >
                  Bus No
                  {sortColumn === "busNo" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("sittingCapacity")}
                  cursor="pointer"
                >
                  Sitting Capacity
                  {sortColumn === "sittingCapacity" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>

                <Th borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("status")}
                  cursor="pointer">
                  Switch
                  {sortColumn === "status" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("status")}
                  cursor="pointer">
                  Type
                  {sortColumn === "status" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("status")}
                  cursor="pointer">
                  Driver Name
                  {sortColumn === "status" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>

                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("insuranceValidityDate")}
                  cursor="pointer"
                >
                  Insurance Validity Date
                  {sortColumn === "insuranceValidityDate" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th borderColor={borderColor}></Th>
              </Tr>
            </Thead>
            <Tbody>
              {busList ? (
                busList.map((row, index, arr) => (
                  <BusTableRow
                   serialnumber={((response.currentPage - 1)*response.limit) + (index+1) }
                    key={row._id}
                    busNo={row.busNo}
                    sittingCapacity={row.sittingCapacity}
                    insuranceValidityDate={row.insuranceValidityDate}
                    status = {row.status}
                    driverName = {row.driver.Name}
                    type = {row.type}
                    isLast={index === arr.length - 1}
                    id={row._id}
                    
                  />
                ))
              ) : (
                <h1></h1>
              )}
            </Tbody>
          </Table>
          </div>

          {busList.length === 0 ? (
            <></>
          ) : (
            <Pagination
               limit = {response.limit}
              totalPages={response.totalPages}
              currentPage={response.currentPage}
              perPage={response.perPage}
              sortBy={sortColumn}
              sortOrder={sortOrder}
              handlePageClick={(data) => {
                const selectedPage = data.selected + 1;
                dispatch(busGetAll(`&page=${selectedPage}`));
              }}
            />
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default BusTable;
