// Chakra imports
import {
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { patrollingLogsClearShowSnackbar, patrollingLogsAddOrUpdate } from '../../actions/patrollingLogsAction';
import { useToast } from '@chakra-ui/react'
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { PATROLLING_LOGS_CLEAR_SNACKBAR } from "constants/types";
import {
  Skeleton,
  SkeletonCircle,
  SkeletonText,
} from "@chakra-ui/react";
import { locale } from "moment/moment";
// import { patrollingLogsClearShowSnackbar } from "actions/PatrollingLogsAction";
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
function PatrollingLogsView() {

  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [guardName, setGuardName] = useState('');
  const [startDateTime, setStartDateTime] = useState('');
  const [endDateTime, setEndDateTime] = useState('');
  const [routeName, setRouteName] = useState('');
  const [noOfScan, setNoOfScan] = useState(''); // Assuming it's a number
  const [scanLog,setScanLog ] = useState(''); // Assuming it's an array
  const [missedScanLog, setMissedScanLog] = useState('');

  const history = useHistory();
  const dispatch = useDispatch();
  const toast = useToast();

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.patrollingLogs.status);
  const responseMessage = useSelector(state => state.patrollingLogs.text);
  const loading = useSelector(state => state.patrollingLogs.loading);
  // const designationDetail = useSelector(state => state.patrollingLogs.designationDetail);
  const patrollingLogsDetail = useSelector((state) => state.patrollingLogs.patrollingLogsDetail);
  const showSnackbar = useSelector(state => state.patrollingLogs.showSnackbar);
  const patrollingLogs = useSelector(state => state.patrollingLogs.patrollingLogs);
  const jj = useSelector((state) =>state.patrollingLogs )

  
  const params = useParams();
console.log("patrollingLogsDetail123")
console.log(patrollingLogsDetail)

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
     
       setGuardName(patrollingLogsDetail.guard ? patrollingLogsDetail.guard.name : "")
       setStartDateTime(patrollingLogsDetail.startDateTime)
       setEndDateTime(patrollingLogsDetail.endDateTime)
       setRouteName(patrollingLogsDetail.route ? patrollingLogsDetail.route.name : "")
       setNoOfScan(patrollingLogsDetail.noOfScan)
       setScanLog(patrollingLogsDetail.scanLog)
       setMissedScanLog(patrollingLogsDetail.missedScanLog)
        
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
      dispatch({
        type: PATROLLING_LOGS_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        }
      });
    }
  }, [loading]);

  useEffect(() => {
    const patrollingLogsId = params.id;
    const patrollingLogs = {
      _id: patrollingLogsId,
    };
    dispatch(patrollingLogsAddOrUpdate(patrollingLogs, 'view'));
  }, []);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };


  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Patrolling Logs View
          </Text>
        </CardHeader>
        <CardBody>
          {loading ? (
            <Skeleton height="20px" />
          ) : (
            <TableContainer>
              <Table variant="simple">
                <Tbody>
                  <Tr>
                    <Td>Guard Name</Td>
                    <Td>{guardName}</Td>
                  </Tr>
                  <Tr>
                    <Td>Start-Date-Time</Td>
                    <Td>{startDateTime}</Td>
                  </Tr>
                  <Tr>
                    <Td>End-Date-Time</Td>
                    <Td>{endDateTime}</Td>
                  </Tr>
                  <Tr>
                    <Td>Route-Name</Td>
                    <Td>{routeName}</Td>
                  </Tr>
                  <Tr>
                    <Td>No-Of-Scan</Td>
                    <Td>{noOfScan}</Td>
                  </Tr>
                  <Tr>
                    <Td>Scan-Log</Td>
                    <Td>
                    {scanLog && scanLog.length > 0 ? (
                      scanLog.map((obj, index) => (
                        <div key={index}>
                          <Tr>
                            <Td>Floor Name : {obj.floorName}</Td>
                            <Td>Date Time : {obj.dateTime}</Td>
                          </Tr>
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                    </Td>


                  </Tr>
                  <Tr>
                    <Td>Missed-Scan-Log</Td>
                    <Td>{missedScanLog}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </CardBody>
      </Card>
    </Flex>
  )};

  export default PatrollingLogsView;
  