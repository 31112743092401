import axios from 'axios';
import {
  DEPARTMENT_LOADING,
  DEPARTMENT_GET_ALL_SUCCESS,
  DEPARTMENT_ERROR,
  DEPARTMENT_CLEAR_SNACKBAR
} from '../constants/types';

import { server_url } from '../constants/types';

export const departmentGetAll = (pagination = '') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: DEPARTMENT_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/department?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {

          dispatch({
            type: DEPARTMENT_GET_ALL_SUCCESS,
            payload: {
              departmentList: response.data.data,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all department data successfully.',
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: DEPARTMENT_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting department data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const departmentAddOrUpdate = (department, state) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: DEPARTMENT_LOADING,
        payload: {
          loading: true
        }
      });
      if (state === 'add') {
        axios
          .post(`${server_url}/department`, department, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/department`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {

                dispatch({
                  type: DEPARTMENT_GET_ALL_SUCCESS,
                  payload: {
                    departmentList: response.data.data,
                    response: response.data,
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message'],
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: DEPARTMENT_ERROR,
                  payload: {
                    text: 'Error occurred during getting department data.',
                    status: 'error',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: DEPARTMENT_ERROR,
              payload: {
                text: 'Error occurred during adding department data.',
                status: 'error',
                loading: false
              }
            });
            resolve();
          });
      } else if (state === 'edit') {
        axios
          .put(`${server_url}/department/${department._id}`, department, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/department`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {
                console.log("departmentDetail reset");
                console.log(responseF.data['data']);
                dispatch({
                  type: DEPARTMENT_GET_ALL_SUCCESS,
                  payload: {
                    departmentList: response.data.data,
                    response: response.data,
                    loading: false,
                    status: responseF.data['status'],
                    departmentDetail: responseF.data['data'],
                    text: responseF.data['message'],
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: DEPARTMENT_ERROR,
                  payload: {
                    text: 'Error occurred during getting department data.',
                    status: 'error',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: DEPARTMENT_ERROR,
              payload: {
                text: 'Error occurred during updating department data.',
                status: 'error',
                loading: false
              }
            });
            resolve();
          });
        } else if (state == 'view') {
          const authToken = localStorage.getItem('authToken');
          console.log("authToken2222")
          console.log(authToken)
          axios
            .get(`${server_url}/department/${department._id}`, {
              headers: {
                'Authorization': 'Bearer ' + authToken
              }
            })
            .then((responseF) => {
  
              axios
                .get(`${server_url}/department`, {
                  headers: {
                    'Authorization': 'Bearer ' + authToken
                  }
                })
                .then((response) => {
                  dispatch({
                    type: DEPARTMENT_GET_ALL_SUCCESS,
                    payload: {
                      departmentList: response.data.data,
                      response: response.data,
                      loading: false,
                      status: responseF.data['status'],
                      departmentDetail: responseF.data['data'],
                      text: responseF.data['message'],
                      limit: response.data.limit,
                      page: response.data.currentPage,
                      totalPages: response.data.totalPages,
                    }
                  });
                  resolve();
                })
                .catch((e) => {
                  dispatch({
                    type: DEPARTMENT_ERROR,
                    payload: {
                      text: 'Error occurred during getting department data.',
                      status: 'error',
                      loading: false
                    }
                  });
                  resolve();
                });
            })
            .catch((e) => {
              dispatch({
                type: DEPARTMENT_ERROR,
                payload: {
                  text: 'Error occurred during updating department data.',
                  status: 'error',
                  loading: false
                }
              });
              resolve();
            });
        }
    });
  };
};

export const departmentDelete = (department,plant="") => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: DEPARTMENT_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .delete(`${server_url}/department/${department}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((responseF) => {
          axios
            .get(`${server_url}/department?plant=${plant}`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
            .then((response) => {
              dispatch({
                type: DEPARTMENT_GET_ALL_SUCCESS,
                payload: {
                  departmentList: response.data.data,
                  response: response.data,
                  departmentDetail: responseF.data['data'],
                  loading: false,
                  status: responseF.data['status'],
                  text: responseF.data['message'],
                  limit: response.data.limit,
                  page: response.data.currentPage,
                  totalPages: response.data.totalPages,
                }
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: DEPARTMENT_ERROR,
                payload: {
                  text: 'Error occurred during getting department data.',
                  status: 'error',
                  loading: false
                }
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: DEPARTMENT_ERROR,
            payload: {
              text: 'Error occurred during deleting department data.',
              status: 'error',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const departmentClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: DEPARTMENT_CLEAR_SNACKBAR
    });
  };
};
