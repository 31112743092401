import React, { useEffect, useState } from 'react';
import {
  Flex,
  Input,
  SimpleGrid,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Button,
  Center,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import { SCHEDULE_HIGH_ALERT_CLEAR_SNACKBAR } from 'constants/types';
import { Spinner } from '@chakra-ui/react';
import dataConstant from 'constants/dataConstant';
import { useParams, useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import { schedulehighAlertAddOrUpdate } from 'actions/FloorAction'; // Import your Floor action
import { schedulehighAlertAddOrUpdate } from 'actions/schedulehighalertAction'
import { patrollingRouteGetAll } from '../../actions/PatrollingRouteAction'
import axios from 'axios';
import { server_url } from "constants/types";
import { checkpostGetAll } from 'actions/checkpostActions';
import RSelect from 'react-select';

function ScheduleHighAlertForm() {
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [startDate, setStartDate] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('');
  const [route, setRoute] = useState('');
  const [endDate, setEndDate] = useState('');
  const [duration, setDuration] = useState('');
  const [popupDuration, setPopupDuration] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [alternateContactNumber, setAlternateContactNumber] = useState('');
  const [isErrorStartDate, setIsErrorStartDate] = useState(false);
  const [isErrorDescription, setIsErrorDescription] = useState(false);
  const [isErrorStatus, setIsErrorStatus] = useState(false);
  const [isErrorRoute, setIsErrorRoute] = useState(false);
  const [isErrorEndDate, setIsErrorEndDate] = useState(false);
  const [isErrorCheckpost , setIsErrorCheckpost] = useState(false);
  const [checkpost , setCheckpost] = useState(false);
  const [isErrorAlternateContactNumber , setIsErrorAlternateContactNumber] = useState(false);
  const [isErrorContactNumber , setIsErrorContactNumber] = useState(false);
  const [isErrorpopupDuration , setIsErrorpopupDuration] = useState(false);
  const [isErrorDuration , setIsErrorDuration] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();
  const params = useParams();
  const history = useHistory();

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector((state) => state.SchedulehighAlert.status);
  const responseMessage = useSelector((state) => state.SchedulehighAlert.text);
  const loading = useSelector((state) => state.SchedulehighAlert.loading);
  const showSnackbar = useSelector((state) => state.SchedulehighAlert.showSnackbar);
  const patrollingRouteList = useSelector((state) => state.patrollingRoute.patrollingRouteList);
  const checkpostList = useSelector((state) => state.checkpost.checkpostList);

   
  console.log("checkpostList")
  console.log(checkpostList);

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsErrorStartDate(startDate === '');
    setIsErrorStatus(status === '');
    setIsErrorEndDate(endDate === '');
    setIsErrorCheckpost(checkpost.length == 0);
    // setIsErrorAlternateContactNumber(alternateContactNumber === '')
    // setIsErrorContactNumber(contactNumber === '')
    setIsErrorpopupDuration(popupDuration === '')
    setIsErrorDuration(duration === '')
    if (startDate !== '' && status !== '' && endDate !== ''  && checkpost.length != 0 && duration !== "" && popupDuration !== '') {
      // Get form data
      const schedulehighAlertData = {
        startDate: startDate,
        status: status,
        endDate: endDate,
        plantAdmin : params.id,
        checkpost : checkpost.map((module) => module.value) ,
        duration : duration,
        popupDuration : popupDuration,
        // contactNumber : contactNumber,
        // alternateContactNumber : alternateContactNumber,
        // plant: req._id,
        // Other schedulehighAlert data fields
      };
      console.log("schedulehighAlertData+++++++++");

      console.log(schedulehighAlertData);

      // Determine if it's an add or edit operation
      const state = 'add';

      // Call the schedulehighAlertAddOrUpdate action
      dispatch(schedulehighAlertAddOrUpdate(schedulehighAlertData, state));
      
    }
  };
  useEffect(() => {
    dispatch(patrollingRouteGetAll("&plant=" + params.id));
  }, [])

  useEffect(()=>{
    dispatch(checkpostGetAll("&plant=" + params.id));
  },[]);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== '') {
          toastFunction(responseMessage, 'success');
          history.goBack();
          // Redirect to the schedulehighAlert table or the desired page after successful submission
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== '') {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: SCHEDULE_HIGH_ALERT_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      },
    });
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
          Schedule-High-Alert Form
          </Text>
          <SimpleGrid minChildWidth='120px' spacing='40px'>
          

            <FormControl isInvalid={isErrorStartDate}>
              <FormLabel>Start Time</FormLabel>
              <Input
                type='time'
                onChange={(e) => {
                  setIsErrorStartDate(e.target.value === '');
                  setStartDate(e.target.value);
                }}
              />
              {!isErrorStartDate ? (
                <></>
              ) : (
                <FormErrorMessage>Start Time is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorEndDate}>
              <FormLabel>End Time</FormLabel>
              <Input
                type='time'
                onChange={(e) => {
                  setIsErrorEndDate(e.target.value === '');
                  setEndDate(e.target.value);
                }}
              />
              {!isErrorEndDate ? (
                <></>
              ) : (
                <FormErrorMessage>End Time is required.</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' marginTop='30px'>
          <FormControl isInvalid={isErrorDuration}>
            <FormLabel>Duration (in min)</FormLabel>
            <Input
              type='text'
              onChange={(e) => {
                setIsErrorDuration(e.target.value === '');
                setDuration(e.target.value);
              }}
            />
            {!isErrorDuration ? (
              <></>
            ) : (
              <FormErrorMessage>Duration is required.</FormErrorMessage>
            )}
          </FormControl>

          <FormControl isInvalid={isErrorpopupDuration}>
            <FormLabel>Popup Duration (in sec)</FormLabel>
            <Input
              type="text"
              onChange={(e) => {
                setIsErrorpopupDuration(e.target.value === '');
                setPopupDuration(e.target.value);
              }}
            />
            {!isErrorpopupDuration ? (
              <></>
            ) : (
              <FormErrorMessage>Popup Duration is required.</FormErrorMessage>
            )}
          </FormControl>
        </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' marginTop='30px'>
            <FormControl isInvalid={isErrorStatus}>
              <FormLabel>Status</FormLabel>
              <Select
                placeholder="Select status"
                onChange={(e) => {
                  setIsErrorStatus(e.target.value === '');
                  setStatus(e.target.value);
                }}
              >
                {dataConstant.status.map((element, index) => {
                  return (<option key={element['id']} value={element['value']} >{element['name']}</option>);
                })}
              </Select>
              {!isErrorStatus ? (
                <></>
              ) : (
                <FormErrorMessage>Status is required.</FormErrorMessage>
              )}
            </FormControl>
            

            <FormControl isInvalid={isErrorCheckpost }>
                <FormLabel>Check post</FormLabel>
                <RSelect
               isMulti
               name="colors"
               placeholder="--select checkpost--"
               options={checkpostList?.map((item) => ({
                  value: item._id,
                  label: item.name
                  }))}
              
               onChange={(value) => {
                console.log(value);
                console.log(value)
                setCheckpost(value)
                 setIsErrorCheckpost(value.length==0)}}
                className="basic-multi-select"
                classNamePrefix="select"
              />
              
                {isErrorCheckpost && (
                  <FormErrorMessage>Checkpost is required.</FormErrorMessage>
                )}
              </FormControl>
          </SimpleGrid>
          <Center>
            {loading ? <Spinner mt='24px' size='lg' /> :
              <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                Submit
              </Button>
            }
          </Center>
        </CardHeader>
        <CardBody>
          {/* Additional form fields and content can be added here */}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default ScheduleHighAlertForm;
