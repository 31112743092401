import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Spinner,
  Image,
  Select,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter
} from "@chakra-ui/react";
import Relect from 'react-select';
import dataConstant from "constants/dataConstant";
import { GUARD_CLEAR_SNACKBAR } from "constants/types";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { deviceGetAll } from "actions/devicesAction";
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';

import { useToast } from '@chakra-ui/react'

import { useParams } from "react-router-dom/cjs/react-router-dom.min";
// import { guardmoduleAddOrUpdate } from "actions/guardModuleAction";
import { guardAddOrUpdate } from "../../actions/GaurdAction";
import CustomInput from "views/CustomValidation";


function GuardUpdateForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [photo, setPhoto] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [guardId, setGuardId] = useState("");
  const [checkpost, setCheckpost] = useState("");
  const [checkpostList, setCheckpostList] = useState([]);
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [locality, setLocality] = useState("");
  const [state, setState] = useState("");
  const [selectedCheckposts, setSelectedCheckposts] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState([]);
  const [homeModule, setHomeModule] = useState([]);
  const [departmentList, setDepartmentList] = useState('')
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [subdepartment, setSubdepartment] = useState("");
  const [subdepartmentList, setSubdepartmentList] = useState([])
  const [designationList, setDesignationList] = useState([])
  const [endDate ,setEndDate]=useState('');
  const [startDate, setStartDate] = useState('');
  const [companyName, setCompanyName] = useState("");
  const [employeeType, setEmployeeType] = useState('');
  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorPhoto, setIsErrorPhoto] = useState(false);
  const [isErrorNumber, setIsErrorNumber] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isErrorGuardId, setIsErrorGuardId] = useState(false);
  const [isErrorCheckpost, setIsErrorCheckpost] = useState(false);
  const [isErrorPostalcode, setIsErrorPostalcode] = useState(false);
  const [isErrorLine1, setIsErrorLine1] = useState(false);
  const [isErrorLine2, setIsErrorLine2] = useState(false);
  const [isErrorLocality, setIsErrorLocality] = useState(false);
  const [isErrorHomeModule, setIsErrorHomeModule] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isErrorDepartment, setIsErrorDepartment] = useState(false);
  const [isErrorDesignation, setIsErrorDesignation] = useState(false);
  const [isErrorSubdepartment, setIsErrorSubdepartment] = useState(false);
  const [isErrorEmployeeType, setIsErrorEmployeeType] = useState(false);
  const [isErrorEndDate, setIsErrorEndDate] = useState(false);
  const [isErrorStartDate, setIsErrorStartDate] = useState(false);
  const [isErrorCompanyName, setIsErrorCompanyName] = useState(false);
  const [isErrorGuardDeviceNo, setIsErrorGuardDeviceNo] = useState(false);
  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.guard.status);
  const responseMessage = useSelector(state => state.guard.text);
  const loading = useSelector(state => state.guard.loading);
  const showSnackbar = useSelector(state => state.guard.showSnackbar);
  const guardDetail = useSelector(state => state.guard.guardDetail);
  const deviceList = useSelector(state => state.device.deviceList);
  const [isErrorState, setIsErrorState] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const params = useParams();
  const Idofguard = params.id;

  console.log("department#@#@#")
  console.log(guardDetail)
  console.log("department")
  useEffect(() => {

    const guard = {
      _id: Idofguard
    }
    dispatch(guardAddOrUpdate(guard, 'view'));
  }, []);


  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {

        setPhoto(guardDetail.photo);
        setName(guardDetail.name);
        setNumber(guardDetail.number);
        setEmail(guardDetail.email);
        setGuardId(guardDetail.guardId);
        setEmployeeType(guardDetail.employeeType);
        setStartDate(guardDetail.startDate);
        setEndDate(guardDetail.endDate);
        setCompanyName(guardDetail.companyName);
        if (guardDetail.department) {
          setDepartment(guardDetail.department);
          subdepartmentListforDropdown(guardDetail.department);
        }
        if (guardDetail.subdepartment) {
          setSubdepartment(guardDetail.subdepartment);
          designationListforDropdown(guardDetail.subdepartment);
        }
        if (guardDetail.designation) {
          setDesignation(guardDetail.designation);
        }
        // setCheckpost(guardDetail.checkpost);
        setSelectedCheckposts(guardDetail.checkpost.map((item) => ({
          value: item._id,
          label: item.name
        })));

        setSelectedDevice(guardDetail.guardDeviceNo.map((item) => {
          const dataDevice = deviceList.find((item1) => item1.deviceId === item);
          if(dataDevice)
          {
            return {
              value: item,
              label: dataDevice.name, // Handle the case when dataDevice is not found
            };
          }
        }));

        // homeModule
        if (guardDetail.homeModule) {
          console.log(guardDetail.homeModule)
          console.log('guardDetail.homeModule')
          setHomeModule(guardDetail.homeModule.map((item) => ({
            value: item,
            label: replaceUnderscoresAndCapitalize(item)
          })));
        } else {
          setHomeModule(guardDetail.homeModule);
        }
        guardDetail.Address.map((item) => {
          setLine1(item.line1);
          setLine2(item.line2);
          setPostalCode(item.postalCode);
          setLocality(item.locality);
          setState(item.state)
        });

      }

    }

    function replaceUnderscoresAndCapitalize(inputString) {
      // Replace underscores with spaces
      const stringWithSpaces = inputString.replace(/_/g, ' ');

      // Capitalize the first letter of each word
      const words = stringWithSpaces.split(' ');
      const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

      // Join the words back into a single string
      const resultString = capitalizedWords.join(' ');

      return resultString;
    }

    dispatch({
      type: GUARD_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const handleExit = () => {
    const newGuard = {
      _id: Idofguard,
      isExit : true
    };
    const state = 'edit';
    dispatch(guardAddOrUpdate(newGuard, 'edit'));
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsErrorName(name === '');
    setIsErrorPhoto(photo === '');
    setIsErrorNumber(number === '');
    // setIsErrorEmail(email === '');
    // setIsErrorGuardId(guardId === '');
    setIsErrorCheckpost(selectedCheckposts.length == 0);
    setIsErrorGuardDeviceNo(selectedDevice.length == 0);
    // setIsErrorPostalcode(line1 === '');
    // setIsErrorLine1(line2 === '');
    // setIsErrorLine2(postalCode === '');
    // setIsErrorLocality(locality === '');
    // setIsErrorState(state === '');
    // setIsErrorHomeModule(homeModule === '');
    setIsErrorDepartment(department === '');
    setIsErrorDesignation(designation === '');
    setIsErrorSubdepartment(subdepartment === '');
    setIsErrorCompanyName(companyName === '');
    setIsErrorStartDate(startDate === '');
    setIsErrorEndDate(endDate === '');
    setIsErrorEmployeeType(employeeType === '');  
console.log("selectedDevice");
console.log(selectedDevice);
console.log("selectedDevice");
    if (name !== "" && number !== "" && selectedCheckposts !== "" &&
    selectedDevice.length != 0 &&
    employeeType !== 0 &&
      department !== 0 &&
      designation !== 0 &&
      subdepartment !== 0
    ) {

      const newGuard = {
        _id: Idofguard,
        name,
        number,
        email,
        guardId,
        // 'homeModule': homeModule.map((module) => module.value),
        'checkpost': selectedCheckposts.map((module) => module.value),
        'guardDeviceNo': selectedDevice.map((module) => module.value),
        department: department,
        designation: designation,
        subdepartment: subdepartment,
        employeeType: employeeType,
        endDate : endDate,
        startDate : startDate,
        companyName : companyName,
        Address: [
          {
            line1,
            line2,
            postalCode,
            locality,
            state,
          }

        ],
      };
      if(photo)
      {
        newGuard.file= photo;
      }
      console.log("kagdikdafik12222222221222222221");
      console.log(newGuard)
      dispatch(guardAddOrUpdate(newGuard, 'edit'));
    }

  };


  const departmentListforDropdown = () => {
    axios
      .get(`${server_url}/department`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        setDepartmentList(responce.data.data)
      }).catch(() => console.log("error"))
  }

  const subdepartmentListforDropdown = (depId) => {
    if (depId) {
      axios
        .get(`${server_url}/plant/subdepartment-list/${depId}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          setSubdepartmentList(responce.data.data)
        })
    }
    else {
      setSubdepartmentList([])
    }

  }

  const designationListforDropdown = (subdepId) => {
    if (subdepId) {
      axios
        .get(`${server_url}/plant/designation-list/${subdepId}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          setDesignationList(responce.data.data)
        })
    }
    else {
      setDesignationList([])
    }

  }

  useEffect(() => {
    departmentListforDropdown()
    dispatch(deviceGetAll("&limit=100"));
  }, [])

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== "") {
          window.location.reload(false);
          const guard = {
            _id: Idofguard
          }
          dispatch(guardAddOrUpdate(guard, 'view'));
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    
    dispatch({
      type: GUARD_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };



  const checkpostListforDropdown = () => {
    axios
      .get(`${server_url}/plant/checkpost-list`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {

        setCheckpostList(responce.data.data)
      }).catch(() => console.log("error"))
  }

  useEffect(() => {
    checkpostListforDropdown()


  }, [])

  const textRegex = /^[A-Za-z\s]+$/;
  const numberRegex = /^\d{10}$/;
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/;
  const guardIdRegex = /^[a-zA-Z0-9]+$/;
  const postalCodeRegex = /^[0-9]{6}$/;

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          
          <Flex>
        <Text fontSize="xl" color={textColor} fontWeight="bold">
            Guard Update Form
          </Text>
          <Spacer />
          <Button colorScheme='red' mt='0px' onClick={onOpen}>Exit</Button>
          <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Exit Guard
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={() => { handleExit(), onClose() }} ml={3}>
                  Exit
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        </Flex>
          <br/>
          <SimpleGrid minChildWidth='120px' spacing='40px'>
            <FormControl isInvalid={isErrorName || (submitted && name === "")}>
              <CustomInput
                type='text'
                label='Guard Name'
                value={name}
                onChange={(value) => {
                  setIsErrorName(value === '' || !textRegex.test(value));
                  setName(value);
                }}
                isInvalid={isErrorName || (submitted && name === "")}
                regex={textRegex}
                submitted={submitted}
                required={true}
              />
            </FormControl>
            <FormControl isInvalid={isErrorPhoto || (submitted && photo === "")}>
              <FormLabel>Photo <span style={{ color: 'red' }}>*</span></FormLabel>
              <Input
                type='file'
                onChange={(e) => {
                  setIsErrorPhoto(e.target.value === '')
                  setPhoto(e.target.files[0])
                }}
              />
              {isErrorPhoto && submitted && (
                <FormErrorMessage>Photo is required.</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            <FormControl isInvalid={isErrorNumber || (submitted && number === "")}>
              <CustomInput
                type="number"
                label="Phone Number"
                value={number}
                onChange={(value) => {
                  setIsErrorNumber(value === '' || !numberRegex.test(value));
                  setNumber(value);
                }}
                isInvalid={isErrorNumber || (submitted && number === "")}
                regex={numberRegex}
                submitted={submitted}
                required={true}
              />
            </FormControl>
            <FormControl isInvalid={isErrorEmail}>
              <CustomInput
                type="email"
                label="Email"
                value={email}
                onChange={(value) => {
                  // setIsErrorEmail(value === '' || !emailRegex.test(value));
                  setEmail(value);
                }}
                isInvalid={isErrorEmail}
                regex={emailRegex}
                // submitted={submitted}
              />
            </FormControl>
          </SimpleGrid>
          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            {/* Department */}
            <FormControl isInvalid={isErrorDepartment || (submitted && department === "")}>
              <FormLabel>Department <span style={{ color: 'red' }}>*</span></FormLabel>
              <Select
                placeholder="Department"
                onChange={(e) => {
                  setIsErrorDepartment(e.target.value === '');
                  setDepartment(e.target.value);
                  if (e.target.value === '') {
                    setSubdepartmentList([]);
                    setDesignationList([]);
                  }
                  else {
                    setDesignationList([]);
                    subdepartmentListforDropdown(e.target.value);
                  }

                }}
                defaultValue={department}
              >
                {departmentList ? (departmentList.map((item, index) => (
                  <option key={item._id} value={item._id} selected={department == item._id ? true : false}>{item.name}</option>

                ))) : (<></>)}
              </Select>
              {isErrorDepartment && submitted && (
                <FormErrorMessage>Department is required.</FormErrorMessage>
              )}
            </FormControl>
            {/* Subdepartment */}
            <FormControl isInvalid={isErrorSubdepartment || (submitted && subdepartment === "")}>
              <FormLabel>Subdepartment <span style={{ color: 'red' }}>*</span></FormLabel>
              <Select
                placeholder="Subdepartment"
                onChange={(e) => {
                  setIsErrorSubdepartment(e.target.value === '');
                  setSubdepartment(e.target.value);
                  designationListforDropdown(e.target.value);
                }}
                value={subdepartment}
              >
                {subdepartmentList ? (subdepartmentList.map((item, index) => (
                  <option key={item._id} value={item._id} selected={subdepartment == item._id ? true : false}>{item.name}</option>
                ))) : (<></>)}
              </Select>
              {isErrorSubdepartment && submitted && (
                <FormErrorMessage>Subdepartment is required.</FormErrorMessage>
              )}
            </FormControl>


          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            {/* Designation */}
            <FormControl isInvalid={isErrorDesignation || (submitted && designation === "")}>
              <FormLabel>Designation <span style={{ color: 'red' }}>*</span></FormLabel>
              <Select
                placeholder="Designation"
                onChange={(e) => {
                  setIsErrorDesignation(e.target.value === '');
                  setDesignation(e.target.value);
                }}
                value={designation}
              >
                {designationList ? (designationList.map((item, index) => (
                  <option key={item._id} value={item._id} selected={designation == item._id ? true : false}>{item.name}</option>
                ))) : (<></>)}
              </Select>
              {isErrorDesignation && submitted && (
                <FormErrorMessage>Designation is required.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={isErrorGuardId}>
              <CustomInput
                type='text'
                label="Guard Id"
                placeholder="Guard Id"
                regex={guardIdRegex}
                value={guardId}
                onChange={(value) => {
                  // setIsErrorGuardId(value === '' || !guardIdRegex.test(value));
                  setGuardId(value);
                }}
                isInvalid={isErrorGuardId}
                // submitted={submitted}
              />
            </FormControl>

            {/* Home Module */}
            {/* <FormControl >
              <FormLabel>Home Module</FormLabel>
              <Relect
                isMulti
                name="colors"
                placeholder="--select Home Module--"
                options={dataConstant.homeModule.map((item) => ({
                  value: item.value,
                  label: item.name
                }))}
                value={homeModule}
                onChange={(value) => {

                  setHomeModule(value)
                  console.log('homeModule');
                  console.log(value);
                  console.log("123123123123123123123123123123123");
                  console.log(setHomeModule(value));
                  setIsErrorHomeModule(value === '')
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
              {isErrorHomeModule && submitted && (
                <FormErrorMessage>Home Module is required.</FormErrorMessage>
              )}
            </FormControl> */}
            <FormControl isInvalid={isErrorCheckpost || (submitted && checkpost === "")}>
              <FormLabel>Check post<span style={{ color: 'red' }}>*</span></FormLabel>
              <Relect
                isMulti
                name="colors"
                placeholder="--select checkpost--"
                options={checkpostList.map((item) => ({
                  value: item._id,
                  label: item.name,
                }))}
                value={selectedCheckposts} // Use selectedCheckposts for value
                onChange={(value) => {
                  setSelectedCheckposts(value); // Update selectedCheckposts on change
                  setIsErrorCheckpost(value.length === 0); // Check if it's empty
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
              {isErrorCheckpost && submitted && (
                <FormErrorMessage>Checkpost is required.</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
          <FormControl isInvalid={isErrorGuardDeviceNo}>
            <FormLabel>Devices <span style={{ color: 'red' }}>*</span></FormLabel>
            <Relect
              isMulti
              name="colors"
              placeholder="-- Select Device --"
              options={deviceList.map((item) => ({
                value: item.deviceId,
                label: item.name,
              }))}
              value={selectedDevice}
              onChange={(value) => {
                setIsErrorGuardDeviceNo(!value || value.length === 0);
                setSelectedDevice(value);
                console.log(value)
              }}
              className="basic-multi-select"
              classNamePrefix="select"
            />
            {!isErrorGuardDeviceNo ? (
              <></>
            ) : (
              <FormErrorMessage>Devices are required.</FormErrorMessage>
            )}
          </FormControl>
            <FormControl isInvalid={isErrorLine1}>
              <CustomInput
                type='text'
                label="Line 1"
                placeholder="Line 1"
                regex={textRegex}
                value={line1}
                onChange={(value) => {
                  // setIsErrorLine1(value === '' || !textRegex.test(value));
                  setLine1(value);
                }}
                isInvalid={isErrorLine1}
                // submitted={submitted}
              />
            </FormControl>
            <FormControl isInvalid={isErrorLine2}>
              <CustomInput
                type='text'
                label="Line 2"
                placeholder="Line 2"
                regex={textRegex}
                value={line2}
                onChange={(value) => {
                  // setIsErrorLine2(value === '' || !textRegex.test(value));
                  setLine2(value);
                }}
                isInvalid={isErrorLine2}
                // submitted={submitted}
              />
            </FormControl>
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            <FormControl isInvalid={isErrorPostalcode}>
              <CustomInput
                type='number'
                label="Postal Code"
                placeholder="Postal Code"
                regex={postalCodeRegex}
                value={postalCode}
                onChange={(value) => {
                  setIsErrorPostalcode(value === '');
                  setPostalCode(value);
                }}
                isInvalid={isErrorPostalcode}
                // submitted={submitted}
              />
            </FormControl>

            <FormControl isInvalid={isErrorLocality}>
              <CustomInput
                type='text'
                label="Locality"
                placeholder="Locality"
                regex={textRegex}
                value={locality}
                onChange={(value) => {
                  // setIsErrorLocality(value === '' || !textRegex.test(value));
                  setLocality(value);
                }}
                isInvalid={isErrorLocality}
                // submitted={submitted}
              />
            </FormControl>

            <FormControl isInvalid={isErrorState}>
              <CustomInput
                type='text'
                label="State"
                placeholder="State"
                regex={textRegex}
                value={state}
                onChange={(value) => {
                  // setIsErrorState(value === '' || !textRegex.test(value));
                  setState(value);
                }}
                isInvalid={isErrorState}
                // submitted={submitted}
              />
            </FormControl>

            <FormControl isInvalid={isErrorEmployeeType || (submitted && employeeType === "")}>
                <FormLabel>Employee Type <span style={{ color: 'red' }}>*</span></FormLabel>
                <Select
                  placeholder="--- Select Employee Type ---"
                  onChange={(e) => {
                    setIsErrorEmployeeType(e.target.value === '');
                    setEmployeeType(e.target.value);
                  }}
                  value={employeeType}
                >
                  {dataConstant.employeeType.map((element, index) => (
                    <option key={element.id} value={element.value}>{element.name}</option>
                  ))}
                </Select>
                {isErrorEmployeeType && submitted && (
                  <FormErrorMessage>Employee Type is required.</FormErrorMessage>
                )}
              </FormControl>
          </SimpleGrid>


          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
              {employeeType == 3 || employeeType == 4 ?
              <>

               <FormControl isInvalid={isErrorStartDate || (submitted && startDate === "")}>
                  <FormLabel>Start Date <span style={{ color: 'red' }}>*</span></FormLabel>
                  <Input
                    type='text'
                    value={startDate}
                    onChange={(e) => {

                      setIsErrorStartDate(e.target.value === '')
                      setStartDate(e.target.value)
                    }}
                  />
                  {!isErrorStartDate ? (
                    <></>
                  ) : (
                    <FormErrorMessage>Start Date is required.</FormErrorMessage>
                  )}
                </FormControl>

               <FormControl isInvalid={isErrorEndDate || (submitted && endDate === "")}>
                <FormLabel>End Date <span style={{ color: 'red' }}>*</span></FormLabel>
                <Input
                  type='text'
                  value={endDate}
                  onChange={(e) => {
                    
                    setIsErrorEndDate(e.target.value === '')
                    setEndDate(e.target.value)
                  }}
                />
                {!isErrorEndDate ? (
                  <></>
                ) : (
                  <FormErrorMessage>End Date is required.</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={isErrorCompanyName || (submitted && companyName === "")}>
                  <FormLabel>Company Name <span style={{ color: 'red' }}>*</span></FormLabel>
                  <Input
                    type='text'
                    value={companyName}
                    // onChange={(e) => {
                    //   setIsErrorCompanyName(e.target.value === '' || !textRegex.test(value));
                    //   setCompanyName(e.target.value);
                    // }}
                        onChange={(e) => {

                        setIsErrorCompanyName(e.target.value === '')
                        setCompanyName(e.target.value)
                    }}
                    regex={textRegex}

                  />
                  {!isErrorCompanyName ? (
                    <></>
                  ) : (
                    <FormErrorMessage>Company Name is required.</FormErrorMessage>
                  )}
                </FormControl>

              </>
               :<></>}
              </SimpleGrid>
          {/* ... Additional form fields ... */}
          <Center>
            {loading ? (
              <Spinner mt="24px" size="lg" />
            ) : (
              <Button colorScheme="blue" mt="24px" onClick={handleSubmit}>
                Submit
              </Button>
            )}
          </Center>
        </CardHeader>
        <CardBody>
          {/* ... Additional form content ... */}
        </CardBody>
      </Card>
      <Spacer></Spacer>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px" mt="4">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Guard
          </Text>
          <Box boxSize='sm'>
            <Image src={photo} alt='Dan Abramov' />
          </Box>
        </CardHeader>
        <CardBody>
          {/* ... Additional content ... */}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default GuardUpdateForm;
