// Chakra imports
import {
    Box,
    Button,
    Center,
    Flex,
    Input,
    ListItem,
    OrderedList,
    SimpleGrid,
    Spacer,
    Stack,
    Text,
    Wrap,
    WrapItem,
    useColorModeValue
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { tablesProjectData, tablesTableData } from "variables/general";
  import { useDispatch, useSelector } from 'react-redux';
  import { roleAddOrUpdate, } from '../../actions/roleActions';
  import { useToast } from '@chakra-ui/react'
  import { useParams } from "react-router-dom/cjs/react-router-dom.min";
  import { ROLE_CLEAR_SNACKBAR } from "constants/types";
  import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
  } from '@chakra-ui/react'
  import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'
  import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { server_url } from '../../constants/types';
  
  function RoleView() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [name,setName]= useState("");
    const [allPermission,setAllPermission]= useState("");
    const [permissions,setPermissions]= useState([]);
    const [contactNo,setContactNo]= useState("");
    const [alertMessage, setAlertMessage] = useState('iii');
    const [isErrorName, setIsErrorName] = useState(false);
    const [isErrorEmail, setIsErrorEmail] = useState(false);
    const [isErrorContactNo, setIsErrorContactNo] = useState(false);
    const [isErrorPassword, setIsErrorPassword] = useState(false);
    const history = useHistory()
    const dispatch = useDispatch();
    const toast = useToast();
  
    const statuses = ['success', 'error', 'warning', 'info'];
    const responseStatus = useSelector(state => state.role.status);
    const responseMessage = useSelector(state => state.role.text);
    const loading = useSelector(state => state.role.loading);
    const roleDetail = useSelector(state => state.role.roleDetail);
    const showSnackbar = useSelector(state => state.role.showSnackbar);
  
    const params = useParams();
    useEffect(() => {
      if(showSnackbar)
      {
        if (responseStatus == 'OK' && loading == false) {
          console.log(roleDetail);
            setName(roleDetail.name);
            setAllPermission(roleDetail.allPermissions);
            setPermissions(roleDetail.permissions);
          if(responseMessage !="")
          {
            toastFunction(responseMessage, 'success');
          }
          
        } else if (responseStatus == 'NOK' && loading == false) {
          if(responseMessage !="")
          {
            toastFunction(responseMessage, 'error');
          }
          
        }
      }
  
      dispatch({
        type: ROLE_CLEAR_SNACKBAR,
        payload: {
            showSnackbar: false,
        }
    })
    }, [loading]);
  
    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
    }, [])

    useEffect(() => {
      console.log(params);
      const state = 'view';
        // Call the roleAddOrUpdate action
        const role = {
          '_id' : params.id
        };
        dispatch(roleAddOrUpdate(role, state))
    }, []);
  
    const toastFunction = (title,status) =>
    {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      })
    };
  
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Role View
            </Text>
          </CardHeader>
          <CardBody>
          {loading ? <Stack>
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
          </Stack>
          :
          <TableContainer>
            <Table variant='simple'>
              <Tbody>
                <Tr>
                  <Td>Name</Td>
                  <Td>{name}</Td>
                </Tr>
                <Tr>
                  <Td>AllPermission</Td>
                  <Td>{allPermission?'true':'false'}</Td>
                </Tr>
                <Tr>
                  <Td>Permissions</Td>
                  <Td>
                    <OrderedList>
                    {permissions.map((element,index)=>{
                        return(
                            <ListItem>{element.moduleName} {element.permissionName}</ListItem>
                        );
                  })}
                  </OrderedList>
                  </Td>
                </Tr>
                
              </Tbody>
              
            </Table>
          </TableContainer>}
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default RoleView;
  