// Chakra imports
import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Input,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { bannerPositionAddOrUpdate, bannerPositionClearShowSnackbar } from "actions/inspectionAction";
import { useToast } from '@chakra-ui/react'
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { BANNER_POSITION_CLEAR_SNACKBAR } from "constants/types";
import axios from 'axios';
import { server_url } from '../../constants/types';
import { useHistory } from 'react-router-dom';

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'
import dataConstant from "constants/dataConstant";
import InspectionQrTable from "views/InspectionQr/InspectionQrTable";

function InspectionListView() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [description, setDescription] = useState("");
  const [baseurl, setBaseurl] = useState("");
  const [authtype, setAuthtype] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [timeInterval, setTimeIntervel] = useState("");
  const [plantAdmin, setPlantAdmin] = useState("");


  const [status, setStatus] = useState('false');
  const [type, setType] = useState("");

  const dispatch = useDispatch();
  const toast = useToast();

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.biometricSettings.status);
  const responseMessage = useSelector(state => state.biometricSettings.text);
  const loading = useSelector(state => state.biometricSettings.loading);
  const showSnackbar = useSelector(state => state.biometricSettings.showSnackbar);
  const history = useHistory();

  const params = useParams();

  useEffect(() => {
    console.log("params");
    const id = params.id;
    axios
      .get(`${server_url}/admin/inspection/items/${id}`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        // if(responce.data.data)
        // {
          setId(responce.data.data._id);
          setName(responce.data.data.name);
          setDescription(responce.data.data.description);
          setStatus(responce.data.data.status);
          
          
          setPlantAdmin(responce.data.data.plant?._id);
          console.log("I am here");
        // }
      })
      .catch((e) => {

      });
  }, []);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  return (
    <>
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="18px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Inspection List Detail
          </Text>
        </CardHeader>
        <CardBody>
          {loading ? <Stack>
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
          </Stack>
            :
            <TableContainer>
              <Table variant='simple'>
                <Tbody>
                  <Tr>
                    <Td>Name</Td>
                    <Td>{name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Description</Td>
                    <Td>{description}</Td>
                  </Tr>
                  
                  <Tr>
                    <Td>Status</Td>
                    <Td>{status?'active':'inactive'}</Td>
                  </Tr>
                  <Tr>
                    <Td>Status</Td>
                    <Td>{status?'Active':'Inactive'}</Td>
                  </Tr>
                  {/* <Tr>
                    <Td>Type</Td>
                    <Td>{type}</Td>
                  </Tr> */}
                  {/* <Tr>
                      <Td>City</Td>
                      <Td>{city}</Td>
                    </Tr> */}
                </Tbody>
              </Table>
            </TableContainer>}
        </CardBody>
      </Card>
    </Flex>
    <SimpleGrid minChildWidth="120px" spacing="40px">
    <InspectionQrTable url={`#/admin/inspection-qr/form/${params.id}`} plant={params.id}></InspectionQrTable>
  </SimpleGrid>
  </>
  );
}

export default InspectionListView;