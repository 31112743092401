// Chakra imports
import {
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Link,
  Icon
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { PlantAdminGetAll } from '../../actions/plantAction';
import { useToast } from '@chakra-ui/react'
import { PLANTADMIN_CLEAR_SNACKBAR } from "constants/types";
import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import { useState } from "react";
import PlantAdminsTableRow from "./PlantAdminTableRow";
import PlantAdminPagination from "./PlantAdminPagination";

import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';



function PlantAdminTable() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const response = useSelector(state => state.plantadmin.response);
  const responseStatus = useSelector(state => state.plantadmin.status);
  const responseMessage = useSelector(state => state.plantadmin.text);
  const loading = useSelector(state => state.plantadmin.loading);
  const plantList = useSelector(state => state.plantadmin.plantList);
  const showSnackbar = useSelector(state => state.plantadmin.showSnackbar);
  const [statusofadmin, setStatusofadmin] = useState();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();

  useEffect(() => {
    dispatch(PlantAdminGetAll());
  }, [dispatch]);


  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])

  
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        console.log(response)
        console.log('response')
        if (response != "" && response != undefined) {
          setCurrentPage(response.currentPage)
          // setLimit(response.limit)
        }
        
        
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: PLANTADMIN_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    });
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };



  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
    dispatch(PlantAdminGetAll(sorting));
  };

  useEffect(() => {
    const searching = "&search=" + search;
    console.log(searching);
    dispatch(PlantAdminGetAll(searching));
  }, [search]);
  console.log("plantListtttttt data")
  console.log(plantList)


  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>

      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <Flex >
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Plant Admins
            </Text>
          </CardHeader>
          <Spacer />
          <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            <Link href={`#/admin/plant-admin-form`}>
              <IconButton
                colorScheme="blue"
                aria-label="Search database"
                icon={<AddIcon />}
              />
            </Link>
          </Flex>

          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              <InputGroup borderRadius="8px" w="200px">
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon
                          color={searchIconColor}
                          w="15px"
                          h="15px"
                        />
                      }
                    ></IconButton>
                  }
                />


                <Input
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.length < 3) {
                      setSearch("");
                    }
                  }}
                  placeholder="Type here..."
                />
              </InputGroup>
            </Text>
          </CardHeader>
        </Flex>

        <CardBody pb={4}>
          <div style={{ overflowX: "auto" }}>
            <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                    pl="15px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                   SNO
                  </Th>
                  <Th pl="0px" borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("plantName")}
                    cursor="pointer">
                    Plant Name
                    {sortColumn === "plantName" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("contactPersonName")}
                    cursor="pointer">
                    contact Person Name
                    {sortColumn === "contactPersonName" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}</Th>
                  <Th borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("alternateNo")}
                    cursor="pointer">
                    Alternate No
                    {sortColumn === "alternateNo" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}</Th>

                  <Th borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("officalEmail")}
                    cursor="pointer">
                    Offical Email
                    {sortColumn === "officalEmail" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}</Th>



                  <Th borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("status")}
                    cursor="pointer">
                    Switch
                    {sortColumn === "status" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}</Th>

                  <Th borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer">
                    Action
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {plantList ? (plantList.map((row, index, arr) => (row.plant.map((item, index2, arr2) => (<PlantAdminsTableRow
                  PlantName={item.plantName}
                  serialnumber={currentPage - 1 + index + 1}
                  contactPersonName={item.contactPersonName}
                  AlternateNo={item.alternateNo}
                  OfficalEmail={item.officalEmail}
                  isLast={index2 === arr2.length - 1}
                  status={row.status}
                  id={row._id}

                />))

                ))) : (<h1></h1>)}
              </Tbody>
            </Table>
          </div>
          {plantList.length === 0 ? (
            <></>
          ) : (
            <PlantAdminPagination
              totalPages={response?.totalPages}
              currentPage={response?.currentPage}
              perPage={response?.perPage}
              sortBy={sortColumn}
              sortOrder={sortOrder}
              handlePageClick={(data) => {
                const selectedPage = data.selected + 1;
                dispatch(PlantAdminGetAll(`&page=${selectedPage}`));
              }}
            />
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default PlantAdminTable;
