import {
    Avatar,
    Box,
    Badge,
    Button,
    ButtonGroup,
    Flex,
    IconButton,
    Link,
    Select,
    Switch,
    Td,
    Text,
    Tr,
    useColorModeValue,
    useDisclosure,
  
  } from "@chakra-ui/react";
  import { adminDelete } from "actions/adminActions";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
  } from '@chakra-ui/react'
  import { ChangeAdminStatus, corporateGetAll } from "../../actions/adminActions";
  import { ViewIcon } from "@chakra-ui/icons";
  import { AiFillEdit } from "react-icons/ai";
  import { BsFillTrashFill } from "react-icons/bs";
  
  
  function CorporateTableRow(props) {
    const { serialnumber, name, email, primarycontactNo, secoundarycontactNo, cityadminId, isLast, id, status, startDate, endDate } = props;
    const textColor = useColorModeValue("gray.500", "white");
    const titleColor = useColorModeValue("gray.700", "white");
    const bgStatus = useColorModeValue("gray.400", "navy.900");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()
    const limit = useSelector((state) => state.admin.limit);
    const page = useSelector((state) => state.admin.page);
  
    const dispatch = useDispatch();
  
    const deleteAdmin = () => {
      const admin = {
        _id: id
      };
      dispatch(adminDelete(admin))
    }
  
    console.log("name")
    console.log(name)
  
    function handleSwitchChange() {
  
      const admin = {
        _id: id
      };
      console.log(admin._id)
      dispatch(ChangeAdminStatus(admin , page , limit))
      dispatch(corporateGetAll())
    }
  
  
  
    console.log(status)
  
  
  
  
  
    return (
      <>
        <Tr>
          <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
            <Flex direction="column">
              <Text fontSize="md" color={textColor} fontWeight="bold">
                {serialnumber}
              </Text>
  
            </Flex>
          </Td>
          <Td
            minWidth={{ sm: "250px" }}
            pl="30px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : null}
          >
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={titleColor}
                  fontWeight="bold"
                  minWidth="100%"
                >
                  {cityadminId}
                </Text>
  
              </Flex>
            </Flex>
          </Td>
          <Td
            minWidth={{ sm: "250px" }}
            pl="20px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : null}
          >
            <Flex direction="column">
              <Text fontSize="md" color={textColor} fontWeight="bold">
                {name}
              </Text>
  
            </Flex>
          </Td>
  
          <Td
            minWidth={{ sm: "250px" }}
            pl="30px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : null}
          >
            <Flex direction="column">
              <Text fontSize="md" color={textColor} fontWeight="bold">
                {email}
              </Text>
  
            </Flex>
          </Td>
  
          <Td
            minWidth={{ sm: "250px" }}
            pl="30px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : null}
          >
            <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
              {primarycontactNo}
            </Text>
          </Td>
  
          <Td
            minWidth={{ sm: "250px" }}
            pl="30px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : null}
          >
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {secoundarycontactNo}
            </Text>
          </Td>
  
  
          <Td
            minWidth={{ sm: "250px" }}
            pl="30px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : null}
          >
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {startDate}
            </Text>
          </Td>
          <Td
            minWidth={{ sm: "250px" }}
            pl="30px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : null}
          >
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {endDate}
            </Text>
          </Td>
          <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
            <Flex flexDirection="column" alignItems="center">
              <Switch
                sx={{
                  'span.chakra-switch__track:not([data-checked])': {
                    backgroundColor: 'red'
                  }
                }}
                colorScheme='green'
                id='isRequired'
                onChange={handleSwitchChange}
                isChecked={status}
              />
              <Box mt={1}>
                {status ? (<Text variant='outline' style={{ fontSize: '10px', color: 'green' }} border fontWeight='bold' colorScheme='green'>Active</Text>) : (<Text variant='outline' style={{ fontSize: '10px', color: 'red' }} fontWeight='bold' colorScheme='red'>Inactive</Text>)}
              </Box>
            </Flex>
          </Td>
  
  
          <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
            <ButtonGroup>
              <Link href={`#/admin/corporate-view/${id}`}>
                <IconButton
                  colorScheme="blue"
                  icon={<ViewIcon />}
                  aria-label="Up"
                />
              </Link>
  
              <Link href={`#/admin/corporate-update-form/${id}`}>
                <IconButton
                  colorScheme="green"
                  icon={<AiFillEdit />}
                  aria-label="Edit"
                />
              </Link>
  
              <IconButton
                colorScheme="red"
                variant="outline"
                icon={<BsFillTrashFill />}
                aria-label="Delete"
                onClick={onOpen}
              />
            </ButtonGroup>
          </Td>
  
          
  
          {/* <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Switch  sx={{
      'span.chakra-switch__track:not([data-checked])': {
        backgroundColor: 'red'
      }
    }} colorScheme='green' id='isRequired' onChange={handleSwitchChange} isChecked={status} />
    {status ? (<Badge colorScheme='green'>Active</Badge>):(<Badge colorScheme='red'>InActive</Badge>)}
   
          </Td> */}
        </Tr>
  
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Delete Admin
              </AlertDialogHeader>
  
              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>
  
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme='red' onClick={() => { deleteAdmin(), onClose() }} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
  
    );
  }
  
  export default CorporateTableRow;
  