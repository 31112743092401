import React, { useEffect, useState } from "react";
import {
  chakra,
  Text,
  useColorModeValue,
  Flex,
  Image,
  Table,
  TableContainer,
  Tbody,
  Tr,
  Td,
  Box,
  Stack
} from "@chakra-ui/react";
import { useDispatch, useSelector } from 'react-redux';
import { employeeTypeAddOrUpdate, employeeTypeClearShowSnackbar } from '../../actions/EmployeeTypeAction'; // Import your employee type actions
import { useToast } from '@chakra-ui/react';
import { useParams } from "react-router-dom"; // Update with the correct import
import { EMPLOYEETYPE_CLEAR_SNACKBAR } from "constants/types"; // Import your employee type constants
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react';

function EmployeeTypeView() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  
  const dispatch = useDispatch();
  const toast = useToast();
  
  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.employeeType.status); // Update with your employee type state
  const responseMessage = useSelector(state => state.employeeType.text); // Update with your employee type state
  const loading = useSelector(state => state.employeeType.loading); // Update with your employee type state
  const employeeTypeDetail = useSelector(state => state.employeeType.employeeTypeDetail); // Update with your employee type state
  const showSnackbar = useSelector(state => state.employeeType.showSnackbar); // Update with your employee type state
  
  const params = useParams();
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
          console.log("employeeTypeDetail");
        console.log(employeeTypeDetail);
        setName(employeeTypeDetail.name);
        setDescription(employeeTypeDetail.description);
        setStatus(employeeTypeDetail.status ? "Active" : "Inactive");
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
  
    dispatch({
      type: EMPLOYEETYPE_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);
  
  useEffect(() => {
    console.log("params");
    const employeeTypeId = params.id; // Update with the correct param name
    const employeeType = {
      _id : employeeTypeId
    };
    dispatch(employeeTypeAddOrUpdate(employeeType,'view')); // Update with your employee type action
  }, []);
  
  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };
  
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            View Form
          </Text>
        </CardHeader>
        <CardBody>
          {/* {loading ? 
            <Stack>
              <Skeleton height='20px' />
              <Skeleton height='20px' />
              <Skeleton height='20px' />
            </Stack>
            :
            <TableContainer>
              <Table variant='simple'>
                <Tbody>
                  <Tr>
                    <Td>Employee Type Name</Td>
                    <Td>{name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Description</Td>
                    <Td>{description}</Td>
                  </Tr>
                  <Tr>
                    <Td>Status</Td>
                    <Td>{status}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>} */}
            <TableContainer>
            <Table variant='simple'>
                <Tbody>
                  <Tr>
                    <Td>Employee Type Name</Td>
                    <Td>{name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Description</Td>
                    <Td>{description}</Td>
                  </Tr>
                  <Tr>
                    <Td>Status</Td>
                    <Td>{status}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          
        </CardBody>
      </Card>
    </Flex>
  );
}

export default EmployeeTypeView;
