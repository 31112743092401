import axios from 'axios';
import {
  INTERCARTINGINOUT_LOADING,
  INTERCARTINGINOUT_GET_ALL_SUCCESS,
  INTERCARTINGINOUT_ERROR,
  INTERCARTINGINOUT_CLEAR_SNACKBAR
} from '../constants/types';

import { server_url } from '../constants/types';

export const IntercartingInOutGetAll = (pagination = '') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: INTERCARTINGINOUT_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/intercarting?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {

          console.log(response.data.data)
          dispatch({
            type: INTERCARTINGINOUT_GET_ALL_SUCCESS,
            payload: {
              intercartingInOutList: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all Intercarting successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: INTERCARTINGINOUT_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting Intercarting  data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

// View 

export const intercartAddOrUpdate = (intercarting, state , page='' , limit='') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: INTERCARTINGINOUT_LOADING,
        payload: {
          loading: true,
        },
      });

      if (state === "view") {
        axios
          .get(`${server_url}/intercarting/${intercarting._id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            },
          })
          .then((response) => {
            dispatch({
              type: INTERCARTINGINOUT_GET_ALL_SUCCESS,
              payload: {
                intercartingInOutList: response.data.data,
                limit: response.data.limit,
                page: response.data.currentPage,
                totalPages: response.data.totalPages,
                response: response.data,
                intercartingInOutDetail: response.data["data"],
                loading: false,
                status: response.data["status"],
                text: response.data["message"],
              },
            });
            resolve();
          })
          .catch((e) => {
            dispatch({
              type: INTERCARTINGINOUT_ERROR,
              payload: {
                text: "Error occurred during updating floor data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      }
    });
  };
};
