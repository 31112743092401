import {
   
    CITY_CLEAR_SNACKBAR, CITY_ERROR, CITY_GET_ALL_SUCCESS, CITY_LOADING
  } from '../constants/types';
  
  const initialState = {
    response: {},
    cityList: [],
    cityDetail: {},
    limit:0,
    page: 0,
    totalPages: 0,
    loading: false,
    status: 'success',
    text: '',
    showSnackbar: false
  };
  
  const CityReducer = (state = initialState, action) => {
    switch (action.type) {
      case CITY_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case CITY_LOADING:
        return {
          ...state,
          loading: true
        };
      case CITY_ERROR:
        return {
          ...state,
          cityList: [],
          response: {},
          cityDetail: {},
          limit:0,
          page: 0,
          totalPages: 0,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case CITY_GET_ALL_SUCCESS:
        return {
          ...state,
          cityList: action.payload.cityList,
          response: action.payload.response,
          cityDetail: action.payload.cityDetail,
          limit: action.payload.limit,
          page: action.payload.page,
          totalPages: action.payload.totalPages,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      default:
        return state || {};
    }
  };
  


export default CityReducer;