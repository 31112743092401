// Chakra imports
import {
  Flex,
  InputGroup,
  Spacer,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  InputLeftElement,
  IconButton,
  Input,
  Icon,
  
  
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesProjectRow from "components/Tables/TablesProjectRow";
import TablesTableRow from "components/Tables/TablesTableRow";
import Pagination from "components/Tables/Pagination";
import React, { useEffect ,useState} from "react";
import { tablesProjectData, tablesTableData } from "variables/general";
import { useDispatch, useSelector } from 'react-redux';
import { userGetAll, userDelete, userAddOrUpdate, userClearShowSnackbar } from '../../actions/userActions';
import { useToast } from '@chakra-ui/react'
import { USER_CLEAR_SNACKBAR } from "constants/types";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

function Tables() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.user.status);
  const responseMessage = useSelector(state => state.user.text);
  const loading = useSelector(state => state.user.loading);
  const userList = useSelector(state => state.user.userList);
  const response = useSelector(state => state.user.response);
  const showSnackbar = useSelector(state => state.user.showSnackbar);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search,setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  useEffect(() => {
    dispatch(userGetAll());
  },[dispatch]);

  useEffect(() => {
    if(showSnackbar)
    {
      if (responseStatus == 'OK' && loading == false) {
        if(responseMessage !="")
        {
          toastFunction(responseMessage, 'success');
        }
        
      } else if (responseStatus == 'NOK' && loading == false) {
        if(responseMessage !="")
        {
          toastFunction(responseMessage, 'error');
        }
        
      }
    }
    dispatch({
      type: USER_CLEAR_SNACKBAR,
      payload: {
          showSnackbar: false,
      }
  })
  }, [loading]);

  const toastFunction = (title,status) =>
  {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&sortOrder="+sortOrder+"&column="+column;
    dispatch(userGetAll(sorting));
  };

  useEffect(() => {
    const searching = "&search="+search;
    console.log(searching);
    dispatch(userGetAll(searching));
  }, [search]);


  return (
    
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      {/* {console.log("userList")}
      {console.log(response)} */}
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        
        <Flex>
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Users Table
          </Text>
        </CardHeader>
        <Spacer />
        
        
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
          <InputGroup borderRadius='8px' w='200px' >
          <InputLeftElement
            children={
              <IconButton
                bg='inherit'
                borderRadius='inherit'
                _hover='none'
                _active={{
                  bg: "inherit",
                  transform: "none",
                  borderColor: "transparent",
                }}
                _focus={{
                  boxShadow: "none",
                }}
                icon={
                  <SearchIcon color={searchIconColor} w='15px' h='15px' />
                }></IconButton>
            }
          />
          <Input
            variant='search'
            fontSize='xs'
            bg={inputBg}
            onKeyUp ={
              (e)=>
              {
                setSearch(e.target.value);
                if(e.target.value.length<3)
                {
                  setSearch("");
                }
                
              }
            }
            placeholder='Type here...'
          />
        </InputGroup> 
          </Text>
        </CardHeader>
      </Flex>
        <CardBody pb={4}>
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400" >
                <Th pl="0px" borderColor={borderColor} color="gray.400"
                onClick={() => handleSort("name")}
                cursor="pointer"
                >
                  Name
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th borderColor={borderColor} color="gray.400" onClick={() => handleSort("email")}
                  cursor="pointer">
                    Email
                    {sortColumn === "email" && (
                  <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th borderColor={borderColor} color="gray.400" onClick={() => handleSort("contactNo")}
                  cursor="pointer">Phone
                  {sortColumn === "contactNo" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                  </Th>
                <Th borderColor={borderColor} color="gray.400" onClick={() => handleSort("password")}
                cursor="pointer">Password
                  {sortColumn === "password" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th borderColor={borderColor}></Th>
              </Tr>
            </Thead>
            <Tbody>
            {/* {tablesTableData.map((row, index, arr) => { */}
              {
              userList.map((row, index, arr) => {
                return (
                  <TablesTableRow
                    name={row.name}
                    
                    email={row.email}
                    contactNo={row.contactNo}
                    password={row.password}
                    isLast={index === arr.length - 1 ? true : false}
                    id={row._id}
                  />
                );
              })}
            </Tbody>
          </Table>
          {userList.length==0?<></>:<Pagination
          totalPages = {response.totalPages}
          currentPage = {response.currentPage}
          
          ></Pagination>
          }
        </CardBody>
      </Card>
              
    </Flex>
  );
}

export default Tables;
