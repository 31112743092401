const {
    EMPLOYEE_LOADING,
    EMPLOYEE_GET_ALL_SUCCESS,
    EMPLOYEE_ERROR,
    EMPLOYEE_CLEAR_SNACKBAR,
    EMPLOYEE_SEARCH
  } = require('../constants/types');
  
  const initialState = {
    response: {},
    employeeList: [],
    employeeAttendenceHistoryList : [],
    employeeDetail: {},
    loading: false,
    status: 'success',
    text: '',
    showSnackbar: false,
    limit : 0,
    page : 0,
    totalPages : 0,
    totalItems : 0,
    searching : ""
  };
  
  const employeeReducer = (state = initialState, action) => {
    switch (action.type) {
      case EMPLOYEE_SEARCH:
        return {
          ...state,
          searching: action.payload.searching
        };
      case EMPLOYEE_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case EMPLOYEE_LOADING:
        return {
          ...state,
          loading: true
        };
      case EMPLOYEE_ERROR:
        return {
          ...state,
          employeeList: [],
          employeeAttendenceHistoryList : [],
          response: {},
          employeeDetail: {},
          limit : 0,
          page : 0,
          totalPages : 0,
          totalItems : 0,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case EMPLOYEE_GET_ALL_SUCCESS:
        return {
          ...state,
          employeeList: action.payload.employeeList,
          employeeAttendenceHistoryList: action.payload.employeeAttendenceHistoryList,
          response: action.payload.response,
          employeeDetail: action.payload.employeeDetail,
          limit: action.payload.limit,
          page: action.payload.page,
          totalPages: action.payload.totalPages,
          totalItems: action.payload.totalItems,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      default:
        return state || {};
    }
  };
  
  export default employeeReducer;
