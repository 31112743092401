import {
    CANTEEN_LOADING,
    CANTEEN_GET_ALL_SUCCESS,
    CANTEEN_ERROR,
    CANTEEN_CLEAR_SNACKBAR
  } from '../constants/types';
  
  const initialState = {
    response: {},
    canteenList: [],
    canteenDetail: {},
    loading: false,
    status: 'success',
    text: '',
    showSnackbar: false
  };
  
  const canteenReducer = (state = initialState, action) => {
    switch (action.type) {
      case CANTEEN_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case CANTEEN_LOADING:
        return {
          ...state,
          loading: true
        };
      case CANTEEN_ERROR:
        return {
          ...state,
          canteenList: [],
          response: {},
          canteenDetail: {},
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case CANTEEN_GET_ALL_SUCCESS:
        return {
          ...state,
          canteenList: action.payload.canteenList,
          response: action.payload.response,
          canteenDetail: action.payload.canteenDetail,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      default:
        return state || {};
    }
  };
  
  export default canteenReducer;