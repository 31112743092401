import React, { useRef } from 'react';
import {
  Badge,
  Button,
  Flex,
  Link,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure,
  ButtonGroup,
  IconButton,
  Center,
} from "@chakra-ui/react";
import { BsFillTrashFill } from "react-icons/bs";
import { ViewIcon } from "@chakra-ui/icons";
import { AiFillEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import QRCode from 'react-qr-code';
import { toPng } from 'html-to-image';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";

import { floorDelete } from "actions/floorAction";
import { useParams, useHistory } from "react-router-dom/cjs/react-router-dom.min";
const GroupFloorTableRow = (props) => {
  const {
serialnumber,
    name,
    groupName,
    description,
    status,
    patrollingRoute,
    time,
    scan,
    group,
    isLast,
    id,

  } = props;


  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const authDetail = useSelector((state) => state.auth.authDetail);
  const dispatch = useDispatch();
  const params = useParams();

  const handleDelete = () => {
    const floor = {
      _id: id,
    };
    dispatch(floorDelete(floor._id,params.id));
  };

  const qrCodeRef = useRef();

  console.log("nakhkahksdkhl");
  console.log(qrCodeRef);

  const handleDownloadQRCode = async () => {
    try {
      const svgString = qrCodeRef.current.querySelector('svg').outerHTML;
      const dataUrl = await toPng(qrCodeRef.current);

      const a = document.createElement('a');
      a.href = dataUrl;
      a.download = `${name}.png`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading QR code:', error);
    }
  };

  return (
    <>
      <Tr>
      
      <Td
          >
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={titleColor}
                  fontWeight="bold"
                  minWidth="100%"
                >
                  {serialnumber}
                </Text>
              </Flex>
            </Flex>
          </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {name}
            </Text>
          </Flex>
        </Td>

        <Td
          minWidth={{ sm: "250px" }}
          pl="20px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {description}
            </Text>
          </Flex>
        </Td>


        <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Badge colorScheme={status ? "green" : "red"}>
            {status ? "Active" : "Inactive"}
          </Badge>
        </Td>

        <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {patrollingRoute}
            </Text>
          </Flex>
        </Td>
        <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {new Date(time).toLocaleDateString('en-GB').replace(/\//g, '-')}
            </Text>
          </Flex>
        </Td>

        <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold" ref={qrCodeRef} style={{ textAlign: 'center', color: 'black' }}>
              <Center><QRCode size={100} value={scan.toString()} /></Center>
              {/* <span style={{ display: 'inline-block', fontWeight: 'italic', color: 'white', padding: '15px',paddingTop: '5px', paddingBottom: '5px', textAlign: 'center', backgroundColor: 'blue', margin: "10px", marginLeft: '90px', marginRight: '90px', borderRadius: '10px'}}>{name}</span> */}
              <span style={{
                display: 'inline-block',
                margin: '5px',
                borderRadius: '8px',
                fontFamily: 'Arial, sans-serif',
                fontSize: '12px',
                marginTop: '20px',
                backgroundColor: '#ccccff',
                color: 'black',
                border: '2px solid #3498db',
                padding: '15px', paddingTop: '5px', paddingBottom: '5px', textAlign: 'center',
              }}>{`Floor: ${name}, Group: ${group}`}</span>
              <span style={{ display: 'inline-block', fontWeight: 'italic', color: 'white', padding: '15px', paddingTop: '5px', paddingBottom: '5px', textAlign: 'center', backgroundColor: 'blue', margin: "10px", marginLeft: '90px', marginRight: '90px', borderRadius: '10px', }}>{name}</span>
            </Text>

            <Button onClick={handleDownloadQRCode}>Download QR Code</Button>
          </Flex>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          {authDetail.role.allPermissions === false ? (
            <ButtonGroup variant="solid" size="sm" spacing={3}>
              {authDetail.role.permissions.map((permission) => {
                const { permissionName, moduleName } = permission;

                if (
                  permissionName === "VIEW" &&
                  moduleName === "FLOOR"
                ) {
                  return (
                    <Link href={`#/admin/floor-view/${id}`}>
                      <IconButton
                        key={permission.id}
                        colorScheme="blue"
                        icon={<ViewIcon />}
                        aria-label="View"
                      />
                    </Link>
                  );
                }

                if (
                  permissionName === "EDIT" &&
                  moduleName === "FLOOR"
                ) {
                  return (
                    <Link href={`#/admin/floor-update-form/${id}`}>
                      <IconButton
                        key={permission.id}
                        colorScheme="green"
                        icon={<AiFillEdit />}
                        aria-label="Edit"
                      />
                    </Link>
                  );
                }

                if (
                  permissionName === "DELETE" &&
                  moduleName === "FLOOR"
                ) {
                  return (
                    <IconButton
                      key={permission.id}
                      colorScheme="red"
                      variant="outline"
                      icon={<BsFillTrashFill />}
                      aria-label="Delete"
                      onClick={() => handleDelete(id)}
                    />
                  );
                }

                return null;
              })}
            </ButtonGroup>
          ) : (
            <ButtonGroup>
              <Link href={`#/admin/floor-view/${id}`}>
                <IconButton
                  colorScheme="blue"
                  icon={<ViewIcon />}
                  aria-label="View"
                />
              </Link>
              <Link href={`#/admin/floor-update-form/${id}`}>
                <IconButton
                  colorScheme="green"
                  icon={<AiFillEdit />}
                  aria-label="Edit"
                />
              </Link>
              <IconButton
                colorScheme="red"
                variant="outline"
                icon={<BsFillTrashFill />}
                aria-label="Delete"
                onClick={() => handleDelete(id)}
              />
            </ButtonGroup>
          )}
        </Td>
        {/* <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>

          <ButtonGroup>
            <Link href={`#/admin/floor-view/${id}`}>
              <IconButton
                colorScheme="blue"
                icon={<ViewIcon />}
                aria-label="View"
              />
            </Link>

            <Link href={`#/admin/floor-update-form/${id}`}>
              <IconButton
                colorScheme="green"
                icon={<AiFillEdit />}
                aria-label="Edit"
              />
            </Link>

            <IconButton
              colorScheme="red"
              variant="outline"
              icon={<BsFillTrashFill />}
              aria-label="Delete"
              onClick={onOpen}
            />
          </ButtonGroup>
        </Td> */}

        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Floor
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    handleDelete();
                    onClose();
                  }}
                  ml={3}
                >
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Tr>
    </>
  );
};

export default GroupFloorTableRow;
