import React from "react";
import {
  chakra,
  Badge,
  Td,
  Tr,
  Text,
  useColorModeValue,
  Flex,
  ButtonGroup,
  IconButton,
  Link,
  Image,
} from "@chakra-ui/react";
import { ViewIcon } from "@chakra-ui/icons";
import { id } from "date-fns/locale";

const VisitorTodayHeadCountRow = (props) => {
  const {
    serialnumber,
    txnNumber,
    guardNameEntryRaisedBy,
    checkpostInName,
    image,
    name,
    company,
    contactno,
    visitorType,
    purpose,
    status,
    visitorStatus,
    department,
    subdepartment,
    carryItem,
    vehicleType,
    vehicleNumber,
    inTime,
    isLast,
    logs,
    id,
  } = props;

  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  console.log("log");
  console.log(logs);
  return (
    <>
      <Tr>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {serialnumber}
            </Text>
          </Flex>
        </Td>

        <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {txnNumber}
              </Text>
            </Flex>
          </Flex>
        </Td>

        <Td
          minWidth={{ sm: "250px" }}
          pl="50px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {guardNameEntryRaisedBy}
              </Text>
            </Flex>
          </Flex>
        </Td>
        <Td
          minWidth={{ sm: "250px" }}
          pl="30px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {checkpostInName}
              </Text>
            </Flex>
          </Flex>
        </Td>

        <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              <Image
                boxSize="70px"
                objectFit="cover"
                src={image}
                borderRadius="50%"
                alt="Dan Abramov"
                _hover={{ transform: "scale(2)" }}
                transition="transform 0.5s ease"
                cursor="pointer"
              />
            </Text>
          </Flex>
        </Td>

        <Td
          minWidth={{ sm: "250px" }}
          pl="10px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {name}
            </Text>
          </Flex>
        </Td>

        <Td
          minWidth={{ sm: "250px" }}
          pl="25px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {company}
            </Text>
          </Flex>
        </Td>
        <Td
          minWidth={{ sm: "250px" }}
          pl="20px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {contactno}
              </Text>
            </Flex>
          </Flex>
        </Td>

        <Td
          minWidth={{ sm: "250px" }}
          pl="30px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {visitorType}
            </Text>
          </Flex>
        </Td>

        <Td
          minWidth={{ sm: "250px" }}
          pl="30px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {purpose}
            </Text>
          </Flex>
        </Td>
        

        <Td
          minWidth={{ sm: "250px" }}
          pl="20px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {department}
            </Text>
          </Flex>
        </Td>

        <Td
          minWidth={{ sm: "250px" }}
          pl="20px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {subdepartment}
            </Text>
          </Flex>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Badge colorScheme={visitorStatus=="IN"? "green" : "red"}>{visitorStatus}</Badge>
        </Td>

        <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {carryItem}
            </Text>
          </Flex>
        </Td>

        <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {vehicleType}
            </Text>
          </Flex>
        </Td>

        <Td
          minWidth={{ sm: "250px" }}
          pl="50px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {vehicleNumber}
            </Text>
          </Flex>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Badge colorScheme={status=="NEW REQUEST"  || status=="APPROVED" || status=="REACHED" || status=="COMPLETED" || status=="ALLOW"? "green" : "red"}>{status}</Badge>
          
          </Td>
        
        {/* <Td
          minWidth={{ sm: "250px" }}
          pl="30px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {totalTime}
            </Text>
          </Flex>
        </Td> */}
        

        {/* <Td
          minWidth={{ sm: "250px" }}
          pl="50px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {ExitByGuardName}
            </Text>
          </Flex>
        </Td> */}
{/* 
        <Td
          minWidth={{ sm: "250px" }}
          pl="50px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {checkpostOutName}
            </Text>
          </Flex>
        </Td> */}

        {/* 
        <Td
          minWidth={{ sm: "250px" }}
          pl="20px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
            <Badge colorScheme={status === "ALLOW" ? "green" : "red"}>{status}</Badge>
          </Td> */}

        <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {inTime}
            </Text>
          </Flex>
        </Td>

        {/* <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {outTime}
            </Text>
          </Flex>
        </Td> */}
        

       
      </Tr>
    </>
  );
};

export default VisitorTodayHeadCountRow;
