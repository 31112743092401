import {
    MODULE_LOADING,
    MODULE_GET_ALL_SUCCESS,
    MODULE_ERROR,
    MODULE_CLEAR_SNACKBAR
  } from '../constants/types';
  
  const initialState = {
    response: {},
    moduleList: [],
    moduleDetail: {},
    loading: true,
    status: "success",
    text: "",
    showSnackbar: false
  };
  
  const moduleReducer = (state = initialState, action) => {
    switch (action.type) {
      case MODULE_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case MODULE_LOADING:
        return {
          ...state,
          loading: true,
        };
      case MODULE_ERROR:
        return {
          ...state,
          moduleList: [],
          response: {},
          moduleDetail: {},
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case MODULE_GET_ALL_SUCCESS:
        return {
          ...state,
          moduleList: action.payload.moduleList,
          response: action.payload.response,
          moduleDetail: action.payload.moduleDetail,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      default:
        return state || {};
    }
  };
  
  export default moduleReducer;
  