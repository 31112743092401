import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Spinner,
  Image,
  Select
} from "@chakra-ui/react";
import Relect from 'react-select';
import dataConstant from "constants/dataConstant";
import { GUARD_WORKEMP_CLEAR_SNACKBAR } from "constants/types";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { server_url } from '../../constants/types';

import { useToast } from '@chakra-ui/react'

import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { howItWorkAddOrUpdate } from "../../actions/howItWorkCategoryAction";
import CustomInput from "views/CustomValidation";

function HowItWorkCategoryUpdate() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [photo, setPhoto] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [type,setType] = useState("");
  const [primary,setPrimary] = useState("");
  const [secondary,setSecondary] = useState("");
  const [howItWorkId, setHowItWorkId] = useState("");
  const [checkpost, setCheckpost] = useState("");
  const [checkpostList, setCheckpostList] = useState([]);
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [locality, setLocality] = useState("");
  const [state, setState] = useState("");
  const [selectedCheckposts, setSelectedCheckposts] = useState([]);
  const [homeModule, setHomeModule] = useState([]);
  const [departmentList, setDepartmentList] = useState('')
  const [department, setDepartment] = useState("");
  const [catName,setCatName] = useState("");
  const [designation, setDesignation] = useState("");
  const[description, setDescription] = useState("")
  const [subdepartment, setSubdepartment] = useState("");
  const [subdepartmentList, setSubdepartmentList] = useState([])
  const [designationList, setDesignationList] = useState([])
  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorPhoto, setIsErrorPhoto] = useState(false);
  const [isErrorNumber, setIsErrorNumber] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isErrorCatName,setIsErrorCatName] = useState(false)
  const [isErrorHowItWorkId, setIsErrorHowItWorkId] = useState(false);
  const [isErrorCheckpost, setIsErrorCheckpost] = useState(false);
  const [isErrorPostalcode, setIsErrorPostalcode] = useState(false);
  const [isErrorLine1, setIsErrorLine1] = useState(false);
  const [isErrorLine2, setIsErrorLine2] = useState(false);
  const [isErrorLocality, setIsErrorLocality] = useState(false);
  const [isErrorHomeModule, setIsErrorHomeModule] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isErrorDepartment, setIsErrorDepartment] = useState(false);
  const [isErrorDesignation, setIsErrorDesignation] = useState(false);
  const [isErrorSubdepartment, setIsErrorSubdepartment] = useState(false);
  const [isErrorDescription,setIsErrorDescription] = useState(false)
  const [isErrorType,setIsErrorType] = useState(false)
  const [isErrorPrimary ,setIsErrorPrimary ] = useState(false);
  const [isErrorSecondary ,setIsErrorSecondary ] = useState(false);


  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.HowItWorkCategory.status);
  const responseMessage = useSelector(state => state.HowItWorkCategory.text);
  const loading = useSelector(state => state.HowItWorkCategory.loading);
  const showSnackbar = useSelector(state => state.HowItWorkCategory.showSnackbar);
  const howItWorkDetail = useSelector(state => state.HowItWorkCategory.howItWorkDetail);
  const [isErrorState, setIsErrorState] = useState(false);
  const history = useHistory()
  const dispatch = useDispatch();
  const toast = useToast();

  const params = useParams();
  const IdofhowItWork = params.id;

  console.log("department")
  console.log(department)
  console.log("department")
  useEffect(() => {

    const howItWork = {
      _id: IdofhowItWork
    }
    dispatch(howItWorkAddOrUpdate(howItWork, 'view'));
  }, []);


  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {

        
        setName(howItWorkDetail.name);        
        setDescription(howItWorkDetail.description);
        setCatName(howItWorkDetail.city.name);
        setType(howItWorkDetail.type);
        setEmail(howItWorkDetail.city.email);
        setPrimary(howItWorkDetail.city.contactNo);
        setSecondary(howItWorkDetail.city.secoundarycontactNo)

      }

    }

    dispatch({
      type: GUARD_WORKEMP_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsErrorName(name === '');
    setIsErrorPhoto(photo === '');
    setIsErrorNumber(number === '');
    setIsErrorEmail(email === '');
    setIsErrorDescription(description === "");
    setIsErrorCatName(catName === "");
    setIsErrorType(type === "");
    setIsErrorPrimary(primary === "");
    setIsErrorSecondary(secondary === "")

    if (name !== "" && email !== "" 
     && description !== "" && catName !== "" && type !== "" && primary !=="" && secondary !== "") 
     {

      const newHowItWork = {
        _id: IdofhowItWork,
        name,
        email,
        description,
        catName,
        type,
        primary,
        secondary, 
      };
      console.log(newHowItWork)
      dispatch(howItWorkAddOrUpdate(newHowItWork, 'edit'));
    }

  };

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: GUARD_WORKEMP_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])


  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };



  const textRegex = /^[A-Za-z\s]+$/;
  const numberRegex = /^\d{10}$/;
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/;
  const howItWorkIdRegex = /^[a-zA-Z0-9]+$/;
  const postalCodeRegex = /^[0-9]{6}$/;

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            How-It-Work Category Update Form
          </Text>
          <br/>
          <SimpleGrid minChildWidth='120px' spacing='40px'>
            <FormControl isInvalid={isErrorName || (submitted && name === "")}>
              <CustomInput
                type='text'
                label='Name'
                value={name}
                onChange={(value) => {
                  setIsErrorName(value === '' || !textRegex.test(value));
                  setName(value);
                }}
                isInvalid={isErrorName || (submitted && name === "")}
                regex={textRegex}
                submitted={submitted}
              />
            </FormControl>

            <FormControl isInvalid={isErrorDescription || (submitted && description === "")}>
                <CustomInput
                  type="text"
                  label="Description"
                  value={description}
                  onChange={(value) => {
                    setIsErrorDescription(value === '' || !textRegex.test(value));
                    setDescription(value);
                  }}
                  isInvalid={isErrorDescription || (submitted && description === "")}
                  regex={textRegex}
                  submitted={submitted}
                />
              </FormControl>

              <FormControl isInvalid={isErrorCatName || (submitted && catName === "")}>
                <CustomInput
                  type="text"
                  label="Category Name"
                  value={catName}
                  onChange={(value) => {
                    setIsErrorCatName(value === '' || !textRegex.test(value));
                    setCatName(value);
                  }}
                  isInvalid={isErrorCatName || (submitted && catName === "")}
                  regex={textRegex}
                  submitted={submitted}
                />
              </FormControl>
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>

          <FormControl isInvalid={isErrorType || (submitted && type === "")}>
                <CustomInput
                  type="text"
                  label="Type"
                  value={type}
                  onChange={(value) => {
                    setIsErrorType(value === '' || !textRegex.test(value));
                    setType(value);
                  }}
                  isInvalid={isErrorType || (submitted && type === "")}
                  regex={textRegex}
                  submitted={submitted}
                />
              </FormControl>

            <FormControl isInvalid={isErrorEmail || (submitted && email === "")}>
              <CustomInput
                type="email"
                label="Email"
                value={email}
                onChange={(value) => {
                  setIsErrorEmail(value === '' || !emailRegex.test(value));
                  setEmail(value);
                }}
                isInvalid={isErrorEmail || (submitted && email === "")}
                regex={emailRegex}
                submitted={submitted}
              />
            </FormControl>
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>

          <FormControl isInvalid={isErrorPrimary || (submitted && primary === "")}>
                <CustomInput
                  type="number"
                  label="Primary Number"
                  value={primary}
                  onChange={(value) => {
                    setIsErrorPrimary(value === '' || !numberRegex.test(value));
                    setPrimary(value);
                  }}
                  isInvalid={isErrorPrimary || (submitted && primary === "")}
                  regex={numberRegex}
                  submitted={submitted}
                />
              </FormControl>

          <FormControl isInvalid={isErrorSecondary || (submitted && secondary === "")}>
                <CustomInput
                  type="number"
                  label="Secondary Number"
                  value={secondary}
                  onChange={(value) => {
                    setIsErrorSecondary(value === '' || !numberRegex.test(value));
                    setSecondary(value);
                  }}
                  isInvalid={isErrorPrimary || (submitted && secondary === "")}
                  regex={numberRegex}
                  submitted={submitted}
                />
              </FormControl>
          </SimpleGrid>

          {/* ... Additional form fields ... */}
          <Center>
            {loading ? (
              <Spinner mt="24px" size="lg" />
            ) : (
              <Button colorScheme="blue" mt="24px" onClick={handleSubmit}>
                Submit
              </Button>
            )}
          </Center>
        </CardHeader>
        <CardBody>
          {/* ... Additional form content ... */}
        </CardBody>
      </Card>
      <Spacer></Spacer>
      
    </Flex>
  );
}

export default HowItWorkCategoryUpdate;
