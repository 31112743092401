import React, { useEffect, useState } from "react";
import { Box, Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useDispatch, useSelector } from "react-redux";

import { useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { LONG_ALERT_CLEAR_SNACKBAR } from "constants/types";
import { Table, Tbody, Td, Tr, TableContainer } from "@chakra-ui/react";
import { Skeleton, SkeletonText } from "@chakra-ui/react";
import { longStayAlertAddOrUpdate } from "../../actions/longStayAlertAction";
import axios from "axios";
import { server_url } from "constants/types";
import { useHistory } from "react-router-dom";

const LongAlertView = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [department, setDepartment] = useState("");
  const [subdepartment, setSubdepartment] = useState("");
  const [checkpostName, setCheckpostName] = useState("");
  const [timeIn, setTimeIn] = useState("");
  const [guardName, setGuardName] = useState("");
  const [currentStatustype, setCurrentStatustype] = useState("");
  const [purpose, setPurpose] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [txnNumber, setTxnNumber] = useState("");
  const [totalTime, setTotalTime] = useState("");

  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const responseStatus = useSelector((state) => state.longStayAlert.status);
  const responseMessage = useSelector((state) => state.longStayAlert.text);
  const loading = useSelector((state) => state.longStayAlert.loading);
  const longStayAlertDetail = useSelector(
    (state) => state.longStayAlert.longStayAlertDetail
  );
  const showSnackbar = useSelector((state) => state.longStayAlert.showSnackbar);

  console.log("longStayAlertDetail4040");
  console.log(longStayAlertDetail);

  const params = useParams();
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === "OK" && loading === false) {
        setType(longStayAlertDetail.type);
        setName(longStayAlertDetail.name);
        setImage(longStayAlertDetail.image);
        setDepartment(longStayAlertDetail.department);
        setSubdepartment(longStayAlertDetail.subdepartment);
        setCheckpostName(longStayAlertDetail.checkpostName);
        setTimeIn(longStayAlertDetail.timeIn);
        setGuardName(longStayAlertDetail.guardName);
        setCurrentStatustype(longStayAlertDetail.currentStatustype);
        setPurpose(longStayAlertDetail.purpose);
        setCurrentStatus(longStayAlertDetail.currentStatus);
        setTxnNumber(longStayAlertDetail.txnNumber);
        setTotalTime(longStayAlertDetail.totalTime);

        if (responseMessage !== "") {
          toastFunction(responseMessage, "success");
        }
      } else if (responseStatus === "NOK" && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, "error");
        }
      }
    }

    dispatch({
      type: LONG_ALERT_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      },
    });
  }, [loading]);

  useEffect(() => {
    const longStayAlertId = params.id;
    console.log("ujdiysdgfiygyudf././///./");
    console.log(longStayAlertId);
    const longStayAlert = {
      _id: longStayAlertId,
    };
    dispatch(longStayAlertAddOrUpdate(longStayAlert, "view"));
  }, []);

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
      })
      .then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push("/auth/signin");
      });
  }, []);

  console.log("longStayAlertDetail");
  console.log(longStayAlertDetail);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Long Stay Alert View
          </Text>
        </CardHeader>
        <CardBody>
          {loading ? (
            <SkeletonText noOfLines={3} spacing="4" />
          ) : (
            <TableContainer>
              <Table variant="simple">
                <Tbody>
                  <Tr>
                    <Td>Type</Td>
                    <Td>{type}</Td>
                  </Tr>
                  <Tr>
                    <Td>Name</Td>
                    <Td>{name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Image</Td>
                    <Td>
                      <Flex direction="column">
                        <Image
                          boxSize="100px"
                          objectFit="cover"
                          src={image}
                          alt="HowItWork Image"
                        />
                      </Flex>
                    </Td>
                  </Tr>
                
                  <Tr>
                    <Td>Department</Td>
                    <Td>{department}</Td>
                  </Tr>
                  <Tr>
                    <Td>Subdepartment</Td>
                    <Td>{subdepartment}</Td>
                  </Tr>
                  <Tr>
                    <Td>Checkpost Name</Td>
                    <Td>{checkpostName}</Td>
                  </Tr>
                  <Tr>
                    <Td>Time In</Td>
                    <Td>{timeIn}</Td>
                  </Tr>
                  <Tr>
                    <Td>Guard Name</Td>
                    <Td>{guardName}</Td>
                  </Tr>
                  <Tr>
                    <Td>Current Status Type</Td>
                    <Td>{ }</Td>
                  </Tr>
                  <Tr>
                    <Td>Purpose</Td>
                    <Td>{purpose}</Td>
                  </Tr>
                  <Tr>
                    <Td>Current Status</Td>
                    <Td>{currentStatus}</Td>
                  </Tr>
                  <Tr>
                    <Td>Transaction Number</Td>
                    <Td>{txnNumber}</Td>
                  </Tr>
                  <Tr>
                    <Td>Total Time</Td>
                    <Td>{totalTime}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
};

export default LongAlertView;
