import React, { useState, useEffect } from 'react';
import {
  Flex,
  Input,
  SimpleGrid,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Button,
  Center,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '@chakra-ui/react';
import dataConstant from 'constants/dataConstant';
import { DESIGNATION_CLEAR_SNACKBAR } from 'constants/types';
import { designationAddOrUpdate } from '../../../actions/designationAction';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { server_url } from 'constants/types';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router-dom';
import Relect from 'react-select';
function GroupDesignationsForm() {
  const textColor = useColorModeValue('gray.700', 'white');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('');
  const [departmentId, setDepartmentId] = useState('');
  const [department, setDepartment] = useState('');
  const [subDepartment, setSubDepartment] = useState('');
  const [subDepartmentList, setSubDepartmentList] = useState('');
  const [departmentList, setDepartmentList] = useState([]);
  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorDescription, setIsErrorDescription] = useState(false);
  const [isErrorStatus, setIsErrorStatus] = useState(false);
  const [isErrorDepartment, setIsErrorDepartment] = useState(false);
  const [isErrorSubDepartment, setIsErrorSubDepartment] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [type, setType] = useState([]);

  const dispatch = useDispatch();
  const toast = useToast();
  const params = useParams();

  const responseStatus = useSelector((state) => state.designation.status);
  const responseMessage = useSelector((state) => state.designation.text);
  const loading = useSelector((state) => state.designation.loading);
  const showSnackbar = useSelector((state) => state.designation.showSnackbar);
  const history = useHistory();
  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsErrorName(name === '');
    // setIsErrorDescription(description === '');
    setIsErrorStatus(status === '');
    setIsErrorDepartment(department === '');
    setIsErrorSubDepartment(subDepartment === '');

    if (
      name !== '' &&
      status !== '' &&
      department !== '' &&
      subDepartment !== ''
    ) {
      // Get form data
      const designationData = {
        name: name,
        status: status,
        description: description,
        department: department,
        subDepartment: subDepartment,
        group:params.id,
        type: type.map((module) => module.value),
        // Other designation data fields
      };

      // Determine if it's an add or edit operation
      const state = 'add';
      // Call the designationAddOrUpdate action
      console.log("designationData");
      console.log(designationData);

      dispatch(designationAddOrUpdate(designationData, state));
    }
  };

  const departmentListforDropdown = () => {
    axios
      .get(`${server_url}/department?group=${params.id}&limit=1000`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('authToken'),
        },
      })
      .then((response) => {
        setDepartmentList(response.data.data);
      })
      .catch(() => console.log('error'));
  };

  const subdepartmentListforDropdown = (depId) => {
    console.log(depId);
    if(depId)
    {
      axios
      .get(`${server_url}/plant/subdepartment-list/${depId}?limit=1000`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('authToken'),
        },
      })
      .then((response) => {
        setSubDepartmentList(response.data.data);
      });
    }
    else{
      setSubDepartmentList([]);
    }
  };

  useEffect(() => {
    departmentListforDropdown();
  }, []);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== '') {
          toastFunction(responseMessage, 'success');
          history.goBack();
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== '') {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: DESIGNATION_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      },
    });
  }, [loading]);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Designation Form
          </Text>
          <SimpleGrid minChildWidth="120px" spacing="40px" mt="24px">
          <FormControl
              isInvalid={isErrorDepartment || (submitted && department === '')}
            >
              <FormLabel>Department</FormLabel>
              <Select
                // placeholder="Select Department"
                onChange={(e) => {
                  setIsErrorDepartment(e.target.value === '');
                  setDepartment(e.target.value);
                  subdepartmentListforDropdown(e.target.value);
                }}
                value={department}
              >
                 <option value="" disabled hidden>
                  Select Department
                </option>
                {departmentList ? (
                  departmentList.map((item, index) => (
                    <option key={item._id} value={item._id}>
                      {item.name}
                    </option>
                  ))
                ) : (
                  <></>
                )}
              </Select>
              {isErrorDepartment && submitted && (
                <FormErrorMessage>Department is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              isInvalid={isErrorSubDepartment || (submitted && subDepartment === '')}
            >
              <FormLabel>Subdepartment</FormLabel>
              <Select
                onChange={(e) => {
                  setIsErrorSubDepartment(e.target.value === '');
                  setSubDepartment(e.target.value);
                }}
                value={subDepartment}
              >
                <option value="" disabled hidden>
                  Select Subdepartment
                </option>
                {subDepartmentList ? (
                  subDepartmentList.map((item, index) => (
                    <option key={item._id} value={item._id}>
                      {item.name}
                    </option>
                  ))
                ) : (
                  <></>
                )}
              </Select>
              {isErrorSubDepartment && submitted && (
                <FormErrorMessage>Subdepartment is required.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Type</FormLabel>
              <Relect
                isMulti
                name="colors"
                placeholder="--select type--"
                options={dataConstant.designationType.map((item) => ({
                  value: item.value,
                  label: item.name
                }))}

                onChange={(value) => {
                  setType(value)
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
              {/* {isErrorSubDepartment && submitted && (
                <FormErrorMessage>Subdepartment is required.</FormErrorMessage>
              )} */}
            </FormControl>
            </SimpleGrid>

          <SimpleGrid minChildWidth="120px" spacing="40px">
            <FormControl isInvalid={isErrorName || (submitted && name === '')}>
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                onChange={(e) => {
                  setIsErrorName(e.target.value === '');
                  setName(e.target.value);
                }}
              />
              {!isErrorName ? (
                <></>
              ) : (
                <FormErrorMessage>Name is required.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              isInvalid={isErrorDescription || (submitted && description === '')}
            >
              <FormLabel>Description</FormLabel>
              <Input
                type="text"
                onChange={(e) => {
                  setIsErrorDescription(e.target.value === '');
                  setDescription(e.target.value);
                }}
              />
              {!isErrorDescription ? (
                <></>
              ) : (
                <FormErrorMessage>Description is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorStatus || (submitted && status === '')}>
              <FormLabel>Status</FormLabel>
              <Select
                placeholder="Select status"
                onChange={(e) => {
                  setIsErrorStatus(e.target.value === '');
                  setStatus(e.target.value);
                }}
              >
                {dataConstant.status.map((element, index) => (
                  <option key={element.id} value={element.value}>
                    {element.name}
                  </option>
                ))}
              </Select>
              {!isErrorStatus ? (
                <></>
              ) : (
                <FormErrorMessage>Status is required.</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>

          <Center>
            <Button
              type="submit"
              variant="solid"
              mt="32px"
              colorScheme="blue"
              onClick={(e) => handleSubmit(e)}
            >
              {loading ? <Spinner size="sm" /> : 'Submit'}
            </Button>
          </Center>
        </CardHeader>
        <CardBody>{/* Other card body content */}</CardBody>
      </Card>
    </Flex>
  );
}

export default GroupDesignationsForm;
