import React, { useEffect, useState } from 'react';
import {
  Flex,
  Input,
  SimpleGrid,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Button,
  Center, useColorModeValue,
} from '@chakra-ui/react';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useDispatch, useSelector } from 'react-redux';
// import { deviceAddOrUpdate } from '../../A'; // Update with the correct import for DeviceActions
import { useToast } from '@chakra-ui/react';
import { PATROLLING_ROUTE_CLEAR_SNACKBAR } from 'constants/types';
import { Spinner } from '@chakra-ui/react';
import dataConstant from 'constants/dataConstant';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
// import { deviceAddOrUpdate } from 'actions/devicesAction';
import { patrollingRouteAddOrUpdate } from 'actions/PatrollingRouteAction';
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';


function GroupPatrollingRouteForm() {
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('');
  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorDescription, setIsErrorDescription] = useState(false);
  const [isErrorStatus, setIsErrorStatus] = useState(false);


  const dispatch = useDispatch();
  const toast = useToast();
  const params = useParams();

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector((state) => state.patrollingRoute.status);
  const responseMessage = useSelector((state) => state.patrollingRoute.text);
  const loading = useSelector((state) => state.patrollingRoute.loading);
  const showSnackbar = useSelector((state) => state.patrollingRoute.showSnackbar);
  const history = useHistory();

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsErrorName(name === '');
    // setIsErrorDescription(description === '');
    setIsErrorStatus(status === '');
    history.push()

    if (name !== '' && status !== '') {
      console.log('Submit');
      // Get form data
      const patrollingRouteData = {
        name: name,
        description: description,
        status: status,
        group: params.id,
        // Other device data fields
      };
       console.log("patrollingRouteData");
       console.log(patrollingRouteData);

      // Determine if it's an add or edit operation
      const state = 'add';
      // Call the deviceAddOrUpdate action
      dispatch(patrollingRouteAddOrUpdate(patrollingRouteData, state));
      
    }
  };

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== '') {
          toastFunction(responseMessage, 'success');
          history.goBack();
          //   history.push('#/admin/DevicesTable');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== '') {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: PATROLLING_ROUTE_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      },
    });
  }, [loading]);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">

        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Form
          </Text>
          <SimpleGrid minChildWidth='120px' spacing='40px'>
            <FormControl isInvalid={isErrorName}>
              <FormLabel>Name</FormLabel>
              <Input
                type='text'
                onChange={(e) => {
                  setIsErrorName(e.target.value === '')
                  setName(e.target.value)
                }}
              />
              {!isErrorName ? (
                <></>
              ) : (
                <FormErrorMessage>Name is required.</FormErrorMessage>
              )}
            </FormControl>


            <FormControl isInvalid={isErrorDescription}>
              <FormLabel>Description</FormLabel>
              <Input
                type='text'
                onChange={(e) => {
                  // setIsErrorDescription(e.target.value === '')
                  setDescription(e.target.value)
                }}
              />
              {!isErrorDescription ? (
                <></>
              ) : (
                <FormErrorMessage>Description is required.</FormErrorMessage>
              )}
            </FormControl>


            <FormControl isInvalid={isErrorStatus}>
              <FormLabel>Status</FormLabel>
              <Select
                placeholder="Select status"
                onChange={(e) => {
                  setIsErrorStatus(e.target.value === '')
                  setStatus(e.target.value)
                }}
              >
                {dataConstant.status.map((element, index) => {
                  return (<option key={element['id']} value={element['value']} >{element['name']}</option>);

                })}
              </Select>
              {!isErrorStatus ? (
                <></>
              ) : (
                <FormErrorMessage>Status is required.</FormErrorMessage>
              )}
            </FormControl>

          </SimpleGrid>
          <Center>
            {loading ? <Spinner mt='24px' size='lg' /> :
              <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                Submit
              </Button>
            }
          </Center>
        </CardHeader>
        <CardBody>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default GroupPatrollingRouteForm;
