import React, { useEffect, useState, useRef } from 'react';
import { Flex, Button, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link, SimpleGrid, FormControl, Center, Spinner, FormLabel, Select } from "@chakra-ui/react";
import {
    FormErrorMessage,
  } from '@chakra-ui/react'
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
// import DevicePagination from './DevicePagination';
import { AddIcon } from '@chakra-ui/icons';
import { CALL_SETTING_CLEAR_SNACKBAR} from 'constants/types';
// import {  } from '../../actions/devicesAction'; // Assuming you have a DeviceAction file
// import DeviceTableRow from './DevicesTableRow';

// import DevicesPagination from './DevicesPagination';
// import iamSafePagination from './iamSafePagination'
import { callSettingGetAll } from 'actions/callSettingAction';
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory,useLocation } from 'react-router-dom';
const CallSettingForm = (props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { url = "", plant } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.callSetting.status);
  const responseMessage = useSelector(state => state.callSetting.text);
  const loading = useSelector(state => state.callSetting.loading);
//   const iamSafeList = useSelector(state => state.callSetting.iamSafeList);
  const response = useSelector(state => state.callSetting.response);
  const showSnackbar = useSelector(state => state.callSetting.showSnackbar);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const history = useHistory();
  const tableRef = useRef(null);
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
  const [department, setDepartment] = useState('');
  const [subdepartment, setSubdepartment] = useState('');
  const [departmentList, setDepartmentList] = useState([]);
  const [subdepartmentList, setSubdepartmentList] = useState([]);
  const [isErrorDepartment, setIsErrorDepartment] = useState(false);
  const [isErrorSubdepartment, setIsErrorSubdepartment] = useState(false);

  const [submitted, setSubmitted] = useState(false);
//   const [canteenCategory, setCanteenCategory] = useState('');
//   const [canteenCategoryList, setCanteenCategoryList] = useState([]);
  useEffect(() => {
    departmentListforDropdown();
    handleSubmit(event);
    if(searchParams.get('department'))
    {
      subdepartmentListforDropdown(searchParams.get('department'));
    }
    
  }, [dispatch]);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: CALL_SETTING_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const departmentListforDropdown = () => {
    axios
      .get(`${server_url}/department`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        setDepartmentList(responce.data.data)
      }).catch(() => console.log("error"))
  }

  const subdepartmentListforDropdown = (depId) => {
    if (depId) {
      axios
        .get(`${server_url}/plant/subdepartment-list/${depId}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          setSubdepartmentList(responce.data.data)
        })
    }
    else {
      setSubdepartmentList([])
    }

  }

  const handleSubmit = (event) => {
    if((department != null && department != '') && (subdepartment != null && subdepartment != ""))
    {
      searchParams.set('department', department);
      searchParams.set('subdepartment', subdepartment);
    }
    else{
      setDepartment(searchParams.get('department'));
      setSubdepartment(searchParams.get('subdepartment'));
    }

    
    history.replace(`${location.pathname}?${searchParams.toString()}`);
    // event.preventDefault();
    // setIsErrorDepartment(department === '')
    // setIsErrorSubdepartment(subdepartment === '')

    if (searchParams.get('department') !== '' && searchParams.get('subdepartment') !== ''){
        const callSetting = {
            department: searchParams.get('department'),
            subdepartment: searchParams.get('subdepartment'),
          };
          dispatch(callSettingGetAll('',callSetting));
    }
  };

  
  

  return (
    <>
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Select Give Fields
          </Text>
          <SimpleGrid minChildWidth="120px" spacing="40px" mt="20px">

            <FormControl isInvalid={isErrorDepartment || (submitted && department === "")}>
              <FormLabel>Department</FormLabel>
              <Select
                placeholder="Department"
                onChange={(e) => {
                  setIsErrorDepartment(e.target.value === '');
                  setDepartment(e.target.value);
                  subdepartmentListforDropdown(e.target.value);
                }}
                value={department}
              >
                {departmentList ? (departmentList.map((item, index) => (
                  <option key={item._id} value={item._id}>{item.name}</option>
                ))) : (<></>)}
              </Select>
              {isErrorDepartment && submitted && (
                <FormErrorMessage>Department is required.</FormErrorMessage>
              )}
            </FormControl>
           
            <FormControl isInvalid={isErrorSubdepartment || (submitted && subdepartment === "")}>
              <FormLabel>Sub Department</FormLabel>
              <Select
                placeholder="Subdepartment"
                onChange={(e) => {
                  setIsErrorSubdepartment(e.target.value === '');
                  setSubdepartment(e.target.value);
                }}
                value={subdepartment}
              >
                {subdepartmentList ? (subdepartmentList.map((item, index) => (
                  <option key={item._id} value={item._id}>{item.name}</option>
                ))) : (<></>)}
              </Select>
              {isErrorSubdepartment && submitted && (
                <FormErrorMessage>Subdepartment is required.</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>
          
          <Center>
            {loading ? (
              <Spinner mt="24px" size="lg" />
            ) : (
                <><Button colorScheme="blue" mt="24px" onClick={handleSubmit} >
                Submit
              </Button>
              </>
              
              
            )}
          </Center>
        </CardHeader>
        <CardBody></CardBody>
      </Card>
    </Flex>
    </>
  );
};

export default CallSettingForm;
