// Chakra imports
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';


import { PARKING_CLEAR_SNACKBAR } from "constants/types";
import {
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react";
import dataConstant from "constants/dataConstant";
import { permissionGetAll } from "actions/permissionActions";
import { moduleGetAll } from "actions/moduleActions";
import { parkingGetAll } from "actions/parkingAction";
import { adminGetAll } from "actions/adminActions";
import { parkingAddOrUpdate } from "actions/parkingAction";
import { parkingClearShowSnackbar } from "actions/parkingAction";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function ParkingUpdateForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [departmentList , setDepartmentList] = useState([]);
  const [departmentName , setDepartmentName] = useState("");
  const [type , setType] = useState("");
  // const [byWalk , setByWalk] = useState("");
  const [twoWheeler , setTwoWheeler] = useState(0);
  const [fourWheeler , setFourWheeler] = useState(0);
  const [bus , setBus] = useState(0);
  const [loadedBays , setLoadedBays] = useState(0);
  const [unloadedBays , setUnloadedBays] = useState(0);
  const [parkingStatus , setParkingStatus] = useState("");


  const [isErrorDepartmentName, setIsErrorDepartmentName] = useState(false);
  const [isErrorType, setIsErrorType] = useState(false);
  // const [isErrorByWalk, setIsErrorByWalk] = useState(false);
  const [isErrorTwoWheeler, setIsErrorTwoWheeler] = useState(false);
  const [isErrorFourWheeler, setIsErrorFourWheeler] = useState(false);
  const [isErrorBus, setIsErrorBus] = useState(false);
  const [isErrorLoadedBays, setIsErrorLoadedBays] = useState(false);
  const [isErrorUnLoadedBays, setIsErrorUnLoadedBays] = useState(false);
  const [isErrorParkingStatus, setIsErrorParkingStatus] = useState(false);
  const [permissionStyle, setPermissionStyle] = useState({
    display: "none",
  });
  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const statuses = ["success", "error", "warning", "info"];
  const responseStatus = useSelector((state) => state.parking.status);
  const responseMessage = useSelector((state) => state.parking.text);
  const loading = useSelector((state) => state.parking.loading);
  const parkingDetail = useSelector(state => state.parking.parkingDetail);
  const showSnackbar = useSelector(
    (state) => state.parking.showSnackbar
  );
  const moduleList = useSelector((state) => state.module.moduleList);
  const permissionList = useSelector(
    (state) => state.permission.permissionList
  );

// const plantAdmin = parkingDetail.plantAdmin 
// console.log("plantAdmin123445")
// console.log(plantAdmin)

// useEffect(() => {
//   plantDepartmentList()
// }, []);

const plantDepartmentList = (plantAdmin) => {
  axios
    .get(`${server_url}/plant/department/${plantAdmin}`, {  
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      }
    })
    .then((response) => {
      setDepartmentList(response.data.data);
    })
    .catch((error) => {
      console.error('Error fetching department list:', error);
    });
};


  const params = useParams();
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === "OK" && loading === false) {

        setDepartmentName(parkingDetail.departmentName._id);
        setType(parkingDetail.type);
        setTwoWheeler(parkingDetail.twoWheeler);
        setFourWheeler(parkingDetail.fourWheeler);
        setBus(parkingDetail.bus);
        setLoadedBays(parkingDetail.loadedBays);
        setUnloadedBays(parkingDetail.unloadedBays);
        setParkingStatus(parkingDetail.parkingStatus);
        plantDepartmentList(parkingDetail.plantAdmin);  
        if (responseMessage !== "") {
          toastFunction(responseMessage, "success");
        }
      } else if (responseStatus === "NOK" && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, "error");
        }
      }
    }
    dispatch({
      type: PARKING_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    });
  }, [loading]);

  useEffect(() => {
    dispatch(moduleGetAll());
    dispatch(permissionGetAll());

    dispatch(adminGetAll());


  }, []);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  

  // const parkingdepartmentListforDropdown = () => {
  //   axios
  //     .get(`${server_url}/parkingdepartment?`, {
  //       headers: {
  //         'Authorization': 'Bearer ' + localStorage.getItem('authToken')
  //       }
  //     }).then((responce) => {
  //       setParkingDepartmentList(responce.data.data)
  //     }).catch(() => console.log("error"))
  // }

  useEffect(() => {
    console.log(params);
    const state = 'view';
    // Call the roleAddOrUpdate action
    const parking = {
      _id: params.id,
    };

    dispatch(parkingAddOrUpdate(parking, state))


  }, []);

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  const handleSubmit = (event) => {
    event.preventDefault();
   
    setIsErrorDepartmentName(departmentName === '');
    setIsErrorType(type === '');
    setIsErrorTwoWheeler(twoWheeler === '');
    setIsErrorFourWheeler(fourWheeler === '')
    setIsErrorBus(bus === '')
    setIsErrorLoadedBays(loadedBays === '')
    setIsErrorUnLoadedBays(unloadedBays === '')
    setIsErrorParkingStatus(parkingStatus === '')

 
    if (departmentName !== "" && type !== "" && twoWheeler !== "" && fourWheeler !== "" && bus !== "" 
     && loadedBays !== ""  && unloadedBays !== ""  && parkingStatus !== "" ) {
      const parkingData = {
        _id : params.id,
        departmentName: departmentName,
        type:type,
        twoWheeler: twoWheeler ,
        fourWheeler: fourWheeler ,
        bus: bus,
        loadedBays: loadedBays,
        unloadedBays: unloadedBays,
        parkingStatus:parkingStatus,
        // Other parking data fields
      };


      const state = "edit";
      dispatch(parkingAddOrUpdate(parkingData, state));
    }
  };


  const handlePermissionChange = (permissionId, moduleId) => {
    setSelectedPermissions((prevSelectedPermissions) => {
      const existingPermissionIndex = prevSelectedPermissions.findIndex(
        (permission) =>
          permission._id === permissionId &&
          permission.modules._id === moduleId
      );

      if (existingPermissionIndex !== -1) {
        const updatedPermissions = [...prevSelectedPermissions];
        updatedPermissions.splice(existingPermissionIndex, 1);
        return updatedPermissions;
      } else {
        const permission = permissionList.find(
          (permission) =>
            permission._id === permissionId &&
            permission.modules._id === moduleId
        );

        return [...prevSelectedPermissions, permission];
      }
    });
  };






  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
    <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
      
      <CardHeader p="6px 0px 22px 0px">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
         Parking Update Form
        </Text>
        <br/>
        <SimpleGrid minChildWidth='120px' spacing='40px'>
       
             <FormControl isInvalid={isErrorDepartmentName}>
              <FormLabel>Department Name</FormLabel>
              <Select
                placeholder="----Select Department----"
                onChange={(e) => {
                  setIsErrorDepartmentName(e.target.value === '');
                  setDepartmentName(e.target.value);
                }}
                defaultValue={departmentName}
              >
                {departmentList ? (departmentList.map((item, index) => (
                  <option key={item._id} value={item._id} selected={departmentName == item._id ? true : false}>{item.name}</option>
                ))) : (<></>)}
              </Select>
              {!isErrorDepartmentName ? (
                <></>
              ) : (
                <FormErrorMessage>Department Name is required.</FormErrorMessage>
              )}
            </FormControl>

              <FormControl isInvalid={isErrorType}>
              <FormLabel>Type</FormLabel>
              <Select
                placeholder="----Select Type----"
                onChange={(e) => {
                  setIsErrorType(e.target.value === '')
                  setType(e.target.value);
                  console.log(e.target.value);
                }}
                value={type}
              >
                {dataConstant.parkingDepartment.map((element, index) => {
                  return (<option key={element['id']} value={element['value']} >{element['name']}</option>);

                })}
              </Select>
              {!isErrorType ? (
                <></>
              ) : (
                <FormErrorMessage>type is required.</FormErrorMessage>
              )}
            </FormControl>


          
          {/* <FormControl isInvalid={isErrorByWalk}>
            <FormLabel>By Walk</FormLabel>
            <Input
              type='text'
              onChange={(e) => {
                setIsErrorByWalk(e.target.value === '')
                setByWalk(e.target.value)
              }}
            />
            {!isErrorByWalk ? (
              <></>
            ) : (
              <FormErrorMessage>by Walk is required.</FormErrorMessage>
            )}
          </FormControl> */}
        </SimpleGrid>


        <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>


        <FormControl isInvalid={isErrorTwoWheeler}>
  <FormLabel>Two Wheeler</FormLabel>
  <Input
    type='text'
    onChange={(e) => {
      const inputValue = e.target.value;
      setIsErrorTwoWheeler(inputValue === '');
      setTwoWheeler(inputValue === '' ? 0 : parseFloat(inputValue));
    }}
    value={twoWheeler}
  />
  {!isErrorTwoWheeler ? (
    <></>
  ) : (
    <FormErrorMessage>Two wheeler is required.</FormErrorMessage>
  )}
</FormControl>


          <FormControl isInvalid={isErrorFourWheeler}>
  <FormLabel>Four Wheeler</FormLabel>
  <Input
    type='text'
    onChange={(e) => {
      const inputValue = e.target.value;
      setIsErrorFourWheeler(inputValue === '');
      setFourWheeler(inputValue === '' ? 0 : parseFloat(inputValue));
    }}
    value={fourWheeler}
  />
  {!isErrorFourWheeler ? (
    <></>
  ) : (
    <FormErrorMessage>Four wheeler is required.</FormErrorMessage>
  )}
</FormControl>


<FormControl isInvalid={isErrorBus}>
  <FormLabel>Bus</FormLabel>
  <Input
    type='text'
    onChange={(e) => {
      const inputValue = e.target.value;
      setIsErrorBus(inputValue === '');
      setBus(inputValue === '' ? 0 : parseFloat(inputValue));
    }}
    value={bus}
  />
  {!isErrorBus ? (
    <></>
  ) : (
    <FormErrorMessage>Bus is required.</FormErrorMessage>
  )}
</FormControl>



        </SimpleGrid>


        <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>


       
        <FormControl isInvalid={isErrorLoadedBays}>
  <FormLabel>Loaded Bays</FormLabel>
  <Input
    type='text'
    onChange={(e) => {
      const inputValue = e.target.value;
      setIsErrorLoadedBays(inputValue === '');
      setLoadedBays(inputValue === '' ? 0 : parseFloat(inputValue));
    }}
    value={loadedBays}
  />
  {!isErrorLoadedBays ? (
    <></>
  ) : (
    <FormErrorMessage>Loaded Bay is required.</FormErrorMessage>
  )}
</FormControl>


<FormControl isInvalid={isErrorUnLoadedBays}>
  <FormLabel>Unloaded Bays</FormLabel>
  <Input
    type='text'
    onChange={(e) => {
      const inputValue = e.target.value;
      setIsErrorUnLoadedBays(inputValue === '');
      setUnloadedBays(inputValue === '' ? 0 : parseFloat(inputValue));
    }}
    value={unloadedBays}
  />
  {!isErrorUnLoadedBays ? (
    <></>
  ) : (
    <FormErrorMessage>Unloaded Bay is required.</FormErrorMessage>
  )}
</FormControl>


        <FormControl isInvalid={isErrorParkingStatus}>
            <FormLabel>Status</FormLabel>
            <Select
              placeholder="----Select status----"
              onChange={(e) => {
                setIsErrorParkingStatus(e.target.value === '')
                setParkingStatus(e.target.value)
              }}
              value={parkingStatus}
            >
              { dataConstant.status.map((element,index) => {
                  return (<option key={element['id']} value={element['value']} >{element['name']}</option>);   
                    
                })}
            </Select>
            {!isErrorParkingStatus ? (
              <></>
            ) : (
              <FormErrorMessage>Status is required.</FormErrorMessage>
            )}
          </FormControl>

        </SimpleGrid>
        <Center>
          {loading ? <Spinner mt='24px' size='lg' /> :
            <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
              Submit
            </Button>
          }
        </Center>
      </CardHeader>
      <CardBody>
      </CardBody>
    </Card>
  </Flex>
  );
}

export default ParkingUpdateForm;
